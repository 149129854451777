import { useTranslation } from "react-i18next";
import { useEffect, useMemo, useState } from "react";
import useDebounce from "../../../Hooks/debounce";
import { Form } from "antd";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../../Notifications/NotificationsUtils";
import { useQuery } from "react-query";
import { getAllFaculties } from "../../../Requests/faculty-requests";
import { getDepartmentsForFaculties } from "../../../Requests/department-requests";
import { getStudyProgramsForFaculties } from "../../../Requests/student-study-request";
import { getAllAcademicUsers } from "../../../Requests/academic-user-requests";
import { toDomainModel, toFormModel } from "./transformers/model.transformers";
import {
	publishEmail,
	updateEmail,
} from "../../../Requests/newsletter-requests";
import { useInitPublishEmailForm } from "./services/useInitPublishEmailForm";
import { getEmail } from "./AddEmailForm.utils";
import { PublishUpdateEmailDTO } from "../../../Api";
import { AddEmailFormModel } from "./models/AddEmailFormModel";
import {
	AddEmailFormController,
	AddEmailFormProps,
} from "./AddEmailForm.types";
import { getAllCompanyUsers } from "../../../Requests/company-users-requests";

export const useAddEmailFormController = (
	props: AddEmailFormProps
): AddEmailFormController => {
	const { t } = useTranslation();

	const [saveModalVisibility, setSaveModalVisibility] = useState(false);
	const [cancelModalVisibility, setCancelModalVisibility] = useState(false);
	const [spinning, setSpinning] = useState(false);
	const [userSearch, setUserSearch] = useState("");
	const debouncedUserSearch = useDebounce(userSearch, 1000);
	const [facultySearch, setFacultySearch] = useState("");
	const [departmentSearch, setDepartmentSearch] = useState("");
	const [studyProgramSearch, setStudyProgramSearch] = useState("");
	const [facultyOptions, setFacultyOptions] = useState<any>([]);
	const [departmentOptions, setDepartmentOptions] = useState<any>([]);
	const [studyProgramOptions, setStudyProgramOptions] = useState<any>([]);
	const [recipientsOptions, setRecipientsOptions] = useState<any>([]);
	const [form] = Form.useForm<AddEmailFormModel>();

	const sendStrategyValue = Form.useWatch("sendStrategy", form);
	const recipientRoleValue = Form.useWatch("recipientRole", form);
	const facultiesValue = Form.useWatch("faculties", form);

	const { defaultValues, schema, yupSync } = useInitPublishEmailForm(form);

	const selectedFaculties = useMemo(() => {
		if (facultiesValue)
			return Array.isArray(facultiesValue) ? facultiesValue : [facultiesValue];
		return [];
	}, [facultiesValue]);

	useEffect(() => {
		form.setFieldsValue({
			...form.getFieldsValue(),
			departments: [],
			studyPrograms: [],
			years: [],
		});
	}, [form, facultiesValue]);

	const openGetErrorNotification = (_error: any) => {
		openNotification(
			t("emails.error"),
			t("emails.fetchDataError"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const { data: facultiesList } = useQuery(
		["getFaculties"],
		() => getAllFaculties("", 1, 20),
		{
			onError: (err) => {
				openGetErrorNotification(err);
			},
			refetchOnWindowFocus: false,
		}
	);

	const { data: departmentsList } = useQuery(
		["getFacultiesDepartments", selectedFaculties],
		() =>
			(selectedFaculties?.length ?? 0) === 0
				? null
				: getDepartmentsForFaculties(selectedFaculties),
		{
			onError: (err) => {
				openGetErrorNotification(err);
			},
			refetchOnWindowFocus: false,
		}
	);

	const { data: studyProgramsList } = useQuery(
		["getFacultiesStudyPrograms", selectedFaculties],
		() =>
			(selectedFaculties?.length ?? 0) === 0
				? null
				: getStudyProgramsForFaculties(selectedFaculties),
		{
			onError: (err) => {
				openGetErrorNotification(err);
			},
			refetchOnWindowFocus: false,
		}
	);

	const { data: academicUsers, isLoading: isLoadingAcademicUsers } = useQuery(
		["getAcademicUsers", debouncedUserSearch],
		() => getAllAcademicUsers(debouncedUserSearch),
		{
			onError: (err) => {
				openGetErrorNotification(err);
			},
			refetchOnWindowFocus: false,
		}
	);

	const { data: email, isLoading: isLoadingEmail } = useQuery(
		["getNewsletterEmail", props.emailId],
		() => getEmail(props.emailId),
		{
			onError: (err) => {
				openGetErrorNotification(err);
			},
			onSuccess: (data) => {
				if (data) {
					form.setFieldsValue({
						...toFormModel(data),
						recipients: data.recipients?.map((x) => x.recipientId!),
					});
				}
			},
			refetchOnWindowFocus: false,
		}
	);

	const isEditing = useMemo(
		() => props.emailId !== undefined && props.emailId !== "",
		[props.emailId]
	);

	const handleCancel = () => {
		props.setRedirect(true);
	};

	const openSuccessAddNotificationAndRedirect = () => {
		props.setRedirect(true);
		openNotification(
			t("emails.publishEmail"),
			t("emails.publishEmailSuccess"),
			NOTIFICATION_TYPES.SUCCESS,
			6
		);
	};

	const openSuccessEditNotificationAndRedirect = () => {
		props.setRedirect(true);
		openNotification(
			t("emails.updateEmail"),
			t("emails.updateEmailSuccess"),
			NOTIFICATION_TYPES.SUCCESS,
			6
		);
	};

	const openSaveErrorNotification = (error: any) => {
		openNotification(
			t("emails.error"),
			t("emails.saveDataError"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const handleSave = () => {
		const state = form.getFieldsValue();

		try {
			schema.validateSync(state);

			const obj: PublishUpdateEmailDTO = toDomainModel(
				state,
				selectedFaculties
			);

			setSpinning(true);

			(props.emailId && isEditing
				? updateEmail(props.emailId, obj)
				: publishEmail(obj)
			)
				.then(
					props.emailId
						? openSuccessEditNotificationAndRedirect
						: openSuccessAddNotificationAndRedirect
				)
				.catch(openSaveErrorNotification)
				.finally(() => setSpinning(false));
		} catch (e: any) {
			openNotification(
				t("emails.publishEmailForm.publishEmail"),
				e.errors[0],
				NOTIFICATION_TYPES.ERROR
			);

			setSaveModalVisibility(false);
		}
	};

	return {
		state: {
			form,
			sendStrategyValue,
			recipientRoleValue,
			facultiesList,
			facultySearch,
			departmentsList,
			departmentSearch,
			studyProgramsList,
			studyProgramSearch,
			facultyOptions,
			studyProgramOptions,
			departmentOptions,
			recipientsOptions,
			userSearch,
			academicUsers: academicUsers?.data,
			spinning,
			cancelModalVisibility,
			saveModalVisibility,
		},
		actions: {
			setFacultySearch,
			setDepartmentSearch,
			setStudyProgramSearch,
			setFacultyOptions,
			setStudyProgramOptions,
			setDepartmentOptions,
			setRecipientsOptions,
			setUserSearch,
			setCancelModalVisibility,
			setSaveModalVisibility,
			handleCancel,
			handleSave,
		},
		computed: {
			isEditing,
			email,
			isLoadingAcademicUsers,
			defaultValues,
			yupSync,
		},
	};
};
