/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum PoliJobsRecommendationStatus {
	Pending = "Pending",
	Accepted = "Accepted",
	Invalid = "Invalid",
}

export function PoliJobsRecommendationStatusFromJSON(
	json: any
): PoliJobsRecommendationStatus {
	return PoliJobsRecommendationStatusFromJSONTyped(json, false);
}

export function PoliJobsRecommendationStatusFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): PoliJobsRecommendationStatus {
	return json as PoliJobsRecommendationStatus;
}

export function PoliJobsRecommendationStatusToJSON(
	value?: PoliJobsRecommendationStatus | null
): any {
	return value as any;
}
