import {
	InternshipDocumentAddDTO,
	InternshipDocumentUpdateDTO,
	InternshipDocumentApi,
} from "../Api";
import { getAuthConfiguration } from "./configuration";

const AuthInternshipDocumentApiFactory = () =>
	new InternshipDocumentApi(getAuthConfiguration());

export const getInternshipDocumentForInternshipEnrollment = (
	internshipEnrollmentId: string
) => {
	return AuthInternshipDocumentApiFactory().apiInternshipDocumentGetForEnrollmentInternshipEnrollmentIdGet(
		{ internshipEnrollmentId }
	);
};

export const addInternshipDocument = async (
	internshipDocumentAddDTO: InternshipDocumentAddDTO
) => {
	await AuthInternshipDocumentApiFactory().apiInternshipDocumentAddPost({
		internshipDocumentAddDTO,
	});
};

export const updateInternshipDocument = async (
	id: string,
	internshipDocumentUpdateDTO: InternshipDocumentUpdateDTO
) => {
	await AuthInternshipDocumentApiFactory().apiInternshipDocumentUpdateIdPut({
		id,
		internshipDocumentUpdateDTO,
	});
};

export const generateInternshipDocument = (id: string, type: string) => {
	return AuthInternshipDocumentApiFactory().apiInternshipDocumentGenerateDocumentIdGet(
		{ id, type }
	);
};

export const addInternshipDocumentSignature = async (documentId: string) => {
	await AuthInternshipDocumentApiFactory().apiInternshipDocumentAddSignatureDocumentIdPost(
		{ documentId }
	);
};

export const approveAllDiplomaDocuments = async () => {
	await AuthInternshipDocumentApiFactory().apiInternshipDocumentApproveAllDiplomaDocumentsPut();
};
