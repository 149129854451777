import { DataNode } from "antd/lib/tree";
import { Filter } from "../Api";

export function arrayParamAjust<T>(array?: T[]): T[] | undefined {
	return array && array.length > 0 ? array : undefined;
}

export function filtersToDataNodes(array: Filter[]): DataNode[] {
	return array?.map((x) => ({
		key: x.key!,
		title: x.title!,
		children: Array.from(x.children ?? []).map((y) => ({
			key: y.key!,
			title: y.title!,
		})),
	}));
}

export function removeDiacritics(str: string) {
	return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

export function isEmptyGuid(guid: string) {
	return (
		guid === null ||
		guid === undefined ||
		guid === "00000000-0000-0000-0000-000000000000"
	);
}
