/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum InternshipEngagementType {
	Internship = "Internship",
	BachelorsInternship = "BachelorsInternship",
	MastersInternship = "MastersInternship",
	ResearchInternship = "ResearchInternship",
	Scholarship = "Scholarship",
	Job = "Job",
	SummerSchool = "SummerSchool",
	Event = "Event",
}

export function InternshipEngagementTypeFromJSON(
	json: any
): InternshipEngagementType {
	return InternshipEngagementTypeFromJSONTyped(json, false);
}

export function InternshipEngagementTypeFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): InternshipEngagementType {
	return json as InternshipEngagementType;
}

export function InternshipEngagementTypeToJSON(
	value?: InternshipEngagementType | null
): any {
	return value as any;
}
