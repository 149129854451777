import { StudyProgramsSelectProps } from "./StudyProgramsSelect.types";
import { Form, Select } from "antd";
import styles from "../../../AddInternshipForm.module.scss";
import { useEffect, useState } from "react";
import { InternshipEngagementType } from "../../../../../../Api";
import { useQuery } from "react-query";
import { getFacultiesStudyPrograms } from "../../../../../../utils/reactQueriesConstants";
import { getStudyProgramsForFaculties } from "../../../../../../Requests/student-study-request";
import { useTranslation } from "react-i18next";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../../../../../Notifications/NotificationsUtils";

const { Option } = Select;

export const StudyProgramsSelect = (props: StudyProgramsSelectProps) => {
	const { t } = useTranslation();
	const [studyProgramsByEngagementType, setStudyProgramsByEngagementType] =
		useState<any>([]);
	const [studyProgramSearch, setStudyProgramSearch] = useState("");

	const openGetErrorNotification = (_error: any) => {
		openNotification(
			t("internships.error"),
			t("internships.fetchDataError"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const { data: studyProgramsList } = useQuery(
		[getFacultiesStudyPrograms, props.selectedFaculties, props.defaultFaculty],
		() =>
			(props.selectedFaculties?.length ?? 0) === 0
				? null
				: getStudyProgramsForFaculties(
						props.isProfessor ? [props.defaultFaculty] : props.selectedFaculties
				  ),
		{
			onError: (err) => {
				openGetErrorNotification(err);
			},
			refetchOnWindowFocus: false,
		}
	);

	useEffect(() => {
		if (
			props.engagementTypeValue ===
				InternshipEngagementType.BachelorsInternship ||
			props.engagementTypeValue === InternshipEngagementType.Internship
		) {
			setStudyProgramsByEngagementType(
				studyProgramsList
					?.filter(
						(e) =>
							e.studyCycle === "Bachelor" &&
							(studyProgramSearch === "" ||
								e.name
									?.toLowerCase()
									.includes(studyProgramSearch.toLowerCase()))
					)
					.map((program) => (
						<Option key={"p" + program.id} value={program.id}>
							{program.name}
						</Option>
					))
			);
		} else if (
			props.engagementTypeValue ===
				InternshipEngagementType.MastersInternship ||
			props.engagementTypeValue === InternshipEngagementType.ResearchInternship
		) {
			setStudyProgramsByEngagementType(
				studyProgramsList
					?.filter(
						(e) =>
							e.studyCycle === "Master" &&
							(studyProgramSearch === "" ||
								e.name
									?.toLowerCase()
									.includes(studyProgramSearch.toLowerCase()))
					)
					.map((program) => (
						<Option key={"p" + program.id} value={program.id}>
							{program.name}
						</Option>
					))
			);
		} else {
			setStudyProgramsByEngagementType(
				studyProgramsList
					?.filter(
						(e) =>
							studyProgramSearch === "" ||
							e.name?.toLowerCase().includes(studyProgramSearch.toLowerCase())
					)
					.map((program) => (
						<Option key={"p" + program.id} value={program.id}>
							{program.name}
						</Option>
					))
			);
		}
	}, [props.engagementTypeValue, studyProgramSearch, studyProgramsList]);

	return (
		<Form.Item
			name="studyPrograms"
			label={t("internships.addInternshipForm.studyPrograms") + ":"}
			tooltip={t("internships.addInternshipForm.studyProgramsHelper")}
		>
			<Select
				className={styles.antSelect}
				mode="multiple"
				allowClear
				maxTagCount="responsive"
				filterOption={false}
				onSearch={setStudyProgramSearch}
				style={{ width: "100%", textAlign: "left" }}
				placeholder={t("internships.addInternshipForm.studyPrograms")}
				notFoundContent={t("internships.addInternshipForm.notFoundContent")}
			>
				{studyProgramsByEngagementType}
			</Select>
		</Form.Item>
	);
};
