import {
	StudentPersonalDataApi,
	StudentPersonalDataAddUpdateDTO,
} from "../Api";
import { getAuthConfiguration } from "./configuration";

const AuthStudentPersonalDataApiFactory = () =>
	new StudentPersonalDataApi(getAuthConfiguration());

export const getStudentPersonalDataForStudent = (studentId: string) => {
	return AuthStudentPersonalDataApiFactory().apiStudentPersonalDataGetForStudentStudentIdGet(
		{ studentId }
	);
};

export const addStudentPersonalData = async (
	studentPersonalDataAddUpdateDTO: StudentPersonalDataAddUpdateDTO
) => {
	await AuthStudentPersonalDataApiFactory().apiStudentPersonalDataAddPost({
		studentPersonalDataAddUpdateDTO,
	});
};

export const updateStudentPersonalData = async (
	id: string,
	studentPersonalDataAddUpdateDTO: StudentPersonalDataAddUpdateDTO
) => {
	await AuthStudentPersonalDataApiFactory().apiStudentPersonalDataUpdateIdPut({
		id,
		studentPersonalDataAddUpdateDTO,
	});
};
