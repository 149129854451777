/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
	InternshipDocumentSignature,
	InternshipDocumentSignatureFromJSON,
	InternshipDocumentSignatureFromJSONTyped,
	InternshipDocumentSignatureToJSON,
} from "./InternshipDocumentSignature";
import {
	InternshipDocumentState,
	InternshipDocumentStateFromJSON,
	InternshipDocumentStateFromJSONTyped,
	InternshipDocumentStateToJSON,
} from "./InternshipDocumentState";
import {
	InternshipEnrollment,
	InternshipEnrollmentFromJSON,
	InternshipEnrollmentFromJSONTyped,
	InternshipEnrollmentToJSON,
} from "./InternshipEnrollment";

/**
 *
 * @export
 * @interface InternshipDocument
 */
export interface InternshipDocument {
	/**
	 *
	 * @type {string}
	 * @memberof InternshipDocument
	 */
	id?: string;
	/**
	 *
	 * @type {Date}
	 * @memberof InternshipDocument
	 */
	createdAt?: Date;
	/**
	 *
	 * @type {Date}
	 * @memberof InternshipDocument
	 */
	updatedAt?: Date;
	/**
	 *
	 * @type {string}
	 * @memberof InternshipDocument
	 */
	bibliography?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof InternshipDocument
	 */
	studentGroup?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof InternshipDocument
	 */
	signingCoordinatorId?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof InternshipDocument
	 */
	signingDeanId?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof InternshipDocument
	 */
	signingDepartmentDirectorId?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof InternshipDocument
	 */
	signingStudyProgramCoordinatorId?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof InternshipDocument
	 */
	internshipEnrollmentId?: string;
	/**
	 *
	 * @type {InternshipEnrollment}
	 * @memberof InternshipDocument
	 */
	internshipEnrollment?: InternshipEnrollment;
	/**
	 *
	 * @type {InternshipDocumentState}
	 * @memberof InternshipDocument
	 */
	state?: InternshipDocumentState;
	/**
	 *
	 * @type {Array<InternshipDocumentSignature>}
	 * @memberof InternshipDocument
	 */
	internshipDocumentSignatures?: Array<InternshipDocumentSignature> | null;
}

export function InternshipDocumentFromJSON(json: any): InternshipDocument {
	return InternshipDocumentFromJSONTyped(json, false);
}

export function InternshipDocumentFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): InternshipDocument {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		id: !exists(json, "id") ? undefined : json["id"],
		createdAt: !exists(json, "createdAt")
			? undefined
			: new Date(json["createdAt"]),
		updatedAt: !exists(json, "updatedAt")
			? undefined
			: new Date(json["updatedAt"]),
		bibliography: !exists(json, "bibliography")
			? undefined
			: json["bibliography"],
		studentGroup: !exists(json, "studentGroup")
			? undefined
			: json["studentGroup"],
		signingCoordinatorId: !exists(json, "signingCoordinatorId")
			? undefined
			: json["signingCoordinatorId"],
		signingDeanId: !exists(json, "signingDeanId")
			? undefined
			: json["signingDeanId"],
		signingDepartmentDirectorId: !exists(json, "signingDepartmentDirectorId")
			? undefined
			: json["signingDepartmentDirectorId"],
		signingStudyProgramCoordinatorId: !exists(
			json,
			"signingStudyProgramCoordinatorId"
		)
			? undefined
			: json["signingStudyProgramCoordinatorId"],
		internshipEnrollmentId: !exists(json, "internshipEnrollmentId")
			? undefined
			: json["internshipEnrollmentId"],
		internshipEnrollment: !exists(json, "internshipEnrollment")
			? undefined
			: InternshipEnrollmentFromJSON(json["internshipEnrollment"]),
		state: !exists(json, "state")
			? undefined
			: InternshipDocumentStateFromJSON(json["state"]),
		internshipDocumentSignatures: !exists(json, "internshipDocumentSignatures")
			? undefined
			: json["internshipDocumentSignatures"] === null
			? null
			: (json["internshipDocumentSignatures"] as Array<any>).map(
					InternshipDocumentSignatureFromJSON
			  ),
	};
}

export function InternshipDocumentToJSON(
	value?: InternshipDocument | null
): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		id: value.id,
		createdAt:
			value.createdAt === undefined ? undefined : value.createdAt.toISOString(),
		updatedAt:
			value.updatedAt === undefined ? undefined : value.updatedAt.toISOString(),
		bibliography: value.bibliography,
		studentGroup: value.studentGroup,
		signingCoordinatorId: value.signingCoordinatorId,
		signingDeanId: value.signingDeanId,
		signingDepartmentDirectorId: value.signingDepartmentDirectorId,
		signingStudyProgramCoordinatorId: value.signingStudyProgramCoordinatorId,
		internshipEnrollmentId: value.internshipEnrollmentId,
		internshipEnrollment: InternshipEnrollmentToJSON(
			value.internshipEnrollment
		),
		state: InternshipDocumentStateToJSON(value.state),
		internshipDocumentSignatures:
			value.internshipDocumentSignatures === undefined
				? undefined
				: value.internshipDocumentSignatures === null
				? null
				: (value.internshipDocumentSignatures as Array<any>).map(
						InternshipDocumentSignatureToJSON
				  ),
	};
}
