import { Avatar, Button } from "antd";
import OfferActions from "./OfferActions";
import {
	isUserLogged,
	useIsCompany,
	useIsProfessor,
} from "../../utils/utilFunctions";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { ResearchProposalDTO, ResearchProposalType, Role } from "../../Api";
import { useTranslation } from "react-i18next";
import { PropsWithChildren } from "react";
import { OfferListProps } from "../../PropsLists/OfferListProps";
import { useOfferListController } from "./OfferList.controller";

const OffersList = (props: PropsWithChildren<OfferListProps>) => {
	const { t } = useTranslation();
	const isProfessor = useIsProfessor();
	const isCompany = useIsCompany();

	const { actions } = useOfferListController(props);

	return (
		<>
			{props.loading && !props.data && (
				<div className="w-full py-10 text-center">Loading</div>
			)}
			{props.data?.data.length === 0 && (
				<div className="w-full py-10 text-center">
					{t("tableText.noOffers")}
				</div>
			)}

			<div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 xxl:grid-cols-4 gap-4">
				{props.data?.data.map((item: ResearchProposalDTO) => (
					<div
						className="bg-white rounded-md shadow-md p-4 flex flex-col gap-4"
						key={item.id}
					>
						<div className="flex justify-between gap-6">
							<div className="flex flex-col gap-2">
								<Link
									to={{
										pathname: `/profil`,
										state: {
											id: isProfessor ? item.professorId : item.companyId,
											userType:
												item.type === ResearchProposalType.ByProfessor
													? Role.Professor
													: Role.Company, // TBD check here
											filters: props.checkedKeys,
											searchTerm: props.searchTerm,
											sortField: props.sortField,
											sortDirection: props.sortDirection,
											currentPage: props.currentPage,
											pageSize: props.pageSize,
											activeTab: props.activeTab,
											origin: props.location.pathname,
										},
									}}
								>
									{item.type === ResearchProposalType.ByCompany && (
										<Avatar src={item.recruiterAvatar} />
									)}
									{item.type === ResearchProposalType.ByProfessor && (
										<span className="text-secondary">
											{item.type === ResearchProposalType.ByProfessor
												? t("abbreviations." + item.professionalTitle) + " "
												: ""}{" "}
											{item.recruiterName}
										</span>
									)}
								</Link>

								<Link
									to={{
										pathname: "/propunere-cercetare/" + item.id,
										search: "?type=" + item.type,
										state: {
											id: item.id,
											type: item.type,
											filters: props.checkedKeys,
											searchTerm: props.searchTerm,
											sortField: props.sortField,
											sortDirection: props.sortDirection,
											currentPage: props.currentPage,
											pageSize: props.pageSize,
											activeTab: props.activeTab,
											origin: props.location.pathname,
										},
									}}
									onClick={() => actions.updateViewCount(item.id, item.type)}
									className="text-lg text-secondary font-bold flex items-center gap-2 line-clamp-2 overflow-ellipsis"
								>
									<FontAwesomeIcon icon={solid("arrow-up-right-from-square")} />

									{item.researchProposalName}
								</Link>
							</div>
						</div>

						{item.keywords && item.keywords.length > 0 && (
							<div className="flex flex-wrap gap-1 items-center">
								{item.keywords?.map((skill) => (
									<span
										className="bg-primary rounded-md px-2 py-1 text-white"
										key={skill}
									>
										{skill}
									</span>
								))}
							</div>
						)}

						<div className="line-clamp-5 overflow-ellipsis">
							{item.shortDescription}
						</div>

						<OfferActions item={item} activeTab={props.activeTab} />

						<div className="mt-auto">
							{isUserLogged() && (
								<>
									{(isProfessor || isCompany) && props.activeTab === "1" ? (
										<Button title={t("internships.edit")} className="w-full">
											<Link
												to={{
													pathname: "/editare-propunere-cercetare/" + item.id,
													state: {
														activeTab: props.activeTab,
														currentPage: props.currentPage,
														pageSize: props.pageSize,
														filters: props.checkedKeys,
														searchTerm: props.searchTerm,
														sortDirection: props.sortDirection,
														sortField: props.sortField,
													},
												}}
											>
												<FontAwesomeIcon icon={solid("edit")} /> &nbsp;
												{t("internships.edit")}
											</Link>
										</Button>
									) : null}
								</>
							)}
						</div>
					</div>
				))}
			</div>
		</>
	);
};

export default OffersList;
