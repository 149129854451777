import { Button, Col, List, Row, Spin } from "antd";
import { useState } from "react";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useQuery, useQueryClient } from "react-query";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../../Notifications/NotificationsUtils";
import { getNewsletterEmailData } from "../../../utils/reactQueriesConstants";
import CustomButton from "../../../CustomComponents/CustomButton";
import { theme } from "../../../theme";
import styles from "./NewsletterEmailInfoView.module.scss";
import ConfirmationModal from "../../../Containers/ConfirmationModal";
import {
	deleteNewsletterEmail,
	getNewsletterEmailById,
	toggleEmailExpeditionEnabled,
} from "../../../Requests/newsletter-requests";
import { EmailRecipientsTable } from "./components/tables/EmailRecipientsTable";
import EmailActions from "../NewsletterEmailsView/components/listItems/EmailListItem/EmailActions";
import { ArrowDownOutlined } from "@ant-design/icons";

export const NewsletterEmailInfoView = () => {
	const { t } = useTranslation();
	const { id } = useParams<{ id: string }>();
	const location = useLocation<
		| {
				origin: string;
				filters: any;
				searchTerm: string;
				sortField: any;
				sortDirection: any;
				currentPage: any;
				pageSize: any;
				activeTab: string;
		  }
		| null
		| undefined
	>();
	const [spinning, setSpinning] = useState(false);
	const history = useHistory();
	const queryClient = useQueryClient();
	const [
		updateEmailExpeditionModalVisibility,
		setUpdateEmailExpeditionModalVisibility,
	] = useState(false);
	const [deleteModalVisibility, setDeleteModalVisibility] = useState(false);

	if (!id) {
		history.push("/");
	}

	const openGetErrorNotification = (_error: any) => {
		openNotification(
			t("internships.error"),
			t("internships.fetchDataError"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const { data: email } = useQuery(
		[getNewsletterEmailData, id],
		() => getNewsletterEmailById(id),
		{
			onError: (err) => {
				history.push("/404");
				openGetErrorNotification(err);
			},
		}
	);

	const handleToggleExpeditionEnabled = () => {
		toggleEmailExpeditionEnabled(email?.id!)
			.then(async () => {
				await queryClient.invalidateQueries(getNewsletterEmailData);
				openNotification(
					t("emails.updateEmail"),
					t("emails.updateExpeditionStatusSuccess"),
					NOTIFICATION_TYPES.SUCCESS
				);
			})
			.catch((_error: any) => {
				openNotification(
					t("emails.updateEmail"),
					t("emails.updateExpeditionStatusError"),
					NOTIFICATION_TYPES.ERROR
				);
			})
			.finally(() => {
				setSpinning(false);
				setUpdateEmailExpeditionModalVisibility(false);
			});
	};

	const handleDelete = () => {
		deleteNewsletterEmail(email?.id!)
			.then(async () => {
				openNotification(
					t("emails.deleteEmail"),
					t("emails.deleteEmailSuccess"),
					NOTIFICATION_TYPES.SUCCESS
				);
			})
			.catch((_error: any) => {
				openNotification(
					t("emails.deleteEmails"),
					t("emails.deleteEmailsError"),
					NOTIFICATION_TYPES.ERROR
				);
			})
			.finally(() => {
				setSpinning(false);
				setDeleteModalVisibility(false);

				let state = {
					searchTerm: location.state?.searchTerm,
					sortField: location.state?.sortField,
					sortDirection: location.state?.sortDirection,
					currentPage: location.state?.currentPage,
					pageSize: location.state?.pageSize,
				};

				history.push(location.state!.origin, state);
			});
	};

	return (
		<Spin size="large" spinning={!email} tip={t("internships.loading")}>
			<div className="flex flex-col items-start container mx-auto px-4 py-10 gap-8">
				<div className="flex items-center gap-2">
					{location.state && (
						<Link
							to={{
								pathname: location.state.origin,
								state: {
									searchTerm: location.state.searchTerm,
									sortField: location.state.sortField,
									sortDirection: location.state.sortDirection,
									currentPage: location.state.currentPage,
									pageSize: location.state.pageSize,
								},
							}}
						>
							<ArrowDownOutlined className="rotate-90 text-4xl text-primary" />
						</Link>
					)}

					<div className={styles.title}>{email?.subject}</div>
				</div>

				<div className="flex flex-col-reverse xl:flex-row gap-10 w-full lg:items-start">
					<div className="flex flex-col gap-6 basis-full">
						<div className="text-lg font-bold">
							{t("emails.publishEmailForm.body")}
						</div>

						<div
							className={styles.description}
							dangerouslySetInnerHTML={{ __html: email?.body || "" }}
						/>
					</div>

					<div className="flex flex-col gap-10 bg-white rounded-md shadow-md p-6 w-full xl:max-w-lg">
						<div className="flex gap-4 flex-wrap xl:flex-nowrap">
							{location.state && (
								<>
									<Button
										type="primary"
										title={t("emails.edit")}
										icon={<FontAwesomeIcon icon={solid("edit")} />}
									>
										<Link
											to={{
												pathname: "/editare-email/" + email?.id,
												state: {
													currentPage: location.state.currentPage,
													pageSize: location.state.pageSize,
													searchTerm: location.state.searchTerm,
													sortDirection: location.state.sortDirection,
													sortField: location.state.sortField,
												},
											}}
										>
											{t("emails.edit")}
										</Link>
									</Button>

									{email?.currentRecipientsCount! > 0 && (
										<div>
											<Button
												title={
													email?.expeditionEnabled
														? t("emails.stopExpedition")
														: t("emails.restartExpedition")
												}
												onClick={() =>
													setUpdateEmailExpeditionModalVisibility(true)
												}
												icon={
													<FontAwesomeIcon
														icon={
															email?.expeditionEnabled
																? solid("stop")
																: solid("play")
														}
													/>
												}
											>
												{email?.expeditionEnabled
													? t("emails.stopExpedition")
													: t("emails.restartExpedition")}
											</Button>

											<ConfirmationModal
												modalText={
													email?.expeditionEnabled
														? t("emails.stopEmailExpeditionMessage")
														: t("emails.restartEmailExpeditionMessage")
												}
												handleFunction={() => {
													handleToggleExpeditionEnabled();
												}}
												modalVisibility={updateEmailExpeditionModalVisibility}
												title=""
												changeModalVisibility={() =>
													setUpdateEmailExpeditionModalVisibility(false)
												}
												spinning={spinning}
											/>
										</div>
									)}

									<div>
										<Button
											danger
											title={t("emails.deleteEmail")}
											onClick={() => setDeleteModalVisibility(true)}
											icon={<FontAwesomeIcon icon={solid("trash")} />}
										>
											{t("emails.deleteEmail")}
										</Button>

										<ConfirmationModal
											modalText={t("emails.deleteEmailMessage")}
											handleFunction={() => {
												handleDelete();
											}}
											modalVisibility={deleteModalVisibility}
											title=""
											changeModalVisibility={() =>
												setDeleteModalVisibility(false)
											}
											spinning={spinning}
										/>
									</div>
								</>
							)}
						</div>

						<EmailActions item={email!} />
					</div>
				</div>

				<div className={styles.subtitle}>{t("emails.expeditionReport")}</div>
				<EmailRecipientsTable emailId={email?.id} />
			</div>
		</Spin>
	);
};
