import { PropsWithChildren, useState } from "react";
import {
	InternsForEmployerDTO,
	ResearchPartnersForProfessorOrCompanyDTO,
	ResearchProposalEnrollmentStatus,
	ResearchProposalEnrollmentType,
	Role,
} from "../../Api";
import {
	acceptApplicant,
	rejectApplicant,
	updateIsResearchProposalEnrollmentViewed,
} from "../../Requests/research-proposal-requests";
import { Table, Space } from "antd";
import styles from "./ApplicantsList.module.scss";
import { Link } from "react-router-dom";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../Notifications/NotificationsUtils";
import ConfirmationModal from "../../Containers/ConfirmationModal";
import { useTranslation } from "react-i18next";
import { theme } from "../../theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useQueryClient } from "react-query";
import { StatusCodes } from "http-status-codes";
import CustomButton from "../../CustomComponents/CustomButton";
import {
	getAllApplicationTypesCount,
	getInterns,
	getTraineesFilters,
} from "../../utils/reactQueriesConstants";
import { ResearchProposalProps } from "../../PropsLists/ResearchProposalProps";

const ApplicantsList = (props: PropsWithChildren<ResearchProposalProps>) => {
	const { t } = useTranslation();
	const [acceptModalVisibility, setAcceptModalVisibility] = useState(false);
	const [rejectModalVisibility, setRejectModalVisibility] = useState(false);
	const [selectedTrainee, setSelectedTrainee] = useState<InternsForEmployerDTO>(
		{}
	);
	const [spinning, setSpinning] = useState(false);

	let locale = {
		emptyText: t("tableText.noApplicants"),
	};

	const updateViewApplication = (id: any, viewed: boolean) => {
		updateIsResearchProposalEnrollmentViewed(id, viewed).then(async () => {
			await queryClient.invalidateQueries(getInterns);
			await queryClient.invalidateQueries(getTraineesFilters);
		});
	};

	const columns = [
		{
			title: t("account.name"),
			key: "name",
			dataIndex: "name",
			fixed: "left" as "left",
			// sorter: (
			// 	a: ResearchPartnersForProfessorOrCompanyDTO,
			// 	b: ResearchPartnersForProfessorOrCompanyDTO
			// ) => {
			// 	if (!a.applicantName || !b.applicantName) return -1;
			// 	return a.applicantName.localeCompare(b.applicantName);
			// },
			render: (
				text: string | undefined,
				record: ResearchPartnersForProfessorOrCompanyDTO
			) => {
				return (
					<Link
						key="profile"
						to={{
							pathname: `/profil`,
							state: {
								id: record.applicantId,
								userType:
									record.enrollmentType ===
									ResearchProposalEnrollmentType.ByProfessor
										? Role.Professor
										: Role.Company, // TBD check here
								filters: props.checkedKeys,
								searchTerm: props.searchTerm,
								sortField: props.sortField,
								sortDirection: props.sortDirection,
								currentPage: props.currentPage,
								pageSize: props.pageSize,
								activeTab: props.activeTab,
								origin: props.location.pathname,
							},
						}}
					>
						<b>{record.applicantName}</b>
					</Link>
				);
			},
		},
		{
			title: t("applicants.internshipName"),
			key: "researchProposalName",
			dataIndex: "researchProposalName",
			// sorter: (
			// 	a: ResearchPartnersForProfessorOrCompanyDTO,
			// 	b: ResearchPartnersForProfessorOrCompanyDTO
			// ) => {
			// 	if (!a.researchProposalName || !b.researchProposalName) return -1;
			// 	return a.researchProposalName.localeCompare(b.researchProposalName);
			// },
		},
		{
			title: t("students.email"),
			dataIndex: "email",
			key: "email",
			// sorter: (
			// 	a: ResearchPartnersForProfessorOrCompanyDTO,
			// 	b: ResearchPartnersForProfessorOrCompanyDTO
			// ) => {
			// 	if (!a.applicantEmail || !b.applicantEmail) return -1;
			// 	return a.applicantEmail.localeCompare(b.applicantEmail);
			// },
			render: (
				text: string | undefined,
				record: ResearchPartnersForProfessorOrCompanyDTO
			) => <span>{record.applicantEmail}</span>,
			hidden: window.innerWidth < 550,
		},
		{
			title: t("research.status"),
			dataIndex: "status",
			key: "status",
			// sorter: (
			// 	a: ResearchPartnersForProfessorOrCompanyDTO,
			// 	b: ResearchPartnersForProfessorOrCompanyDTO
			// ) => {
			// 	if (!a.status || !b.status) return -1;
			// 	return a.status.localeCompare(b.status);
			// },
			render: (
				text: string | undefined,
				record: ResearchPartnersForProfessorOrCompanyDTO
			) => <span>{t("research.status" + record.status)}</span>,
			hidden: window.innerWidth < 550,
		},
		{
			title: t("students.actions"),
			key: "actions",
			dataIndex: "actions",
			fixed: "right" as "right",
			width: 85,
			render: (
				text: string | undefined,
				record: ResearchPartnersForProfessorOrCompanyDTO
			) => {
				return (
					<div
						className={styles.modalContainer}
						key={"actions-" + record.researchProposalEnrollmentId}
					>
						<Space>
							{record.status ===
								ResearchProposalEnrollmentStatus.WaitingForProposer && (
								<div className={styles.modalBtnContainer}>
									<CustomButton
										fontSize={"0.9rem"}
										style={{
											background: "transparent",
											border: "none",
											outline: "none",
											color: theme.black,
											boxShadow: "none",
											padding: 0,
											marginTop: 0,
										}}
										onClick={() => {
											setSelectedTrainee(record);
											setAcceptModalVisibility(true);
										}}
										title={t("students.acceptApplicant")}
										icon={
											<FontAwesomeIcon
												icon={solid("circle-check")}
												style={{ fontSize: "15px" }}
											/>
										}
									/>
									<ConfirmationModal
										modalText={
											record.availablePositions !== 0
												? t("students.acceptStudentMessage") +
												  selectedTrainee.name +
												  t("students.acceptStudentMessage2")
												: t("students.acceptStudentExceeded") +
												  selectedTrainee.internshipName +
												  t("students.acceptStudentExceeded2")
										}
										handleFunction={() => accept(selectedTrainee)}
										modalVisibility={acceptModalVisibility}
										title={
											record.availablePositions !== 0
												? ""
												: t("students.acceptStudentExceededTitle")
										}
										changeModalVisibility={() =>
											setAcceptModalVisibility(false)
										}
										spinning={spinning}
									/>
								</div>
							)}
							{record.status ===
								ResearchProposalEnrollmentStatus.WaitingForProposer && (
								<div className={styles.modalBtnContainer}>
									<CustomButton
										fontSize={"0.9rem"}
										style={{
											background: "transparent",
											border: "none",
											outline: "none",
											color: theme.black,
											boxShadow: "none",
											padding: 0,
											marginTop: 0,
										}}
										onClick={() => {
											setSelectedTrainee(record);
											setRejectModalVisibility(true);
										}}
										title={t("students.rejectApplicant")}
										icon={
											<FontAwesomeIcon
												icon={solid("ban")}
												style={{ fontSize: "15px" }}
											/>
										}
									/>
									<ConfirmationModal
										modalText={
											t("students.rejectStudentMessage") +
											selectedTrainee.name +
											t("students.rejectStudentMessage2")
										}
										handleFunction={() => reject(selectedTrainee)}
										modalVisibility={rejectModalVisibility}
										title=""
										changeModalVisibility={() =>
											setRejectModalVisibility(false)
										}
										spinning={spinning}
									/>
								</div>
							)}

							{record.isEnrollmentViewed &&
								record.status ===
									ResearchProposalEnrollmentStatus.WaitingForProposer && (
									<CustomButton
										fontSize={"0.9rem"}
										style={{
											background: "transparent",
											border: "none",
											outline: "none",
											color: theme.black,
											boxShadow: "none",
											padding: 0,
											marginTop: 0,
										}}
										onClick={() => {
											updateViewApplication(
												record.researchProposalEnrollmentId,
												false
											);
										}}
										title={t("students.unviewApplication")}
										icon={
											<FontAwesomeIcon
												icon={solid("eye-slash")}
												style={{ fontSize: "15px" }}
											/>
										}
									/>
								)}
						</Space>
					</div>
				);
			},
		},
	].filter((item) => !item.hidden);

	const queryClient = useQueryClient();

	const invalidateApplicantsQueries = async () => {
		await queryClient.invalidateQueries(getInterns);
		await queryClient.invalidateQueries(getTraineesFilters);
		await queryClient.invalidateQueries(getAllApplicationTypesCount);
	};

	const accept = (item: any) => {
		setSpinning(true);

		acceptApplicant(item.researchProposalEnrollmentId)
			.then(async () => {
				await invalidateApplicantsQueries();
				openNotification(
					t("students.acceptApplicant"),
					t("students.successAcceptApplicantMessage"),
					NOTIFICATION_TYPES.SUCCESS
				);
			})
			.catch((ex) => {
				openNotification(
					t("students.acceptApplicant"),
					t(
						ex.status === StatusCodes.FORBIDDEN
							? "students.errorAcceptApplicantExceededMessage"
							: "students.errorAcceptApplicantMessage"
					),
					NOTIFICATION_TYPES.ERROR
				);
			})
			.finally(() => {
				setSpinning(false);
				setAcceptModalVisibility(false);
			});
	};

	const reject = (item: any) => {
		setSpinning(true);

		rejectApplicant(item.researchProposalEnrollmentId)
			.then(async () => {
				await invalidateApplicantsQueries();
				openNotification(
					t("students.rejectApplicant"),
					t("students.successRejectApplicantMessage"),
					NOTIFICATION_TYPES.SUCCESS
				);
			})
			.catch((_error) => {
				openNotification(
					t("students.rejectApplicant"),
					t("students.errorRejectApplicantMessage"),
					NOTIFICATION_TYPES.ERROR
				);
			})
			.finally(() => {
				setSpinning(false);
				setRejectModalVisibility(false);
			});
	};

	const getKey = (record: any): string => {
		return record.researchProposalEnrollmentId + record.researchProposalId;
	};

	return (
		<Table
			locale={locale}
			columns={columns}
			dataSource={props.data?.data ?? []}
			pagination={false}
			scroll={{ x: "calc(700px + 50%)", y: 420 }}
			rowKey={getKey}
		/>
	);
};

export default ApplicantsList;
