/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
	PoliJobsRecommendationStatus,
	PoliJobsRecommendationStatusFromJSON,
	PoliJobsRecommendationStatusFromJSONTyped,
	PoliJobsRecommendationStatusToJSON,
} from "./PoliJobsRecommendationStatus";
import {
	StudentYearEnum,
	StudentYearEnumFromJSON,
	StudentYearEnumFromJSONTyped,
	StudentYearEnumToJSON,
} from "./StudentYearEnum";

/**
 *
 * @export
 * @interface PoliJobsPotentialCandidateDTO
 */
export interface PoliJobsPotentialCandidateDTO {
	/**
	 *
	 * @type {string}
	 * @memberof PoliJobsPotentialCandidateDTO
	 */
	id?: string;
	/**
	 *
	 * @type {string}
	 * @memberof PoliJobsPotentialCandidateDTO
	 */
	candidateId?: string;
	/**
	 *
	 * @type {string}
	 * @memberof PoliJobsPotentialCandidateDTO
	 */
	candidateName?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof PoliJobsPotentialCandidateDTO
	 */
	candidateFacultyNameEn?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof PoliJobsPotentialCandidateDTO
	 */
	candidateFacultyNameRo?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof PoliJobsPotentialCandidateDTO
	 */
	candidateStudyProgram?: string | null;
	/**
	 *
	 * @type {StudentYearEnum}
	 * @memberof PoliJobsPotentialCandidateDTO
	 */
	candidateYear?: StudentYearEnum;
	/**
	 *
	 * @type {string}
	 * @memberof PoliJobsPotentialCandidateDTO
	 */
	candidateEmail?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof PoliJobsPotentialCandidateDTO
	 */
	candidatePhone?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof PoliJobsPotentialCandidateDTO
	 */
	candidateCvFileName?: string | null;
	/**
	 *
	 * @type {boolean}
	 * @memberof PoliJobsPotentialCandidateDTO
	 */
	hasPoliJobsContactDetails?: boolean;
	/**
	 *
	 * @type {number}
	 * @memberof PoliJobsPotentialCandidateDTO
	 */
	matchingPercentage?: number;
	/**
	 *
	 * @type {PoliJobsRecommendationStatus}
	 * @memberof PoliJobsPotentialCandidateDTO
	 */
	status?: PoliJobsRecommendationStatus;
}

export function PoliJobsPotentialCandidateDTOFromJSON(
	json: any
): PoliJobsPotentialCandidateDTO {
	return PoliJobsPotentialCandidateDTOFromJSONTyped(json, false);
}

export function PoliJobsPotentialCandidateDTOFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): PoliJobsPotentialCandidateDTO {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		id: !exists(json, "id") ? undefined : json["id"],
		candidateId: !exists(json, "candidateId") ? undefined : json["candidateId"],
		candidateName: !exists(json, "candidateName")
			? undefined
			: json["candidateName"],
		candidateFacultyNameEn: !exists(json, "candidateFacultyNameEn")
			? undefined
			: json["candidateFacultyNameEn"],
		candidateFacultyNameRo: !exists(json, "candidateFacultyNameRo")
			? undefined
			: json["candidateFacultyNameRo"],
		candidateStudyProgram: !exists(json, "candidateStudyProgram")
			? undefined
			: json["candidateStudyProgram"],
		candidateYear: !exists(json, "candidateYear")
			? undefined
			: StudentYearEnumFromJSON(json["candidateYear"]),
		candidateEmail: !exists(json, "candidateEmail")
			? undefined
			: json["candidateEmail"],
		candidatePhone: !exists(json, "candidatePhone")
			? undefined
			: json["candidatePhone"],
		candidateCvFileName: !exists(json, "candidateCvFileName")
			? undefined
			: json["candidateCvFileName"],
		hasPoliJobsContactDetails: !exists(json, "hasPoliJobsContactDetails")
			? undefined
			: json["hasPoliJobsContactDetails"],
		matchingPercentage: !exists(json, "matchingPercentage")
			? undefined
			: json["matchingPercentage"],
		status: !exists(json, "status")
			? undefined
			: PoliJobsRecommendationStatusFromJSON(json["status"]),
	};
}

export function PoliJobsPotentialCandidateDTOToJSON(
	value?: PoliJobsPotentialCandidateDTO | null
): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		id: value.id,
		candidateId: value.candidateId,
		candidateName: value.candidateName,
		candidateFacultyNameEn: value.candidateFacultyNameEn,
		candidateFacultyNameRo: value.candidateFacultyNameRo,
		candidateStudyProgram: value.candidateStudyProgram,
		candidateYear: StudentYearEnumToJSON(value.candidateYear),
		candidateEmail: value.candidateEmail,
		candidatePhone: value.candidatePhone,
		candidateCvFileName: value.candidateCvFileName,
		hasPoliJobsContactDetails: value.hasPoliJobsContactDetails,
		matchingPercentage: value.matchingPercentage,
		status: PoliJobsRecommendationStatusToJSON(value.status),
	};
}
