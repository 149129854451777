import { useState } from "react";
import { Redirect, useParams } from "react-router-dom";
import { AddEmailForm } from "../Forms/AddEmailForm";

export const PublishEmailFormPage = (props: { location: any }) => {
	const [redirect, setRedirect] = useState(false);
	const { emailId } = useParams<{ emailId?: string }>();

	if (redirect) {
		return (
			<Redirect
				to={{
					pathname: "/newsletter",
					state: {
						searchTerm: props.location.state?.searchTerm,
						sortField: props.location.state?.sortField,
						sortDirection: props.location.state?.sortDirection,
						currentPage: props.location.state?.currentPage,
						pageSize: props.location.state?.pageSize,
					},
				}}
			/>
		);
	} else {
		return (
			<AddEmailForm
				location={props.location}
				setRedirect={setRedirect}
				emailId={emailId}
			/>
		);
	}
};
