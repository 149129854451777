/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
	FacultySettingsAddDTO,
	FacultySettingsAddDTOFromJSON,
	FacultySettingsAddDTOToJSON,
	FacultySettingsDTO,
	FacultySettingsDTOFromJSON,
	FacultySettingsDTOToJSON,
} from "../models";

export interface ApiFacultySettingsAddPostRequest {
	facultySettingsAddDTO?: FacultySettingsAddDTO;
}

export interface ApiFacultySettingsUpdatePostRequest {
	facultySettingsAddDTO?: FacultySettingsAddDTO;
}

/**
 *
 */
export class FacultySettingsApi extends runtime.BaseAPI {
	/**
	 */
	async apiFacultySettingsAddPostRaw(
		requestParameters: ApiFacultySettingsAddPostRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<void>> {
		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		headerParameters["Content-Type"] = "application/json";

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/FacultySettings/Add`,
				method: "POST",
				headers: headerParameters,
				query: queryParameters,
				body: FacultySettingsAddDTOToJSON(
					requestParameters.facultySettingsAddDTO
				),
			},
			initOverrides
		);

		return new runtime.VoidApiResponse(response);
	}

	/**
	 */
	async apiFacultySettingsAddPost(
		requestParameters: ApiFacultySettingsAddPostRequest = {},
		initOverrides?: RequestInit
	): Promise<void> {
		await this.apiFacultySettingsAddPostRaw(requestParameters, initOverrides);
	}

	/**
	 */
	async apiFacultySettingsGetGetRaw(
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<FacultySettingsDTO>> {
		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/FacultySettings/Get`,
				method: "GET",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			FacultySettingsDTOFromJSON(jsonValue)
		);
	}

	/**
	 */
	async apiFacultySettingsGetGet(
		initOverrides?: RequestInit
	): Promise<FacultySettingsDTO> {
		const response = await this.apiFacultySettingsGetGetRaw(initOverrides);
		return await response.value();
	}

	/**
	 */
	async apiFacultySettingsUpdatePostRaw(
		requestParameters: ApiFacultySettingsUpdatePostRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<void>> {
		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		headerParameters["Content-Type"] = "application/json";

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/FacultySettings/Update`,
				method: "POST",
				headers: headerParameters,
				query: queryParameters,
				body: FacultySettingsAddDTOToJSON(
					requestParameters.facultySettingsAddDTO
				),
			},
			initOverrides
		);

		return new runtime.VoidApiResponse(response);
	}

	/**
	 */
	async apiFacultySettingsUpdatePost(
		requestParameters: ApiFacultySettingsUpdatePostRequest = {},
		initOverrides?: RequestInit
	): Promise<void> {
		await this.apiFacultySettingsUpdatePostRaw(
			requestParameters,
			initOverrides
		);
	}
}
