/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
	AcademicUser,
	AcademicUserFromJSON,
	AcademicUserFromJSONTyped,
	AcademicUserToJSON,
} from "./AcademicUser";
import {
	InternshipEnrollment,
	InternshipEnrollmentFromJSON,
	InternshipEnrollmentFromJSONTyped,
	InternshipEnrollmentToJSON,
} from "./InternshipEnrollment";
import {
	PracticeConventionSignature,
	PracticeConventionSignatureFromJSON,
	PracticeConventionSignatureFromJSONTyped,
	PracticeConventionSignatureToJSON,
} from "./PracticeConventionSignature";
import {
	PracticeConventionState,
	PracticeConventionStateFromJSON,
	PracticeConventionStateFromJSONTyped,
	PracticeConventionStateToJSON,
} from "./PracticeConventionState";

/**
 *
 * @export
 * @interface PracticeConvention
 */
export interface PracticeConvention {
	/**
	 *
	 * @type {string}
	 * @memberof PracticeConvention
	 */
	id?: string;
	/**
	 *
	 * @type {Date}
	 * @memberof PracticeConvention
	 */
	createdAt?: Date;
	/**
	 *
	 * @type {Date}
	 * @memberof PracticeConvention
	 */
	updatedAt?: Date;
	/**
	 *
	 * @type {number}
	 * @memberof PracticeConvention
	 */
	number?: number;
	/**
	 *
	 * @type {string}
	 * @memberof PracticeConvention
	 */
	internshipEnrollmentId?: string;
	/**
	 *
	 * @type {PracticeConventionState}
	 * @memberof PracticeConvention
	 */
	state?: PracticeConventionState;
	/**
	 *
	 * @type {string}
	 * @memberof PracticeConvention
	 */
	supervisorId?: string | null;
	/**
	 *
	 * @type {InternshipEnrollment}
	 * @memberof PracticeConvention
	 */
	internshipEnrollment?: InternshipEnrollment;
	/**
	 *
	 * @type {AcademicUser}
	 * @memberof PracticeConvention
	 */
	supervisor?: AcademicUser;
	/**
	 *
	 * @type {Array<PracticeConventionSignature>}
	 * @memberof PracticeConvention
	 */
	practiceConventionSignatures?: Array<PracticeConventionSignature> | null;
}

export function PracticeConventionFromJSON(json: any): PracticeConvention {
	return PracticeConventionFromJSONTyped(json, false);
}

export function PracticeConventionFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): PracticeConvention {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		id: !exists(json, "id") ? undefined : json["id"],
		createdAt: !exists(json, "createdAt")
			? undefined
			: new Date(json["createdAt"]),
		updatedAt: !exists(json, "updatedAt")
			? undefined
			: new Date(json["updatedAt"]),
		number: !exists(json, "number") ? undefined : json["number"],
		internshipEnrollmentId: !exists(json, "internshipEnrollmentId")
			? undefined
			: json["internshipEnrollmentId"],
		state: !exists(json, "state")
			? undefined
			: PracticeConventionStateFromJSON(json["state"]),
		supervisorId: !exists(json, "supervisorId")
			? undefined
			: json["supervisorId"],
		internshipEnrollment: !exists(json, "internshipEnrollment")
			? undefined
			: InternshipEnrollmentFromJSON(json["internshipEnrollment"]),
		supervisor: !exists(json, "supervisor")
			? undefined
			: AcademicUserFromJSON(json["supervisor"]),
		practiceConventionSignatures: !exists(json, "practiceConventionSignatures")
			? undefined
			: json["practiceConventionSignatures"] === null
			? null
			: (json["practiceConventionSignatures"] as Array<any>).map(
					PracticeConventionSignatureFromJSON
			  ),
	};
}

export function PracticeConventionToJSON(
	value?: PracticeConvention | null
): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		id: value.id,
		createdAt:
			value.createdAt === undefined ? undefined : value.createdAt.toISOString(),
		updatedAt:
			value.updatedAt === undefined ? undefined : value.updatedAt.toISOString(),
		number: value.number,
		internshipEnrollmentId: value.internshipEnrollmentId,
		state: PracticeConventionStateToJSON(value.state),
		supervisorId: value.supervisorId,
		internshipEnrollment: InternshipEnrollmentToJSON(
			value.internshipEnrollment
		),
		supervisor: AcademicUserToJSON(value.supervisor),
		practiceConventionSignatures:
			value.practiceConventionSignatures === undefined
				? undefined
				: value.practiceConventionSignatures === null
				? null
				: (value.practiceConventionSignatures as Array<any>).map(
						PracticeConventionSignatureToJSON
				  ),
	};
}
