/**
 * checks if Push notification and service workers are supported by your browser
 */
function isPushNotificationSupported() {
	return "serviceWorker" in navigator && "PushManager" in window;
}

/**
 * asks user consent to receive push notifications and returns the response of the user, one of granted, default, denied
 */
async function askUserPermission() {
	return await Notification.requestPermission();
}

/**
 *
 */
function registerServiceWorker() {
	return navigator.serviceWorker.register("/sw.js");
}

/**
 *
 * using the registered service worker creates a push notification subscription and returns it
 *
 */
async function subscribe() {
	//wait for service worker installation to be ready
	const serviceWorker = await navigator.serviceWorker.ready;

	// subscribe and return the subscription
	return await serviceWorker.pushManager.subscribe({
		userVisibleOnly: true,
		applicationServerKey: process.env.REACT_APP_VAPID_PUBLIC_KEY,
	});
}

/**
 *
 * using the registered service worker creates a push notification unsubscription and returns it
 *
 */
async function unsubscribe() {
	//wait for service worker installation to be ready
	const serviceWorker = await navigator.serviceWorker.ready;

	return await serviceWorker.pushManager
		.getSubscription()
		.then(function (subscription) {
			// If no `push subscription`, then return
			if (!subscription) {
				return;
			}

			// Unsubscribes user
			subscription
				.unsubscribe()
				.then(function () {
					console.info("Push notification unsubscribed.");
				})
				.catch(function (error) {
					console.error(error);
				});
		});
}

/**
 * returns the subscription if present or nothing
 */
function getUserSubscription() {
	//wait for service worker installation to be ready, and then
	return navigator.serviceWorker.ready
		.then(function (serviceWorker) {
			return serviceWorker.pushManager.getSubscription();
		})
		.then(function (pushSubscription) {
			return pushSubscription;
		});
}

export {
	isPushNotificationSupported,
	askUserPermission,
	registerServiceWorker,
	subscribe,
	unsubscribe,
	getUserSubscription,
};
