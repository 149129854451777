/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
	PreferredLanguageEnum,
	PreferredLanguageEnumFromJSON,
	PreferredLanguageEnumFromJSONTyped,
	PreferredLanguageEnumToJSON,
} from "./PreferredLanguageEnum";

/**
 *
 * @export
 * @interface CompanyUpdateDTO
 */
export interface CompanyUpdateDTO {
	/**
	 *
	 * @type {string}
	 * @memberof CompanyUpdateDTO
	 */
	commercialName?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyUpdateDTO
	 */
	emailContact?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyUpdateDTO
	 */
	address?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyUpdateDTO
	 */
	postalCode?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyUpdateDTO
	 */
	city?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyUpdateDTO
	 */
	country?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyUpdateDTO
	 */
	phone?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyUpdateDTO
	 */
	domainOfActivity?: string | null;
	/**
	 *
	 * @type {Array<string>}
	 * @memberof CompanyUpdateDTO
	 */
	areasOfInterest?: Array<string> | null;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyUpdateDTO
	 */
	siteUrl?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyUpdateDTO
	 */
	linkedInUrl?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyUpdateDTO
	 */
	description?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyUpdateDTO
	 */
	lowResImage?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyUpdateDTO
	 */
	fullResImage?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyUpdateDTO
	 */
	fax?: string | null;
	/**
	 *
	 * @type {PreferredLanguageEnum}
	 * @memberof CompanyUpdateDTO
	 */
	preferredLanguage?: PreferredLanguageEnum;
}

export function CompanyUpdateDTOFromJSON(json: any): CompanyUpdateDTO {
	return CompanyUpdateDTOFromJSONTyped(json, false);
}

export function CompanyUpdateDTOFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): CompanyUpdateDTO {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		commercialName: !exists(json, "commercialName")
			? undefined
			: json["commercialName"],
		emailContact: !exists(json, "emailContact")
			? undefined
			: json["emailContact"],
		address: !exists(json, "address") ? undefined : json["address"],
		postalCode: !exists(json, "postalCode") ? undefined : json["postalCode"],
		city: !exists(json, "city") ? undefined : json["city"],
		country: !exists(json, "country") ? undefined : json["country"],
		phone: !exists(json, "phone") ? undefined : json["phone"],
		domainOfActivity: !exists(json, "domainOfActivity")
			? undefined
			: json["domainOfActivity"],
		areasOfInterest: !exists(json, "areasOfInterest")
			? undefined
			: json["areasOfInterest"],
		siteUrl: !exists(json, "siteUrl") ? undefined : json["siteUrl"],
		linkedInUrl: !exists(json, "linkedInUrl") ? undefined : json["linkedInUrl"],
		description: !exists(json, "description") ? undefined : json["description"],
		lowResImage: !exists(json, "lowResImage") ? undefined : json["lowResImage"],
		fullResImage: !exists(json, "fullResImage")
			? undefined
			: json["fullResImage"],
		fax: !exists(json, "fax") ? undefined : json["fax"],
		preferredLanguage: !exists(json, "preferredLanguage")
			? undefined
			: PreferredLanguageEnumFromJSON(json["preferredLanguage"]),
	};
}

export function CompanyUpdateDTOToJSON(value?: CompanyUpdateDTO | null): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		commercialName: value.commercialName,
		emailContact: value.emailContact,
		address: value.address,
		postalCode: value.postalCode,
		city: value.city,
		country: value.country,
		phone: value.phone,
		domainOfActivity: value.domainOfActivity,
		areasOfInterest: value.areasOfInterest,
		siteUrl: value.siteUrl,
		linkedInUrl: value.linkedInUrl,
		description: value.description,
		lowResImage: value.lowResImage,
		fullResImage: value.fullResImage,
		fax: value.fax,
		preferredLanguage: PreferredLanguageEnumToJSON(value.preferredLanguage),
	};
}
