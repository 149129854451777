/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface CompanyCountersDTO
 */
export interface CompanyCountersDTO {
	/**
	 *
	 * @type {number}
	 * @memberof CompanyCountersDTO
	 */
	practiceProtocols?: number;
}

export function CompanyCountersDTOFromJSON(json: any): CompanyCountersDTO {
	return CompanyCountersDTOFromJSONTyped(json, false);
}

export function CompanyCountersDTOFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): CompanyCountersDTO {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		practiceProtocols: !exists(json, "practiceProtocols")
			? undefined
			: json["practiceProtocols"],
	};
}

export function CompanyCountersDTOToJSON(
	value?: CompanyCountersDTO | null
): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		practiceProtocols: value.practiceProtocols,
	};
}
