import { FormInstance } from "antd";
import { AddUpdateCompanyUserFormModel } from "../models/AddUpdateCompanyUserFormModel";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { useCallback } from "react";
import { phonePattern } from "../../../../utils/constants";

const getDefaultValues = (): Partial<AddUpdateCompanyUserFormModel> => ({
	department: "",
	email: "",
	phone: "",
	function: "",
	name: "",
});

export const useInitAddUpdateCompanyUserForm = (
	form: FormInstance<AddUpdateCompanyUserFormModel>
) => {
	const defaultValues = getDefaultValues();
	const { t } = useTranslation();

	let schema = yup.object().shape({
		function: yup
			.string()
			.trim()
			.required(t("account.requiredField", { field: t("account.function") })),
		phone: yup
			.string()
			.trim()
			.transform((s) => (s === "" ? null : s))
			.nullable()
			.matches(
				phonePattern,
				t("usersText.invalidField", { field: t("account.phone") })
			),
		email: yup
			.string()
			.required(t("account.requiredField", { field: t("account.email") }))
			.email(t("usersText.invalidField", { field: t("account.email") })),
		name: yup
			.string()
			.trim()
			.required(t("account.requiredField", { field: t("account.name") })),
	});

	const validator = useCallback(
		async ({ field }: any, _value: any) => {
			await schema.validateSyncAt(field, form.getFieldsValue());
		},
		[schema, form]
	);

	const yupSync = {
		validator,
	};

	return { defaultValues, schema, yupSync };
};
