import { useState } from "react";
import Layout from "../../Containers/Layout";
import styles from "./IntershipDocument.module.scss";
import { Form, Checkbox, Input, Button } from "antd";
import CustomForm from "../../CustomComponents/CustomForm";
import { theme } from "../../theme";
import { Redirect } from "react-router-dom";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../Notifications/NotificationsUtils";
import { InternshipDocumentState, StudentYearEnum } from "../../Api";
import ConfirmationModal from "../../Containers/ConfirmationModal";
import { useTranslation } from "react-i18next";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import {
	addInternshipDocument,
	getInternshipDocumentForInternshipEnrollment,
	updateInternshipDocument,
} from "../../Requests/internship-document-requests";
import { selectStudentInternshipEnrollmentId } from "../Student/StudentSlice";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useQuery, useQueryClient } from "react-query";
import {
	getUserId,
	useIsDean,
	useIsDepartmentDirector,
	useIsFacultyAdmin,
	useIsProfessor,
	useIsStudent,
} from "../../utils/utilFunctions";
import { getStudentById } from "../../Requests/academic-user-requests";
import { getInternshipEnrollmentById } from "../../Requests/internship-requests";
import CustomEditor from "../../CustomComponents/CustomEditor";

const IntershipDocument = (props: {
	location: any;
	filters?: any;
	oldSearchTerm?: any;
	currentPage?: any;
	pageSize?: any;
	activeTab?: any;
}) => {
	const { t } = useTranslation();
	const [redirect, setRedirect] = useState(false);
	const [complete, setComplete] = useState(false);
	const [cancelModalVisibility, setCancelModalVisibility] = useState(false);
	const [addModalVisibility, setAddModalVisibility] = useState(false);
	const userId = getUserId();
	const isStudent = useIsStudent();
	const isProfessor = useIsProfessor();
	const isDepartmentDirector = useIsDepartmentDirector();
	const isFacultyAdmin = useIsFacultyAdmin();
	const isDean = useIsDean();
	const queryClient = useQueryClient();
	const internshipEnrollmentId = useSelector(
		selectStudentInternshipEnrollmentId
	);
	const location = useLocation();
	const [spinning, setSpinning] = useState(false);

	const openGetErrorNotification = (_error: any) => {
		openNotification(
			t("internshipDocument.error"),
			t("internshipDocument.fetchDataError"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const openSaveErrorNotification = (_error: any) => {
		openNotification(
			t("internshipDocument.error"),
			t("internshipDocument.saveDataError"),
			NOTIFICATION_TYPES.ERROR
		);
		setAddModalVisibility(false);
	};

	const [form] = Form.useForm<{
		title: string;
		description: string;
		studentGroup: string;
		bibliography: string;
	}>();

	const { data: internshipDetails } = useQuery(
		["getInternshipQuery", userId, internshipEnrollmentId],
		() => {
			return getInternshipEnrollmentById(internshipEnrollmentId!);
		},
		{
			onError: (err: any) => {
				if (err.status !== 404) {
					openGetErrorNotification(err);
				}
			},
			onSuccess: (data) => {
				form.setFieldsValue({
					...form.getFieldsValue(),
					title: data.internshipName!,
					description: data.description!,
				});
			},
			refetchOnWindowFocus: false,
		}
	);

	const { data: student } = useQuery(
		["getStudentQuery", userId, isStudent, isProfessor, internshipEnrollmentId],
		() => {
			if (isStudent) {
				return getStudentById(userId);
			}
		},
		{
			onError: (err: any) => {
				if (err.status !== 404) {
					openGetErrorNotification(err);
				}
			},
			refetchOnWindowFocus: false,
		}
	);

	const { data: internshipDocument } = useQuery(
		["getInternshipDocumentQuery", internshipEnrollmentId, internshipDetails],
		() => {
			return getInternshipDocumentForInternshipEnrollment(
				internshipEnrollmentId!
			);
		},
		{
			enabled:
				internshipDetails?.internshipDocumentState ===
					InternshipDocumentState.NotGenerated ||
				internshipDetails?.internshipDocumentState ===
					InternshipDocumentState.NotApplicable
					? false
					: true,
			onError: (err: any) => {
				if (err.status !== 404) {
					openGetErrorNotification(err);
				}
			},
			onSuccess: (data) => {
				form.setFieldsValue({
					...form.getFieldsValue(),
					title: data?.title || "",
					description: data?.description || "",
					studentGroup: data?.studentGroup || "",
					bibliography: data?.bibliography || "",
				});

				setComplete(data.state !== InternshipDocumentState.InProgress);
			},
			refetchOnWindowFocus: false,
		}
	);

	const handleChangeCheckbox = (event: CheckboxChangeEvent) => {
		setComplete(event.target.checked);
	};

	const handleCancel = () => {
		setCancelModalVisibility(false);
		setRedirect(true);
	};

	const openSuccessNotificationAndRedirect = () => {
		setRedirect(true);
		openNotification(
			t("internshipDocument.editInternshipDocument"),
			t("internshipDocument.editInternshipDocumentSuccess"),
			NOTIFICATION_TYPES.SUCCESS,
			6
		);
	};

	const setNextDocumentState = (currentState: any, type: string) => {
		if (currentState === InternshipDocumentState.PendingCoordinatorSignature) {
			return InternshipDocumentState.PendingStudyProgramCoordinatorSignature;
		} else if (
			currentState ===
				InternshipDocumentState.PendingStudyProgramCoordinatorSignature &&
			type === "Default"
		) {
			return InternshipDocumentState.PendingDepartmentDirectorSignature;
		} else if (
			currentState ===
				InternshipDocumentState.PendingDepartmentDirectorSignature &&
			type === "FIIR"
		) {
			return InternshipDocumentState.PendingDeanSignature;
		} else if (
			currentState === InternshipDocumentState.PendingDeanSignature &&
			type === "FIIR"
		) {
			return InternshipDocumentState.Finalized;
		}
	};

	const handleSave = async () => {
		const state = form.getFieldsValue();
		if (
			(state.title !== "" || internshipDetails?.internshipName !== "") &&
			(!internshipDocument &&
			student?.facultyNameRo?.toLowerCase() ===
				"facultatea de automatică și calculatoare"
				? (state.description !== "" || internshipDetails?.description !== "") &&
				  state.studentGroup !== "" &&
				  state.bibliography !== ""
				: true)
		) {
			setSpinning(true);

			if (internshipDocument) {
				let obj = null;
				if (
					internshipDocument?.faculty?.facultyNameRo?.toLowerCase() ===
					"facultatea de automatică și calculatoare"
				) {
					obj = {
						title: state.title || internshipDetails?.internshipName!,
						description: state.description || internshipDetails?.description,
						studentGroup: state.studentGroup,
						bibliography: state.bibliography,
						state: isStudent
							? complete
								? InternshipDocumentState.PendingCoordinatorSignature
								: InternshipDocumentState.InProgress
							: complete
							? internshipDocument.state
							: setNextDocumentState(internshipDocument.state, "ACS"),
					};
				} else {
					obj = {
						title: state.title || internshipDetails?.internshipName!,
						state: isStudent
							? complete
								? InternshipDocumentState.PendingCoordinatorSignature
								: InternshipDocumentState.InProgress
							: complete
							? internshipDocument.state
							: setNextDocumentState(internshipDocument.state, "FIIR"),
					};
				}
				updateInternshipDocument(internshipDocument.id!, obj)
					.then(openSuccessNotificationAndRedirect)
					.catch(openSaveErrorNotification)
					.finally(() => {
						setSpinning(false);
						setAddModalVisibility(false);
					});

				await queryClient.invalidateQueries("getInternship");
			} else {
				let obj = null;
				if (
					student?.facultyNameRo?.toLowerCase() ===
					"facultatea de automatică și calculatoare"
				) {
					obj = {
						title: state.title || internshipDetails?.internshipName!,
						description: state.description,
						studentGroup: state.studentGroup,
						bibliography: state.bibliography,
						internshipEnrollmentId: internshipEnrollmentId!,
					};
				} else {
					obj = {
						title: state.title || internshipDetails?.internshipName!,
						internshipEnrollmentId: internshipEnrollmentId!,
					};
				}

				addInternshipDocument(obj)
					.then(openSuccessNotificationAndRedirect)
					.catch(openSaveErrorNotification)
					.finally(() => {
						setSpinning(false);
						setAddModalVisibility(false);
					});
				await queryClient.invalidateQueries("getInternship");
			}
		} else {
			openNotification(
				t("internshipDocument.editInternshipDocument"),
				t("usersText.requiredFieldsError"),
				NOTIFICATION_TYPES.ERROR
			);
		}
	};

	if (redirect) {
		return (
			<Redirect
				to={
					!isStudent
						? isDean
							? "/studenti-diploma"
							: isDepartmentDirector || isFacultyAdmin
							? {
									pathname: "/utilizatori",
									state: {
										location: location,
									},
							  }
							: {
									pathname: "/studenti",
									state: {
										activeTab: "2",
									},
							  }
						: internshipDetails?.studentYear === StudentYearEnum.M1 ||
						  internshipDetails?.studentYear === StudentYearEnum.M2
						? "/tema-cercetare"
						: "/lucrare-licenta"
				}
			/>
		);
	} else {
		return (
			<div className="container mx-auto px-4 py-10 flex flex-col gap-8">
				<Form.Provider>
					<Form
						form={form}
						onFinish={() => {
							setAddModalVisibility(true);
						}}
						layout={theme.layout}
						style={{
							boxShadow: "none",
							marginTop: 0,
							width: "100%",
						}}
						action={
							!isStudent
								? isDepartmentDirector
									? "/utilizatori"
									: "/studenti"
								: internshipDetails?.studentYear === StudentYearEnum.M1 ||
								  internshipDetails?.studentYear === StudentYearEnum.M2
								? "/tema-cercetare"
								: "/lucrare-licenta"
						}
					>
						<div className={styles.firstLine}>
							<Form.Item
								name="title"
								label={
									t("internshipDocument.addInternshipDocumentForm.title") + ":"
								}
								initialValue={internshipDetails?.internshipName}
							>
								<Input
									defaultValue={internshipDetails?.internshipName! || ""}
								/>
							</Form.Item>

							{((isStudent &&
								student?.facultyNameRo?.toLowerCase() ===
									"facultatea de automatică și calculatoare") ||
								(internshipDocument &&
									internshipDocument?.faculty?.facultyNameRo?.toLowerCase() ===
										"facultatea de automatică și calculatoare")) && (
								<div className={styles.firstLine}>
									<Form.Item
										name="description"
										label={
											t(
												"internshipDocument.addInternshipDocumentForm.description"
											) + ":"
										}
									>
										<CustomEditor
											onEditorChange={(description: string) => {
												form.setFieldsValue({
													...form.getFieldsValue(),
													description,
												});
											}}
										/>
									</Form.Item>

									<Form.Item
										name="studentGroup"
										label={
											t("internshipDocument.addInternshipDocumentForm.group") +
											":"
										}
										rules={[
											{
												required: true,
												message: t(
													"internshipDocument.addInternshipDocumentForm.requiredField",
													{
														field: t(
															"internshipDocument.addInternshipDocumentForm.group"
														),
													}
												),
												whitespace: true,
											},
										]}
									>
										<Input
											placeholder={t(
												"internshipDocument.addInternshipDocumentForm.group"
											)}
										/>
									</Form.Item>

									<Form.Item
										name="bibliography"
										label={
											t(
												"internshipDocument.addInternshipDocumentForm.bibliography"
											) + ":"
										}
										rules={[
											{
												required: true,
												message: t(
													"internshipDocument.addInternshipDocumentForm.requiredField",
													{
														field: t(
															"internshipDocument.addInternshipDocumentForm.bibliography"
														),
													}
												),
												whitespace: true,
											},
										]}
									>
										<CustomEditor
											onEditorChange={(bibliography: string) => {
												form.setFieldsValue({
													...form.getFieldsValue(),
													bibliography,
												});
											}}
										/>
									</Form.Item>
								</div>
							)}

							{isStudent && (
								<Checkbox
									className="pb-5"
									checked={complete}
									onChange={(event) => handleChangeCheckbox(event)}
									style={{ textAlign: "left" }}
								>
									{t(
										"internshipDocument.addInternshipDocumentForm.markComplete"
									)}
								</Checkbox>
							)}
						</div>
						<div className={styles.secondLine}>
							<div className={styles.firstColumn}>
								<ConfirmationModal
									modalText={t("internshipDocument.cancelMessage")}
									handleFunction={handleCancel}
									modalVisibility={cancelModalVisibility}
									changeModalVisibility={() => setCancelModalVisibility(false)}
									title=""
									spinning={spinning}
								/>
								<Button
									htmlType="submit"
									style={{
										fontSize: "0.9rem",
										background: theme.secondColor,
										color: theme.white,
										marginRight: "1rem",
										marginTop: "0",
										boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
									}}
								>
									{internshipDocument
										? t(
												"internshipDocument.addInternshipDocumentForm.updateButton"
										  )
										: t(
												"internshipDocument.addInternshipDocumentForm.saveButton"
										  )}
								</Button>
								<ConfirmationModal
									modalText={t(
										"internshipDocument.addInternshipDocumentForm.saveMessage"
									)}
									handleFunction={handleSave}
									modalVisibility={addModalVisibility}
									changeModalVisibility={() => setAddModalVisibility(false)}
									title=""
									spinning={spinning}
								/>
							</div>
						</div>
					</Form>
				</Form.Provider>
			</div>
		);
	}
};

export default IntershipDocument;
