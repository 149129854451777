import { Row, Radio, Space, RadioChangeEvent, Checkbox, Input } from "antd";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import styles from "./Feedback.module.scss";
import { QuestionTypeEnum } from "../../Api/models/QuestionTypeEnum";

const likertSatisfcationAnswers = [
	"1 (Nesatisfăcător)",
	"2 (Satisfăcător)",
	"3 (Bine)",
	"4 (Foarte bine)",
	"5 (Excelent)",
	"Nu se aplica",
];
const likertAccordAnswers = [
	"1 (Total dezacord)",
	"2 (Dezacord)",
	"3 (Nici acord, nici dezacord)",
	"4 (Acord)",
	"5 (Total acord)",
	"Nu se aplica",
];
const YesNoAnswers = ["Da", "Nu", "Nu se aplica"];

type CustomFormItemProps = {
	value?: number | number[] | string;
	onChange?: (value: number | number[] | string) => void;
	props: { question: string; type: QuestionTypeEnum; answers?: string[] };
};

const Question: React.FC<CustomFormItemProps> = ({
	value,
	onChange,
	props,
}) => {
	const onChangeSingleChoice = (e: RadioChangeEvent) => {
		if (typeof onChange === "function") {
			onChange(e.target.value);
		}
	};

	const onChangeMultipleChoice = (checkedValues: CheckboxValueType[]) => {
		if (typeof onChange === "function") {
			onChange(checkedValues as number[]);
		}
	};

	const onChangeText = (e: any) => {
		if (typeof onChange === "function") {
			onChange(e.target.value);
		}
	};

	return (
		<div className="border border-solid rounded-lg border-primary px-4 pb-4 bg-white">
			<p className="">
				<span style={{ color: "#ff4d4f" }}>*&nbsp;</span>
				{props.question}
			</p>

			{/* Single choice question */}
			{props.type === QuestionTypeEnum.SingleChoice && (
				<Radio.Group
					value={value}
					onChange={onChangeSingleChoice}
					className="w-full space-y-2"
				>
					{props.answers?.map((answer) => {
						return (
							<Radio value={answer} key={answer} className="w-full">
								{answer}
							</Radio>
						);
					})}
				</Radio.Group>
			)}

			{/* Multiple choice question */}
			{props.type === QuestionTypeEnum.MultipleChoice && (
				<Checkbox.Group
					defaultValue={value as number[]}
					onChange={onChangeMultipleChoice}
					className="w-full space-y-2"
				>
					{props.answers?.map((answer) => {
						return (
							<Checkbox value={answer} key={answer} className="w-full">
								{answer}
							</Checkbox>
						);
					})}
				</Checkbox.Group>
			)}

			{/* LikertAccord question */}
			{props.type === QuestionTypeEnum.LikertAccord && (
				<Radio.Group
					value={value}
					onChange={onChangeSingleChoice}
					buttonStyle="solid"
				>
					{likertAccordAnswers.map((answer, index) => {
						return (
							<Radio.Button value={index + 1} key={answer}>
								{answer}
							</Radio.Button>
						);
					})}
				</Radio.Group>
			)}

			{/* LikertSatisfaction question */}
			{props.type === QuestionTypeEnum.LikertSatisfaction && (
				<Radio.Group
					value={value}
					onChange={onChangeSingleChoice}
					buttonStyle="solid"
				>
					{likertSatisfcationAnswers.map((answer, index) => {
						return (
							<Radio.Button value={index + 1} key={answer}>
								{answer}
							</Radio.Button>
						);
					})}
				</Radio.Group>
			)}

			{/* YesNo question */}
			{props.type === QuestionTypeEnum.YesNo && (
				<Radio.Group
					value={value}
					onChange={onChangeSingleChoice}
					buttonStyle="solid"
				>
					{YesNoAnswers.map((answer) => {
						return (
							<Radio.Button value={answer} key={answer}>
								{answer}
							</Radio.Button>
						);
					})}
				</Radio.Group>
			)}

			{/* OpenText question */}
			{props.type === QuestionTypeEnum.OpenText && (
				<Input.TextArea
					defaultValue={value as string}
					autoSize={{ minRows: 2, maxRows: 8 }}
					onChange={onChangeText}
				/>
			)}
		</div>
	);
};

export default Question;
