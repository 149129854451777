/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum InternshipParticipationType {
	None = "None",
	Interested = "Interested",
	Going = "Going",
	NotGoing = "NotGoing",
}

export function InternshipParticipationTypeFromJSON(
	json: any
): InternshipParticipationType {
	return InternshipParticipationTypeFromJSONTyped(json, false);
}

export function InternshipParticipationTypeFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): InternshipParticipationType {
	return json as InternshipParticipationType;
}

export function InternshipParticipationTypeToJSON(
	value?: InternshipParticipationType | null
): any {
	return value as any;
}
