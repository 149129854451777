import { Avatar, Button, Pagination, Spin, Typography } from "antd";
import { useCallback, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { isUserLogged } from "../../utils/utilFunctions";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../Notifications/NotificationsUtils";
import {
	EnrollmentStatus,
	PoliJobsProposalEnrollmentDTO,
	PoliJobsProposalEnrollmentStatus,
	Role,
} from "../../Api";
import ConfirmationModal from "../../Containers/ConfirmationModal";
import { useTranslation } from "react-i18next";
import OfferActions from "./PoliJobsProposalActions";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useQuery, useQueryClient } from "react-query";
import {
	getPoliJobsApplicationsData,
	getPoliJobsApplicationsFilters,
} from "../../utils/reactQueriesConstants";
import { Routes } from "../../utils/routes";
import useQueryFilters from "../../Hooks/useQueryFilters";
import {
	getAllApplications,
	getApplicationsFilters,
	withdrawApplication,
} from "../../Requests/polijobs-proposal-enrollment-requests";
import Filters from "../Filters";

const StudentApplications = () => {
	const { t } = useTranslation();
	const [pageSize, setPageSize] = useState(12);
	const [currentPage, setCurrent] = useState(1);
	const [selectedPoliJobsProposalId, setSelectedPoliJobsProposalId] =
		useState<string>("");
	const location = useLocation();
	const queryClient = useQueryClient();
	const [
		cancelApplicationModalVisibility,
		setCancelApplicationModalVisibility,
	] = useState(false);
	const [spinning, setSpinning] = useState(false);
	const query = useQueryFilters({});

	const openGetApplicationsErrorNotification = (_error: any) => {
		openNotification(
			t("internships.error"),
			t("internships.fetchApplicationsError"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const { data, isLoading: loading } = useQuery(
		[getPoliJobsApplicationsData, query.filters],
		() => getAllApplications(query.filters),
		{
			onError: openGetApplicationsErrorNotification,
		}
	);

	const { data: filters } = useQuery(
		[getPoliJobsApplicationsFilters],
		() => getApplicationsFilters(),
		{
			onError: openGetApplicationsErrorNotification,
		}
	);

	const cancelApplication = (poliJobsProposalId: string) => {
		setSpinning(true);

		withdrawApplication(poliJobsProposalId)
			.then(async () => {
				await queryClient.invalidateQueries(getPoliJobsApplicationsData);
				openNotification(
					t("polijobs.withdrawApplication"),
					t("polijobs.applicationWithdrawalSuccessMessage"),
					NOTIFICATION_TYPES.SUCCESS
				);
			})
			.catch((_error) => {
				console.error(_error);

				openNotification(
					t("polijobs.withdrawApplication"),
					t("polijobs.applicationWithdrawalErrorMessage"),
					NOTIFICATION_TYPES.ERROR
				);
			})
			.finally(() => {
				setSpinning(false);
				setCancelApplicationModalVisibility(false);
			});
	};

	const onUpdate = useCallback(
		(values: { [key: string]: string[] }) => {
			query.update(values, true);
		},
		[query]
	);

	const onPageUpdate = useCallback(
		(page: number, pageSize: number) => {
			query.update({
				page: page.toString(),
				pageSize: pageSize.toString(),
			});
		},
		[query]
	);

	return (
		<Spin size="large" spinning={loading}>
			<Filters filters={filters} onUpdate={onUpdate} hasSort={false} />

			<div className="px-4 py-10 flex flex-col gap-10">
				<div className="flex flex-col md:flex-row justify-between items-center">
					<Typography.Title level={3} className="my-0">
						{data?.totalCount} {t("filtering.results")}
					</Typography.Title>
					{!loading && (
						<Pagination
							defaultCurrent={data?.page}
							total={data?.totalCount}
							onChange={onPageUpdate}
							pageSizeOptions={[12, 24, 48, 96]}
							defaultPageSize={12}
						/>
					)}
				</div>

				{loading && !data && (
					<div className="w-full py-10 text-center">Loading</div>
				)}
				{data?.data?.length === 0 && (
					<div className="w-full py-10 text-center">
						{t("tableText.noApplications")}
					</div>
				)}

				<div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 xxl:grid-cols-4 gap-4">
					{data?.data?.map((item: PoliJobsProposalEnrollmentDTO) => (
						<div
							className="bg-white rounded-md shadow-md p-4 flex flex-col gap-4"
							key={item.id}
						>
							<div className="flex flex-col gap-2">
								<Link
									to={{
										pathname: `/profil`,
										state: {
											id: item.companyId,
											userType: Role.Company,
											origin: location.pathname,
										},
									}}
								>
									<Avatar src={item.companyLogo} />
									<span className="text-secondary">{item.companyName}</span>
								</Link>

								<Link
									to={{
										pathname: `${Routes.POLIJOBS_OFFER}/${item.poliJobsProposalId}`,
										state: {
											id: item.poliJobsProposalId,
											origin: location.pathname,
										},
									}}
									className="text-lg text-secondary font-bold flex items-center gap-2 line-clamp-2 overflow-ellipsis"
								>
									<FontAwesomeIcon icon={solid("arrow-up-right-from-square")} />

									{item.poliJobsProposalName}
								</Link>
							</div>

							<OfferActions item={item} />

							<div className="mt-auto flex flex-col gap-2 w-full">
								{isUserLogged() &&
									item.enrollmentStatus ===
										PoliJobsProposalEnrollmentStatus.WaitingForProposer && (
										<>
											<Button
												danger
												onClick={() => {
													setSelectedPoliJobsProposalId(
														item.poliJobsProposalId!
													);
													setCancelApplicationModalVisibility(true);
												}}
												title={t("polijobs.withdrawApplication")}
												icon={<FontAwesomeIcon icon={solid("ban")} />}
											>
												{t("polijobs.withdrawApplication")}
											</Button>

											<ConfirmationModal
												modalText={t("polijobs.withdrawApplicationMessage")}
												handleFunction={() =>
													cancelApplication(selectedPoliJobsProposalId)
												}
												modalVisibility={cancelApplicationModalVisibility}
												title=""
												changeModalVisibility={() =>
													setCancelApplicationModalVisibility(false)
												}
												spinning={spinning}
											/>
										</>
									)}
							</div>
						</div>
					))}
				</div>
			</div>
		</Spin>
	);
};

export default StudentApplications;
