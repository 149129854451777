import { RejectApplicantModalProps } from "./RejectApplicantModal.types";
import { useTranslation } from "react-i18next";
import ConfirmationModal from "../../../../../../Containers/ConfirmationModal";
import { rejectApplicant } from "../../../../../../Requests/internship-requests";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../../../../../Notifications/NotificationsUtils";

export const RejectApplicantModal = (props: RejectApplicantModalProps) => {
	const { t } = useTranslation();

	const handleRejectApplicant = (item: any) => {
		props.setSpinning(true);

		rejectApplicant(item.internshipId, item.id)
			.then(async () => {
				await props.invalidateApplicantsQueries();
				openNotification(
					t("students.rejectApplicant"),
					t("students.successRejectApplicantMessage"),
					NOTIFICATION_TYPES.SUCCESS
				);
			})
			.catch((_error) => {
				openNotification(
					t("students.rejectApplicant"),
					t("students.errorRejectApplicantMessage"),
					NOTIFICATION_TYPES.ERROR
				);
			})
			.finally(() => {
				props.setSpinning(false);
				props.setModalVisibility(false);
			});
	};

	return (
		<ConfirmationModal
			modalText={
				t("students.rejectStudentMessage") +
				props.selectedApplicant.name +
				t("students.rejectStudentMessage2")
			}
			handleFunction={() => handleRejectApplicant(props.selectedApplicant)}
			modalVisibility={props.modalVisibility}
			title=""
			changeModalVisibility={() => props.setModalVisibility(false)}
			spinning={props.spinning}
			divStyle={{}}
		/>
	);
};
