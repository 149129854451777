/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface CompanyReportDTO
 */
export interface CompanyReportDTO {
	/**
	 *
	 * @type {string}
	 * @memberof CompanyReportDTO
	 */
	companyName?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyReportDTO
	 */
	commercialName?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyReportDTO
	 */
	domainOfActivity?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyReportDTO
	 */
	userName?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyReportDTO
	 */
	userEmail?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyReportDTO
	 */
	userPhone?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyReportDTO
	 */
	companySite?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyReportDTO
	 */
	r1?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyReportDTO
	 */
	r2?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyReportDTO
	 */
	r3?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyReportDTO
	 */
	r4?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyReportDTO
	 */
	r5?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyReportDTO
	 */
	r6?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyReportDTO
	 */
	r7?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyReportDTO
	 */
	r8?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyReportDTO
	 */
	r9?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyReportDTO
	 */
	r10?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyReportDTO
	 */
	r11?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyReportDTO
	 */
	r12?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyReportDTO
	 */
	r13?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyReportDTO
	 */
	r14?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyReportDTO
	 */
	r15?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyReportDTO
	 */
	r16?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyReportDTO
	 */
	r17?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyReportDTO
	 */
	r18?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyReportDTO
	 */
	r19?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyReportDTO
	 */
	r20?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyReportDTO
	 */
	r21?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyReportDTO
	 */
	r22?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyReportDTO
	 */
	r23?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyReportDTO
	 */
	r24?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyReportDTO
	 */
	r25?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyReportDTO
	 */
	r26?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyReportDTO
	 */
	r27?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyReportDTO
	 */
	r28?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyReportDTO
	 */
	r29?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyReportDTO
	 */
	r30?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyReportDTO
	 */
	r31?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyReportDTO
	 */
	r32?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyReportDTO
	 */
	r33?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyReportDTO
	 */
	r34?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyReportDTO
	 */
	r35?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyReportDTO
	 */
	r36?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyReportDTO
	 */
	r37?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyReportDTO
	 */
	r38?: string | null;
}

export function CompanyReportDTOFromJSON(json: any): CompanyReportDTO {
	return CompanyReportDTOFromJSONTyped(json, false);
}

export function CompanyReportDTOFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): CompanyReportDTO {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		companyName: !exists(json, "companyName") ? undefined : json["companyName"],
		commercialName: !exists(json, "commercialName")
			? undefined
			: json["commercialName"],
		domainOfActivity: !exists(json, "domainOfActivity")
			? undefined
			: json["domainOfActivity"],
		userName: !exists(json, "userName") ? undefined : json["userName"],
		userEmail: !exists(json, "userEmail") ? undefined : json["userEmail"],
		userPhone: !exists(json, "userPhone") ? undefined : json["userPhone"],
		companySite: !exists(json, "companySite") ? undefined : json["companySite"],
		r1: !exists(json, "r1") ? undefined : json["r1"],
		r2: !exists(json, "r2") ? undefined : json["r2"],
		r3: !exists(json, "r3") ? undefined : json["r3"],
		r4: !exists(json, "r4") ? undefined : json["r4"],
		r5: !exists(json, "r5") ? undefined : json["r5"],
		r6: !exists(json, "r6") ? undefined : json["r6"],
		r7: !exists(json, "r7") ? undefined : json["r7"],
		r8: !exists(json, "r8") ? undefined : json["r8"],
		r9: !exists(json, "r9") ? undefined : json["r9"],
		r10: !exists(json, "r10") ? undefined : json["r10"],
		r11: !exists(json, "r11") ? undefined : json["r11"],
		r12: !exists(json, "r12") ? undefined : json["r12"],
		r13: !exists(json, "r13") ? undefined : json["r13"],
		r14: !exists(json, "r14") ? undefined : json["r14"],
		r15: !exists(json, "r15") ? undefined : json["r15"],
		r16: !exists(json, "r16") ? undefined : json["r16"],
		r17: !exists(json, "r17") ? undefined : json["r17"],
		r18: !exists(json, "r18") ? undefined : json["r18"],
		r19: !exists(json, "r19") ? undefined : json["r19"],
		r20: !exists(json, "r20") ? undefined : json["r20"],
		r21: !exists(json, "r21") ? undefined : json["r21"],
		r22: !exists(json, "r22") ? undefined : json["r22"],
		r23: !exists(json, "r23") ? undefined : json["r23"],
		r24: !exists(json, "r24") ? undefined : json["r24"],
		r25: !exists(json, "r25") ? undefined : json["r25"],
		r26: !exists(json, "r26") ? undefined : json["r26"],
		r27: !exists(json, "r27") ? undefined : json["r27"],
		r28: !exists(json, "r28") ? undefined : json["r28"],
		r29: !exists(json, "r29") ? undefined : json["r29"],
		r30: !exists(json, "r30") ? undefined : json["r30"],
		r31: !exists(json, "r31") ? undefined : json["r31"],
		r32: !exists(json, "r32") ? undefined : json["r32"],
		r33: !exists(json, "r33") ? undefined : json["r33"],
		r34: !exists(json, "r34") ? undefined : json["r34"],
		r35: !exists(json, "r35") ? undefined : json["r35"],
		r36: !exists(json, "r36") ? undefined : json["r36"],
		r37: !exists(json, "r37") ? undefined : json["r37"],
		r38: !exists(json, "r38") ? undefined : json["r38"],
	};
}

export function CompanyReportDTOToJSON(value?: CompanyReportDTO | null): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		companyName: value.companyName,
		commercialName: value.commercialName,
		domainOfActivity: value.domainOfActivity,
		userName: value.userName,
		userEmail: value.userEmail,
		userPhone: value.userPhone,
		companySite: value.companySite,
		r1: value.r1,
		r2: value.r2,
		r3: value.r3,
		r4: value.r4,
		r5: value.r5,
		r6: value.r6,
		r7: value.r7,
		r8: value.r8,
		r9: value.r9,
		r10: value.r10,
		r11: value.r11,
		r12: value.r12,
		r13: value.r13,
		r14: value.r14,
		r15: value.r15,
		r16: value.r16,
		r17: value.r17,
		r18: value.r18,
		r19: value.r19,
		r20: value.r20,
		r21: value.r21,
		r22: value.r22,
		r23: value.r23,
		r24: value.r24,
		r25: value.r25,
		r26: value.r26,
		r27: value.r27,
		r28: value.r28,
		r29: value.r29,
		r30: value.r30,
		r31: value.r31,
		r32: value.r32,
		r33: value.r33,
		r34: value.r34,
		r35: value.r35,
		r36: value.r36,
		r37: value.r37,
		r38: value.r38,
	};
}
