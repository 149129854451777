/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
	InternshipType,
	InternshipTypeFromJSON,
	InternshipTypeFromJSONTyped,
	InternshipTypeToJSON,
} from "./InternshipType";
import {
	ProfessionalTitleEnum,
	ProfessionalTitleEnumFromJSON,
	ProfessionalTitleEnumFromJSONTyped,
	ProfessionalTitleEnumToJSON,
} from "./ProfessionalTitleEnum";

/**
 *
 * @export
 * @interface SummerSchoolsReportDTO
 */
export interface SummerSchoolsReportDTO {
	/**
	 *
	 * @type {string}
	 * @memberof SummerSchoolsReportDTO
	 */
	id?: string;
	/**
	 *
	 * @type {ProfessionalTitleEnum}
	 * @memberof SummerSchoolsReportDTO
	 */
	professionalTitle?: ProfessionalTitleEnum;
	/**
	 *
	 * @type {string}
	 * @memberof SummerSchoolsReportDTO
	 */
	summerSchoolId?: string;
	/**
	 *
	 * @type {string}
	 * @memberof SummerSchoolsReportDTO
	 */
	summerSchoolName?: string | null;
	/**
	 *
	 * @type {InternshipType}
	 * @memberof SummerSchoolsReportDTO
	 */
	summerSchoolType?: InternshipType;
	/**
	 *
	 * @type {string}
	 * @memberof SummerSchoolsReportDTO
	 */
	coTutorName?: string | null;
	/**
	 *
	 * @type {number}
	 * @memberof SummerSchoolsReportDTO
	 */
	totalAvailablePositions?: number;
	/**
	 *
	 * @type {number}
	 * @memberof SummerSchoolsReportDTO
	 */
	totalAllocatedPositions?: number;
}

export function SummerSchoolsReportDTOFromJSON(
	json: any
): SummerSchoolsReportDTO {
	return SummerSchoolsReportDTOFromJSONTyped(json, false);
}

export function SummerSchoolsReportDTOFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): SummerSchoolsReportDTO {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		id: !exists(json, "id") ? undefined : json["id"],
		professionalTitle: !exists(json, "professionalTitle")
			? undefined
			: ProfessionalTitleEnumFromJSON(json["professionalTitle"]),
		summerSchoolId: !exists(json, "summerSchoolId")
			? undefined
			: json["summerSchoolId"],
		summerSchoolName: !exists(json, "summerSchoolName")
			? undefined
			: json["summerSchoolName"],
		summerSchoolType: !exists(json, "summerSchoolType")
			? undefined
			: InternshipTypeFromJSON(json["summerSchoolType"]),
		coTutorName: !exists(json, "coTutorName") ? undefined : json["coTutorName"],
		totalAvailablePositions: !exists(json, "totalAvailablePositions")
			? undefined
			: json["totalAvailablePositions"],
		totalAllocatedPositions: !exists(json, "totalAllocatedPositions")
			? undefined
			: json["totalAllocatedPositions"],
	};
}

export function SummerSchoolsReportDTOToJSON(
	value?: SummerSchoolsReportDTO | null
): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		id: value.id,
		professionalTitle: ProfessionalTitleEnumToJSON(value.professionalTitle),
		summerSchoolId: value.summerSchoolId,
		summerSchoolName: value.summerSchoolName,
		summerSchoolType: InternshipTypeToJSON(value.summerSchoolType),
		coTutorName: value.coTutorName,
		totalAvailablePositions: value.totalAvailablePositions,
		totalAllocatedPositions: value.totalAllocatedPositions,
	};
}
