import { useState, useEffect } from "react";
import { theme } from "../../theme";
import { Button, Checkbox, Form, Input, Select } from "antd";
import nophoto from "../../Media/nophoto.jpg";
import { usePushNotifications, useIsAdmin } from "../../utils/utilFunctions";
import { phonePattern, urlPattern } from "../../utils/constants";
import {
	acceptGDPRDate,
	toggleAcademicUserNotifications,
} from "../../Requests/academic-user-requests";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../Notifications/NotificationsUtils";
import {
	getCompanyById,
	updateCompanyInfoAdmin,
} from "../../Requests/company-requests";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import CompanyUserTable from "./CompanyUserTable";
import ConfirmationModal from "../../Containers/ConfirmationModal";
import { useTranslation } from "react-i18next";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useQuery } from "react-query";
import { ErrorCodes, Role } from "../../Api";
import { getCompanyAccountInfo } from "../../utils/reactQueriesConstants";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import ImageCropModal from "./ImageCropModal";
import { getErrorFromResponse } from "../../utils/responseUtils";
import CustomEditor from "../../CustomComponents/CustomEditor";
import { Routes } from "../../utils/routes";
import { StatusCodes } from "http-status-codes";

const { Option } = Select;

const CompanyAccount = () => {
	const { t, i18n } = useTranslation();
	const { id } = useParams<{ id?: string }>();
	const [state, setState] = useState({} as any);
	const [modalVisibility, setModalVisibility] = useState(false);
	const [profilePicture, setProfilePicture] = useState<string | null>("");
	const [lowResPicture, setLowResPicture] = useState<string | null>("");
	const [cropImageModalVisibility, setCropImageModalVisibility] =
		useState(false);
	const [areasOfInterest, setAreasOfInterest] = useState<string[]>(
		new Array<string>()
	);
	const [checked, setChecked] = useState(false);
	const [form] = Form.useForm<{
		phone: string;
		linkedInUrl?: string | null;
		name?: string | null;
		email: string;
		personalEmail: string;
		domainOfActivity?: string | null;
		researchField?: string | null;
		skills?: string[] | null;
		areasOfInterest?: string[] | null;
		description?: string | null;
		gitUrl?: string | null;
		siteUrl?: string | null;
		cvUrl?: string | null;
		department?: string;
		studyProgram?: string;
		group?: string | null;
		preferredLanguage?: string;
		address?: string;
		postalCode?: string;
	}>();
	const isAdmin = useIsAdmin();
	const [spinning, setSpinning] = useState(false);

	const history = useHistory();
	const redirectToProfile = () => {
		history.push(Routes.PROFILE, { id, userType: Role.Company });
	};

	const {
		pushNotificationSupported,
		userSubscription,
		subscribeToPushNotifications,
		unsubscribeFromPushNotifications,
	} = usePushNotifications();

	const onCheckChange = (e: CheckboxChangeEvent) => {
		setChecked(e.target.checked);
		acceptGDPRDate(e.target.checked)
			.then((e) => successMessage())
			.catch((e) => saveChangesError());
	};

	const openDataErrorNotification = (_error: any) => {
		openNotification(
			t("account.error"),
			t("account.fetchUserDataError"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const saveChangesError = () => {
		openNotification(
			t("account.saveError"),
			t("account.saveChangesError"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const missingFieldsError = () => {
		openNotification(
			t("account.saveError"),
			t("usersText.requiredFieldsError"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const successMessage = () => {
		openNotification(
			t("account.success"),
			t("account.successSaveMessage"),
			NOTIFICATION_TYPES.SUCCESS
		);
	};

	const changeLng = (event: any) => {
		setState({
			...state,
			preferredLanguage: event.toUpperCase(),
		});
	};

	const handleSave = () => {
		form
			.validateFields()
			.then(() => {
				setSpinning(true);
				updateCompanyInfoAdmin(id!, {
					commercialName: state?.name,
					phone: state?.phone ?? "",
					description: state?.description,
					emailContact: state?.email,
					domainOfActivity: state?.domainOfActivity,
					siteUrl: state?.siteUrl,
					linkedInUrl: state?.linkedInUrl,
					fullResImage: state?.fullResLogo,
					lowResImage: state?.lowResLogo,
					preferredLanguage: state?.preferredLanguage,
					areasOfInterest,
					address: state.address,
					postalCode: state.postalCode,
				})
					.then(() => {
						successMessage();
						redirectToProfile();
					})
					?.catch(async (error: any) => {
						const errorMessage = await getErrorFromResponse(error);

						let notificationMessage =
							errorMessage?.code === ErrorCodes.Forbidden
								? t("account.cannotUpdateProfile")
								: t("account.saveChangesError");

						openNotification(
							t("account.saveError"),
							notificationMessage,
							NOTIFICATION_TYPES.ERROR
						);
					})
					.finally(() => {
						setSpinning(false);
						setModalVisibility(false);
					});
			})
			.catch(missingFieldsError)
			.finally(() => setModalVisibility(false));
	};

	const handleChangeAreasOfInterest = (value: any) => {
		setAreasOfInterest(value);
	};

	const handleChange = (event: any, field: any) => {
		event.persist();
		setState({
			...state,
			[field]: event.target.value,
		});
	};

	const onPushNotificationToggle = async (e: CheckboxChangeEvent) => {
		if (e.target.checked && pushNotificationSupported) {
			await subscribeToPushNotifications();
		} else if (!e.target.checked) {
			await unsubscribeFromPushNotifications();
		}
	};

	const openProfileErrorNotification = (ex: any) => {
		if (ex.status && ex.status === StatusCodes.NOT_FOUND) {
			openNotification(
				t("account.error"),
				t("account.notFoundProfile"),
				NOTIFICATION_TYPES.ERROR
			);
		} else {
			openNotification(
				t("account.error"),
				t("account.unknownError"),
				NOTIFICATION_TYPES.ERROR
			);
		}
	};

	useQuery(
		[getCompanyAccountInfo, isAdmin, id],
		async () => {
			return isAdmin && id ? await getCompanyById(id!) : null;
		},
		{
			onSuccess: (response) => {
				if (response) {
					setState(response);
					form.setFieldsValue({
						...form.getFieldsValue(),
						name: response.commercialName || "",
						email: response.email || "",
						domainOfActivity: response?.domainOfActivity || "",
						preferredLanguage: response.preferredLanguage?.toLowerCase(),
						siteUrl: response.siteUrl || "",
						phone: response.phone || "",
						linkedInUrl: response.linkedInUrl,
						description: response.description,
						address: response.address,
						postalCode: response.postalCode,
					} as any);
				}
			},
			onError: openProfileErrorNotification,
		}
	);

	useEffect(() => {
		setState({
			...state,
			fullResLogo: profilePicture,
			lowResLogo: lowResPicture,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [profilePicture, lowResPicture]);

	return (
		<div className="flex flex-col">
			<div className="flex gap-20 flex-col items-center lg:flex-row lg:items-start max-w-[728px] py-20 px-4 mx-auto">
				<div className="flex flex-col gap-10">
					<div className="relative">
						<ImageCropModal
							changeProfilePicture={setProfilePicture}
							changeLowResPicture={setLowResPicture}
							modalVisibility={cropImageModalVisibility}
							changeModalVisibility={setCropImageModalVisibility}
						/>

						{state && (
							<img
								src={state?.fullResLogo ?? state?.lowResLogo ?? nophoto}
								alt="Profile"
								className="w-[172px] h-[172px] rounded-full"
							/>
						)}

						<div className="absolute bottom-0 right-0 flex gap-2 translate-y-1/2">
							<Button
								danger
								onClick={() => {
									setProfilePicture(null);
									setLowResPicture(null);
								}}
								title={t("account.deletePicture")}
							>
								<FontAwesomeIcon icon={solid("trash")} />
							</Button>

							<Button
								type="primary"
								onClick={() => setCropImageModalVisibility(true)}
								title={t("account.changePicture")}
							>
								<FontAwesomeIcon icon={solid("camera")} />
							</Button>
						</div>
					</div>

					<div className="flex flex-col gap-6">
						<Button onClick={history.goBack}>
							{t("profile.backToInternships")}
						</Button>

						<Button
							htmlType="submit"
							style={{
								background: theme.secondColor,
								color: theme.white,
								boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
							}}
							onClick={() => setModalVisibility(true)}
						>
							{t("account.save")}
						</Button>
					</div>

					<ConfirmationModal
						modalText={t("account.saveMessage")}
						handleFunction={handleSave}
						modalVisibility={modalVisibility}
						title=""
						changeModalVisibility={() => setModalVisibility(false)}
						spinning={spinning}
					/>
				</div>

				<div className="flex flex-col gap-4">
					<div className="flex flex-col gap-2">
						<Checkbox
							disabled={!pushNotificationSupported}
							checked={userSubscription !== null}
							onChange={async (e: CheckboxChangeEvent) =>
								await onPushNotificationToggle(e)
							}
						>
							{t("modal.receivePushNotifications")}
						</Checkbox>
					</div>

					<Form.Provider onFormFinish={handleSave}>
						<Form form={form} layout={theme.layout} initialValues={state}>
							<div className="grid w-full grid-cols-1 md:grid-cols-2 gap-x-4">
								<Form.Item
									name="name"
									label={t("account.commercialName") + ":"}
									rules={[
										{
											required: true,
											message: t("account.requiredField", {
												field: t("account.commercialName"),
											}),
										},
									]}
								>
									<Input
										onChange={(event: any) => handleChange(event, "name")}
									/>
								</Form.Item>

								<Form.Item
									name="preferredLanguage"
									label={t("account.preferredLanguage") + ":"}
									rules={[
										{
											whitespace: true,
										},
									]}
								>
									<Select onChange={changeLng}>
										<Option value="ro">{t("account.romanian")}</Option>
										<Option value="en">{t("account.english")}</Option>
									</Select>
								</Form.Item>

								<Form.Item
									name="email"
									label={t("account.contactEmail") + ":"}
									rules={[
										{
											required: true,
											message: t("account.requiredField", {
												field: t("account.contactEmail"),
											}),
											whitespace: true,
										},
										{
											type: "email",
											message: t("usersText.invalidField", {
												field: t("account.contactEmail"),
											}),
										},
									]}
								>
									<Input
										onChange={(event: any) => handleChange(event, "email")}
									/>
								</Form.Item>

								<Form.Item
									name="phone"
									label={t("account.phone") + ":"}
									rules={[
										{
											pattern: phonePattern,
											message: t("usersText.invalidField", {
												field: t("account.phone"),
											}),
										},
									]}
								>
									<Input
										onChange={(event: any) => handleChange(event, "phone")}
									/>
								</Form.Item>

								<>
									<Form.Item
										name="address"
										label={t("account.address") + ":"}
										rules={[
											{
												required: true,
												message: t("account.requiredField", {
													field: t("account.address"),
												}),
												whitespace: true,
											},
										]}
									>
										<Input
											onChange={(event: any) => handleChange(event, "address")}
										/>
									</Form.Item>

									<Form.Item
										name="postalCode"
										label={t("account.postalCode") + ":"}
										rules={[
											{
												required: true,
												message: t("account.requiredField", {
													field: t("account.postalCode"),
												}),
												whitespace: true,
											},
										]}
									>
										<Input
											onChange={(event: any) =>
												handleChange(event, "postalCode")
											}
										/>
									</Form.Item>
								</>
								<Form.Item
									name="domainOfActivity"
									label={t("account.activityDomain") + ":"}
								>
									<Input
										onChange={(event: any) =>
											handleChange(event, "domainOfActivity")
										}
									/>
								</Form.Item>
								<Form.Item
									name="siteUrl"
									label={t("account.siteLink") + ":"}
									rules={[
										{
											required: true,
											message: t("account.requiredField", {
												field: t("account.siteLink"),
											}),
											whitespace: true,
										},
										{
											pattern: urlPattern,
											message: t("usersText.invalidField", {
												field: t("account.siteLink"),
											}),
										},
									]}
								>
									<Input
										onChange={(event: any) => handleChange(event, "siteUrl")}
									/>
								</Form.Item>
								<Form.Item
									name="linkedInUrl"
									label={t("account.companyLinkedin") + ":"}
									rules={[
										{
											required: true,
											message: t("account.requiredField", {
												field: t("account.companyLinkedin"),
											}),
											whitespace: true,
										},
										{
											pattern: urlPattern,
											message: t("usersText.invalidField", {
												field: t("account.link"),
											}),
										},
									]}
								>
									<Input
										onChange={(event: any) =>
											handleChange(event, "linkedInUrl")
										}
									/>
								</Form.Item>
							</div>
						</Form>
					</Form.Provider>
				</div>
			</div>

			<Form
				form={form}
				layout={theme.layout}
				initialValues={{ description: state?.description }}
				className="px-4"
			>
				<Form.Item
					name="areasOfInterest"
					label={t("account.areasOfInterest") + ":"}
				>
					<Select
						mode="tags"
						maxTagCount="responsive"
						allowClear
						style={{ display: "inherit", borderRadius: "4px" }}
						value={areasOfInterest}
						onChange={handleChangeAreasOfInterest}
					/>
				</Form.Item>
				<Form.Item
					name="description"
					label={t("account.description") + ":"}
					rules={[
						{
							whitespace: true,
						},
					]}
				>
					<CustomEditor
						content={state?.description}
						onEditorChange={(newDescription: string) =>
							setState({
								...state,
								description: newDescription,
							})
						}
					/>
				</Form.Item>
			</Form>
			{id && <CompanyUserTable companyId={id} />}
		</div>
	);
};

export default CompanyAccount;
