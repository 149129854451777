/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface CompanyFacultyAccordsDTO
 */
export interface CompanyFacultyAccordsDTO {
	/**
	 *
	 * @type {string}
	 * @memberof CompanyFacultyAccordsDTO
	 */
	companyId?: string;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyFacultyAccordsDTO
	 */
	companyName?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyFacultyAccordsDTO
	 */
	companyCif?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyFacultyAccordsDTO
	 */
	facultiesWithValidAccordsCommaSeparated?: string | null;
}

export function CompanyFacultyAccordsDTOFromJSON(
	json: any
): CompanyFacultyAccordsDTO {
	return CompanyFacultyAccordsDTOFromJSONTyped(json, false);
}

export function CompanyFacultyAccordsDTOFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): CompanyFacultyAccordsDTO {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		companyId: !exists(json, "companyId") ? undefined : json["companyId"],
		companyName: !exists(json, "companyName") ? undefined : json["companyName"],
		companyCif: !exists(json, "companyCif") ? undefined : json["companyCif"],
		facultiesWithValidAccordsCommaSeparated: !exists(
			json,
			"facultiesWithValidAccordsCommaSeparated"
		)
			? undefined
			: json["facultiesWithValidAccordsCommaSeparated"],
	};
}

export function CompanyFacultyAccordsDTOToJSON(
	value?: CompanyFacultyAccordsDTO | null
): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		companyId: value.companyId,
		companyName: value.companyName,
		companyCif: value.companyCif,
		facultiesWithValidAccordsCommaSeparated:
			value.facultiesWithValidAccordsCommaSeparated,
	};
}
