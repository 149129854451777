import { AddUpdateCompanyUserFormModel } from "../models/AddUpdateCompanyUserFormModel";
import { CompanyUserAddUpdateDTO } from "../../../../Api";

export const toDomainModel = (
	model: AddUpdateCompanyUserFormModel
): CompanyUserAddUpdateDTO => ({
	name: model.name,
	email: model.email,
	phone: model.phone.trim() ?? "",
	_function: model.function,
	department: model.department,
});
