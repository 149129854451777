import { AddUpdateCompanyModalFormProps } from "./AddUpdateCompanyModalForm.types";
import { useTranslation } from "react-i18next";
import { Button, Form, Input, Modal, Spin } from "antd";
import { theme } from "../../../theme";
import CustomForm from "../../../CustomComponents/CustomForm";
import { useAddUpdateCompanyModalFormController } from "./AddUpdateCompanyModalForm.controller";

export const AddUpdateCompanyModalForm = (
	props: AddUpdateCompanyModalFormProps
) => {
	const { t } = useTranslation();
	const { state, actions, computed } =
		useAddUpdateCompanyModalFormController(props);

	return (
		<>
			<Button
				type="primary"
				onClick={actions.handleShow}
				title={t("companies.addCompany")}
			>
				{t("companies.addCompany")}
			</Button>

			<Modal
				open={state.isModalVisible || props.visibility}
				onCancel={actions.handleClose}
				title={props.isEdit ? "" : t("companies.addCompany")}
				width={window.innerWidth > 1215 ? "30%" : "80%"}
				footer={null}
			>
				<Spin spinning={state.spinning}>
					<CustomForm
						layout={theme.layout}
						form={state.form}
						onFinish={actions.handleFinish}
					>
						<Form.Item
							name="cui"
							label={`${t("companies.cui")}:`}
							rules={[computed.yupSync]}
							initialValue={computed.defaultValues.cui}
							required
						>
							<Input
								placeholder={t("companies.cui")}
								disabled={props.record !== null}
							/>
						</Form.Item>
						<Form.Item
							name="name"
							label={`${t("companies.name")}:`}
							rules={[computed.yupSync]}
							initialValue={computed.defaultValues.name}
							required
						>
							<Input
								placeholder={t("companies.name")}
								disabled={props.record !== null}
							/>
						</Form.Item>

						<Form.Item
							name="commercialName"
							label={`${t("companies.commercialName")}:`}
							rules={[computed.yupSync]}
							initialValue={computed.defaultValues.commercialName}
							required
						>
							<Input placeholder={t("companies.commercialName")} />
						</Form.Item>

						<Form.Item
							name="address"
							label={`${t("companies.address")}:`}
							rules={[computed.yupSync]}
							initialValue={computed.defaultValues.address}
							required
						>
							<Input placeholder={t("companies.address")} />
						</Form.Item>

						<Form.Item
							name="phone"
							label={`${t("companies.phone")}:`}
							rules={[computed.yupSync]}
							initialValue={computed.defaultValues.phone}
							required
						>
							<Input placeholder={t("companies.phone")} />
						</Form.Item>

						<Form.Item
							name="emailContact"
							label={`${t("companies.email")}:`}
							rules={[computed.yupSync]}
							initialValue={computed.defaultValues.emailContact}
							required
						>
							<Input placeholder={t("companies.email")} />
						</Form.Item>

						<Form.Item
							name="domainOfActivity"
							label={`${t("companies.domainOfActivity")}:`}
							rules={[computed.yupSync]}
							initialValue={computed.defaultValues.domainOfActivity}
							required
						>
							<Input placeholder={t("companies.domainOfActivity")} />
						</Form.Item>

						<Form.Item
							name="fax"
							label={`${t("companies.fax")}:`}
							rules={[computed.yupSync]}
							initialValue={computed.defaultValues.fax}
						>
							<Input placeholder={t("companies.fax")} />
						</Form.Item>

						<Form.Item>
							<Button type="primary" htmlType="submit">
								{props.isEdit
									? t("companies.saveButton")
									: t("companies.addCompany")}
							</Button>
						</Form.Item>
					</CustomForm>
				</Spin>
			</Modal>
		</>
	);
};
