import { InternshipApplicantsTableProps } from "./InternshipApplicantsTable.types";
import { Pagination, Table, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { useQuery, useQueryClient } from "react-query";
import {
	getInterns,
	getTraineesFilters,
} from "../../../../../utils/reactQueriesConstants";
import {
	getInternsForEmployerV2,
	updateIsEnrollmentViewed,
} from "../../../../../Requests/internship-requests";
import { InternsForEmployerDTO, InternType } from "../../../../../Api";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../../../../Notifications/NotificationsUtils";
import { useDispatch } from "react-redux";
import { studentIdSet } from "../../../../Student/StudentSlice";
import { useHistory } from "react-router-dom";
import { InternshipApplicantActions } from "./components/InternshipApplicantActions/InternshipApplicantActions";
import { useCallback, useState } from "react";
import { AcceptApplicantModal } from "./components/modals/AcceptApplicantModal/AcceptApplicantModal";
import { FinalAcceptApplicantModal } from "./components/modals/FinalAcceptApplicantModal/FinalAcceptApplicantModal";
import { RejectApplicantModal } from "./components/modals/RejectApplicantModal/RejectApplicantModal";
import useQueryFilters from "../../../../../Hooks/useQueryFilters";

export const InternshipApplicantsTable = (
	props: InternshipApplicantsTableProps
) => {
	const { t } = useTranslation();
	const [selectedApplicant, setSelectedApplicant] =
		useState<InternsForEmployerDTO>({});
	const [selectedTutor, setSelectedTutor] = useState("");
	const [acceptModalVisibility, setAcceptModalVisibility] = useState(false);
	const [finalAcceptModalVisibility, setFinalAcceptModalVisibility] =
		useState(false);
	const [rejectModalVisibility, setRejectModalVisibility] = useState(false);
	const dispatch = useDispatch();
	const history = useHistory();
	const query = useQueryFilters({});

	let locale = {
		emptyText: t("tableText.noApplicants"),
	};

	const goToStudentProfile = (id: any, enrollmentId: any) => {
		updateViewApplication(enrollmentId, true);
		dispatch(studentIdSet(id));
		history.push("/student");
	};

	const updateViewApplication = (id: any, viewed: boolean) => {
		updateIsEnrollmentViewed(id, viewed).then(async () => {
			await queryClient.invalidateQueries(getInterns);
			await queryClient.invalidateQueries(getTraineesFilters);
		});
	};

	const columns = [
		{
			title: t("students.name"),
			key: "name",
			dataIndex: "name",
			fixed: "left" as "left",
			// sorter: (a: InternsForEmployerDTO, b: InternsForEmployerDTO) => {
			// 	if (!a.name || !b.name) return -1;
			// 	return a.name.localeCompare(b.name);
			// },
			render: (text: string | undefined, record: InternsForEmployerDTO) => {
				return (
					<a
						onClick={() =>
							goToStudentProfile(record.id, record.internshipEnrollmentId)
						}
					>
						<b>{text}</b>
					</a>
				);
			},
		},
		{
			title: t("students.group"),
			key: "group",
			dataIndex: "group",
			// sorter: (a: InternsForEmployerDTO, b: InternsForEmployerDTO) => {
			// 	if (!a.group || !b.group) return -1;
			// 	return a.group.localeCompare(b.group);
			// },
		},
		{
			title: t("students.email"),
			dataIndex: "email",
			key: "email",
			// sorter: (a: InternsForEmployerDTO, b: InternsForEmployerDTO) => {
			// 	if (!a.email || !b.email) return -1;
			// 	return a.email.localeCompare(b.email);
			// },
			render: (_: string | undefined, record: InternsForEmployerDTO) => (
				<span>
					{record.personalEmail !== "" && record.personalEmail !== undefined
						? record.personalEmail
						: record.email}
				</span>
			),
		},
		{
			title: t("students.actions"),
			key: "actions",
			dataIndex: "actions",
			fixed: "right" as "right",
			width: 85,
			render: (_: string | undefined, record: InternsForEmployerDTO) => {
				return (
					<InternshipApplicantActions
						record={record}
						setSelectedApplicant={setSelectedApplicant}
						setSelectedTutor={setSelectedTutor}
						setAcceptModalVisibility={setAcceptModalVisibility}
						setFinalAcceptModalVisibility={setFinalAcceptModalVisibility}
						setRejectModalVisibility={setRejectModalVisibility}
					/>
				);
			},
		},
	];

	const openGetErrorNotification = (_error: any) => {
		openNotification(
			t("usersText.errorTexts.failedUserGet"),
			t("usersText.errorTexts.serverFailedDescription"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const queryClient = useQueryClient();
	const { data, isLoading: loading } = useQuery(
		[getInterns, props.internship.internshipName, query.filters],
		() => {
			return getInternsForEmployerV2({
				type: InternType.Applicant,
				internships: [props.internship.internshipName],
				...query.filters,
			});
		},
		{
			onError: openGetErrorNotification,
		}
	);

	const invalidateQuery = async () => {
		await queryClient.invalidateQueries(getInterns);
	};

	const onPageUpdate = useCallback(
		(page: number, pageSize: number) => {
			query.update({
				page: page.toString(),
				pageSize: pageSize.toString(),
			});
		},
		[query]
	);

	return (
		<div className="w-full">
			<h2>{t("internships.applicants")}</h2>
			<div className="flex flex-col md:flex-row justify-between items-center">
				<Typography.Title level={4} className="my-0">
					{data?.totalCount} {t("filtering.results")}
				</Typography.Title>
				{!loading && (
					<Pagination
						defaultCurrent={data?.page}
						defaultPageSize={data?.pageSize}
						total={data?.totalCount}
						onChange={onPageUpdate}
						locale={{ items_per_page: t("pagination") }}
					/>
				)}
			</div>

			<Table
				locale={locale}
				columns={columns}
				dataSource={data?.data ?? []}
				pagination={false}
				scroll={{ x: "calc(300px + 50%)", y: 420 }}
				rowKey={(record) => record.id!}
			/>
			<AcceptApplicantModal
				selectedApplicant={selectedApplicant}
				selectedTutor={selectedTutor}
				modalVisibility={acceptModalVisibility}
				invalidateApplicantsQueries={invalidateQuery}
				setModalVisibility={setAcceptModalVisibility}
				setSelectedTutor={setSelectedTutor}
			/>
			<FinalAcceptApplicantModal
				selectedApplicant={selectedApplicant}
				modalVisibility={finalAcceptModalVisibility}
				invalidateApplicantsQueries={invalidateQuery}
				setModalVisibility={setFinalAcceptModalVisibility}
			/>
			<RejectApplicantModal
				selectedApplicant={selectedApplicant}
				modalVisibility={rejectModalVisibility}
				invalidateApplicantsQueries={invalidateQuery}
				setModalVisibility={setRejectModalVisibility}
			/>
		</div>
	);
};
