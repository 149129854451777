/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface AddAnswerDTO
 */
export interface AddAnswerDTO {
	/**
	 *
	 * @type {string}
	 * @memberof AddAnswerDTO
	 */
	questionId?: string;
	/**
	 *
	 * @type {Array<string>}
	 * @memberof AddAnswerDTO
	 */
	answers?: Array<string> | null;
}

export function AddAnswerDTOFromJSON(json: any): AddAnswerDTO {
	return AddAnswerDTOFromJSONTyped(json, false);
}

export function AddAnswerDTOFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): AddAnswerDTO {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		questionId: !exists(json, "questionId") ? undefined : json["questionId"],
		answers: !exists(json, "answers") ? undefined : json["answers"],
	};
}

export function AddAnswerDTOToJSON(value?: AddAnswerDTO | null): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		questionId: value.questionId,
		answers: value.answers,
	};
}
