/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
	CompanyUser,
	CompanyUserFromJSON,
	CompanyUserFromJSONTyped,
	CompanyUserToJSON,
} from "./CompanyUser";

/**
 *
 * @export
 * @interface PasswordAction
 */
export interface PasswordAction {
	/**
	 *
	 * @type {string}
	 * @memberof PasswordAction
	 */
	id?: string;
	/**
	 *
	 * @type {Date}
	 * @memberof PasswordAction
	 */
	createdAt?: Date;
	/**
	 *
	 * @type {Date}
	 * @memberof PasswordAction
	 */
	updatedAt?: Date;
	/**
	 *
	 * @type {string}
	 * @memberof PasswordAction
	 */
	companyUserId?: string;
	/**
	 *
	 * @type {boolean}
	 * @memberof PasswordAction
	 */
	isComplete?: boolean;
	/**
	 *
	 * @type {CompanyUser}
	 * @memberof PasswordAction
	 */
	companyUser?: CompanyUser;
}

export function PasswordActionFromJSON(json: any): PasswordAction {
	return PasswordActionFromJSONTyped(json, false);
}

export function PasswordActionFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): PasswordAction {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		id: !exists(json, "id") ? undefined : json["id"],
		createdAt: !exists(json, "createdAt")
			? undefined
			: new Date(json["createdAt"]),
		updatedAt: !exists(json, "updatedAt")
			? undefined
			: new Date(json["updatedAt"]),
		companyUserId: !exists(json, "companyUserId")
			? undefined
			: json["companyUserId"],
		isComplete: !exists(json, "isComplete") ? undefined : json["isComplete"],
		companyUser: !exists(json, "companyUser")
			? undefined
			: CompanyUserFromJSON(json["companyUser"]),
	};
}

export function PasswordActionToJSON(value?: PasswordAction | null): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		id: value.id,
		createdAt:
			value.createdAt === undefined ? undefined : value.createdAt.toISOString(),
		updatedAt:
			value.updatedAt === undefined ? undefined : value.updatedAt.toISOString(),
		companyUserId: value.companyUserId,
		isComplete: value.isComplete,
		companyUser: CompanyUserToJSON(value.companyUser),
	};
}
