/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
	ProfessorsReportDTO,
	ProfessorsReportDTOFromJSON,
	ProfessorsReportDTOFromJSONTyped,
	ProfessorsReportDTOToJSON,
} from "./ProfessorsReportDTO";

/**
 *
 * @export
 * @interface ProfessorsReportDTOPagedResponse
 */
export interface ProfessorsReportDTOPagedResponse {
	/**
	 *
	 * @type {number}
	 * @memberof ProfessorsReportDTOPagedResponse
	 */
	page?: number;
	/**
	 *
	 * @type {number}
	 * @memberof ProfessorsReportDTOPagedResponse
	 */
	pageSize?: number;
	/**
	 *
	 * @type {number}
	 * @memberof ProfessorsReportDTOPagedResponse
	 */
	totalCount?: number;
	/**
	 *
	 * @type {Array<ProfessorsReportDTO>}
	 * @memberof ProfessorsReportDTOPagedResponse
	 */
	data?: Array<ProfessorsReportDTO> | null;
}

export function ProfessorsReportDTOPagedResponseFromJSON(
	json: any
): ProfessorsReportDTOPagedResponse {
	return ProfessorsReportDTOPagedResponseFromJSONTyped(json, false);
}

export function ProfessorsReportDTOPagedResponseFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): ProfessorsReportDTOPagedResponse {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		page: !exists(json, "page") ? undefined : json["page"],
		pageSize: !exists(json, "pageSize") ? undefined : json["pageSize"],
		totalCount: !exists(json, "totalCount") ? undefined : json["totalCount"],
		data: !exists(json, "data")
			? undefined
			: json["data"] === null
			? null
			: (json["data"] as Array<any>).map(ProfessorsReportDTOFromJSON),
	};
}

export function ProfessorsReportDTOPagedResponseToJSON(
	value?: ProfessorsReportDTOPagedResponse | null
): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		page: value.page,
		pageSize: value.pageSize,
		totalCount: value.totalCount,
		data:
			value.data === undefined
				? undefined
				: value.data === null
				? null
				: (value.data as Array<any>).map(ProfessorsReportDTOToJSON),
	};
}
