/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
	StudentAppreciationAddUpdateDTO,
	StudentAppreciationAddUpdateDTOFromJSON,
	StudentAppreciationAddUpdateDTOToJSON,
	StudentAppreciationDTO,
	StudentAppreciationDTOFromJSON,
	StudentAppreciationDTOToJSON,
} from "../models";

export interface ApiStudentAppreciationAddPostRequest {
	studentAppreciationAddUpdateDTO?: StudentAppreciationAddUpdateDTO;
}

export interface ApiStudentAppreciationDeleteIdDeleteRequest {
	id: string;
}

export interface ApiStudentAppreciationGetAllForStudentStudentIdGetRequest {
	studentId: string;
}

export interface ApiStudentAppreciationUpdatePutRequest {
	studentAppreciationAddUpdateDTO?: StudentAppreciationAddUpdateDTO;
}

/**
 *
 */
export class StudentAppreciationApi extends runtime.BaseAPI {
	/**
	 */
	async apiStudentAppreciationAddPostRaw(
		requestParameters: ApiStudentAppreciationAddPostRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<StudentAppreciationDTO>> {
		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		headerParameters["Content-Type"] = "application/json";

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/StudentAppreciation/Add`,
				method: "POST",
				headers: headerParameters,
				query: queryParameters,
				body: StudentAppreciationAddUpdateDTOToJSON(
					requestParameters.studentAppreciationAddUpdateDTO
				),
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			StudentAppreciationDTOFromJSON(jsonValue)
		);
	}

	/**
	 */
	async apiStudentAppreciationAddPost(
		requestParameters: ApiStudentAppreciationAddPostRequest = {},
		initOverrides?: RequestInit
	): Promise<StudentAppreciationDTO> {
		const response = await this.apiStudentAppreciationAddPostRaw(
			requestParameters,
			initOverrides
		);
		return await response.value();
	}

	/**
	 */
	async apiStudentAppreciationDeleteIdDeleteRaw(
		requestParameters: ApiStudentAppreciationDeleteIdDeleteRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<void>> {
		if (requestParameters.id === null || requestParameters.id === undefined) {
			throw new runtime.RequiredError(
				"id",
				"Required parameter requestParameters.id was null or undefined when calling apiStudentAppreciationDeleteIdDelete."
			);
		}

		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/StudentAppreciation/Delete/{id}`.replace(
					`{${"id"}}`,
					encodeURIComponent(String(requestParameters.id))
				),
				method: "DELETE",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.VoidApiResponse(response);
	}

	/**
	 */
	async apiStudentAppreciationDeleteIdDelete(
		requestParameters: ApiStudentAppreciationDeleteIdDeleteRequest,
		initOverrides?: RequestInit
	): Promise<void> {
		await this.apiStudentAppreciationDeleteIdDeleteRaw(
			requestParameters,
			initOverrides
		);
	}

	/**
	 */
	async apiStudentAppreciationGetAllForStudentStudentIdGetRaw(
		requestParameters: ApiStudentAppreciationGetAllForStudentStudentIdGetRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<Array<StudentAppreciationDTO>>> {
		if (
			requestParameters.studentId === null ||
			requestParameters.studentId === undefined
		) {
			throw new runtime.RequiredError(
				"studentId",
				"Required parameter requestParameters.studentId was null or undefined when calling apiStudentAppreciationGetAllForStudentStudentIdGet."
			);
		}

		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/StudentAppreciation/GetAllForStudent/{studentId}`.replace(
					`{${"studentId"}}`,
					encodeURIComponent(String(requestParameters.studentId))
				),
				method: "GET",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			jsonValue.map(StudentAppreciationDTOFromJSON)
		);
	}

	/**
	 */
	async apiStudentAppreciationGetAllForStudentStudentIdGet(
		requestParameters: ApiStudentAppreciationGetAllForStudentStudentIdGetRequest,
		initOverrides?: RequestInit
	): Promise<Array<StudentAppreciationDTO>> {
		const response =
			await this.apiStudentAppreciationGetAllForStudentStudentIdGetRaw(
				requestParameters,
				initOverrides
			);
		return await response.value();
	}

	/**
	 */
	async apiStudentAppreciationUpdatePutRaw(
		requestParameters: ApiStudentAppreciationUpdatePutRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<StudentAppreciationDTO>> {
		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		headerParameters["Content-Type"] = "application/json";

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/StudentAppreciation/Update`,
				method: "PUT",
				headers: headerParameters,
				query: queryParameters,
				body: StudentAppreciationAddUpdateDTOToJSON(
					requestParameters.studentAppreciationAddUpdateDTO
				),
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			StudentAppreciationDTOFromJSON(jsonValue)
		);
	}

	/**
	 */
	async apiStudentAppreciationUpdatePut(
		requestParameters: ApiStudentAppreciationUpdatePutRequest = {},
		initOverrides?: RequestInit
	): Promise<StudentAppreciationDTO> {
		const response = await this.apiStudentAppreciationUpdatePutRaw(
			requestParameters,
			initOverrides
		);
		return await response.value();
	}
}
