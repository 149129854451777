/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
	AcademicUser,
	AcademicUserFromJSON,
	AcademicUserFromJSONTyped,
	AcademicUserToJSON,
} from "./AcademicUser";
import {
	PoliJobsProposal,
	PoliJobsProposalFromJSON,
	PoliJobsProposalFromJSONTyped,
	PoliJobsProposalToJSON,
} from "./PoliJobsProposal";
import {
	PoliJobsRecommendationStatus,
	PoliJobsRecommendationStatusFromJSON,
	PoliJobsRecommendationStatusFromJSONTyped,
	PoliJobsRecommendationStatusToJSON,
} from "./PoliJobsRecommendationStatus";

/**
 *
 * @export
 * @interface PoliJobsPotentialCandidate
 */
export interface PoliJobsPotentialCandidate {
	/**
	 *
	 * @type {string}
	 * @memberof PoliJobsPotentialCandidate
	 */
	id?: string;
	/**
	 *
	 * @type {Date}
	 * @memberof PoliJobsPotentialCandidate
	 */
	createdAt?: Date;
	/**
	 *
	 * @type {Date}
	 * @memberof PoliJobsPotentialCandidate
	 */
	updatedAt?: Date;
	/**
	 *
	 * @type {string}
	 * @memberof PoliJobsPotentialCandidate
	 */
	proposalId?: string;
	/**
	 *
	 * @type {string}
	 * @memberof PoliJobsPotentialCandidate
	 */
	candidateId?: string;
	/**
	 *
	 * @type {PoliJobsRecommendationStatus}
	 * @memberof PoliJobsPotentialCandidate
	 */
	status?: PoliJobsRecommendationStatus;
	/**
	 *
	 * @type {number}
	 * @memberof PoliJobsPotentialCandidate
	 */
	score?: number;
	/**
	 *
	 * @type {PoliJobsProposal}
	 * @memberof PoliJobsPotentialCandidate
	 */
	proposal?: PoliJobsProposal;
	/**
	 *
	 * @type {AcademicUser}
	 * @memberof PoliJobsPotentialCandidate
	 */
	candidate?: AcademicUser;
}

export function PoliJobsPotentialCandidateFromJSON(
	json: any
): PoliJobsPotentialCandidate {
	return PoliJobsPotentialCandidateFromJSONTyped(json, false);
}

export function PoliJobsPotentialCandidateFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): PoliJobsPotentialCandidate {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		id: !exists(json, "id") ? undefined : json["id"],
		createdAt: !exists(json, "createdAt")
			? undefined
			: new Date(json["createdAt"]),
		updatedAt: !exists(json, "updatedAt")
			? undefined
			: new Date(json["updatedAt"]),
		proposalId: !exists(json, "proposalId") ? undefined : json["proposalId"],
		candidateId: !exists(json, "candidateId") ? undefined : json["candidateId"],
		status: !exists(json, "status")
			? undefined
			: PoliJobsRecommendationStatusFromJSON(json["status"]),
		score: !exists(json, "score") ? undefined : json["score"],
		proposal: !exists(json, "proposal")
			? undefined
			: PoliJobsProposalFromJSON(json["proposal"]),
		candidate: !exists(json, "candidate")
			? undefined
			: AcademicUserFromJSON(json["candidate"]),
	};
}

export function PoliJobsPotentialCandidateToJSON(
	value?: PoliJobsPotentialCandidate | null
): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		id: value.id,
		createdAt:
			value.createdAt === undefined ? undefined : value.createdAt.toISOString(),
		updatedAt:
			value.updatedAt === undefined ? undefined : value.updatedAt.toISOString(),
		proposalId: value.proposalId,
		candidateId: value.candidateId,
		status: PoliJobsRecommendationStatusToJSON(value.status),
		score: value.score,
		proposal: PoliJobsProposalToJSON(value.proposal),
		candidate: AcademicUserToJSON(value.candidate),
	};
}
