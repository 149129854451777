import { FacultySettingsAddDTO, FacultySettingsApi } from "../Api";
import { getAuthConfiguration } from "./configuration";

const AuthFacultySettingsApiFactory = () =>
	new FacultySettingsApi(getAuthConfiguration());

export const getFacultySettings = () => {
	return AuthFacultySettingsApiFactory().apiFacultySettingsGetGet();
};

export const addFacultySettings = async (
	facultySettingsAddDTO: FacultySettingsAddDTO
) => {
	await AuthFacultySettingsApiFactory().apiFacultySettingsAddPost({
		facultySettingsAddDTO,
	});
};

export const updateFacultySettings = async (
	facultySettingsAddDTO: FacultySettingsAddDTO
) => {
	await AuthFacultySettingsApiFactory().apiFacultySettingsUpdatePost({
		facultySettingsAddDTO: facultySettingsAddDTO,
	});
};
