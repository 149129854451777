/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
	AcademicUser,
	AcademicUserFromJSON,
	AcademicUserFromJSONTyped,
	AcademicUserToJSON,
} from "./AcademicUser";
import {
	CompanyUser,
	CompanyUserFromJSON,
	CompanyUserFromJSONTyped,
	CompanyUserToJSON,
} from "./CompanyUser";

/**
 *
 * @export
 * @interface PushNotificationSubscription
 */
export interface PushNotificationSubscription {
	/**
	 *
	 * @type {string}
	 * @memberof PushNotificationSubscription
	 */
	id?: string;
	/**
	 *
	 * @type {Date}
	 * @memberof PushNotificationSubscription
	 */
	createdAt?: Date;
	/**
	 *
	 * @type {Date}
	 * @memberof PushNotificationSubscription
	 */
	updatedAt?: Date;
	/**
	 *
	 * @type {string}
	 * @memberof PushNotificationSubscription
	 */
	endpoint?: string | null;
	/**
	 *
	 * @type {Date}
	 * @memberof PushNotificationSubscription
	 */
	expirationTime?: Date;
	/**
	 *
	 * @type {string}
	 * @memberof PushNotificationSubscription
	 */
	p256dh?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof PushNotificationSubscription
	 */
	auth?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof PushNotificationSubscription
	 */
	academicUserId?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof PushNotificationSubscription
	 */
	companyUserId?: string | null;
	/**
	 *
	 * @type {AcademicUser}
	 * @memberof PushNotificationSubscription
	 */
	academicUser?: AcademicUser;
	/**
	 *
	 * @type {CompanyUser}
	 * @memberof PushNotificationSubscription
	 */
	companyUser?: CompanyUser;
}

export function PushNotificationSubscriptionFromJSON(
	json: any
): PushNotificationSubscription {
	return PushNotificationSubscriptionFromJSONTyped(json, false);
}

export function PushNotificationSubscriptionFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): PushNotificationSubscription {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		id: !exists(json, "id") ? undefined : json["id"],
		createdAt: !exists(json, "createdAt")
			? undefined
			: new Date(json["createdAt"]),
		updatedAt: !exists(json, "updatedAt")
			? undefined
			: new Date(json["updatedAt"]),
		endpoint: !exists(json, "endpoint") ? undefined : json["endpoint"],
		expirationTime: !exists(json, "expirationTime")
			? undefined
			: new Date(json["expirationTime"]),
		p256dh: !exists(json, "p256dh") ? undefined : json["p256dh"],
		auth: !exists(json, "auth") ? undefined : json["auth"],
		academicUserId: !exists(json, "academicUserId")
			? undefined
			: json["academicUserId"],
		companyUserId: !exists(json, "companyUserId")
			? undefined
			: json["companyUserId"],
		academicUser: !exists(json, "academicUser")
			? undefined
			: AcademicUserFromJSON(json["academicUser"]),
		companyUser: !exists(json, "companyUser")
			? undefined
			: CompanyUserFromJSON(json["companyUser"]),
	};
}

export function PushNotificationSubscriptionToJSON(
	value?: PushNotificationSubscription | null
): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		id: value.id,
		createdAt:
			value.createdAt === undefined ? undefined : value.createdAt.toISOString(),
		updatedAt:
			value.updatedAt === undefined ? undefined : value.updatedAt.toISOString(),
		endpoint: value.endpoint,
		expirationTime:
			value.expirationTime === undefined
				? undefined
				: value.expirationTime.toISOString(),
		p256dh: value.p256dh,
		auth: value.auth,
		academicUserId: value.academicUserId,
		companyUserId: value.companyUserId,
		academicUser: AcademicUserToJSON(value.academicUser),
		companyUser: CompanyUserToJSON(value.companyUser),
	};
}
