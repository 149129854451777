/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
	InternshipEngagementType,
	InternshipEngagementTypeFromJSON,
	InternshipEngagementTypeFromJSONTyped,
	InternshipEngagementTypeToJSON,
} from "./InternshipEngagementType";

/**
 *
 * @export
 * @interface InternshipLimitsAddUpdateDTO
 */
export interface InternshipLimitsAddUpdateDTO {
	/**
	 *
	 * @type {InternshipEngagementType}
	 * @memberof InternshipLimitsAddUpdateDTO
	 */
	internshipType?: InternshipEngagementType;
	/**
	 *
	 * @type {number}
	 * @memberof InternshipLimitsAddUpdateDTO
	 */
	maximumApplicants?: number;
}

export function InternshipLimitsAddUpdateDTOFromJSON(
	json: any
): InternshipLimitsAddUpdateDTO {
	return InternshipLimitsAddUpdateDTOFromJSONTyped(json, false);
}

export function InternshipLimitsAddUpdateDTOFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): InternshipLimitsAddUpdateDTO {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		internshipType: !exists(json, "internshipType")
			? undefined
			: InternshipEngagementTypeFromJSON(json["internshipType"]),
		maximumApplicants: !exists(json, "maximumApplicants")
			? undefined
			: json["maximumApplicants"],
	};
}

export function InternshipLimitsAddUpdateDTOToJSON(
	value?: InternshipLimitsAddUpdateDTO | null
): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		internshipType: InternshipEngagementTypeToJSON(value.internshipType),
		maximumApplicants: value.maximumApplicants,
	};
}
