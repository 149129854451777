import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import styles from "../MyAccount/MyAccount.module.scss";
import { theme } from "../../theme";
import CustomButton from "../../CustomComponents/CustomButton";
import { List, Modal, Spin, Switch } from "antd";
import { Role } from "../../Api";
import "./Users.module.scss";
import { facultyAdminRolesArray, rolesArray } from "../../utils/constants";
import { deanRolesArray } from "../../utils/constants";
import { updateAcademicUserRole } from "../../Requests/academic-user-requests";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../Notifications/NotificationsUtils";
import CustomModalFooter from "../../Containers/CustomModalFooter";
import {
	useIsAdmin,
	useIsDean,
	useIsFacultyAdmin,
} from "../../utils/utilFunctions";

const AddUserRolesModal = (props: {
	icon: any;
	roles: Array<Role>;
	userId: string;
}) => {
	const { t } = useTranslation();
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [spinning, setSpinning] = useState(false);
	const [userRoles, setUserRoles] = useState(props.roles);
	const [roles, setRoles] = useState([] as Role[]);
	const isAdmin = useIsAdmin();
	const isDean = useIsDean();
	const isFacultyAdmin = useIsFacultyAdmin();

	useEffect(() => {
		if (isAdmin) {
			setRoles(rolesArray);
		} else if (isDean) {
			setRoles(deanRolesArray);
		} else if (isFacultyAdmin) setRoles(facultyAdminRolesArray);
	}, [isAdmin, isDean, isFacultyAdmin]);

	const handleShow = () => {
		setIsModalVisible(true);
	};

	const handleClose = () => {
		setIsModalVisible(false);
	};

	const changeSwitchState = (item: any) => {
		if (!userRoles.includes(item)) {
			userRoles.push(item);
		} else {
			const index = userRoles.indexOf(item);
			userRoles.splice(index, 1);
		}
	};

	const handleOk = (id: string) => {
		setSpinning(true);
		updateAcademicUserRole(id, { roles: userRoles })
			.then(() => {
				openNotification(
					t("admin.editRoles"),
					t("admin.rolesUpdatedSuccessfully"),
					NOTIFICATION_TYPES.SUCCESS
				);
			})
			.catch((_error) => {
				openNotification(
					t("usersText.errorTexts.rolesUpdateError"),
					t("usersText.errorTexts.serverFailedDescription"),
					NOTIFICATION_TYPES.ERROR
				);
			})
			.finally(() => {
				setSpinning(false);
				setIsModalVisible(false);
			});
	};

	return (
		<>
			<CustomButton
				className={styles.addUserButton}
				backgroundcolor={"transparent"}
				textcolor={theme.black}
				paddingvertical={"19px"}
				fontSize={"0.9rem"}
				boxshadow={"none"}
				marginbottom={"3%"}
				marginleft={"auto"}
				icon={props.icon}
				onClick={handleShow}
				title={t("admin.editUserRoles")}
			></CustomButton>
			<Modal
				open={isModalVisible}
				onCancel={handleClose}
				onOk={() => handleOk(props.userId)}
				title={t("admin.editUserRoles")}
				width={window.innerWidth > 1215 ? "30%" : "80%"}
				footer={
					<CustomModalFooter
						handleClose={handleClose}
						spinning={spinning}
						handleSave={() => handleOk(props.userId)}
						confirmButtonName={t("account.save")}
					/>
				}
			>
				<Spin spinning={spinning}>
					<List
						dataSource={roles}
						renderItem={(item: any) => (
							<List.Item>
								<List.Item.Meta
									avatar={
										<Switch
											defaultChecked={props.roles.includes(item)}
											onChange={() => {
												changeSwitchState(item);
											}}
										/>
									}
									title={t("roles." + item)}
								/>
							</List.Item>
						)}
					></List>
				</Spin>
			</Modal>
		</>
	);
};

export default AddUserRolesModal;
