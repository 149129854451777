import { useState } from "react";
import Layout from "../../Containers/Layout";
import styles from "./PracticeNotebook.module.scss";
import { Form, InputNumber, Checkbox, Row, Col, Button, Input } from "antd";
import CustomForm from "../../CustomComponents/CustomForm";
import { theme } from "../../theme";
import { Redirect, useHistory } from "react-router-dom";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../Notifications/NotificationsUtils";
import {
	PracticalActivityDTO,
	PracticeNotebookAddDTO,
	PracticeNotebookState,
	PracticeNotebookUpdateDTO,
} from "../../Api";
import ConfirmationModal from "../../Containers/ConfirmationModal";
import { useTranslation } from "react-i18next";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import {
	addPracticeNotebook,
	getPracticeNotebookForInternshipEnrollment,
	updatePracticeNotebook,
} from "../../Requests/practice-notebook-requests";
import CustomButton from "../../CustomComponents/CustomButton";
import { selectStudentInternshipEnrollmentId } from "../Student/StudentSlice";
import { useSelector } from "react-redux";
import { useQuery } from "react-query";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import {
	getInternshipEnrollment,
	getPracticeNotebook,
} from "../../utils/reactQueriesConstants";
import { getInternshipEnrollmentById } from "../../Requests/internship-requests";

const PracticeNotebook = () => {
	const { t } = useTranslation();
	const [redirect, setRedirect] = useState(false);
	const [complete, setComplete] = useState(false);
	const [cancelModalVisibility, setCancelModalVisibility] = useState(false);
	const [addModalVisibility, setAddModalVisibility] = useState(false);
	const history = useHistory();
	const [activities, setActivities] = useState<PracticalActivityDTO[]>([
		{
			week: "",
			activity: "",
			description: "",
		},
	]);
	const internshipEnrollmentId = useSelector(
		selectStudentInternshipEnrollmentId
	);
	const [spinning, setSpinning] = useState(false);

	const openGetErrorNotification = (_error: any) => {
		openNotification(
			t("practiceNotebook.error"),
			t("practiceNotebook.fetchDataError"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const openSaveErrorNotification = (_error: any) => {
		openNotification(
			t("practiceNotebook.error"),
			t("practiceNotebook.saveDataError"),
			NOTIFICATION_TYPES.ERROR
		);
		setAddModalVisibility(false);
	};

	const [state, setState] = useState({
		title: "",
		totalHours: 0,
	});

	const [form] = Form.useForm();

	const { data: internshipEnrollment } = useQuery(
		[getInternshipEnrollment, internshipEnrollmentId],
		() => getInternshipEnrollmentById(internshipEnrollmentId!),
		{
			onError: (err) => {
				openGetErrorNotification(err);
			},
			onSuccess: (data) => {
				if (data) {
					form.setFieldsValue({
						totalNumberOfHours: data.totalPracticeHours,
					});

					setState((prevState) => {
						return {
							...prevState,
							totalHours: data.totalPracticeHours as number,
						};
					});
				}
			},
			refetchOnWindowFocus: false,
		}
	);

	const { data: practiceNotebook } = useQuery(
		[getPracticeNotebook, internshipEnrollmentId],
		() => getPracticeNotebookForInternshipEnrollment(internshipEnrollmentId!),
		{
			onError: (err: any) => {
				if (err.status !== 404) {
					openGetErrorNotification(err);
				}
			},
			onSuccess: (data) => {
				if (data) {
					if (
						data.state !== PracticeNotebookState.InProgress &&
						data.state !== PracticeNotebookState.PendingTutorSignature
					) {
						history.push("/404");
					}
					form.setFieldsValue({
						title: data.title,
						totalNumberOfHours: data.totalHours,
					});

					setState((prevState) => {
						return {
							...prevState,
							title: data.title!,
						};
					});
					setActivities(data.practicalActivities!);
					setComplete(data.state !== PracticeNotebookState.InProgress);

					data.practicalActivities.forEach((item, index) => {
						form.setFieldsValue({
							[`week${index}`]: item.week,
							[`activity${index}`]: item.activity,
							[`description${index}`]: item.description,
						});
					});
				}
			},
		}
	);

	const handleChangeInputs = (event: any, field: any) => {
		event.persist();
		setState({
			...state,
			[field]: event.target.value,
		});
	};

	const handleChangeNumberInputs = (value: any, field: any) => {
		setState({
			...state,
			[field]: value,
		});
	};

	const handleChangeCheckbox = (event: CheckboxChangeEvent) => {
		setComplete(event.target.checked);
	};

	const handleChangeActivity = (event: any, index: number, field: string) => {
		let practicalActivities = [...activities];
		practicalActivities[index] = {
			...practicalActivities[index],
			[field]: event.target.value,
		};

		setActivities(practicalActivities);
	};

	const handleCancel = () => {
		setCancelModalVisibility(false);
		setRedirect(true);
	};

	const openSuccessNotificationAndRedirect = () => {
		setRedirect(true);
		openNotification(
			t("practiceNotebook.editPracticeNotebook"),
			t("practiceNotebook.editPracticeNotebookSuccess"),
			NOTIFICATION_TYPES.SUCCESS,
			6
		);
	};

	const addNewActivity = () => {
		let practicalActivities = [...activities];
		practicalActivities.push({
			week: "",
			activity: "",
			description: "",
		});
		setActivities(practicalActivities);

		practicalActivities.forEach((item, index) => {
			form.setFieldsValue({
				[`week${index}`]: item.week,
				[`activity${index}`]: item.activity,
				[`description${index}`]: item.description,
			});
		});
	};

	const deleteActivity = (index: number) => {
		let practicalActivities = [...activities];
		practicalActivities.splice(index, 1);
		setActivities(() => {
			return practicalActivities;
		});

		practicalActivities.forEach((item, index) => {
			form.setFieldsValue({
				[`week${index}`]: item.week,
				[`activity${index}`]: item.activity,
				[`description${index}`]: item.description,
			});
		});
	};

	const renderActivities = () => {
		return activities.map((activity, index) => (
			<div className="flex flex-wrap items-end w-full gap-4 mb-2" key={index}>
				<Form.Item
					name={`week${index}`}
					noStyle={index > 0}
					style={{ marginBottom: 0 }}
					label={t("practiceNotebook.addPracticeNotebookForm.week") + ":"}
					rules={[
						{
							required: true,
							message: t("usersText.requiredField", {
								field: t("practiceNotebook.addPracticeNotebookForm.week"),
							}),
							whitespace: true,
						},
					]}
				>
					<Input
						className="w-80"
						placeholder={t("practiceNotebook.addPracticeNotebookForm.week")}
						onChange={(event) => handleChangeActivity(event, index, "week")}
						value={activity.week}
					/>
				</Form.Item>

				<Form.Item
					className="w-auto"
					name={`activity${index}`}
					noStyle={index > 0}
					style={{ marginBottom: 0 }}
					label={t("practiceNotebook.addPracticeNotebookForm.activity") + ":"}
					rules={[
						{
							required: true,
							message: t("usersText.requiredField", {
								field: t("practiceNotebook.addPracticeNotebookForm.activity"),
							}),
							whitespace: true,
						},
					]}
				>
					<Input
						className="w-96"
						placeholder={t("practiceNotebook.addPracticeNotebookForm.activity")}
						onChange={(event) => handleChangeActivity(event, index, "activity")}
						value={activity.activity}
					/>
				</Form.Item>

				<Form.Item
					name={`description${index}`}
					noStyle={index > 0}
					style={{ marginBottom: 0 }}
					label={
						t("practiceNotebook.addPracticeNotebookForm.description") + ":"
					}
					rules={[
						{
							required: true,
							message: t("usersText.requiredField", {
								field: t(
									"practiceNotebook.addPracticeNotebookForm.description"
								),
							}),
							whitespace: true,
						},
					]}
				>
					<Input
						className="w-80"
						placeholder={t(
							"practiceNotebook.addPracticeNotebookForm.description"
						)}
						onChange={(event) =>
							handleChangeActivity(event, index, "description")
						}
						value={activity.description}
					/>
				</Form.Item>
				{activities.length > 1 && index > 0 && (
					<Col
						xs={24}
						sm={24}
						md={24}
						lg={1}
						xl={1}
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "flex-start",
							paddingBottom: "8px",
						}}
					>
						<MinusCircleOutlined
							onClick={() => deleteActivity(index)}
							style={{
								width: "30px",
								display: "flex",
								alignItems: "center",
								height: window.innerWidth > 1100 ? "30%" : "auto",
								justifyContent:
									window.innerWidth > 1100 ? "flex-start" : "center",
							}}
						/>
					</Col>
				)}
			</div>
		));
	};

	const completeActivities = (activities: PracticalActivityDTO[]) => {
		for (let i = 0; i < activities.length; i++) {
			if (
				activities[i].week === "" ||
				activities[i].activity === "" ||
				activities[i].description === ""
			) {
				return false;
			}
		}

		return true;
	};

	const handleSave = () => {
		if (
			state.title !== "" &&
			state.totalHours > 0 &&
			activities.length > 0 &&
			completeActivities(activities)
		) {
			setSpinning(true);

			if (practiceNotebook) {
				const obj: PracticeNotebookUpdateDTO = {
					title: state.title,
					activities: activities,
					state: complete
						? PracticeNotebookState.PendingTutorSignature
						: PracticeNotebookState.InProgress,
				};

				updatePracticeNotebook(practiceNotebook.id!, obj)
					.then(openSuccessNotificationAndRedirect)
					.catch(openSaveErrorNotification)
					.finally(() => {
						setSpinning(false);
						setAddModalVisibility(false);
					});
			} else {
				const obj: PracticeNotebookAddDTO = {
					title: state.title,
					activities: activities,
					internshipEnrollmentId: internshipEnrollmentId!,
					state: complete
						? PracticeNotebookState.PendingTutorSignature
						: PracticeNotebookState.InProgress,
				};

				addPracticeNotebook(obj)
					.then(openSuccessNotificationAndRedirect)
					.catch(openSaveErrorNotification)
					.finally(() => {
						setSpinning(false);
						setAddModalVisibility(false);
					});
			}
		} else {
			openNotification(
				t("practiceNotebook.editPracticeNotebook"),
				t("usersText.requiredFieldsError"),
				NOTIFICATION_TYPES.ERROR
			);
		}
	};

	if (redirect) {
		return <Redirect to="/stagiu-practica" />;
	} else {
		return (
			<div className="flex flex-col w-full px-4 py-10">
				<Form.Provider onFormFinish={() => setAddModalVisibility(true)}>
					<Form
						className="flex flex-col"
						form={form}
						layout={theme.layout}
						action="/stagiu-practica"
					>
						<Form.Item
							name="title"
							label={t("practiceNotebook.addPracticeNotebookForm.title") + ":"}
							rules={[
								{
									required: true,
									message: t("usersText.requiredField", {
										field: t("practiceNotebook.addPracticeNotebookForm.title"),
									}),
									whitespace: true,
								},
							]}
						>
							<Input
								placeholder={t(
									"practiceNotebook.addPracticeNotebookForm.title"
								)}
								onChange={(event) => handleChangeInputs(event, "title")}
							/>
						</Form.Item>

						<Form.Item
							className="w-full"
							name="totalNumberOfHours"
							label={
								t(
									"practiceNotebook.addPracticeNotebookForm.totalNumberOfHours"
								) + ":"
							}
						>
							<InputNumber
								disabled
								className="w-full text-black"
								placeholder={t(
									"practiceNotebook.addPracticeNotebookForm.totalNumberOfHoursPlaceholder"
								)}
								onChange={(event) =>
									handleChangeNumberInputs(event, "totalHours")
								}
							/>
						</Form.Item>

						{renderActivities()}

						<Button
							className={styles.addActivity}
							type="dashed"
							onClick={() => addNewActivity()}
							icon={<PlusOutlined />}
							block
						>
							{t("practiceNotebook.addPracticeNotebookForm.addActivity")}
						</Button>

						<Checkbox
							className="pt-5"
							checked={complete}
							onChange={(event) => handleChangeCheckbox(event)}
							style={{ textAlign: "left" }}
						>
							{t("practiceNotebook.addPracticeNotebookForm.markComplete")}
						</Checkbox>
						<div className="flex w-full pt-4 flex-column">
							<Button
								style={{
									fontSize: "0.9rem",
									background: theme.green,
									color: theme.white,
									marginRight: "2em",
									marginTop: "0",
									boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
								}}
								onClick={() => setCancelModalVisibility(true)}
							>
								{t("practiceNotebook.addPracticeNotebookForm.cancel")}
							</Button>
							<ConfirmationModal
								modalText={t(
									"practiceNotebook.addPracticeNotebookForm.cancelMessage"
								)}
								handleFunction={handleCancel}
								modalVisibility={cancelModalVisibility}
								title=""
								changeModalVisibility={() => setCancelModalVisibility(false)}
								spinning={spinning}
							/>
							<Button
								htmlType="submit"
								style={{
									fontSize: "0.9rem",
									background: theme.secondColor,
									color: theme.white,
									marginRight: "1rem",
									marginTop: "0",
									boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
								}}
								onClick={() => form.submit()}
							>
								{practiceNotebook
									? !complete
										? t(
												"practiceNotebook.addPracticeNotebookForm.updateDraftButton"
										  )
										: t("practiceNotebook.addPracticeNotebookForm.updateButton")
									: !complete
									? t(
											"practiceNotebook.addPracticeNotebookForm.saveDraftButton"
									  )
									: t("practiceNotebook.addPracticeNotebookForm.saveButton")}
							</Button>
							<ConfirmationModal
								modalText={t(
									"practiceNotebook.addPracticeNotebookForm.saveMessage"
								)}
								handleFunction={handleSave}
								modalVisibility={addModalVisibility}
								title=""
								changeModalVisibility={() => setAddModalVisibility(false)}
								spinning={spinning}
							/>
						</div>
					</Form>
				</Form.Provider>
			</div>
		);
	}
};

export default PracticeNotebook;
