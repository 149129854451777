/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
	EmailPriority,
	EmailPriorityFromJSON,
	EmailPriorityFromJSONTyped,
	EmailPriorityToJSON,
} from "./EmailPriority";
import {
	EmailRecipientDTO,
	EmailRecipientDTOFromJSON,
	EmailRecipientDTOFromJSONTyped,
	EmailRecipientDTOToJSON,
} from "./EmailRecipientDTO";

/**
 *
 * @export
 * @interface NewsletterEmailDTO
 */
export interface NewsletterEmailDTO {
	/**
	 *
	 * @type {string}
	 * @memberof NewsletterEmailDTO
	 */
	id?: string;
	/**
	 *
	 * @type {string}
	 * @memberof NewsletterEmailDTO
	 */
	subject?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof NewsletterEmailDTO
	 */
	body?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof NewsletterEmailDTO
	 */
	newsletterChannel?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof NewsletterEmailDTO
	 */
	publisherName?: string | null;
	/**
	 *
	 * @type {number}
	 * @memberof NewsletterEmailDTO
	 */
	initialRecipientsCount?: number;
	/**
	 *
	 * @type {number}
	 * @memberof NewsletterEmailDTO
	 */
	currentRecipientsCount?: number;
	/**
	 *
	 * @type {EmailPriority}
	 * @memberof NewsletterEmailDTO
	 */
	priority?: EmailPriority;
	/**
	 *
	 * @type {boolean}
	 * @memberof NewsletterEmailDTO
	 */
	expeditionEnabled?: boolean;
	/**
	 *
	 * @type {Array<EmailRecipientDTO>}
	 * @memberof NewsletterEmailDTO
	 */
	recipients?: Array<EmailRecipientDTO> | null;
}

export function NewsletterEmailDTOFromJSON(json: any): NewsletterEmailDTO {
	return NewsletterEmailDTOFromJSONTyped(json, false);
}

export function NewsletterEmailDTOFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): NewsletterEmailDTO {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		id: !exists(json, "id") ? undefined : json["id"],
		subject: !exists(json, "subject") ? undefined : json["subject"],
		body: !exists(json, "body") ? undefined : json["body"],
		newsletterChannel: !exists(json, "newsletterChannel")
			? undefined
			: json["newsletterChannel"],
		publisherName: !exists(json, "publisherName")
			? undefined
			: json["publisherName"],
		initialRecipientsCount: !exists(json, "initialRecipientsCount")
			? undefined
			: json["initialRecipientsCount"],
		currentRecipientsCount: !exists(json, "currentRecipientsCount")
			? undefined
			: json["currentRecipientsCount"],
		priority: !exists(json, "priority")
			? undefined
			: EmailPriorityFromJSON(json["priority"]),
		expeditionEnabled: !exists(json, "expeditionEnabled")
			? undefined
			: json["expeditionEnabled"],
		recipients: !exists(json, "recipients")
			? undefined
			: json["recipients"] === null
			? null
			: (json["recipients"] as Array<any>).map(EmailRecipientDTOFromJSON),
	};
}

export function NewsletterEmailDTOToJSON(
	value?: NewsletterEmailDTO | null
): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		id: value.id,
		subject: value.subject,
		body: value.body,
		newsletterChannel: value.newsletterChannel,
		publisherName: value.publisherName,
		initialRecipientsCount: value.initialRecipientsCount,
		currentRecipientsCount: value.currentRecipientsCount,
		priority: EmailPriorityToJSON(value.priority),
		expeditionEnabled: value.expeditionEnabled,
		recipients:
			value.recipients === undefined
				? undefined
				: value.recipients === null
				? null
				: (value.recipients as Array<any>).map(EmailRecipientDTOToJSON),
	};
}
