/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
	EnrollmentStatus,
	EnrollmentStatusFromJSON,
	EnrollmentStatusFromJSONTyped,
	EnrollmentStatusToJSON,
} from "./EnrollmentStatus";
import {
	InternshipDocumentState,
	InternshipDocumentStateFromJSON,
	InternshipDocumentStateFromJSONTyped,
	InternshipDocumentStateToJSON,
} from "./InternshipDocumentState";
import {
	InternshipEngagementType,
	InternshipEngagementTypeFromJSON,
	InternshipEngagementTypeFromJSONTyped,
	InternshipEngagementTypeToJSON,
} from "./InternshipEngagementType";
import {
	InternshipType,
	InternshipTypeFromJSON,
	InternshipTypeFromJSONTyped,
	InternshipTypeToJSON,
} from "./InternshipType";
import {
	StudentYearEnum,
	StudentYearEnumFromJSON,
	StudentYearEnumFromJSONTyped,
	StudentYearEnumToJSON,
} from "./StudentYearEnum";

/**
 *
 * @export
 * @interface DiplomaStudentDTO
 */
export interface DiplomaStudentDTO {
	/**
	 *
	 * @type {string}
	 * @memberof DiplomaStudentDTO
	 */
	id?: string;
	/**
	 *
	 * @type {string}
	 * @memberof DiplomaStudentDTO
	 */
	internshipId?: string;
	/**
	 *
	 * @type {string}
	 * @memberof DiplomaStudentDTO
	 */
	readonly name?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof DiplomaStudentDTO
	 */
	readonly email?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof DiplomaStudentDTO
	 */
	readonly phone?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof DiplomaStudentDTO
	 */
	readonly cvUrl?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof DiplomaStudentDTO
	 */
	readonly linkedinUrl?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof DiplomaStudentDTO
	 */
	readonly departmentId?: string;
	/**
	 *
	 * @type {string}
	 * @memberof DiplomaStudentDTO
	 */
	readonly departmentNameRo?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof DiplomaStudentDTO
	 */
	readonly departmentNameEn?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof DiplomaStudentDTO
	 */
	readonly facultyNameRo?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof DiplomaStudentDTO
	 */
	readonly facultyNameEn?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof DiplomaStudentDTO
	 */
	readonly internshipName?: string | null;
	/**
	 *
	 * @type {InternshipType}
	 * @memberof DiplomaStudentDTO
	 */
	internshipType?: InternshipType;
	/**
	 *
	 * @type {InternshipEngagementType}
	 * @memberof DiplomaStudentDTO
	 */
	internshipEngagementType?: InternshipEngagementType;
	/**
	 *
	 * @type {Date}
	 * @memberof DiplomaStudentDTO
	 */
	startingDate?: Date;
	/**
	 *
	 * @type {number}
	 * @memberof DiplomaStudentDTO
	 */
	period?: number;
	/**
	 *
	 * @type {string}
	 * @memberof DiplomaStudentDTO
	 */
	readonly internshipEmployerId?: string;
	/**
	 *
	 * @type {string}
	 * @memberof DiplomaStudentDTO
	 */
	readonly internshipEmployerName?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof DiplomaStudentDTO
	 */
	readonly internshipTutorId?: string;
	/**
	 *
	 * @type {EnrollmentStatus}
	 * @memberof DiplomaStudentDTO
	 */
	studentStatus?: EnrollmentStatus;
	/**
	 *
	 * @type {EnrollmentStatus}
	 * @memberof DiplomaStudentDTO
	 */
	recruiterStatus?: EnrollmentStatus;
	/**
	 *
	 * @type {string}
	 * @memberof DiplomaStudentDTO
	 */
	readonly internshipDocumentId?: string;
	/**
	 *
	 * @type {InternshipDocumentState}
	 * @memberof DiplomaStudentDTO
	 */
	internshipDocumentStatus?: InternshipDocumentState;
	/**
	 *
	 * @type {string}
	 * @memberof DiplomaStudentDTO
	 */
	readonly documentSigningStudyProgramCoordId?: string;
	/**
	 *
	 * @type {string}
	 * @memberof DiplomaStudentDTO
	 */
	readonly documentSigningDepartmentDirectorId?: string;
	/**
	 *
	 * @type {string}
	 * @memberof DiplomaStudentDTO
	 */
	readonly documentSigningDeanId?: string;
	/**
	 *
	 * @type {string}
	 * @memberof DiplomaStudentDTO
	 */
	readonly internshipDocumentLastSignatureId?: string;
	/**
	 *
	 * @type {string}
	 * @memberof DiplomaStudentDTO
	 */
	readonly internshipEnrollmentId?: string;
	/**
	 *
	 * @type {string}
	 * @memberof DiplomaStudentDTO
	 */
	readonly studyProgram?: string | null;
	/**
	 *
	 * @type {StudentYearEnum}
	 * @memberof DiplomaStudentDTO
	 */
	year?: StudentYearEnum;
}

export function DiplomaStudentDTOFromJSON(json: any): DiplomaStudentDTO {
	return DiplomaStudentDTOFromJSONTyped(json, false);
}

export function DiplomaStudentDTOFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): DiplomaStudentDTO {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		id: !exists(json, "id") ? undefined : json["id"],
		internshipId: !exists(json, "internshipId")
			? undefined
			: json["internshipId"],
		name: !exists(json, "name") ? undefined : json["name"],
		email: !exists(json, "email") ? undefined : json["email"],
		phone: !exists(json, "phone") ? undefined : json["phone"],
		cvUrl: !exists(json, "cvUrl") ? undefined : json["cvUrl"],
		linkedinUrl: !exists(json, "linkedinUrl") ? undefined : json["linkedinUrl"],
		departmentId: !exists(json, "departmentId")
			? undefined
			: json["departmentId"],
		departmentNameRo: !exists(json, "departmentNameRo")
			? undefined
			: json["departmentNameRo"],
		departmentNameEn: !exists(json, "departmentNameEn")
			? undefined
			: json["departmentNameEn"],
		facultyNameRo: !exists(json, "facultyNameRo")
			? undefined
			: json["facultyNameRo"],
		facultyNameEn: !exists(json, "facultyNameEn")
			? undefined
			: json["facultyNameEn"],
		internshipName: !exists(json, "internshipName")
			? undefined
			: json["internshipName"],
		internshipType: !exists(json, "internshipType")
			? undefined
			: InternshipTypeFromJSON(json["internshipType"]),
		internshipEngagementType: !exists(json, "internshipEngagementType")
			? undefined
			: InternshipEngagementTypeFromJSON(json["internshipEngagementType"]),
		startingDate: !exists(json, "startingDate")
			? undefined
			: new Date(json["startingDate"]),
		period: !exists(json, "period") ? undefined : json["period"],
		internshipEmployerId: !exists(json, "internshipEmployerId")
			? undefined
			: json["internshipEmployerId"],
		internshipEmployerName: !exists(json, "internshipEmployerName")
			? undefined
			: json["internshipEmployerName"],
		internshipTutorId: !exists(json, "internshipTutorId")
			? undefined
			: json["internshipTutorId"],
		studentStatus: !exists(json, "studentStatus")
			? undefined
			: EnrollmentStatusFromJSON(json["studentStatus"]),
		recruiterStatus: !exists(json, "recruiterStatus")
			? undefined
			: EnrollmentStatusFromJSON(json["recruiterStatus"]),
		internshipDocumentId: !exists(json, "internshipDocumentId")
			? undefined
			: json["internshipDocumentId"],
		internshipDocumentStatus: !exists(json, "internshipDocumentStatus")
			? undefined
			: InternshipDocumentStateFromJSON(json["internshipDocumentStatus"]),
		documentSigningStudyProgramCoordId: !exists(
			json,
			"documentSigningStudyProgramCoordId"
		)
			? undefined
			: json["documentSigningStudyProgramCoordId"],
		documentSigningDepartmentDirectorId: !exists(
			json,
			"documentSigningDepartmentDirectorId"
		)
			? undefined
			: json["documentSigningDepartmentDirectorId"],
		documentSigningDeanId: !exists(json, "documentSigningDeanId")
			? undefined
			: json["documentSigningDeanId"],
		internshipDocumentLastSignatureId: !exists(
			json,
			"internshipDocumentLastSignatureId"
		)
			? undefined
			: json["internshipDocumentLastSignatureId"],
		internshipEnrollmentId: !exists(json, "internshipEnrollmentId")
			? undefined
			: json["internshipEnrollmentId"],
		studyProgram: !exists(json, "studyProgram")
			? undefined
			: json["studyProgram"],
		year: !exists(json, "year")
			? undefined
			: StudentYearEnumFromJSON(json["year"]),
	};
}

export function DiplomaStudentDTOToJSON(value?: DiplomaStudentDTO | null): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		id: value.id,
		internshipId: value.internshipId,
		internshipType: InternshipTypeToJSON(value.internshipType),
		internshipEngagementType: InternshipEngagementTypeToJSON(
			value.internshipEngagementType
		),
		startingDate:
			value.startingDate === undefined
				? undefined
				: value.startingDate.toISOString(),
		period: value.period,
		studentStatus: EnrollmentStatusToJSON(value.studentStatus),
		recruiterStatus: EnrollmentStatusToJSON(value.recruiterStatus),
		internshipDocumentStatus: InternshipDocumentStateToJSON(
			value.internshipDocumentStatus
		),
		year: StudentYearEnumToJSON(value.year),
	};
}
