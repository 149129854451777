/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
	AcademicUser,
	AcademicUserFromJSON,
	AcademicUserFromJSONTyped,
	AcademicUserToJSON,
} from "./AcademicUser";
import {
	ProfessorInternship,
	ProfessorInternshipFromJSON,
	ProfessorInternshipFromJSONTyped,
	ProfessorInternshipToJSON,
} from "./ProfessorInternship";

/**
 *
 * @export
 * @interface ProfessorInternshipCoTutor
 */
export interface ProfessorInternshipCoTutor {
	/**
	 *
	 * @type {string}
	 * @memberof ProfessorInternshipCoTutor
	 */
	id?: string;
	/**
	 *
	 * @type {Date}
	 * @memberof ProfessorInternshipCoTutor
	 */
	createdAt?: Date;
	/**
	 *
	 * @type {Date}
	 * @memberof ProfessorInternshipCoTutor
	 */
	updatedAt?: Date;
	/**
	 *
	 * @type {string}
	 * @memberof ProfessorInternshipCoTutor
	 */
	professorInternshipId?: string;
	/**
	 *
	 * @type {string}
	 * @memberof ProfessorInternshipCoTutor
	 */
	coTutorId?: string;
	/**
	 *
	 * @type {ProfessorInternship}
	 * @memberof ProfessorInternshipCoTutor
	 */
	professorInternship?: ProfessorInternship;
	/**
	 *
	 * @type {AcademicUser}
	 * @memberof ProfessorInternshipCoTutor
	 */
	coTutor?: AcademicUser;
}

export function ProfessorInternshipCoTutorFromJSON(
	json: any
): ProfessorInternshipCoTutor {
	return ProfessorInternshipCoTutorFromJSONTyped(json, false);
}

export function ProfessorInternshipCoTutorFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): ProfessorInternshipCoTutor {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		id: !exists(json, "id") ? undefined : json["id"],
		createdAt: !exists(json, "createdAt")
			? undefined
			: new Date(json["createdAt"]),
		updatedAt: !exists(json, "updatedAt")
			? undefined
			: new Date(json["updatedAt"]),
		professorInternshipId: !exists(json, "professorInternshipId")
			? undefined
			: json["professorInternshipId"],
		coTutorId: !exists(json, "coTutorId") ? undefined : json["coTutorId"],
		professorInternship: !exists(json, "professorInternship")
			? undefined
			: ProfessorInternshipFromJSON(json["professorInternship"]),
		coTutor: !exists(json, "coTutor")
			? undefined
			: AcademicUserFromJSON(json["coTutor"]),
	};
}

export function ProfessorInternshipCoTutorToJSON(
	value?: ProfessorInternshipCoTutor | null
): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		id: value.id,
		createdAt:
			value.createdAt === undefined ? undefined : value.createdAt.toISOString(),
		updatedAt:
			value.updatedAt === undefined ? undefined : value.updatedAt.toISOString(),
		professorInternshipId: value.professorInternshipId,
		coTutorId: value.coTutorId,
		professorInternship: ProfessorInternshipToJSON(value.professorInternship),
		coTutor: AcademicUserToJSON(value.coTutor),
	};
}
