/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum EmailSendStatus {
	Pending = "Pending",
	Sent = "Sent",
	Error = "Error",
}

export function EmailSendStatusFromJSON(json: any): EmailSendStatus {
	return EmailSendStatusFromJSONTyped(json, false);
}

export function EmailSendStatusFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): EmailSendStatus {
	return json as EmailSendStatus;
}

export function EmailSendStatusToJSON(value?: EmailSendStatus | null): any {
	return value as any;
}
