import Router from "./Router/Router";
import mailValidationReducer from "./Components/MailValidation/MailValidationSlice";
import { PersistGate } from "redux-persist/integration/react";
import studentReducer from "./Components/Student/StudentSlice";
import filtersReducer from "./Components/Filters/FiltersSlice";
import { Provider } from "react-redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { combineReducers, createStore } from "redux";
import { QueryClient, QueryClientProvider } from "react-query";
import fetchIntercept from "fetch-intercept";
import { getTokenExpire } from "./utils/utilFunctions";
import { StatusCodes } from "http-status-codes";
import AcceptGDPRModal from "./Components/GDPRModal/AcceptGDPRModal";
import { refreshToken } from "./Requests/company-requests";
import SelectStudyProgramModal from "./Components/StudyProgramModal/SelectStudyProgramModal";
import { ConfigProvider } from "antd";
import { StyleProvider } from "@ant-design/cssinjs";

fetchIntercept.register({
	response: (response) => {
		const expire = getTokenExpire();
		const now = Date.now() / 1000;
		if (
			response.request?.headers &&
			response.request.headers.has("authorization") &&
			!response.request.headers.get("authorization")?.includes("null") &&
			response.status === StatusCodes.UNAUTHORIZED &&
			(!expire || expire < now)
		) {
			localStorage.removeItem("token");
			window.location.href = "/?expired=true";
		}
		if (
			response.request?.headers &&
			response.request.headers.has("authorization") &&
			!response.request.headers.get("authorization")?.includes("null") &&
			response.status === StatusCodes.UNAUTHORIZED &&
			(!expire || expire < now)
		) {
			localStorage.removeItem("token");
			window.location.href = "/?expired=true";
		}

		return response;
	},
	responseError: (error) => {
		const expire = getTokenExpire();
		const now = Date.now() / 1000;
		if (
			error.request?.headers &&
			error.request.headers.has("authorization") &&
			!error.request.headers.get("authorization")?.includes("null") &&
			(!expire || expire < now)
		) {
			const token = localStorage.getItem("refreshToken");
			refreshToken(token || "")
				.then((newToken) => {
					if (newToken) {
						localStorage.setItem("token", newToken.response!);
						window.location.reload();
					} else {
						localStorage.removeItem("token");
						localStorage.removeItem("refreshToken");
						window.location.href = "/?expired=true";
					}
				})
				.catch((err) => {
					localStorage.removeItem("token");
					localStorage.removeItem("refreshToken");
					window.location.href = "/?expired=true";
				});
		}
		if (
			error.request?.headers &&
			error.request.headers.has("authorization") &&
			!error.request.headers.get("authorization")?.includes("null") &&
			(!expire || expire < now)
		) {
			const token = localStorage.getItem("refreshToken");
			refreshToken(token || "")
				.then((newToken) => {
					if (newToken) {
						localStorage.setItem("token", newToken.response!);
						window.location.reload();
					} else {
						localStorage.removeItem("token");
						localStorage.removeItem("refreshToken");
						window.location.href = "/?expired=true";
					}
				})
				.catch((err) => {
					localStorage.removeItem("token");
					localStorage.removeItem("refreshToken");
					window.location.href = "/?expired=true";
				});
		}

		return Promise.reject(error);
	},
});

const persistConfig = {
	key: "root",
	storage: storage,
	whitelist: ["student"],
};

const rootReducer = combineReducers({
	mailValidation: mailValidationReducer,
	student: studentReducer,
	filters: filtersReducer,
});

const pReducer = persistReducer(persistConfig, rootReducer);
const store = createStore(pReducer);
const persistor = persistStore(store);
const queryClient = new QueryClient();

function App() {
	return (
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<QueryClientProvider client={queryClient}>
					<ConfigProvider
						theme={{
							token: {
								colorPrimary: "#376e6f",
								colorPrimaryBg: "#CCE8E9",
								colorText: "#153738",
								fontFamily: "Montserrat",
								colorError: "#E04848",
							},
						}}
					>
						<StyleProvider hashPriority="high">
							<div className="App">
								<AcceptGDPRModal />
								<SelectStudyProgramModal />
								<Router />
							</div>
						</StyleProvider>
					</ConfigProvider>
				</QueryClientProvider>
			</PersistGate>
		</Provider>
	);
}

export default App;
