/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface MissingStudentEnrollmentReportDTO
 */
export interface MissingStudentEnrollmentReportDTO {
	/**
	 *
	 * @type {string}
	 * @memberof MissingStudentEnrollmentReportDTO
	 */
	id?: string;
	/**
	 *
	 * @type {string}
	 * @memberof MissingStudentEnrollmentReportDTO
	 */
	name?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof MissingStudentEnrollmentReportDTO
	 */
	studyProgram?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof MissingStudentEnrollmentReportDTO
	 */
	department?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof MissingStudentEnrollmentReportDTO
	 */
	email?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof MissingStudentEnrollmentReportDTO
	 */
	phone?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof MissingStudentEnrollmentReportDTO
	 */
	group?: string | null;
	/**
	 *
	 * @type {number}
	 * @memberof MissingStudentEnrollmentReportDTO
	 */
	applications?: number;
}

export function MissingStudentEnrollmentReportDTOFromJSON(
	json: any
): MissingStudentEnrollmentReportDTO {
	return MissingStudentEnrollmentReportDTOFromJSONTyped(json, false);
}

export function MissingStudentEnrollmentReportDTOFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): MissingStudentEnrollmentReportDTO {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		id: !exists(json, "id") ? undefined : json["id"],
		name: !exists(json, "name") ? undefined : json["name"],
		studyProgram: !exists(json, "studyProgram")
			? undefined
			: json["studyProgram"],
		department: !exists(json, "department") ? undefined : json["department"],
		email: !exists(json, "email") ? undefined : json["email"],
		phone: !exists(json, "phone") ? undefined : json["phone"],
		group: !exists(json, "group") ? undefined : json["group"],
		applications: !exists(json, "applications")
			? undefined
			: json["applications"],
	};
}

export function MissingStudentEnrollmentReportDTOToJSON(
	value?: MissingStudentEnrollmentReportDTO | null
): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		id: value.id,
		name: value.name,
		studyProgram: value.studyProgram,
		department: value.department,
		email: value.email,
		phone: value.phone,
		group: value.group,
		applications: value.applications,
	};
}
