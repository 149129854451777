/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface InternshipCompetenceDTO
 */
export interface InternshipCompetenceDTO {
	/**
	 *
	 * @type {number}
	 * @memberof InternshipCompetenceDTO
	 */
	index?: number;
	/**
	 *
	 * @type {string}
	 * @memberof InternshipCompetenceDTO
	 */
	competence?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof InternshipCompetenceDTO
	 */
	type?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof InternshipCompetenceDTO
	 */
	place?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof InternshipCompetenceDTO
	 */
	activity?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof InternshipCompetenceDTO
	 */
	observations?: string | null;
}

export function InternshipCompetenceDTOFromJSON(
	json: any
): InternshipCompetenceDTO {
	return InternshipCompetenceDTOFromJSONTyped(json, false);
}

export function InternshipCompetenceDTOFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): InternshipCompetenceDTO {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		index: !exists(json, "index") ? undefined : json["index"],
		competence: !exists(json, "competence") ? undefined : json["competence"],
		type: !exists(json, "type") ? undefined : json["type"],
		place: !exists(json, "place") ? undefined : json["place"],
		activity: !exists(json, "activity") ? undefined : json["activity"],
		observations: !exists(json, "observations")
			? undefined
			: json["observations"],
	};
}

export function InternshipCompetenceDTOToJSON(
	value?: InternshipCompetenceDTO | null
): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		index: value.index,
		competence: value.competence,
		type: value.type,
		place: value.place,
		activity: value.activity,
		observations: value.observations,
	};
}
