import { EmailsListProps } from "./EmailsList.types";
import { EmailListItem } from "../../listItems/EmailListItem/EmailListItem";
import { NewsletterEmailDTO } from "../../../../../../Api";
import { useTranslation } from "react-i18next";

const EmailsList = (props: EmailsListProps) => {
	const { t } = useTranslation();

	return (
		<>
			{props.loading && !props.data && (
				<div className="w-full py-10 text-center">Loading</div>
			)}
			{props.data?.data?.length === 0 && (
				<div className="w-full py-10 text-center">
					{t("tableText.noOffers")}
				</div>
			)}

			<div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 xxl:grid-cols-4 gap-4">
				{props.data?.data?.map((item: NewsletterEmailDTO) => (
					<EmailListItem
						key={item.id}
						item={item}
						currentPage={props.currentPage}
						pageSize={props.pageSize}
						searchTerm={props.searchTerm}
						sortDirection={props.sortDirection}
						sortField={props.sortField}
						location={props.location}
					/>
				))}
			</div>
		</>
	);
};

export default EmailsList;
