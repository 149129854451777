import { PracticeProtocolApi } from "../Api";
import { getAuthConfiguration } from "./configuration";

const ApiFactory = () => new PracticeProtocolApi(getAuthConfiguration());

export const generatePracticeProtocolPDF = (
	facultyId: string,
	duration: number,
	studentsPerYear: number
) => {
	return ApiFactory().apiPracticeProtocolGeneratePDFProtocolGet({
		facultyId,
		duration,
		studentsPerYear,
	});
};

export const generatePracticeProtocolDocx = (
	facultyId: string,
	duration: number,
	studentsPerYear: number
) => {
	return ApiFactory().apiPracticeProtocolGenerateDocxProtocolGet({
		facultyId,
		duration,
		studentsPerYear,
	});
};
