/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum InternshipType {
	ByCompany = "ByCompany",
	ByProfessor = "ByProfessor",
}

export function InternshipTypeFromJSON(json: any): InternshipType {
	return InternshipTypeFromJSONTyped(json, false);
}

export function InternshipTypeFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): InternshipType {
	return json as InternshipType;
}

export function InternshipTypeToJSON(value?: InternshipType | null): any {
	return value as any;
}
