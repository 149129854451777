/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
	PracticeCertificate,
	PracticeCertificateFromJSON,
	PracticeCertificateFromJSONTyped,
	PracticeCertificateToJSON,
} from "./PracticeCertificate";
import {
	PracticeCertificateCriterion,
	PracticeCertificateCriterionFromJSON,
	PracticeCertificateCriterionFromJSONTyped,
	PracticeCertificateCriterionToJSON,
} from "./PracticeCertificateCriterion";
import {
	PracticeCertificateQualifier,
	PracticeCertificateQualifierFromJSON,
	PracticeCertificateQualifierFromJSONTyped,
	PracticeCertificateQualifierToJSON,
} from "./PracticeCertificateQualifier";

/**
 *
 * @export
 * @interface PracticeQualification
 */
export interface PracticeQualification {
	/**
	 *
	 * @type {string}
	 * @memberof PracticeQualification
	 */
	id?: string;
	/**
	 *
	 * @type {Date}
	 * @memberof PracticeQualification
	 */
	createdAt?: Date;
	/**
	 *
	 * @type {Date}
	 * @memberof PracticeQualification
	 */
	updatedAt?: Date;
	/**
	 *
	 * @type {PracticeCertificateCriterion}
	 * @memberof PracticeQualification
	 */
	criterion?: PracticeCertificateCriterion;
	/**
	 *
	 * @type {PracticeCertificateQualifier}
	 * @memberof PracticeQualification
	 */
	qualifier?: PracticeCertificateQualifier;
	/**
	 *
	 * @type {string}
	 * @memberof PracticeQualification
	 */
	practiceCertificateId?: string;
	/**
	 *
	 * @type {PracticeCertificate}
	 * @memberof PracticeQualification
	 */
	practiceCertificate?: PracticeCertificate;
}

export function PracticeQualificationFromJSON(
	json: any
): PracticeQualification {
	return PracticeQualificationFromJSONTyped(json, false);
}

export function PracticeQualificationFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): PracticeQualification {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		id: !exists(json, "id") ? undefined : json["id"],
		createdAt: !exists(json, "createdAt")
			? undefined
			: new Date(json["createdAt"]),
		updatedAt: !exists(json, "updatedAt")
			? undefined
			: new Date(json["updatedAt"]),
		criterion: !exists(json, "criterion")
			? undefined
			: PracticeCertificateCriterionFromJSON(json["criterion"]),
		qualifier: !exists(json, "qualifier")
			? undefined
			: PracticeCertificateQualifierFromJSON(json["qualifier"]),
		practiceCertificateId: !exists(json, "practiceCertificateId")
			? undefined
			: json["practiceCertificateId"],
		practiceCertificate: !exists(json, "practiceCertificate")
			? undefined
			: PracticeCertificateFromJSON(json["practiceCertificate"]),
	};
}

export function PracticeQualificationToJSON(
	value?: PracticeQualification | null
): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		id: value.id,
		createdAt:
			value.createdAt === undefined ? undefined : value.createdAt.toISOString(),
		updatedAt:
			value.updatedAt === undefined ? undefined : value.updatedAt.toISOString(),
		criterion: PracticeCertificateCriterionToJSON(value.criterion),
		qualifier: PracticeCertificateQualifierToJSON(value.qualifier),
		practiceCertificateId: value.practiceCertificateId,
		practiceCertificate: PracticeCertificateToJSON(value.practiceCertificate),
	};
}
