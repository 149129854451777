/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
	CompanyDocumentDTO,
	CompanyDocumentDTOFromJSON,
	CompanyDocumentDTOFromJSONTyped,
	CompanyDocumentDTOToJSON,
} from "./CompanyDocumentDTO";
import {
	CompanyInternshipState,
	CompanyInternshipStateFromJSON,
	CompanyInternshipStateFromJSONTyped,
	CompanyInternshipStateToJSON,
} from "./CompanyInternshipState";
import {
	DepartmentDTO,
	DepartmentDTOFromJSON,
	DepartmentDTOFromJSONTyped,
	DepartmentDTOToJSON,
} from "./DepartmentDTO";
import {
	FacultyDTO,
	FacultyDTOFromJSON,
	FacultyDTOFromJSONTyped,
	FacultyDTOToJSON,
} from "./FacultyDTO";
import {
	InternshipCompetenceDTO,
	InternshipCompetenceDTOFromJSON,
	InternshipCompetenceDTOFromJSONTyped,
	InternshipCompetenceDTOToJSON,
} from "./InternshipCompetenceDTO";
import {
	InternshipEngagementType,
	InternshipEngagementTypeFromJSON,
	InternshipEngagementTypeFromJSONTyped,
	InternshipEngagementTypeToJSON,
} from "./InternshipEngagementType";
import {
	InternshipParticipationIntentDTO,
	InternshipParticipationIntentDTOFromJSON,
	InternshipParticipationIntentDTOFromJSONTyped,
	InternshipParticipationIntentDTOToJSON,
} from "./InternshipParticipationIntentDTO";
import {
	InternshipType,
	InternshipTypeFromJSON,
	InternshipTypeFromJSONTyped,
	InternshipTypeToJSON,
} from "./InternshipType";
import {
	ProfessionalTitleEnum,
	ProfessionalTitleEnumFromJSON,
	ProfessionalTitleEnumFromJSONTyped,
	ProfessionalTitleEnumToJSON,
} from "./ProfessionalTitleEnum";
import {
	StudentYearEnum,
	StudentYearEnumFromJSON,
	StudentYearEnumFromJSONTyped,
	StudentYearEnumToJSON,
} from "./StudentYearEnum";
import {
	StudyProgramDTO,
	StudyProgramDTOFromJSON,
	StudyProgramDTOFromJSONTyped,
	StudyProgramDTOToJSON,
} from "./StudyProgramDTO";

/**
 *
 * @export
 * @interface InternshipDTO
 */
export interface InternshipDTO {
	/**
	 *
	 * @type {string}
	 * @memberof InternshipDTO
	 */
	id: string;
	/**
	 *
	 * @type {string}
	 * @memberof InternshipDTO
	 */
	recruiterId: string;
	/**
	 *
	 * @type {string}
	 * @memberof InternshipDTO
	 */
	recruiterName: string;
	/**
	 *
	 * @type {string}
	 * @memberof InternshipDTO
	 */
	recruiterAvatar: string;
	/**
	 *
	 * @type {string}
	 * @memberof InternshipDTO
	 */
	recruiterDepartment?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof InternshipDTO
	 */
	address?: string | null;
	/**
	 *
	 * @type {boolean}
	 * @memberof InternshipDTO
	 */
	isPaid?: boolean;
	/**
	 *
	 * @type {boolean}
	 * @memberof InternshipDTO
	 */
	isValid?: boolean;
	/**
	 *
	 * @type {boolean}
	 * @memberof InternshipDTO
	 */
	isViewOnly?: boolean;
	/**
	 *
	 * @type {CompanyInternshipState}
	 * @memberof InternshipDTO
	 */
	state?: CompanyInternshipState;
	/**
	 *
	 * @type {string}
	 * @memberof InternshipDTO
	 */
	internshipId: string;
	/**
	 *
	 * @type {string}
	 * @memberof InternshipDTO
	 */
	internshipName: string;
	/**
	 *
	 * @type {InternshipType}
	 * @memberof InternshipDTO
	 */
	type: InternshipType;
	/**
	 *
	 * @type {InternshipEngagementType}
	 * @memberof InternshipDTO
	 */
	engagementType: InternshipEngagementType;
	/**
	 *
	 * @type {string}
	 * @memberof InternshipDTO
	 */
	description: string;
	/**
	 *
	 * @type {string}
	 * @memberof InternshipDTO
	 */
	shortDescription: string;
	/**
	 *
	 * @type {string}
	 * @memberof InternshipDTO
	 */
	internshipLink?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof InternshipDTO
	 */
	category: string;
	/**
	 *
	 * @type {string}
	 * @memberof InternshipDTO
	 */
	location: string;
	/**
	 *
	 * @type {string}
	 * @memberof InternshipDTO
	 */
	placeId: string;
	/**
	 *
	 * @type {string}
	 * @memberof InternshipDTO
	 */
	tutorId?: string;
	/**
	 *
	 * @type {Array<string>}
	 * @memberof InternshipDTO
	 */
	coTutorIds?: Array<string> | null;
	/**
	 *
	 * @type {Array<FacultyDTO>}
	 * @memberof InternshipDTO
	 */
	faculties: Array<FacultyDTO>;
	/**
	 *
	 * @type {Array<DepartmentDTO>}
	 * @memberof InternshipDTO
	 */
	departments: Array<DepartmentDTO>;
	/**
	 *
	 * @type {Array<StudyProgramDTO>}
	 * @memberof InternshipDTO
	 */
	studyPrograms?: Array<StudyProgramDTO> | null;
	/**
	 *
	 * @type {Array<InternshipParticipationIntentDTO>}
	 * @memberof InternshipDTO
	 */
	participationIntents?: Array<InternshipParticipationIntentDTO> | null;
	/**
	 *
	 * @type {Array<StudentYearEnum>}
	 * @memberof InternshipDTO
	 */
	years: Array<StudentYearEnum>;
	/**
	 *
	 * @type {number}
	 * @memberof InternshipDTO
	 */
	period: number;
	/**
	 *
	 * @type {number}
	 * @memberof InternshipDTO
	 */
	totalPracticeHours?: number;
	/**
	 *
	 * @type {Date}
	 * @memberof InternshipDTO
	 */
	availableFrom: Date;
	/**
	 *
	 * @type {Date}
	 * @memberof InternshipDTO
	 */
	availableTo: Date;
	/**
	 *
	 * @type {Date}
	 * @memberof InternshipDTO
	 */
	startingDate: Date;
	/**
	 *
	 * @type {number}
	 * @memberof InternshipDTO
	 */
	initialAvailablePositions: number;
	/**
	 *
	 * @type {number}
	 * @memberof InternshipDTO
	 */
	availablePositions: number;
	/**
	 *
	 * @type {number}
	 * @memberof InternshipDTO
	 */
	enrolledStudentsCount: number;
	/**
	 *
	 * @type {number}
	 * @memberof InternshipDTO
	 */
	applicantsCount: number;
	/**
	 *
	 * @type {number}
	 * @memberof InternshipDTO
	 */
	viewCount?: number;
	/**
	 *
	 * @type {Array<string>}
	 * @memberof InternshipDTO
	 */
	skillsRequired: Array<string>;
	/**
	 *
	 * @type {Array<InternshipCompetenceDTO>}
	 * @memberof InternshipDTO
	 */
	internshipCompetences?: Array<InternshipCompetenceDTO> | null;
	/**
	 *
	 * @type {string}
	 * @memberof InternshipDTO
	 */
	professorId?: string;
	/**
	 *
	 * @type {string}
	 * @memberof InternshipDTO
	 */
	companyId?: string;
	/**
	 *
	 * @type {string}
	 * @memberof InternshipDTO
	 */
	facultyCoordinatorId?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof InternshipDTO
	 */
	publisherId?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof InternshipDTO
	 */
	facultyCoordinatorName?: string | null;
	/**
	 *
	 * @type {ProfessionalTitleEnum}
	 * @memberof InternshipDTO
	 */
	professionalTitle?: ProfessionalTitleEnum;
	/**
	 *
	 * @type {Array<CompanyDocumentDTO>}
	 * @memberof InternshipDTO
	 */
	companyDocuments?: Array<CompanyDocumentDTO> | null;
	/**
	 *
	 * @type {boolean}
	 * @memberof InternshipDTO
	 */
	needsApproval?: boolean | null;
}

export function InternshipDTOFromJSON(json: any): InternshipDTO {
	return InternshipDTOFromJSONTyped(json, false);
}

export function InternshipDTOFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): InternshipDTO {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		id: json["id"],
		recruiterId: json["recruiterId"],
		recruiterName: json["recruiterName"],
		recruiterAvatar: json["recruiterAvatar"],
		recruiterDepartment: !exists(json, "recruiterDepartment")
			? undefined
			: json["recruiterDepartment"],
		address: !exists(json, "address") ? undefined : json["address"],
		isPaid: !exists(json, "isPaid") ? undefined : json["isPaid"],
		isValid: !exists(json, "isValid") ? undefined : json["isValid"],
		isViewOnly: !exists(json, "isViewOnly") ? undefined : json["isViewOnly"],
		state: !exists(json, "state")
			? undefined
			: CompanyInternshipStateFromJSON(json["state"]),
		internshipId: json["internshipId"],
		internshipName: json["internshipName"],
		type: InternshipTypeFromJSON(json["type"]),
		engagementType: InternshipEngagementTypeFromJSON(json["engagementType"]),
		description: json["description"],
		shortDescription: json["shortDescription"],
		internshipLink: !exists(json, "internshipLink")
			? undefined
			: json["internshipLink"],
		category: json["category"],
		location: json["location"],
		placeId: json["placeId"],
		tutorId: !exists(json, "tutorId") ? undefined : json["tutorId"],
		coTutorIds: !exists(json, "coTutorIds") ? undefined : json["coTutorIds"],
		faculties: (json["faculties"] as Array<any>).map(FacultyDTOFromJSON),
		departments: (json["departments"] as Array<any>).map(DepartmentDTOFromJSON),
		studyPrograms: !exists(json, "studyPrograms")
			? undefined
			: json["studyPrograms"] === null
			? null
			: (json["studyPrograms"] as Array<any>).map(StudyProgramDTOFromJSON),
		participationIntents: !exists(json, "participationIntents")
			? undefined
			: json["participationIntents"] === null
			? null
			: (json["participationIntents"] as Array<any>).map(
					InternshipParticipationIntentDTOFromJSON
			  ),
		years: (json["years"] as Array<any>).map(StudentYearEnumFromJSON),
		period: json["period"],
		totalPracticeHours: !exists(json, "totalPracticeHours")
			? undefined
			: json["totalPracticeHours"],
		availableFrom: new Date(json["availableFrom"]),
		availableTo: new Date(json["availableTo"]),
		startingDate: new Date(json["startingDate"]),
		initialAvailablePositions: json["initialAvailablePositions"],
		availablePositions: json["availablePositions"],
		enrolledStudentsCount: json["enrolledStudentsCount"],
		applicantsCount: json["applicantsCount"],
		viewCount: !exists(json, "viewCount") ? undefined : json["viewCount"],
		skillsRequired: json["skillsRequired"],
		internshipCompetences: !exists(json, "internshipCompetences")
			? undefined
			: json["internshipCompetences"] === null
			? null
			: (json["internshipCompetences"] as Array<any>).map(
					InternshipCompetenceDTOFromJSON
			  ),
		professorId: !exists(json, "professorId") ? undefined : json["professorId"],
		companyId: !exists(json, "companyId") ? undefined : json["companyId"],
		facultyCoordinatorId: !exists(json, "facultyCoordinatorId")
			? undefined
			: json["facultyCoordinatorId"],
		publisherId: !exists(json, "publisherId") ? undefined : json["publisherId"],
		facultyCoordinatorName: !exists(json, "facultyCoordinatorName")
			? undefined
			: json["facultyCoordinatorName"],
		professionalTitle: !exists(json, "professionalTitle")
			? undefined
			: ProfessionalTitleEnumFromJSON(json["professionalTitle"]),
		companyDocuments: !exists(json, "companyDocuments")
			? undefined
			: json["companyDocuments"] === null
			? null
			: (json["companyDocuments"] as Array<any>).map(
					CompanyDocumentDTOFromJSON
			  ),
		needsApproval: !exists(json, "needsApproval")
			? undefined
			: json["needsApproval"],
	};
}

export function InternshipDTOToJSON(value?: InternshipDTO | null): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		id: value.id,
		recruiterId: value.recruiterId,
		recruiterName: value.recruiterName,
		recruiterAvatar: value.recruiterAvatar,
		recruiterDepartment: value.recruiterDepartment,
		address: value.address,
		isPaid: value.isPaid,
		isValid: value.isValid,
		isViewOnly: value.isViewOnly,
		state: CompanyInternshipStateToJSON(value.state),
		internshipId: value.internshipId,
		internshipName: value.internshipName,
		type: InternshipTypeToJSON(value.type),
		engagementType: InternshipEngagementTypeToJSON(value.engagementType),
		description: value.description,
		shortDescription: value.shortDescription,
		internshipLink: value.internshipLink,
		category: value.category,
		location: value.location,
		placeId: value.placeId,
		tutorId: value.tutorId,
		coTutorIds: value.coTutorIds,
		faculties: (value.faculties as Array<any>).map(FacultyDTOToJSON),
		departments: (value.departments as Array<any>).map(DepartmentDTOToJSON),
		studyPrograms:
			value.studyPrograms === undefined
				? undefined
				: value.studyPrograms === null
				? null
				: (value.studyPrograms as Array<any>).map(StudyProgramDTOToJSON),
		participationIntents:
			value.participationIntents === undefined
				? undefined
				: value.participationIntents === null
				? null
				: (value.participationIntents as Array<any>).map(
						InternshipParticipationIntentDTOToJSON
				  ),
		years: (value.years as Array<any>).map(StudentYearEnumToJSON),
		period: value.period,
		totalPracticeHours: value.totalPracticeHours,
		availableFrom: value.availableFrom.toISOString(),
		availableTo: value.availableTo.toISOString(),
		startingDate: value.startingDate.toISOString(),
		initialAvailablePositions: value.initialAvailablePositions,
		availablePositions: value.availablePositions,
		enrolledStudentsCount: value.enrolledStudentsCount,
		applicantsCount: value.applicantsCount,
		viewCount: value.viewCount,
		skillsRequired: value.skillsRequired,
		internshipCompetences:
			value.internshipCompetences === undefined
				? undefined
				: value.internshipCompetences === null
				? null
				: (value.internshipCompetences as Array<any>).map(
						InternshipCompetenceDTOToJSON
				  ),
		professorId: value.professorId,
		companyId: value.companyId,
		facultyCoordinatorId: value.facultyCoordinatorId,
		publisherId: value.publisherId,
		facultyCoordinatorName: value.facultyCoordinatorName,
		professionalTitle: ProfessionalTitleEnumToJSON(value.professionalTitle),
		companyDocuments:
			value.companyDocuments === undefined
				? undefined
				: value.companyDocuments === null
				? null
				: (value.companyDocuments as Array<any>).map(CompanyDocumentDTOToJSON),
		needsApproval: value.needsApproval,
	};
}
