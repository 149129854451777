/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
	ExperienceLevel,
	ExperienceLevelFromJSON,
	ExperienceLevelFromJSONTyped,
	ExperienceLevelToJSON,
} from "./ExperienceLevel";

/**
 *
 * @export
 * @interface PoliJobsProposalAddUpdateDTO
 */
export interface PoliJobsProposalAddUpdateDTO {
	/**
	 *
	 * @type {string}
	 * @memberof PoliJobsProposalAddUpdateDTO
	 */
	companyId?: string;
	/**
	 *
	 * @type {string}
	 * @memberof PoliJobsProposalAddUpdateDTO
	 */
	name?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof PoliJobsProposalAddUpdateDTO
	 */
	description?: string | null;
	/**
	 *
	 * @type {number}
	 * @memberof PoliJobsProposalAddUpdateDTO
	 */
	numberOfPositions?: number;
	/**
	 *
	 * @type {string}
	 * @memberof PoliJobsProposalAddUpdateDTO
	 */
	url?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof PoliJobsProposalAddUpdateDTO
	 */
	location?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof PoliJobsProposalAddUpdateDTO
	 */
	placeId?: string | null;
	/**
	 *
	 * @type {Date}
	 * @memberof PoliJobsProposalAddUpdateDTO
	 */
	availableFrom?: Date;
	/**
	 *
	 * @type {Date}
	 * @memberof PoliJobsProposalAddUpdateDTO
	 */
	availableTo?: Date;
	/**
	 *
	 * @type {Date}
	 * @memberof PoliJobsProposalAddUpdateDTO
	 */
	startingDate?: Date;
	/**
	 *
	 * @type {boolean}
	 * @memberof PoliJobsProposalAddUpdateDTO
	 */
	isArchived?: boolean;
	/**
	 *
	 * @type {ExperienceLevel}
	 * @memberof PoliJobsProposalAddUpdateDTO
	 */
	experienceLevel?: ExperienceLevel;
}

export function PoliJobsProposalAddUpdateDTOFromJSON(
	json: any
): PoliJobsProposalAddUpdateDTO {
	return PoliJobsProposalAddUpdateDTOFromJSONTyped(json, false);
}

export function PoliJobsProposalAddUpdateDTOFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): PoliJobsProposalAddUpdateDTO {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		companyId: !exists(json, "companyId") ? undefined : json["companyId"],
		name: !exists(json, "name") ? undefined : json["name"],
		description: !exists(json, "description") ? undefined : json["description"],
		numberOfPositions: !exists(json, "numberOfPositions")
			? undefined
			: json["numberOfPositions"],
		url: !exists(json, "url") ? undefined : json["url"],
		location: !exists(json, "location") ? undefined : json["location"],
		placeId: !exists(json, "placeId") ? undefined : json["placeId"],
		availableFrom: !exists(json, "availableFrom")
			? undefined
			: new Date(json["availableFrom"]),
		availableTo: !exists(json, "availableTo")
			? undefined
			: new Date(json["availableTo"]),
		startingDate: !exists(json, "startingDate")
			? undefined
			: new Date(json["startingDate"]),
		isArchived: !exists(json, "isArchived") ? undefined : json["isArchived"],
		experienceLevel: !exists(json, "experienceLevel")
			? undefined
			: ExperienceLevelFromJSON(json["experienceLevel"]),
	};
}

export function PoliJobsProposalAddUpdateDTOToJSON(
	value?: PoliJobsProposalAddUpdateDTO | null
): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		companyId: value.companyId,
		name: value.name,
		description: value.description,
		numberOfPositions: value.numberOfPositions,
		url: value.url,
		location: value.location,
		placeId: value.placeId,
		availableFrom:
			value.availableFrom === undefined
				? undefined
				: value.availableFrom.toISOString(),
		availableTo:
			value.availableTo === undefined
				? undefined
				: value.availableTo.toISOString(),
		startingDate:
			value.startingDate === undefined
				? undefined
				: value.startingDate.toISOString(),
		isArchived: value.isArchived,
		experienceLevel: ExperienceLevelToJSON(value.experienceLevel),
	};
}
