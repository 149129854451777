import React, { useEffect, useMemo, useState } from "react";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { Layout, Button, theme } from "antd";
import Footer from "../Footer";
import Navbar from "../Navbar";
import { useHistory, useLocation } from "react-router-dom";
import { Routes } from "../../utils/routes";
import { NavigationMenu } from "./Menus";

const { Header, Sider, Content } = Layout;

const sidebarWidth = 260;

const DashboardLayout: React.FC = ({ children }) => {
	const [collapsed, setCollapsed] = useState(true);
	const {
		token: { colorBgContainer },
	} = theme.useToken();
	const [collapsedWidth, setCollapsedWidth] = useState(0);
	const history = useHistory();
	const location = useLocation();

	const btnMargin = useMemo(() => {
		const windowWidth = window.innerWidth;
		if (windowWidth > 992)
			return {
				marginLeft: collapsed ? 80 : sidebarWidth,
			};
		else
			return {
				marginLeft: collapsed ? 0 : sidebarWidth,
			};
	}, [collapsed]);

	const returnHome = () => history.push(Routes.HOME);

	useEffect(() => {
		setCollapsed(true);
	}, [location.pathname]);

	return (
		<Layout className="relative">
			<Sider
				theme="light"
				trigger={null}
				collapsible
				collapsed={collapsed}
				className="overflow-auto h-[100vh] fixed left-0 top-0 bottom-0 z-20 py-4 shadow-xl"
				breakpoint="lg"
				collapsedWidth={collapsedWidth}
				width={sidebarWidth}
				onBreakpoint={(broken) => {
					setCollapsedWidth(broken ? 0 : 80);
				}}
			>
				<div className="grow h-full flex flex-col gap-10">
					<div className="h-7 flex items-end w-full pl-4" onClick={returnHome}>
						<img src="/images/logo/icon.svg" alt="logo sidenav" />
						{!collapsed && (
							<span className="text-[34px] font-semibold leading-[28px]">
								nnect
							</span>
						)}
					</div>

					<NavigationMenu menuCollapsed={collapsed} />
				</div>
			</Sider>
			<Layout>
				<Header
					className="flex items-center fixed right-0 left-0 z-10"
					style={{ padding: "0 16px", background: colorBgContainer }}
				>
					<Button
						type="text"
						icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
						onClick={() => setCollapsed(!collapsed)}
						className="text-base w-16 h-16 mr-auto"
						style={btnMargin}
					/>

					<Navbar />
				</Header>

				<Content className="z-0 relative pt-[64px] lg:ml-[80px] flex flex-col min-h-[100vh] overflow-hidden">
					<div className="grow w-full">{children}</div>
					<Footer />
				</Content>
			</Layout>
		</Layout>
	);
};

export default DashboardLayout;
