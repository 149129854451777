/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
	PracticeCertificateCriterion,
	PracticeCertificateCriterionFromJSON,
	PracticeCertificateCriterionFromJSONTyped,
	PracticeCertificateCriterionToJSON,
} from "./PracticeCertificateCriterion";
import {
	PracticeCertificateQualifier,
	PracticeCertificateQualifierFromJSON,
	PracticeCertificateQualifierFromJSONTyped,
	PracticeCertificateQualifierToJSON,
} from "./PracticeCertificateQualifier";

/**
 *
 * @export
 * @interface PracticeQualificationDTO
 */
export interface PracticeQualificationDTO {
	/**
	 *
	 * @type {PracticeCertificateCriterion}
	 * @memberof PracticeQualificationDTO
	 */
	criterion?: PracticeCertificateCriterion;
	/**
	 *
	 * @type {PracticeCertificateQualifier}
	 * @memberof PracticeQualificationDTO
	 */
	qualifier?: PracticeCertificateQualifier;
}

export function PracticeQualificationDTOFromJSON(
	json: any
): PracticeQualificationDTO {
	return PracticeQualificationDTOFromJSONTyped(json, false);
}

export function PracticeQualificationDTOFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): PracticeQualificationDTO {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		criterion: !exists(json, "criterion")
			? undefined
			: PracticeCertificateCriterionFromJSON(json["criterion"]),
		qualifier: !exists(json, "qualifier")
			? undefined
			: PracticeCertificateQualifierFromJSON(json["qualifier"]),
	};
}

export function PracticeQualificationDTOToJSON(
	value?: PracticeQualificationDTO | null
): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		criterion: PracticeCertificateCriterionToJSON(value.criterion),
		qualifier: PracticeCertificateQualifierToJSON(value.qualifier),
	};
}
