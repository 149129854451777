/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface CompanyInternshipAcceptDTO
 */
export interface CompanyInternshipAcceptDTO {
	/**
	 *
	 * @type {string}
	 * @memberof CompanyInternshipAcceptDTO
	 */
	internshipId?: string;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyInternshipAcceptDTO
	 */
	studentId?: string;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyInternshipAcceptDTO
	 */
	tutorId?: string;
}

export function CompanyInternshipAcceptDTOFromJSON(
	json: any
): CompanyInternshipAcceptDTO {
	return CompanyInternshipAcceptDTOFromJSONTyped(json, false);
}

export function CompanyInternshipAcceptDTOFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): CompanyInternshipAcceptDTO {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		internshipId: !exists(json, "internshipId")
			? undefined
			: json["internshipId"],
		studentId: !exists(json, "studentId") ? undefined : json["studentId"],
		tutorId: !exists(json, "tutorId") ? undefined : json["tutorId"],
	};
}

export function CompanyInternshipAcceptDTOToJSON(
	value?: CompanyInternshipAcceptDTO | null
): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		internshipId: value.internshipId,
		studentId: value.studentId,
		tutorId: value.tutorId,
	};
}
