/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
	FeatureFlag,
	FeatureFlagFromJSON,
	FeatureFlagToJSON,
	FeatureFlagsCategoryEnum,
	FeatureFlagsCategoryEnumFromJSON,
	FeatureFlagsCategoryEnumToJSON,
	FeatureFlagsDTO,
	FeatureFlagsDTOFromJSON,
	FeatureFlagsDTOToJSON,
	UpdateFacultyFeatureFlagRequestDTO,
	UpdateFacultyFeatureFlagRequestDTOFromJSON,
	UpdateFacultyFeatureFlagRequestDTOToJSON,
	UpdateFeatureFlagRequestDTO,
	UpdateFeatureFlagRequestDTOFromJSON,
	UpdateFeatureFlagRequestDTOToJSON,
} from "../models";

export interface ApiFeatureFlagsGetFeatureFlagsValuesGetRequest {
	category?: FeatureFlagsCategoryEnum;
}

export interface ApiFeatureFlagsUpdateFacultyFeatureFlagValuePutRequest {
	updateFacultyFeatureFlagRequestDTO?: UpdateFacultyFeatureFlagRequestDTO;
}

export interface ApiFeatureFlagsUpdateFeatureFlagValuePutRequest {
	updateFeatureFlagRequestDTO?: UpdateFeatureFlagRequestDTO;
}

/**
 *
 */
export class FeatureFlagsApi extends runtime.BaseAPI {
	/**
	 */
	async apiFeatureFlagsGetAddChangeRequestsEnabledFlagValueGetRaw(
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<FeatureFlagsDTO>> {
		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/FeatureFlags/GetAddChangeRequestsEnabledFlagValue`,
				method: "GET",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			FeatureFlagsDTOFromJSON(jsonValue)
		);
	}

	/**
	 */
	async apiFeatureFlagsGetAddChangeRequestsEnabledFlagValueGet(
		initOverrides?: RequestInit
	): Promise<FeatureFlagsDTO> {
		const response =
			await this.apiFeatureFlagsGetAddChangeRequestsEnabledFlagValueGetRaw(
				initOverrides
			);
		return await response.value();
	}

	/**
	 */
	async apiFeatureFlagsGetCompaniesFeedbackEnabledFlagValueGetRaw(
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<FeatureFlag>> {
		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/FeatureFlags/GetCompaniesFeedbackEnabledFlagValue`,
				method: "GET",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			FeatureFlagFromJSON(jsonValue)
		);
	}

	/**
	 */
	async apiFeatureFlagsGetCompaniesFeedbackEnabledFlagValueGet(
		initOverrides?: RequestInit
	): Promise<FeatureFlag> {
		const response =
			await this.apiFeatureFlagsGetCompaniesFeedbackEnabledFlagValueGetRaw(
				initOverrides
			);
		return await response.value();
	}

	/**
	 */
	async apiFeatureFlagsGetFeatureFlagsAdminGetRaw(
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<Array<FeatureFlagsDTO>>> {
		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/FeatureFlags/GetFeatureFlagsAdmin`,
				method: "GET",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			jsonValue.map(FeatureFlagsDTOFromJSON)
		);
	}

	/**
	 */
	async apiFeatureFlagsGetFeatureFlagsAdminGet(
		initOverrides?: RequestInit
	): Promise<Array<FeatureFlagsDTO>> {
		const response = await this.apiFeatureFlagsGetFeatureFlagsAdminGetRaw(
			initOverrides
		);
		return await response.value();
	}

	/**
	 */
	async apiFeatureFlagsGetFeatureFlagsGetRaw(
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<Array<FeatureFlagsDTO>>> {
		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/FeatureFlags/GetFeatureFlags`,
				method: "GET",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			jsonValue.map(FeatureFlagsDTOFromJSON)
		);
	}

	/**
	 */
	async apiFeatureFlagsGetFeatureFlagsGet(
		initOverrides?: RequestInit
	): Promise<Array<FeatureFlagsDTO>> {
		const response = await this.apiFeatureFlagsGetFeatureFlagsGetRaw(
			initOverrides
		);
		return await response.value();
	}

	/**
	 */
	async apiFeatureFlagsGetFeatureFlagsValuesGetRaw(
		requestParameters: ApiFeatureFlagsGetFeatureFlagsValuesGetRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<Array<FeatureFlagsDTO>>> {
		const queryParameters: any = {};

		if (requestParameters.category !== undefined) {
			queryParameters["category"] = requestParameters.category;
		}

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/FeatureFlags/GetFeatureFlagsValues`,
				method: "GET",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			jsonValue.map(FeatureFlagsDTOFromJSON)
		);
	}

	/**
	 */
	async apiFeatureFlagsGetFeatureFlagsValuesGet(
		requestParameters: ApiFeatureFlagsGetFeatureFlagsValuesGetRequest = {},
		initOverrides?: RequestInit
	): Promise<Array<FeatureFlagsDTO>> {
		const response = await this.apiFeatureFlagsGetFeatureFlagsValuesGetRaw(
			requestParameters,
			initOverrides
		);
		return await response.value();
	}

	/**
	 */
	async apiFeatureFlagsGetOptionalsChoosingEnabledFlagValueGetRaw(
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<FeatureFlagsDTO>> {
		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/FeatureFlags/GetOptionalsChoosingEnabledFlagValue`,
				method: "GET",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			FeatureFlagsDTOFromJSON(jsonValue)
		);
	}

	/**
	 */
	async apiFeatureFlagsGetOptionalsChoosingEnabledFlagValueGet(
		initOverrides?: RequestInit
	): Promise<FeatureFlagsDTO> {
		const response =
			await this.apiFeatureFlagsGetOptionalsChoosingEnabledFlagValueGetRaw(
				initOverrides
			);
		return await response.value();
	}

	/**
	 */
	async apiFeatureFlagsGetPhdFeedbackEnabledFlagValueGetRaw(
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<FeatureFlag>> {
		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/FeatureFlags/GetPhdFeedbackEnabledFlagValue`,
				method: "GET",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			FeatureFlagFromJSON(jsonValue)
		);
	}

	/**
	 */
	async apiFeatureFlagsGetPhdFeedbackEnabledFlagValueGet(
		initOverrides?: RequestInit
	): Promise<FeatureFlag> {
		const response =
			await this.apiFeatureFlagsGetPhdFeedbackEnabledFlagValueGetRaw(
				initOverrides
			);
		return await response.value();
	}

	/**
	 */
	async apiFeatureFlagsGetProfessorsFeedbackEnabledFlagValueGetRaw(
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<FeatureFlag>> {
		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/FeatureFlags/GetProfessorsFeedbackEnabledFlagValue`,
				method: "GET",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			FeatureFlagFromJSON(jsonValue)
		);
	}

	/**
	 */
	async apiFeatureFlagsGetProfessorsFeedbackEnabledFlagValueGet(
		initOverrides?: RequestInit
	): Promise<FeatureFlag> {
		const response =
			await this.apiFeatureFlagsGetProfessorsFeedbackEnabledFlagValueGetRaw(
				initOverrides
			);
		return await response.value();
	}

	/**
	 */
	async apiFeatureFlagsGetStudentsFeedbackEnabledFlagValueGetRaw(
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<FeatureFlag>> {
		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/FeatureFlags/GetStudentsFeedbackEnabledFlagValue`,
				method: "GET",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			FeatureFlagFromJSON(jsonValue)
		);
	}

	/**
	 */
	async apiFeatureFlagsGetStudentsFeedbackEnabledFlagValueGet(
		initOverrides?: RequestInit
	): Promise<FeatureFlag> {
		const response =
			await this.apiFeatureFlagsGetStudentsFeedbackEnabledFlagValueGetRaw(
				initOverrides
			);
		return await response.value();
	}

	/**
	 */
	async apiFeatureFlagsGetViewResultsChangeRequestsEnabledFlagValueGetRaw(
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<FeatureFlagsDTO>> {
		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/FeatureFlags/GetViewResultsChangeRequestsEnabledFlagValue`,
				method: "GET",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			FeatureFlagsDTOFromJSON(jsonValue)
		);
	}

	/**
	 */
	async apiFeatureFlagsGetViewResultsChangeRequestsEnabledFlagValueGet(
		initOverrides?: RequestInit
	): Promise<FeatureFlagsDTO> {
		const response =
			await this.apiFeatureFlagsGetViewResultsChangeRequestsEnabledFlagValueGetRaw(
				initOverrides
			);
		return await response.value();
	}

	/**
	 */
	async apiFeatureFlagsGetViewResultsOptionalsEnabledFlagValueGetRaw(
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<FeatureFlagsDTO>> {
		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/FeatureFlags/GetViewResultsOptionalsEnabledFlagValue`,
				method: "GET",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			FeatureFlagsDTOFromJSON(jsonValue)
		);
	}

	/**
	 */
	async apiFeatureFlagsGetViewResultsOptionalsEnabledFlagValueGet(
		initOverrides?: RequestInit
	): Promise<FeatureFlagsDTO> {
		const response =
			await this.apiFeatureFlagsGetViewResultsOptionalsEnabledFlagValueGetRaw(
				initOverrides
			);
		return await response.value();
	}

	/**
	 */
	async apiFeatureFlagsUpdateFacultyFeatureFlagValuePutRaw(
		requestParameters: ApiFeatureFlagsUpdateFacultyFeatureFlagValuePutRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<void>> {
		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		headerParameters["Content-Type"] = "application/json";

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/FeatureFlags/UpdateFacultyFeatureFlagValue`,
				method: "PUT",
				headers: headerParameters,
				query: queryParameters,
				body: UpdateFacultyFeatureFlagRequestDTOToJSON(
					requestParameters.updateFacultyFeatureFlagRequestDTO
				),
			},
			initOverrides
		);

		return new runtime.VoidApiResponse(response);
	}

	/**
	 */
	async apiFeatureFlagsUpdateFacultyFeatureFlagValuePut(
		requestParameters: ApiFeatureFlagsUpdateFacultyFeatureFlagValuePutRequest = {},
		initOverrides?: RequestInit
	): Promise<void> {
		await this.apiFeatureFlagsUpdateFacultyFeatureFlagValuePutRaw(
			requestParameters,
			initOverrides
		);
	}

	/**
	 */
	async apiFeatureFlagsUpdateFeatureFlagValuePutRaw(
		requestParameters: ApiFeatureFlagsUpdateFeatureFlagValuePutRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<void>> {
		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		headerParameters["Content-Type"] = "application/json";

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/FeatureFlags/UpdateFeatureFlagValue`,
				method: "PUT",
				headers: headerParameters,
				query: queryParameters,
				body: UpdateFeatureFlagRequestDTOToJSON(
					requestParameters.updateFeatureFlagRequestDTO
				),
			},
			initOverrides
		);

		return new runtime.VoidApiResponse(response);
	}

	/**
	 */
	async apiFeatureFlagsUpdateFeatureFlagValuePut(
		requestParameters: ApiFeatureFlagsUpdateFeatureFlagValuePutRequest = {},
		initOverrides?: RequestInit
	): Promise<void> {
		await this.apiFeatureFlagsUpdateFeatureFlagValuePutRaw(
			requestParameters,
			initOverrides
		);
	}
}
