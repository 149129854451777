import { EmailListItemProps } from "./EmailListItem.types";
import { Button, List } from "antd";
import styles from "./EmailListItem.module.scss";
import EmailActions from "./EmailActions";
import CustomButton from "../../../../../../CustomComponents/CustomButton";
import { theme } from "../../../../../../theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import ConfirmationModal from "../../../../../../Containers/ConfirmationModal";
import { useTranslation } from "react-i18next";
import { useCallback, useState } from "react";
import {
	deleteNewsletterEmail,
	toggleEmailExpeditionEnabled,
} from "../../../../../../Requests/newsletter-requests";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../../../../../Notifications/NotificationsUtils";
import { useQueryClient } from "react-query";
import { Link } from "react-router-dom";

export const EmailListItem = (props: EmailListItemProps) => {
	const { t } = useTranslation();
	const [
		updateEmailExpeditionModalVisibility,
		setUpdateEmailExpeditionModalVisibility,
	] = useState(false);
	const [deleteModalVisibility, setDeleteModalVisibility] = useState(false);
	const [spinning, setSpinning] = useState(false);
	const queryClient = useQueryClient();

	const invalidateNewsletterQueries = useCallback(async () => {
		await queryClient.invalidateQueries("getNewsletterEmails");
	}, [queryClient]);

	const handleToggleExpeditionEnabled = () => {
		toggleEmailExpeditionEnabled(props.item.id!)
			.then(async () => {
				await invalidateNewsletterQueries();
				openNotification(
					t("emails.updateEmail"),
					t("emails.updateExpeditionStatusSuccess"),
					NOTIFICATION_TYPES.SUCCESS
				);
			})
			.catch((_error: any) => {
				openNotification(
					t("emails.updateEmail"),
					t("emails.updateExpeditionStatusError"),
					NOTIFICATION_TYPES.ERROR
				);
			})
			.finally(() => {
				setSpinning(false);
				setUpdateEmailExpeditionModalVisibility(false);
			});
	};

	const deleteEntry = useCallback(() => {
		if (props.item.id) {
			setSpinning(true);

			deleteNewsletterEmail(props.item.id)
				.then(async () => {
					await invalidateNewsletterQueries();
					openNotification(
						t("emails.deleteEmail"),
						t("emails.deleteEmailSuccess"),
						NOTIFICATION_TYPES.SUCCESS
					);
				})
				.catch((_error: any) => {
					openNotification(
						t("emails.deleteEmail"),
						t("emails.deleteEmailError"),
						NOTIFICATION_TYPES.ERROR
					);
				})
				.finally(() => {
					setSpinning(false);
					setDeleteModalVisibility(false);
				});
		}
	}, [invalidateNewsletterQueries, props.item.id, t]);

	return (
		<div className="bg-white rounded-md shadow-md p-4 flex flex-col gap-4">
			<div className="flex justify-between gap-6">
				<div className="flex flex-col gap-2 items-start">
					<FontAwesomeIcon
						icon={solid("envelope")}
						style={{ paddingRight: "5%" }}
					/>

					<Link
						to={{
							pathname: "/newsletter-email/" + props.item.id,
							state: {
								id: props.item.id,
								searchTerm: props.searchTerm,
								sortField: props.sortField,
								sortDirection: props.sortDirection,
								currentPage: props.currentPage,
								pageSize: props.pageSize,
								origin: props.location.pathname,
							},
						}}
						className="text-lg text-secondary font-bold line-clamp-2 overflow-ellipsis"
					>
						{props.item.subject}
					</Link>
				</div>
			</div>

			<div className="line-clamp-5 overflow-ellipsis">
				{props.item.newsletterChannel}
			</div>

			<EmailActions item={props.item} />

			<div className="mt-auto flex flex-col gap-2">
				<Button title={t("emails.edit")}>
					<Link
						to={{
							pathname: "/editare-email/" + props.item.id,
							state: {
								currentPage: props.currentPage,
								pageSize: props.pageSize,
								searchTerm: props.searchTerm,
								sortField: props.sortField,
								sortDirection: props.sortDirection,
							},
						}}
					>
						<FontAwesomeIcon icon={solid("edit")} /> &nbsp;
						{t("emails.edit")}
					</Link>
				</Button>

				{props.item.currentRecipientsCount! > 0 && (
					<div>
						<Button
							className="w-full"
							title={
								props.item?.expeditionEnabled
									? t("emails.stopExpedition")
									: t("emails.restartExpedition")
							}
							onClick={() => setUpdateEmailExpeditionModalVisibility(true)}
							icon={
								<FontAwesomeIcon
									icon={
										props.item?.expeditionEnabled
											? solid("stop")
											: solid("play")
									}
								/>
							}
						>
							{props.item?.expeditionEnabled
								? t("emails.stopExpedition")
								: t("emails.restartExpedition")}
						</Button>

						<ConfirmationModal
							modalText={
								props.item?.expeditionEnabled
									? t("emails.stopEmailExpeditionMessage")
									: t("emails.restartEmailExpeditionMessage")
							}
							handleFunction={() => {
								handleToggleExpeditionEnabled();
							}}
							modalVisibility={updateEmailExpeditionModalVisibility}
							title=""
							changeModalVisibility={() =>
								setUpdateEmailExpeditionModalVisibility(false)
							}
							spinning={spinning}
						/>
					</div>
				)}

				<div>
					<Button
						className="w-full"
						danger
						onClick={() => {
							setDeleteModalVisibility(true);
						}}
						title={t("emails.deleteEmail")}
						icon={
							<FontAwesomeIcon
								icon={solid("trash")}
								style={{ paddingRight: "5%" }}
							/>
						}
					>
						{t("emails.deleteEmail")}
					</Button>

					<ConfirmationModal
						modalText={t("emails.deleteEmailMessage")}
						handleFunction={deleteEntry}
						modalVisibility={deleteModalVisibility}
						title=""
						changeModalVisibility={() => setDeleteModalVisibility(false)}
						spinning={spinning}
					/>
				</div>
			</div>
		</div>
	);
};
