import { Card, Col, Image, Row, Spin } from "antd";
import { Link, useParams } from "react-router-dom";
import { validateByMailToken } from "../../Requests/mail-validation-request";
import logo from "../../Media/UPB-Connect.png";
import Text from "antd/lib/typography/Text";
import StatusCodes from "http-status-codes";
import {
	mailValidationRequestConflict,
	mailValidationRequestFailedOther,
	mailValidationRequestSucceeded,
	mailValidationRequestUnauthorized,
	selectLoading,
	selectMessage,
} from "./MailValidationSlice";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { theme } from "../../theme";
import CustomButton from "../../CustomComponents/CustomButton";
import { validateEmail } from "../../utils/reactQueriesConstants";
import Layout from "../../Containers/Layout";
import { getErrorFromResponse } from "../../utils/responseUtils";
import { ErrorCodes } from "../../Api";

const MailValidation = () => {
	const { t } = useTranslation();
	const { validationToken } = useParams<{ validationToken: string }>();
	const dispatch = useDispatch();
	const loading = useSelector(selectLoading);
	const message = useSelector(selectMessage);

	useQuery(
		[validateEmail],
		() => {
			validateByMailToken(validationToken)
				.then(() => {
					dispatch(mailValidationRequestSucceeded());
				})
				.catch(async (error) => {
					const errorMessage = await getErrorFromResponse(error);

					if (errorMessage?.code === ErrorCodes.ValidationTokenExpired) {
						dispatch(mailValidationRequestUnauthorized());
					} else if (errorMessage?.code === ErrorCodes.CompanyAlreadyActive) {
						dispatch(mailValidationRequestConflict());
					} else {
						dispatch(mailValidationRequestFailedOther());
					}
				});
		},
		{
			refetchOnWindowFocus: false,
		}
	);

	return (
		<Layout>
			<Card style={{ alignContent: "center", border: "none" }}>
				<Row style={{ alignContent: "center" }} justify={"center"}>
					<Col>
						<Image
							src={logo}
							preview={false}
							style={{ height: "16rem", marginTop: "2rem" }}
						/>
					</Col>
				</Row>
				<Row justify={"center"}>
					<Col>
						{loading && <Spin size="large" style={{ padding: 10 }} />}
						<Text style={{ verticalAlign: "middle", fontSize: "1.5rem" }}>
							{t(message)}
						</Text>
					</Col>
				</Row>
				<Row justify={"center"}>
					<Col>
						<CustomButton
							backgroundcolor={theme.primaryColor}
							textcolor={theme.white}
							paddingvertical={"1.5rem"}
							style={{
								fontSize: "1.3rem",
								width: "fit-content",
								overflowWrap: "break-word",
							}}
						>
							<Link to="/">{t("mailValidationText.redirect")}</Link>
						</CustomButton>
					</Col>
				</Row>
			</Card>
		</Layout>
	);
};

export default MailValidation;
