/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface SkillEndorsementAddDTO
 */
export interface SkillEndorsementAddDTO {
	/**
	 *
	 * @type {string}
	 * @memberof SkillEndorsementAddDTO
	 */
	userSkillId: string;
}

export function SkillEndorsementAddDTOFromJSON(
	json: any
): SkillEndorsementAddDTO {
	return SkillEndorsementAddDTOFromJSONTyped(json, false);
}

export function SkillEndorsementAddDTOFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): SkillEndorsementAddDTO {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		userSkillId: json["userSkillId"],
	};
}

export function SkillEndorsementAddDTOToJSON(
	value?: SkillEndorsementAddDTO | null
): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		userSkillId: value.userSkillId,
	};
}
