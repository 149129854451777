/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface ProfessorResearchProposalAddUpdateDTO
 */
export interface ProfessorResearchProposalAddUpdateDTO {
	/**
	 *
	 * @type {string}
	 * @memberof ProfessorResearchProposalAddUpdateDTO
	 */
	name?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof ProfessorResearchProposalAddUpdateDTO
	 */
	description?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof ProfessorResearchProposalAddUpdateDTO
	 */
	shortDescription?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof ProfessorResearchProposalAddUpdateDTO
	 */
	url?: string | null;
	/**
	 *
	 * @type {number}
	 * @memberof ProfessorResearchProposalAddUpdateDTO
	 */
	numberOfPartners?: number;
	/**
	 *
	 * @type {Array<string>}
	 * @memberof ProfessorResearchProposalAddUpdateDTO
	 */
	keywords?: Array<string> | null;
	/**
	 *
	 * @type {Date}
	 * @memberof ProfessorResearchProposalAddUpdateDTO
	 */
	availableFrom?: Date;
	/**
	 *
	 * @type {Date}
	 * @memberof ProfessorResearchProposalAddUpdateDTO
	 */
	availableTo?: Date;
	/**
	 *
	 * @type {boolean}
	 * @memberof ProfessorResearchProposalAddUpdateDTO
	 */
	isArchived?: boolean;
}

export function ProfessorResearchProposalAddUpdateDTOFromJSON(
	json: any
): ProfessorResearchProposalAddUpdateDTO {
	return ProfessorResearchProposalAddUpdateDTOFromJSONTyped(json, false);
}

export function ProfessorResearchProposalAddUpdateDTOFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): ProfessorResearchProposalAddUpdateDTO {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		name: !exists(json, "name") ? undefined : json["name"],
		description: !exists(json, "description") ? undefined : json["description"],
		shortDescription: !exists(json, "shortDescription")
			? undefined
			: json["shortDescription"],
		url: !exists(json, "url") ? undefined : json["url"],
		numberOfPartners: !exists(json, "numberOfPartners")
			? undefined
			: json["numberOfPartners"],
		keywords: !exists(json, "keywords") ? undefined : json["keywords"],
		availableFrom: !exists(json, "availableFrom")
			? undefined
			: new Date(json["availableFrom"]),
		availableTo: !exists(json, "availableTo")
			? undefined
			: new Date(json["availableTo"]),
		isArchived: !exists(json, "isArchived") ? undefined : json["isArchived"],
	};
}

export function ProfessorResearchProposalAddUpdateDTOToJSON(
	value?: ProfessorResearchProposalAddUpdateDTO | null
): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		name: value.name,
		description: value.description,
		shortDescription: value.shortDescription,
		url: value.url,
		numberOfPartners: value.numberOfPartners,
		keywords: value.keywords,
		availableFrom:
			value.availableFrom === undefined
				? undefined
				: value.availableFrom.toISOString(),
		availableTo:
			value.availableTo === undefined
				? undefined
				: value.availableTo.toISOString(),
		isArchived: value.isArchived,
	};
}
