import styles from "./InternshipInfo.module.scss";
import { theme } from "../../theme";
import { Button, List, Row, Spin } from "antd";
import { useEffect, useState } from "react";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../Notifications/NotificationsUtils";
import { StatusCodes } from "http-status-codes";
import {
	deleteProfessorInternship,
	getProfessorInternshipById,
} from "../../Requests/professor-internship-requests";
import {
	deleteCompanyInternship,
	getCompanyInternshipById,
} from "../../Requests/company-internship-requests";
import { canApply, apply } from "../../Requests/internship-requests";
import {
	getUserId,
	internshipStatus,
	isUserLogged,
	useIsCompany,
	useIsProfessor,
	useIsStudent,
} from "../../utils/utilFunctions";
import {
	CompanyInternshipState,
	ErrorCodes,
	InternshipDTO,
	InternshipEngagementType,
	InternshipType,
} from "../../Api";
import ConfirmationModal from "../../Containers/ConfirmationModal";
import { useTranslation } from "react-i18next";
import InternshipActions from "../Internships/InternshipActions";
import { useQueryClient, useQuery } from "react-query";
import {
	getErrorFromResponse,
	useErrorCodeTranslation,
} from "../../utils/responseUtils";
import {
	getAllApplicationTypesCount,
	getCompanyInternships,
	getCoordinatedInternships,
	getInternshipData,
	getInternshipsFilters,
	getProfessorInternships,
} from "../../utils/reactQueriesConstants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import EventActions from "../Events/EventActions";
import { ArrowDownOutlined } from "@ant-design/icons";

const InternshipInfo = () => {
	const { t } = useTranslation();
	const { id } = useParams<{ id: string }>();
	const [internship, setInternship] = useState<InternshipDTO>();
	const [applicable, setApplicable] = useState(false);
	const [spinning, setSpinning] = useState(false);
	const isStudent = useIsStudent();
	const isCompany = useIsCompany();
	const isProfessor = useIsProfessor();
	const history = useHistory();
	const queryClient = useQueryClient();
	const search = useLocation().search;
	const type = new URLSearchParams(search).get("type") as any;
	const [errorCode, setErrorCode] = useState<ErrorCodes | null>(
		ErrorCodes.CannotApplyAgain
	);
	const [modalVisibility, setModalVisibility] = useState(false);

	if (!id) {
		history.push("/");
	}

	const openGetErrorNotification = (_error: any) => {
		openNotification(
			t("internships.error"),
			t("internships.fetchDataError"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	useQuery(
		[getInternshipData, isProfessor, isCompany, id],
		() =>
			type === InternshipType.ByProfessor
				? getProfessorInternshipById(id)
				: getCompanyInternshipById(id),
		{
			onError: (err) => {
				history.push("/404");
				openGetErrorNotification(err);
			},
			onSuccess: (response) => {
				setInternship(() => {
					return response;
				});
			},
		}
	);

	const canApplyToInternship = () => {
		if (!internship) return false;

		canApply(internship?.internshipId)
			.then(() => {
				setApplicable(() => {
					return true;
				});
			})
			.catch(async (error) => {
				const errorMessage = await getErrorFromResponse(error);
				setApplicable(false);
				setErrorCode(errorMessage?.code || null);
			});

		return applicable;
	};

	const applyToInternship = () => {
		if (!internship) return;

		setSpinning(true);

		apply(internship?.internshipId)
			.then(async () => {
				setApplicable(false);
				await queryClient.invalidateQueries(getInternshipData);
				await queryClient.invalidateQueries(getAllApplicationTypesCount);
				openNotification(
					t("internships.applyToInternshipSuccess"),
					t("internships.applyToInternshipSuccessText"),
					NOTIFICATION_TYPES.SUCCESS
				);
			})
			.catch((error) => {
				console.error(error);

				if (error) {
					if (error.status === StatusCodes.CONFLICT) {
						openNotification(
							t("internships.applyToInternshipError"),
							t("internships.applyToInternshipConflict"),
							NOTIFICATION_TYPES.ERROR
						);
					} else {
						openNotification(
							t("internships.applyToInternshipError"),
							t("internships.applyToInternshipOtherError"),
							NOTIFICATION_TYPES.ERROR
						);
					}
				}
			})
			.finally(() => {
				setSpinning(false);
				setModalVisibility(false);
			});
	};

	useEffect(() => {
		if (isUserLogged() && isStudent) {
			setApplicable(canApplyToInternship());
		}
	}, [internship?.internshipId, isStudent]);

	const applicationMessage = useErrorCodeTranslation(errorCode);

	useEffect(() => {
		if (!isUserLogged()) {
			localStorage.setItem("nextUrl", window.location.href);
			history.push("/autentificare");
		}
	}, []);

	return (
		<Spin size="large" spinning={!internship} tip={t("internships.loading")}>
			<div className="flex flex-col items-start container mx-auto px-4 py-10 gap-8">
				<div className="flex items-center gap-2">
					<ArrowDownOutlined
						onClick={() => history.goBack()}
						className="rotate-90 text-4xl text-primary"
					/>

					<div className={styles.title}>{internship?.internshipName}</div>
				</div>

				<div className="flex flex-col-reverse xl:flex-row gap-10 w-full lg:items-start">
					<div className="flex flex-col gap-6 basis-full">
						<div className={styles.subtitle}>
							{t("internships.description")}
						</div>
						<div
							className={styles.description}
							dangerouslySetInnerHTML={{
								__html: internship?.description || "",
							}}
						/>

						{!internshipStatus(
							internship?.availableFrom || new Date(),
							internship?.availableTo || new Date()
						) ? (
							<div className={styles.message}>
								{t("internships.applicationsClosed")}
							</div>
						) : !isUserLogged() ? (
							<div className={styles.center}>
								<Link to="/autentificare">
									<Button>{t("internships.loginToApply")}</Button>
								</Link>
							</div>
						) : isStudent &&
						  internship?.engagementType === InternshipEngagementType.Event ? (
							<></>
						) : isStudent && !applicable ? (
							<div className={styles.message}>{applicationMessage}</div>
						) : (
							isStudent &&
							applicable && (
								<div className={styles.center}>
									<div>
										<Row
											style={{
												display: "flex",
												flexDirection: "row",
												alignItems: "baseline",
											}}
										>
											<Button
												style={{
													background: theme.secondColor,
													color: theme.white,
													boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
												}}
												onClick={() => setModalVisibility(true)}
											>
												{t("internships.apply")}
											</Button>
											{internship?.internshipLink && (
												<a
													href={internship.internshipLink}
													target="_blank"
													rel="noopener noreferrer"
												>
													<Button
														style={{
															background: theme.secondColor,
															color: theme.white,
															boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
														}}
													>
														{t("internships.internshipLink")}
													</Button>
												</a>
											)}
										</Row>
										<ConfirmationModal
											modalText={t("internships.applyInternshipMessage")}
											handleFunction={applyToInternship}
											modalVisibility={modalVisibility}
											title=""
											changeModalVisibility={() => setModalVisibility(false)}
											spinning={spinning}
										/>
									</div>
								</div>
							)
						)}
					</div>

					<div className="flex flex-col gap-10 bg-white rounded-md shadow-md p-6 w-full xl:max-w-lg">
						{(isProfessor && internship?.recruiterId === getUserId()) ||
						isCompany ? (
							<Actions
								internship={internship}
								spinning={spinning}
								setSpinning={setSpinning}
							/>
						) : null}

						<BasicInfo internship={internship} />
					</div>
				</div>
			</div>
		</Spin>
	);
};

const BasicInfo = ({ internship }: { internship?: InternshipDTO }) => {
	const { t } = useTranslation();
	const location = useLocation<
		| {
				origin: string;
				filters: any;
				searchTerm: string;
				sortField: any;
				sortDirection: any;
				currentPage: any;
				pageSize: any;
				activeTab: string;
		  }
		| null
		| undefined
	>();

	return (
		<div className="flex flex-col gap-4">
			{internship?.skillsRequired && internship?.skillsRequired.length > 0 ? (
				<div className="flex gap-4 flex-col">
					<div>{t("internships.requiredSkills")}</div>

					<List
						grid={{
							gutter: 20,
						}}
						dataSource={internship?.skillsRequired}
						renderItem={(item: string) => (
							<List.Item>
								<div className="bg-primary rounded-md text-white px-5 py-2">
									{item}
								</div>
							</List.Item>
						)}
					/>
				</div>
			) : null}

			{internship?.engagementType === InternshipEngagementType.Event && (
				<EventActions item={internship!} />
			)}
			{internship?.engagementType !== InternshipEngagementType.Event && (
				<InternshipActions
					item={internship!}
					activeTab={location.state?.activeTab}
				/>
			)}
		</div>
	);
};

const Actions = ({
	internship,
	spinning,
	setSpinning,
}: {
	internship?: InternshipDTO;
	spinning: boolean;
	setSpinning: (val: boolean) => void;
}) => {
	const location = useLocation<
		| {
				origin: string;
				filters: any;
				searchTerm: string;
				sortField: any;
				sortDirection: any;
				currentPage: any;
				pageSize: any;
				activeTab: string;
		  }
		| null
		| undefined
	>();
	const { t } = useTranslation();
	const [deleteModalVisibility, setDeleteModalVisibility] = useState(false);
	const queryClient = useQueryClient();
	const isCompany = useIsCompany();
	const isProfessor = useIsProfessor();
	const history = useHistory();

	const invalidateInternshipsQueries = async () => {
		await queryClient.invalidateQueries(getInternshipsFilters);
		await queryClient.invalidateQueries(getCompanyInternships);
		await queryClient.invalidateQueries(getProfessorInternships);
		await queryClient.invalidateQueries(getCoordinatedInternships);
		await queryClient.invalidateQueries(getAllApplicationTypesCount);
	};

	const isEditAllowed = (internship: InternshipDTO) => {
		return (
			internship?.state === CompanyInternshipState.Draft ||
			internship?.state === CompanyInternshipState.Approved
		);
	};

	const isDeleteAllowed = (internship: InternshipDTO) => {
		return (
			internship?.state !== CompanyInternshipState.Approved ||
			(internship?.engagementType !==
				InternshipEngagementType.BachelorsInternship &&
				internship?.engagementType !==
					InternshipEngagementType.MastersInternship)
		);
	};

	const handleDelete = () => {
		(isUserLogged() && isProfessor
			? deleteProfessorInternship(internship?.id!)
			: deleteCompanyInternship(internship?.id!)
		)
			.then(async () => {
				await invalidateInternshipsQueries();
				openNotification(
					t("internships.deleteInternship"),
					t("internships.deleteInternshipSuccess"),
					NOTIFICATION_TYPES.SUCCESS
				);
			})
			.catch((_error) => {
				openNotification(
					t("internships.deleteInternship"),
					t("internships.deleteInternshipError"),
					NOTIFICATION_TYPES.ERROR
				);
			})
			.finally(() => {
				setSpinning(false);
				setDeleteModalVisibility(false);

				let state = {
					existingFilters: location.state?.filters,
					searchTerm: location.state?.searchTerm,
					sortField: location.state?.sortField,
					sortDirection: location.state?.sortDirection,
					currentPage: location.state?.currentPage,
					pageSize: location.state?.pageSize,
					activeTab: location.state?.activeTab,
				};

				history.push(location.state!.origin, state);
			});
	};

	return (
		<div className="flex gap-4 flex-wrap xl:flex-nowrap">
			{location.state && (
				<>
					{(isProfessor && internship?.recruiterId === getUserId()) ||
					(isCompany && isEditAllowed(internship!)) ? (
						<Button title={t("internships.edit")} type="primary">
							<Link
								to={{
									pathname: "/editare-propunere/" + internship?.id,
									state: {
										type: internship?.type,
										filters: location.state.filters,
										activeTab: location.state.activeTab,
										currentPage: location.state.currentPage,
										pageSize: location.state.pageSize,
										searchTerm: location.state.searchTerm,
										sortDirection: location.state.sortDirection,
										sortField: location.state.sortField,
									},
								}}
							>
								<FontAwesomeIcon icon={solid("edit")} />
								&nbsp;{t("internships.edit")}
							</Link>
						</Button>
					) : null}

					{(isProfessor && internship?.recruiterId === getUserId()) ||
					(isCompany && isDeleteAllowed(internship!)) ? (
						<Button
							danger
							onClick={() => setDeleteModalVisibility(true)}
							title={
								internship && internship.enrolledStudentsCount > 0
									? t("internships.deletionNotAllowed")
									: t("internships.deleteInternship")
							}
							icon={
								<FontAwesomeIcon
									icon={solid("trash")}
									style={{ paddingRight: "5%" }}
								/>
							}
							disabled={internship && internship.enrolledStudentsCount > 0}
						>
							{t("internships.deleteInternship")}
						</Button>
					) : null}

					{(isProfessor && internship?.recruiterId === getUserId()) ||
					(isCompany &&
						internship?.state === CompanyInternshipState.Approved) ? (
						<Button title={t("internships.applicants")}>
							<Link
								to={{
									pathname: "/aplicanti",
									state: {
										internshipId: internship!.internshipId,
										internshipName: internship!.internshipName,
									},
								}}
							>
								<FontAwesomeIcon icon={solid("users")} /> &nbsp;
								{t("internships.applicants")}
							</Link>
						</Button>
					) : null}

					<ConfirmationModal
						modalText={t("internships.deleteInternshipMessage")}
						handleFunction={() => {
							handleDelete();
						}}
						modalVisibility={deleteModalVisibility}
						title=""
						changeModalVisibility={() => setDeleteModalVisibility(false)}
						spinning={spinning}
					/>
				</>
			)}
		</div>
	);
};

export default InternshipInfo;
