/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface ApplicationTypesCountDTO
 */
export interface ApplicationTypesCountDTO {
	/**
	 *
	 * @type {number}
	 * @memberof ApplicationTypesCountDTO
	 */
	scholarshipApplication?: number;
	/**
	 *
	 * @type {number}
	 * @memberof ApplicationTypesCountDTO
	 */
	bachelorInternshipApplications?: number;
	/**
	 *
	 * @type {number}
	 * @memberof ApplicationTypesCountDTO
	 */
	masterInternshipApplications?: number;
	/**
	 *
	 * @type {number}
	 * @memberof ApplicationTypesCountDTO
	 */
	internshipApplications?: number;
	/**
	 *
	 * @type {number}
	 * @memberof ApplicationTypesCountDTO
	 */
	otherApplications?: number;
	/**
	 *
	 * @type {number}
	 * @memberof ApplicationTypesCountDTO
	 */
	allApplications?: number;
	/**
	 *
	 * @type {number}
	 * @memberof ApplicationTypesCountDTO
	 */
	oldApplications?: number;
}

export function ApplicationTypesCountDTOFromJSON(
	json: any
): ApplicationTypesCountDTO {
	return ApplicationTypesCountDTOFromJSONTyped(json, false);
}

export function ApplicationTypesCountDTOFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): ApplicationTypesCountDTO {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		scholarshipApplication: !exists(json, "scholarshipApplication")
			? undefined
			: json["scholarshipApplication"],
		bachelorInternshipApplications: !exists(
			json,
			"bachelorInternshipApplications"
		)
			? undefined
			: json["bachelorInternshipApplications"],
		masterInternshipApplications: !exists(json, "masterInternshipApplications")
			? undefined
			: json["masterInternshipApplications"],
		internshipApplications: !exists(json, "internshipApplications")
			? undefined
			: json["internshipApplications"],
		otherApplications: !exists(json, "otherApplications")
			? undefined
			: json["otherApplications"],
		allApplications: !exists(json, "allApplications")
			? undefined
			: json["allApplications"],
		oldApplications: !exists(json, "oldApplications")
			? undefined
			: json["oldApplications"],
	};
}

export function ApplicationTypesCountDTOToJSON(
	value?: ApplicationTypesCountDTO | null
): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		scholarshipApplication: value.scholarshipApplication,
		bachelorInternshipApplications: value.bachelorInternshipApplications,
		masterInternshipApplications: value.masterInternshipApplications,
		internshipApplications: value.internshipApplications,
		otherApplications: value.otherApplications,
		allApplications: value.allApplications,
		oldApplications: value.oldApplications,
	};
}
