/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
	Department,
	DepartmentFromJSON,
	DepartmentFromJSONTyped,
	DepartmentToJSON,
} from "./Department";
import {
	Internship,
	InternshipFromJSON,
	InternshipFromJSONTyped,
	InternshipToJSON,
} from "./Internship";

/**
 *
 * @export
 * @interface InternshipDepartment
 */
export interface InternshipDepartment {
	/**
	 *
	 * @type {string}
	 * @memberof InternshipDepartment
	 */
	id?: string;
	/**
	 *
	 * @type {Date}
	 * @memberof InternshipDepartment
	 */
	createdAt?: Date;
	/**
	 *
	 * @type {Date}
	 * @memberof InternshipDepartment
	 */
	updatedAt?: Date;
	/**
	 *
	 * @type {string}
	 * @memberof InternshipDepartment
	 */
	internshipId?: string;
	/**
	 *
	 * @type {string}
	 * @memberof InternshipDepartment
	 */
	departmentId?: string;
	/**
	 *
	 * @type {Internship}
	 * @memberof InternshipDepartment
	 */
	internship?: Internship;
	/**
	 *
	 * @type {Department}
	 * @memberof InternshipDepartment
	 */
	department?: Department;
}

export function InternshipDepartmentFromJSON(json: any): InternshipDepartment {
	return InternshipDepartmentFromJSONTyped(json, false);
}

export function InternshipDepartmentFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): InternshipDepartment {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		id: !exists(json, "id") ? undefined : json["id"],
		createdAt: !exists(json, "createdAt")
			? undefined
			: new Date(json["createdAt"]),
		updatedAt: !exists(json, "updatedAt")
			? undefined
			: new Date(json["updatedAt"]),
		internshipId: !exists(json, "internshipId")
			? undefined
			: json["internshipId"],
		departmentId: !exists(json, "departmentId")
			? undefined
			: json["departmentId"],
		internship: !exists(json, "internship")
			? undefined
			: InternshipFromJSON(json["internship"]),
		department: !exists(json, "department")
			? undefined
			: DepartmentFromJSON(json["department"]),
	};
}

export function InternshipDepartmentToJSON(
	value?: InternshipDepartment | null
): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		id: value.id,
		createdAt:
			value.createdAt === undefined ? undefined : value.createdAt.toISOString(),
		updatedAt:
			value.updatedAt === undefined ? undefined : value.updatedAt.toISOString(),
		internshipId: value.internshipId,
		departmentId: value.departmentId,
		internship: InternshipToJSON(value.internship),
		department: DepartmentToJSON(value.department),
	};
}
