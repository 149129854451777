import { useQuery, useQueryClient } from "react-query";
import Filters from "../Filters";
import { useIsAdmin } from "../../utils/utilFunctions";
import { useCallback, useState } from "react";
import useQueryFilters from "../../Hooks/useQueryFilters";
import { useTranslation } from "react-i18next";
import { Dropdown, Menu, Pagination, Table, Typography } from "antd";
import { getEnrollments } from "../../utils/reactQueriesConstants";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../Notifications/NotificationsUtils";
import {
	getEnrollmentsAdmin,
	resetEnrollmentStatus,
} from "../../Requests/internship-requests";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MenuProps } from "antd/lib";
import { ItemType } from "antd/es/menu/hooks/useItems";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import ConfirmationModal from "../../Containers/ConfirmationModal";

enum ActionKeyEnum {
	ResetStudentStatus,
	ResetRecruiterStatus,
}

const EnrollmentsView = () => {
	const { t } = useTranslation();
	const isAdmin = useIsAdmin();
	const queryClient = useQueryClient();
	const query = useQueryFilters({});
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [modalTitle, setModalTitle] = useState("");
	const [modalText, setModalText] = useState("");
	const [handleFunction, setHandleFunction] = useState(() => () => {});
	const [spinning, setSpinning] = useState(false);

	const openNotificationErrorFetchEnrollments = (ex: any) => {
		if (ex.status) {
			openNotification(
				t("usersText.errorTexts.failedUserGet"),
				t("usersText.errorTexts.serverFailedDescription"),
				NOTIFICATION_TYPES.ERROR
			);
		} else {
			openNotification(
				t("usersText.errorTexts.failedUserGet"),
				t("usersText.errorTexts.networkFailedDescription"),
				NOTIFICATION_TYPES.ERROR
			);
		}
	};

	const { data, isLoading: loading } = useQuery(
		[getEnrollments, isAdmin, query.filters],
		() => {
			return isAdmin ? getEnrollmentsAdmin(query.filters) : null;
		},
		{
			onError: openNotificationErrorFetchEnrollments,
		}
	);

	const onUpdate = useCallback(
		(values: { [key: string]: string[] }) => {
			query.update({ ...values }, true);
		},
		[query]
	);

	let locale = {
		emptyText: t("tableText.noEnrollments"),
	};

	const onPageUpdate = useCallback(
		(page: number, pageSize: number) => {
			query.update({
				page: page.toString(),
				pageSize: pageSize.toString(),
			});
		},
		[query]
	);

	const getActions = (record: any): ItemType[] | undefined => {
		let actions: MenuProps["items"] = [];

		actions.push({
			label: t("admin.resetRecruiterStatus"),
			key: ActionKeyEnum.ResetRecruiterStatus,
			icon: (
				<FontAwesomeIcon icon={solid("redo")} style={{ fontSize: "15px" }} />
			),
			disabled: record.recruiterStatus !== "Rejected",
			title: t("admin.resetRecruiterStatus"),
		});
		actions.push({
			label: t("admin.resetStudentStatus"),
			key: ActionKeyEnum.ResetStudentStatus,
			icon: (
				<FontAwesomeIcon icon={solid("redo")} style={{ fontSize: "15px" }} />
			),
			disabled: record.studentStatus !== "Rejected",
			title: t("admin.resetStudentStatus"),
		});

		return actions;
	};

	const resetStatus = (id: string, studentStatus: boolean) => {
		setSpinning(true);

		resetEnrollmentStatus(id, studentStatus)
			.then(async () => {
				await queryClient.invalidateQueries(getEnrollments);

				openNotification(
					studentStatus
						? t("admin.resetStudentStatus")
						: t("admin.resetRecruiterStatus"),
					t("admin.successResetEnrollmentStatusMessage"),
					NOTIFICATION_TYPES.SUCCESS
				);
			})
			.catch((_error) => {
				openNotification(
					studentStatus
						? t("admin.resetStudentStatus")
						: t("admin.resetRecruiterStatus"),
					t("admin.errorResetEnrollmentStatusMessage"),
					NOTIFICATION_TYPES.ERROR
				);
			})
			.finally(() => {
				setSpinning(false);
				setIsModalVisible(false);
			});
	};

	const onMenuClick = (e: any, record: any) => {
		if (e.key == ActionKeyEnum.ResetRecruiterStatus) {
			setModalTitle(t("admin.resetRecruiterStatus"));
			setModalText(
				t("admin.resetRecruiterStatusMessage") +
					record.internshipName +
					t("admin.resetRecruiterStatusMessage2") +
					record.studentName +
					"?"
			);
			setIsModalVisible(true);
			setHandleFunction(() => () => resetStatus(record.id, false));
		} else if (e.key == ActionKeyEnum.ResetStudentStatus) {
			setModalTitle(t("admin.resetStudentStatus"));
			setModalText(
				t("admin.resetStudentStatusMessage") +
					record.internshipName +
					t("admin.resetStudentStatusMessage2") +
					record.studentName +
					"?"
			);
			setIsModalVisible(true);
			setHandleFunction(() => () => resetStatus(record.id, true));
		}
	};

	const columns = [
		{
			title: t("admin.internshipName"),
			dataIndex: "internshipName",
			key: "internshipName",
			fixed: "left" as "left",
			render: (_text: string | undefined, record: any) => (
				<Link
					to={{
						pathname: `/propunere/${record.internshipId}`,
					}}
				>
					<b>{record.internshipName}</b>
				</Link>
			),
		},
		{
			title: t("admin.engagementType"),
			dataIndex: "engagementType",
			key: "engagementType",
			render: (_text: string | undefined, record: any) =>
				t(`admin.${record.engagementType}`),
		},
		{
			title: t("admin.internshipName"),
			dataIndex: "internshipName",
			key: "internshipName",
		},
		{
			title: t("admin.employerName"),
			dataIndex: "employerName",
			key: "employerName",
		},
		{
			title: t("admin.studentName"),
			dataIndex: "studentName",
			key: "studentName",
		},
		{
			title: t("admin.recruiterStatus"),
			dataIndex: "recruiterStatus",
			key: "recruiterStatus",
			render: (_text: string | undefined, record: any) =>
				t(`admin.${record.recruiterStatus}`),
		},
		{
			title: t("admin.studentStatus"),
			dataIndex: "studentStatus",
			key: "studentStatus",
			render: (_text: string | undefined, record: any) =>
				t(`admin.${record.studentStatus}`),
		},
		{
			title: t("students.actions"),
			dataIndex: "actions",
			fixed: "right" as "right",
			width: 85,
			render: (_text: string | undefined, record: any) => (
				<div>
					<Dropdown
						placement="bottomLeft"
						overlay={
							<Menu
								onClick={(e) => onMenuClick(e, record)}
								items={getActions(record)}
							/>
						}
					>
						<FontAwesomeIcon
							icon={solid("bars")}
							style={{
								cursor: "pointer",
								height: "1rem",
								padding: "6px 0px",
								marginLeft: "13px",
							}}
						/>
					</Dropdown>
				</div>
			),
		},
	];

	return (
		<div className="w-full">
			<div className="flex flex-col px-4 pb-10">
				{isAdmin && (
					<Filters
						filters={[]}
						searchFields={[
							{ name: "studentName", label: t("search.byStudentName") },
							{ name: "employerName", label: t("search.byEmployerName") },
							{ name: "internshipName", label: t("search.byInternshipName") },
						]}
						onUpdate={onUpdate}
						hasSort={false}
					/>
				)}

				<div className="flex flex-col gap-10">
					<div className="flex flex-col items-center justify-between md:flex-row">
						<Typography.Title level={3} className="my-0">
							{data?.totalCount} {t("filtering.results")}
						</Typography.Title>
						{!loading && (
							<Pagination
								defaultCurrent={data?.page}
								defaultPageSize={data?.pageSize}
								total={data?.totalCount}
								onChange={onPageUpdate}
								locale={{ items_per_page: t("pagination") }}
							/>
						)}
					</div>

					<Table
						locale={locale}
						columns={columns}
						dataSource={data?.data ?? []}
						pagination={false}
						scroll={{ x: "calc(700px + 50%)", y: 420 }}
						rowKey={(record) => record.id!}
					/>
				</div>
			</div>
			<ConfirmationModal
				modalText={modalText}
				handleFunction={handleFunction}
				modalVisibility={isModalVisible}
				title={modalTitle}
				changeModalVisibility={() => setIsModalVisible(false)}
				spinning={spinning}
			/>
		</div>
	);
};

export default EnrollmentsView;
