import { useState } from "react";
import styles from "./Notifications.module.scss";
import CustomCard from "../../CustomComponents/CustomCard";
import { List, Row, Col, Button } from "antd";
import { theme } from "../../theme";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../Notifications/NotificationsUtils";
import {
	getAllNotifications,
	readAllNotifications,
	readNotification,
} from "../../Requests/notification-requests";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { computeDateMessage, useIsStudent } from "../../utils/utilFunctions";
import { useQuery, useQueryClient } from "react-query";
import ConfirmationModal from "../../Containers/ConfirmationModal";
import {
	buildNotificationColor,
	buildNotificationText,
	buildNotificationTextColor,
} from "./NotificationsTypes";
import {
	getNotifications,
	getNotificationsNumber,
} from "../../utils/reactQueriesConstants";

const Notifications = () => {
	const { t, i18n } = useTranslation();
	const [modalVisibility, setModalVisibility] = useState(false);
	const [pageSize, setPageSize] = useState(10);
	const [current, setCurrent] = useState(1);
	const isStudent = useIsStudent();
	const queryClient = useQueryClient();
	const [spinning, setSpinning] = useState(false);

	const openNotificationsErrorNotification = (ex: any) => {
		if (ex.status) {
			openNotification(
				t("notifications.error"),
				t("usersText.errorTexts.serverFailedDescription"),
				NOTIFICATION_TYPES.ERROR
			);
		} else {
			openNotification(
				t("notifications.error"),
				t("usersText.errorTexts.networkFailedDescription"),
				NOTIFICATION_TYPES.ERROR
			);
		}
	};

	const { data: notifications, isLoading: loading } = useQuery(
		[getNotifications, current, pageSize],
		() => {
			return getAllNotifications(current, pageSize);
		},
		{
			onError: openNotificationsErrorNotification,
		}
	);

	const handleListChange = (current: number, size: number) => {
		setCurrent(() => current);
		setPageSize(() => size);
	};

	const handleRead = async (id: string) => {
		await readNotification(id);
		await queryClient.invalidateQueries(getNotifications);
		await queryClient.invalidateQueries(getNotificationsNumber);
	};

	const handleDeleteAllNotifications = () => {
		setSpinning(true);

		readAllNotifications()
			.then(async () => {
				await queryClient.invalidateQueries(getNotifications);
				await queryClient.invalidateQueries(getNotificationsNumber);
			})
			.finally(() => {
				setSpinning(false);
				setModalVisibility(false);
			});
	};

	return (
		<Row>
			<Col span={20} style={{ marginLeft: "auto", marginRight: "auto" }}>
				{notifications?.totalCount && notifications?.totalCount > 0 ? (
					<>
						<Button
							style={{
								background: theme.primaryColor,
								color: theme.white,
								marginLeft: window.innerWidth > 1100 ? "16px" : "auto",
								marginRight: window.innerWidth > 1100 ? "0" : "auto",
								marginTop: "1em",
								marginBottom: "0%",
								boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
							}}
							onClick={() => setModalVisibility(true)}
						>
							{t("usersText.notifications.deleteAll")}
						</Button>
						<ConfirmationModal
							modalText={t("internships.cancelMessage")}
							handleFunction={handleDeleteAllNotifications}
							modalVisibility={modalVisibility}
							title=""
							changeModalVisibility={() => setModalVisibility(false)}
							spinning={spinning}
						/>
					</>
				) : null}
				<List
					itemLayout="vertical"
					size="small"
					pagination={{
						total: notifications?.totalCount,
						current: current,
						pageSize: pageSize,
						pageSizeOptions: ["10", "20", "50"],
						defaultPageSize: 10,
						showSizeChanger: true,
						locale: { items_per_page: t("configuration.pagination") },
						position: "both",
						hideOnSinglePage: notifications?.totalCount
							? notifications?.totalCount <= 10
							: true,
						onChange: handleListChange,
					}}
					loading={loading && !notifications}
					dataSource={notifications?.data || []}
					renderItem={(item) => (
						<List.Item
							className={styles.ListItem}
							key={item.id}
							style={{ borderBlockEnd: "none" }}
						>
							<CustomCard
								marginbottom={"0px"}
								backgroundcolor={buildNotificationColor(item)}
							>
								<Row style={{ alignItems: "center" }}>
									<Col xs={24} md={20} className={styles.notificationText}>
										<span style={{ color: buildNotificationTextColor(item) }}>
											<div className={styles.subtitle}>
												{buildNotificationText(
													item,
													isStudent,
													t,
													i18n.language
												)}
											</div>
										</span>
									</Col>
									<Col xs={22} md={3}>
										<span style={{ color: buildNotificationTextColor(item) }}>
											{computeDateMessage(item.createdDate!)}
										</span>
									</Col>
									<Col xs={2} md={1}>
										<Button
											onClick={() => handleRead(item.id ?? "")}
											title={t("notifications.readAction")}
											className={styles.deleteButton}
											style={{
												border: "none",
												marginRight: "5%",
												boxShadow: "none",
												fontSize: "0.9rem",
												paddingBlock: "10px",
												background: "transparent",
											}}
										>
											<FontAwesomeIcon
												icon={solid("trash")}
												style={{
													width: "1.2rem",
													height: "1.2rem",
													color: buildNotificationTextColor(item),
												}}
											/>
										</Button>
									</Col>
								</Row>
							</CustomCard>
						</List.Item>
					)}
				/>
			</Col>
		</Row>
	);
};

export default Notifications;
