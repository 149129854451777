import { PropsWithChildren } from "react";
import {
	ResearchPartnersForProfessorOrCompanyDTO,
	ResearchProposalEnrollmentType,
	ResearchProposalType,
	Role,
} from "../../Api";
import { Table } from "antd";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ResearchProposalProps } from "../../PropsLists/ResearchProposalProps";

type ColumnType<T> = {
	title: string | JSX.Element;
	key: string;
	dataIndex?: string;
	hidden?: boolean;
	render?: (text: string | undefined, record: T) => JSX.Element | string;
};

const ApplicationsList = (props: PropsWithChildren<ResearchProposalProps>) => {
	const { t } = useTranslation();

	let locale = {
		emptyText: t("tableText.noApplications"),
	};

	const columns: ColumnType<ResearchPartnersForProfessorOrCompanyDTO>[] = [
		{
			title: t("account.name"),
			key: "name",
			dataIndex: "name",
			// sorter: (
			// 	a: ResearchPartnersForProfessorOrCompanyDTO,
			// 	b: ResearchPartnersForProfessorOrCompanyDTO
			// ) => {
			// 	if (!a.applicantName || !b.applicantName) return -1;
			// 	return a.applicantName.localeCompare(b.applicantName);
			// },
			render: (
				text: string | undefined,
				record: ResearchPartnersForProfessorOrCompanyDTO
			) => {
				return (
					<Link
						key="profile"
						to={{
							pathname: `/profil`,
							state: {
								id:
									record.enrollmentType ===
									ResearchProposalEnrollmentType.ByProfessor
										? record.professorId
										: record.companyId,
								userType:
									record.researchProposalType ===
									ResearchProposalType.ByProfessor
										? Role.Professor
										: Role.Company, // TBD check here
								filters: props.checkedKeys,
								searchTerm: props.searchTerm,
								sortField: props.sortField,
								sortDirection: props.sortDirection,
								currentPage: props.currentPage,
								pageSize: props.pageSize,
								activeTab: props.activeTab,
								origin: props.location.pathname,
							},
						}}
					>
						<b>
							{record.enrollmentType ===
							ResearchProposalEnrollmentType.ByCompany
								? record.companyName
								: record.professorName}
						</b>
					</Link>
				);
			},
		},
		{
			title: t("applicants.internshipName"),
			key: "researchProposalName",
			dataIndex: "researchProposalName",
			// sorter: (
			// 	a: ResearchPartnersForProfessorOrCompanyDTO,
			// 	b: ResearchPartnersForProfessorOrCompanyDTO
			// ) => {
			// 	if (!a.researchProposalName || !b.researchProposalName) return -1;
			// 	return a.researchProposalName.localeCompare(b.researchProposalName);
			// },
		},
		{
			title: t("students.email"),
			dataIndex: "email",
			key: "email",
			// sorter: (
			// 	a: ResearchPartnersForProfessorOrCompanyDTO,
			// 	b: ResearchPartnersForProfessorOrCompanyDTO
			// ) => {
			// 	if (
			// 		a.enrollmentType === ResearchProposalEnrollmentType.ByCompany &&
			// 		!a.companyUserEmail
			// 	)
			// 		return -1;
			// 	if (
			// 		b.enrollmentType === ResearchProposalEnrollmentType.ByCompany &&
			// 		!b.companyUserEmail
			// 	)
			// 		return -1;
			// 	if (
			// 		a.enrollmentType === ResearchProposalEnrollmentType.ByProfessor &&
			// 		!a.professorEmail &&
			// 		!a.professorPersonalEmail
			// 	)
			// 		return -1;
			// 	if (
			// 		b.enrollmentType === ResearchProposalEnrollmentType.ByProfessor &&
			// 		!b.professorEmail &&
			// 		!b.professorPersonalEmail
			// 	)
			// 		return -1;

			// 	if (a.enrollmentType === ResearchProposalEnrollmentType.ByCompany) {
			// 		if (b.enrollmentType === ResearchProposalEnrollmentType.ByCompany) {
			// 			return a.companyUserEmail?.localeCompare(b.companyUserEmail!);
			// 		} else {
			// 			return a.companyUserEmail?.localeCompare(
			// 				b.professorEmail ?? b.professorPersonalEmail!
			// 			);
			// 		}
			// 	} else {
			// 		if (b.enrollmentType === ResearchProposalEnrollmentType.ByCompany) {
			// 			return a.professorEmail
			// 				? a.professorEmail?.localeCompare(b.companyUserEmail!)
			// 				: a.professorPersonalEmail?.localeCompare(b.companyUserEmail!);
			// 		} else {
			// 			return a.professorEmail
			// 				? a.professorEmail.localeCompare(
			// 						b.professorEmail ?? b.professorPersonalEmail!
			// 				  )
			// 				: a.professorPersonalEmail?.localeCompare(
			// 						b.professorEmail ?? b.professorPersonalEmail!
			// 				  );
			// 		}
			// 	}
			// },
			render: (
				text: string | undefined,
				record: ResearchPartnersForProfessorOrCompanyDTO
			) => (
				<span>
					{record.enrollmentType === ResearchProposalEnrollmentType.ByCompany
						? record.companyUserEmail
						: record.professorEmail ?? record.professorPersonalEmail}
				</span>
			),
			hidden: window.innerWidth < 550,
		},
	].filter((item) => !item.hidden);

	const getKey = (record: any): string => {
		return record.researchProposalEnrollmentId + record.researchProposalId;
	};

	return (
		<Table
			locale={locale}
			columns={columns}
			dataSource={props.data?.data ?? []}
			pagination={false}
			scroll={{ x: "calc(700px + 50%)", y: 420 }}
			rowKey={getKey}
		/>
	);
};

export default ApplicationsList;
