import {
	StudentAppreciationApi,
	StudentAppreciationAddUpdateDTO,
} from "../Api";
import { getAuthConfiguration } from "./configuration";

const AuthStudentAppreciationApiFactory = () =>
	new StudentAppreciationApi(getAuthConfiguration());

export const getAppreciations = (studentId: string) => {
	return AuthStudentAppreciationApiFactory().apiStudentAppreciationGetAllForStudentStudentIdGet(
		{ studentId }
	);
};

export const addAppreciation = (
	studentAppreciationAddUpdateDTO: StudentAppreciationAddUpdateDTO
) => {
	return AuthStudentAppreciationApiFactory().apiStudentAppreciationAddPost({
		studentAppreciationAddUpdateDTO,
	});
};

export const deleteAppreciation = async (id: string) => {
	await AuthStudentAppreciationApiFactory().apiStudentAppreciationDeleteIdDelete(
		{ id }
	);
};

export const updateAppreciation = (
	studentAppreciationAddUpdateDTO: StudentAppreciationAddUpdateDTO
) => {
	return AuthStudentAppreciationApiFactory().apiStudentAppreciationUpdatePut({
		studentAppreciationAddUpdateDTO,
	});
};
