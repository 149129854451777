/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import { Role, RoleFromJSON, RoleFromJSONTyped, RoleToJSON } from "./Role";

/**
 *
 * @export
 * @interface AcademicUserUpdateRoleDTO
 */
export interface AcademicUserUpdateRoleDTO {
	/**
	 *
	 * @type {Array<Role>}
	 * @memberof AcademicUserUpdateRoleDTO
	 */
	roles?: Array<Role> | null;
}

export function AcademicUserUpdateRoleDTOFromJSON(
	json: any
): AcademicUserUpdateRoleDTO {
	return AcademicUserUpdateRoleDTOFromJSONTyped(json, false);
}

export function AcademicUserUpdateRoleDTOFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): AcademicUserUpdateRoleDTO {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		roles: !exists(json, "roles")
			? undefined
			: json["roles"] === null
			? null
			: (json["roles"] as Array<any>).map(RoleFromJSON),
	};
}

export function AcademicUserUpdateRoleDTOToJSON(
	value?: AcademicUserUpdateRoleDTO | null
): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		roles:
			value.roles === undefined
				? undefined
				: value.roles === null
				? null
				: (value.roles as Array<any>).map(RoleToJSON),
	};
}
