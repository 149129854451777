import { useTranslation } from "react-i18next";
import { InternsForEmployerDTO, InternshipEngagementType } from "../../../Api";
import { Tag } from "antd";
import { getUserId } from "../../../utils/utilFunctions";
import { theme } from "antd";
import { useCallback } from "react";

export default function ExpandedDetails({
	record,
}: {
	record: InternsForEmployerDTO;
}) {
	const { t } = useTranslation();
	const { token } = theme.useToken();

	const color = useCallback(
		(type: InternshipEngagementType | undefined) => {
			if (type === InternshipEngagementType.BachelorsInternship)
				return token.colorPrimary;
			if (type === InternshipEngagementType.MastersInternship)
				return token.colorPrimaryActive;
			return token.colorTextBase;
		},
		[token.colorPrimary, token.colorPrimaryActive, token.colorTextBase]
	);

	return (
		<div className="grid grid-cols-2 gap-4 w-fit">
			<div>{t("students.name")}</div>
			<div className="font-bold">{record.name}</div>

			<div>{t("internships.searchProposal")}</div>
			<div className="font-bold">{record.internshipName}</div>

			<div>{t("students.email")}</div>
			<div className="font-bold">{record.email}</div>

			<div>{t("students.studyProgram")}</div>
			<div className="font-bold">{record.studyProgram}</div>

			<div>{t("students.year")}</div>
			<div className="font-bold">{t("students.filters." + record.year)}</div>

			<div>{t("internships.type")}</div>
			<div>
				<Tag color={color(record.internshipEngagementType)}>
					{t("internships.filters." + record.internshipEngagementType)}
				</Tag>
			</div>

			<div>{t("internships.employer")}</div>
			<div className="font-bold">
				{record.internshipEmployerId !== getUserId()
					? record.internshipEmployerName
					: t("internships.you")}
			</div>
		</div>
	);
}
