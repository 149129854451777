/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
	Company,
	CompanyFromJSON,
	CompanyFromJSONTyped,
	CompanyToJSON,
} from "./Company";
import {
	Faculty,
	FacultyFromJSON,
	FacultyFromJSONTyped,
	FacultyToJSON,
} from "./Faculty";

/**
 *
 * @export
 * @interface CompanyFacultyAccord
 */
export interface CompanyFacultyAccord {
	/**
	 *
	 * @type {string}
	 * @memberof CompanyFacultyAccord
	 */
	id?: string;
	/**
	 *
	 * @type {Date}
	 * @memberof CompanyFacultyAccord
	 */
	createdAt?: Date;
	/**
	 *
	 * @type {Date}
	 * @memberof CompanyFacultyAccord
	 */
	updatedAt?: Date;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyFacultyAccord
	 */
	companyId?: string;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyFacultyAccord
	 */
	facultyId?: string;
	/**
	 *
	 * @type {number}
	 * @memberof CompanyFacultyAccord
	 */
	year?: number;
	/**
	 *
	 * @type {boolean}
	 * @memberof CompanyFacultyAccord
	 */
	signed?: boolean;
	/**
	 *
	 * @type {boolean}
	 * @memberof CompanyFacultyAccord
	 */
	read?: boolean;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyFacultyAccord
	 */
	file?: string | null;
	/**
	 *
	 * @type {Company}
	 * @memberof CompanyFacultyAccord
	 */
	company?: Company;
	/**
	 *
	 * @type {Faculty}
	 * @memberof CompanyFacultyAccord
	 */
	faculty?: Faculty;
}

export function CompanyFacultyAccordFromJSON(json: any): CompanyFacultyAccord {
	return CompanyFacultyAccordFromJSONTyped(json, false);
}

export function CompanyFacultyAccordFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): CompanyFacultyAccord {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		id: !exists(json, "id") ? undefined : json["id"],
		createdAt: !exists(json, "createdAt")
			? undefined
			: new Date(json["createdAt"]),
		updatedAt: !exists(json, "updatedAt")
			? undefined
			: new Date(json["updatedAt"]),
		companyId: !exists(json, "companyId") ? undefined : json["companyId"],
		facultyId: !exists(json, "facultyId") ? undefined : json["facultyId"],
		year: !exists(json, "year") ? undefined : json["year"],
		signed: !exists(json, "signed") ? undefined : json["signed"],
		read: !exists(json, "read") ? undefined : json["read"],
		file: !exists(json, "file") ? undefined : json["file"],
		company: !exists(json, "company")
			? undefined
			: CompanyFromJSON(json["company"]),
		faculty: !exists(json, "faculty")
			? undefined
			: FacultyFromJSON(json["faculty"]),
	};
}

export function CompanyFacultyAccordToJSON(
	value?: CompanyFacultyAccord | null
): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		id: value.id,
		createdAt:
			value.createdAt === undefined ? undefined : value.createdAt.toISOString(),
		updatedAt:
			value.updatedAt === undefined ? undefined : value.updatedAt.toISOString(),
		companyId: value.companyId,
		facultyId: value.facultyId,
		year: value.year,
		signed: value.signed,
		read: value.read,
		file: value.file,
		company: CompanyToJSON(value.company),
		faculty: FacultyToJSON(value.faculty),
	};
}
