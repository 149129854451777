/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
	ErrorCodes,
	ErrorCodesFromJSON,
	ErrorCodesFromJSONTyped,
	ErrorCodesToJSON,
} from "./ErrorCodes";
import {
	HttpStatusCode,
	HttpStatusCodeFromJSON,
	HttpStatusCodeFromJSONTyped,
	HttpStatusCodeToJSON,
} from "./HttpStatusCode";

/**
 *
 * @export
 * @interface ErrorMessage
 */
export interface ErrorMessage {
	/**
	 *
	 * @type {string}
	 * @memberof ErrorMessage
	 */
	message?: string | null;
	/**
	 *
	 * @type {ErrorCodes}
	 * @memberof ErrorMessage
	 */
	code?: ErrorCodes;
	/**
	 *
	 * @type {HttpStatusCode}
	 * @memberof ErrorMessage
	 */
	status?: HttpStatusCode;
}

export function ErrorMessageFromJSON(json: any): ErrorMessage {
	return ErrorMessageFromJSONTyped(json, false);
}

export function ErrorMessageFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): ErrorMessage {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		message: !exists(json, "message") ? undefined : json["message"],
		code: !exists(json, "code") ? undefined : ErrorCodesFromJSON(json["code"]),
		status: !exists(json, "status")
			? undefined
			: HttpStatusCodeFromJSON(json["status"]),
	};
}

export function ErrorMessageToJSON(value?: ErrorMessage | null): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		message: value.message,
		code: ErrorCodesToJSON(value.code),
		status: HttpStatusCodeToJSON(value.status),
	};
}
