import Layout from "../../Containers/Layout";
import StudentsTable from "./StudentsTable";
import MyStudentsTable from "./MyStudentsTable";
import { useTranslation } from "react-i18next";
import { Row, Tabs } from "antd";
import { useState } from "react";
import { useLocation } from "react-router-dom";

const { TabPane } = Tabs;

const Students = () => {
	const { t } = useTranslation();
	const location = useLocation<
		| {
				activeTab: string;
		  }
		| null
		| undefined
	>();
	const [activeTab, setActiveTab] = useState(location.state?.activeTab || "1");

	const changeTab = (activeKey: any) => {
		setActiveTab(activeKey);
	};

	return (
		<Layout>
			<Row style={{ justifyContent: "center" }}>
				<Tabs
					activeKey={activeTab}
					centered
					tabBarStyle={{
						display: "flex",
						justifyContent: "space-evenly",
						alignItems: "center",
					}}
					onChange={changeTab}
				>
					<TabPane tab={t("admin.myStudents")} key="1">
						<MyStudentsTable location={location} />
					</TabPane>
					<TabPane tab={t("admin.students")} key="2">
						<StudentsTable />
					</TabPane>
				</Tabs>
			</Row>
		</Layout>
	);
};

export default Students;
