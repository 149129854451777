/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface StudentAppreciationAddUpdateDTO
 */
export interface StudentAppreciationAddUpdateDTO {
	/**
	 *
	 * @type {string}
	 * @memberof StudentAppreciationAddUpdateDTO
	 */
	studentId?: string;
	/**
	 *
	 * @type {string}
	 * @memberof StudentAppreciationAddUpdateDTO
	 */
	title?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof StudentAppreciationAddUpdateDTO
	 */
	message?: string | null;
}

export function StudentAppreciationAddUpdateDTOFromJSON(
	json: any
): StudentAppreciationAddUpdateDTO {
	return StudentAppreciationAddUpdateDTOFromJSONTyped(json, false);
}

export function StudentAppreciationAddUpdateDTOFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): StudentAppreciationAddUpdateDTO {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		studentId: !exists(json, "studentId") ? undefined : json["studentId"],
		title: !exists(json, "title") ? undefined : json["title"],
		message: !exists(json, "message") ? undefined : json["message"],
	};
}

export function StudentAppreciationAddUpdateDTOToJSON(
	value?: StudentAppreciationAddUpdateDTO | null
): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		studentId: value.studentId,
		title: value.title,
		message: value.message,
	};
}
