import React, { useState, useRef, useEffect } from "react";
import ReactCrop, {
	centerCrop,
	makeAspectCrop,
	Crop,
	PixelCrop,
} from "react-image-crop";
import { CanvasPreview } from "./CanvasPreview";
import "react-image-crop/dist/ReactCrop.css";
import { useTranslation } from "react-i18next";
import { InputNumber, Modal } from "antd";
import CustomModalFooter from "../../Containers/CustomModalFooter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import CustomButton from "../../CustomComponents/CustomButton";
import { NOTIFICATION_TYPES } from "../Notifications/NotificationsUtils";
import { openNotification } from "../Notifications/NotificationsUtils";
import { reduceImageSize } from "../../utils/utilFunctions";

function useDebounceEffect(fn: () => void, waitTime: number, deps: any) {
	useEffect(() => {
		const t = setTimeout(() => {
			fn.apply(undefined, deps);
		}, waitTime);

		return () => {
			clearTimeout(t);
		};
	}, deps);
}

function centerAspectCrop(
	mediaWidth: number,
	mediaHeight: number,
	aspect: number
) {
	return centerCrop(
		makeAspectCrop(
			{
				unit: "%",
				width: 90,
			},
			aspect,
			mediaWidth,
			mediaHeight
		),
		mediaWidth,
		mediaHeight
	);
}
const ImageCropModal = (props: {
	changeProfilePicture: any;
	changeLowResPicture: any;
	modalVisibility: boolean;
	changeModalVisibility: any;
}) => {
	const { t } = useTranslation();
	const [imgSrc, setImgSrc] = useState("");
	const previewCanvasRef = useRef<HTMLCanvasElement>(null);
	const imgRef = useRef<HTMLImageElement>(null);
	const [crop, setCrop] = useState<Crop>();
	const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
	const [scale, setScale] = useState(1);
	const [rotate, setRotate] = useState(0);
	const [aspect, setAspect] = useState<number | undefined>(1);
	const imageUploader = useRef<any>();

	function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
		if (e.target.files && e.target.files.length > 0) {
			const file = e.target.files[0];
			const maxSizeInBytes = 5 * 1024 * 1024; // 5 MB

			if (file.size > maxSizeInBytes) {
				imageSizeError();
				return;
			}

			setCrop(undefined); // Makes crop preview update between images.
			const reader = new FileReader();
			reader.addEventListener("load", () =>
				setImgSrc(reader.result?.toString() || "")
			);
			reader.readAsDataURL(file);
		}
	}

	const imageSizeError = () => {
		openNotification(
			t("account.imageSizeError"),
			t("account.imageSizeLimitError"),
			NOTIFICATION_TYPES.ERROR,
			5
		);
	};

	function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
		if (aspect) {
			const { width, height } = e.currentTarget;
			setCrop(centerAspectCrop(width, height, aspect));
		}
	}

	const handleChangeImage = async () => {
		let canvas = document.getElementById("canvas") as HTMLCanvasElement;
		let dataURL = canvas?.toDataURL("image/png");
		let dataBlob = await (await fetch(dataURL)).blob();

		props.changeProfilePicture(dataURL);
		props.changeLowResPicture(await reduceImageSize(dataBlob));
		props.changeModalVisibility(false);
	};

	useDebounceEffect(
		async () => {
			if (
				completedCrop?.width &&
				completedCrop?.height &&
				imgRef.current &&
				previewCanvasRef.current
			) {
				CanvasPreview(
					imgRef.current,
					previewCanvasRef.current,
					completedCrop,
					scale,
					rotate
				);
			}
		},
		100,
		[completedCrop, scale, rotate]
	);

	return (
		<Modal
			open={props.modalVisibility}
			title={
				t("account.changeProfilePicture") + ". " + t("account.imageSizeLimit")
			}
			onCancel={() => props.changeModalVisibility(false)}
			onOk={handleChangeImage}
			width={window.innerWidth > 1215 ? "30%" : "80%"}
			footer={
				<CustomModalFooter
					handleClose={() => props.changeModalVisibility(false)}
					handleSave={handleChangeImage}
					confirmButtonName={t("account.confirm")}
				/>
			}
		>
			<div className="App">
				<div className="Crop-Controls">
					<input
						ref={imageUploader}
						type="file"
						accept="image/*"
						onChange={onSelectFile}
						style={{ display: "none" }}
					/>
					<CustomButton
						icon={<FontAwesomeIcon icon={solid("upload")} />}
						marginbottom={"1em"}
						style={{ marginTop: "0.5rem" }}
						onClick={(_e: any) =>
							imageUploader.current && imageUploader.current.click()
						}
					>
						&nbsp;{t("documents.upload")}
					</CustomButton>
				</div>
				{!!imgSrc && (
					<>
						<ReactCrop
							crop={crop}
							onChange={(_, percentCrop) => setCrop(percentCrop)}
							onComplete={(c) => setCompletedCrop(c)}
							aspect={aspect}
							circularCrop={true}
						>
							<img
								ref={imgRef}
								alt="Crop me"
								src={imgSrc}
								style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
								onLoad={onImageLoad}
							/>
						</ReactCrop>
						<div style={{ width: "100%" }}>
							<label htmlFor="scale-input">{t("profile.scale")}: </label>
							<InputNumber
								type="number"
								step="0.1"
								value={scale}
								disabled={!imgSrc}
								onChange={(e) => setScale(Number(e))}
								style={{
									marginBottom: "1rem",
									marginTop: "0.5rem",
									width: "85%",
								}}
							/>
						</div>
					</>
				)}
				{!!completedCrop && (
					<>
						<div>
							<label>{t("profile.preview")}: </label>
							<canvas
								ref={previewCanvasRef}
								id={"canvas"}
								style={{
									border: "1px solid black",
									borderRadius: "50%",
									objectFit: "contain",
									width: completedCrop.width,
									height: completedCrop.height,
									marginTop: "0.5rem",
								}}
							/>
						</div>
					</>
				)}
			</div>
		</Modal>
	);
};

export default ImageCropModal;
