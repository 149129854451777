export enum ActionKeyEnum {
	EditCompany,
	DeleteCompany,
	Contacts,
	ContactsReport,
}

export type CompaniesViewProps = {
	location?: any;
};
