import {
	AddUpdateCompanyUserFormController,
	AddUpdateCompanyUserModalFormProps,
} from "./AddUpdateCompanyUserModalForm.types";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { CompanyUserAddUpdateDTO, ErrorCodes } from "../../../Api";
import { Form } from "antd";
import { AddUpdateCompanyUserFormModel } from "./models/AddUpdateCompanyUserFormModel";
import {
	useIsAdmin,
	useIsCompany,
	useIsUPBAcademicUser,
} from "../../../utils/utilFunctions";
import { useQueryClient } from "react-query";
import { useInitAddUpdateCompanyUserForm } from "./services/useInitAddUpdateCompanyUserForm";
import { getCompanyUsers } from "../../../utils/reactQueriesConstants";
import { getErrorFromResponse } from "../../../utils/responseUtils";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../../Notifications/NotificationsUtils";
import {
	addCompanyContactUsers,
	addCompanyUsers,
	addCompanyUsersAdmin,
	getCompanyUserById,
	updateCompanyUser,
	updateCompanyUserAdmin,
} from "../../../Requests/company-users-requests";
import { toDomainModel } from "./transformers/model.transformers";
import { StatusCodes } from "http-status-codes";

export const useAddUpdateCompanyUserFormController = (
	props: AddUpdateCompanyUserModalFormProps
): AddUpdateCompanyUserFormController => {
	const { t } = useTranslation();
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [spinning, setSpinning] = useState(false);
	const [user, setUser] = useState<CompanyUserAddUpdateDTO>();
	const [form] = Form.useForm<AddUpdateCompanyUserFormModel>();
	const isAcademicUser = useIsUPBAcademicUser();
	const isCompany = useIsCompany();
	const isAdmin = useIsAdmin();
	const queryClient = useQueryClient();
	const { defaultValues, yupSync } = useInitAddUpdateCompanyUserForm(form);

	const invalidateUsersQuery = async () => {
		await queryClient.invalidateQueries(getCompanyUsers);
	};

	useEffect(() => {
		if (props.type === "edit") {
			getCompanyUserById(props.userId)
				.then((response: any) => {
					form.setFieldsValue({
						...response,
						function: response._function,
					});
					setUser(response);
				})
				.catch((ex: any) => {
					if (ex.status && ex.status === StatusCodes.NOT_FOUND) {
						openNotification(
							t("account.error"),
							t("account.notFoundUser"),
							NOTIFICATION_TYPES.ERROR
						);
					} else {
						openNotification(
							t("account.error"),
							t("account.unknownError"),
							NOTIFICATION_TYPES.ERROR
						);
					}
				});
		}
	}, [form, props.type, props.userId, t]);

	const handleShow = () => {
		setIsModalVisible(true);
	};

	const handleClose = () => {
		setIsModalVisible(false);
	};

	const saveChangesError = async (ex: any) => {
		const errorMessage = await getErrorFromResponse(ex);

		let notificationMessage;

		switch (errorMessage?.code) {
			case ErrorCodes.UserNotFound:
				notificationMessage = t("account.userNotFound");
				break;
			case ErrorCodes.NotRepresentative:
				notificationMessage = t("account.notEnoughPermissions");
				break;
			case ErrorCodes.CompanyUserAlreadyExists:
				notificationMessage = t("signupText.messages.userExists");
				break;
			default:
				notificationMessage = t("account.unknownError");
				break;
		}

		openNotification(
			t("account.error"),
			notificationMessage,
			NOTIFICATION_TYPES.ERROR
		);
	};

	const missingFieldsError = () => {
		openNotification(
			t("account.saveError"),
			t("usersText.requiredFieldsError"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const handleAdd = () => {
		form
			.validateFields()
			.then((values) => {
				setSpinning(true);

				if (isAdmin) {
					addCompanyUsersAdmin(toDomainModel(values), props.companyId!)
						.then(async () => {
							form.setFieldsValue({
								name: "",
								email: "",
								phone: "",
								function: "",
								department: "",
							});

							await invalidateUsersQuery();
						})
						.catch(saveChangesError)
						.finally(() => {
							setSpinning(false);
							setIsModalVisible(false);
						});
				} else if (form.getFieldsValue().isContact || isAcademicUser) {
					addCompanyContactUsers(toDomainModel(values), props.userId)
						.then(async () => {
							form.setFieldsValue({
								name: "",
								email: "",
								phone: "",
								function: "",
								department: "",
							});

							await invalidateUsersQuery();
						})
						.catch(saveChangesError)
						.finally(() => {
							setSpinning(false);
							setIsModalVisible(false);
						});
				} else {
					addCompanyUsers(toDomainModel(values))
						.then(async () => {
							form.setFieldsValue({
								name: "",
								email: "",
								phone: "",
								function: "",
								department: "",
							});

							await invalidateUsersQuery();
						})
						.catch(saveChangesError)
						.finally(() => {
							setSpinning(false);
							setIsModalVisible(false);
						});
				}
			})
			.catch(missingFieldsError);
	};

	const handleUpdate = () => {
		form.validateFields().then((values) => {
			setSpinning(true);

			if (!props.isAdmin) {
				updateCompanyUser(props.userId, toDomainModel(values))
					.then(async () => {
						await invalidateUsersQuery();
					})
					.catch(saveChangesError)
					.finally(() => {
						setSpinning(false);
						setIsModalVisible(false);
					});
			} else {
				updateCompanyUserAdmin(props.userId, toDomainModel(values))
					.then(async () => {
						await invalidateUsersQuery();
					})
					.catch(saveChangesError)
					.finally(() => {
						setSpinning(false);
						setIsModalVisible(false);
					});
			}
		});
	};

	return {
		state: {
			form,
			isModalVisible,
			spinning,
		},
		actions: {
			handleShow,
			handleClose,
			handleAdd,
			handleUpdate,
		},
		computed: {
			defaultValues,
			yupSync,
			isContact: user?.isContact,
			isCompany,
			isAdmin,
		},
	};
};
