/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
	UniversityAccordReviewDTO,
	UniversityAccordReviewDTOFromJSON,
	UniversityAccordReviewDTOFromJSONTyped,
	UniversityAccordReviewDTOToJSON,
} from "./UniversityAccordReviewDTO";
import {
	UniversityAccordSignatureDTO,
	UniversityAccordSignatureDTOFromJSON,
	UniversityAccordSignatureDTOFromJSONTyped,
	UniversityAccordSignatureDTOToJSON,
} from "./UniversityAccordSignatureDTO";
import {
	UniversityAccordStateEnum,
	UniversityAccordStateEnumFromJSON,
	UniversityAccordStateEnumFromJSONTyped,
	UniversityAccordStateEnumToJSON,
} from "./UniversityAccordStateEnum";

/**
 *
 * @export
 * @interface CompanyUniversityAccordDTO
 */
export interface CompanyUniversityAccordDTO {
	/**
	 *
	 * @type {string}
	 * @memberof CompanyUniversityAccordDTO
	 */
	id?: string;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyUniversityAccordDTO
	 */
	companyId?: string;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyUniversityAccordDTO
	 */
	companyName?: string | null;
	/**
	 *
	 * @type {UniversityAccordStateEnum}
	 * @memberof CompanyUniversityAccordDTO
	 */
	state?: UniversityAccordStateEnum;
	/**
	 *
	 * @type {number}
	 * @memberof CompanyUniversityAccordDTO
	 */
	number?: number;
	/**
	 *
	 * @type {Date}
	 * @memberof CompanyUniversityAccordDTO
	 */
	generationDate?: Date;
	/**
	 *
	 * @type {Date}
	 * @memberof CompanyUniversityAccordDTO
	 */
	endDate?: Date;
	/**
	 *
	 * @type {Date}
	 * @memberof CompanyUniversityAccordDTO
	 */
	updatedAt?: Date;
	/**
	 *
	 * @type {Array<UniversityAccordSignatureDTO>}
	 * @memberof CompanyUniversityAccordDTO
	 */
	signatures?: Array<UniversityAccordSignatureDTO> | null;
	/**
	 *
	 * @type {Array<UniversityAccordReviewDTO>}
	 * @memberof CompanyUniversityAccordDTO
	 */
	reviews?: Array<UniversityAccordReviewDTO> | null;
}

export function CompanyUniversityAccordDTOFromJSON(
	json: any
): CompanyUniversityAccordDTO {
	return CompanyUniversityAccordDTOFromJSONTyped(json, false);
}

export function CompanyUniversityAccordDTOFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): CompanyUniversityAccordDTO {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		id: !exists(json, "id") ? undefined : json["id"],
		companyId: !exists(json, "companyId") ? undefined : json["companyId"],
		companyName: !exists(json, "companyName") ? undefined : json["companyName"],
		state: !exists(json, "state")
			? undefined
			: UniversityAccordStateEnumFromJSON(json["state"]),
		number: !exists(json, "number") ? undefined : json["number"],
		generationDate: !exists(json, "generationDate")
			? undefined
			: new Date(json["generationDate"]),
		endDate: !exists(json, "endDate") ? undefined : new Date(json["endDate"]),
		updatedAt: !exists(json, "updatedAt")
			? undefined
			: new Date(json["updatedAt"]),
		signatures: !exists(json, "signatures")
			? undefined
			: json["signatures"] === null
			? null
			: (json["signatures"] as Array<any>).map(
					UniversityAccordSignatureDTOFromJSON
			  ),
		reviews: !exists(json, "reviews")
			? undefined
			: json["reviews"] === null
			? null
			: (json["reviews"] as Array<any>).map(UniversityAccordReviewDTOFromJSON),
	};
}

export function CompanyUniversityAccordDTOToJSON(
	value?: CompanyUniversityAccordDTO | null
): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		id: value.id,
		companyId: value.companyId,
		companyName: value.companyName,
		state: UniversityAccordStateEnumToJSON(value.state),
		number: value.number,
		generationDate:
			value.generationDate === undefined
				? undefined
				: value.generationDate.toISOString(),
		endDate:
			value.endDate === undefined ? undefined : value.endDate.toISOString(),
		updatedAt:
			value.updatedAt === undefined ? undefined : value.updatedAt.toISOString(),
		signatures:
			value.signatures === undefined
				? undefined
				: value.signatures === null
				? null
				: (value.signatures as Array<any>).map(
						UniversityAccordSignatureDTOToJSON
				  ),
		reviews:
			value.reviews === undefined
				? undefined
				: value.reviews === null
				? null
				: (value.reviews as Array<any>).map(UniversityAccordReviewDTOToJSON),
	};
}
