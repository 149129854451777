import { Button, Checkbox, Modal, Spin, Typography } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { getUserId, useIsStudent } from "../../utils/utilFunctions";
import {
	acceptGDPRDate,
	getGDPRDate,
} from "../../Requests/academic-user-requests";
import { theme } from "../../theme";
import { geStudentGDPRAcceptData } from "../../utils/reactQueriesConstants";
import { CheckboxChangeEvent } from "antd/lib/checkbox";

const AcceptGDPRModal = () => {
	const { t } = useTranslation();
	const [spinning, setSpinning] = useState(false);
	const isStudent = useIsStudent();
	const userId = getUserId();
	const [checked, setChecked] = useState(true);
	const { data: acceptData } = useQuery(
		[geStudentGDPRAcceptData, userId, isStudent],
		() => {
			if (isStudent) {
				return getGDPRDate(userId);
			}
		}
	);
	const [isModalVisible, setIsModalVisible] = useState(false);

	useEffect(() => {
		setIsModalVisible(acceptData !== undefined && !acceptData?.gdprAcceptDate);
	}, [acceptData]);

	const handleOk = () => {
		setSpinning(true);
		acceptGDPRDate(checked);
		setSpinning(false);
		setIsModalVisible(false);
	};

	const onCheckChange = (e: CheckboxChangeEvent) => {
		setChecked(e.target.checked);
	};

	return isStudent ? (
		<div style={{ display: "flex", alignItems: "center" }}>
			<Modal
				open={isModalVisible}
				onOk={handleOk}
				title="GDPR"
				closable={false}
				width={"50%"}
				footer={
					<Button
						key="submit"
						type="primary"
						loading={spinning}
						disabled={spinning}
						onClick={handleOk}
						style={{
							background: theme.secondColor,
							border: theme.secondColor,
							borderRadius: "10px",
						}}
					>
						{t("modal.accept")}
					</Button>
				}
			>
				<Spin spinning={spinning}>
					<div>
						<Typography>
							{t("modal.text")}{" "}
							<a href="/GDPR.docx" download>
								{" "}
								{t("modal.downloadText")}.
							</a>
						</Typography>
						<Checkbox checked={checked} onChange={onCheckChange}>
							{t("modal.publicProfileAccept")}
						</Checkbox>
					</div>
				</Spin>
			</Modal>
		</div>
	) : null;
};

export default AcceptGDPRModal;
