import { FeedbackReportsApi } from "../Api";
import { getAuthConfiguration } from "./configuration";

const ApiFactory = () => new FeedbackReportsApi(getAuthConfiguration());

export const getStudentAnswers = (
	reportYear: number,
	facultyIds: string[],
	studyProgramIds: string[],
	page?: number,
	pageSize?: number
) => {
	return ApiFactory().apiFeedbackReportsGetStudentsAnswersGet({
		reportYear,
		facultyIds,
		studyProgramIds,
		page,
		pageSize,
	});
};

export const getPhdAnswers = (
	reportYear: number,
	facultyIds: string[],
	page?: number,
	pageSize?: number
) => {
	return ApiFactory().apiFeedbackReportsGetPhdAnswersGet({
		reportYear,
		facultyIds,
		page,
		pageSize,
	});
};

export const getProfessorsAnswers = (
	reportYear: number,
	facultyIds: string[],
	departmentIds: string[],
	page?: number,
	pageSize?: number
) => {
	return ApiFactory().apiFeedbackReportsGetProfessorsAnswersGet({
		reportYear,
		facultyIds,
		departmentIds,
		page,
		pageSize,
	});
};

export const getCompanyAnswers = (
	reportYear: number,
	page?: number,
	pageSize?: number
) => {
	return ApiFactory().apiFeedbackReportsGetCompanyAnswersGet({
		reportYear,
		page,
		pageSize,
	});
};
