/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
	AcademicUser,
	AcademicUserFromJSON,
	AcademicUserFromJSONTyped,
	AcademicUserToJSON,
} from "./AcademicUser";
import {
	ExperienceLevel,
	ExperienceLevelFromJSON,
	ExperienceLevelFromJSONTyped,
	ExperienceLevelToJSON,
} from "./ExperienceLevel";

/**
 *
 * @export
 * @interface PoliJobsContactDetails
 */
export interface PoliJobsContactDetails {
	/**
	 *
	 * @type {string}
	 * @memberof PoliJobsContactDetails
	 */
	id?: string;
	/**
	 *
	 * @type {Date}
	 * @memberof PoliJobsContactDetails
	 */
	createdAt?: Date;
	/**
	 *
	 * @type {Date}
	 * @memberof PoliJobsContactDetails
	 */
	updatedAt?: Date;
	/**
	 *
	 * @type {string}
	 * @memberof PoliJobsContactDetails
	 */
	studentId?: string;
	/**
	 *
	 * @type {AcademicUser}
	 * @memberof PoliJobsContactDetails
	 */
	student?: AcademicUser;
	/**
	 *
	 * @type {string}
	 * @memberof PoliJobsContactDetails
	 */
	phone?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof PoliJobsContactDetails
	 */
	personalEmail?: string | null;
	/**
	 *
	 * @type {ExperienceLevel}
	 * @memberof PoliJobsContactDetails
	 */
	experienceLevel?: ExperienceLevel;
	/**
	 *
	 * @type {string}
	 * @memberof PoliJobsContactDetails
	 */
	currentCompany?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof PoliJobsContactDetails
	 */
	currentPosition?: string | null;
}

export function PoliJobsContactDetailsFromJSON(
	json: any
): PoliJobsContactDetails {
	return PoliJobsContactDetailsFromJSONTyped(json, false);
}

export function PoliJobsContactDetailsFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): PoliJobsContactDetails {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		id: !exists(json, "id") ? undefined : json["id"],
		createdAt: !exists(json, "createdAt")
			? undefined
			: new Date(json["createdAt"]),
		updatedAt: !exists(json, "updatedAt")
			? undefined
			: new Date(json["updatedAt"]),
		studentId: !exists(json, "studentId") ? undefined : json["studentId"],
		student: !exists(json, "student")
			? undefined
			: AcademicUserFromJSON(json["student"]),
		phone: !exists(json, "phone") ? undefined : json["phone"],
		personalEmail: !exists(json, "personalEmail")
			? undefined
			: json["personalEmail"],
		experienceLevel: !exists(json, "experienceLevel")
			? undefined
			: ExperienceLevelFromJSON(json["experienceLevel"]),
		currentCompany: !exists(json, "currentCompany")
			? undefined
			: json["currentCompany"],
		currentPosition: !exists(json, "currentPosition")
			? undefined
			: json["currentPosition"],
	};
}

export function PoliJobsContactDetailsToJSON(
	value?: PoliJobsContactDetails | null
): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		id: value.id,
		createdAt:
			value.createdAt === undefined ? undefined : value.createdAt.toISOString(),
		updatedAt:
			value.updatedAt === undefined ? undefined : value.updatedAt.toISOString(),
		studentId: value.studentId,
		student: AcademicUserToJSON(value.student),
		phone: value.phone,
		personalEmail: value.personalEmail,
		experienceLevel: ExperienceLevelToJSON(value.experienceLevel),
		currentCompany: value.currentCompany,
		currentPosition: value.currentPosition,
	};
}
