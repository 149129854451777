import { FeatureFlagsDTO, FeatureFlagsEnum } from "../Api";
import { useQuery } from "react-query";
import { getFeatureFlags } from "../Requests/feedback-feature-flags-requests";

export const featureFlagsQuery = "featureFlags";

export const useFeatureFlagLists = (): FeatureFlagsDTO[] => {
	const { data } = useQuery(
		[featureFlagsQuery],
		() => {
			return getFeatureFlags();
		},
		{
			refetchOnWindowFocus: false,
			refetchInterval: 5 * 60 * 1000,
		}
	);

	return data ?? [];
};

type UseFlagsMap = {
	[K in keyof typeof FeatureFlagsEnum as `is${K & string}`]: boolean;
};
export const featureFlagTypeKeys = Object.keys(FeatureFlagsEnum).map(
	(e) => FeatureFlagsEnum[e as keyof typeof FeatureFlagsEnum]
);

export const useFeatureFlags = () => {
	const featureFlags = useFeatureFlagLists();

	return featureFlagTypeKeys.reduce((acc, x) => {
		return {
			...acc,
			[`is${x}`]: featureFlags.some((e) => e.value && e.featureFlag === x),
		};
	}, {} as UseFlagsMap);
};
