/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
	StudentPersonalDataAddUpdateDTO,
	StudentPersonalDataAddUpdateDTOFromJSON,
	StudentPersonalDataAddUpdateDTOToJSON,
	StudentPersonalDataDTO,
	StudentPersonalDataDTOFromJSON,
	StudentPersonalDataDTOToJSON,
} from "../models";

export interface ApiStudentPersonalDataAddPostRequest {
	studentPersonalDataAddUpdateDTO?: StudentPersonalDataAddUpdateDTO;
}

export interface ApiStudentPersonalDataGetForStudentStudentIdGetRequest {
	studentId: string;
}

export interface ApiStudentPersonalDataUpdateIdPutRequest {
	id: string;
	studentPersonalDataAddUpdateDTO?: StudentPersonalDataAddUpdateDTO;
}

/**
 *
 */
export class StudentPersonalDataApi extends runtime.BaseAPI {
	/**
	 */
	async apiStudentPersonalDataAddPostRaw(
		requestParameters: ApiStudentPersonalDataAddPostRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<void>> {
		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		headerParameters["Content-Type"] = "application/json";

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/StudentPersonalData/Add`,
				method: "POST",
				headers: headerParameters,
				query: queryParameters,
				body: StudentPersonalDataAddUpdateDTOToJSON(
					requestParameters.studentPersonalDataAddUpdateDTO
				),
			},
			initOverrides
		);

		return new runtime.VoidApiResponse(response);
	}

	/**
	 */
	async apiStudentPersonalDataAddPost(
		requestParameters: ApiStudentPersonalDataAddPostRequest = {},
		initOverrides?: RequestInit
	): Promise<void> {
		await this.apiStudentPersonalDataAddPostRaw(
			requestParameters,
			initOverrides
		);
	}

	/**
	 */
	async apiStudentPersonalDataGetForStudentStudentIdGetRaw(
		requestParameters: ApiStudentPersonalDataGetForStudentStudentIdGetRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<StudentPersonalDataDTO>> {
		if (
			requestParameters.studentId === null ||
			requestParameters.studentId === undefined
		) {
			throw new runtime.RequiredError(
				"studentId",
				"Required parameter requestParameters.studentId was null or undefined when calling apiStudentPersonalDataGetForStudentStudentIdGet."
			);
		}

		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/StudentPersonalData/GetForStudent/{studentId}`.replace(
					`{${"studentId"}}`,
					encodeURIComponent(String(requestParameters.studentId))
				),
				method: "GET",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			StudentPersonalDataDTOFromJSON(jsonValue)
		);
	}

	/**
	 */
	async apiStudentPersonalDataGetForStudentStudentIdGet(
		requestParameters: ApiStudentPersonalDataGetForStudentStudentIdGetRequest,
		initOverrides?: RequestInit
	): Promise<StudentPersonalDataDTO> {
		const response =
			await this.apiStudentPersonalDataGetForStudentStudentIdGetRaw(
				requestParameters,
				initOverrides
			);
		return await response.value();
	}

	/**
	 */
	async apiStudentPersonalDataUpdateIdPutRaw(
		requestParameters: ApiStudentPersonalDataUpdateIdPutRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<void>> {
		if (requestParameters.id === null || requestParameters.id === undefined) {
			throw new runtime.RequiredError(
				"id",
				"Required parameter requestParameters.id was null or undefined when calling apiStudentPersonalDataUpdateIdPut."
			);
		}

		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		headerParameters["Content-Type"] = "application/json";

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/StudentPersonalData/Update/{id}`.replace(
					`{${"id"}}`,
					encodeURIComponent(String(requestParameters.id))
				),
				method: "PUT",
				headers: headerParameters,
				query: queryParameters,
				body: StudentPersonalDataAddUpdateDTOToJSON(
					requestParameters.studentPersonalDataAddUpdateDTO
				),
			},
			initOverrides
		);

		return new runtime.VoidApiResponse(response);
	}

	/**
	 */
	async apiStudentPersonalDataUpdateIdPut(
		requestParameters: ApiStudentPersonalDataUpdateIdPutRequest,
		initOverrides?: RequestInit
	): Promise<void> {
		await this.apiStudentPersonalDataUpdateIdPutRaw(
			requestParameters,
			initOverrides
		);
	}
}
