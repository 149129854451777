/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";

export interface ApiPracticeProtocolGenerateDocxProtocolGetRequest {
	facultyId?: string;
	duration?: number;
	studentsPerYear?: number;
}

export interface ApiPracticeProtocolGeneratePDFProtocolGetRequest {
	facultyId?: string;
	duration?: number;
	studentsPerYear?: number;
}

/**
 *
 */
export class PracticeProtocolApi extends runtime.BaseAPI {
	/**
	 */
	async apiPracticeProtocolGenerateDocxProtocolGetRaw(
		requestParameters: ApiPracticeProtocolGenerateDocxProtocolGetRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<Blob>> {
		const queryParameters: any = {};

		if (requestParameters.facultyId !== undefined) {
			queryParameters["FacultyId"] = requestParameters.facultyId;
		}

		if (requestParameters.duration !== undefined) {
			queryParameters["Duration"] = requestParameters.duration;
		}

		if (requestParameters.studentsPerYear !== undefined) {
			queryParameters["StudentsPerYear"] = requestParameters.studentsPerYear;
		}

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/PracticeProtocol/GenerateDocxProtocol`,
				method: "GET",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.BlobApiResponse(response);
	}

	/**
	 */
	async apiPracticeProtocolGenerateDocxProtocolGet(
		requestParameters: ApiPracticeProtocolGenerateDocxProtocolGetRequest = {},
		initOverrides?: RequestInit
	): Promise<Blob> {
		const response = await this.apiPracticeProtocolGenerateDocxProtocolGetRaw(
			requestParameters,
			initOverrides
		);
		return await response.value();
	}

	/**
	 */
	async apiPracticeProtocolGeneratePDFProtocolGetRaw(
		requestParameters: ApiPracticeProtocolGeneratePDFProtocolGetRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<Blob>> {
		const queryParameters: any = {};

		if (requestParameters.facultyId !== undefined) {
			queryParameters["FacultyId"] = requestParameters.facultyId;
		}

		if (requestParameters.duration !== undefined) {
			queryParameters["Duration"] = requestParameters.duration;
		}

		if (requestParameters.studentsPerYear !== undefined) {
			queryParameters["StudentsPerYear"] = requestParameters.studentsPerYear;
		}

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/PracticeProtocol/GeneratePDFProtocol`,
				method: "GET",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.BlobApiResponse(response);
	}

	/**
	 */
	async apiPracticeProtocolGeneratePDFProtocolGet(
		requestParameters: ApiPracticeProtocolGeneratePDFProtocolGetRequest = {},
		initOverrides?: RequestInit
	): Promise<Blob> {
		const response = await this.apiPracticeProtocolGeneratePDFProtocolGetRaw(
			requestParameters,
			initOverrides
		);
		return await response.value();
	}
}
