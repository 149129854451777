import { NotificationApi } from "../Api";
import { getAuthConfiguration } from "./configuration";

const AuthNotificationApiFactory = () =>
	new NotificationApi(getAuthConfiguration());

export const getAllNotifications = (page?: number, pageSize?: number) => {
	return AuthNotificationApiFactory().apiNotificationGetForUserGet({
		page,
		pageSize,
	});
};

export const readNotification = (id: string) => {
	return AuthNotificationApiFactory().apiNotificationDeleteIdDelete({ id });
};

export const getCountNotificationsNumber = () => {
	return AuthNotificationApiFactory().apiNotificationGetCountNotificationForUserGet();
};

export const readAllNotifications = () => {
	return AuthNotificationApiFactory().apiNotificationDeleteAllDelete();
};
