export const enum theme {
	//verde deschis
	primaryColor = "#376e6f",
	//verde inchis
	secondColor = "#1c3334",
	lightGreen = "#ddeeee",
	//alb
	white = "white",
	//gri deschis
	lightGray = "#E8E8E8",
	//gri
	forthColor = "#DBD7D7",
	//rosu opac
	redOpac = "rgb(255,0,0, 0.7)",
	//rosu
	red = "rgba(255,0,0)",
	//verde opac
	green = "rgba(55, 110, 111, 0.7)",
	greenOpac = "rgba(55, 110, 111, 0.2)",
	thirdGreen = "#3B8D7F",
	limeGreen = "#7FC881",
	yellow = "#F9F871",
	orange = "#FF8946",
	lightRed = "#EF6873",
	pink = "#C05E91",
	purple = "#805E94",
	//negru
	black = "black",
	//font text
	font = "Montserrat",
	layout = "vertical",
	headerSizeIcons = "22px",
}
