/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
	LandingPageStatisticsDTO,
	LandingPageStatisticsDTOFromJSON,
	LandingPageStatisticsDTOToJSON,
} from "../models";

/**
 *
 */
export class StatisticsApi extends runtime.BaseAPI {
	/**
	 */
	async apiStatisticsGetLandingPageStatisticsGetRaw(
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<LandingPageStatisticsDTO>> {
		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/Statistics/GetLandingPageStatistics`,
				method: "GET",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			LandingPageStatisticsDTOFromJSON(jsonValue)
		);
	}

	/**
	 */
	async apiStatisticsGetLandingPageStatisticsGet(
		initOverrides?: RequestInit
	): Promise<LandingPageStatisticsDTO> {
		const response = await this.apiStatisticsGetLandingPageStatisticsGetRaw(
			initOverrides
		);
		return await response.value();
	}
}
