import { CoTutorSelectProps } from "./CoTutorSelect.types";
import { Form, Select, Spin } from "antd";
import styles from "../../../AddInternshipForm.module.scss";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { getCoTutorProfessors } from "../../../../../../utils/reactQueriesConstants";
import {
	getAllProfessors,
	getProfessorsByIds,
} from "../../../../../../Requests/academic-user-requests";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../../../../../Notifications/NotificationsUtils";

const { Option } = Select;

export const CoTutorSelect = (props: CoTutorSelectProps) => {
	const { t } = useTranslation();

	const openGetErrorNotification = (_error: any) => {
		openNotification(
			t("internships.error"),
			t("internships.fetchDataError"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const { data: coTutorProfessors, isLoading: isLoadingCoTutorProfessors } =
		useQuery(
			[
				getCoTutorProfessors,
				props.isProfessor,
				props.internship,
				props.debouncedUserSearch,
			],
			async () => {
				if (!props.isProfessor) {
					return null;
				}

				if (
					props.internship?.coTutorIds &&
					props.internship?.coTutorIds?.length > 0 &&
					props.debouncedUserSearch === ""
				) {
					return getProfessorsByIds(props.internship?.coTutorIds).then((e) =>
						e.map((x) => {
							return { id: x.id, name: x.name };
						})
					);
				}

				return getAllProfessors(props.debouncedUserSearch, 1, 25).then((e) =>
					e.data?.map((e) => {
						return { id: e.id, name: e.name };
					})
				);
			},
			{
				onError: (err) => {
					openGetErrorNotification(err);
				},
				refetchOnWindowFocus: false,
			}
		);

	return (
		<Form.Item
			label={t("internships.addInternshipForm.professorCoTutor") + ":"}
			name="coTutorIds"
			style={{ marginTop: "-10px", marginBottom: "10px" }}
		>
			<Select
				className={styles.antSelect}
				mode="multiple"
				maxTagCount="responsive"
				allowClear
				showSearch
				style={{ width: "100%", textAlign: "left" }}
				placeholder={t("internships.addInternshipForm.professorCoTutor")}
				filterOption={false}
				onSearch={props.setUserSearch}
				notFoundContent={
					isLoadingCoTutorProfessors ? <Spin size="small" /> : null
				}
			>
				{coTutorProfessors
					?.filter((e) => e.id !== props.currentUserId)
					.map((user) => (
						<Option key={user.id} value={user.id}>
							{user.name}
						</Option>
					))}
			</Select>
		</Form.Item>
	);
};
