/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface StudentPersonalDataAddUpdateDTO
 */
export interface StudentPersonalDataAddUpdateDTO {
	/**
	 *
	 * @type {string}
	 * @memberof StudentPersonalDataAddUpdateDTO
	 */
	birthPlace: string;
	/**
	 *
	 * @type {string}
	 * @memberof StudentPersonalDataAddUpdateDTO
	 */
	city: string;
	/**
	 *
	 * @type {string}
	 * @memberof StudentPersonalDataAddUpdateDTO
	 */
	county: string;
	/**
	 *
	 * @type {string}
	 * @memberof StudentPersonalDataAddUpdateDTO
	 */
	streetName: string;
	/**
	 *
	 * @type {string}
	 * @memberof StudentPersonalDataAddUpdateDTO
	 */
	streetNumber: string;
	/**
	 *
	 * @type {string}
	 * @memberof StudentPersonalDataAddUpdateDTO
	 */
	building?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof StudentPersonalDataAddUpdateDTO
	 */
	floor?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof StudentPersonalDataAddUpdateDTO
	 */
	apartment?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof StudentPersonalDataAddUpdateDTO
	 */
	identitySeries: string;
	/**
	 *
	 * @type {string}
	 * @memberof StudentPersonalDataAddUpdateDTO
	 */
	identityNumber: string;
	/**
	 *
	 * @type {string}
	 * @memberof StudentPersonalDataAddUpdateDTO
	 */
	identityIssuer: string;
	/**
	 *
	 * @type {Date}
	 * @memberof StudentPersonalDataAddUpdateDTO
	 */
	identityDate: Date;
	/**
	 *
	 * @type {string}
	 * @memberof StudentPersonalDataAddUpdateDTO
	 */
	cnp: string;
	/**
	 *
	 * @type {string}
	 * @memberof StudentPersonalDataAddUpdateDTO
	 */
	passport?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof StudentPersonalDataAddUpdateDTO
	 */
	residencePermit?: string | null;
	/**
	 *
	 * @type {Date}
	 * @memberof StudentPersonalDataAddUpdateDTO
	 */
	birthdate: Date;
}

export function StudentPersonalDataAddUpdateDTOFromJSON(
	json: any
): StudentPersonalDataAddUpdateDTO {
	return StudentPersonalDataAddUpdateDTOFromJSONTyped(json, false);
}

export function StudentPersonalDataAddUpdateDTOFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): StudentPersonalDataAddUpdateDTO {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		birthPlace: json["birthPlace"],
		city: json["city"],
		county: json["county"],
		streetName: json["streetName"],
		streetNumber: json["streetNumber"],
		building: !exists(json, "building") ? undefined : json["building"],
		floor: !exists(json, "floor") ? undefined : json["floor"],
		apartment: !exists(json, "apartment") ? undefined : json["apartment"],
		identitySeries: json["identitySeries"],
		identityNumber: json["identityNumber"],
		identityIssuer: json["identityIssuer"],
		identityDate: new Date(json["identityDate"]),
		cnp: json["cnp"],
		passport: !exists(json, "passport") ? undefined : json["passport"],
		residencePermit: !exists(json, "residencePermit")
			? undefined
			: json["residencePermit"],
		birthdate: new Date(json["birthdate"]),
	};
}

export function StudentPersonalDataAddUpdateDTOToJSON(
	value?: StudentPersonalDataAddUpdateDTO | null
): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		birthPlace: value.birthPlace,
		city: value.city,
		county: value.county,
		streetName: value.streetName,
		streetNumber: value.streetNumber,
		building: value.building,
		floor: value.floor,
		apartment: value.apartment,
		identitySeries: value.identitySeries,
		identityNumber: value.identityNumber,
		identityIssuer: value.identityIssuer,
		identityDate: value.identityDate.toISOString(),
		cnp: value.cnp,
		passport: value.passport,
		residencePermit: value.residencePermit,
		birthdate: value.birthdate.toISOString(),
	};
}
