import { useEffect, useState } from "react";
import styles from "./Layout.module.scss";
import { FloatButton } from "antd";

const Layout = (props: any) => {
	const [state, setState] = useState({
		mounted: false,
	});

	useEffect(() => {
		if (!state.mounted) {
			setState((prevState) => ({
				...prevState,
				mounted: true,
			}));
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: "smooth",
			});
		}
	}, [state.mounted]);

	return (
		<div className={styles.container}>
			<div className={styles.content}>{props.children}</div>
			<FloatButton.BackTop />
		</div>
	);
};

export default Layout;
