/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
	AcademicUser,
	AcademicUserFromJSON,
	AcademicUserFromJSONTyped,
	AcademicUserToJSON,
} from "./AcademicUser";
import {
	Company,
	CompanyFromJSON,
	CompanyFromJSONTyped,
	CompanyToJSON,
} from "./Company";

/**
 *
 * @export
 * @interface CompanyPracticeSupervisor
 */
export interface CompanyPracticeSupervisor {
	/**
	 *
	 * @type {string}
	 * @memberof CompanyPracticeSupervisor
	 */
	id?: string;
	/**
	 *
	 * @type {Date}
	 * @memberof CompanyPracticeSupervisor
	 */
	createdAt?: Date;
	/**
	 *
	 * @type {Date}
	 * @memberof CompanyPracticeSupervisor
	 */
	updatedAt?: Date;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyPracticeSupervisor
	 */
	companyId?: string;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyPracticeSupervisor
	 */
	supervisorId?: string;
	/**
	 *
	 * @type {Company}
	 * @memberof CompanyPracticeSupervisor
	 */
	company?: Company;
	/**
	 *
	 * @type {AcademicUser}
	 * @memberof CompanyPracticeSupervisor
	 */
	supervisor?: AcademicUser;
}

export function CompanyPracticeSupervisorFromJSON(
	json: any
): CompanyPracticeSupervisor {
	return CompanyPracticeSupervisorFromJSONTyped(json, false);
}

export function CompanyPracticeSupervisorFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): CompanyPracticeSupervisor {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		id: !exists(json, "id") ? undefined : json["id"],
		createdAt: !exists(json, "createdAt")
			? undefined
			: new Date(json["createdAt"]),
		updatedAt: !exists(json, "updatedAt")
			? undefined
			: new Date(json["updatedAt"]),
		companyId: !exists(json, "companyId") ? undefined : json["companyId"],
		supervisorId: !exists(json, "supervisorId")
			? undefined
			: json["supervisorId"],
		company: !exists(json, "company")
			? undefined
			: CompanyFromJSON(json["company"]),
		supervisor: !exists(json, "supervisor")
			? undefined
			: AcademicUserFromJSON(json["supervisor"]),
	};
}

export function CompanyPracticeSupervisorToJSON(
	value?: CompanyPracticeSupervisor | null
): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		id: value.id,
		createdAt:
			value.createdAt === undefined ? undefined : value.createdAt.toISOString(),
		updatedAt:
			value.updatedAt === undefined ? undefined : value.updatedAt.toISOString(),
		companyId: value.companyId,
		supervisorId: value.supervisorId,
		company: CompanyToJSON(value.company),
		supervisor: AcademicUserToJSON(value.supervisor),
	};
}
