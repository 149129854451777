/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
	CompanyInternship,
	CompanyInternshipFromJSON,
	CompanyInternshipFromJSONTyped,
	CompanyInternshipToJSON,
} from "./CompanyInternship";
import {
	FacultyInternship,
	FacultyInternshipFromJSON,
	FacultyInternshipFromJSONTyped,
	FacultyInternshipToJSON,
} from "./FacultyInternship";
import {
	InternshipDepartment,
	InternshipDepartmentFromJSON,
	InternshipDepartmentFromJSONTyped,
	InternshipDepartmentToJSON,
} from "./InternshipDepartment";
import {
	InternshipEngagementType,
	InternshipEngagementTypeFromJSON,
	InternshipEngagementTypeFromJSONTyped,
	InternshipEngagementTypeToJSON,
} from "./InternshipEngagementType";
import {
	InternshipEnrollment,
	InternshipEnrollmentFromJSON,
	InternshipEnrollmentFromJSONTyped,
	InternshipEnrollmentToJSON,
} from "./InternshipEnrollment";
import {
	InternshipFaculty,
	InternshipFacultyFromJSON,
	InternshipFacultyFromJSONTyped,
	InternshipFacultyToJSON,
} from "./InternshipFaculty";
import {
	InternshipParticipationIntent,
	InternshipParticipationIntentFromJSON,
	InternshipParticipationIntentFromJSONTyped,
	InternshipParticipationIntentToJSON,
} from "./InternshipParticipationIntent";
import {
	InternshipStudyProgram,
	InternshipStudyProgramFromJSON,
	InternshipStudyProgramFromJSONTyped,
	InternshipStudyProgramToJSON,
} from "./InternshipStudyProgram";
import {
	InternshipType,
	InternshipTypeFromJSON,
	InternshipTypeFromJSONTyped,
	InternshipTypeToJSON,
} from "./InternshipType";
import {
	ProfessorInternship,
	ProfessorInternshipFromJSON,
	ProfessorInternshipFromJSONTyped,
	ProfessorInternshipToJSON,
} from "./ProfessorInternship";
import {
	StudentYearEnum,
	StudentYearEnumFromJSON,
	StudentYearEnumFromJSONTyped,
	StudentYearEnumToJSON,
} from "./StudentYearEnum";

/**
 *
 * @export
 * @interface Internship
 */
export interface Internship {
	/**
	 *
	 * @type {string}
	 * @memberof Internship
	 */
	id?: string;
	/**
	 *
	 * @type {Date}
	 * @memberof Internship
	 */
	createdAt?: Date;
	/**
	 *
	 * @type {Date}
	 * @memberof Internship
	 */
	updatedAt?: Date;
	/**
	 *
	 * @type {string}
	 * @memberof Internship
	 */
	name?: string | null;
	/**
	 *
	 * @type {InternshipType}
	 * @memberof Internship
	 */
	type?: InternshipType;
	/**
	 *
	 * @type {InternshipEngagementType}
	 * @memberof Internship
	 */
	engagementType?: InternshipEngagementType;
	/**
	 *
	 * @type {string}
	 * @memberof Internship
	 */
	description?: string | null;
	/**
	 *
	 * @type {number}
	 * @memberof Internship
	 */
	period?: number;
	/**
	 *
	 * @type {number}
	 * @memberof Internship
	 */
	totalPracticeHours?: number;
	/**
	 *
	 * @type {Date}
	 * @memberof Internship
	 */
	availableFrom?: Date;
	/**
	 *
	 * @type {Date}
	 * @memberof Internship
	 */
	availableTo?: Date;
	/**
	 *
	 * @type {Date}
	 * @memberof Internship
	 */
	startingDate?: Date;
	/**
	 *
	 * @type {number}
	 * @memberof Internship
	 */
	initialAvailablePositions?: number;
	/**
	 *
	 * @type {number}
	 * @memberof Internship
	 */
	availablePositions?: number;
	/**
	 *
	 * @type {string}
	 * @memberof Internship
	 */
	location?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof Internship
	 */
	placeId?: string | null;
	/**
	 *
	 * @type {Array<StudentYearEnum>}
	 * @memberof Internship
	 */
	years?: Array<StudentYearEnum> | null;
	/**
	 *
	 * @type {string}
	 * @memberof Internship
	 */
	category?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof Internship
	 */
	shortDescription?: string | null;
	/**
	 *
	 * @type {Array<string>}
	 * @memberof Internship
	 */
	skillsRequired?: Array<string> | null;
	/**
	 *
	 * @type {number}
	 * @memberof Internship
	 */
	viewCount?: number;
	/**
	 *
	 * @type {string}
	 * @memberof Internship
	 */
	internshipLink?: string | null;
	/**
	 *
	 * @type {boolean}
	 * @memberof Internship
	 */
	isArchived?: boolean;
	/**
	 *
	 * @type {CompanyInternship}
	 * @memberof Internship
	 */
	companyInternship?: CompanyInternship;
	/**
	 *
	 * @type {ProfessorInternship}
	 * @memberof Internship
	 */
	professorInternship?: ProfessorInternship;
	/**
	 *
	 * @type {Array<FacultyInternship>}
	 * @memberof Internship
	 */
	facultyInternships?: Array<FacultyInternship> | null;
	/**
	 *
	 * @type {Array<InternshipFaculty>}
	 * @memberof Internship
	 */
	internshipFaculties?: Array<InternshipFaculty> | null;
	/**
	 *
	 * @type {Array<InternshipDepartment>}
	 * @memberof Internship
	 */
	internshipDepartments?: Array<InternshipDepartment> | null;
	/**
	 *
	 * @type {Array<InternshipStudyProgram>}
	 * @memberof Internship
	 */
	internshipStudyPrograms?: Array<InternshipStudyProgram> | null;
	/**
	 *
	 * @type {Array<InternshipEnrollment>}
	 * @memberof Internship
	 */
	internshipEnrollments?: Array<InternshipEnrollment> | null;
	/**
	 *
	 * @type {Array<InternshipParticipationIntent>}
	 * @memberof Internship
	 */
	internshipParticipationIntents?: Array<InternshipParticipationIntent> | null;
}

export function InternshipFromJSON(json: any): Internship {
	return InternshipFromJSONTyped(json, false);
}

export function InternshipFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): Internship {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		id: !exists(json, "id") ? undefined : json["id"],
		createdAt: !exists(json, "createdAt")
			? undefined
			: new Date(json["createdAt"]),
		updatedAt: !exists(json, "updatedAt")
			? undefined
			: new Date(json["updatedAt"]),
		name: !exists(json, "name") ? undefined : json["name"],
		type: !exists(json, "type")
			? undefined
			: InternshipTypeFromJSON(json["type"]),
		engagementType: !exists(json, "engagementType")
			? undefined
			: InternshipEngagementTypeFromJSON(json["engagementType"]),
		description: !exists(json, "description") ? undefined : json["description"],
		period: !exists(json, "period") ? undefined : json["period"],
		totalPracticeHours: !exists(json, "totalPracticeHours")
			? undefined
			: json["totalPracticeHours"],
		availableFrom: !exists(json, "availableFrom")
			? undefined
			: new Date(json["availableFrom"]),
		availableTo: !exists(json, "availableTo")
			? undefined
			: new Date(json["availableTo"]),
		startingDate: !exists(json, "startingDate")
			? undefined
			: new Date(json["startingDate"]),
		initialAvailablePositions: !exists(json, "initialAvailablePositions")
			? undefined
			: json["initialAvailablePositions"],
		availablePositions: !exists(json, "availablePositions")
			? undefined
			: json["availablePositions"],
		location: !exists(json, "location") ? undefined : json["location"],
		placeId: !exists(json, "placeId") ? undefined : json["placeId"],
		years: !exists(json, "years")
			? undefined
			: json["years"] === null
			? null
			: (json["years"] as Array<any>).map(StudentYearEnumFromJSON),
		category: !exists(json, "category") ? undefined : json["category"],
		shortDescription: !exists(json, "shortDescription")
			? undefined
			: json["shortDescription"],
		skillsRequired: !exists(json, "skillsRequired")
			? undefined
			: json["skillsRequired"],
		viewCount: !exists(json, "viewCount") ? undefined : json["viewCount"],
		internshipLink: !exists(json, "internshipLink")
			? undefined
			: json["internshipLink"],
		isArchived: !exists(json, "isArchived") ? undefined : json["isArchived"],
		companyInternship: !exists(json, "companyInternship")
			? undefined
			: CompanyInternshipFromJSON(json["companyInternship"]),
		professorInternship: !exists(json, "professorInternship")
			? undefined
			: ProfessorInternshipFromJSON(json["professorInternship"]),
		facultyInternships: !exists(json, "facultyInternships")
			? undefined
			: json["facultyInternships"] === null
			? null
			: (json["facultyInternships"] as Array<any>).map(
					FacultyInternshipFromJSON
			  ),
		internshipFaculties: !exists(json, "internshipFaculties")
			? undefined
			: json["internshipFaculties"] === null
			? null
			: (json["internshipFaculties"] as Array<any>).map(
					InternshipFacultyFromJSON
			  ),
		internshipDepartments: !exists(json, "internshipDepartments")
			? undefined
			: json["internshipDepartments"] === null
			? null
			: (json["internshipDepartments"] as Array<any>).map(
					InternshipDepartmentFromJSON
			  ),
		internshipStudyPrograms: !exists(json, "internshipStudyPrograms")
			? undefined
			: json["internshipStudyPrograms"] === null
			? null
			: (json["internshipStudyPrograms"] as Array<any>).map(
					InternshipStudyProgramFromJSON
			  ),
		internshipEnrollments: !exists(json, "internshipEnrollments")
			? undefined
			: json["internshipEnrollments"] === null
			? null
			: (json["internshipEnrollments"] as Array<any>).map(
					InternshipEnrollmentFromJSON
			  ),
		internshipParticipationIntents: !exists(
			json,
			"internshipParticipationIntents"
		)
			? undefined
			: json["internshipParticipationIntents"] === null
			? null
			: (json["internshipParticipationIntents"] as Array<any>).map(
					InternshipParticipationIntentFromJSON
			  ),
	};
}

export function InternshipToJSON(value?: Internship | null): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		id: value.id,
		createdAt:
			value.createdAt === undefined ? undefined : value.createdAt.toISOString(),
		updatedAt:
			value.updatedAt === undefined ? undefined : value.updatedAt.toISOString(),
		name: value.name,
		type: InternshipTypeToJSON(value.type),
		engagementType: InternshipEngagementTypeToJSON(value.engagementType),
		description: value.description,
		period: value.period,
		totalPracticeHours: value.totalPracticeHours,
		availableFrom:
			value.availableFrom === undefined
				? undefined
				: value.availableFrom.toISOString(),
		availableTo:
			value.availableTo === undefined
				? undefined
				: value.availableTo.toISOString(),
		startingDate:
			value.startingDate === undefined
				? undefined
				: value.startingDate.toISOString(),
		initialAvailablePositions: value.initialAvailablePositions,
		availablePositions: value.availablePositions,
		location: value.location,
		placeId: value.placeId,
		years:
			value.years === undefined
				? undefined
				: value.years === null
				? null
				: (value.years as Array<any>).map(StudentYearEnumToJSON),
		category: value.category,
		shortDescription: value.shortDescription,
		skillsRequired: value.skillsRequired,
		viewCount: value.viewCount,
		internshipLink: value.internshipLink,
		isArchived: value.isArchived,
		companyInternship: CompanyInternshipToJSON(value.companyInternship),
		professorInternship: ProfessorInternshipToJSON(value.professorInternship),
		facultyInternships:
			value.facultyInternships === undefined
				? undefined
				: value.facultyInternships === null
				? null
				: (value.facultyInternships as Array<any>).map(FacultyInternshipToJSON),
		internshipFaculties:
			value.internshipFaculties === undefined
				? undefined
				: value.internshipFaculties === null
				? null
				: (value.internshipFaculties as Array<any>).map(
						InternshipFacultyToJSON
				  ),
		internshipDepartments:
			value.internshipDepartments === undefined
				? undefined
				: value.internshipDepartments === null
				? null
				: (value.internshipDepartments as Array<any>).map(
						InternshipDepartmentToJSON
				  ),
		internshipStudyPrograms:
			value.internshipStudyPrograms === undefined
				? undefined
				: value.internshipStudyPrograms === null
				? null
				: (value.internshipStudyPrograms as Array<any>).map(
						InternshipStudyProgramToJSON
				  ),
		internshipEnrollments:
			value.internshipEnrollments === undefined
				? undefined
				: value.internshipEnrollments === null
				? null
				: (value.internshipEnrollments as Array<any>).map(
						InternshipEnrollmentToJSON
				  ),
		internshipParticipationIntents:
			value.internshipParticipationIntents === undefined
				? undefined
				: value.internshipParticipationIntents === null
				? null
				: (value.internshipParticipationIntents as Array<any>).map(
						InternshipParticipationIntentToJSON
				  ),
	};
}
