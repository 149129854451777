import { PoliJobsProposalAddUpdateDTO, PoliJobsProposalApi } from "../Api";
import { getAuthConfiguration } from "./configuration";

const AuthPoliJobsProposalApiFactory = () =>
	new PoliJobsProposalApi(getAuthConfiguration());

export const addPoliJobsProposal = async (
	poliJobsProposalAddUpdateDTO: PoliJobsProposalAddUpdateDTO
) => {
	await AuthPoliJobsProposalApiFactory().apiPoliJobsProposalAddPost({
		poliJobsProposalAddUpdateDTO,
	});
};

interface AllPoliJobsProposalsProps {
	searchTerm?: string;
	Company?: Array<string>;
	Location?: Array<string>;
	page?: number;
	pageSize?: number;
}

export const getAllPoliJobsProposals = (props: AllPoliJobsProposalsProps) => {
	const {
		searchTerm,
		Company: companies,
		Location: locations,
		page,
		pageSize,
	} = props;
	return AuthPoliJobsProposalApiFactory().apiPoliJobsProposalGetAllGet({
		searchTerm,
		companies,
		locations,
		page,
		pageSize,
	});
};

export const getAllPoliJobsProposalFilters = () => {
	return AuthPoliJobsProposalApiFactory().apiPoliJobsProposalGetPoliJobsProposalFiltersGet();
};

export const getPoliJobsProposalById = (id: string) => {
	return AuthPoliJobsProposalApiFactory().apiPoliJobsProposalGetByIdIdGet({
		id,
	});
};

export const updatePoliJobsProposalInfo = async (
	id: string,
	poliJobsProposalAddUpdateDTO?: PoliJobsProposalAddUpdateDTO
) => {
	await AuthPoliJobsProposalApiFactory().apiPoliJobsProposalUpdateIdPut({
		id,
		poliJobsProposalAddUpdateDTO,
	});
};

export const deletePoliJobsProposal = async (id: string) => {
	await AuthPoliJobsProposalApiFactory().apiPoliJobsProposalDeleteIdDelete({
		id,
	});
};

export const uploadPoliJobsProposalJD = async (
	poliJobsProposalId: string,
	file: Blob
) => {
	await AuthPoliJobsProposalApiFactory().apiPoliJobsProposalUploadJobsDescriptionPoliJobsProposalIdPost(
		{ poliJobsProposalId, file }
	);
};

export const downloadPoliJobsProposalJD = (poliJobsProposalId: string) => {
	return AuthPoliJobsProposalApiFactory().apiPoliJobsProposalDownloadJobDescriptionPoliJobsProposalIdGet(
		{
			poliJobsProposalId,
		}
	);
};
