import { WithdrawApplicationModalProps } from "./WithdrawApplicationModal.types";
import ConfirmationModal from "../../../../../../Containers/ConfirmationModal";
import { useTranslation } from "react-i18next";
import { withdrawOffer } from "../../../../../../Requests/internship-requests";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../../../../../Notifications/NotificationsUtils";

export const WithdrawApplicationModal = (
	props: WithdrawApplicationModalProps
) => {
	const { t } = useTranslation();

	const withdrawApplication = (item: any) => {
		props.setSpinning(true);

		withdrawOffer(item)
			.then(async () => {
				await props.invalidateApplicantsQueries();
				openNotification(
					t("students.withdrawApplication"),
					t("students.successWithdrawApplicationMessage"),
					NOTIFICATION_TYPES.SUCCESS
				);
			})
			.catch((_error) => {
				openNotification(
					t("students.withdrawApplication"),
					t("students.errorWithdrawApplicationMessage"),
					NOTIFICATION_TYPES.ERROR
				);
			})
			.finally(() => {
				props.setSpinning(false);
				props.setModalVisibility(false);
			});
	};

	return (
		<ConfirmationModal
			modalText={
				t("students.withdrawApplicationMessage") +
				props.selectedApplicant.internshipName +
				t("students.withdrawApplicationMessage2") +
				props.selectedApplicant.name +
				"?"
			}
			handleFunction={() =>
				withdrawApplication(props.selectedApplicant.internshipEnrollmentId)
			}
			modalVisibility={props.modalVisibility}
			title=""
			changeModalVisibility={() => props.setModalVisibility(false)}
			spinning={props.spinning}
			divStyle={{}}
		/>
	);
};
