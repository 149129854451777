import { getProfessorInternshipById } from "../../../Requests/professor-internship-requests";
import { getCompanyInternshipById } from "../../../Requests/company-internship-requests";
import { InternshipType } from "../../../Api";

export const getInternship = async (
	isProfessor?: boolean,
	isFacultyAdmin?: boolean,
	isCompany?: boolean,
	isAdmin?: boolean,
	internshipId?: string,
	activeTab?: string,
	type?: InternshipType
) => {
	if (!internshipId) {
		return null;
	}

	if (!isAdmin && isProfessor) {
		return await getProfessorInternshipById(internshipId);
	}

	if (isCompany) {
		return await getCompanyInternshipById(internshipId);
	}

	if (isAdmin && activeTab === "6") {
		return await getCompanyInternshipById(internshipId);
	}

	if (isAdmin && activeTab === "7") {
		return await getProfessorInternshipById(internshipId);
	}

	if (isCompany || (isFacultyAdmin && type === InternshipType.ByCompany)) {
		return await getCompanyInternshipById(internshipId);
	}

	if (isProfessor) {
		return await getProfessorInternshipById(internshipId);
	}

	return null;
};
