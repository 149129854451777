import {
	FeatureFlagsApi,
	FeatureFlagsEnum,
	FeatureFlagsCategoryEnum,
} from "../Api";
import { getAuthConfiguration } from "./configuration";

const ApiFactory = () => new FeatureFlagsApi(getAuthConfiguration());

export const getFeatureFlags = () => {
	return ApiFactory().apiFeatureFlagsGetFeatureFlagsGet();
};

export const getFeatureFlagsAdmin = () => {
	return ApiFactory().apiFeatureFlagsGetFeatureFlagsAdminGet();
};

export const getFeatureFlagsValues = (category: FeatureFlagsCategoryEnum) => {
	return ApiFactory().apiFeatureFlagsGetFeatureFlagsValuesGet({ category });
};

export const getStudentsFeedbackEnabledFlagValue = () => {
	return ApiFactory().apiFeatureFlagsGetStudentsFeedbackEnabledFlagValueGet();
};

export const getPhdFeedbackEnabledFlagValue = () => {
	return ApiFactory().apiFeatureFlagsGetPhdFeedbackEnabledFlagValueGet();
};

export const getProfessorsFeedbackEnabledFlagValue = () => {
	return ApiFactory().apiFeatureFlagsGetProfessorsFeedbackEnabledFlagValueGet();
};

export const getCompaniesFeedbackEnabledFlagValue = () => {
	return ApiFactory().apiFeatureFlagsGetCompaniesFeedbackEnabledFlagValueGet();
};

export const updateFeatureFlagValue = (
	flag: FeatureFlagsEnum,
	newValue: boolean
) => {
	return ApiFactory().apiFeatureFlagsUpdateFeatureFlagValuePut({
		updateFeatureFlagRequestDTO: { flag, newValue },
	});
};

export const updateFacultyFeatureFlagsValue = (
	flag: FeatureFlagsEnum,
	facultyIds: string[]
) => {
	return ApiFactory().apiFeatureFlagsUpdateFacultyFeatureFlagValuePut({
		updateFacultyFeatureFlagRequestDTO: { flag, facultyIds },
	});
};
