/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
	ANAFCompanyDetailsDTORequestResponse,
	ANAFCompanyDetailsDTORequestResponseFromJSON,
	ANAFCompanyDetailsDTORequestResponseToJSON,
	CompanyAddDTO,
	CompanyAddDTOFromJSON,
	CompanyAddDTOToJSON,
	CompanyCountersDTO,
	CompanyCountersDTOFromJSON,
	CompanyCountersDTOToJSON,
	CompanyDTO,
	CompanyDTOFromJSON,
	CompanyDTOToJSON,
	CompanyDTOPagedResponse,
	CompanyDTOPagedResponseFromJSON,
	CompanyDTOPagedResponseToJSON,
	CompanyDocumentStatusEnum,
	CompanyDocumentStatusEnumFromJSON,
	CompanyDocumentStatusEnumToJSON,
	CompanyLoginDTO,
	CompanyLoginDTOFromJSON,
	CompanyLoginDTOToJSON,
	CompanyLoginResponseDTORequestResponse,
	CompanyLoginResponseDTORequestResponseFromJSON,
	CompanyLoginResponseDTORequestResponseToJSON,
	CompanyStateEnum,
	CompanyStateEnumFromJSON,
	CompanyStateEnumToJSON,
	CompanyUpdateAdminDTO,
	CompanyUpdateAdminDTOFromJSON,
	CompanyUpdateAdminDTOToJSON,
	CompanyUpdateDTO,
	CompanyUpdateDTOFromJSON,
	CompanyUpdateDTOToJSON,
	FacultyDTOPagedResponse,
	FacultyDTOPagedResponseFromJSON,
	FacultyDTOPagedResponseToJSON,
	Filter,
	FilterFromJSON,
	FilterToJSON,
	RequestResponse,
	RequestResponseFromJSON,
	RequestResponseToJSON,
	StringRequestResponse,
	StringRequestResponseFromJSON,
	StringRequestResponseToJSON,
} from "../models";

export interface ApiCompanyActivateIdPutRequest {
	id: string;
}

export interface ApiCompanyAddContactCompanyPostRequest {
	companyAddDTO?: CompanyAddDTO;
}

export interface ApiCompanyAddFacultyOfInterestFacultyIdPutRequest {
	facultyId: string;
}

export interface ApiCompanyAllocatePracticeSupervisorCompanyIdPutRequest {
	companyId: string;
	body?: string;
}

export interface ApiCompanyDeleteFacultyOfInterestFacultyIdDeleteRequest {
	facultyId: string;
}

export interface ApiCompanyDeleteIdDeleteRequest {
	id: string;
}

export interface ApiCompanyDeletePracticeSupervisorCompanyIdDeleteRequest {
	companyId: string;
	body?: string;
}

export interface ApiCompanyGetAllGetRequest {
	namePattern?: string;
	companyStates?: Array<CompanyStateEnum>;
	documentStatus?: Array<CompanyDocumentStatusEnum>;
	facultyOfInterest?: Array<string>;
	page?: number;
	pageSize?: number;
}

export interface ApiCompanyGetByCuiCuiGetRequest {
	cui: number;
}

export interface ApiCompanyGetByIdIdGetRequest {
	id: string;
}

export interface ApiCompanyGetCompanyStateFiltersGetRequest {
	namePattern?: string;
	companyStates?: Array<CompanyStateEnum>;
	documentStatus?: Array<CompanyDocumentStatusEnum>;
	facultyOfInterest?: Array<string>;
	page?: number;
	pageSize?: number;
}

export interface ApiCompanyGetFacultiesOfInterestCompanyIdGetRequest {
	companyId: string;
	namePattern?: string;
	page?: number;
	pageSize?: number;
}

export interface ApiCompanyInvalidateIdPutRequest {
	id: string;
}

export interface ApiCompanyLoginPostRequest {
	companyLoginDTO?: CompanyLoginDTO;
}

export interface ApiCompanyRefreshTokenPostRequest {
	body?: string;
}

export interface ApiCompanyRegisterPostRequest {
	companyAddDTO?: CompanyAddDTO;
}

export interface ApiCompanyResendValidationEmailPostRequest {
	body?: string;
}

export interface ApiCompanyUpdateAdminIdPutRequest {
	id: string;
	companyUpdateAdminDTO?: CompanyUpdateAdminDTO;
}

export interface ApiCompanyUpdateIdPutRequest {
	id: string;
	companyUpdateDTO?: CompanyUpdateDTO;
}

export interface ApiCompanyValidateEmailTokenPostRequest {
	token: string;
}

export interface ApiCompanyValidateIdPutRequest {
	id: string;
}

/**
 *
 */
export class CompanyApi extends runtime.BaseAPI {
	/**
	 */
	async apiCompanyActivateIdPutRaw(
		requestParameters: ApiCompanyActivateIdPutRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<CompanyDTO>> {
		if (requestParameters.id === null || requestParameters.id === undefined) {
			throw new runtime.RequiredError(
				"id",
				"Required parameter requestParameters.id was null or undefined when calling apiCompanyActivateIdPut."
			);
		}

		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/Company/Activate/{id}`.replace(
					`{${"id"}}`,
					encodeURIComponent(String(requestParameters.id))
				),
				method: "PUT",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			CompanyDTOFromJSON(jsonValue)
		);
	}

	/**
	 */
	async apiCompanyActivateIdPut(
		requestParameters: ApiCompanyActivateIdPutRequest,
		initOverrides?: RequestInit
	): Promise<CompanyDTO> {
		const response = await this.apiCompanyActivateIdPutRaw(
			requestParameters,
			initOverrides
		);
		return await response.value();
	}

	/**
	 */
	async apiCompanyAddContactCompanyPostRaw(
		requestParameters: ApiCompanyAddContactCompanyPostRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<RequestResponse>> {
		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		headerParameters["Content-Type"] = "application/json";

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/Company/AddContactCompany`,
				method: "POST",
				headers: headerParameters,
				query: queryParameters,
				body: CompanyAddDTOToJSON(requestParameters.companyAddDTO),
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			RequestResponseFromJSON(jsonValue)
		);
	}

	/**
	 */
	async apiCompanyAddContactCompanyPost(
		requestParameters: ApiCompanyAddContactCompanyPostRequest = {},
		initOverrides?: RequestInit
	): Promise<RequestResponse> {
		const response = await this.apiCompanyAddContactCompanyPostRaw(
			requestParameters,
			initOverrides
		);
		return await response.value();
	}

	/**
	 */
	async apiCompanyAddFacultyOfInterestFacultyIdPutRaw(
		requestParameters: ApiCompanyAddFacultyOfInterestFacultyIdPutRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<RequestResponse>> {
		if (
			requestParameters.facultyId === null ||
			requestParameters.facultyId === undefined
		) {
			throw new runtime.RequiredError(
				"facultyId",
				"Required parameter requestParameters.facultyId was null or undefined when calling apiCompanyAddFacultyOfInterestFacultyIdPut."
			);
		}

		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/Company/AddFacultyOfInterest/{facultyId}`.replace(
					`{${"facultyId"}}`,
					encodeURIComponent(String(requestParameters.facultyId))
				),
				method: "PUT",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			RequestResponseFromJSON(jsonValue)
		);
	}

	/**
	 */
	async apiCompanyAddFacultyOfInterestFacultyIdPut(
		requestParameters: ApiCompanyAddFacultyOfInterestFacultyIdPutRequest,
		initOverrides?: RequestInit
	): Promise<RequestResponse> {
		const response = await this.apiCompanyAddFacultyOfInterestFacultyIdPutRaw(
			requestParameters,
			initOverrides
		);
		return await response.value();
	}

	/**
	 */
	async apiCompanyAllocatePracticeSupervisorCompanyIdPutRaw(
		requestParameters: ApiCompanyAllocatePracticeSupervisorCompanyIdPutRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<RequestResponse>> {
		if (
			requestParameters.companyId === null ||
			requestParameters.companyId === undefined
		) {
			throw new runtime.RequiredError(
				"companyId",
				"Required parameter requestParameters.companyId was null or undefined when calling apiCompanyAllocatePracticeSupervisorCompanyIdPut."
			);
		}

		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		headerParameters["Content-Type"] = "application/json";

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/Company/AllocatePracticeSupervisor/{companyId}`.replace(
					`{${"companyId"}}`,
					encodeURIComponent(String(requestParameters.companyId))
				),
				method: "PUT",
				headers: headerParameters,
				query: queryParameters,
				body: requestParameters.body as any,
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			RequestResponseFromJSON(jsonValue)
		);
	}

	/**
	 */
	async apiCompanyAllocatePracticeSupervisorCompanyIdPut(
		requestParameters: ApiCompanyAllocatePracticeSupervisorCompanyIdPutRequest,
		initOverrides?: RequestInit
	): Promise<RequestResponse> {
		const response =
			await this.apiCompanyAllocatePracticeSupervisorCompanyIdPutRaw(
				requestParameters,
				initOverrides
			);
		return await response.value();
	}

	/**
	 */
	async apiCompanyDeleteFacultyOfInterestFacultyIdDeleteRaw(
		requestParameters: ApiCompanyDeleteFacultyOfInterestFacultyIdDeleteRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<RequestResponse>> {
		if (
			requestParameters.facultyId === null ||
			requestParameters.facultyId === undefined
		) {
			throw new runtime.RequiredError(
				"facultyId",
				"Required parameter requestParameters.facultyId was null or undefined when calling apiCompanyDeleteFacultyOfInterestFacultyIdDelete."
			);
		}

		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/Company/DeleteFacultyOfInterest/{facultyId}`.replace(
					`{${"facultyId"}}`,
					encodeURIComponent(String(requestParameters.facultyId))
				),
				method: "DELETE",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			RequestResponseFromJSON(jsonValue)
		);
	}

	/**
	 */
	async apiCompanyDeleteFacultyOfInterestFacultyIdDelete(
		requestParameters: ApiCompanyDeleteFacultyOfInterestFacultyIdDeleteRequest,
		initOverrides?: RequestInit
	): Promise<RequestResponse> {
		const response =
			await this.apiCompanyDeleteFacultyOfInterestFacultyIdDeleteRaw(
				requestParameters,
				initOverrides
			);
		return await response.value();
	}

	/**
	 */
	async apiCompanyDeleteIdDeleteRaw(
		requestParameters: ApiCompanyDeleteIdDeleteRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<void>> {
		if (requestParameters.id === null || requestParameters.id === undefined) {
			throw new runtime.RequiredError(
				"id",
				"Required parameter requestParameters.id was null or undefined when calling apiCompanyDeleteIdDelete."
			);
		}

		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/Company/Delete/{id}`.replace(
					`{${"id"}}`,
					encodeURIComponent(String(requestParameters.id))
				),
				method: "DELETE",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.VoidApiResponse(response);
	}

	/**
	 */
	async apiCompanyDeleteIdDelete(
		requestParameters: ApiCompanyDeleteIdDeleteRequest,
		initOverrides?: RequestInit
	): Promise<void> {
		await this.apiCompanyDeleteIdDeleteRaw(requestParameters, initOverrides);
	}

	/**
	 */
	async apiCompanyDeletePracticeSupervisorCompanyIdDeleteRaw(
		requestParameters: ApiCompanyDeletePracticeSupervisorCompanyIdDeleteRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<RequestResponse>> {
		if (
			requestParameters.companyId === null ||
			requestParameters.companyId === undefined
		) {
			throw new runtime.RequiredError(
				"companyId",
				"Required parameter requestParameters.companyId was null or undefined when calling apiCompanyDeletePracticeSupervisorCompanyIdDelete."
			);
		}

		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		headerParameters["Content-Type"] = "application/json";

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/Company/DeletePracticeSupervisor/{companyId}`.replace(
					`{${"companyId"}}`,
					encodeURIComponent(String(requestParameters.companyId))
				),
				method: "DELETE",
				headers: headerParameters,
				query: queryParameters,
				body: requestParameters.body as any,
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			RequestResponseFromJSON(jsonValue)
		);
	}

	/**
	 */
	async apiCompanyDeletePracticeSupervisorCompanyIdDelete(
		requestParameters: ApiCompanyDeletePracticeSupervisorCompanyIdDeleteRequest,
		initOverrides?: RequestInit
	): Promise<RequestResponse> {
		const response =
			await this.apiCompanyDeletePracticeSupervisorCompanyIdDeleteRaw(
				requestParameters,
				initOverrides
			);
		return await response.value();
	}

	/**
	 */
	async apiCompanyGetAllCountersGetRaw(
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<CompanyCountersDTO>> {
		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/Company/GetAllCounters`,
				method: "GET",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			CompanyCountersDTOFromJSON(jsonValue)
		);
	}

	/**
	 */
	async apiCompanyGetAllCountersGet(
		initOverrides?: RequestInit
	): Promise<CompanyCountersDTO> {
		const response = await this.apiCompanyGetAllCountersGetRaw(initOverrides);
		return await response.value();
	}

	/**
	 */
	async apiCompanyGetAllGetRaw(
		requestParameters: ApiCompanyGetAllGetRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<CompanyDTOPagedResponse>> {
		const queryParameters: any = {};

		if (requestParameters.namePattern !== undefined) {
			queryParameters["NamePattern"] = requestParameters.namePattern;
		}

		if (requestParameters.companyStates) {
			queryParameters["CompanyStates"] = requestParameters.companyStates;
		}

		if (requestParameters.documentStatus) {
			queryParameters["DocumentStatus"] = requestParameters.documentStatus;
		}

		if (requestParameters.facultyOfInterest) {
			queryParameters["FacultyOfInterest"] =
				requestParameters.facultyOfInterest;
		}

		if (requestParameters.page !== undefined) {
			queryParameters["Page"] = requestParameters.page;
		}

		if (requestParameters.pageSize !== undefined) {
			queryParameters["PageSize"] = requestParameters.pageSize;
		}

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/Company/GetAll`,
				method: "GET",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			CompanyDTOPagedResponseFromJSON(jsonValue)
		);
	}

	/**
	 */
	async apiCompanyGetAllGet(
		requestParameters: ApiCompanyGetAllGetRequest = {},
		initOverrides?: RequestInit
	): Promise<CompanyDTOPagedResponse> {
		const response = await this.apiCompanyGetAllGetRaw(
			requestParameters,
			initOverrides
		);
		return await response.value();
	}

	/**
	 */
	async apiCompanyGetByCuiCuiGetRaw(
		requestParameters: ApiCompanyGetByCuiCuiGetRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<ANAFCompanyDetailsDTORequestResponse>> {
		if (requestParameters.cui === null || requestParameters.cui === undefined) {
			throw new runtime.RequiredError(
				"cui",
				"Required parameter requestParameters.cui was null or undefined when calling apiCompanyGetByCuiCuiGet."
			);
		}

		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/Company/GetByCui/{cui}`.replace(
					`{${"cui"}}`,
					encodeURIComponent(String(requestParameters.cui))
				),
				method: "GET",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			ANAFCompanyDetailsDTORequestResponseFromJSON(jsonValue)
		);
	}

	/**
	 */
	async apiCompanyGetByCuiCuiGet(
		requestParameters: ApiCompanyGetByCuiCuiGetRequest,
		initOverrides?: RequestInit
	): Promise<ANAFCompanyDetailsDTORequestResponse> {
		const response = await this.apiCompanyGetByCuiCuiGetRaw(
			requestParameters,
			initOverrides
		);
		return await response.value();
	}

	/**
	 */
	async apiCompanyGetByIdIdGetRaw(
		requestParameters: ApiCompanyGetByIdIdGetRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<CompanyDTO>> {
		if (requestParameters.id === null || requestParameters.id === undefined) {
			throw new runtime.RequiredError(
				"id",
				"Required parameter requestParameters.id was null or undefined when calling apiCompanyGetByIdIdGet."
			);
		}

		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/Company/GetById/{id}`.replace(
					`{${"id"}}`,
					encodeURIComponent(String(requestParameters.id))
				),
				method: "GET",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			CompanyDTOFromJSON(jsonValue)
		);
	}

	/**
	 */
	async apiCompanyGetByIdIdGet(
		requestParameters: ApiCompanyGetByIdIdGetRequest,
		initOverrides?: RequestInit
	): Promise<CompanyDTO> {
		const response = await this.apiCompanyGetByIdIdGetRaw(
			requestParameters,
			initOverrides
		);
		return await response.value();
	}

	/**
	 */
	async apiCompanyGetCompanyStateFiltersGetRaw(
		requestParameters: ApiCompanyGetCompanyStateFiltersGetRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<Array<Filter>>> {
		const queryParameters: any = {};

		if (requestParameters.namePattern !== undefined) {
			queryParameters["NamePattern"] = requestParameters.namePattern;
		}

		if (requestParameters.companyStates) {
			queryParameters["CompanyStates"] = requestParameters.companyStates;
		}

		if (requestParameters.documentStatus) {
			queryParameters["DocumentStatus"] = requestParameters.documentStatus;
		}

		if (requestParameters.facultyOfInterest) {
			queryParameters["FacultyOfInterest"] =
				requestParameters.facultyOfInterest;
		}

		if (requestParameters.page !== undefined) {
			queryParameters["Page"] = requestParameters.page;
		}

		if (requestParameters.pageSize !== undefined) {
			queryParameters["PageSize"] = requestParameters.pageSize;
		}

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/Company/GetCompanyStateFilters`,
				method: "GET",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			jsonValue.map(FilterFromJSON)
		);
	}

	/**
	 */
	async apiCompanyGetCompanyStateFiltersGet(
		requestParameters: ApiCompanyGetCompanyStateFiltersGetRequest = {},
		initOverrides?: RequestInit
	): Promise<Array<Filter>> {
		const response = await this.apiCompanyGetCompanyStateFiltersGetRaw(
			requestParameters,
			initOverrides
		);
		return await response.value();
	}

	/**
	 */
	async apiCompanyGetFacultiesOfInterestCompanyIdGetRaw(
		requestParameters: ApiCompanyGetFacultiesOfInterestCompanyIdGetRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<FacultyDTOPagedResponse>> {
		if (
			requestParameters.companyId === null ||
			requestParameters.companyId === undefined
		) {
			throw new runtime.RequiredError(
				"companyId",
				"Required parameter requestParameters.companyId was null or undefined when calling apiCompanyGetFacultiesOfInterestCompanyIdGet."
			);
		}

		const queryParameters: any = {};

		if (requestParameters.namePattern !== undefined) {
			queryParameters["NamePattern"] = requestParameters.namePattern;
		}

		if (requestParameters.page !== undefined) {
			queryParameters["Page"] = requestParameters.page;
		}

		if (requestParameters.pageSize !== undefined) {
			queryParameters["PageSize"] = requestParameters.pageSize;
		}

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/Company/GetFacultiesOfInterest/{companyId}`.replace(
					`{${"companyId"}}`,
					encodeURIComponent(String(requestParameters.companyId))
				),
				method: "GET",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			FacultyDTOPagedResponseFromJSON(jsonValue)
		);
	}

	/**
	 */
	async apiCompanyGetFacultiesOfInterestCompanyIdGet(
		requestParameters: ApiCompanyGetFacultiesOfInterestCompanyIdGetRequest,
		initOverrides?: RequestInit
	): Promise<FacultyDTOPagedResponse> {
		const response = await this.apiCompanyGetFacultiesOfInterestCompanyIdGetRaw(
			requestParameters,
			initOverrides
		);
		return await response.value();
	}

	/**
	 */
	async apiCompanyInvalidateIdPutRaw(
		requestParameters: ApiCompanyInvalidateIdPutRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<CompanyDTO>> {
		if (requestParameters.id === null || requestParameters.id === undefined) {
			throw new runtime.RequiredError(
				"id",
				"Required parameter requestParameters.id was null or undefined when calling apiCompanyInvalidateIdPut."
			);
		}

		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/Company/Invalidate/{id}`.replace(
					`{${"id"}}`,
					encodeURIComponent(String(requestParameters.id))
				),
				method: "PUT",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			CompanyDTOFromJSON(jsonValue)
		);
	}

	/**
	 */
	async apiCompanyInvalidateIdPut(
		requestParameters: ApiCompanyInvalidateIdPutRequest,
		initOverrides?: RequestInit
	): Promise<CompanyDTO> {
		const response = await this.apiCompanyInvalidateIdPutRaw(
			requestParameters,
			initOverrides
		);
		return await response.value();
	}

	/**
	 */
	async apiCompanyLoginPostRaw(
		requestParameters: ApiCompanyLoginPostRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<CompanyLoginResponseDTORequestResponse>> {
		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		headerParameters["Content-Type"] = "application/json";

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/Company/Login`,
				method: "POST",
				headers: headerParameters,
				query: queryParameters,
				body: CompanyLoginDTOToJSON(requestParameters.companyLoginDTO),
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			CompanyLoginResponseDTORequestResponseFromJSON(jsonValue)
		);
	}

	/**
	 */
	async apiCompanyLoginPost(
		requestParameters: ApiCompanyLoginPostRequest = {},
		initOverrides?: RequestInit
	): Promise<CompanyLoginResponseDTORequestResponse> {
		const response = await this.apiCompanyLoginPostRaw(
			requestParameters,
			initOverrides
		);
		return await response.value();
	}

	/**
	 */
	async apiCompanyRefreshTokenPostRaw(
		requestParameters: ApiCompanyRefreshTokenPostRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<StringRequestResponse>> {
		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		headerParameters["Content-Type"] = "application/json";

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/Company/RefreshToken`,
				method: "POST",
				headers: headerParameters,
				query: queryParameters,
				body: requestParameters.body as any,
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			StringRequestResponseFromJSON(jsonValue)
		);
	}

	/**
	 */
	async apiCompanyRefreshTokenPost(
		requestParameters: ApiCompanyRefreshTokenPostRequest = {},
		initOverrides?: RequestInit
	): Promise<StringRequestResponse> {
		const response = await this.apiCompanyRefreshTokenPostRaw(
			requestParameters,
			initOverrides
		);
		return await response.value();
	}

	/**
	 */
	async apiCompanyRegisterPostRaw(
		requestParameters: ApiCompanyRegisterPostRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<RequestResponse>> {
		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		headerParameters["Content-Type"] = "application/json";

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/Company/Register`,
				method: "POST",
				headers: headerParameters,
				query: queryParameters,
				body: CompanyAddDTOToJSON(requestParameters.companyAddDTO),
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			RequestResponseFromJSON(jsonValue)
		);
	}

	/**
	 */
	async apiCompanyRegisterPost(
		requestParameters: ApiCompanyRegisterPostRequest = {},
		initOverrides?: RequestInit
	): Promise<RequestResponse> {
		const response = await this.apiCompanyRegisterPostRaw(
			requestParameters,
			initOverrides
		);
		return await response.value();
	}

	/**
	 */
	async apiCompanyResendValidationEmailPostRaw(
		requestParameters: ApiCompanyResendValidationEmailPostRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<RequestResponse>> {
		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		headerParameters["Content-Type"] = "application/json";

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/Company/ResendValidationEmail`,
				method: "POST",
				headers: headerParameters,
				query: queryParameters,
				body: requestParameters.body as any,
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			RequestResponseFromJSON(jsonValue)
		);
	}

	/**
	 */
	async apiCompanyResendValidationEmailPost(
		requestParameters: ApiCompanyResendValidationEmailPostRequest = {},
		initOverrides?: RequestInit
	): Promise<RequestResponse> {
		const response = await this.apiCompanyResendValidationEmailPostRaw(
			requestParameters,
			initOverrides
		);
		return await response.value();
	}

	/**
	 */
	async apiCompanyUpdateAdminIdPutRaw(
		requestParameters: ApiCompanyUpdateAdminIdPutRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<RequestResponse>> {
		if (requestParameters.id === null || requestParameters.id === undefined) {
			throw new runtime.RequiredError(
				"id",
				"Required parameter requestParameters.id was null or undefined when calling apiCompanyUpdateAdminIdPut."
			);
		}

		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		headerParameters["Content-Type"] = "application/json";

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/Company/UpdateAdmin/{id}`.replace(
					`{${"id"}}`,
					encodeURIComponent(String(requestParameters.id))
				),
				method: "PUT",
				headers: headerParameters,
				query: queryParameters,
				body: CompanyUpdateAdminDTOToJSON(
					requestParameters.companyUpdateAdminDTO
				),
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			RequestResponseFromJSON(jsonValue)
		);
	}

	/**
	 */
	async apiCompanyUpdateAdminIdPut(
		requestParameters: ApiCompanyUpdateAdminIdPutRequest,
		initOverrides?: RequestInit
	): Promise<RequestResponse> {
		const response = await this.apiCompanyUpdateAdminIdPutRaw(
			requestParameters,
			initOverrides
		);
		return await response.value();
	}

	/**
	 */
	async apiCompanyUpdateIdPutRaw(
		requestParameters: ApiCompanyUpdateIdPutRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<RequestResponse>> {
		if (requestParameters.id === null || requestParameters.id === undefined) {
			throw new runtime.RequiredError(
				"id",
				"Required parameter requestParameters.id was null or undefined when calling apiCompanyUpdateIdPut."
			);
		}

		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		headerParameters["Content-Type"] = "application/json";

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/Company/Update/{id}`.replace(
					`{${"id"}}`,
					encodeURIComponent(String(requestParameters.id))
				),
				method: "PUT",
				headers: headerParameters,
				query: queryParameters,
				body: CompanyUpdateDTOToJSON(requestParameters.companyUpdateDTO),
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			RequestResponseFromJSON(jsonValue)
		);
	}

	/**
	 */
	async apiCompanyUpdateIdPut(
		requestParameters: ApiCompanyUpdateIdPutRequest,
		initOverrides?: RequestInit
	): Promise<RequestResponse> {
		const response = await this.apiCompanyUpdateIdPutRaw(
			requestParameters,
			initOverrides
		);
		return await response.value();
	}

	/**
	 */
	async apiCompanyValidateEmailTokenPostRaw(
		requestParameters: ApiCompanyValidateEmailTokenPostRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<RequestResponse>> {
		if (
			requestParameters.token === null ||
			requestParameters.token === undefined
		) {
			throw new runtime.RequiredError(
				"token",
				"Required parameter requestParameters.token was null or undefined when calling apiCompanyValidateEmailTokenPost."
			);
		}

		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/Company/ValidateEmail/{token}`.replace(
					`{${"token"}}`,
					encodeURIComponent(String(requestParameters.token))
				),
				method: "POST",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			RequestResponseFromJSON(jsonValue)
		);
	}

	/**
	 */
	async apiCompanyValidateEmailTokenPost(
		requestParameters: ApiCompanyValidateEmailTokenPostRequest,
		initOverrides?: RequestInit
	): Promise<RequestResponse> {
		const response = await this.apiCompanyValidateEmailTokenPostRaw(
			requestParameters,
			initOverrides
		);
		return await response.value();
	}

	/**
	 */
	async apiCompanyValidateIdPutRaw(
		requestParameters: ApiCompanyValidateIdPutRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<CompanyDTO>> {
		if (requestParameters.id === null || requestParameters.id === undefined) {
			throw new runtime.RequiredError(
				"id",
				"Required parameter requestParameters.id was null or undefined when calling apiCompanyValidateIdPut."
			);
		}

		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/Company/Validate/{id}`.replace(
					`{${"id"}}`,
					encodeURIComponent(String(requestParameters.id))
				),
				method: "PUT",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			CompanyDTOFromJSON(jsonValue)
		);
	}

	/**
	 */
	async apiCompanyValidateIdPut(
		requestParameters: ApiCompanyValidateIdPutRequest,
		initOverrides?: RequestInit
	): Promise<CompanyDTO> {
		const response = await this.apiCompanyValidateIdPutRaw(
			requestParameters,
			initOverrides
		);
		return await response.value();
	}
}
