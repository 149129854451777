import {
	ActionKeyEnum,
	DiplomaStudentsViewProps,
} from "./DiplomaStudentsView.types";
import {
	Button,
	Dropdown,
	Form,
	Menu,
	MenuProps,
	Pagination,
	Table,
	Typography,
} from "antd";
import { useTranslation } from "react-i18next";
import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
	getUserId,
	useIsDean,
	useIsDepartmentDirector,
	useIsProfessor,
	useIsStudyProgramCoordinator,
} from "../../../utils/utilFunctions";
import {
	studentIdSet,
	studentInternshipEnrollmentIdSet,
} from "../../Student/StudentSlice";
import {
	addInternshipDocumentSignature,
	approveAllDiplomaDocuments,
	generateInternshipDocument,
} from "../../../Requests/internship-document-requests";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../../Notifications/NotificationsUtils";
import {
	InternsForEmployerDTO,
	InternshipDocumentState,
	InternshipEngagementType,
	InternshipType,
} from "../../../Api";
import { downloadFile, MimeType } from "../../../utils/downloadUtils";
import { ItemType } from "antd/lib/menu/hooks/useItems";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useQuery, useQueryClient } from "react-query";
import { SelectDocumentTypeModal } from "./components/modals/SelectDocumentTypeModal/SelectDocumentTypeModal";
import {
	getFacultiesDepartments,
	getSpecificProfessor,
} from "../../../utils/reactQueriesConstants";
import { getDepartmentsForFaculties } from "../../../Requests/department-requests";
import {
	changeDepartmentForStudent,
	getProfessorById,
} from "../../../Requests/academic-user-requests";
import ConfirmationModal from "../../../Containers/ConfirmationModal";
import useQueryFilters from "../../../Hooks/useQueryFilters";
import { getDiplomaStudentsV2 } from "../../../Requests/internship-requests";
import Filters from "../../Filters";

export const DiplomaStudentsView = (props: DiplomaStudentsViewProps) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const history = useHistory();
	const [visibleDocument, setVisibleDocument] = useState<
		InternsForEmployerDTO | undefined
	>(undefined);
	const [isDocumentModalVisible, setIsDocumentModalVisible] = useState(false);
	const [documentType, setDocumentType] = useState("PDF");
	const [defaultDepartment, setDefaultDepartment] = useState("");
	const [form] = Form.useForm<{
		documentType: string;
		department: string;
	}>();

	const isProfessor = useIsProfessor();
	const isDean = useIsDean();
	const isStudyProgramCoordinator = useIsStudyProgramCoordinator();
	const isDepartmentDirector = useIsDepartmentDirector();
	const [bulkApproveModalVisibility, setBulkApproveModalVisibility] =
		useState(false);
	const [spinning, setSpinning] = useState(false);
	const query = useQueryFilters({});

	const goToStudentProfile = (id: any) => {
		dispatch(studentIdSet(id));
		history.push("/student");
	};

	const goToEditDocumentPage = (enrollmentId: any) => {
		dispatch(studentInternshipEnrollmentIdSet(enrollmentId));
		history.push("/editare-document-propunere");
	};

	const signInternshipDocument = (id: any) => {
		addInternshipDocumentSignature(id)
			.then(async () => {
				await queryClient.invalidateQueries("getInterns");
				await queryClient.invalidateQueries("getFilters");

				openNotification(
					t("students.approveInternshipDocument"),
					t("students.successApproveInternshipDocumentMessage"),
					NOTIFICATION_TYPES.SUCCESS
				);
			})
			.catch((_error) => {
				openNotification(
					t("students.approveInternshipDocument"),
					t("students.errorApproveInternshipDocumentMessage"),
					NOTIFICATION_TYPES.ERROR
				);
			});
	};

	const downloadInternshipDocumentFail = (_err: any) => {
		openNotification(
			t("practiceCertificate.error"),
			t("practiceCertificate.downloadError"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const downloadInternshipDocument = async (
		enrollment: InternsForEmployerDTO
	) => {
		let { documentType, department } = form.getFieldsValue();
		if (enrollment.departmentId !== department) {
			await changeDepartmentForStudent(enrollment.id!, department);
		}
		if (
			enrollment.internshipDocumentStatus ===
				InternshipDocumentState.PendingCoordinatorSignature ||
			enrollment.internshipDocumentStatus ===
				InternshipDocumentState.PendingStudyProgramCoordinatorSignature ||
			enrollment.internshipDocumentStatus ===
				InternshipDocumentState.PendingDepartmentDirectorSignature ||
			enrollment.internshipDocumentStatus ===
				InternshipDocumentState.PendingDeanSignature ||
			enrollment.internshipType === InternshipType.ByProfessor
		) {
			downloadFile(
				generateInternshipDocument(
					enrollment.internshipDocumentId!,
					documentType === "PDF" ? MimeType.pdf : MimeType.docx
				),
				t("students.internshipDocument"),
				documentType === "PDF" ? MimeType.pdf : MimeType.docx
			).catch(downloadInternshipDocumentFail);
		} else {
			downloadFile(
				generateInternshipDocument(
					enrollment.internshipDocumentLastSignatureId!,
					documentType === "PDF" ? MimeType.pdf : MimeType.docx
				),
				t("students.internshipDocument"),
				documentType === "PDF" ? MimeType.pdf : MimeType.docx
			).catch(downloadInternshipDocumentFail);
		}
	};

	const getActions = (
		record: InternsForEmployerDTO
	): ItemType[] | undefined => {
		let actions: MenuProps["items"] = [];

		if (
			(record.internshipEngagementType ===
				InternshipEngagementType.BachelorsInternship ||
				record.internshipEngagementType ===
					InternshipEngagementType.MastersInternship) &&
			record.internshipDocumentStatus !==
				InternshipDocumentState.NotGenerated &&
			record.internshipDocumentStatus !== InternshipDocumentState.InProgress
		) {
			if (
				isProfessor ||
				isDean ||
				isStudyProgramCoordinator ||
				isDepartmentDirector
			) {
				actions.push({
					label: t("students.downloadInternshipDocument"),
					key: ActionKeyEnum.DownloadInternshipDocument + "DiplomaStudents",
					icon: (
						<FontAwesomeIcon
							icon={solid("download")}
							style={{ fontSize: "15px" }}
						/>
					),
				});

				if (
					(record.internshipDocumentStatus ===
						InternshipDocumentState.PendingCoordinatorSignature &&
						record.internshipEmployerId === getUserId()) ||
					(record.internshipDocumentStatus ===
						InternshipDocumentState.PendingStudyProgramCoordinatorSignature &&
						record.documentSigningStudyProgramCoordId === getUserId()) ||
					(record.internshipDocumentStatus ===
						InternshipDocumentState.PendingDepartmentDirectorSignature &&
						record.documentSigningDepartmentDirectorId === getUserId()) ||
					(record.internshipDocumentStatus ===
						InternshipDocumentState.PendingDeanSignature &&
						record.documentSigningDeanId === getUserId())
				) {
					actions.push({
						label: t("students.editInternshipDocument"),
						key: ActionKeyEnum.EditInternshipDocument + "DiplomaStudents",
						icon: (
							<FontAwesomeIcon
								icon={solid("pencil")}
								style={{ fontSize: "15px" }}
							/>
						),
					});

					actions.push({
						label: t("students.validateInternshipDocument"),
						key: ActionKeyEnum.ValidateInternshipDocument + "DiplomaStudents",
						icon: (
							<FontAwesomeIcon
								icon={solid("circle-check")}
								style={{ fontSize: "15px" }}
							/>
						),
					});
				}
			}
		}

		return actions;
	};

	const onMenuClick = (e: any, record: InternsForEmployerDTO) => {
		if (e.key === ActionKeyEnum.EditInternshipDocument + "DiplomaStudents") {
			goToEditDocumentPage(record.internshipEnrollmentId);
		} else if (
			e.key ===
			ActionKeyEnum.DownloadInternshipDocument + "DiplomaStudents"
		) {
			setVisibleDocument(record);
			setDefaultDepartment(record.departmentId!);
			setIsDocumentModalVisible(true);
		} else if (
			e.key ===
			ActionKeyEnum.ValidateInternshipDocument + "DiplomaStudents"
		) {
			signInternshipDocument(record.internshipDocumentId);
		}
	};

	const columns = [
		{
			title: t("account.name"),
			dataIndex: "name",
			fixed: "left" as "left",
			// sorter: (a: DiplomaStudentDTO, b: DiplomaStudentDTO) => {
			// 	if (!a.name || !b.name) return -1;
			// 	return a.name.localeCompare(b.name);
			// },
			render: (text: string | undefined, record: any) => (
				<a onClick={() => goToStudentProfile(record.id)}>
					<b>{text}</b>
				</a>
			),
		},
		{
			title: t("applicants.internshipName"),
			dataIndex: "internshipName",
			key: "internshipName",
			// sorter: (a: DiplomaStudentDTO, b: DiplomaStudentDTO) => {
			// 	if (!a.internshipName || !b.internshipName) return -1;
			// 	return a.internshipName.localeCompare(b.internshipName);
			// },
		},
		{
			title: t("students.studyProgram"),
			dataIndex: "studyProgram",
			key: "studyProgram",
			hidden: window.innerWidth < 550,
			// sorter: (a: DiplomaStudentDTO, b: DiplomaStudentDTO) => {
			// 	if (!a.studyProgram || !b.studyProgram) return -1;
			// 	return a.studyProgram.localeCompare(b.studyProgram);
			// },
		},
		{
			title: t("students.year"),
			dataIndex: "year",
			key: "year",
			hidden: window.innerWidth < 550,
			// sorter: (a: DiplomaStudentDTO, b: DiplomaStudentDTO) => {
			// 	if (!a.year || !b.year) return -1;
			// 	return a.year.localeCompare(b.year);
			// },
		},
		{
			title: t("internships.type"),
			dataIndex: "internshipEngagementType",
			key: "internshipEngagementType",
			hidden: window.innerWidth < 650,
			// sorter: (a: DiplomaStudentDTO, b: DiplomaStudentDTO) => {
			// 	if (!a.internshipEngagementType || !b.internshipEngagementType)
			// 		return -1;
			// 	return a.internshipEngagementType.localeCompare(
			// 		b.internshipEngagementType
			// 	);
			// },
			render: (_text: string | undefined, record: any) =>
				t("internships.filters." + record.internshipEngagementType),
		},
		{
			title: t("students.internshipDocumentStatus"),
			dataIndex: "internshipDocumentStatus",
			key: "internshipDocumentStatus",
			hidden: window.innerWidth < 1200,
			// sorter: (a: DiplomaStudentDTO, b: DiplomaStudentDTO) => {
			// 	if (!a.internshipDocumentStatus || !b.internshipDocumentStatus)
			// 		return -1;
			// 	return a.internshipDocumentStatus.localeCompare(
			// 		b.internshipDocumentStatus
			// 	);
			// },
			render: (_text: string | undefined, record: any) =>
				t(
					"students.internshipDocumentStatuses." +
						record.internshipDocumentStatus
				),
		},
		{
			title: t("students.actions"),
			dataIndex: "actions",
			fixed: "right" as "right",
			width: 85,
			render: (_text: string | undefined, record: any) => (
				<div>
					<Dropdown
						placement="bottomLeft"
						overlay={
							<Menu
								onClick={(e) => onMenuClick(e, record)}
								items={getActions(record)}
							/>
						}
					>
						<FontAwesomeIcon
							icon={solid("bars")}
							style={{
								cursor: "pointer",
								height: "1rem",
								padding: "6px 0px",
								marginLeft: "13px",
							}}
						/>
					</Dropdown>
				</div>
			),
		},
	].filter((item) => !item.hidden);

	const openGetErrorNotification = (_error: any) => {
		openNotification(
			t("usersText.errorTexts.failedUserGet"),
			t("usersText.errorTexts.serverFailedDescription"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const queryClient = useQueryClient();
	const { data, isLoading: loading } = useQuery(
		["getDiplomaStudents", query.filters],
		() => {
			return getDiplomaStudentsV2(query.filters);
		},
		{
			onError: openGetErrorNotification,
		}
	);

	const onChange = (event: any) => {
		setDocumentType(event);
	};

	const onDepartmentChange = (event: any) => {
		setDefaultDepartment(event);
	};

	const handleModalClose = () => {
		setIsDocumentModalVisible(false);
		setDocumentType("PDF");
	};

	const handleModalFunction = useCallback(() => {
		if (visibleDocument) downloadInternshipDocument(visibleDocument);
	}, [visibleDocument]);

	const { data: currentUser } = useQuery([getSpecificProfessor], () => {
		return getProfessorById(getUserId());
	});

	const { data: departments } = useQuery(
		[getFacultiesDepartments, currentUser],
		() => {
			if (currentUser) {
				return getDepartmentsForFaculties([currentUser?.faculty?.id!]);
			}
		}
	);

	const handleBulkApprove = () => {
		setSpinning(true);

		approveAllDiplomaDocuments().then(async () => {
			openNotification(
				t("students.bulkApproveInternshipDocuments"),
				t("students.successBulkApproveInternshipDocumentsMessage"),
				NOTIFICATION_TYPES.SUCCESS
			);
			await queryClient.invalidateQueries("getDiplomaStudents");
			setSpinning(false);
			setBulkApproveModalVisibility(false);
		});
	};

	const onUpdate = useCallback(
		(values: { [key: string]: string[] }) => {
			query.update(values, true);
		},
		[query]
	);

	const onPageUpdate = useCallback(
		(page: number, pageSize: number) => {
			query.update({
				page: page.toString(),
				pageSize: pageSize.toString(),
			});
		},
		[query]
	);

	return (
		<div className="px-4 py-10 flex flex-col">
			{(isDepartmentDirector || isDean) && (
				<div className="flex justify-end">
					<Button
						type="primary"
						onClick={() => setBulkApproveModalVisibility(true)}
					>
						{t("internships.bulkApprove")}
					</Button>
					<ConfirmationModal
						modalText={t("internships.bulkApproveMessage")}
						handleFunction={handleBulkApprove}
						modalVisibility={bulkApproveModalVisibility}
						changeModalVisibility={() => setBulkApproveModalVisibility(false)}
						title=""
						spinning={spinning}
					/>
				</div>
			)}

			<Filters
				filters={[]}
				searchFields={[{ name: "search", label: t("search.byName") }]}
				onUpdate={onUpdate}
				hasSort={false}
			/>

			<div className="flex flex-col gap-10">
				<div className="flex flex-col md:flex-row justify-between items-center">
					<Typography.Title level={3} className="my-0">
						{data?.totalCount} {t("filtering.results")}
					</Typography.Title>
					{!loading && (
						<Pagination
							defaultCurrent={data?.page}
							defaultPageSize={data?.pageSize}
							total={data?.totalCount}
							onChange={onPageUpdate}
							locale={{ items_per_page: t("pagination") }}
						/>
					)}
				</div>

				<Table
					locale={{ emptyText: t("tableText.emptyTable") }}
					columns={columns}
					dataSource={data?.data ?? []}
					pagination={false}
					scroll={{ x: "calc(700px + 50%)", y: 420 }}
					rowKey={(record) => record.id!}
				/>
			</div>

			<SelectDocumentTypeModal
				visible={isDocumentModalVisible}
				title={t("students.downloadInternshipDocument")}
				form={form}
				defaultValue={documentType}
				departments={departments!}
				defaultDepartmentValue={defaultDepartment}
				onDepartmentChange={onDepartmentChange}
				onOk={handleModalFunction}
				onCancel={handleModalClose}
				onClose={handleModalClose}
				onSave={handleModalFunction}
				onChange={onChange}
			/>
		</div>
	);
};
