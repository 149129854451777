import { SkillEndorsementApi } from "../Api";
import { getAuthConfiguration } from "./configuration";

const AuthSkillEndorsementApiFactory = () =>
	new SkillEndorsementApi(getAuthConfiguration());

export const addSkillEndorsement = (id: string) => {
	return AuthSkillEndorsementApiFactory().apiSkillEndorsementAddPost({
		skillEndorsementAddDTO: { userSkillId: id },
	});
};

export const deleteSkillEndorsement = async (id: string) => {
	await AuthSkillEndorsementApiFactory().apiSkillEndorsementDeleteIdDelete({
		id,
	});
};
