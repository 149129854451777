import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { EmailPriority, EmailSendStrategy, Role } from "../../../../Api";
import { useCallback } from "react";
import { FormInstance } from "antd";
import { AddEmailFormModel } from "../models/AddEmailFormModel";

const getDefaultValues = (): Partial<AddEmailFormModel> => ({
	emailSubject: "",
	priority: EmailPriority.Normal,
	body: "",
	sendStrategy: EmailSendStrategy.RoleBased,
});

export const useInitPublishEmailForm = (
	form: FormInstance<AddEmailFormModel>
) => {
	const defaultValues = getDefaultValues();
	const { t } = useTranslation();

	let schema = yup.object().shape({
		recipients: yup.array(yup.string()).when("sendStrategy", {
			is: EmailSendStrategy.MultiUser,
			then: () =>
				yup
					.array(yup.string())
					.min(1, t("emails.publishEmailForm.requiredRecipients"))
					.required(t("emails.publishEmailForm.requiredRecipients")),
		}),
		recipientRole: yup.string().when("sendStrategy", {
			is: EmailSendStrategy.RoleBased,
			then: () =>
				yup
					.string()
					.oneOf<Role>([Role.Professor, Role.Student, Role.Company])
					.required(t("emails.publishEmailForm.requiredRecipientRole")),
		}),
		sendStrategy: yup
			.string()
			.oneOf<EmailSendStrategy>([
				EmailSendStrategy.RoleBased,
				EmailSendStrategy.MultiUser,
			])
			.required(),
		body: yup
			.string()
			.trim()
			.required(t("emails.publishEmailForm.requiredBody"))
			.max(60000, t("emails.publishEmailForm.bodyLengthError")),
		newsletterChannel: yup
			.string()
			.required(t("emails.publishEmailForm.requiredNewsletterChannel")),
		priority: yup
			.string()
			.oneOf<EmailPriority>([
				EmailPriority.Low,
				EmailPriority.Normal,
				EmailPriority.High,
			])
			.required(t("emails.publishEmailForm.requiredPriority")),
		emailSubject: yup
			.string()
			.trim()
			.required(t("emails.publishEmailForm.requiredEmailSubject"))
			.max(250, t("emails.publishEmailForm.subjectLengthError")),
	});

	const validator = useCallback(
		async ({ field }: any, _value: any) => {
			await schema.validateSyncAt(field, form.getFieldsValue());
		},
		[schema, form]
	);

	const yupSync = {
		validator,
	};

	return { defaultValues, schema, yupSync };
};
