/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface AcademicUserUpdateDTO
 */
export interface AcademicUserUpdateDTO {
	/**
	 *
	 * @type {string}
	 * @memberof AcademicUserUpdateDTO
	 */
	description?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof AcademicUserUpdateDTO
	 */
	phone?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof AcademicUserUpdateDTO
	 */
	personalEmail?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof AcademicUserUpdateDTO
	 */
	cvUrl?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof AcademicUserUpdateDTO
	 */
	linkedInUrl?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof AcademicUserUpdateDTO
	 */
	gitUrl?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof AcademicUserUpdateDTO
	 */
	siteUrl?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof AcademicUserUpdateDTO
	 */
	researchField?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof AcademicUserUpdateDTO
	 */
	lowResImage?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof AcademicUserUpdateDTO
	 */
	fullResImage?: string | null;
	/**
	 *
	 * @type {Array<string>}
	 * @memberof AcademicUserUpdateDTO
	 */
	skills?: Array<string> | null;
	/**
	 *
	 * @type {Array<string>}
	 * @memberof AcademicUserUpdateDTO
	 */
	areasOfInterest?: Array<string> | null;
	/**
	 *
	 * @type {string}
	 * @memberof AcademicUserUpdateDTO
	 */
	departmentId?: string;
	/**
	 *
	 * @type {string}
	 * @memberof AcademicUserUpdateDTO
	 */
	group?: string | null;
}

export function AcademicUserUpdateDTOFromJSON(
	json: any
): AcademicUserUpdateDTO {
	return AcademicUserUpdateDTOFromJSONTyped(json, false);
}

export function AcademicUserUpdateDTOFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): AcademicUserUpdateDTO {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		description: !exists(json, "description") ? undefined : json["description"],
		phone: !exists(json, "phone") ? undefined : json["phone"],
		personalEmail: !exists(json, "personalEmail")
			? undefined
			: json["personalEmail"],
		cvUrl: !exists(json, "cvUrl") ? undefined : json["cvUrl"],
		linkedInUrl: !exists(json, "linkedInUrl") ? undefined : json["linkedInUrl"],
		gitUrl: !exists(json, "gitUrl") ? undefined : json["gitUrl"],
		siteUrl: !exists(json, "siteUrl") ? undefined : json["siteUrl"],
		researchField: !exists(json, "researchField")
			? undefined
			: json["researchField"],
		lowResImage: !exists(json, "lowResImage") ? undefined : json["lowResImage"],
		fullResImage: !exists(json, "fullResImage")
			? undefined
			: json["fullResImage"],
		skills: !exists(json, "skills") ? undefined : json["skills"],
		areasOfInterest: !exists(json, "areasOfInterest")
			? undefined
			: json["areasOfInterest"],
		departmentId: !exists(json, "departmentId")
			? undefined
			: json["departmentId"],
		group: !exists(json, "group") ? undefined : json["group"],
	};
}

export function AcademicUserUpdateDTOToJSON(
	value?: AcademicUserUpdateDTO | null
): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		description: value.description,
		phone: value.phone,
		personalEmail: value.personalEmail,
		cvUrl: value.cvUrl,
		linkedInUrl: value.linkedInUrl,
		gitUrl: value.gitUrl,
		siteUrl: value.siteUrl,
		researchField: value.researchField,
		lowResImage: value.lowResImage,
		fullResImage: value.fullResImage,
		skills: value.skills,
		areasOfInterest: value.areasOfInterest,
		departmentId: value.departmentId,
		group: value.group,
	};
}
