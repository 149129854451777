/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
	Filter,
	FilterFromJSON,
	FilterToJSON,
	InternType,
	InternTypeFromJSON,
	InternTypeToJSON,
	PoliJobsCandidateDTOPagedResponse,
	PoliJobsCandidateDTOPagedResponseFromJSON,
	PoliJobsCandidateDTOPagedResponseToJSON,
	PoliJobsProposalEnrollmentDTOPagedResponse,
	PoliJobsProposalEnrollmentDTOPagedResponseFromJSON,
	PoliJobsProposalEnrollmentDTOPagedResponseToJSON,
	PoliJobsProposalEnrollmentStatus,
	PoliJobsProposalEnrollmentStatusFromJSON,
	PoliJobsProposalEnrollmentStatusToJSON,
	RequestResponse,
	RequestResponseFromJSON,
	RequestResponseToJSON,
} from "../models";

export interface ApiPoliJobsProposalEnrollmentAcceptApplicationEnrollmentIdPutRequest {
	enrollmentId: string;
}

export interface ApiPoliJobsProposalEnrollmentApplyPoliJobsProposalIdPostRequest {
	poliJobsProposalId: string;
}

export interface ApiPoliJobsProposalEnrollmentCanApplyPoliJobsProposalIdGetRequest {
	poliJobsProposalId: string;
}

export interface ApiPoliJobsProposalEnrollmentEditNotesEnrollmentIdPutRequest {
	enrollmentId: string;
	notes?: string;
}

export interface ApiPoliJobsProposalEnrollmentGetAllApplicationsGetRequest {
	status?: Array<PoliJobsProposalEnrollmentStatus>;
	page?: number;
	pageSize?: number;
}

export interface ApiPoliJobsProposalEnrollmentGetApplicantsPoliJobsProposalIdGetRequest {
	poliJobsProposalId: string;
	internType?: InternType;
	page?: number;
	pageSize?: number;
}

export interface ApiPoliJobsProposalEnrollmentRejectApplicationEnrollmentIdPutRequest {
	enrollmentId: string;
}

export interface ApiPoliJobsProposalEnrollmentRejectCVEnrollmentIdPutRequest {
	enrollmentId: string;
	message?: string;
}

export interface ApiPoliJobsProposalEnrollmentWithdrawApplicationPoliJobsProposalIdPutRequest {
	poliJobsProposalId: string;
}

/**
 *
 */
export class PoliJobsProposalEnrollmentApi extends runtime.BaseAPI {
	/**
	 */
	async apiPoliJobsProposalEnrollmentAcceptApplicationEnrollmentIdPutRaw(
		requestParameters: ApiPoliJobsProposalEnrollmentAcceptApplicationEnrollmentIdPutRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<void>> {
		if (
			requestParameters.enrollmentId === null ||
			requestParameters.enrollmentId === undefined
		) {
			throw new runtime.RequiredError(
				"enrollmentId",
				"Required parameter requestParameters.enrollmentId was null or undefined when calling apiPoliJobsProposalEnrollmentAcceptApplicationEnrollmentIdPut."
			);
		}

		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/PoliJobsProposalEnrollment/AcceptApplication/{enrollmentId}`.replace(
					`{${"enrollmentId"}}`,
					encodeURIComponent(String(requestParameters.enrollmentId))
				),
				method: "PUT",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.VoidApiResponse(response);
	}

	/**
	 */
	async apiPoliJobsProposalEnrollmentAcceptApplicationEnrollmentIdPut(
		requestParameters: ApiPoliJobsProposalEnrollmentAcceptApplicationEnrollmentIdPutRequest,
		initOverrides?: RequestInit
	): Promise<void> {
		await this.apiPoliJobsProposalEnrollmentAcceptApplicationEnrollmentIdPutRaw(
			requestParameters,
			initOverrides
		);
	}

	/**
	 */
	async apiPoliJobsProposalEnrollmentApplyPoliJobsProposalIdPostRaw(
		requestParameters: ApiPoliJobsProposalEnrollmentApplyPoliJobsProposalIdPostRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<RequestResponse>> {
		if (
			requestParameters.poliJobsProposalId === null ||
			requestParameters.poliJobsProposalId === undefined
		) {
			throw new runtime.RequiredError(
				"poliJobsProposalId",
				"Required parameter requestParameters.poliJobsProposalId was null or undefined when calling apiPoliJobsProposalEnrollmentApplyPoliJobsProposalIdPost."
			);
		}

		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/PoliJobsProposalEnrollment/Apply/{poliJobsProposalId}`.replace(
					`{${"poliJobsProposalId"}}`,
					encodeURIComponent(String(requestParameters.poliJobsProposalId))
				),
				method: "POST",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			RequestResponseFromJSON(jsonValue)
		);
	}

	/**
	 */
	async apiPoliJobsProposalEnrollmentApplyPoliJobsProposalIdPost(
		requestParameters: ApiPoliJobsProposalEnrollmentApplyPoliJobsProposalIdPostRequest,
		initOverrides?: RequestInit
	): Promise<RequestResponse> {
		const response =
			await this.apiPoliJobsProposalEnrollmentApplyPoliJobsProposalIdPostRaw(
				requestParameters,
				initOverrides
			);
		return await response.value();
	}

	/**
	 */
	async apiPoliJobsProposalEnrollmentCanApplyPoliJobsProposalIdGetRaw(
		requestParameters: ApiPoliJobsProposalEnrollmentCanApplyPoliJobsProposalIdGetRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<RequestResponse>> {
		if (
			requestParameters.poliJobsProposalId === null ||
			requestParameters.poliJobsProposalId === undefined
		) {
			throw new runtime.RequiredError(
				"poliJobsProposalId",
				"Required parameter requestParameters.poliJobsProposalId was null or undefined when calling apiPoliJobsProposalEnrollmentCanApplyPoliJobsProposalIdGet."
			);
		}

		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/PoliJobsProposalEnrollment/CanApply/{poliJobsProposalId}`.replace(
					`{${"poliJobsProposalId"}}`,
					encodeURIComponent(String(requestParameters.poliJobsProposalId))
				),
				method: "GET",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			RequestResponseFromJSON(jsonValue)
		);
	}

	/**
	 */
	async apiPoliJobsProposalEnrollmentCanApplyPoliJobsProposalIdGet(
		requestParameters: ApiPoliJobsProposalEnrollmentCanApplyPoliJobsProposalIdGetRequest,
		initOverrides?: RequestInit
	): Promise<RequestResponse> {
		const response =
			await this.apiPoliJobsProposalEnrollmentCanApplyPoliJobsProposalIdGetRaw(
				requestParameters,
				initOverrides
			);
		return await response.value();
	}

	/**
	 */
	async apiPoliJobsProposalEnrollmentEditNotesEnrollmentIdPutRaw(
		requestParameters: ApiPoliJobsProposalEnrollmentEditNotesEnrollmentIdPutRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<void>> {
		if (
			requestParameters.enrollmentId === null ||
			requestParameters.enrollmentId === undefined
		) {
			throw new runtime.RequiredError(
				"enrollmentId",
				"Required parameter requestParameters.enrollmentId was null or undefined when calling apiPoliJobsProposalEnrollmentEditNotesEnrollmentIdPut."
			);
		}

		const queryParameters: any = {};

		if (requestParameters.notes !== undefined) {
			queryParameters["notes"] = requestParameters.notes;
		}

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/PoliJobsProposalEnrollment/EditNotes/{enrollmentId}`.replace(
					`{${"enrollmentId"}}`,
					encodeURIComponent(String(requestParameters.enrollmentId))
				),
				method: "PUT",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.VoidApiResponse(response);
	}

	/**
	 */
	async apiPoliJobsProposalEnrollmentEditNotesEnrollmentIdPut(
		requestParameters: ApiPoliJobsProposalEnrollmentEditNotesEnrollmentIdPutRequest,
		initOverrides?: RequestInit
	): Promise<void> {
		await this.apiPoliJobsProposalEnrollmentEditNotesEnrollmentIdPutRaw(
			requestParameters,
			initOverrides
		);
	}

	/**
	 */
	async apiPoliJobsProposalEnrollmentGetAllApplicationsCountGetRaw(
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<number>> {
		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/PoliJobsProposalEnrollment/GetAllApplicationsCount`,
				method: "GET",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.TextApiResponse(response) as any;
	}

	/**
	 */
	async apiPoliJobsProposalEnrollmentGetAllApplicationsCountGet(
		initOverrides?: RequestInit
	): Promise<number> {
		const response =
			await this.apiPoliJobsProposalEnrollmentGetAllApplicationsCountGetRaw(
				initOverrides
			);
		return await response.value();
	}

	/**
	 */
	async apiPoliJobsProposalEnrollmentGetAllApplicationsGetRaw(
		requestParameters: ApiPoliJobsProposalEnrollmentGetAllApplicationsGetRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<PoliJobsProposalEnrollmentDTOPagedResponse>> {
		const queryParameters: any = {};

		if (requestParameters.status) {
			queryParameters["Status"] = requestParameters.status;
		}

		if (requestParameters.page !== undefined) {
			queryParameters["Page"] = requestParameters.page;
		}

		if (requestParameters.pageSize !== undefined) {
			queryParameters["PageSize"] = requestParameters.pageSize;
		}

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/PoliJobsProposalEnrollment/GetAllApplications`,
				method: "GET",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			PoliJobsProposalEnrollmentDTOPagedResponseFromJSON(jsonValue)
		);
	}

	/**
	 */
	async apiPoliJobsProposalEnrollmentGetAllApplicationsGet(
		requestParameters: ApiPoliJobsProposalEnrollmentGetAllApplicationsGetRequest = {},
		initOverrides?: RequestInit
	): Promise<PoliJobsProposalEnrollmentDTOPagedResponse> {
		const response =
			await this.apiPoliJobsProposalEnrollmentGetAllApplicationsGetRaw(
				requestParameters,
				initOverrides
			);
		return await response.value();
	}

	/**
	 */
	async apiPoliJobsProposalEnrollmentGetApplicantsPoliJobsProposalIdGetRaw(
		requestParameters: ApiPoliJobsProposalEnrollmentGetApplicantsPoliJobsProposalIdGetRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<PoliJobsCandidateDTOPagedResponse>> {
		if (
			requestParameters.poliJobsProposalId === null ||
			requestParameters.poliJobsProposalId === undefined
		) {
			throw new runtime.RequiredError(
				"poliJobsProposalId",
				"Required parameter requestParameters.poliJobsProposalId was null or undefined when calling apiPoliJobsProposalEnrollmentGetApplicantsPoliJobsProposalIdGet."
			);
		}

		const queryParameters: any = {};

		if (requestParameters.internType !== undefined) {
			queryParameters["InternType"] = requestParameters.internType;
		}

		if (requestParameters.page !== undefined) {
			queryParameters["Page"] = requestParameters.page;
		}

		if (requestParameters.pageSize !== undefined) {
			queryParameters["PageSize"] = requestParameters.pageSize;
		}

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/PoliJobsProposalEnrollment/GetApplicants/{poliJobsProposalId}`.replace(
					`{${"poliJobsProposalId"}}`,
					encodeURIComponent(String(requestParameters.poliJobsProposalId))
				),
				method: "GET",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			PoliJobsCandidateDTOPagedResponseFromJSON(jsonValue)
		);
	}

	/**
	 */
	async apiPoliJobsProposalEnrollmentGetApplicantsPoliJobsProposalIdGet(
		requestParameters: ApiPoliJobsProposalEnrollmentGetApplicantsPoliJobsProposalIdGetRequest,
		initOverrides?: RequestInit
	): Promise<PoliJobsCandidateDTOPagedResponse> {
		const response =
			await this.apiPoliJobsProposalEnrollmentGetApplicantsPoliJobsProposalIdGetRaw(
				requestParameters,
				initOverrides
			);
		return await response.value();
	}

	/**
	 */
	async apiPoliJobsProposalEnrollmentGetApplicationsFiltersGetRaw(
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<Array<Filter>>> {
		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/PoliJobsProposalEnrollment/GetApplicationsFilters`,
				method: "GET",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			jsonValue.map(FilterFromJSON)
		);
	}

	/**
	 */
	async apiPoliJobsProposalEnrollmentGetApplicationsFiltersGet(
		initOverrides?: RequestInit
	): Promise<Array<Filter>> {
		const response =
			await this.apiPoliJobsProposalEnrollmentGetApplicationsFiltersGetRaw(
				initOverrides
			);
		return await response.value();
	}

	/**
	 */
	async apiPoliJobsProposalEnrollmentRejectApplicationEnrollmentIdPutRaw(
		requestParameters: ApiPoliJobsProposalEnrollmentRejectApplicationEnrollmentIdPutRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<void>> {
		if (
			requestParameters.enrollmentId === null ||
			requestParameters.enrollmentId === undefined
		) {
			throw new runtime.RequiredError(
				"enrollmentId",
				"Required parameter requestParameters.enrollmentId was null or undefined when calling apiPoliJobsProposalEnrollmentRejectApplicationEnrollmentIdPut."
			);
		}

		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/PoliJobsProposalEnrollment/RejectApplication/{enrollmentId}`.replace(
					`{${"enrollmentId"}}`,
					encodeURIComponent(String(requestParameters.enrollmentId))
				),
				method: "PUT",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.VoidApiResponse(response);
	}

	/**
	 */
	async apiPoliJobsProposalEnrollmentRejectApplicationEnrollmentIdPut(
		requestParameters: ApiPoliJobsProposalEnrollmentRejectApplicationEnrollmentIdPutRequest,
		initOverrides?: RequestInit
	): Promise<void> {
		await this.apiPoliJobsProposalEnrollmentRejectApplicationEnrollmentIdPutRaw(
			requestParameters,
			initOverrides
		);
	}

	/**
	 */
	async apiPoliJobsProposalEnrollmentRejectCVEnrollmentIdPutRaw(
		requestParameters: ApiPoliJobsProposalEnrollmentRejectCVEnrollmentIdPutRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<void>> {
		if (
			requestParameters.enrollmentId === null ||
			requestParameters.enrollmentId === undefined
		) {
			throw new runtime.RequiredError(
				"enrollmentId",
				"Required parameter requestParameters.enrollmentId was null or undefined when calling apiPoliJobsProposalEnrollmentRejectCVEnrollmentIdPut."
			);
		}

		const queryParameters: any = {};

		if (requestParameters.message !== undefined) {
			queryParameters["message"] = requestParameters.message;
		}

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/PoliJobsProposalEnrollment/RejectCV/{enrollmentId}`.replace(
					`{${"enrollmentId"}}`,
					encodeURIComponent(String(requestParameters.enrollmentId))
				),
				method: "PUT",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.VoidApiResponse(response);
	}

	/**
	 */
	async apiPoliJobsProposalEnrollmentRejectCVEnrollmentIdPut(
		requestParameters: ApiPoliJobsProposalEnrollmentRejectCVEnrollmentIdPutRequest,
		initOverrides?: RequestInit
	): Promise<void> {
		await this.apiPoliJobsProposalEnrollmentRejectCVEnrollmentIdPutRaw(
			requestParameters,
			initOverrides
		);
	}

	/**
	 */
	async apiPoliJobsProposalEnrollmentWithdrawApplicationPoliJobsProposalIdPutRaw(
		requestParameters: ApiPoliJobsProposalEnrollmentWithdrawApplicationPoliJobsProposalIdPutRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<void>> {
		if (
			requestParameters.poliJobsProposalId === null ||
			requestParameters.poliJobsProposalId === undefined
		) {
			throw new runtime.RequiredError(
				"poliJobsProposalId",
				"Required parameter requestParameters.poliJobsProposalId was null or undefined when calling apiPoliJobsProposalEnrollmentWithdrawApplicationPoliJobsProposalIdPut."
			);
		}

		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/PoliJobsProposalEnrollment/WithdrawApplication/{poliJobsProposalId}`.replace(
					`{${"poliJobsProposalId"}}`,
					encodeURIComponent(String(requestParameters.poliJobsProposalId))
				),
				method: "PUT",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.VoidApiResponse(response);
	}

	/**
	 */
	async apiPoliJobsProposalEnrollmentWithdrawApplicationPoliJobsProposalIdPut(
		requestParameters: ApiPoliJobsProposalEnrollmentWithdrawApplicationPoliJobsProposalIdPutRequest,
		initOverrides?: RequestInit
	): Promise<void> {
		await this.apiPoliJobsProposalEnrollmentWithdrawApplicationPoliJobsProposalIdPutRaw(
			requestParameters,
			initOverrides
		);
	}
}
