/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
	AcademicUser,
	AcademicUserFromJSON,
	AcademicUserFromJSONTyped,
	AcademicUserToJSON,
} from "./AcademicUser";
import {
	UserSkill,
	UserSkillFromJSON,
	UserSkillFromJSONTyped,
	UserSkillToJSON,
} from "./UserSkill";

/**
 *
 * @export
 * @interface SkillEndorsement
 */
export interface SkillEndorsement {
	/**
	 *
	 * @type {string}
	 * @memberof SkillEndorsement
	 */
	id?: string;
	/**
	 *
	 * @type {Date}
	 * @memberof SkillEndorsement
	 */
	createdAt?: Date;
	/**
	 *
	 * @type {Date}
	 * @memberof SkillEndorsement
	 */
	updatedAt?: Date;
	/**
	 *
	 * @type {string}
	 * @memberof SkillEndorsement
	 */
	userSkillId?: string;
	/**
	 *
	 * @type {string}
	 * @memberof SkillEndorsement
	 */
	professorId?: string;
	/**
	 *
	 * @type {UserSkill}
	 * @memberof SkillEndorsement
	 */
	userSkill?: UserSkill;
	/**
	 *
	 * @type {AcademicUser}
	 * @memberof SkillEndorsement
	 */
	user?: AcademicUser;
}

export function SkillEndorsementFromJSON(json: any): SkillEndorsement {
	return SkillEndorsementFromJSONTyped(json, false);
}

export function SkillEndorsementFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): SkillEndorsement {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		id: !exists(json, "id") ? undefined : json["id"],
		createdAt: !exists(json, "createdAt")
			? undefined
			: new Date(json["createdAt"]),
		updatedAt: !exists(json, "updatedAt")
			? undefined
			: new Date(json["updatedAt"]),
		userSkillId: !exists(json, "userSkillId") ? undefined : json["userSkillId"],
		professorId: !exists(json, "professorId") ? undefined : json["professorId"],
		userSkill: !exists(json, "userSkill")
			? undefined
			: UserSkillFromJSON(json["userSkill"]),
		user: !exists(json, "user")
			? undefined
			: AcademicUserFromJSON(json["user"]),
	};
}

export function SkillEndorsementToJSON(value?: SkillEndorsement | null): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		id: value.id,
		createdAt:
			value.createdAt === undefined ? undefined : value.createdAt.toISOString(),
		updatedAt:
			value.updatedAt === undefined ? undefined : value.updatedAt.toISOString(),
		userSkillId: value.userSkillId,
		professorId: value.professorId,
		userSkill: UserSkillToJSON(value.userSkill),
		user: AcademicUserToJSON(value.user),
	};
}
