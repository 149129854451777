/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
	AcademicUser,
	AcademicUserFromJSON,
	AcademicUserFromJSONTyped,
	AcademicUserToJSON,
} from "./AcademicUser";
import {
	StudentYearEnum,
	StudentYearEnumFromJSON,
	StudentYearEnumFromJSONTyped,
	StudentYearEnumToJSON,
} from "./StudentYearEnum";
import {
	StudyProgram,
	StudyProgramFromJSON,
	StudyProgramFromJSONTyped,
	StudyProgramToJSON,
} from "./StudyProgram";

/**
 *
 * @export
 * @interface SupervisorsStudyProgram
 */
export interface SupervisorsStudyProgram {
	/**
	 *
	 * @type {string}
	 * @memberof SupervisorsStudyProgram
	 */
	studyProgramId?: string;
	/**
	 *
	 * @type {StudyProgram}
	 * @memberof SupervisorsStudyProgram
	 */
	studyProgram?: StudyProgram;
	/**
	 *
	 * @type {string}
	 * @memberof SupervisorsStudyProgram
	 */
	supervisorId?: string;
	/**
	 *
	 * @type {AcademicUser}
	 * @memberof SupervisorsStudyProgram
	 */
	supervisor?: AcademicUser;
	/**
	 *
	 * @type {StudentYearEnum}
	 * @memberof SupervisorsStudyProgram
	 */
	year?: StudentYearEnum;
}

export function SupervisorsStudyProgramFromJSON(
	json: any
): SupervisorsStudyProgram {
	return SupervisorsStudyProgramFromJSONTyped(json, false);
}

export function SupervisorsStudyProgramFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): SupervisorsStudyProgram {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		studyProgramId: !exists(json, "studyProgramId")
			? undefined
			: json["studyProgramId"],
		studyProgram: !exists(json, "studyProgram")
			? undefined
			: StudyProgramFromJSON(json["studyProgram"]),
		supervisorId: !exists(json, "supervisorId")
			? undefined
			: json["supervisorId"],
		supervisor: !exists(json, "supervisor")
			? undefined
			: AcademicUserFromJSON(json["supervisor"]),
		year: !exists(json, "year")
			? undefined
			: StudentYearEnumFromJSON(json["year"]),
	};
}

export function SupervisorsStudyProgramToJSON(
	value?: SupervisorsStudyProgram | null
): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		studyProgramId: value.studyProgramId,
		studyProgram: StudyProgramToJSON(value.studyProgram),
		supervisorId: value.supervisorId,
		supervisor: AcademicUserToJSON(value.supervisor),
		year: StudentYearEnumToJSON(value.year),
	};
}
