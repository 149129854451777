/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum DocumentTypeEnum {
	UniversityAccord = "UniversityAccord",
	PracticeConvention = "PracticeConvention",
	PracticeProtocol = "PracticeProtocol",
}

export function DocumentTypeEnumFromJSON(json: any): DocumentTypeEnum {
	return DocumentTypeEnumFromJSONTyped(json, false);
}

export function DocumentTypeEnumFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): DocumentTypeEnum {
	return json as DocumentTypeEnum;
}

export function DocumentTypeEnumToJSON(value?: DocumentTypeEnum | null): any {
	return value as any;
}
