import { Button } from "antd";
import { theme } from "../theme";
import { useTranslation } from "react-i18next";
import { PropsWithChildren } from "react";
import { CustomModalFooterProps } from "../PropsLists/CustomModalFooterProps";

const CustomModalFooter = (
	props: PropsWithChildren<CustomModalFooterProps>
) => {
	const { t } = useTranslation();

	return (
		<>
			<Button
				key="back"
				onClick={props.handleClose}
				disabled={props.spinning}
				style={{
					background: theme.green,
					border: theme.green,
					color: theme.white,
					borderRadius: "10px",
				}}
			>
				{props.cancelButtonName ? props.cancelButtonName : t("account.cancel")}
			</Button>
			<Button
				key="submit"
				type="primary"
				loading={props.spinning}
				disabled={props.spinning}
				onClick={props.handleSave}
				style={{
					background: theme.secondColor,
					border: theme.secondColor,
					borderRadius: "10px",
				}}
			>
				{props.confirmButtonName}
			</Button>
		</>
	);
};

export default CustomModalFooter;
