/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum PracticeCertificateQualifier {
	Fb = "FB",
	B = "B",
	S = "S",
	Ns = "NS",
}

export function PracticeCertificateQualifierFromJSON(
	json: any
): PracticeCertificateQualifier {
	return PracticeCertificateQualifierFromJSONTyped(json, false);
}

export function PracticeCertificateQualifierFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): PracticeCertificateQualifier {
	return json as PracticeCertificateQualifier;
}

export function PracticeCertificateQualifierToJSON(
	value?: PracticeCertificateQualifier | null
): any {
	return value as any;
}
