/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
	ApplicationTypesCountDTO,
	ApplicationTypesCountDTOFromJSON,
	ApplicationTypesCountDTOToJSON,
	CompanyInternshipAcceptDTO,
	CompanyInternshipAcceptDTOFromJSON,
	CompanyInternshipAcceptDTOToJSON,
	CompanyInternshipState,
	CompanyInternshipStateFromJSON,
	CompanyInternshipStateToJSON,
	DiplomaStudentDTOPagedResponse,
	DiplomaStudentDTOPagedResponseFromJSON,
	DiplomaStudentDTOPagedResponseToJSON,
	EnrollStudentDTO,
	EnrollStudentDTOFromJSON,
	EnrollStudentDTOToJSON,
	EnrollmentDTOPagedResponse,
	EnrollmentDTOPagedResponseFromJSON,
	EnrollmentDTOPagedResponseToJSON,
	EnrollmentStatus,
	EnrollmentStatusFromJSON,
	EnrollmentStatusToJSON,
	Filter,
	FilterFromJSON,
	FilterToJSON,
	InternType,
	InternTypeFromJSON,
	InternTypeToJSON,
	InternsForEmployerDTOPagedResponse,
	InternsForEmployerDTOPagedResponseFromJSON,
	InternsForEmployerDTOPagedResponseToJSON,
	InternshipAcceptDTO,
	InternshipAcceptDTOFromJSON,
	InternshipAcceptDTOToJSON,
	InternshipDTOPagedResponse,
	InternshipDTOPagedResponseFromJSON,
	InternshipDTOPagedResponseToJSON,
	InternshipDocumentState,
	InternshipDocumentStateFromJSON,
	InternshipDocumentStateToJSON,
	InternshipEngagementType,
	InternshipEngagementTypeFromJSON,
	InternshipEngagementTypeToJSON,
	InternshipEnrollmentDTO,
	InternshipEnrollmentDTOFromJSON,
	InternshipEnrollmentDTOToJSON,
	InternshipEnrollmentDTOPagedResponse,
	InternshipEnrollmentDTOPagedResponseFromJSON,
	InternshipEnrollmentDTOPagedResponseToJSON,
	InternshipParticipationIntentDTO,
	InternshipParticipationIntentDTOFromJSON,
	InternshipParticipationIntentDTOToJSON,
	InternshipSortFieldEnum,
	InternshipSortFieldEnumFromJSON,
	InternshipSortFieldEnumToJSON,
	InternshipStatus,
	InternshipStatusFromJSON,
	InternshipStatusToJSON,
	InternshipType,
	InternshipTypeFromJSON,
	InternshipTypeToJSON,
	PracticeCertificateState,
	PracticeCertificateStateFromJSON,
	PracticeCertificateStateToJSON,
	PracticeNotebookState,
	PracticeNotebookStateFromJSON,
	PracticeNotebookStateToJSON,
	PracticeStudentsDTOPagedResponse,
	PracticeStudentsDTOPagedResponseFromJSON,
	PracticeStudentsDTOPagedResponseToJSON,
	RequestResponse,
	RequestResponseFromJSON,
	RequestResponseToJSON,
	SortDirectionEnum,
	SortDirectionEnumFromJSON,
	SortDirectionEnumToJSON,
	StudentTypeCountDTO,
	StudentTypeCountDTOFromJSON,
	StudentTypeCountDTOToJSON,
	StudentYearEnum,
	StudentYearEnumFromJSON,
	StudentYearEnumToJSON,
	TraineesFilterEnum,
	TraineesFilterEnumFromJSON,
	TraineesFilterEnumToJSON,
} from "../models";

export interface ApiInternshipAcceptApplicationPostRequest {
	companyInternshipAcceptDTO?: CompanyInternshipAcceptDTO;
}

export interface ApiInternshipAcceptPositionInternshipIdPostRequest {
	internshipId: string;
}

export interface ApiInternshipApplyInternshipIdPostRequest {
	internshipId: string;
}

export interface ApiInternshipCanApplyInternshipIdGetRequest {
	internshipId: string;
}

export interface ApiInternshipCancelInternshipIdPostRequest {
	internshipId: string;
}

export interface ApiInternshipDeleteEnrollmentIdDeleteRequest {
	id: string;
}

export interface ApiInternshipEnrollStudentPostRequest {
	enrollStudentDTO?: EnrollStudentDTO;
}

export interface ApiInternshipFinalAcceptPostRequest {
	internshipAcceptDTO?: InternshipAcceptDTO;
}

export interface ApiInternshipGetAllApplicationsGetRequest {
	companyId?: string;
	professorId?: string;
	skills?: Array<string>;
	categories?: Array<string>;
	types?: Array<InternshipEngagementType>;
	locations?: Array<string>;
	statuses?: Array<InternshipStatus>;
	states?: Array<CompanyInternshipState>;
	applicabilityTypes?: Array<boolean>;
	validationStates?: Array<boolean>;
	universityYears?: Array<number>;
	searchTerm?: string;
	sortField?: InternshipSortFieldEnum;
	sortDirection?: SortDirectionEnum;
	facultyCoordinatorId?: string;
	enableSearchByFacultyCoordinator?: boolean;
	studentStatus?: Array<EnrollmentStatus>;
	recruiterStatus?: Array<EnrollmentStatus>;
	page?: number;
	pageSize?: number;
}

export interface ApiInternshipGetAllEnrollmentsAdminGetRequest {
	studentNamePattern?: string;
	employerNamePattern?: string;
	internshipNamePattern?: string;
	page?: number;
	pageSize?: number;
}

export interface ApiInternshipGetDiplomaStudentsGetRequest {
	namePattern?: string;
	studyProgram?: Array<string>;
	engagementType?: Array<InternshipEngagementType>;
	years?: Array<StudentYearEnum>;
	faculties?: Array<string>;
	internshipDocumentStates?: Array<InternshipDocumentState>;
	page?: number;
	pageSize?: number;
}

export interface ApiInternshipGetEnrollmentByIdIdGetRequest {
	id: string;
}

export interface ApiInternshipGetInternsFiltersGetRequest {
	filtersList?: Array<TraineesFilterEnum>;
	language?: string;
	type?: InternType;
	namePattern?: string;
	internships?: Array<string>;
	studyProgram?: Array<string>;
	engagementType?: Array<InternshipEngagementType>;
	years?: Array<StudentYearEnum>;
	faculties?: Array<string>;
	studentStatus?: Array<EnrollmentStatus>;
	recruiterStatus?: Array<EnrollmentStatus>;
	practiceNotebookStatuses?: Array<PracticeNotebookState>;
	practiceCertificateStatuses?: Array<PracticeCertificateState>;
	viewStatuses?: Array<string>;
	page?: number;
	pageSize?: number;
}

export interface ApiInternshipGetInternsForEmployerGetRequest {
	namePattern?: string;
	internships?: Array<string>;
	studyProgram?: Array<string>;
	engagementType?: Array<InternshipEngagementType>;
	years?: Array<StudentYearEnum>;
	faculties?: Array<string>;
	studentStatus?: Array<EnrollmentStatus>;
	recruiterStatus?: Array<EnrollmentStatus>;
	practiceNotebookStatuses?: Array<PracticeNotebookState>;
	practiceCertificateStatuses?: Array<PracticeCertificateState>;
	viewStatuses?: Array<string>;
	page?: number;
	pageSize?: number;
	type?: InternType;
}

export interface ApiInternshipGetInternshipsTypeEventGetRequest {
	skills?: Array<string>;
	categories?: Array<string>;
	studyProgram?: Array<string>;
	years?: Array<StudentYearEnum>;
	types?: Array<InternshipType>;
	locations?: Array<string>;
	searchTerm?: string;
	sortField?: InternshipSortFieldEnum;
	sortDirection?: SortDirectionEnum;
	page?: number;
	pageSize?: number;
}

export interface ApiInternshipGetPracticeStudentsAdminGetRequest {
	internshipNamePattern?: string;
	studentNamePattern?: string;
	employerNamePattern?: string;
	supervisorNamePattern?: string;
	studyProgram?: Array<string>;
	faculty?: Array<string>;
	department?: Array<string>;
	years?: Array<StudentYearEnum>;
	practiceNotebookStatuses?: Array<PracticeNotebookState>;
	practiceCertificateStatuses?: Array<PracticeCertificateState>;
	universityYears?: Array<number>;
	page?: number;
	pageSize?: number;
}

export interface ApiInternshipGetPracticeStudentsFiltersGetRequest {
	internshipNamePattern?: string;
	studentNamePattern?: string;
	employerNamePattern?: string;
	supervisorNamePattern?: string;
	studyProgram?: Array<string>;
	faculty?: Array<string>;
	department?: Array<string>;
	years?: Array<StudentYearEnum>;
	practiceNotebookStatuses?: Array<PracticeNotebookState>;
	practiceCertificateStatuses?: Array<PracticeCertificateState>;
	universityYears?: Array<number>;
	page?: number;
	pageSize?: number;
}

export interface ApiInternshipGetPracticeStudentsGetRequest {
	internshipNamePattern?: string;
	studentNamePattern?: string;
	employerNamePattern?: string;
	supervisorNamePattern?: string;
	studyProgram?: Array<string>;
	faculty?: Array<string>;
	department?: Array<string>;
	years?: Array<StudentYearEnum>;
	practiceNotebookStatuses?: Array<PracticeNotebookState>;
	practiceCertificateStatuses?: Array<PracticeCertificateState>;
	universityYears?: Array<number>;
	page?: number;
	pageSize?: number;
}

export interface ApiInternshipGetPreviousEnrollmentsGetRequest {
	companyId?: string;
	professorId?: string;
	skills?: Array<string>;
	categories?: Array<string>;
	types?: Array<InternshipEngagementType>;
	locations?: Array<string>;
	statuses?: Array<InternshipStatus>;
	states?: Array<CompanyInternshipState>;
	applicabilityTypes?: Array<boolean>;
	validationStates?: Array<boolean>;
	universityYears?: Array<number>;
	searchTerm?: string;
	sortField?: InternshipSortFieldEnum;
	sortDirection?: SortDirectionEnum;
	facultyCoordinatorId?: string;
	enableSearchByFacultyCoordinator?: boolean;
	studentStatus?: Array<EnrollmentStatus>;
	recruiterStatus?: Array<EnrollmentStatus>;
	page?: number;
	pageSize?: number;
}

export interface ApiInternshipRejectApplicationPostRequest {
	internshipAcceptDTO?: InternshipAcceptDTO;
}

export interface ApiInternshipResetEnrollmentStatusAdminIdPutRequest {
	id: string;
	resetStudentStatus?: boolean;
}

export interface ApiInternshipSetInternshipParticipationIntentByInternshipIdInternshipIdPostRequest {
	internshipId: string;
	internshipParticipationIntentDTO?: InternshipParticipationIntentDTO;
}

export interface ApiInternshipUpdateIsEnrollmentViewedIdPutRequest {
	id: string;
	isEnrollmentView?: boolean;
}

export interface ApiInternshipUpdatePracticeInLaboratoryIdPutRequest {
	id: string;
	laboratoryPracticeRoom?: string;
}

export interface ApiInternshipUpdateTutorIdPutRequest {
	id: string;
	body?: string;
}

export interface ApiInternshipWithdrawOfferIdDeleteRequest {
	id: string;
}

/**
 *
 */
export class InternshipApi extends runtime.BaseAPI {
	/**
	 */
	async apiInternshipAcceptApplicationPostRaw(
		requestParameters: ApiInternshipAcceptApplicationPostRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<void>> {
		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		headerParameters["Content-Type"] = "application/json";

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/Internship/AcceptApplication`,
				method: "POST",
				headers: headerParameters,
				query: queryParameters,
				body: CompanyInternshipAcceptDTOToJSON(
					requestParameters.companyInternshipAcceptDTO
				),
			},
			initOverrides
		);

		return new runtime.VoidApiResponse(response);
	}

	/**
	 */
	async apiInternshipAcceptApplicationPost(
		requestParameters: ApiInternshipAcceptApplicationPostRequest = {},
		initOverrides?: RequestInit
	): Promise<void> {
		await this.apiInternshipAcceptApplicationPostRaw(
			requestParameters,
			initOverrides
		);
	}

	/**
	 */
	async apiInternshipAcceptPositionInternshipIdPostRaw(
		requestParameters: ApiInternshipAcceptPositionInternshipIdPostRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<RequestResponse>> {
		if (
			requestParameters.internshipId === null ||
			requestParameters.internshipId === undefined
		) {
			throw new runtime.RequiredError(
				"internshipId",
				"Required parameter requestParameters.internshipId was null or undefined when calling apiInternshipAcceptPositionInternshipIdPost."
			);
		}

		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/Internship/AcceptPosition/{internshipId}`.replace(
					`{${"internshipId"}}`,
					encodeURIComponent(String(requestParameters.internshipId))
				),
				method: "POST",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			RequestResponseFromJSON(jsonValue)
		);
	}

	/**
	 */
	async apiInternshipAcceptPositionInternshipIdPost(
		requestParameters: ApiInternshipAcceptPositionInternshipIdPostRequest,
		initOverrides?: RequestInit
	): Promise<RequestResponse> {
		const response = await this.apiInternshipAcceptPositionInternshipIdPostRaw(
			requestParameters,
			initOverrides
		);
		return await response.value();
	}

	/**
	 */
	async apiInternshipApplyInternshipIdPostRaw(
		requestParameters: ApiInternshipApplyInternshipIdPostRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<RequestResponse>> {
		if (
			requestParameters.internshipId === null ||
			requestParameters.internshipId === undefined
		) {
			throw new runtime.RequiredError(
				"internshipId",
				"Required parameter requestParameters.internshipId was null or undefined when calling apiInternshipApplyInternshipIdPost."
			);
		}

		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/Internship/Apply/{internshipId}`.replace(
					`{${"internshipId"}}`,
					encodeURIComponent(String(requestParameters.internshipId))
				),
				method: "POST",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			RequestResponseFromJSON(jsonValue)
		);
	}

	/**
	 */
	async apiInternshipApplyInternshipIdPost(
		requestParameters: ApiInternshipApplyInternshipIdPostRequest,
		initOverrides?: RequestInit
	): Promise<RequestResponse> {
		const response = await this.apiInternshipApplyInternshipIdPostRaw(
			requestParameters,
			initOverrides
		);
		return await response.value();
	}

	/**
	 */
	async apiInternshipCanApplyInternshipIdGetRaw(
		requestParameters: ApiInternshipCanApplyInternshipIdGetRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<RequestResponse>> {
		if (
			requestParameters.internshipId === null ||
			requestParameters.internshipId === undefined
		) {
			throw new runtime.RequiredError(
				"internshipId",
				"Required parameter requestParameters.internshipId was null or undefined when calling apiInternshipCanApplyInternshipIdGet."
			);
		}

		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/Internship/CanApply/{internshipId}`.replace(
					`{${"internshipId"}}`,
					encodeURIComponent(String(requestParameters.internshipId))
				),
				method: "GET",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			RequestResponseFromJSON(jsonValue)
		);
	}

	/**
	 */
	async apiInternshipCanApplyInternshipIdGet(
		requestParameters: ApiInternshipCanApplyInternshipIdGetRequest,
		initOverrides?: RequestInit
	): Promise<RequestResponse> {
		const response = await this.apiInternshipCanApplyInternshipIdGetRaw(
			requestParameters,
			initOverrides
		);
		return await response.value();
	}

	/**
	 */
	async apiInternshipCancelInternshipIdPostRaw(
		requestParameters: ApiInternshipCancelInternshipIdPostRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<void>> {
		if (
			requestParameters.internshipId === null ||
			requestParameters.internshipId === undefined
		) {
			throw new runtime.RequiredError(
				"internshipId",
				"Required parameter requestParameters.internshipId was null or undefined when calling apiInternshipCancelInternshipIdPost."
			);
		}

		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/Internship/Cancel/{internshipId}`.replace(
					`{${"internshipId"}}`,
					encodeURIComponent(String(requestParameters.internshipId))
				),
				method: "POST",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.VoidApiResponse(response);
	}

	/**
	 */
	async apiInternshipCancelInternshipIdPost(
		requestParameters: ApiInternshipCancelInternshipIdPostRequest,
		initOverrides?: RequestInit
	): Promise<void> {
		await this.apiInternshipCancelInternshipIdPostRaw(
			requestParameters,
			initOverrides
		);
	}

	/**
	 */
	async apiInternshipDeleteEnrollmentIdDeleteRaw(
		requestParameters: ApiInternshipDeleteEnrollmentIdDeleteRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<void>> {
		if (requestParameters.id === null || requestParameters.id === undefined) {
			throw new runtime.RequiredError(
				"id",
				"Required parameter requestParameters.id was null or undefined when calling apiInternshipDeleteEnrollmentIdDelete."
			);
		}

		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/Internship/DeleteEnrollment/{id}`.replace(
					`{${"id"}}`,
					encodeURIComponent(String(requestParameters.id))
				),
				method: "DELETE",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.VoidApiResponse(response);
	}

	/**
	 */
	async apiInternshipDeleteEnrollmentIdDelete(
		requestParameters: ApiInternshipDeleteEnrollmentIdDeleteRequest,
		initOverrides?: RequestInit
	): Promise<void> {
		await this.apiInternshipDeleteEnrollmentIdDeleteRaw(
			requestParameters,
			initOverrides
		);
	}

	/**
	 */
	async apiInternshipEnrollStudentPostRaw(
		requestParameters: ApiInternshipEnrollStudentPostRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<void>> {
		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		headerParameters["Content-Type"] = "application/json";

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/Internship/EnrollStudent`,
				method: "POST",
				headers: headerParameters,
				query: queryParameters,
				body: EnrollStudentDTOToJSON(requestParameters.enrollStudentDTO),
			},
			initOverrides
		);

		return new runtime.VoidApiResponse(response);
	}

	/**
	 */
	async apiInternshipEnrollStudentPost(
		requestParameters: ApiInternshipEnrollStudentPostRequest = {},
		initOverrides?: RequestInit
	): Promise<void> {
		await this.apiInternshipEnrollStudentPostRaw(
			requestParameters,
			initOverrides
		);
	}

	/**
	 */
	async apiInternshipFinalAcceptPostRaw(
		requestParameters: ApiInternshipFinalAcceptPostRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<void>> {
		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		headerParameters["Content-Type"] = "application/json";

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/Internship/FinalAccept`,
				method: "POST",
				headers: headerParameters,
				query: queryParameters,
				body: InternshipAcceptDTOToJSON(requestParameters.internshipAcceptDTO),
			},
			initOverrides
		);

		return new runtime.VoidApiResponse(response);
	}

	/**
	 */
	async apiInternshipFinalAcceptPost(
		requestParameters: ApiInternshipFinalAcceptPostRequest = {},
		initOverrides?: RequestInit
	): Promise<void> {
		await this.apiInternshipFinalAcceptPostRaw(
			requestParameters,
			initOverrides
		);
	}

	/**
	 */
	async apiInternshipGetAllApplicationTypesCountGetRaw(
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<ApplicationTypesCountDTO>> {
		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/Internship/GetAllApplicationTypesCount`,
				method: "GET",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			ApplicationTypesCountDTOFromJSON(jsonValue)
		);
	}

	/**
	 */
	async apiInternshipGetAllApplicationTypesCountGet(
		initOverrides?: RequestInit
	): Promise<ApplicationTypesCountDTO> {
		const response = await this.apiInternshipGetAllApplicationTypesCountGetRaw(
			initOverrides
		);
		return await response.value();
	}

	/**
	 */
	async apiInternshipGetAllApplicationsGetRaw(
		requestParameters: ApiInternshipGetAllApplicationsGetRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<InternshipEnrollmentDTOPagedResponse>> {
		const queryParameters: any = {};

		if (requestParameters.companyId !== undefined) {
			queryParameters["CompanyId"] = requestParameters.companyId;
		}

		if (requestParameters.professorId !== undefined) {
			queryParameters["ProfessorId"] = requestParameters.professorId;
		}

		if (requestParameters.skills) {
			queryParameters["Skills"] = requestParameters.skills;
		}

		if (requestParameters.categories) {
			queryParameters["Categories"] = requestParameters.categories;
		}

		if (requestParameters.types) {
			queryParameters["Types"] = requestParameters.types;
		}

		if (requestParameters.locations) {
			queryParameters["Locations"] = requestParameters.locations;
		}

		if (requestParameters.statuses) {
			queryParameters["Statuses"] = requestParameters.statuses;
		}

		if (requestParameters.states) {
			queryParameters["States"] = requestParameters.states;
		}

		if (requestParameters.applicabilityTypes) {
			queryParameters["ApplicabilityTypes"] =
				requestParameters.applicabilityTypes;
		}

		if (requestParameters.validationStates) {
			queryParameters["ValidationStates"] = requestParameters.validationStates;
		}

		if (requestParameters.universityYears) {
			queryParameters["UniversityYears"] = requestParameters.universityYears;
		}

		if (requestParameters.searchTerm !== undefined) {
			queryParameters["SearchTerm"] = requestParameters.searchTerm;
		}

		if (requestParameters.sortField !== undefined) {
			queryParameters["SortField"] = requestParameters.sortField;
		}

		if (requestParameters.sortDirection !== undefined) {
			queryParameters["SortDirection"] = requestParameters.sortDirection;
		}

		if (requestParameters.facultyCoordinatorId !== undefined) {
			queryParameters["FacultyCoordinatorId"] =
				requestParameters.facultyCoordinatorId;
		}

		if (requestParameters.enableSearchByFacultyCoordinator !== undefined) {
			queryParameters["EnableSearchByFacultyCoordinator"] =
				requestParameters.enableSearchByFacultyCoordinator;
		}

		if (requestParameters.studentStatus) {
			queryParameters["StudentStatus"] = requestParameters.studentStatus;
		}

		if (requestParameters.recruiterStatus) {
			queryParameters["RecruiterStatus"] = requestParameters.recruiterStatus;
		}

		if (requestParameters.page !== undefined) {
			queryParameters["Page"] = requestParameters.page;
		}

		if (requestParameters.pageSize !== undefined) {
			queryParameters["PageSize"] = requestParameters.pageSize;
		}

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/Internship/GetAllApplications`,
				method: "GET",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			InternshipEnrollmentDTOPagedResponseFromJSON(jsonValue)
		);
	}

	/**
	 */
	async apiInternshipGetAllApplicationsGet(
		requestParameters: ApiInternshipGetAllApplicationsGetRequest = {},
		initOverrides?: RequestInit
	): Promise<InternshipEnrollmentDTOPagedResponse> {
		const response = await this.apiInternshipGetAllApplicationsGetRaw(
			requestParameters,
			initOverrides
		);
		return await response.value();
	}

	/**
	 */
	async apiInternshipGetAllEnrollmentsAdminGetRaw(
		requestParameters: ApiInternshipGetAllEnrollmentsAdminGetRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<EnrollmentDTOPagedResponse>> {
		const queryParameters: any = {};

		if (requestParameters.studentNamePattern !== undefined) {
			queryParameters["StudentNamePattern"] =
				requestParameters.studentNamePattern;
		}

		if (requestParameters.employerNamePattern !== undefined) {
			queryParameters["EmployerNamePattern"] =
				requestParameters.employerNamePattern;
		}

		if (requestParameters.internshipNamePattern !== undefined) {
			queryParameters["InternshipNamePattern"] =
				requestParameters.internshipNamePattern;
		}

		if (requestParameters.page !== undefined) {
			queryParameters["Page"] = requestParameters.page;
		}

		if (requestParameters.pageSize !== undefined) {
			queryParameters["PageSize"] = requestParameters.pageSize;
		}

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/Internship/GetAllEnrollmentsAdmin`,
				method: "GET",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			EnrollmentDTOPagedResponseFromJSON(jsonValue)
		);
	}

	/**
	 */
	async apiInternshipGetAllEnrollmentsAdminGet(
		requestParameters: ApiInternshipGetAllEnrollmentsAdminGetRequest = {},
		initOverrides?: RequestInit
	): Promise<EnrollmentDTOPagedResponse> {
		const response = await this.apiInternshipGetAllEnrollmentsAdminGetRaw(
			requestParameters,
			initOverrides
		);
		return await response.value();
	}

	/**
	 */
	async apiInternshipGetDiplomaStudentsCountGetRaw(
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<number>> {
		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/Internship/GetDiplomaStudentsCount`,
				method: "GET",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.TextApiResponse(response) as any;
	}

	/**
	 */
	async apiInternshipGetDiplomaStudentsCountGet(
		initOverrides?: RequestInit
	): Promise<number> {
		const response = await this.apiInternshipGetDiplomaStudentsCountGetRaw(
			initOverrides
		);
		return await response.value();
	}

	/**
	 */
	async apiInternshipGetDiplomaStudentsGetRaw(
		requestParameters: ApiInternshipGetDiplomaStudentsGetRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<DiplomaStudentDTOPagedResponse>> {
		const queryParameters: any = {};

		if (requestParameters.namePattern !== undefined) {
			queryParameters["NamePattern"] = requestParameters.namePattern;
		}

		if (requestParameters.studyProgram) {
			queryParameters["StudyProgram"] = requestParameters.studyProgram;
		}

		if (requestParameters.engagementType) {
			queryParameters["EngagementType"] = requestParameters.engagementType;
		}

		if (requestParameters.years) {
			queryParameters["Years"] = requestParameters.years;
		}

		if (requestParameters.faculties) {
			queryParameters["Faculties"] = requestParameters.faculties;
		}

		if (requestParameters.internshipDocumentStates) {
			queryParameters["InternshipDocumentStates"] =
				requestParameters.internshipDocumentStates;
		}

		if (requestParameters.page !== undefined) {
			queryParameters["Page"] = requestParameters.page;
		}

		if (requestParameters.pageSize !== undefined) {
			queryParameters["PageSize"] = requestParameters.pageSize;
		}

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/Internship/GetDiplomaStudents`,
				method: "GET",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			DiplomaStudentDTOPagedResponseFromJSON(jsonValue)
		);
	}

	/**
	 */
	async apiInternshipGetDiplomaStudentsGet(
		requestParameters: ApiInternshipGetDiplomaStudentsGetRequest = {},
		initOverrides?: RequestInit
	): Promise<DiplomaStudentDTOPagedResponse> {
		const response = await this.apiInternshipGetDiplomaStudentsGetRaw(
			requestParameters,
			initOverrides
		);
		return await response.value();
	}

	/**
	 */
	async apiInternshipGetEnrollmentByIdIdGetRaw(
		requestParameters: ApiInternshipGetEnrollmentByIdIdGetRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<InternshipEnrollmentDTO>> {
		if (requestParameters.id === null || requestParameters.id === undefined) {
			throw new runtime.RequiredError(
				"id",
				"Required parameter requestParameters.id was null or undefined when calling apiInternshipGetEnrollmentByIdIdGet."
			);
		}

		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/Internship/GetEnrollmentById/{id}`.replace(
					`{${"id"}}`,
					encodeURIComponent(String(requestParameters.id))
				),
				method: "GET",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			InternshipEnrollmentDTOFromJSON(jsonValue)
		);
	}

	/**
	 */
	async apiInternshipGetEnrollmentByIdIdGet(
		requestParameters: ApiInternshipGetEnrollmentByIdIdGetRequest,
		initOverrides?: RequestInit
	): Promise<InternshipEnrollmentDTO> {
		const response = await this.apiInternshipGetEnrollmentByIdIdGetRaw(
			requestParameters,
			initOverrides
		);
		return await response.value();
	}

	/**
	 */
	async apiInternshipGetInternsFiltersGetRaw(
		requestParameters: ApiInternshipGetInternsFiltersGetRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<Array<Filter>>> {
		const queryParameters: any = {};

		if (requestParameters.filtersList) {
			queryParameters["filtersList"] = requestParameters.filtersList;
		}

		if (requestParameters.language !== undefined) {
			queryParameters["language"] = requestParameters.language;
		}

		if (requestParameters.type !== undefined) {
			queryParameters["type"] = requestParameters.type;
		}

		if (requestParameters.namePattern !== undefined) {
			queryParameters["NamePattern"] = requestParameters.namePattern;
		}

		if (requestParameters.internships) {
			queryParameters["Internships"] = requestParameters.internships;
		}

		if (requestParameters.studyProgram) {
			queryParameters["StudyProgram"] = requestParameters.studyProgram;
		}

		if (requestParameters.engagementType) {
			queryParameters["EngagementType"] = requestParameters.engagementType;
		}

		if (requestParameters.years) {
			queryParameters["Years"] = requestParameters.years;
		}

		if (requestParameters.faculties) {
			queryParameters["Faculties"] = requestParameters.faculties;
		}

		if (requestParameters.studentStatus) {
			queryParameters["StudentStatus"] = requestParameters.studentStatus;
		}

		if (requestParameters.recruiterStatus) {
			queryParameters["RecruiterStatus"] = requestParameters.recruiterStatus;
		}

		if (requestParameters.practiceNotebookStatuses) {
			queryParameters["PracticeNotebookStatuses"] =
				requestParameters.practiceNotebookStatuses;
		}

		if (requestParameters.practiceCertificateStatuses) {
			queryParameters["PracticeCertificateStatuses"] =
				requestParameters.practiceCertificateStatuses;
		}

		if (requestParameters.viewStatuses) {
			queryParameters["ViewStatuses"] = requestParameters.viewStatuses;
		}

		if (requestParameters.page !== undefined) {
			queryParameters["Page"] = requestParameters.page;
		}

		if (requestParameters.pageSize !== undefined) {
			queryParameters["PageSize"] = requestParameters.pageSize;
		}

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/Internship/GetInternsFilters`,
				method: "GET",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			jsonValue.map(FilterFromJSON)
		);
	}

	/**
	 */
	async apiInternshipGetInternsFiltersGet(
		requestParameters: ApiInternshipGetInternsFiltersGetRequest = {},
		initOverrides?: RequestInit
	): Promise<Array<Filter>> {
		const response = await this.apiInternshipGetInternsFiltersGetRaw(
			requestParameters,
			initOverrides
		);
		return await response.value();
	}

	/**
	 */
	async apiInternshipGetInternsForEmployerGetRaw(
		requestParameters: ApiInternshipGetInternsForEmployerGetRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<InternsForEmployerDTOPagedResponse>> {
		const queryParameters: any = {};

		if (requestParameters.namePattern !== undefined) {
			queryParameters["NamePattern"] = requestParameters.namePattern;
		}

		if (requestParameters.internships) {
			queryParameters["Internships"] = requestParameters.internships;
		}

		if (requestParameters.studyProgram) {
			queryParameters["StudyProgram"] = requestParameters.studyProgram;
		}

		if (requestParameters.engagementType) {
			queryParameters["EngagementType"] = requestParameters.engagementType;
		}

		if (requestParameters.years) {
			queryParameters["Years"] = requestParameters.years;
		}

		if (requestParameters.faculties) {
			queryParameters["Faculties"] = requestParameters.faculties;
		}

		if (requestParameters.studentStatus) {
			queryParameters["StudentStatus"] = requestParameters.studentStatus;
		}

		if (requestParameters.recruiterStatus) {
			queryParameters["RecruiterStatus"] = requestParameters.recruiterStatus;
		}

		if (requestParameters.practiceNotebookStatuses) {
			queryParameters["PracticeNotebookStatuses"] =
				requestParameters.practiceNotebookStatuses;
		}

		if (requestParameters.practiceCertificateStatuses) {
			queryParameters["PracticeCertificateStatuses"] =
				requestParameters.practiceCertificateStatuses;
		}

		if (requestParameters.viewStatuses) {
			queryParameters["ViewStatuses"] = requestParameters.viewStatuses;
		}

		if (requestParameters.page !== undefined) {
			queryParameters["Page"] = requestParameters.page;
		}

		if (requestParameters.pageSize !== undefined) {
			queryParameters["PageSize"] = requestParameters.pageSize;
		}

		if (requestParameters.type !== undefined) {
			queryParameters["type"] = requestParameters.type;
		}

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/Internship/GetInternsForEmployer`,
				method: "GET",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			InternsForEmployerDTOPagedResponseFromJSON(jsonValue)
		);
	}

	/**
	 */
	async apiInternshipGetInternsForEmployerGet(
		requestParameters: ApiInternshipGetInternsForEmployerGetRequest = {},
		initOverrides?: RequestInit
	): Promise<InternsForEmployerDTOPagedResponse> {
		const response = await this.apiInternshipGetInternsForEmployerGetRaw(
			requestParameters,
			initOverrides
		);
		return await response.value();
	}

	/**
	 */
	async apiInternshipGetInternshipTypeEventFiltersGetRaw(
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<Array<Filter>>> {
		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/Internship/GetInternshipTypeEventFilters`,
				method: "GET",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			jsonValue.map(FilterFromJSON)
		);
	}

	/**
	 */
	async apiInternshipGetInternshipTypeEventFiltersGet(
		initOverrides?: RequestInit
	): Promise<Array<Filter>> {
		const response =
			await this.apiInternshipGetInternshipTypeEventFiltersGetRaw(
				initOverrides
			);
		return await response.value();
	}

	/**
	 */
	async apiInternshipGetInternshipsTypeEventGetRaw(
		requestParameters: ApiInternshipGetInternshipsTypeEventGetRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<InternshipDTOPagedResponse>> {
		const queryParameters: any = {};

		if (requestParameters.skills) {
			queryParameters["Skills"] = requestParameters.skills;
		}

		if (requestParameters.categories) {
			queryParameters["Categories"] = requestParameters.categories;
		}

		if (requestParameters.studyProgram) {
			queryParameters["StudyProgram"] = requestParameters.studyProgram;
		}

		if (requestParameters.years) {
			queryParameters["Years"] = requestParameters.years;
		}

		if (requestParameters.types) {
			queryParameters["Types"] = requestParameters.types;
		}

		if (requestParameters.locations) {
			queryParameters["Locations"] = requestParameters.locations;
		}

		if (requestParameters.searchTerm !== undefined) {
			queryParameters["SearchTerm"] = requestParameters.searchTerm;
		}

		if (requestParameters.sortField !== undefined) {
			queryParameters["SortField"] = requestParameters.sortField;
		}

		if (requestParameters.sortDirection !== undefined) {
			queryParameters["SortDirection"] = requestParameters.sortDirection;
		}

		if (requestParameters.page !== undefined) {
			queryParameters["Page"] = requestParameters.page;
		}

		if (requestParameters.pageSize !== undefined) {
			queryParameters["PageSize"] = requestParameters.pageSize;
		}

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/Internship/GetInternshipsTypeEvent`,
				method: "GET",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			InternshipDTOPagedResponseFromJSON(jsonValue)
		);
	}

	/**
	 */
	async apiInternshipGetInternshipsTypeEventGet(
		requestParameters: ApiInternshipGetInternshipsTypeEventGetRequest = {},
		initOverrides?: RequestInit
	): Promise<InternshipDTOPagedResponse> {
		const response = await this.apiInternshipGetInternshipsTypeEventGetRaw(
			requestParameters,
			initOverrides
		);
		return await response.value();
	}

	/**
	 */
	async apiInternshipGetPracticeStudentsAdminGetRaw(
		requestParameters: ApiInternshipGetPracticeStudentsAdminGetRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<PracticeStudentsDTOPagedResponse>> {
		const queryParameters: any = {};

		if (requestParameters.internshipNamePattern !== undefined) {
			queryParameters["InternshipNamePattern"] =
				requestParameters.internshipNamePattern;
		}

		if (requestParameters.studentNamePattern !== undefined) {
			queryParameters["StudentNamePattern"] =
				requestParameters.studentNamePattern;
		}

		if (requestParameters.employerNamePattern !== undefined) {
			queryParameters["EmployerNamePattern"] =
				requestParameters.employerNamePattern;
		}

		if (requestParameters.supervisorNamePattern !== undefined) {
			queryParameters["SupervisorNamePattern"] =
				requestParameters.supervisorNamePattern;
		}

		if (requestParameters.studyProgram) {
			queryParameters["StudyProgram"] = requestParameters.studyProgram;
		}

		if (requestParameters.faculty) {
			queryParameters["Faculty"] = requestParameters.faculty;
		}

		if (requestParameters.department) {
			queryParameters["Department"] = requestParameters.department;
		}

		if (requestParameters.years) {
			queryParameters["Years"] = requestParameters.years;
		}

		if (requestParameters.practiceNotebookStatuses) {
			queryParameters["PracticeNotebookStatuses"] =
				requestParameters.practiceNotebookStatuses;
		}

		if (requestParameters.practiceCertificateStatuses) {
			queryParameters["PracticeCertificateStatuses"] =
				requestParameters.practiceCertificateStatuses;
		}

		if (requestParameters.universityYears) {
			queryParameters["UniversityYears"] = requestParameters.universityYears;
		}

		if (requestParameters.page !== undefined) {
			queryParameters["Page"] = requestParameters.page;
		}

		if (requestParameters.pageSize !== undefined) {
			queryParameters["PageSize"] = requestParameters.pageSize;
		}

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/Internship/GetPracticeStudentsAdmin`,
				method: "GET",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			PracticeStudentsDTOPagedResponseFromJSON(jsonValue)
		);
	}

	/**
	 */
	async apiInternshipGetPracticeStudentsAdminGet(
		requestParameters: ApiInternshipGetPracticeStudentsAdminGetRequest = {},
		initOverrides?: RequestInit
	): Promise<PracticeStudentsDTOPagedResponse> {
		const response = await this.apiInternshipGetPracticeStudentsAdminGetRaw(
			requestParameters,
			initOverrides
		);
		return await response.value();
	}

	/**
	 */
	async apiInternshipGetPracticeStudentsCountGetRaw(
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<number>> {
		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/Internship/GetPracticeStudentsCount`,
				method: "GET",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.TextApiResponse(response) as any;
	}

	/**
	 */
	async apiInternshipGetPracticeStudentsCountGet(
		initOverrides?: RequestInit
	): Promise<number> {
		const response = await this.apiInternshipGetPracticeStudentsCountGetRaw(
			initOverrides
		);
		return await response.value();
	}

	/**
	 */
	async apiInternshipGetPracticeStudentsFiltersGetRaw(
		requestParameters: ApiInternshipGetPracticeStudentsFiltersGetRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<Array<Filter>>> {
		const queryParameters: any = {};

		if (requestParameters.internshipNamePattern !== undefined) {
			queryParameters["InternshipNamePattern"] =
				requestParameters.internshipNamePattern;
		}

		if (requestParameters.studentNamePattern !== undefined) {
			queryParameters["StudentNamePattern"] =
				requestParameters.studentNamePattern;
		}

		if (requestParameters.employerNamePattern !== undefined) {
			queryParameters["EmployerNamePattern"] =
				requestParameters.employerNamePattern;
		}

		if (requestParameters.supervisorNamePattern !== undefined) {
			queryParameters["SupervisorNamePattern"] =
				requestParameters.supervisorNamePattern;
		}

		if (requestParameters.studyProgram) {
			queryParameters["StudyProgram"] = requestParameters.studyProgram;
		}

		if (requestParameters.faculty) {
			queryParameters["Faculty"] = requestParameters.faculty;
		}

		if (requestParameters.department) {
			queryParameters["Department"] = requestParameters.department;
		}

		if (requestParameters.years) {
			queryParameters["Years"] = requestParameters.years;
		}

		if (requestParameters.practiceNotebookStatuses) {
			queryParameters["PracticeNotebookStatuses"] =
				requestParameters.practiceNotebookStatuses;
		}

		if (requestParameters.practiceCertificateStatuses) {
			queryParameters["PracticeCertificateStatuses"] =
				requestParameters.practiceCertificateStatuses;
		}

		if (requestParameters.universityYears) {
			queryParameters["UniversityYears"] = requestParameters.universityYears;
		}

		if (requestParameters.page !== undefined) {
			queryParameters["Page"] = requestParameters.page;
		}

		if (requestParameters.pageSize !== undefined) {
			queryParameters["PageSize"] = requestParameters.pageSize;
		}

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/Internship/GetPracticeStudentsFilters`,
				method: "GET",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			jsonValue.map(FilterFromJSON)
		);
	}

	/**
	 */
	async apiInternshipGetPracticeStudentsFiltersGet(
		requestParameters: ApiInternshipGetPracticeStudentsFiltersGetRequest = {},
		initOverrides?: RequestInit
	): Promise<Array<Filter>> {
		const response = await this.apiInternshipGetPracticeStudentsFiltersGetRaw(
			requestParameters,
			initOverrides
		);
		return await response.value();
	}

	/**
	 */
	async apiInternshipGetPracticeStudentsGetRaw(
		requestParameters: ApiInternshipGetPracticeStudentsGetRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<PracticeStudentsDTOPagedResponse>> {
		const queryParameters: any = {};

		if (requestParameters.internshipNamePattern !== undefined) {
			queryParameters["InternshipNamePattern"] =
				requestParameters.internshipNamePattern;
		}

		if (requestParameters.studentNamePattern !== undefined) {
			queryParameters["StudentNamePattern"] =
				requestParameters.studentNamePattern;
		}

		if (requestParameters.employerNamePattern !== undefined) {
			queryParameters["EmployerNamePattern"] =
				requestParameters.employerNamePattern;
		}

		if (requestParameters.supervisorNamePattern !== undefined) {
			queryParameters["SupervisorNamePattern"] =
				requestParameters.supervisorNamePattern;
		}

		if (requestParameters.studyProgram) {
			queryParameters["StudyProgram"] = requestParameters.studyProgram;
		}

		if (requestParameters.faculty) {
			queryParameters["Faculty"] = requestParameters.faculty;
		}

		if (requestParameters.department) {
			queryParameters["Department"] = requestParameters.department;
		}

		if (requestParameters.years) {
			queryParameters["Years"] = requestParameters.years;
		}

		if (requestParameters.practiceNotebookStatuses) {
			queryParameters["PracticeNotebookStatuses"] =
				requestParameters.practiceNotebookStatuses;
		}

		if (requestParameters.practiceCertificateStatuses) {
			queryParameters["PracticeCertificateStatuses"] =
				requestParameters.practiceCertificateStatuses;
		}

		if (requestParameters.universityYears) {
			queryParameters["UniversityYears"] = requestParameters.universityYears;
		}

		if (requestParameters.page !== undefined) {
			queryParameters["Page"] = requestParameters.page;
		}

		if (requestParameters.pageSize !== undefined) {
			queryParameters["PageSize"] = requestParameters.pageSize;
		}

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/Internship/GetPracticeStudents`,
				method: "GET",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			PracticeStudentsDTOPagedResponseFromJSON(jsonValue)
		);
	}

	/**
	 */
	async apiInternshipGetPracticeStudentsGet(
		requestParameters: ApiInternshipGetPracticeStudentsGetRequest = {},
		initOverrides?: RequestInit
	): Promise<PracticeStudentsDTOPagedResponse> {
		const response = await this.apiInternshipGetPracticeStudentsGetRaw(
			requestParameters,
			initOverrides
		);
		return await response.value();
	}

	/**
	 */
	async apiInternshipGetPreviousEnrollmentsGetRaw(
		requestParameters: ApiInternshipGetPreviousEnrollmentsGetRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<InternshipEnrollmentDTOPagedResponse>> {
		const queryParameters: any = {};

		if (requestParameters.companyId !== undefined) {
			queryParameters["CompanyId"] = requestParameters.companyId;
		}

		if (requestParameters.professorId !== undefined) {
			queryParameters["ProfessorId"] = requestParameters.professorId;
		}

		if (requestParameters.skills) {
			queryParameters["Skills"] = requestParameters.skills;
		}

		if (requestParameters.categories) {
			queryParameters["Categories"] = requestParameters.categories;
		}

		if (requestParameters.types) {
			queryParameters["Types"] = requestParameters.types;
		}

		if (requestParameters.locations) {
			queryParameters["Locations"] = requestParameters.locations;
		}

		if (requestParameters.statuses) {
			queryParameters["Statuses"] = requestParameters.statuses;
		}

		if (requestParameters.states) {
			queryParameters["States"] = requestParameters.states;
		}

		if (requestParameters.applicabilityTypes) {
			queryParameters["ApplicabilityTypes"] =
				requestParameters.applicabilityTypes;
		}

		if (requestParameters.validationStates) {
			queryParameters["ValidationStates"] = requestParameters.validationStates;
		}

		if (requestParameters.universityYears) {
			queryParameters["UniversityYears"] = requestParameters.universityYears;
		}

		if (requestParameters.searchTerm !== undefined) {
			queryParameters["SearchTerm"] = requestParameters.searchTerm;
		}

		if (requestParameters.sortField !== undefined) {
			queryParameters["SortField"] = requestParameters.sortField;
		}

		if (requestParameters.sortDirection !== undefined) {
			queryParameters["SortDirection"] = requestParameters.sortDirection;
		}

		if (requestParameters.facultyCoordinatorId !== undefined) {
			queryParameters["FacultyCoordinatorId"] =
				requestParameters.facultyCoordinatorId;
		}

		if (requestParameters.enableSearchByFacultyCoordinator !== undefined) {
			queryParameters["EnableSearchByFacultyCoordinator"] =
				requestParameters.enableSearchByFacultyCoordinator;
		}

		if (requestParameters.studentStatus) {
			queryParameters["StudentStatus"] = requestParameters.studentStatus;
		}

		if (requestParameters.recruiterStatus) {
			queryParameters["RecruiterStatus"] = requestParameters.recruiterStatus;
		}

		if (requestParameters.page !== undefined) {
			queryParameters["Page"] = requestParameters.page;
		}

		if (requestParameters.pageSize !== undefined) {
			queryParameters["PageSize"] = requestParameters.pageSize;
		}

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/Internship/GetPreviousEnrollments`,
				method: "GET",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			InternshipEnrollmentDTOPagedResponseFromJSON(jsonValue)
		);
	}

	/**
	 */
	async apiInternshipGetPreviousEnrollmentsGet(
		requestParameters: ApiInternshipGetPreviousEnrollmentsGetRequest = {},
		initOverrides?: RequestInit
	): Promise<InternshipEnrollmentDTOPagedResponse> {
		const response = await this.apiInternshipGetPreviousEnrollmentsGetRaw(
			requestParameters,
			initOverrides
		);
		return await response.value();
	}

	/**
	 */
	async apiInternshipGetStudentsTypesCountGetRaw(
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<StudentTypeCountDTO>> {
		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/Internship/GetStudentsTypesCount`,
				method: "GET",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			StudentTypeCountDTOFromJSON(jsonValue)
		);
	}

	/**
	 */
	async apiInternshipGetStudentsTypesCountGet(
		initOverrides?: RequestInit
	): Promise<StudentTypeCountDTO> {
		const response = await this.apiInternshipGetStudentsTypesCountGetRaw(
			initOverrides
		);
		return await response.value();
	}

	/**
	 */
	async apiInternshipRejectApplicationPostRaw(
		requestParameters: ApiInternshipRejectApplicationPostRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<void>> {
		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		headerParameters["Content-Type"] = "application/json";

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/Internship/RejectApplication`,
				method: "POST",
				headers: headerParameters,
				query: queryParameters,
				body: InternshipAcceptDTOToJSON(requestParameters.internshipAcceptDTO),
			},
			initOverrides
		);

		return new runtime.VoidApiResponse(response);
	}

	/**
	 */
	async apiInternshipRejectApplicationPost(
		requestParameters: ApiInternshipRejectApplicationPostRequest = {},
		initOverrides?: RequestInit
	): Promise<void> {
		await this.apiInternshipRejectApplicationPostRaw(
			requestParameters,
			initOverrides
		);
	}

	/**
	 */
	async apiInternshipResetEnrollmentStatusAdminIdPutRaw(
		requestParameters: ApiInternshipResetEnrollmentStatusAdminIdPutRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<void>> {
		if (requestParameters.id === null || requestParameters.id === undefined) {
			throw new runtime.RequiredError(
				"id",
				"Required parameter requestParameters.id was null or undefined when calling apiInternshipResetEnrollmentStatusAdminIdPut."
			);
		}

		const queryParameters: any = {};

		if (requestParameters.resetStudentStatus !== undefined) {
			queryParameters["resetStudentStatus"] =
				requestParameters.resetStudentStatus;
		}

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/Internship/ResetEnrollmentStatusAdmin/{id}`.replace(
					`{${"id"}}`,
					encodeURIComponent(String(requestParameters.id))
				),
				method: "PUT",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.VoidApiResponse(response);
	}

	/**
	 */
	async apiInternshipResetEnrollmentStatusAdminIdPut(
		requestParameters: ApiInternshipResetEnrollmentStatusAdminIdPutRequest,
		initOverrides?: RequestInit
	): Promise<void> {
		await this.apiInternshipResetEnrollmentStatusAdminIdPutRaw(
			requestParameters,
			initOverrides
		);
	}

	/**
	 */
	async apiInternshipSetInternshipParticipationIntentByInternshipIdInternshipIdPostRaw(
		requestParameters: ApiInternshipSetInternshipParticipationIntentByInternshipIdInternshipIdPostRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<InternshipParticipationIntentDTO>> {
		if (
			requestParameters.internshipId === null ||
			requestParameters.internshipId === undefined
		) {
			throw new runtime.RequiredError(
				"internshipId",
				"Required parameter requestParameters.internshipId was null or undefined when calling apiInternshipSetInternshipParticipationIntentByInternshipIdInternshipIdPost."
			);
		}

		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		headerParameters["Content-Type"] = "application/json";

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/Internship/SetInternshipParticipationIntentByInternshipId/{internshipId}`.replace(
					`{${"internshipId"}}`,
					encodeURIComponent(String(requestParameters.internshipId))
				),
				method: "POST",
				headers: headerParameters,
				query: queryParameters,
				body: InternshipParticipationIntentDTOToJSON(
					requestParameters.internshipParticipationIntentDTO
				),
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			InternshipParticipationIntentDTOFromJSON(jsonValue)
		);
	}

	/**
	 */
	async apiInternshipSetInternshipParticipationIntentByInternshipIdInternshipIdPost(
		requestParameters: ApiInternshipSetInternshipParticipationIntentByInternshipIdInternshipIdPostRequest,
		initOverrides?: RequestInit
	): Promise<InternshipParticipationIntentDTO> {
		const response =
			await this.apiInternshipSetInternshipParticipationIntentByInternshipIdInternshipIdPostRaw(
				requestParameters,
				initOverrides
			);
		return await response.value();
	}

	/**
	 */
	async apiInternshipUpdateIsEnrollmentViewedIdPutRaw(
		requestParameters: ApiInternshipUpdateIsEnrollmentViewedIdPutRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<InternshipEnrollmentDTO>> {
		if (requestParameters.id === null || requestParameters.id === undefined) {
			throw new runtime.RequiredError(
				"id",
				"Required parameter requestParameters.id was null or undefined when calling apiInternshipUpdateIsEnrollmentViewedIdPut."
			);
		}

		const queryParameters: any = {};

		if (requestParameters.isEnrollmentView !== undefined) {
			queryParameters["isEnrollmentView"] = requestParameters.isEnrollmentView;
		}

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/Internship/UpdateIsEnrollmentViewed/{id}`.replace(
					`{${"id"}}`,
					encodeURIComponent(String(requestParameters.id))
				),
				method: "PUT",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			InternshipEnrollmentDTOFromJSON(jsonValue)
		);
	}

	/**
	 */
	async apiInternshipUpdateIsEnrollmentViewedIdPut(
		requestParameters: ApiInternshipUpdateIsEnrollmentViewedIdPutRequest,
		initOverrides?: RequestInit
	): Promise<InternshipEnrollmentDTO> {
		const response = await this.apiInternshipUpdateIsEnrollmentViewedIdPutRaw(
			requestParameters,
			initOverrides
		);
		return await response.value();
	}

	/**
	 */
	async apiInternshipUpdatePracticeInLaboratoryIdPutRaw(
		requestParameters: ApiInternshipUpdatePracticeInLaboratoryIdPutRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<InternshipEnrollmentDTO>> {
		if (requestParameters.id === null || requestParameters.id === undefined) {
			throw new runtime.RequiredError(
				"id",
				"Required parameter requestParameters.id was null or undefined when calling apiInternshipUpdatePracticeInLaboratoryIdPut."
			);
		}

		const queryParameters: any = {};

		if (requestParameters.laboratoryPracticeRoom !== undefined) {
			queryParameters["laboratoryPracticeRoom"] =
				requestParameters.laboratoryPracticeRoom;
		}

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/Internship/UpdatePracticeInLaboratory/{id}`.replace(
					`{${"id"}}`,
					encodeURIComponent(String(requestParameters.id))
				),
				method: "PUT",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			InternshipEnrollmentDTOFromJSON(jsonValue)
		);
	}

	/**
	 */
	async apiInternshipUpdatePracticeInLaboratoryIdPut(
		requestParameters: ApiInternshipUpdatePracticeInLaboratoryIdPutRequest,
		initOverrides?: RequestInit
	): Promise<InternshipEnrollmentDTO> {
		const response = await this.apiInternshipUpdatePracticeInLaboratoryIdPutRaw(
			requestParameters,
			initOverrides
		);
		return await response.value();
	}

	/**
	 */
	async apiInternshipUpdateTutorIdPutRaw(
		requestParameters: ApiInternshipUpdateTutorIdPutRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<RequestResponse>> {
		if (requestParameters.id === null || requestParameters.id === undefined) {
			throw new runtime.RequiredError(
				"id",
				"Required parameter requestParameters.id was null or undefined when calling apiInternshipUpdateTutorIdPut."
			);
		}

		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		headerParameters["Content-Type"] = "application/json";

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/Internship/UpdateTutor/{id}`.replace(
					`{${"id"}}`,
					encodeURIComponent(String(requestParameters.id))
				),
				method: "PUT",
				headers: headerParameters,
				query: queryParameters,
				body: requestParameters.body as any,
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			RequestResponseFromJSON(jsonValue)
		);
	}

	/**
	 */
	async apiInternshipUpdateTutorIdPut(
		requestParameters: ApiInternshipUpdateTutorIdPutRequest,
		initOverrides?: RequestInit
	): Promise<RequestResponse> {
		const response = await this.apiInternshipUpdateTutorIdPutRaw(
			requestParameters,
			initOverrides
		);
		return await response.value();
	}

	/**
	 */
	async apiInternshipWithdrawOfferIdDeleteRaw(
		requestParameters: ApiInternshipWithdrawOfferIdDeleteRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<void>> {
		if (requestParameters.id === null || requestParameters.id === undefined) {
			throw new runtime.RequiredError(
				"id",
				"Required parameter requestParameters.id was null or undefined when calling apiInternshipWithdrawOfferIdDelete."
			);
		}

		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/Internship/WithdrawOffer/{id}`.replace(
					`{${"id"}}`,
					encodeURIComponent(String(requestParameters.id))
				),
				method: "DELETE",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.VoidApiResponse(response);
	}

	/**
	 */
	async apiInternshipWithdrawOfferIdDelete(
		requestParameters: ApiInternshipWithdrawOfferIdDeleteRequest,
		initOverrides?: RequestInit
	): Promise<void> {
		await this.apiInternshipWithdrawOfferIdDeleteRaw(
			requestParameters,
			initOverrides
		);
	}
}
