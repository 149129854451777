/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface EnrollStudentDTO
 */
export interface EnrollStudentDTO {
	/**
	 *
	 * @type {string}
	 * @memberof EnrollStudentDTO
	 */
	studentId?: string;
	/**
	 *
	 * @type {string}
	 * @memberof EnrollStudentDTO
	 */
	internshipId?: string;
}

export function EnrollStudentDTOFromJSON(json: any): EnrollStudentDTO {
	return EnrollStudentDTOFromJSONTyped(json, false);
}

export function EnrollStudentDTOFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): EnrollStudentDTO {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		studentId: !exists(json, "studentId") ? undefined : json["studentId"],
		internshipId: !exists(json, "internshipId")
			? undefined
			: json["internshipId"],
	};
}

export function EnrollStudentDTOToJSON(value?: EnrollStudentDTO | null): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		studentId: value.studentId,
		internshipId: value.internshipId,
	};
}
