import { FacultyApi } from "../Api";
import { getAuthConfiguration } from "./configuration";

const AuthFacultyApiFactory = () => new FacultyApi(getAuthConfiguration());

export const getAllFaculties = (
	namePattern?: string,
	page?: number,
	pageSize?: number
) => {
	return AuthFacultyApiFactory().apiFacultyGetAllGet({
		namePattern,
		page,
		pageSize,
	});
};

export const getFacultyById = (id: string) => {
	return AuthFacultyApiFactory().apiFacultyGetByIdIdGet({ id });
};

export const getAllFacultiesWithASpecificCompanyAccord = (
	companyId: string,
	namePattern?: string,
	page?: number,
	pageSize?: number
) => {
	return AuthFacultyApiFactory().apiFacultyGetAllFacultiesWithASpecificCompanyAccordCompanyIdGet(
		{ companyId, namePattern, page, pageSize }
	);
};

export const getAllCompaniesForFaculty = (
	namePattern?: string,
	page?: number,
	pageSize?: number
) => {
	return AuthFacultyApiFactory().apiFacultyGetCompaniesForFacultyGet({
		namePattern,
		page,
		pageSize,
	});
};

export interface AllCompaniesForFacultyProps {
	search?: string; //namePattern
	page?: number;
	pageSize?: number;
}

export const getAllCompaniesForFacultyV2 = (
	props: AllCompaniesForFacultyProps
) => {
	const { search: namePattern, page, pageSize } = props;

	return AuthFacultyApiFactory().apiFacultyGetCompaniesForFacultyGet({
		namePattern,
		page,
		pageSize,
	});
};
