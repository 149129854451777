import { PropsWithChildren, useCallback, useMemo, useState } from "react";
import { Filter } from "../../Api";
import FilterContent from "./FilterContent";
import { Button, Modal, Typography } from "antd";
import { FilterOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { parseFilters } from "../../utils/filtering";

interface FilterProps {
	filters?: Filter[];
	searchFields?: any[];
	onUpdate: (filters: { [key: string]: string[] }) => void;
	hasSort?: boolean;
}

const Filters = (props: PropsWithChildren<FilterProps>) => {
	const { filters, searchFields, onUpdate, hasSort } = props;
	const { t } = useTranslation();

	const [isOpen, setIsOpen] = useState(false);
	const handleModalOpen = () => setIsOpen(true);
	const handleModalCancel = () => setIsOpen(false);

	const handleUpdate = useCallback(
		(filters: { [key: string]: string[] }) => {
			setIsOpen(false);
			onUpdate(filters);
		},
		[onUpdate]
	);

	const filtersTemplate = useMemo(() => {
		if (!filters) return;
		const parse = parseFilters(filters);
		const validFilters = parse?.filter((item) => item.options.length > 0);
		return validFilters;
	}, [filters]);

	if (
		(!filtersTemplate || filtersTemplate.length === 0) &&
		(!searchFields || searchFields.length === 0)
	)
		return <></>;

	return (
		<div className="flex flex-row md:flex-col gap-4 justify-between items-center md:items-stretch py-5">
			<Typography.Title level={3} className="text-left my-0">
				{t("internships.filters.filters")}
			</Typography.Title>

			<div className="hidden md:block">
				<FilterContent
					filters={filtersTemplate}
					searchFields={searchFields}
					onUpdate={handleUpdate}
					hasSort={hasSort}
				/>
			</div>

			<div className="block md:hidden">
				<Button type="primary" onClick={handleModalOpen}>
					<FilterOutlined />
				</Button>

				<Modal
					title={t("internships.filters.filters")}
					open={isOpen}
					footer={null}
					onCancel={handleModalCancel}
				>
					<FilterContent
						filters={filtersTemplate}
						searchFields={searchFields}
						onUpdate={handleUpdate}
						handleCancel={handleModalCancel}
						hasSort={hasSort}
					/>
				</Modal>
			</div>
		</div>
	);
};

export default Filters;
