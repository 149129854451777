/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
	Company,
	CompanyFromJSON,
	CompanyFromJSONTyped,
	CompanyToJSON,
} from "./Company";
import {
	CompanyDocumentStatusEnum,
	CompanyDocumentStatusEnumFromJSON,
	CompanyDocumentStatusEnumFromJSONTyped,
	CompanyDocumentStatusEnumToJSON,
} from "./CompanyDocumentStatusEnum";
import {
	DocumentTypeEnum,
	DocumentTypeEnumFromJSON,
	DocumentTypeEnumFromJSONTyped,
	DocumentTypeEnumToJSON,
} from "./DocumentTypeEnum";
import {
	Faculty,
	FacultyFromJSON,
	FacultyFromJSONTyped,
	FacultyToJSON,
} from "./Faculty";

/**
 *
 * @export
 * @interface CompanyDocument
 */
export interface CompanyDocument {
	/**
	 *
	 * @type {string}
	 * @memberof CompanyDocument
	 */
	id?: string;
	/**
	 *
	 * @type {Date}
	 * @memberof CompanyDocument
	 */
	createdAt?: Date;
	/**
	 *
	 * @type {Date}
	 * @memberof CompanyDocument
	 */
	updatedAt?: Date;
	/**
	 *
	 * @type {DocumentTypeEnum}
	 * @memberof CompanyDocument
	 */
	documentType?: DocumentTypeEnum;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyDocument
	 */
	documentOwner?: string;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyDocument
	 */
	companyId?: string;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyDocument
	 */
	facultyId?: string;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyDocument
	 */
	file?: string | null;
	/**
	 *
	 * @type {Company}
	 * @memberof CompanyDocument
	 */
	company?: Company;
	/**
	 *
	 * @type {Faculty}
	 * @memberof CompanyDocument
	 */
	faculty?: Faculty;
	/**
	 *
	 * @type {boolean}
	 * @memberof CompanyDocument
	 */
	skipOfferValidation?: boolean;
	/**
	 *
	 * @type {CompanyDocumentStatusEnum}
	 * @memberof CompanyDocument
	 */
	documentStatus?: CompanyDocumentStatusEnum;
}

export function CompanyDocumentFromJSON(json: any): CompanyDocument {
	return CompanyDocumentFromJSONTyped(json, false);
}

export function CompanyDocumentFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): CompanyDocument {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		id: !exists(json, "id") ? undefined : json["id"],
		createdAt: !exists(json, "createdAt")
			? undefined
			: new Date(json["createdAt"]),
		updatedAt: !exists(json, "updatedAt")
			? undefined
			: new Date(json["updatedAt"]),
		documentType: !exists(json, "documentType")
			? undefined
			: DocumentTypeEnumFromJSON(json["documentType"]),
		documentOwner: !exists(json, "documentOwner")
			? undefined
			: json["documentOwner"],
		companyId: !exists(json, "companyId") ? undefined : json["companyId"],
		facultyId: !exists(json, "facultyId") ? undefined : json["facultyId"],
		file: !exists(json, "file") ? undefined : json["file"],
		company: !exists(json, "company")
			? undefined
			: CompanyFromJSON(json["company"]),
		faculty: !exists(json, "faculty")
			? undefined
			: FacultyFromJSON(json["faculty"]),
		skipOfferValidation: !exists(json, "skipOfferValidation")
			? undefined
			: json["skipOfferValidation"],
		documentStatus: !exists(json, "documentStatus")
			? undefined
			: CompanyDocumentStatusEnumFromJSON(json["documentStatus"]),
	};
}

export function CompanyDocumentToJSON(value?: CompanyDocument | null): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		id: value.id,
		createdAt:
			value.createdAt === undefined ? undefined : value.createdAt.toISOString(),
		updatedAt:
			value.updatedAt === undefined ? undefined : value.updatedAt.toISOString(),
		documentType: DocumentTypeEnumToJSON(value.documentType),
		documentOwner: value.documentOwner,
		companyId: value.companyId,
		facultyId: value.facultyId,
		file: value.file,
		company: CompanyToJSON(value.company),
		faculty: FacultyToJSON(value.faculty),
		skipOfferValidation: value.skipOfferValidation,
		documentStatus: CompanyDocumentStatusEnumToJSON(value.documentStatus),
	};
}
