/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
	PreferredLanguageEnum,
	PreferredLanguageEnumFromJSON,
	PreferredLanguageEnumFromJSONTyped,
	PreferredLanguageEnumToJSON,
} from "./PreferredLanguageEnum";

/**
 *
 * @export
 * @interface CompanyAddDTO
 */
export interface CompanyAddDTO {
	/**
	 *
	 * @type {string}
	 * @memberof CompanyAddDTO
	 */
	name?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyAddDTO
	 */
	commercialName?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyAddDTO
	 */
	cui?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyAddDTO
	 */
	address?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyAddDTO
	 */
	postalCode?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyAddDTO
	 */
	phone?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyAddDTO
	 */
	domainOfActivity?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyAddDTO
	 */
	fax?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyAddDTO
	 */
	repName?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyAddDTO
	 */
	email?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyAddDTO
	 */
	password?: string | null;
	/**
	 *
	 * @type {PreferredLanguageEnum}
	 * @memberof CompanyAddDTO
	 */
	preferredLanguage?: PreferredLanguageEnum;
}

export function CompanyAddDTOFromJSON(json: any): CompanyAddDTO {
	return CompanyAddDTOFromJSONTyped(json, false);
}

export function CompanyAddDTOFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): CompanyAddDTO {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		name: !exists(json, "name") ? undefined : json["name"],
		commercialName: !exists(json, "commercialName")
			? undefined
			: json["commercialName"],
		cui: !exists(json, "cui") ? undefined : json["cui"],
		address: !exists(json, "address") ? undefined : json["address"],
		postalCode: !exists(json, "postalCode") ? undefined : json["postalCode"],
		phone: !exists(json, "phone") ? undefined : json["phone"],
		domainOfActivity: !exists(json, "domainOfActivity")
			? undefined
			: json["domainOfActivity"],
		fax: !exists(json, "fax") ? undefined : json["fax"],
		repName: !exists(json, "repName") ? undefined : json["repName"],
		email: !exists(json, "email") ? undefined : json["email"],
		password: !exists(json, "password") ? undefined : json["password"],
		preferredLanguage: !exists(json, "preferredLanguage")
			? undefined
			: PreferredLanguageEnumFromJSON(json["preferredLanguage"]),
	};
}

export function CompanyAddDTOToJSON(value?: CompanyAddDTO | null): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		name: value.name,
		commercialName: value.commercialName,
		cui: value.cui,
		address: value.address,
		postalCode: value.postalCode,
		phone: value.phone,
		domainOfActivity: value.domainOfActivity,
		fax: value.fax,
		repName: value.repName,
		email: value.email,
		password: value.password,
		preferredLanguage: PreferredLanguageEnumToJSON(value.preferredLanguage),
	};
}
