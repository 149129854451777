import { StudentStudyApi } from "../Api";
import { getAuthConfiguration } from "./configuration";
import { arrayParamAjust } from "../utils/dataUtils";

const AuthStudentStudyApiFactory = () =>
	new StudentStudyApi(getAuthConfiguration());

export const getStudyPrograms = (facultyIds?: Array<string>) => {
	return AuthStudentStudyApiFactory().apiStudentStudyGetStudyProgramsGet({
		facultyIds: arrayParamAjust(facultyIds),
	});
};

export const getReportStudyPrograms = () => {
	return AuthStudentStudyApiFactory().apiStudentStudyGetReportsStudyProgramsGet();
};
