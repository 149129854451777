/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
	Faculty,
	FacultyFromJSON,
	FacultyFromJSONTyped,
	FacultyToJSON,
} from "./Faculty";
import {
	StudentYearEnum,
	StudentYearEnumFromJSON,
	StudentYearEnumFromJSONTyped,
	StudentYearEnumToJSON,
} from "./StudentYearEnum";

/**
 *
 * @export
 * @interface PracticeInternshipPeriod
 */
export interface PracticeInternshipPeriod {
	/**
	 *
	 * @type {string}
	 * @memberof PracticeInternshipPeriod
	 */
	id?: string;
	/**
	 *
	 * @type {Date}
	 * @memberof PracticeInternshipPeriod
	 */
	createdAt?: Date;
	/**
	 *
	 * @type {Date}
	 * @memberof PracticeInternshipPeriod
	 */
	updatedAt?: Date;
	/**
	 *
	 * @type {Date}
	 * @memberof PracticeInternshipPeriod
	 */
	practiceEvaluationStart?: Date;
	/**
	 *
	 * @type {Date}
	 * @memberof PracticeInternshipPeriod
	 */
	practiceEvaluationEnd?: Date;
	/**
	 *
	 * @type {number}
	 * @memberof PracticeInternshipPeriod
	 */
	practiceNumberOfHours?: number;
	/**
	 *
	 * @type {StudentYearEnum}
	 * @memberof PracticeInternshipPeriod
	 */
	practiceYear?: StudentYearEnum;
	/**
	 *
	 * @type {number}
	 * @memberof PracticeInternshipPeriod
	 */
	practiceECTS?: number;
	/**
	 *
	 * @type {string}
	 * @memberof PracticeInternshipPeriod
	 */
	facultyId?: string;
	/**
	 *
	 * @type {Faculty}
	 * @memberof PracticeInternshipPeriod
	 */
	faculty?: Faculty;
}

export function PracticeInternshipPeriodFromJSON(
	json: any
): PracticeInternshipPeriod {
	return PracticeInternshipPeriodFromJSONTyped(json, false);
}

export function PracticeInternshipPeriodFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): PracticeInternshipPeriod {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		id: !exists(json, "id") ? undefined : json["id"],
		createdAt: !exists(json, "createdAt")
			? undefined
			: new Date(json["createdAt"]),
		updatedAt: !exists(json, "updatedAt")
			? undefined
			: new Date(json["updatedAt"]),
		practiceEvaluationStart: !exists(json, "practiceEvaluationStart")
			? undefined
			: new Date(json["practiceEvaluationStart"]),
		practiceEvaluationEnd: !exists(json, "practiceEvaluationEnd")
			? undefined
			: new Date(json["practiceEvaluationEnd"]),
		practiceNumberOfHours: !exists(json, "practiceNumberOfHours")
			? undefined
			: json["practiceNumberOfHours"],
		practiceYear: !exists(json, "practiceYear")
			? undefined
			: StudentYearEnumFromJSON(json["practiceYear"]),
		practiceECTS: !exists(json, "practiceECTS")
			? undefined
			: json["practiceECTS"],
		facultyId: !exists(json, "facultyId") ? undefined : json["facultyId"],
		faculty: !exists(json, "faculty")
			? undefined
			: FacultyFromJSON(json["faculty"]),
	};
}

export function PracticeInternshipPeriodToJSON(
	value?: PracticeInternshipPeriod | null
): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		id: value.id,
		createdAt:
			value.createdAt === undefined ? undefined : value.createdAt.toISOString(),
		updatedAt:
			value.updatedAt === undefined ? undefined : value.updatedAt.toISOString(),
		practiceEvaluationStart:
			value.practiceEvaluationStart === undefined
				? undefined
				: value.practiceEvaluationStart.toISOString(),
		practiceEvaluationEnd:
			value.practiceEvaluationEnd === undefined
				? undefined
				: value.practiceEvaluationEnd.toISOString(),
		practiceNumberOfHours: value.practiceNumberOfHours,
		practiceYear: StudentYearEnumToJSON(value.practiceYear),
		practiceECTS: value.practiceECTS,
		facultyId: value.facultyId,
		faculty: FacultyToJSON(value.faculty),
	};
}
