import Layout from "../../Containers/Layout";
import { CompanyContactsView } from "../Views";

export const CompanyContactsPage = (props: { location: any }) => {
	return (
		<Layout>
			<CompanyContactsView location={props.location} />
		</Layout>
	);
};
