/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum QuestionTypeEnum {
	Default = "Default",
	YesNo = "YesNo",
	LikertAccord = "LikertAccord",
	LikertSatisfaction = "LikertSatisfaction",
	OpenText = "OpenText",
	MultipleChoice = "MultipleChoice",
	SingleChoice = "SingleChoice",
}

export function QuestionTypeEnumFromJSON(json: any): QuestionTypeEnum {
	return QuestionTypeEnumFromJSONTyped(json, false);
}

export function QuestionTypeEnumFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): QuestionTypeEnum {
	return json as QuestionTypeEnum;
}

export function QuestionTypeEnumToJSON(value?: QuestionTypeEnum | null): any {
	return value as any;
}
