/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
	FacultyFeatureFlag,
	FacultyFeatureFlagFromJSON,
	FacultyFeatureFlagFromJSONTyped,
	FacultyFeatureFlagToJSON,
} from "./FacultyFeatureFlag";
import {
	FeatureFlagsCategoryEnum,
	FeatureFlagsCategoryEnumFromJSON,
	FeatureFlagsCategoryEnumFromJSONTyped,
	FeatureFlagsCategoryEnumToJSON,
} from "./FeatureFlagsCategoryEnum";
import {
	FeatureFlagsEnum,
	FeatureFlagsEnumFromJSON,
	FeatureFlagsEnumFromJSONTyped,
	FeatureFlagsEnumToJSON,
} from "./FeatureFlagsEnum";

/**
 *
 * @export
 * @interface FeatureFlags
 */
export interface FeatureFlags {
	/**
	 *
	 * @type {string}
	 * @memberof FeatureFlags
	 */
	id?: string;
	/**
	 *
	 * @type {Date}
	 * @memberof FeatureFlags
	 */
	createdAt?: Date;
	/**
	 *
	 * @type {Date}
	 * @memberof FeatureFlags
	 */
	updatedAt?: Date;
	/**
	 *
	 * @type {FeatureFlagsEnum}
	 * @memberof FeatureFlags
	 */
	featureFlag?: FeatureFlagsEnum;
	/**
	 *
	 * @type {boolean}
	 * @memberof FeatureFlags
	 */
	value?: boolean;
	/**
	 *
	 * @type {string}
	 * @memberof FeatureFlags
	 */
	description?: string | null;
	/**
	 *
	 * @type {FeatureFlagsCategoryEnum}
	 * @memberof FeatureFlags
	 */
	category?: FeatureFlagsCategoryEnum;
	/**
	 *
	 * @type {Array<FacultyFeatureFlag>}
	 * @memberof FeatureFlags
	 */
	facultyFeatureFlags?: Array<FacultyFeatureFlag> | null;
}

export function FeatureFlagsFromJSON(json: any): FeatureFlags {
	return FeatureFlagsFromJSONTyped(json, false);
}

export function FeatureFlagsFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): FeatureFlags {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		id: !exists(json, "id") ? undefined : json["id"],
		createdAt: !exists(json, "createdAt")
			? undefined
			: new Date(json["createdAt"]),
		updatedAt: !exists(json, "updatedAt")
			? undefined
			: new Date(json["updatedAt"]),
		featureFlag: !exists(json, "featureFlag")
			? undefined
			: FeatureFlagsEnumFromJSON(json["featureFlag"]),
		value: !exists(json, "value") ? undefined : json["value"],
		description: !exists(json, "description") ? undefined : json["description"],
		category: !exists(json, "category")
			? undefined
			: FeatureFlagsCategoryEnumFromJSON(json["category"]),
		facultyFeatureFlags: !exists(json, "facultyFeatureFlags")
			? undefined
			: json["facultyFeatureFlags"] === null
			? null
			: (json["facultyFeatureFlags"] as Array<any>).map(
					FacultyFeatureFlagFromJSON
			  ),
	};
}

export function FeatureFlagsToJSON(value?: FeatureFlags | null): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		id: value.id,
		createdAt:
			value.createdAt === undefined ? undefined : value.createdAt.toISOString(),
		updatedAt:
			value.updatedAt === undefined ? undefined : value.updatedAt.toISOString(),
		featureFlag: FeatureFlagsEnumToJSON(value.featureFlag),
		value: value.value,
		description: value.description,
		category: FeatureFlagsCategoryEnumToJSON(value.category),
		facultyFeatureFlags:
			value.facultyFeatureFlags === undefined
				? undefined
				: value.facultyFeatureFlags === null
				? null
				: (value.facultyFeatureFlags as Array<any>).map(
						FacultyFeatureFlagToJSON
				  ),
	};
}
