/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
	ANAFCompanyDetailsDTO,
	ANAFCompanyDetailsDTOFromJSON,
	ANAFCompanyDetailsDTOFromJSONTyped,
	ANAFCompanyDetailsDTOToJSON,
} from "./ANAFCompanyDetailsDTO";
import {
	ErrorMessage,
	ErrorMessageFromJSON,
	ErrorMessageFromJSONTyped,
	ErrorMessageToJSON,
} from "./ErrorMessage";

/**
 *
 * @export
 * @interface ANAFCompanyDetailsDTORequestResponse
 */
export interface ANAFCompanyDetailsDTORequestResponse {
	/**
	 *
	 * @type {ANAFCompanyDetailsDTO}
	 * @memberof ANAFCompanyDetailsDTORequestResponse
	 */
	response?: ANAFCompanyDetailsDTO;
	/**
	 *
	 * @type {ErrorMessage}
	 * @memberof ANAFCompanyDetailsDTORequestResponse
	 */
	errorMessage?: ErrorMessage;
}

export function ANAFCompanyDetailsDTORequestResponseFromJSON(
	json: any
): ANAFCompanyDetailsDTORequestResponse {
	return ANAFCompanyDetailsDTORequestResponseFromJSONTyped(json, false);
}

export function ANAFCompanyDetailsDTORequestResponseFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): ANAFCompanyDetailsDTORequestResponse {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		response: !exists(json, "response")
			? undefined
			: ANAFCompanyDetailsDTOFromJSON(json["response"]),
		errorMessage: !exists(json, "errorMessage")
			? undefined
			: ErrorMessageFromJSON(json["errorMessage"]),
	};
}

export function ANAFCompanyDetailsDTORequestResponseToJSON(
	value?: ANAFCompanyDetailsDTORequestResponse | null
): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		response: ANAFCompanyDetailsDTOToJSON(value.response),
		errorMessage: ErrorMessageToJSON(value.errorMessage),
	};
}
