import { message } from "antd";
import {
	InternType,
	PoliJobsProposalEnrollmentApi,
	PoliJobsProposalEnrollmentStatus,
} from "../Api";
import { PaginationQueryParams } from "../Api/models/PaginationQueryParams";
import { arrayParamAjust } from "../utils/dataUtils";
import { getAuthConfiguration } from "./configuration";

const AuthPoliJobsProposalEnrollmentApiFactory = () =>
	new PoliJobsProposalEnrollmentApi(getAuthConfiguration());

export const canApply = async (poliJobsProposalId: string) => {
	await AuthPoliJobsProposalEnrollmentApiFactory().apiPoliJobsProposalEnrollmentCanApplyPoliJobsProposalIdGet(
		{ poliJobsProposalId }
	);
};

export const applyPoliJobsProposal = async (poliJobsProposalId: string) => {
	await AuthPoliJobsProposalEnrollmentApiFactory().apiPoliJobsProposalEnrollmentApplyPoliJobsProposalIdPost(
		{ poliJobsProposalId }
	);
};

export const getAllApplicationsCount = () => {
	return AuthPoliJobsProposalEnrollmentApiFactory().apiPoliJobsProposalEnrollmentGetAllApplicationsCountGet();
};

export interface PoliJobsProposalEnrollmentProps {
	Status?: Array<PoliJobsProposalEnrollmentStatus>;
	page?: number; //page
	pageSize?: number; //pageSize
}

export const getAllApplications = (
	filters: PoliJobsProposalEnrollmentProps
) => {
	const { Status: status, page, pageSize } = filters;
	return AuthPoliJobsProposalEnrollmentApiFactory().apiPoliJobsProposalEnrollmentGetAllApplicationsGet(
		{
			status: arrayParamAjust(status),
			page,
			pageSize,
		}
	);
};

export const getApplicationsFilters = () => {
	return AuthPoliJobsProposalEnrollmentApiFactory().apiPoliJobsProposalEnrollmentGetApplicationsFiltersGet();
};

export interface PoliJobsProposalApplicantProps {
	page?: number; //page
	pageSize?: number; //pageSize
}

export const getApplicants = (
	poliJobsProposalId: string,
	internType: InternType,
	filters: PoliJobsProposalApplicantProps
) => {
	const { page, pageSize } = filters;

	return AuthPoliJobsProposalEnrollmentApiFactory().apiPoliJobsProposalEnrollmentGetApplicantsPoliJobsProposalIdGet(
		{
			poliJobsProposalId,
			internType,
			page,
			pageSize,
		}
	);
};

export const withdrawApplication = (poliJobsProposalId: string) => {
	return AuthPoliJobsProposalEnrollmentApiFactory().apiPoliJobsProposalEnrollmentWithdrawApplicationPoliJobsProposalIdPut(
		{
			poliJobsProposalId,
		}
	);
};

export const acceptApplication = (enrollmentId: string) => {
	return AuthPoliJobsProposalEnrollmentApiFactory().apiPoliJobsProposalEnrollmentAcceptApplicationEnrollmentIdPut(
		{
			enrollmentId,
		}
	);
};

export const rejectApplication = (enrollmentId: string) => {
	return AuthPoliJobsProposalEnrollmentApiFactory().apiPoliJobsProposalEnrollmentRejectApplicationEnrollmentIdPut(
		{
			enrollmentId,
		}
	);
};

export const rejectCV = (enrollmentId: string, message: string) => {
	return AuthPoliJobsProposalEnrollmentApiFactory().apiPoliJobsProposalEnrollmentRejectCVEnrollmentIdPut(
		{
			enrollmentId,
			message,
		}
	);
};

export const editNotes = (enrollmentId: string, notes: string) => {
	return AuthPoliJobsProposalEnrollmentApiFactory().apiPoliJobsProposalEnrollmentEditNotesEnrollmentIdPut(
		{
			enrollmentId,
			notes,
		}
	);
};
