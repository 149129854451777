/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
	NotificationType,
	NotificationTypeFromJSON,
	NotificationTypeFromJSONTyped,
	NotificationTypeToJSON,
} from "./NotificationType";

/**
 *
 * @export
 * @interface NotificationDTO
 */
export interface NotificationDTO {
	/**
	 *
	 * @type {string}
	 * @memberof NotificationDTO
	 */
	readonly id?: string;
	/**
	 *
	 * @type {string}
	 * @memberof NotificationDTO
	 */
	readonly entityId?: string;
	/**
	 *
	 * @type {string}
	 * @memberof NotificationDTO
	 */
	readonly recipientId?: string;
	/**
	 *
	 * @type {NotificationType}
	 * @memberof NotificationDTO
	 */
	type?: NotificationType;
	/**
	 *
	 * @type {string}
	 * @memberof NotificationDTO
	 */
	readonly issuerName?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof NotificationDTO
	 */
	readonly issuerAction?: string | null;
	/**
	 *
	 * @type {Date}
	 * @memberof NotificationDTO
	 */
	readonly createdDate?: Date;
}

export function NotificationDTOFromJSON(json: any): NotificationDTO {
	return NotificationDTOFromJSONTyped(json, false);
}

export function NotificationDTOFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): NotificationDTO {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		id: !exists(json, "id") ? undefined : json["id"],
		entityId: !exists(json, "entityId") ? undefined : json["entityId"],
		recipientId: !exists(json, "recipientId") ? undefined : json["recipientId"],
		type: !exists(json, "type")
			? undefined
			: NotificationTypeFromJSON(json["type"]),
		issuerName: !exists(json, "issuerName") ? undefined : json["issuerName"],
		issuerAction: !exists(json, "issuerAction")
			? undefined
			: json["issuerAction"],
		createdDate: !exists(json, "createdDate")
			? undefined
			: new Date(json["createdDate"]),
	};
}

export function NotificationDTOToJSON(value?: NotificationDTO | null): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		type: NotificationTypeToJSON(value.type),
	};
}
