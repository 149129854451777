/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
	CompanyReportDTOPagedResponse,
	CompanyReportDTOPagedResponseFromJSON,
	CompanyReportDTOPagedResponseToJSON,
	PhdReportDTOPagedResponse,
	PhdReportDTOPagedResponseFromJSON,
	PhdReportDTOPagedResponseToJSON,
	ProfessorsReportDTOPagedResponse,
	ProfessorsReportDTOPagedResponseFromJSON,
	ProfessorsReportDTOPagedResponseToJSON,
	StudentYearEnum,
	StudentYearEnumFromJSON,
	StudentYearEnumToJSON,
	StudentsReportDTOPagedResponse,
	StudentsReportDTOPagedResponseFromJSON,
	StudentsReportDTOPagedResponseToJSON,
} from "../models";

export interface ApiFeedbackReportsGetCompanyAnswersGetRequest {
	reportYear?: number;
	page?: number;
	pageSize?: number;
}

export interface ApiFeedbackReportsGetPhdAnswersGetRequest {
	facultyIds?: Array<string>;
	reportYear?: number;
	page?: number;
	pageSize?: number;
}

export interface ApiFeedbackReportsGetProfessorsAnswersGetRequest {
	facultyIds?: Array<string>;
	reportYear?: number;
	departmentIds?: Array<string>;
	page?: number;
	pageSize?: number;
}

export interface ApiFeedbackReportsGetStudentsAnswersGetRequest {
	facultyIds?: Array<string>;
	years?: Array<StudentYearEnum>;
	studyProgramIds?: Array<string>;
	reportYear?: number;
	page?: number;
	pageSize?: number;
}

/**
 *
 */
export class FeedbackReportsApi extends runtime.BaseAPI {
	/**
	 */
	async apiFeedbackReportsGetCompanyAnswersGetRaw(
		requestParameters: ApiFeedbackReportsGetCompanyAnswersGetRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<CompanyReportDTOPagedResponse>> {
		const queryParameters: any = {};

		if (requestParameters.reportYear !== undefined) {
			queryParameters["ReportYear"] = requestParameters.reportYear;
		}

		if (requestParameters.page !== undefined) {
			queryParameters["Page"] = requestParameters.page;
		}

		if (requestParameters.pageSize !== undefined) {
			queryParameters["PageSize"] = requestParameters.pageSize;
		}

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/FeedbackReports/GetCompanyAnswers`,
				method: "GET",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			CompanyReportDTOPagedResponseFromJSON(jsonValue)
		);
	}

	/**
	 */
	async apiFeedbackReportsGetCompanyAnswersGet(
		requestParameters: ApiFeedbackReportsGetCompanyAnswersGetRequest = {},
		initOverrides?: RequestInit
	): Promise<CompanyReportDTOPagedResponse> {
		const response = await this.apiFeedbackReportsGetCompanyAnswersGetRaw(
			requestParameters,
			initOverrides
		);
		return await response.value();
	}

	/**
	 */
	async apiFeedbackReportsGetPhdAnswersGetRaw(
		requestParameters: ApiFeedbackReportsGetPhdAnswersGetRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<PhdReportDTOPagedResponse>> {
		const queryParameters: any = {};

		if (requestParameters.facultyIds) {
			queryParameters["FacultyIds"] = requestParameters.facultyIds;
		}

		if (requestParameters.reportYear !== undefined) {
			queryParameters["ReportYear"] = requestParameters.reportYear;
		}

		if (requestParameters.page !== undefined) {
			queryParameters["Page"] = requestParameters.page;
		}

		if (requestParameters.pageSize !== undefined) {
			queryParameters["PageSize"] = requestParameters.pageSize;
		}

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/FeedbackReports/GetPhdAnswers`,
				method: "GET",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			PhdReportDTOPagedResponseFromJSON(jsonValue)
		);
	}

	/**
	 */
	async apiFeedbackReportsGetPhdAnswersGet(
		requestParameters: ApiFeedbackReportsGetPhdAnswersGetRequest = {},
		initOverrides?: RequestInit
	): Promise<PhdReportDTOPagedResponse> {
		const response = await this.apiFeedbackReportsGetPhdAnswersGetRaw(
			requestParameters,
			initOverrides
		);
		return await response.value();
	}

	/**
	 */
	async apiFeedbackReportsGetProfessorsAnswersGetRaw(
		requestParameters: ApiFeedbackReportsGetProfessorsAnswersGetRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<ProfessorsReportDTOPagedResponse>> {
		const queryParameters: any = {};

		if (requestParameters.facultyIds) {
			queryParameters["FacultyIds"] = requestParameters.facultyIds;
		}

		if (requestParameters.reportYear !== undefined) {
			queryParameters["ReportYear"] = requestParameters.reportYear;
		}

		if (requestParameters.departmentIds) {
			queryParameters["DepartmentIds"] = requestParameters.departmentIds;
		}

		if (requestParameters.page !== undefined) {
			queryParameters["Page"] = requestParameters.page;
		}

		if (requestParameters.pageSize !== undefined) {
			queryParameters["PageSize"] = requestParameters.pageSize;
		}

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/FeedbackReports/GetProfessorsAnswers`,
				method: "GET",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			ProfessorsReportDTOPagedResponseFromJSON(jsonValue)
		);
	}

	/**
	 */
	async apiFeedbackReportsGetProfessorsAnswersGet(
		requestParameters: ApiFeedbackReportsGetProfessorsAnswersGetRequest = {},
		initOverrides?: RequestInit
	): Promise<ProfessorsReportDTOPagedResponse> {
		const response = await this.apiFeedbackReportsGetProfessorsAnswersGetRaw(
			requestParameters,
			initOverrides
		);
		return await response.value();
	}

	/**
	 */
	async apiFeedbackReportsGetStudentsAnswersGetRaw(
		requestParameters: ApiFeedbackReportsGetStudentsAnswersGetRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<StudentsReportDTOPagedResponse>> {
		const queryParameters: any = {};

		if (requestParameters.facultyIds) {
			queryParameters["FacultyIds"] = requestParameters.facultyIds;
		}

		if (requestParameters.years) {
			queryParameters["Years"] = requestParameters.years;
		}

		if (requestParameters.studyProgramIds) {
			queryParameters["StudyProgramIds"] = requestParameters.studyProgramIds;
		}

		if (requestParameters.reportYear !== undefined) {
			queryParameters["ReportYear"] = requestParameters.reportYear;
		}

		if (requestParameters.page !== undefined) {
			queryParameters["Page"] = requestParameters.page;
		}

		if (requestParameters.pageSize !== undefined) {
			queryParameters["PageSize"] = requestParameters.pageSize;
		}

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/FeedbackReports/GetStudentsAnswers`,
				method: "GET",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			StudentsReportDTOPagedResponseFromJSON(jsonValue)
		);
	}

	/**
	 */
	async apiFeedbackReportsGetStudentsAnswersGet(
		requestParameters: ApiFeedbackReportsGetStudentsAnswersGetRequest = {},
		initOverrides?: RequestInit
	): Promise<StudentsReportDTOPagedResponse> {
		const response = await this.apiFeedbackReportsGetStudentsAnswersGetRaw(
			requestParameters,
			initOverrides
		);
		return await response.value();
	}
}
