/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
	AcademicUser,
	AcademicUserFromJSON,
	AcademicUserFromJSONTyped,
	AcademicUserToJSON,
} from "./AcademicUser";
import {
	PoliJobsProposal,
	PoliJobsProposalFromJSON,
	PoliJobsProposalFromJSONTyped,
	PoliJobsProposalToJSON,
} from "./PoliJobsProposal";
import {
	PoliJobsProposalEnrollmentStatus,
	PoliJobsProposalEnrollmentStatusFromJSON,
	PoliJobsProposalEnrollmentStatusFromJSONTyped,
	PoliJobsProposalEnrollmentStatusToJSON,
} from "./PoliJobsProposalEnrollmentStatus";

/**
 *
 * @export
 * @interface PoliJobsProposalEnrollment
 */
export interface PoliJobsProposalEnrollment {
	/**
	 *
	 * @type {string}
	 * @memberof PoliJobsProposalEnrollment
	 */
	id?: string;
	/**
	 *
	 * @type {Date}
	 * @memberof PoliJobsProposalEnrollment
	 */
	createdAt?: Date;
	/**
	 *
	 * @type {Date}
	 * @memberof PoliJobsProposalEnrollment
	 */
	updatedAt?: Date;
	/**
	 *
	 * @type {string}
	 * @memberof PoliJobsProposalEnrollment
	 */
	poliJobsProposalId?: string;
	/**
	 *
	 * @type {string}
	 * @memberof PoliJobsProposalEnrollment
	 */
	studentId?: string;
	/**
	 *
	 * @type {PoliJobsProposal}
	 * @memberof PoliJobsProposalEnrollment
	 */
	poliJobsProposal?: PoliJobsProposal;
	/**
	 *
	 * @type {AcademicUser}
	 * @memberof PoliJobsProposalEnrollment
	 */
	student?: AcademicUser;
	/**
	 *
	 * @type {PoliJobsProposalEnrollmentStatus}
	 * @memberof PoliJobsProposalEnrollment
	 */
	enrollmentStatus?: PoliJobsProposalEnrollmentStatus;
	/**
	 *
	 * @type {string}
	 * @memberof PoliJobsProposalEnrollment
	 */
	notes?: string | null;
}

export function PoliJobsProposalEnrollmentFromJSON(
	json: any
): PoliJobsProposalEnrollment {
	return PoliJobsProposalEnrollmentFromJSONTyped(json, false);
}

export function PoliJobsProposalEnrollmentFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): PoliJobsProposalEnrollment {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		id: !exists(json, "id") ? undefined : json["id"],
		createdAt: !exists(json, "createdAt")
			? undefined
			: new Date(json["createdAt"]),
		updatedAt: !exists(json, "updatedAt")
			? undefined
			: new Date(json["updatedAt"]),
		poliJobsProposalId: !exists(json, "poliJobsProposalId")
			? undefined
			: json["poliJobsProposalId"],
		studentId: !exists(json, "studentId") ? undefined : json["studentId"],
		poliJobsProposal: !exists(json, "poliJobsProposal")
			? undefined
			: PoliJobsProposalFromJSON(json["poliJobsProposal"]),
		student: !exists(json, "student")
			? undefined
			: AcademicUserFromJSON(json["student"]),
		enrollmentStatus: !exists(json, "enrollmentStatus")
			? undefined
			: PoliJobsProposalEnrollmentStatusFromJSON(json["enrollmentStatus"]),
		notes: !exists(json, "notes") ? undefined : json["notes"],
	};
}

export function PoliJobsProposalEnrollmentToJSON(
	value?: PoliJobsProposalEnrollment | null
): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		id: value.id,
		createdAt:
			value.createdAt === undefined ? undefined : value.createdAt.toISOString(),
		updatedAt:
			value.updatedAt === undefined ? undefined : value.updatedAt.toISOString(),
		poliJobsProposalId: value.poliJobsProposalId,
		studentId: value.studentId,
		poliJobsProposal: PoliJobsProposalToJSON(value.poliJobsProposal),
		student: AcademicUserToJSON(value.student),
		enrollmentStatus: PoliJobsProposalEnrollmentStatusToJSON(
			value.enrollmentStatus
		),
		notes: value.notes,
	};
}
