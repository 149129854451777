/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
	StudentsReportDTO,
	StudentsReportDTOFromJSON,
	StudentsReportDTOFromJSONTyped,
	StudentsReportDTOToJSON,
} from "./StudentsReportDTO";

/**
 *
 * @export
 * @interface StudentsReportDTOPagedResponse
 */
export interface StudentsReportDTOPagedResponse {
	/**
	 *
	 * @type {number}
	 * @memberof StudentsReportDTOPagedResponse
	 */
	page?: number;
	/**
	 *
	 * @type {number}
	 * @memberof StudentsReportDTOPagedResponse
	 */
	pageSize?: number;
	/**
	 *
	 * @type {number}
	 * @memberof StudentsReportDTOPagedResponse
	 */
	totalCount?: number;
	/**
	 *
	 * @type {Array<StudentsReportDTO>}
	 * @memberof StudentsReportDTOPagedResponse
	 */
	data?: Array<StudentsReportDTO> | null;
}

export function StudentsReportDTOPagedResponseFromJSON(
	json: any
): StudentsReportDTOPagedResponse {
	return StudentsReportDTOPagedResponseFromJSONTyped(json, false);
}

export function StudentsReportDTOPagedResponseFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): StudentsReportDTOPagedResponse {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		page: !exists(json, "page") ? undefined : json["page"],
		pageSize: !exists(json, "pageSize") ? undefined : json["pageSize"],
		totalCount: !exists(json, "totalCount") ? undefined : json["totalCount"],
		data: !exists(json, "data")
			? undefined
			: json["data"] === null
			? null
			: (json["data"] as Array<any>).map(StudentsReportDTOFromJSON),
	};
}

export function StudentsReportDTOPagedResponseToJSON(
	value?: StudentsReportDTOPagedResponse | null
): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		page: value.page,
		pageSize: value.pageSize,
		totalCount: value.totalCount,
		data:
			value.data === undefined
				? undefined
				: value.data === null
				? null
				: (value.data as Array<any>).map(StudentsReportDTOToJSON),
	};
}
