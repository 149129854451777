/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
	PracticalActivityDTO,
	PracticalActivityDTOFromJSON,
	PracticalActivityDTOFromJSONTyped,
	PracticalActivityDTOToJSON,
} from "./PracticalActivityDTO";
import {
	PracticeNotebookState,
	PracticeNotebookStateFromJSON,
	PracticeNotebookStateFromJSONTyped,
	PracticeNotebookStateToJSON,
} from "./PracticeNotebookState";

/**
 *
 * @export
 * @interface PracticeNotebookDTO
 */
export interface PracticeNotebookDTO {
	/**
	 *
	 * @type {string}
	 * @memberof PracticeNotebookDTO
	 */
	id?: string;
	/**
	 *
	 * @type {string}
	 * @memberof PracticeNotebookDTO
	 */
	studentName?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof PracticeNotebookDTO
	 */
	researchField?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof PracticeNotebookDTO
	 */
	title?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof PracticeNotebookDTO
	 */
	recruiterName?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof PracticeNotebookDTO
	 */
	location?: string | null;
	/**
	 *
	 * @type {Date}
	 * @memberof PracticeNotebookDTO
	 */
	startDate?: Date;
	/**
	 *
	 * @type {Date}
	 * @memberof PracticeNotebookDTO
	 */
	endDate?: Date;
	/**
	 *
	 * @type {Array<PracticalActivityDTO>}
	 * @memberof PracticeNotebookDTO
	 */
	practicalActivities: Array<PracticalActivityDTO>;
	/**
	 *
	 * @type {PracticeNotebookState}
	 * @memberof PracticeNotebookDTO
	 */
	state?: PracticeNotebookState;
	/**
	 *
	 * @type {number}
	 * @memberof PracticeNotebookDTO
	 */
	totalHours?: number;
}

export function PracticeNotebookDTOFromJSON(json: any): PracticeNotebookDTO {
	return PracticeNotebookDTOFromJSONTyped(json, false);
}

export function PracticeNotebookDTOFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): PracticeNotebookDTO {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		id: !exists(json, "id") ? undefined : json["id"],
		studentName: !exists(json, "studentName") ? undefined : json["studentName"],
		researchField: !exists(json, "researchField")
			? undefined
			: json["researchField"],
		title: !exists(json, "title") ? undefined : json["title"],
		recruiterName: !exists(json, "recruiterName")
			? undefined
			: json["recruiterName"],
		location: !exists(json, "location") ? undefined : json["location"],
		startDate: !exists(json, "startDate")
			? undefined
			: new Date(json["startDate"]),
		endDate: !exists(json, "endDate") ? undefined : new Date(json["endDate"]),
		practicalActivities: (json["practicalActivities"] as Array<any>).map(
			PracticalActivityDTOFromJSON
		),
		state: !exists(json, "state")
			? undefined
			: PracticeNotebookStateFromJSON(json["state"]),
		totalHours: !exists(json, "totalHours") ? undefined : json["totalHours"],
	};
}

export function PracticeNotebookDTOToJSON(
	value?: PracticeNotebookDTO | null
): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		id: value.id,
		studentName: value.studentName,
		researchField: value.researchField,
		title: value.title,
		recruiterName: value.recruiterName,
		location: value.location,
		startDate:
			value.startDate === undefined ? undefined : value.startDate.toISOString(),
		endDate:
			value.endDate === undefined ? undefined : value.endDate.toISOString(),
		practicalActivities: (value.practicalActivities as Array<any>).map(
			PracticalActivityDTOToJSON
		),
		state: PracticeNotebookStateToJSON(value.state),
		totalHours: value.totalHours,
	};
}
