/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import { Role, RoleFromJSON, RoleFromJSONTyped, RoleToJSON } from "./Role";
import {
	StudentYearEnum,
	StudentYearEnumFromJSON,
	StudentYearEnumFromJSONTyped,
	StudentYearEnumToJSON,
} from "./StudentYearEnum";
import {
	UserSkillDTO,
	UserSkillDTOFromJSON,
	UserSkillDTOFromJSONTyped,
	UserSkillDTOToJSON,
} from "./UserSkillDTO";

/**
 *
 * @export
 * @interface ExtendedViewStudentDTO
 */
export interface ExtendedViewStudentDTO {
	/**
	 *
	 * @type {string}
	 * @memberof ExtendedViewStudentDTO
	 */
	readonly id?: string;
	/**
	 *
	 * @type {string}
	 * @memberof ExtendedViewStudentDTO
	 */
	readonly name?: string | null;
	/**
	 *
	 * @type {number}
	 * @memberof ExtendedViewStudentDTO
	 */
	readonly cursId?: number;
	/**
	 *
	 * @type {string}
	 * @memberof ExtendedViewStudentDTO
	 */
	readonly email?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof ExtendedViewStudentDTO
	 */
	readonly personalEmail?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof ExtendedViewStudentDTO
	 */
	readonly description?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof ExtendedViewStudentDTO
	 */
	readonly phone?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof ExtendedViewStudentDTO
	 */
	readonly cvUrl?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof ExtendedViewStudentDTO
	 */
	readonly cvFileName?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof ExtendedViewStudentDTO
	 */
	readonly linkedInUrl?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof ExtendedViewStudentDTO
	 */
	readonly gitUrl?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof ExtendedViewStudentDTO
	 */
	lowResImage?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof ExtendedViewStudentDTO
	 */
	fullResImage?: string | null;
	/**
	 *
	 * @type {Array<Role>}
	 * @memberof ExtendedViewStudentDTO
	 */
	readonly roles?: Array<Role> | null;
	/**
	 *
	 * @type {string}
	 * @memberof ExtendedViewStudentDTO
	 */
	readonly departmentNameRo?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof ExtendedViewStudentDTO
	 */
	readonly departmentNameEn?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof ExtendedViewStudentDTO
	 */
	readonly facultyId?: string;
	/**
	 *
	 * @type {string}
	 * @memberof ExtendedViewStudentDTO
	 */
	readonly facultyNameRo?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof ExtendedViewStudentDTO
	 */
	readonly facultyNameEn?: string | null;
	/**
	 *
	 * @type {StudentYearEnum}
	 * @memberof ExtendedViewStudentDTO
	 */
	year?: StudentYearEnum;
	/**
	 *
	 * @type {string}
	 * @memberof ExtendedViewStudentDTO
	 */
	readonly group?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof ExtendedViewStudentDTO
	 */
	readonly studentPersonalDataId?: string | null;
	/**
	 *
	 * @type {Array<UserSkillDTO>}
	 * @memberof ExtendedViewStudentDTO
	 */
	readonly userSkills?: Array<UserSkillDTO> | null;
	/**
	 *
	 * @type {boolean}
	 * @memberof ExtendedViewStudentDTO
	 */
	readonly hasAcceptedPublicProfile?: boolean;
	/**
	 *
	 * @type {boolean}
	 * @memberof ExtendedViewStudentDTO
	 */
	readonly sendNotifications?: boolean;
}

export function ExtendedViewStudentDTOFromJSON(
	json: any
): ExtendedViewStudentDTO {
	return ExtendedViewStudentDTOFromJSONTyped(json, false);
}

export function ExtendedViewStudentDTOFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): ExtendedViewStudentDTO {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		id: !exists(json, "id") ? undefined : json["id"],
		name: !exists(json, "name") ? undefined : json["name"],
		cursId: !exists(json, "cursId") ? undefined : json["cursId"],
		email: !exists(json, "email") ? undefined : json["email"],
		personalEmail: !exists(json, "personalEmail")
			? undefined
			: json["personalEmail"],
		description: !exists(json, "description") ? undefined : json["description"],
		phone: !exists(json, "phone") ? undefined : json["phone"],
		cvUrl: !exists(json, "cvUrl") ? undefined : json["cvUrl"],
		cvFileName: !exists(json, "cvFileName") ? undefined : json["cvFileName"],
		linkedInUrl: !exists(json, "linkedInUrl") ? undefined : json["linkedInUrl"],
		gitUrl: !exists(json, "gitUrl") ? undefined : json["gitUrl"],
		lowResImage: !exists(json, "lowResImage") ? undefined : json["lowResImage"],
		fullResImage: !exists(json, "fullResImage")
			? undefined
			: json["fullResImage"],
		roles: !exists(json, "roles")
			? undefined
			: json["roles"] === null
			? null
			: (json["roles"] as Array<any>).map(RoleFromJSON),
		departmentNameRo: !exists(json, "departmentNameRo")
			? undefined
			: json["departmentNameRo"],
		departmentNameEn: !exists(json, "departmentNameEn")
			? undefined
			: json["departmentNameEn"],
		facultyId: !exists(json, "facultyId") ? undefined : json["facultyId"],
		facultyNameRo: !exists(json, "facultyNameRo")
			? undefined
			: json["facultyNameRo"],
		facultyNameEn: !exists(json, "facultyNameEn")
			? undefined
			: json["facultyNameEn"],
		year: !exists(json, "year")
			? undefined
			: StudentYearEnumFromJSON(json["year"]),
		group: !exists(json, "group") ? undefined : json["group"],
		studentPersonalDataId: !exists(json, "studentPersonalDataId")
			? undefined
			: json["studentPersonalDataId"],
		userSkills: !exists(json, "userSkills")
			? undefined
			: json["userSkills"] === null
			? null
			: (json["userSkills"] as Array<any>).map(UserSkillDTOFromJSON),
		hasAcceptedPublicProfile: !exists(json, "hasAcceptedPublicProfile")
			? undefined
			: json["hasAcceptedPublicProfile"],
		sendNotifications: !exists(json, "sendNotifications")
			? undefined
			: json["sendNotifications"],
	};
}

export function ExtendedViewStudentDTOToJSON(
	value?: ExtendedViewStudentDTO | null
): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		lowResImage: value.lowResImage,
		fullResImage: value.fullResImage,
		year: StudentYearEnumToJSON(value.year),
	};
}
