import { ResearchProposalApi, ResearchProposalEnrollmentStatus } from "../Api";
import { getAuthConfiguration } from "./configuration";

const AuthResearchProposalApiFactory = () =>
	new ResearchProposalApi(getAuthConfiguration());

interface ResearchPartnersForCompanyOrProfessorProps {
	namePattern?: string;
	page?: number;
	pageSize?: number;
	enrollmentStatus?: Array<ResearchProposalEnrollmentStatus>;
	researchProposals?: Array<string>;
	viewStatuses?: Array<string>;
}

export const getResearchPartnersForCompanyOrProfessorV2 = async (
	props: ResearchPartnersForCompanyOrProfessorProps
) => {
	const {
		namePattern,
		page,
		pageSize,
		enrollmentStatus,
		researchProposals,
		viewStatuses,
	} = props;
	return await AuthResearchProposalApiFactory().apiResearchProposalGetPartnersForCompanyOrProfessorGet(
		{
			namePattern,
			researchProposals,
			enrollmentStatus,
			viewStatuses,
			page,
			pageSize,
		}
	);
};

export const getResearchPartnersForCompanyOrProfessor = async (
	namePattern?: string,
	page?: number,
	pageSize?: number,
	enrollmentStatus?: Array<ResearchProposalEnrollmentStatus>,
	researchProposals?: Array<string>,
	viewStatuses?: Array<string>
) => {
	return await AuthResearchProposalApiFactory().apiResearchProposalGetPartnersForCompanyOrProfessorGet(
		{
			namePattern,
			researchProposals,
			enrollmentStatus,
			viewStatuses,
			page,
			pageSize,
		}
	);
};

interface MyApplicationsProps {
	namePattern?: string;
	page?: number;
	pageSize?: number;
	enrollmentStatus?: Array<ResearchProposalEnrollmentStatus>;
	researchProposals?: Array<string>;
	viewStatuses?: Array<string>;
}

export const getMyApplicationsV2 = async (props: MyApplicationsProps) => {
	const {
		namePattern,
		page,
		pageSize,
		enrollmentStatus,
		researchProposals,
		viewStatuses,
	} = props;

	return await AuthResearchProposalApiFactory().apiResearchProposalGetAcceptedApplicationsForCompanyOrProfessorGet(
		{
			namePattern,
			researchProposals,
			enrollmentStatus,
			viewStatuses,
			page,
			pageSize,
		}
	);
};

export const getMyApplications = async (
	namePattern?: string,
	page?: number,
	pageSize?: number,
	enrollmentStatus?: Array<ResearchProposalEnrollmentStatus>,
	researchProposals?: Array<string>,
	viewStatuses?: Array<string>
) => {
	return await AuthResearchProposalApiFactory().apiResearchProposalGetAcceptedApplicationsForCompanyOrProfessorGet(
		{
			namePattern,
			researchProposals,
			enrollmentStatus,
			viewStatuses,
			page,
			pageSize,
		}
	);
};

export const canApplyProfessor = async (researchProposalId: string) => {
	await AuthResearchProposalApiFactory().apiResearchProposalCanApplyProfessorResearchProposalIdGet(
		{ researchProposalId }
	);
};

export const canApplyCompany = async (researchProposalId: string) => {
	await AuthResearchProposalApiFactory().apiResearchProposalCanApplyCompanyResearchProposalIdGet(
		{ researchProposalId }
	);
};

export const applyResearchProposal = async (researchProposalId: string) => {
	await AuthResearchProposalApiFactory().apiResearchProposalApplyResearchProposalIdPost(
		{ researchProposalId }
	);
};

export const acceptApplicant = async (researchProposalEnrollmentId: string) => {
	await AuthResearchProposalApiFactory().apiResearchProposalAcceptApplicationPost(
		{ researchProposalAcceptDTO: { researchProposalEnrollmentId } }
	);
};

export const rejectApplicant = async (researchProposalEnrollmentId: string) => {
	await AuthResearchProposalApiFactory().apiResearchProposalRejectApplicationPost(
		{ researchProposalAcceptDTO: { researchProposalEnrollmentId } }
	);
};

export const updateIsResearchProposalEnrollmentViewed = (
	id: string,
	isEnrollmentView: boolean
) => {
	return AuthResearchProposalApiFactory().apiResearchProposalUpdateIsEnrollmentViewedIdPut(
		{ id, isEnrollmentView }
	);
};
