/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
	NotificationDTOPagedResponse,
	NotificationDTOPagedResponseFromJSON,
	NotificationDTOPagedResponseToJSON,
} from "../models";

export interface ApiNotificationDeleteIdDeleteRequest {
	id: string;
}

export interface ApiNotificationGetForUserGetRequest {
	page?: number;
	pageSize?: number;
}

/**
 *
 */
export class NotificationApi extends runtime.BaseAPI {
	/**
	 */
	async apiNotificationDeleteAllDeleteRaw(
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<void>> {
		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/Notification/DeleteAll`,
				method: "DELETE",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.VoidApiResponse(response);
	}

	/**
	 */
	async apiNotificationDeleteAllDelete(
		initOverrides?: RequestInit
	): Promise<void> {
		await this.apiNotificationDeleteAllDeleteRaw(initOverrides);
	}

	/**
	 */
	async apiNotificationDeleteIdDeleteRaw(
		requestParameters: ApiNotificationDeleteIdDeleteRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<void>> {
		if (requestParameters.id === null || requestParameters.id === undefined) {
			throw new runtime.RequiredError(
				"id",
				"Required parameter requestParameters.id was null or undefined when calling apiNotificationDeleteIdDelete."
			);
		}

		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/Notification/Delete/{id}`.replace(
					`{${"id"}}`,
					encodeURIComponent(String(requestParameters.id))
				),
				method: "DELETE",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.VoidApiResponse(response);
	}

	/**
	 */
	async apiNotificationDeleteIdDelete(
		requestParameters: ApiNotificationDeleteIdDeleteRequest,
		initOverrides?: RequestInit
	): Promise<void> {
		await this.apiNotificationDeleteIdDeleteRaw(
			requestParameters,
			initOverrides
		);
	}

	/**
	 */
	async apiNotificationGetCountNotificationForUserGetRaw(
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<number>> {
		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/Notification/GetCountNotificationForUser`,
				method: "GET",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.TextApiResponse(response) as any;
	}

	/**
	 */
	async apiNotificationGetCountNotificationForUserGet(
		initOverrides?: RequestInit
	): Promise<number> {
		const response =
			await this.apiNotificationGetCountNotificationForUserGetRaw(
				initOverrides
			);
		return await response.value();
	}

	/**
	 */
	async apiNotificationGetForUserGetRaw(
		requestParameters: ApiNotificationGetForUserGetRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<NotificationDTOPagedResponse>> {
		const queryParameters: any = {};

		if (requestParameters.page !== undefined) {
			queryParameters["Page"] = requestParameters.page;
		}

		if (requestParameters.pageSize !== undefined) {
			queryParameters["PageSize"] = requestParameters.pageSize;
		}

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/Notification/GetForUser`,
				method: "GET",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			NotificationDTOPagedResponseFromJSON(jsonValue)
		);
	}

	/**
	 */
	async apiNotificationGetForUserGet(
		requestParameters: ApiNotificationGetForUserGetRequest = {},
		initOverrides?: RequestInit
	): Promise<NotificationDTOPagedResponse> {
		const response = await this.apiNotificationGetForUserGetRaw(
			requestParameters,
			initOverrides
		);
		return await response.value();
	}
}
