/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum ResearchProposalEnrollmentStatus {
	WaitingForProposer = "WaitingForProposer",
	Accepted = "Accepted",
	Rejected = "Rejected",
}

export function ResearchProposalEnrollmentStatusFromJSON(
	json: any
): ResearchProposalEnrollmentStatus {
	return ResearchProposalEnrollmentStatusFromJSONTyped(json, false);
}

export function ResearchProposalEnrollmentStatusFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): ResearchProposalEnrollmentStatus {
	return json as ResearchProposalEnrollmentStatus;
}

export function ResearchProposalEnrollmentStatusToJSON(
	value?: ResearchProposalEnrollmentStatus | null
): any {
	return value as any;
}
