/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
	CompanyInternshipState,
	CompanyInternshipStateFromJSON,
	CompanyInternshipStateToJSON,
	EnrollmentStatus,
	EnrollmentStatusFromJSON,
	EnrollmentStatusToJSON,
	Filter,
	FilterFromJSON,
	FilterToJSON,
	InternshipDTO,
	InternshipDTOFromJSON,
	InternshipDTOToJSON,
	InternshipDTOPagedResponse,
	InternshipDTOPagedResponseFromJSON,
	InternshipDTOPagedResponseToJSON,
	InternshipEngagementType,
	InternshipEngagementTypeFromJSON,
	InternshipEngagementTypeToJSON,
	InternshipSortFieldEnum,
	InternshipSortFieldEnumFromJSON,
	InternshipSortFieldEnumToJSON,
	InternshipStatus,
	InternshipStatusFromJSON,
	InternshipStatusToJSON,
	ProfessorInternshipAddUpdateDTO,
	ProfessorInternshipAddUpdateDTOFromJSON,
	ProfessorInternshipAddUpdateDTOToJSON,
	SortDirectionEnum,
	SortDirectionEnumFromJSON,
	SortDirectionEnumToJSON,
} from "../models";

export interface ApiProfessorInternshipAddPostRequest {
	professorInternshipAddUpdateDTO?: ProfessorInternshipAddUpdateDTO;
}

export interface ApiProfessorInternshipChangeStudyProgramIdPutRequest {
	id: string;
	requestBody?: Array<string>;
}

export interface ApiProfessorInternshipChangeValidStatusPostRequest {
	internshipId?: string;
	status?: boolean;
}

export interface ApiProfessorInternshipDeleteIdDeleteRequest {
	id: string;
}

export interface ApiProfessorInternshipDuplicateIdPostRequest {
	id: string;
}

export interface ApiProfessorInternshipGetAllAdminGetRequest {
	companyId?: string;
	professorId?: string;
	skills?: Array<string>;
	categories?: Array<string>;
	types?: Array<InternshipEngagementType>;
	locations?: Array<string>;
	statuses?: Array<InternshipStatus>;
	states?: Array<CompanyInternshipState>;
	applicabilityTypes?: Array<boolean>;
	validationStates?: Array<boolean>;
	universityYears?: Array<number>;
	searchTerm?: string;
	sortField?: InternshipSortFieldEnum;
	sortDirection?: SortDirectionEnum;
	facultyCoordinatorId?: string;
	enableSearchByFacultyCoordinator?: boolean;
	studentStatus?: Array<EnrollmentStatus>;
	recruiterStatus?: Array<EnrollmentStatus>;
	page?: number;
	pageSize?: number;
}

export interface ApiProfessorInternshipGetAllForDepartmentGetRequest {
	companyId?: string;
	professorId?: string;
	skills?: Array<string>;
	categories?: Array<string>;
	types?: Array<InternshipEngagementType>;
	locations?: Array<string>;
	statuses?: Array<InternshipStatus>;
	states?: Array<CompanyInternshipState>;
	applicabilityTypes?: Array<boolean>;
	validationStates?: Array<boolean>;
	universityYears?: Array<number>;
	searchTerm?: string;
	sortField?: InternshipSortFieldEnum;
	sortDirection?: SortDirectionEnum;
	facultyCoordinatorId?: string;
	enableSearchByFacultyCoordinator?: boolean;
	studentStatus?: Array<EnrollmentStatus>;
	recruiterStatus?: Array<EnrollmentStatus>;
	page?: number;
	pageSize?: number;
}

export interface ApiProfessorInternshipGetAllForFacultyGetRequest {
	companyId?: string;
	professorId?: string;
	skills?: Array<string>;
	categories?: Array<string>;
	types?: Array<InternshipEngagementType>;
	locations?: Array<string>;
	statuses?: Array<InternshipStatus>;
	states?: Array<CompanyInternshipState>;
	applicabilityTypes?: Array<boolean>;
	validationStates?: Array<boolean>;
	universityYears?: Array<number>;
	searchTerm?: string;
	sortField?: InternshipSortFieldEnum;
	sortDirection?: SortDirectionEnum;
	facultyCoordinatorId?: string;
	enableSearchByFacultyCoordinator?: boolean;
	studentStatus?: Array<EnrollmentStatus>;
	recruiterStatus?: Array<EnrollmentStatus>;
	page?: number;
	pageSize?: number;
}

export interface ApiProfessorInternshipGetAllForStudyProgramGetRequest {
	companyId?: string;
	professorId?: string;
	skills?: Array<string>;
	categories?: Array<string>;
	types?: Array<InternshipEngagementType>;
	locations?: Array<string>;
	statuses?: Array<InternshipStatus>;
	states?: Array<CompanyInternshipState>;
	applicabilityTypes?: Array<boolean>;
	validationStates?: Array<boolean>;
	universityYears?: Array<number>;
	searchTerm?: string;
	sortField?: InternshipSortFieldEnum;
	sortDirection?: SortDirectionEnum;
	facultyCoordinatorId?: string;
	enableSearchByFacultyCoordinator?: boolean;
	studentStatus?: Array<EnrollmentStatus>;
	recruiterStatus?: Array<EnrollmentStatus>;
	page?: number;
	pageSize?: number;
}

export interface ApiProfessorInternshipGetAllGetRequest {
	companyId?: string;
	professorId?: string;
	skills?: Array<string>;
	categories?: Array<string>;
	types?: Array<InternshipEngagementType>;
	locations?: Array<string>;
	statuses?: Array<InternshipStatus>;
	states?: Array<CompanyInternshipState>;
	applicabilityTypes?: Array<boolean>;
	validationStates?: Array<boolean>;
	universityYears?: Array<number>;
	searchTerm?: string;
	sortField?: InternshipSortFieldEnum;
	sortDirection?: SortDirectionEnum;
	facultyCoordinatorId?: string;
	enableSearchByFacultyCoordinator?: boolean;
	studentStatus?: Array<EnrollmentStatus>;
	recruiterStatus?: Array<EnrollmentStatus>;
	page?: number;
	pageSize?: number;
}

export interface ApiProfessorInternshipGetByIdIdGetRequest {
	id: string;
}

export interface ApiProfessorInternshipUpdateIdPutRequest {
	id: string;
	professorInternshipAddUpdateDTO?: ProfessorInternshipAddUpdateDTO;
}

export interface ApiProfessorInternshipUpdateViewCountByInternshipIdIdPutRequest {
	id: string;
}

/**
 *
 */
export class ProfessorInternshipApi extends runtime.BaseAPI {
	/**
	 */
	async apiProfessorInternshipAddPostRaw(
		requestParameters: ApiProfessorInternshipAddPostRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<void>> {
		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		headerParameters["Content-Type"] = "application/json";

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/ProfessorInternship/Add`,
				method: "POST",
				headers: headerParameters,
				query: queryParameters,
				body: ProfessorInternshipAddUpdateDTOToJSON(
					requestParameters.professorInternshipAddUpdateDTO
				),
			},
			initOverrides
		);

		return new runtime.VoidApiResponse(response);
	}

	/**
	 */
	async apiProfessorInternshipAddPost(
		requestParameters: ApiProfessorInternshipAddPostRequest = {},
		initOverrides?: RequestInit
	): Promise<void> {
		await this.apiProfessorInternshipAddPostRaw(
			requestParameters,
			initOverrides
		);
	}

	/**
	 */
	async apiProfessorInternshipChangeStudyProgramIdPutRaw(
		requestParameters: ApiProfessorInternshipChangeStudyProgramIdPutRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<void>> {
		if (requestParameters.id === null || requestParameters.id === undefined) {
			throw new runtime.RequiredError(
				"id",
				"Required parameter requestParameters.id was null or undefined when calling apiProfessorInternshipChangeStudyProgramIdPut."
			);
		}

		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		headerParameters["Content-Type"] = "application/json";

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/ProfessorInternship/ChangeStudyProgram/{id}`.replace(
					`{${"id"}}`,
					encodeURIComponent(String(requestParameters.id))
				),
				method: "PUT",
				headers: headerParameters,
				query: queryParameters,
				body: requestParameters.requestBody,
			},
			initOverrides
		);

		return new runtime.VoidApiResponse(response);
	}

	/**
	 */
	async apiProfessorInternshipChangeStudyProgramIdPut(
		requestParameters: ApiProfessorInternshipChangeStudyProgramIdPutRequest,
		initOverrides?: RequestInit
	): Promise<void> {
		await this.apiProfessorInternshipChangeStudyProgramIdPutRaw(
			requestParameters,
			initOverrides
		);
	}

	/**
	 */
	async apiProfessorInternshipChangeValidStatusPostRaw(
		requestParameters: ApiProfessorInternshipChangeValidStatusPostRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<void>> {
		const queryParameters: any = {};

		if (requestParameters.internshipId !== undefined) {
			queryParameters["internshipId"] = requestParameters.internshipId;
		}

		if (requestParameters.status !== undefined) {
			queryParameters["status"] = requestParameters.status;
		}

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/ProfessorInternship/ChangeValidStatus`,
				method: "POST",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.VoidApiResponse(response);
	}

	/**
	 */
	async apiProfessorInternshipChangeValidStatusPost(
		requestParameters: ApiProfessorInternshipChangeValidStatusPostRequest = {},
		initOverrides?: RequestInit
	): Promise<void> {
		await this.apiProfessorInternshipChangeValidStatusPostRaw(
			requestParameters,
			initOverrides
		);
	}

	/**
	 */
	async apiProfessorInternshipDeleteIdDeleteRaw(
		requestParameters: ApiProfessorInternshipDeleteIdDeleteRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<void>> {
		if (requestParameters.id === null || requestParameters.id === undefined) {
			throw new runtime.RequiredError(
				"id",
				"Required parameter requestParameters.id was null or undefined when calling apiProfessorInternshipDeleteIdDelete."
			);
		}

		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/ProfessorInternship/Delete/{id}`.replace(
					`{${"id"}}`,
					encodeURIComponent(String(requestParameters.id))
				),
				method: "DELETE",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.VoidApiResponse(response);
	}

	/**
	 */
	async apiProfessorInternshipDeleteIdDelete(
		requestParameters: ApiProfessorInternshipDeleteIdDeleteRequest,
		initOverrides?: RequestInit
	): Promise<void> {
		await this.apiProfessorInternshipDeleteIdDeleteRaw(
			requestParameters,
			initOverrides
		);
	}

	/**
	 */
	async apiProfessorInternshipDuplicateIdPostRaw(
		requestParameters: ApiProfessorInternshipDuplicateIdPostRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<void>> {
		if (requestParameters.id === null || requestParameters.id === undefined) {
			throw new runtime.RequiredError(
				"id",
				"Required parameter requestParameters.id was null or undefined when calling apiProfessorInternshipDuplicateIdPost."
			);
		}

		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/ProfessorInternship/Duplicate/{id}`.replace(
					`{${"id"}}`,
					encodeURIComponent(String(requestParameters.id))
				),
				method: "POST",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.VoidApiResponse(response);
	}

	/**
	 */
	async apiProfessorInternshipDuplicateIdPost(
		requestParameters: ApiProfessorInternshipDuplicateIdPostRequest,
		initOverrides?: RequestInit
	): Promise<void> {
		await this.apiProfessorInternshipDuplicateIdPostRaw(
			requestParameters,
			initOverrides
		);
	}

	/**
	 */
	async apiProfessorInternshipGetAllAdminGetRaw(
		requestParameters: ApiProfessorInternshipGetAllAdminGetRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<InternshipDTOPagedResponse>> {
		const queryParameters: any = {};

		if (requestParameters.companyId !== undefined) {
			queryParameters["CompanyId"] = requestParameters.companyId;
		}

		if (requestParameters.professorId !== undefined) {
			queryParameters["ProfessorId"] = requestParameters.professorId;
		}

		if (requestParameters.skills) {
			queryParameters["Skills"] = requestParameters.skills;
		}

		if (requestParameters.categories) {
			queryParameters["Categories"] = requestParameters.categories;
		}

		if (requestParameters.types) {
			queryParameters["Types"] = requestParameters.types;
		}

		if (requestParameters.locations) {
			queryParameters["Locations"] = requestParameters.locations;
		}

		if (requestParameters.statuses) {
			queryParameters["Statuses"] = requestParameters.statuses;
		}

		if (requestParameters.states) {
			queryParameters["States"] = requestParameters.states;
		}

		if (requestParameters.applicabilityTypes) {
			queryParameters["ApplicabilityTypes"] =
				requestParameters.applicabilityTypes;
		}

		if (requestParameters.validationStates) {
			queryParameters["ValidationStates"] = requestParameters.validationStates;
		}

		if (requestParameters.universityYears) {
			queryParameters["UniversityYears"] = requestParameters.universityYears;
		}

		if (requestParameters.searchTerm !== undefined) {
			queryParameters["SearchTerm"] = requestParameters.searchTerm;
		}

		if (requestParameters.sortField !== undefined) {
			queryParameters["SortField"] = requestParameters.sortField;
		}

		if (requestParameters.sortDirection !== undefined) {
			queryParameters["SortDirection"] = requestParameters.sortDirection;
		}

		if (requestParameters.facultyCoordinatorId !== undefined) {
			queryParameters["FacultyCoordinatorId"] =
				requestParameters.facultyCoordinatorId;
		}

		if (requestParameters.enableSearchByFacultyCoordinator !== undefined) {
			queryParameters["EnableSearchByFacultyCoordinator"] =
				requestParameters.enableSearchByFacultyCoordinator;
		}

		if (requestParameters.studentStatus) {
			queryParameters["StudentStatus"] = requestParameters.studentStatus;
		}

		if (requestParameters.recruiterStatus) {
			queryParameters["RecruiterStatus"] = requestParameters.recruiterStatus;
		}

		if (requestParameters.page !== undefined) {
			queryParameters["Page"] = requestParameters.page;
		}

		if (requestParameters.pageSize !== undefined) {
			queryParameters["PageSize"] = requestParameters.pageSize;
		}

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/ProfessorInternship/GetAllAdmin`,
				method: "GET",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			InternshipDTOPagedResponseFromJSON(jsonValue)
		);
	}

	/**
	 */
	async apiProfessorInternshipGetAllAdminGet(
		requestParameters: ApiProfessorInternshipGetAllAdminGetRequest = {},
		initOverrides?: RequestInit
	): Promise<InternshipDTOPagedResponse> {
		const response = await this.apiProfessorInternshipGetAllAdminGetRaw(
			requestParameters,
			initOverrides
		);
		return await response.value();
	}

	/**
	 */
	async apiProfessorInternshipGetAllForDepartmentGetRaw(
		requestParameters: ApiProfessorInternshipGetAllForDepartmentGetRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<InternshipDTOPagedResponse>> {
		const queryParameters: any = {};

		if (requestParameters.companyId !== undefined) {
			queryParameters["CompanyId"] = requestParameters.companyId;
		}

		if (requestParameters.professorId !== undefined) {
			queryParameters["ProfessorId"] = requestParameters.professorId;
		}

		if (requestParameters.skills) {
			queryParameters["Skills"] = requestParameters.skills;
		}

		if (requestParameters.categories) {
			queryParameters["Categories"] = requestParameters.categories;
		}

		if (requestParameters.types) {
			queryParameters["Types"] = requestParameters.types;
		}

		if (requestParameters.locations) {
			queryParameters["Locations"] = requestParameters.locations;
		}

		if (requestParameters.statuses) {
			queryParameters["Statuses"] = requestParameters.statuses;
		}

		if (requestParameters.states) {
			queryParameters["States"] = requestParameters.states;
		}

		if (requestParameters.applicabilityTypes) {
			queryParameters["ApplicabilityTypes"] =
				requestParameters.applicabilityTypes;
		}

		if (requestParameters.validationStates) {
			queryParameters["ValidationStates"] = requestParameters.validationStates;
		}

		if (requestParameters.universityYears) {
			queryParameters["UniversityYears"] = requestParameters.universityYears;
		}

		if (requestParameters.searchTerm !== undefined) {
			queryParameters["SearchTerm"] = requestParameters.searchTerm;
		}

		if (requestParameters.sortField !== undefined) {
			queryParameters["SortField"] = requestParameters.sortField;
		}

		if (requestParameters.sortDirection !== undefined) {
			queryParameters["SortDirection"] = requestParameters.sortDirection;
		}

		if (requestParameters.facultyCoordinatorId !== undefined) {
			queryParameters["FacultyCoordinatorId"] =
				requestParameters.facultyCoordinatorId;
		}

		if (requestParameters.enableSearchByFacultyCoordinator !== undefined) {
			queryParameters["EnableSearchByFacultyCoordinator"] =
				requestParameters.enableSearchByFacultyCoordinator;
		}

		if (requestParameters.studentStatus) {
			queryParameters["StudentStatus"] = requestParameters.studentStatus;
		}

		if (requestParameters.recruiterStatus) {
			queryParameters["RecruiterStatus"] = requestParameters.recruiterStatus;
		}

		if (requestParameters.page !== undefined) {
			queryParameters["Page"] = requestParameters.page;
		}

		if (requestParameters.pageSize !== undefined) {
			queryParameters["PageSize"] = requestParameters.pageSize;
		}

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/ProfessorInternship/GetAllForDepartment`,
				method: "GET",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			InternshipDTOPagedResponseFromJSON(jsonValue)
		);
	}

	/**
	 */
	async apiProfessorInternshipGetAllForDepartmentGet(
		requestParameters: ApiProfessorInternshipGetAllForDepartmentGetRequest = {},
		initOverrides?: RequestInit
	): Promise<InternshipDTOPagedResponse> {
		const response = await this.apiProfessorInternshipGetAllForDepartmentGetRaw(
			requestParameters,
			initOverrides
		);
		return await response.value();
	}

	/**
	 */
	async apiProfessorInternshipGetAllForFacultyGetRaw(
		requestParameters: ApiProfessorInternshipGetAllForFacultyGetRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<InternshipDTOPagedResponse>> {
		const queryParameters: any = {};

		if (requestParameters.companyId !== undefined) {
			queryParameters["CompanyId"] = requestParameters.companyId;
		}

		if (requestParameters.professorId !== undefined) {
			queryParameters["ProfessorId"] = requestParameters.professorId;
		}

		if (requestParameters.skills) {
			queryParameters["Skills"] = requestParameters.skills;
		}

		if (requestParameters.categories) {
			queryParameters["Categories"] = requestParameters.categories;
		}

		if (requestParameters.types) {
			queryParameters["Types"] = requestParameters.types;
		}

		if (requestParameters.locations) {
			queryParameters["Locations"] = requestParameters.locations;
		}

		if (requestParameters.statuses) {
			queryParameters["Statuses"] = requestParameters.statuses;
		}

		if (requestParameters.states) {
			queryParameters["States"] = requestParameters.states;
		}

		if (requestParameters.applicabilityTypes) {
			queryParameters["ApplicabilityTypes"] =
				requestParameters.applicabilityTypes;
		}

		if (requestParameters.validationStates) {
			queryParameters["ValidationStates"] = requestParameters.validationStates;
		}

		if (requestParameters.universityYears) {
			queryParameters["UniversityYears"] = requestParameters.universityYears;
		}

		if (requestParameters.searchTerm !== undefined) {
			queryParameters["SearchTerm"] = requestParameters.searchTerm;
		}

		if (requestParameters.sortField !== undefined) {
			queryParameters["SortField"] = requestParameters.sortField;
		}

		if (requestParameters.sortDirection !== undefined) {
			queryParameters["SortDirection"] = requestParameters.sortDirection;
		}

		if (requestParameters.facultyCoordinatorId !== undefined) {
			queryParameters["FacultyCoordinatorId"] =
				requestParameters.facultyCoordinatorId;
		}

		if (requestParameters.enableSearchByFacultyCoordinator !== undefined) {
			queryParameters["EnableSearchByFacultyCoordinator"] =
				requestParameters.enableSearchByFacultyCoordinator;
		}

		if (requestParameters.studentStatus) {
			queryParameters["StudentStatus"] = requestParameters.studentStatus;
		}

		if (requestParameters.recruiterStatus) {
			queryParameters["RecruiterStatus"] = requestParameters.recruiterStatus;
		}

		if (requestParameters.page !== undefined) {
			queryParameters["Page"] = requestParameters.page;
		}

		if (requestParameters.pageSize !== undefined) {
			queryParameters["PageSize"] = requestParameters.pageSize;
		}

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/ProfessorInternship/GetAllForFaculty`,
				method: "GET",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			InternshipDTOPagedResponseFromJSON(jsonValue)
		);
	}

	/**
	 */
	async apiProfessorInternshipGetAllForFacultyGet(
		requestParameters: ApiProfessorInternshipGetAllForFacultyGetRequest = {},
		initOverrides?: RequestInit
	): Promise<InternshipDTOPagedResponse> {
		const response = await this.apiProfessorInternshipGetAllForFacultyGetRaw(
			requestParameters,
			initOverrides
		);
		return await response.value();
	}

	/**
	 */
	async apiProfessorInternshipGetAllForStudyProgramGetRaw(
		requestParameters: ApiProfessorInternshipGetAllForStudyProgramGetRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<InternshipDTOPagedResponse>> {
		const queryParameters: any = {};

		if (requestParameters.companyId !== undefined) {
			queryParameters["CompanyId"] = requestParameters.companyId;
		}

		if (requestParameters.professorId !== undefined) {
			queryParameters["ProfessorId"] = requestParameters.professorId;
		}

		if (requestParameters.skills) {
			queryParameters["Skills"] = requestParameters.skills;
		}

		if (requestParameters.categories) {
			queryParameters["Categories"] = requestParameters.categories;
		}

		if (requestParameters.types) {
			queryParameters["Types"] = requestParameters.types;
		}

		if (requestParameters.locations) {
			queryParameters["Locations"] = requestParameters.locations;
		}

		if (requestParameters.statuses) {
			queryParameters["Statuses"] = requestParameters.statuses;
		}

		if (requestParameters.states) {
			queryParameters["States"] = requestParameters.states;
		}

		if (requestParameters.applicabilityTypes) {
			queryParameters["ApplicabilityTypes"] =
				requestParameters.applicabilityTypes;
		}

		if (requestParameters.validationStates) {
			queryParameters["ValidationStates"] = requestParameters.validationStates;
		}

		if (requestParameters.universityYears) {
			queryParameters["UniversityYears"] = requestParameters.universityYears;
		}

		if (requestParameters.searchTerm !== undefined) {
			queryParameters["SearchTerm"] = requestParameters.searchTerm;
		}

		if (requestParameters.sortField !== undefined) {
			queryParameters["SortField"] = requestParameters.sortField;
		}

		if (requestParameters.sortDirection !== undefined) {
			queryParameters["SortDirection"] = requestParameters.sortDirection;
		}

		if (requestParameters.facultyCoordinatorId !== undefined) {
			queryParameters["FacultyCoordinatorId"] =
				requestParameters.facultyCoordinatorId;
		}

		if (requestParameters.enableSearchByFacultyCoordinator !== undefined) {
			queryParameters["EnableSearchByFacultyCoordinator"] =
				requestParameters.enableSearchByFacultyCoordinator;
		}

		if (requestParameters.studentStatus) {
			queryParameters["StudentStatus"] = requestParameters.studentStatus;
		}

		if (requestParameters.recruiterStatus) {
			queryParameters["RecruiterStatus"] = requestParameters.recruiterStatus;
		}

		if (requestParameters.page !== undefined) {
			queryParameters["Page"] = requestParameters.page;
		}

		if (requestParameters.pageSize !== undefined) {
			queryParameters["PageSize"] = requestParameters.pageSize;
		}

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/ProfessorInternship/GetAllForStudyProgram`,
				method: "GET",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			InternshipDTOPagedResponseFromJSON(jsonValue)
		);
	}

	/**
	 */
	async apiProfessorInternshipGetAllForStudyProgramGet(
		requestParameters: ApiProfessorInternshipGetAllForStudyProgramGetRequest = {},
		initOverrides?: RequestInit
	): Promise<InternshipDTOPagedResponse> {
		const response =
			await this.apiProfessorInternshipGetAllForStudyProgramGetRaw(
				requestParameters,
				initOverrides
			);
		return await response.value();
	}

	/**
	 */
	async apiProfessorInternshipGetAllGetRaw(
		requestParameters: ApiProfessorInternshipGetAllGetRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<InternshipDTOPagedResponse>> {
		const queryParameters: any = {};

		if (requestParameters.companyId !== undefined) {
			queryParameters["CompanyId"] = requestParameters.companyId;
		}

		if (requestParameters.professorId !== undefined) {
			queryParameters["ProfessorId"] = requestParameters.professorId;
		}

		if (requestParameters.skills) {
			queryParameters["Skills"] = requestParameters.skills;
		}

		if (requestParameters.categories) {
			queryParameters["Categories"] = requestParameters.categories;
		}

		if (requestParameters.types) {
			queryParameters["Types"] = requestParameters.types;
		}

		if (requestParameters.locations) {
			queryParameters["Locations"] = requestParameters.locations;
		}

		if (requestParameters.statuses) {
			queryParameters["Statuses"] = requestParameters.statuses;
		}

		if (requestParameters.states) {
			queryParameters["States"] = requestParameters.states;
		}

		if (requestParameters.applicabilityTypes) {
			queryParameters["ApplicabilityTypes"] =
				requestParameters.applicabilityTypes;
		}

		if (requestParameters.validationStates) {
			queryParameters["ValidationStates"] = requestParameters.validationStates;
		}

		if (requestParameters.universityYears) {
			queryParameters["UniversityYears"] = requestParameters.universityYears;
		}

		if (requestParameters.searchTerm !== undefined) {
			queryParameters["SearchTerm"] = requestParameters.searchTerm;
		}

		if (requestParameters.sortField !== undefined) {
			queryParameters["SortField"] = requestParameters.sortField;
		}

		if (requestParameters.sortDirection !== undefined) {
			queryParameters["SortDirection"] = requestParameters.sortDirection;
		}

		if (requestParameters.facultyCoordinatorId !== undefined) {
			queryParameters["FacultyCoordinatorId"] =
				requestParameters.facultyCoordinatorId;
		}

		if (requestParameters.enableSearchByFacultyCoordinator !== undefined) {
			queryParameters["EnableSearchByFacultyCoordinator"] =
				requestParameters.enableSearchByFacultyCoordinator;
		}

		if (requestParameters.studentStatus) {
			queryParameters["StudentStatus"] = requestParameters.studentStatus;
		}

		if (requestParameters.recruiterStatus) {
			queryParameters["RecruiterStatus"] = requestParameters.recruiterStatus;
		}

		if (requestParameters.page !== undefined) {
			queryParameters["Page"] = requestParameters.page;
		}

		if (requestParameters.pageSize !== undefined) {
			queryParameters["PageSize"] = requestParameters.pageSize;
		}

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/ProfessorInternship/GetAll`,
				method: "GET",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			InternshipDTOPagedResponseFromJSON(jsonValue)
		);
	}

	/**
	 */
	async apiProfessorInternshipGetAllGet(
		requestParameters: ApiProfessorInternshipGetAllGetRequest = {},
		initOverrides?: RequestInit
	): Promise<InternshipDTOPagedResponse> {
		const response = await this.apiProfessorInternshipGetAllGetRaw(
			requestParameters,
			initOverrides
		);
		return await response.value();
	}

	/**
	 */
	async apiProfessorInternshipGetByIdIdGetRaw(
		requestParameters: ApiProfessorInternshipGetByIdIdGetRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<InternshipDTO>> {
		if (requestParameters.id === null || requestParameters.id === undefined) {
			throw new runtime.RequiredError(
				"id",
				"Required parameter requestParameters.id was null or undefined when calling apiProfessorInternshipGetByIdIdGet."
			);
		}

		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/ProfessorInternship/GetById/{id}`.replace(
					`{${"id"}}`,
					encodeURIComponent(String(requestParameters.id))
				),
				method: "GET",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			InternshipDTOFromJSON(jsonValue)
		);
	}

	/**
	 */
	async apiProfessorInternshipGetByIdIdGet(
		requestParameters: ApiProfessorInternshipGetByIdIdGetRequest,
		initOverrides?: RequestInit
	): Promise<InternshipDTO> {
		const response = await this.apiProfessorInternshipGetByIdIdGetRaw(
			requestParameters,
			initOverrides
		);
		return await response.value();
	}

	/**
	 */
	async apiProfessorInternshipGetDepartmentInternshipFiltersGetRaw(
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<Array<Filter>>> {
		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/ProfessorInternship/GetDepartmentInternshipFilters`,
				method: "GET",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			jsonValue.map(FilterFromJSON)
		);
	}

	/**
	 */
	async apiProfessorInternshipGetDepartmentInternshipFiltersGet(
		initOverrides?: RequestInit
	): Promise<Array<Filter>> {
		const response =
			await this.apiProfessorInternshipGetDepartmentInternshipFiltersGetRaw(
				initOverrides
			);
		return await response.value();
	}

	/**
	 */
	async apiProfessorInternshipGetFacultyInternshipFiltersGetRaw(
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<Array<Filter>>> {
		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/ProfessorInternship/GetFacultyInternshipFilters`,
				method: "GET",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			jsonValue.map(FilterFromJSON)
		);
	}

	/**
	 */
	async apiProfessorInternshipGetFacultyInternshipFiltersGet(
		initOverrides?: RequestInit
	): Promise<Array<Filter>> {
		const response =
			await this.apiProfessorInternshipGetFacultyInternshipFiltersGetRaw(
				initOverrides
			);
		return await response.value();
	}

	/**
	 */
	async apiProfessorInternshipGetInternshipFiltersGetRaw(
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<Array<Filter>>> {
		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/ProfessorInternship/GetInternshipFilters`,
				method: "GET",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			jsonValue.map(FilterFromJSON)
		);
	}

	/**
	 */
	async apiProfessorInternshipGetInternshipFiltersGet(
		initOverrides?: RequestInit
	): Promise<Array<Filter>> {
		const response =
			await this.apiProfessorInternshipGetInternshipFiltersGetRaw(
				initOverrides
			);
		return await response.value();
	}

	/**
	 */
	async apiProfessorInternshipGetStudyProgramInternshipFiltersGetRaw(
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<Array<Filter>>> {
		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/ProfessorInternship/GetStudyProgramInternshipFilters`,
				method: "GET",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			jsonValue.map(FilterFromJSON)
		);
	}

	/**
	 */
	async apiProfessorInternshipGetStudyProgramInternshipFiltersGet(
		initOverrides?: RequestInit
	): Promise<Array<Filter>> {
		const response =
			await this.apiProfessorInternshipGetStudyProgramInternshipFiltersGetRaw(
				initOverrides
			);
		return await response.value();
	}

	/**
	 */
	async apiProfessorInternshipUpdateIdPutRaw(
		requestParameters: ApiProfessorInternshipUpdateIdPutRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<void>> {
		if (requestParameters.id === null || requestParameters.id === undefined) {
			throw new runtime.RequiredError(
				"id",
				"Required parameter requestParameters.id was null or undefined when calling apiProfessorInternshipUpdateIdPut."
			);
		}

		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		headerParameters["Content-Type"] = "application/json";

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/ProfessorInternship/Update/{id}`.replace(
					`{${"id"}}`,
					encodeURIComponent(String(requestParameters.id))
				),
				method: "PUT",
				headers: headerParameters,
				query: queryParameters,
				body: ProfessorInternshipAddUpdateDTOToJSON(
					requestParameters.professorInternshipAddUpdateDTO
				),
			},
			initOverrides
		);

		return new runtime.VoidApiResponse(response);
	}

	/**
	 */
	async apiProfessorInternshipUpdateIdPut(
		requestParameters: ApiProfessorInternshipUpdateIdPutRequest,
		initOverrides?: RequestInit
	): Promise<void> {
		await this.apiProfessorInternshipUpdateIdPutRaw(
			requestParameters,
			initOverrides
		);
	}

	/**
	 */
	async apiProfessorInternshipUpdateViewCountByInternshipIdIdPutRaw(
		requestParameters: ApiProfessorInternshipUpdateViewCountByInternshipIdIdPutRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<void>> {
		if (requestParameters.id === null || requestParameters.id === undefined) {
			throw new runtime.RequiredError(
				"id",
				"Required parameter requestParameters.id was null or undefined when calling apiProfessorInternshipUpdateViewCountByInternshipIdIdPut."
			);
		}

		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/ProfessorInternship/UpdateViewCountByInternshipId/{id}`.replace(
					`{${"id"}}`,
					encodeURIComponent(String(requestParameters.id))
				),
				method: "PUT",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.VoidApiResponse(response);
	}

	/**
	 */
	async apiProfessorInternshipUpdateViewCountByInternshipIdIdPut(
		requestParameters: ApiProfessorInternshipUpdateViewCountByInternshipIdIdPutRequest,
		initOverrides?: RequestInit
	): Promise<void> {
		await this.apiProfessorInternshipUpdateViewCountByInternshipIdIdPutRaw(
			requestParameters,
			initOverrides
		);
	}
}
