/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
	InternshipType,
	InternshipTypeFromJSON,
	InternshipTypeFromJSONTyped,
	InternshipTypeToJSON,
} from "./InternshipType";

/**
 *
 * @export
 * @interface PracticeInternshipAllocationReportDTO
 */
export interface PracticeInternshipAllocationReportDTO {
	/**
	 *
	 * @type {string}
	 * @memberof PracticeInternshipAllocationReportDTO
	 */
	id?: string;
	/**
	 *
	 * @type {string}
	 * @memberof PracticeInternshipAllocationReportDTO
	 */
	studentId?: string;
	/**
	 *
	 * @type {string}
	 * @memberof PracticeInternshipAllocationReportDTO
	 */
	studentName?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof PracticeInternshipAllocationReportDTO
	 */
	employerId?: string;
	/**
	 *
	 * @type {string}
	 * @memberof PracticeInternshipAllocationReportDTO
	 */
	employerName?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof PracticeInternshipAllocationReportDTO
	 */
	internshipId?: string;
	/**
	 *
	 * @type {string}
	 * @memberof PracticeInternshipAllocationReportDTO
	 */
	internshipName?: string | null;
	/**
	 *
	 * @type {InternshipType}
	 * @memberof PracticeInternshipAllocationReportDTO
	 */
	internshipType?: InternshipType;
	/**
	 *
	 * @type {string}
	 * @memberof PracticeInternshipAllocationReportDTO
	 */
	studentStudyProgram?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof PracticeInternshipAllocationReportDTO
	 */
	studentEmail?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof PracticeInternshipAllocationReportDTO
	 */
	tutorEmail?: string | null;
}

export function PracticeInternshipAllocationReportDTOFromJSON(
	json: any
): PracticeInternshipAllocationReportDTO {
	return PracticeInternshipAllocationReportDTOFromJSONTyped(json, false);
}

export function PracticeInternshipAllocationReportDTOFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): PracticeInternshipAllocationReportDTO {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		id: !exists(json, "id") ? undefined : json["id"],
		studentId: !exists(json, "studentId") ? undefined : json["studentId"],
		studentName: !exists(json, "studentName") ? undefined : json["studentName"],
		employerId: !exists(json, "employerId") ? undefined : json["employerId"],
		employerName: !exists(json, "employerName")
			? undefined
			: json["employerName"],
		internshipId: !exists(json, "internshipId")
			? undefined
			: json["internshipId"],
		internshipName: !exists(json, "internshipName")
			? undefined
			: json["internshipName"],
		internshipType: !exists(json, "internshipType")
			? undefined
			: InternshipTypeFromJSON(json["internshipType"]),
		studentStudyProgram: !exists(json, "studentStudyProgram")
			? undefined
			: json["studentStudyProgram"],
		studentEmail: !exists(json, "studentEmail")
			? undefined
			: json["studentEmail"],
		tutorEmail: !exists(json, "tutorEmail") ? undefined : json["tutorEmail"],
	};
}

export function PracticeInternshipAllocationReportDTOToJSON(
	value?: PracticeInternshipAllocationReportDTO | null
): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		id: value.id,
		studentId: value.studentId,
		studentName: value.studentName,
		employerId: value.employerId,
		employerName: value.employerName,
		internshipId: value.internshipId,
		internshipName: value.internshipName,
		internshipType: InternshipTypeToJSON(value.internshipType),
		studentStudyProgram: value.studentStudyProgram,
		studentEmail: value.studentEmail,
		tutorEmail: value.tutorEmail,
	};
}
