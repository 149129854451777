/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
	AcademicUser,
	AcademicUserFromJSON,
	AcademicUserFromJSONTyped,
	AcademicUserToJSON,
} from "./AcademicUser";

/**
 *
 * @export
 * @interface StudentPersonalData
 */
export interface StudentPersonalData {
	/**
	 *
	 * @type {string}
	 * @memberof StudentPersonalData
	 */
	id?: string;
	/**
	 *
	 * @type {Date}
	 * @memberof StudentPersonalData
	 */
	createdAt?: Date;
	/**
	 *
	 * @type {Date}
	 * @memberof StudentPersonalData
	 */
	updatedAt?: Date;
	/**
	 *
	 * @type {string}
	 * @memberof StudentPersonalData
	 */
	studentId?: string;
	/**
	 *
	 * @type {AcademicUser}
	 * @memberof StudentPersonalData
	 */
	student?: AcademicUser;
	/**
	 *
	 * @type {string}
	 * @memberof StudentPersonalData
	 */
	birthPlace?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof StudentPersonalData
	 */
	city?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof StudentPersonalData
	 */
	county?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof StudentPersonalData
	 */
	streetName?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof StudentPersonalData
	 */
	streetNumber?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof StudentPersonalData
	 */
	building?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof StudentPersonalData
	 */
	floor?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof StudentPersonalData
	 */
	apartment?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof StudentPersonalData
	 */
	identitySeries?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof StudentPersonalData
	 */
	identityNumber?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof StudentPersonalData
	 */
	identityIssuer?: string | null;
	/**
	 *
	 * @type {Date}
	 * @memberof StudentPersonalData
	 */
	identityDate?: Date;
	/**
	 *
	 * @type {string}
	 * @memberof StudentPersonalData
	 */
	cnp?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof StudentPersonalData
	 */
	passport?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof StudentPersonalData
	 */
	residencePermit?: string | null;
	/**
	 *
	 * @type {Date}
	 * @memberof StudentPersonalData
	 */
	birthdate?: Date;
}

export function StudentPersonalDataFromJSON(json: any): StudentPersonalData {
	return StudentPersonalDataFromJSONTyped(json, false);
}

export function StudentPersonalDataFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): StudentPersonalData {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		id: !exists(json, "id") ? undefined : json["id"],
		createdAt: !exists(json, "createdAt")
			? undefined
			: new Date(json["createdAt"]),
		updatedAt: !exists(json, "updatedAt")
			? undefined
			: new Date(json["updatedAt"]),
		studentId: !exists(json, "studentId") ? undefined : json["studentId"],
		student: !exists(json, "student")
			? undefined
			: AcademicUserFromJSON(json["student"]),
		birthPlace: !exists(json, "birthPlace") ? undefined : json["birthPlace"],
		city: !exists(json, "city") ? undefined : json["city"],
		county: !exists(json, "county") ? undefined : json["county"],
		streetName: !exists(json, "streetName") ? undefined : json["streetName"],
		streetNumber: !exists(json, "streetNumber")
			? undefined
			: json["streetNumber"],
		building: !exists(json, "building") ? undefined : json["building"],
		floor: !exists(json, "floor") ? undefined : json["floor"],
		apartment: !exists(json, "apartment") ? undefined : json["apartment"],
		identitySeries: !exists(json, "identitySeries")
			? undefined
			: json["identitySeries"],
		identityNumber: !exists(json, "identityNumber")
			? undefined
			: json["identityNumber"],
		identityIssuer: !exists(json, "identityIssuer")
			? undefined
			: json["identityIssuer"],
		identityDate: !exists(json, "identityDate")
			? undefined
			: new Date(json["identityDate"]),
		cnp: !exists(json, "cnp") ? undefined : json["cnp"],
		passport: !exists(json, "passport") ? undefined : json["passport"],
		residencePermit: !exists(json, "residencePermit")
			? undefined
			: json["residencePermit"],
		birthdate: !exists(json, "birthdate")
			? undefined
			: new Date(json["birthdate"]),
	};
}

export function StudentPersonalDataToJSON(
	value?: StudentPersonalData | null
): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		id: value.id,
		createdAt:
			value.createdAt === undefined ? undefined : value.createdAt.toISOString(),
		updatedAt:
			value.updatedAt === undefined ? undefined : value.updatedAt.toISOString(),
		studentId: value.studentId,
		student: AcademicUserToJSON(value.student),
		birthPlace: value.birthPlace,
		city: value.city,
		county: value.county,
		streetName: value.streetName,
		streetNumber: value.streetNumber,
		building: value.building,
		floor: value.floor,
		apartment: value.apartment,
		identitySeries: value.identitySeries,
		identityNumber: value.identityNumber,
		identityIssuer: value.identityIssuer,
		identityDate:
			value.identityDate === undefined
				? undefined
				: value.identityDate.toISOString(),
		cnp: value.cnp,
		passport: value.passport,
		residencePermit: value.residencePermit,
		birthdate:
			value.birthdate === undefined ? undefined : value.birthdate.toISOString(),
	};
}
