/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum PracticeNotebookState {
	NotApplicable = "NotApplicable",
	NotGenerated = "NotGenerated",
	InProgress = "InProgress",
	PendingTutorSignature = "PendingTutorSignature",
	PendingInternshipAdminValidation = "PendingInternshipAdminValidation",
	Finalized = "Finalized",
}

export function PracticeNotebookStateFromJSON(
	json: any
): PracticeNotebookState {
	return PracticeNotebookStateFromJSONTyped(json, false);
}

export function PracticeNotebookStateFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): PracticeNotebookState {
	return json as PracticeNotebookState;
}

export function PracticeNotebookStateToJSON(
	value?: PracticeNotebookState | null
): any {
	return value as any;
}
