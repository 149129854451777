import Layout from "../../Containers/Layout";
import {
	Row,
	Radio,
	RadioChangeEvent,
	Spin,
	Select,
	Modal,
	InputNumber,
} from "antd";
import { useQuery, useQueryClient } from "react-query";
import {
	generateUniversityAccord,
	generateUniversityAccordDocx,
} from "../../Requests/university-accords-requests";
import { downloadFile, MimeType } from "../../utils/downloadUtils";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../Notifications/NotificationsUtils";
import { useState } from "react";
import {
	useIsAdministrativeAccordsResponsible,
	useIsCompany,
} from "../../utils/utilFunctions";
import { useTranslation } from "react-i18next";
import styles from "./UniversityAccords.module.scss";
import {
	getFaculties,
	getUniversityAccordsInfo,
} from "../../utils/reactQueriesConstants";
import { getAllFaculties } from "../../Requests/faculty-requests";
import {
	generatePracticeProtocolDocx,
	generatePracticeProtocolPDF,
} from "../../Requests/practice-protocol-requests";
import DocumentsTable from "../Admin/DocumentsTable";
import CustomModalFooter from "../../Containers/CustomModalFooter";
import CustomButton from "../../CustomComponents/CustomButton";
import { FacultyDTO } from "../../Api";

const { Group } = Radio;
const { Option } = Select;

const UniversityAccords = () => {
	const { t, i18n } = useTranslation();
	const isCompany = useIsCompany();
	const isAdministrativeAccordsResponsible =
		useIsAdministrativeAccordsResponsible();
	const [accordType, setAccordType] = useState(
		isCompany || isAdministrativeAccordsResponsible ? "all" : "toSign"
	);
	const [generateLoading, setGenerateLoading] = useState(false);
	const [step, setStep] = useState(0);
	const [accordFormat, setAccordFormat] = useState("pdf");
	const [accord, setAccord] = useState("");
	const [facultyId, setFacultyId] = useState("");
	const [facultyOptions, setFacultyOptions] = useState<any>([]);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [duration, setDuration] = useState(1);
	const [studentsPerYear, setStudentsPerYear] = useState(5);

	const downloadFail = (_err: any) => {
		const faculty = allFacultiesList?.filter((f) => f.id === facultyId)[0];
		openNotification(
			t("universityAccord.error"),
			t("universityAccord.downloadError", {
				field:
					i18n.language === "en"
						? faculty?.facultyNameEn ?? "the faculty"
						: faculty?.facultyNameRo ?? "facultatea",
			}),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const openGetErrorNotification = (_error: any) => {
		openNotification(
			t("internships.error"),
			t("internships.fetchDataError"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const { data: allFacultiesList } = useQuery(
		[getFaculties],
		() => getAllFaculties("", 1, 20),
		{
			onError: openGetErrorNotification,
			refetchOnWindowFocus: false,
		}
	);

	const queryClient = useQueryClient();
	const invalidateAccordsQuery = async () => {
		await queryClient.invalidateQueries(getUniversityAccordsInfo);
	};

	const onChangeGroup = (e: RadioChangeEvent) => {
		setAccordType(e.target.value);
	};

	const changeAccordType = async (value: string) => {
		setAccord(value);

		if (value !== "practiceProtocol") {
			setStep(1);
		} else {
			await queryClient.invalidateQueries(getFaculties);
			setFacultyOptions(
				allFacultiesList
					?.filter((faculty: FacultyDTO) => faculty.facultyNameRo !== "Altele")
					.map((faculty: FacultyDTO) => (
						<Option key={faculty.id} value={faculty.id}>
							{i18n.language === "en"
								? faculty.facultyNameEn?.replace("Faculty of", "")
								: faculty.facultyNameRo?.replace("Facultatea de", "")}
						</Option>
					))
			);

			setStep(1);
		}
	};

	const changeFaculty = (value: any) => {
		setFacultyId(value);
		setStep(2);
	};

	const changeAccordFormat = (value: any) => {
		setStep(3);
		setAccordFormat(value);
	};

	const changeDuration = (value: any) => {
		setDuration(value);
		setStep(4);
	};

	const changeStudentsPerYear = (value: any) => {
		setStudentsPerYear(value);
		setStep(5);
	};

	const generateAccord = () => {
		setGenerateLoading(true);

		if (accord === "practiceProtocol") {
			if (accordFormat === "pdf" && step === 5) {
				downloadFile(
					generatePracticeProtocolPDF(facultyId, duration, studentsPerYear),
					t("universityAccord.practiceProtocol")
				)
					.then(invalidateAccordsQuery)
					.catch(downloadFail)
					.finally(() => {
						setGenerateLoading(false);
						setIsModalVisible(false);
						setAccord("");
						setAccordFormat("");
						setFacultyId("");
						setDuration(1);
						setStudentsPerYear(5);
						setStep(0);
					});
			} else if (step === 5) {
				downloadFile(
					generatePracticeProtocolDocx(facultyId, duration, studentsPerYear),
					t("universityAccord.practiceProtocol"),
					MimeType.docx
				)
					.then(invalidateAccordsQuery)
					.catch(downloadFail)
					.finally(() => {
						setGenerateLoading(false);
						setIsModalVisible(false);
						setAccord("");
						setAccordFormat("");
						setFacultyId("");
						setDuration(1);
						setStudentsPerYear(5);
						setStep(0);
					});
			} else {
				setGenerateLoading(false);
				openNotification(
					t("internships.error"),
					t("usersText.requiredFieldsError"),
					NOTIFICATION_TYPES.ERROR
				);
			}
		} else {
			if (accordFormat === "pdf") {
				downloadFile(generateUniversityAccord(), t("universityAccord.accord"))
					.then(invalidateAccordsQuery)
					.catch(downloadFail)
					.finally(() => setGenerateLoading(false));
			} else {
				downloadFile(
					generateUniversityAccordDocx(),
					t("universityAccord.accord"),
					MimeType.docx
				)
					.then(invalidateAccordsQuery)
					.catch(downloadFail)
					.finally(() => setGenerateLoading(false));
			}
		}
	};

	const handleCancel = () => {
		setAccord("");
		setAccordFormat("");
		setFacultyId("");
		setDuration(1);
		setStudentsPerYear(5);
		setStep(0);
		setIsModalVisible(false);
	};

	return (
		<div className="flex flex-col px-4 gap-5 py-10">
			{isCompany && (
				<div className="flex items-center flex-col lg:flex-row gap-2 w-full flex-wrap my-6">
					<CustomButton onClick={() => setIsModalVisible(true)}>
						{t("universityAccord.generateAccord")}
					</CustomButton>
					<Modal
						open={isModalVisible}
						maskClosable={!generateLoading}
						onCancel={handleCancel}
						onOk={generateAccord}
						title={t("universityAccord.accordGeneration")}
						width={window.innerWidth > 1215 ? "50%" : "80%"}
						footer={
							<CustomModalFooter
								handleClose={handleCancel}
								spinning={generateLoading}
								handleSave={generateAccord}
								confirmButtonName={
									t("universityAccord.generateAccord") +
									" (" +
									accordFormat +
									")"
								}
							/>
						}
					>
						<Spin spinning={generateLoading}>
							<Row>
								<Select
									className={styles.antSelect}
									allowClear
									filterOption={false}
									onChange={changeAccordType}
									placeholder={t("universityAccord.selectAccordType")}
									style={{ width: "100%", marginBottom: "2%" }}
								>
									<Option value="practiceProtocol">
										{t("universityAccord.practiceProtocol")}
									</Option>
								</Select>
							</Row>
							{step >= 1 && accord === "practiceProtocol" ? (
								<Row>
									<Select
										className={styles.antSelect}
										allowClear
										filterOption={false}
										onChange={changeFaculty}
										placeholder={t("universityAccord.selectFacultyType")}
										style={{ width: "100%", marginBottom: "2%" }}
									>
										{facultyOptions}
									</Select>
								</Row>
							) : null}
							{step >= 2 ? (
								<Row>
									<Select
										className={styles.antSelect}
										allowClear
										filterOption={false}
										onChange={changeAccordFormat}
										placeholder={t("universityAccord.selectAccordFormat")}
										style={{ width: "100%", marginBottom: "2%" }}
									>
										<Option value="pdf">PDF</Option>
										<Option value="docx">DOCX</Option>
									</Select>
								</Row>
							) : null}
							{step >= 3 && accord === "practiceProtocol" ? (
								<Row>
									<InputNumber
										className={styles.antSelect}
										placeholder={t("universityAccord.duration")}
										min={1}
										max={5}
										onChange={changeDuration}
										style={{ width: "100%", marginBottom: "2%" }}
									/>
								</Row>
							) : null}
							{step >= 4 && accord === "practiceProtocol" ? (
								<Row>
									<InputNumber
										className={styles.antSelect}
										placeholder={t("universityAccord.studentsPerYear")}
										min={1}
										onChange={changeStudentsPerYear}
										style={{ width: "100%", marginBottom: "2%" }}
									/>
								</Row>
							) : null}
						</Spin>
					</Modal>
				</div>
			)}
			{!isCompany && !isAdministrativeAccordsResponsible && (
				<Row style={{ marginBottom: "1em" }}>
					<Group
						onChange={onChangeGroup}
						value={accordType}
						style={{ textAlign: "left" }}
					>
						<Radio value="toSign">{t("universityAccord.toSign")}</Radio>
						<Radio value="all">{t("universityAccord.all")}</Radio>
					</Group>
				</Row>
			)}
			<DocumentsTable />
		</div>
	);
};

export default UniversityAccords;
