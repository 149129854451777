import { solid, regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Space } from "antd";
import { useTranslation } from "react-i18next";
import {
	CompanyInternshipState,
	InternshipEngagementType,
	InternshipType,
} from "../../Api";
import {
	capitalizeFirst,
	internshipStatus,
	useIsCompany,
	useIsStudent,
	isUserLogged,
	useIsFacultyAdmin,
	useIsInternshipAdmin,
	useIsProfessor,
	useIsDean,
	useIsDepartmentSecretary,
	useIsDepartmentDirector,
} from "../../utils/utilFunctions";
import { useLocation } from "react-router-dom";
import { canApply } from "../../Requests/internship-requests";
import { useState, useEffect } from "react";
import { getProfessorById } from "../../Requests/academic-user-requests";
import { useQuery } from "react-query";
import { isEmptyGuid } from "../../utils/dataUtils";

const InternshipActions = (props: { item: any; activeTab: any }) => {
	const { t } = useTranslation();
	const isCompany = useIsCompany();
	const isFacultyAdmin = useIsFacultyAdmin();
	const isInternshipAdmin = useIsInternshipAdmin();
	const isStudent = useIsStudent();
	const isProfessor = useIsProfessor();
	const isDean = useIsDean();
	const isDepartmentSecretary = useIsDepartmentSecretary();
	const isDepartmentDirector = useIsDepartmentDirector();
	const location = useLocation();
	const search = useLocation().search;
	const type = new URLSearchParams(search).get("type") as any;
	const [applicable, setApplicable] = useState(false);

	const IconText = ({
		icon,
		text,
		tooltipText,
	}: {
		icon: any;
		text: string;
		tooltipText: string;
	}) => (
		<Space title={tooltipText}>
			{icon}
			{text}
		</Space>
	);

	const { data: facultyCoordinator } = useQuery([], () => {
		if (!isEmptyGuid(props.item?.facultyCoordinatorId)) {
			return getProfessorById(props.item.facultyCoordinatorId);
		}
	});

	const canApplyToInternship = () => {
		if (!props.item) return false;

		canApply(props.item.internshipId)
			.then(() => {
				setApplicable(() => {
					return true;
				});
			})
			.catch(async () => {
				setApplicable(false);
			});

		return applicable;
	};

	useEffect(() => {
		if (isUserLogged() && isStudent) {
			setApplicable(canApplyToInternship());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			{props.item && (
				<div className="flex flex-wrap items-center w-full gap-2">
					{type === InternshipType.ByProfessor &&
						location.pathname.includes("/propunere") && (
							<IconText
								icon={<FontAwesomeIcon icon={solid("user")} />}
								text={props.item.recruiterName}
								tooltipText={props.item.recruiterName}
								key="list-Professor"
							/>
						)}

					{props.item.internshipType === InternshipType.ByProfessor &&
						(location.pathname.includes("/lucrare-licenta") ||
							location.pathname.includes("/tema-cercetare") ||
							location.pathname.includes("/stagiu-practica")) && (
							<IconText
								icon={<FontAwesomeIcon icon={solid("user")} />}
								text={props.item.professorName}
								tooltipText={props.item.professorName}
								key="list-Professor"
							/>
						)}

					{type === InternshipType.ByCompany &&
						location.pathname.includes("/propunere") && (
							<IconText
								icon={<FontAwesomeIcon icon={solid("building")} />}
								text={props.item.recruiterName}
								tooltipText={props.item.recruiterName}
								key="list-Building"
							/>
						)}

					{props.item.internshipType === InternshipType.ByCompany &&
						(location.pathname.includes("/lucrare-licenta") ||
							location.pathname.includes("/tema-cercetare") ||
							location.pathname.includes("/stagiu-practica")) && (
							<IconText
								icon={<FontAwesomeIcon icon={solid("building")} />}
								text={props.item.companyName}
								tooltipText={props.item.companyName}
								key="list-Building"
							/>
						)}

					{props.item.internshipType === InternshipType.ByCompany &&
						!isEmptyGuid(props.item.facultyCoordinatorId) &&
						location.pathname.includes("/stagiu-practica") && (
							<IconText
								icon={<FontAwesomeIcon icon={solid("user")} />}
								text={facultyCoordinator?.name || ""}
								tooltipText={t("internships.facultyCoordinator")}
								key="list-FacultyCoordinator"
							/>
						)}

					{isCompany && (
						<IconText
							icon={
								<FontAwesomeIcon
									icon={
										props.item.state === CompanyInternshipState.Draft
											? solid("battery-quarter")
											: props.item.state ===
											  CompanyInternshipState.PendingApproval
											? solid("battery-half")
											: solid("battery-full")
									}
								/>
							}
							text={t("internships.state." + props.item.state)}
							tooltipText={t("internships.state.state")}
							key="list-BatteryFull"
						/>
					)}

					{(props.item.engagementType === InternshipEngagementType.Internship ||
						props.item.engagementType === InternshipEngagementType.Job) && (
						<IconText
							icon={<FontAwesomeIcon icon={solid("calendar")} />}
							text={
								props.item.startingDate
									? new Date(props.item.startingDate).toLocaleDateString("ro")
									: "N/A"
							}
							tooltipText={t("internships.startDate")}
							key="list-CalendarOutlined"
						/>
					)}

					{(props.item.engagementType === InternshipEngagementType.Internship ||
						props.item.engagementType === InternshipEngagementType.Job) && (
						<IconText
							icon={<FontAwesomeIcon icon={solid("clock")} />}
							text={
								props.item.period?.toString() +
								" " +
								(props.item.period?.toString() === "1"
									? t("internships.month")
									: t("internships.months") ?? "N/A")
							}
							tooltipText={t("internships.period")}
							key="list-ClockCircleOutlined"
						/>
					)}

					{props.item.engagementType ===
						InternshipEngagementType.Internship && (
						<IconText
							icon={<FontAwesomeIcon icon={solid("business-time")} />}
							text={
								props.item.totalPracticeHours?.toString() +
									" " +
									t("internships.hours") ?? "N/A"
							}
							tooltipText={t("internships.totalPracticeHours")}
							key="list-BusinessTimeOutlined"
						/>
					)}

					<IconText
						icon={<FontAwesomeIcon icon={solid("paperclip")} />}
						text={
							t(
								"internships.filters." + props.item.engagementType?.toString()
							) ?? "N/A"
						}
						tooltipText={t("internships.type")}
						key="list-PaperclipOutlined"
					/>

					<IconText
						icon={<FontAwesomeIcon icon={solid("users")} />}
						text={
							props.item.availablePositions?.toString()
								? props.item.availablePositions?.toString() === "1"
									? props.item.availablePositions.toString() +
									  " " +
									  t("internships.seat")
									: props.item.availablePositions.toString() +
									  " " +
									  t("internships.seats")
								: "N/A"
						}
						tooltipText={t("internships.freePosts")}
						key="list-TeamOutlined"
					/>

					{(isFacultyAdmin || isInternshipAdmin) &&
						(props.item.type === InternshipType.ByCompany ||
							props.item.internshipType === InternshipType.ByCompany) && (
							<IconText
								icon={<FontAwesomeIcon icon={solid("users-slash")} />}
								text={
									props.item.initialAvailablePositions?.toString()
										? props.item.initialAvailablePositions?.toString() === "1"
											? props.item.initialAvailablePositions.toString() +
											  t("internships.seat")
											: props.item.initialAvailablePositions.toString() +
											  t("internships.seats")
										: "N/A"
								}
								tooltipText={t("internships.initialPosts")}
								key="list-UsersSlashOutlined"
							/>
						)}

					{(((isFacultyAdmin || isInternshipAdmin) &&
						(props.item.type === InternshipType.ByCompany ||
							props.item.internshipType === InternshipType.ByCompany)) ||
						(isDean &&
							(props.item.type === InternshipType.ByProfessor ||
								props.item.internshipType === InternshipType.ByProfessor) &&
							(props.activeTab === "3" || props.activeTab === "4")) ||
						((isProfessor || isDepartmentDirector || isDepartmentSecretary) &&
							(props.item.type === InternshipType.ByProfessor ||
								props.item.internshipType === InternshipType.ByProfessor) &&
							props.activeTab === "4")) && (
						<IconText
							icon={<FontAwesomeIcon icon={solid("user-group")} />}
							text={
								props.item.applicantsCount?.toString()
									? props.item.applicantsCount?.toString() === "1"
										? props.item.applicantsCount.toString() +
										  t("internships.applicant")
										: props.item.applicantsCount.toString() +
										  t("internships.applicants")
									: "N/A"
							}
							tooltipText={t("internships.applicants")}
							key="list-UserGroupOutlined"
						/>
					)}

					{!isStudent && location.pathname.includes("/propuneri") && (
						<IconText
							icon={<FontAwesomeIcon icon={regular("eye")} />}
							text={
								props.item.viewCount?.toString()
									? props.item.viewCount?.toString() === "1"
										? props.item.viewCount.toString() +
										  " " +
										  t("internships.view")
										: props.item.viewCount.toString() +
										  " " +
										  t("internships.views")
									: "N/A"
							}
							tooltipText={t("internships.views")}
							key="list-ViewOutlined"
						/>
					)}

					{(props.item.engagementType === InternshipEngagementType.Internship ||
						props.item.engagementType === InternshipEngagementType.Job) && (
						<a
							href={
								"https://www.google.com/maps/search/?api=1&query=Google&query_place_id=" +
								props.item.placeId.toString()
							}
							style={{ color: "rgb(0 -47 0 / 0.45)" }}
						>
							<IconText
								icon={<FontAwesomeIcon icon={solid("location-dot")} />}
								text={props.item.location?.toString() ?? "N/A"}
								tooltipText={t("internships.location")}
								key="list-PushpinOutlined"
							/>
						</a>
					)}

					{(props.item.engagementType === InternshipEngagementType.Internship ||
						props.item.engagementType === InternshipEngagementType.Job) && (
						<IconText
							icon={<FontAwesomeIcon icon={solid("money-bill")} />}
							text={
								props.item.isPaid
									? capitalizeFirst(t("internships.paid"))
									: capitalizeFirst(t("internships.unpaid"))
							}
							tooltipText={t("internships.payment")}
							key="list-Money"
						/>
					)}

					<IconText
						icon={
							internshipStatus(
								props.item.availableFrom,
								props.item.availableTo
							) ? (
								<FontAwesomeIcon icon={solid("circle-check")} />
							) : (
								<FontAwesomeIcon icon={solid("circle-xmark")} />
							)
						}
						text={
							internshipStatus(props.item.availableFrom, props.item.availableTo)
								? t("internships.activeInternship")
								: t("internships.closedInternship")
						}
						tooltipText={t("internships.status")}
						key="list-CircleOutlined"
					/>

					{props.item.category && (
						<IconText
							icon={<FontAwesomeIcon icon={solid("table-cells")} />}
							text={props.item.category?.toString()}
							tooltipText={t("internships.category")}
							key="list-AppstoreOutlined"
						/>
					)}

					{isStudent && applicable && props.item.internshipLink && (
						<a
							href={props.item.internshipLink}
							target="_blank"
							rel="noopener noreferrer"
						>
							<b>
								<IconText
									icon={
										<FontAwesomeIcon
											icon={solid("arrow-up-right-from-square")}
										/>
									}
									text={t("internships.internshipLink")}
									tooltipText={t("internships.internshipLinkDetails")}
									key="list-AppstoreOutlined"
								/>
							</b>
						</a>
					)}
					<br />
				</div>
			)}
		</>
	);
};

export default InternshipActions;
