import { Button, Form, Input, Row, Col } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import i18n from "../../../../../../i18n";
import styles from "../../../AddInternshipForm.module.scss";
import { InternshipCompetencesFormProps } from "./InternshipCompetencesForm.types";

const { List, Item } = Form;

const InternshipCompetencesForm = (props: InternshipCompetencesFormProps) => {
	const { t } = i18n;

	return (
		<List
			initialValue={props.initialValue}
			name={props.name}
			rules={props.rules}
		>
			{(fields, { add, remove }) => (
				<div className="flex flex-col pb-5">
					<label className="pb-5">
						<span style={{ color: "#ff4d4f" }}>*&nbsp;</span>
						{t("competences.label") + ":"}
					</label>

					<div className="flex flex-col">
						{fields.map(({ key, name, ...restField }) => (
							<div className="flex items-start gap-2 border-b border-black">
								<div className="flex gap-x-2 flex-wrap">
									<Item
										{...restField}
										name={[name, "competence"]}
										initialValue=""
										rules={[
											{
												required: true,
												message: t("usersText.requiredField", {
													field: t("competences.competence"),
												}),
											},
											{
												max: 255,
												message:
													t("usersText.maxFieldLength1") +
													255 +
													t("usersText.maxFieldLength2"),
											},
										]}
									>
										<Input
											disabled={props.disabled ?? false}
											placeholder={t("competences.competence")}
										/>
									</Item>

									<Item
										{...restField}
										name={[name, "type"]}
										initialValue=""
										rules={[
											{
												required: true,
												whitespace: false,
												message: t("usersText.requiredField", {
													field: t("competences.type"),
												}),
											},
											{
												max: 255,
												message:
													t("usersText.maxFieldLength1") +
													255 +
													t("usersText.maxFieldLength2"),
											},
										]}
									>
										<Input
											disabled={props.disabled ?? false}
											placeholder={t("competences.type")}
										/>
									</Item>

									<Item
										{...restField}
										name={[name, "place"]}
										initialValue=""
										rules={[
											{
												required: true,
												whitespace: false,
												message: t("usersText.requiredField", {
													field: t("competences.place"),
												}),
											},
											{
												max: 255,
												message:
													t("usersText.maxFieldLength1") +
													255 +
													t("usersText.maxFieldLength2"),
											},
										]}
									>
										<Input
											disabled={props.disabled ?? false}
											placeholder={t("competences.place")}
										/>
									</Item>

									<Item
										{...restField}
										name={[name, "activity"]}
										initialValue=""
										rules={[
											{
												required: true,
												whitespace: false,
												message: t("usersText.requiredField", {
													field: t("competences.activity"),
												}),
											},
											{
												max: 1023,
												message:
													t("usersText.maxFieldLength1") +
													1023 +
													t("usersText.maxFieldLength2"),
											},
										]}
									>
										<Input
											disabled={props.disabled ?? false}
											placeholder={t("competences.activity")}
										/>
									</Item>

									<Item
										{...restField}
										name={[name, "observations"]}
										initialValue=""
										rules={[
											{
												max: 4095,
												message:
													t("usersText.maxFieldLength1") +
													4095 +
													t("usersText.maxFieldLength2"),
											},
										]}
									>
										<Input
											disabled={props.disabled}
											placeholder={t("competences.observations")}
										/>
									</Item>
								</div>

								{fields.length > 1 && !props.disabled && (
									<MinusCircleOutlined
										className="mt-2"
										onClick={() => remove(name)}
									/>
								)}
							</div>
						))}
					</div>

					{!props.disabled && (
						<div className="">
							<Button
								type="dashed"
								onClick={() => add()}
								icon={<PlusOutlined />}
							>
								{t("competences.add")}
							</Button>
						</div>
					)}
				</div>
			)}
		</List>
	);
};

export default InternshipCompetencesForm;
