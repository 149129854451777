import { Avatar, Col, Divider, Row, Typography } from "antd";
import AddUpdateAppreciationModal from "./AddUpdateAppreciationModal";
import { useTranslation } from "react-i18next";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { theme } from "../../theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useIsProfessor } from "../../utils/utilFunctions";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../Notifications/NotificationsUtils";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { selectStudentId } from "./StudentSlice";
import { getAppreciations } from "../../Requests/appreciations-requests";
import { getStudentAppreciations } from "../../utils/reactQueriesConstants";

const Appreciations = () => {
	const { t } = useTranslation();
	const isProfessor = useIsProfessor();
	const studentId = useSelector(selectStudentId);

	const openGetErrorNotification = (_error: any) => {
		openNotification(
			t("internships.error"),
			t("internships.fetchDataError"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const { data: studentAppreciations } = useQuery(
		[getStudentAppreciations, studentId],
		() => getAppreciations(studentId!),
		{
			onError: (err) => {
				openGetErrorNotification(err);
			},
			refetchOnWindowFocus: false,
		}
	);

	return (
		<div className="w-full max-w-[727px] px-4 py-10">
			{isProfessor && <AddUpdateAppreciationModal />}
			{studentAppreciations?.map((item) => {
				return (
					<div key={item.id} className="py-6">
						<Divider style={{ borderTop: "1px solid black" }} />
						<div className="flex flex-col md:flex-row justify-center gap-10">
							<div className="flex md:flex-col gap-2 items-center">
								{item.professorImage ? (
									<Avatar size={100} src={item.professorImage} />
								) : (
									<Avatar
										size={100}
										icon={
											<FontAwesomeIcon
												icon={solid("user")}
												title={t("account.userPicture")}
											/>
										}
									/>
								)}

								<Typography style={{ fontSize: 15, textAlign: "center" }}>
									<Typography.Text strong={true}>
										{item.professorName}
									</Typography.Text>
								</Typography>
							</div>

							<div className="flex flex-col">
								{item.title && (
									<Typography style={{ fontSize: 20 }}>
										<Typography.Text strong={true}>
											{item.title}
										</Typography.Text>
									</Typography>
								)}
								{item.receiveDate && (
									<Typography
										style={{
											fontSize: 14,
											color: theme.forthColor,
											fontStyle: "italic",
										}}
									>
										<Typography.Text strong={true}>
											{item.receiveDate.getDate() +
												"." +
												(item.receiveDate.getMonth() + 1) +
												"." +
												item.receiveDate.getFullYear()}
										</Typography.Text>
									</Typography>
								)}

								<div
									style={{
										fontSize: 15,
										textAlign: "left",
										fontStyle: "italic",
									}}
									dangerouslySetInnerHTML={{ __html: item.message || "" }}
								/>
							</div>
						</div>
					</div>
				);
			})}
		</div>
	);
};

export default Appreciations;
