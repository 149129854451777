import Layout from "../../Containers/Layout";
import styles from "./Privacy.module.scss";
import { useTranslation } from "react-i18next";

const GetPolicyDescription = () => {
	const { t } = useTranslation();

	return (
		<div>
			<br />
			<h2>{t("gdpr.gdprTitle")}</h2>
			<br />
			<h1>{t("gdpr.gdprInfo")}</h1>
			<p>{t("gdpr.gdpr1")}</p>
			<h2>{t("gdpr.gdprSubtitle1")}</h2>
			<p>{t("gdpr.gdpr2")}</p>
			<p>{t("gdpr.gdprList1-1")}</p>
			<p>{t("gdpr.gdprList1-2")}</p>
			<p>{t("gdpr.gdprList1-3")}</p>
			<p>{t("gdpr.gdprList1-4")}</p>
			<p>{t("gdpr.gdprList1-5")}</p>
			<p>{t("gdpr.gdprList1-6")}</p>

			<h2>{t("gdpr.gdprSubtitle2")}</h2>
			<p>{t("gdpr.gdpr3")}</p>
			<p>{t("gdpr.gdprList2-1")}</p>
			<p>{t("gdpr.gdprList2-2")}</p>
			<p>{t("gdpr.gdprList2-3")}</p>
			<p>{t("gdpr.gdprList2-4")}</p>
			<p>{t("gdpr.gdprList2-5")}</p>
			<p>{t("gdpr.gdprList2-6")}</p>
			<p>{t("gdpr.gdprList2-7")}</p>
			<p>{t("gdpr.gdprList2-8")}</p>
			<p>{t("gdpr.gdpr4")}</p>

			<h2>{t("gdpr.gdprSubtitle3")}</h2>
			<p>{t("gdpr.gdpr5")}</p>
			<p>{t("gdpr.gdprList3-1")}</p>
			<p>{t("gdpr.gdprList3-2")}</p>
			<p>{t("gdpr.gdprList3-3")}</p>
			<p>{t("gdpr.gdprList3-4")}</p>
			<p>{t("gdpr.gdprList3-5")}</p>
			<p>{t("gdpr.gdprList3-6")}</p>
			<p>{t("gdpr.gdprList3-7")}</p>
			<p>{t("gdpr.gdprList3-8")}</p>
			<p>{t("gdpr.gdprList3-9")}</p>
			<p>{t("gdpr.gdprList3-10")}</p>
			<p>{t("gdpr.gdprList3-11")}</p>
			<p>{t("gdpr.gdprList3-12")}</p>
			<p>{t("gdpr.gdprList3-13")}</p>
			<p>{t("gdpr.gdprList3-14")}</p>
			<p>{t("gdpr.gdprList3-15")}</p>
			<p>{t("gdpr.gdprList3-16")}</p>
			<p>{t("gdpr.gdprList3-17")}</p>
			<p>{t("gdpr.gdprList3-18")}</p>
			<p>{t("gdpr.gdpr6")}</p>
			<table>
				<tr>
					<th>{t("gdpr.column1")}</th>
					<th>{t("gdpr.column2")}</th>
				</tr>
				<tr>
					<td>{t("gdpr.th1")}</td>
					<td>{t("gdpr.th2")}</td>
				</tr>
				<tr>
					<td>{t("gdpr.th1")}</td>
					<td>{t("gdpr.th2")}</td>
				</tr>
				<tr>
					<td>{t("gdpr.th3")}</td>
					<td>{t("gdpr.th4")}</td>
				</tr>
				<tr>
					<td>{t("gdpr.th5")}</td>
					<td>{t("gdpr.th6")}</td>
				</tr>
				<tr>
					<td>{t("gdpr.th7")}</td>
					<td>{t("gdpr.th8")}</td>
				</tr>
				<tr>
					<td>{t("gdpr.th9")}</td>
					<td>{t("gdpr.th10")}</td>
				</tr>
			</table>
			<br />

			<h2>{t("gdpr.gdprSubtitle4")}</h2>
			<p>{t("gdpr.gdpr7")}</p>
			<p>{t("gdpr.gdpr8")}</p>
			<p>{t("gdpr.gdprList4-1")}</p>
			<p>{t("gdpr.gdprList4-2")}</p>

			<h2>{t("gdpr.gdprSubtitle5")}</h2>
			<p>{t("gdpr.gdpr9")}</p>
			<p>{t("gdpr.gdpr10")}</p>

			<h2>{t("gdpr.gdprSubtitle6")}</h2>
			<p>{t("gdpr.gdpr11")}</p>

			<h2>{t("gdpr.gdprSubtitle7")}</h2>
			<p>{t("gdpr.gdpr12")}</p>
			<p>{t("gdpr.gdprList5-1")}</p>
			<p>{t("gdpr.gdprList5-2")}</p>
			<p>{t("gdpr.gdprList5-3")}</p>
			<p>{t("gdpr.gdprList5-4")}</p>
			<p>{t("gdpr.gdprList5-5")}</p>
			<p>{t("gdpr.gdprList5-6")}</p>
			<p>{t("gdpr.gdprList5-7")}</p>
			<p>{t("gdpr.gdpr13")}</p>
			<p>{t("gdpr.gdpr14")}</p>

			<h2>{t("gdpr.gdprSubtitle8")}</h2>
			<p>{t("gdpr.gdpr15")}</p>
		</div>
	);
};

const Privacy = () => {
	return (
		<Layout>
			<div className={styles.wrapper}>
				<div className={styles.content}>
					<div className={styles.tableElements}>
						<div id="politica" className={styles.container}>
							{GetPolicyDescription()}
						</div>
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default Privacy;
