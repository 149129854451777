import { Button } from "antd";
import styled from "styled-components";
import { theme } from "../theme";

const StyledButton = styled(Button)`
	&& {
		outline: none;
		border: none;
		background-color: ${(props: any) =>
			props.backgroundcolor ? props.backgroundcolor : theme.primaryColor};
		color: ${(props: any) => (props.textcolor ? props.textcolor : theme.white)};
		border-radius: ${(props: any) =>
			props.borderradius ? props.borderradius : "10px"};
		padding: ${(props: any) =>
				props.paddingvertical ? props.paddingvertical : "0.8rem"}
			${(props: any) =>
				props.paddinghorizontal ? props.paddinghorizontal : "0.9rem"};
		margin-right: ${(props: any) =>
			props.marginright ? props.marginright : "2%"};
		margin-left: ${(props: any) =>
			props.marginleft ? props.marginleft : "0%"};
		margin-bottom: ${(props: any) =>
			props.marginbottom ? props.marginbottom : "0%"};
		margin-top: ${(props: any) => (props.margintop ? props.margintop : "5%")};
		text-decoration: none;
		box-shadow: ${(props: any) =>
			props.boxshadow ? props.boxshadow : "rgba(0, 0, 0, 0.1) 0px 4px 12px"};
		font-size: ${(props: any) => (props.fontsize ? props.fontsize : "100%")};
		font-family: ${(props: any) =>
			props.fontFamily ? props.fontFamily : "Montserrat"};
		width: ${(props: any) =>
			props.fullwidth ? "100%" : props.width ? props.width : "auto"};
		opacity: ${(props: any) => (props.opacity ? props.opacity : "1")};
		display: flex;
		align-items: ${(props: any) =>
			props.alignitems ? props.alignitems : "center"};

		justify-content: center;
		a {
			color: theme.black;
		}
		a:hover {
			color: theme.black;
		}
		&:hover,
		&:focus,
		&:active {
			outline: none;
			border: none;
			background-color: ${(props: any) =>
				props.backgroundcolor ? props.backgroundcolor : theme.primaryColor};
			color: ${(props: any) =>
				props.textcolor ? props.textcolor : theme.white};
			border-radius: ${(props: any) =>
				props.borderradius ? props.borderradius : "10px"};
			padding: ${(props: any) =>
					props.paddingvertical ? props.paddingvertical : "0.8rem"}
				${(props: any) =>
					props.paddinghorizontal ? props.paddinghorizontal : "0.9rem"};
			margin-right: ${(props: any) =>
				props.marginright ? props.marginright : "2%"};
			margin-left: ${(props: any) =>
				props.marginleft ? props.marginleft : "0%"};
			margin-bottom: ${(props: any) =>
				props.marginbottom ? props.marginbottom : "0%"};
			text-decoration: none;
			box-shadow: ${(props: any) =>
				props.boxshadow ? props.boxshadow : "rgba(0, 0, 0, 0.1) 0px 4px 12px"};
			font-size: ${(props: any) => (props.fontsize ? props.fontsize : "100%")};
			opacity: ${(props: any) => (props.opacity ? props.opacity : "1")};
			display: flex;
			align-items: ${(props: any) =>
				props.alignitems ? props.alignitems : "center"};

			justify-content: center;
			@media only screen and (max-width: 1000px) {
				padding: ${(props: any) =>
						props.paddingvertical ? props.paddingvertical : "0.3rem"}
					${(props: any) =>
						props.paddinghorizontal ? props.paddinghorizontal : "0.6rem"};
				font-size: ${(props: any) => (props.fontsize ? props.fontsize : "90%")};
			}
		}
		&:disabled,
		&:disabled:hover {
			background-color: ${theme.primaryColor};
			color: white;
			opacity: 0.6;
		}

		@media only screen and (max-width: 1000px) {
			padding: ${(props: any) =>
					props.paddingvertical ? props.paddingvertical : "0.3rem"}
				${(props: any) =>
					props.paddinghorizontal ? props.paddinghorizontal : "0.6rem"};
			font-size: ${(props: any) => (props.fontsize ? props.fontsize : "90%")};
		}
	}
`;

const CustomButton = (props: any) => {
	return <StyledButton {...props}>{props.children}</StyledButton>;
};

export default CustomButton;
