/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
	AcademicUser,
	AcademicUserFromJSON,
	AcademicUserFromJSONTyped,
	AcademicUserToJSON,
} from "./AcademicUser";
import {
	CompanyUniversityAccord,
	CompanyUniversityAccordFromJSON,
	CompanyUniversityAccordFromJSONTyped,
	CompanyUniversityAccordToJSON,
} from "./CompanyUniversityAccord";

/**
 *
 * @export
 * @interface UniversityAccordReview
 */
export interface UniversityAccordReview {
	/**
	 *
	 * @type {string}
	 * @memberof UniversityAccordReview
	 */
	id?: string;
	/**
	 *
	 * @type {Date}
	 * @memberof UniversityAccordReview
	 */
	createdAt?: Date;
	/**
	 *
	 * @type {Date}
	 * @memberof UniversityAccordReview
	 */
	updatedAt?: Date;
	/**
	 *
	 * @type {string}
	 * @memberof UniversityAccordReview
	 */
	universityAccordId?: string;
	/**
	 *
	 * @type {string}
	 * @memberof UniversityAccordReview
	 */
	message?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof UniversityAccordReview
	 */
	fileName?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof UniversityAccordReview
	 */
	authorId?: string;
	/**
	 *
	 * @type {CompanyUniversityAccord}
	 * @memberof UniversityAccordReview
	 */
	universityAccord?: CompanyUniversityAccord;
	/**
	 *
	 * @type {AcademicUser}
	 * @memberof UniversityAccordReview
	 */
	author?: AcademicUser;
}

export function UniversityAccordReviewFromJSON(
	json: any
): UniversityAccordReview {
	return UniversityAccordReviewFromJSONTyped(json, false);
}

export function UniversityAccordReviewFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): UniversityAccordReview {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		id: !exists(json, "id") ? undefined : json["id"],
		createdAt: !exists(json, "createdAt")
			? undefined
			: new Date(json["createdAt"]),
		updatedAt: !exists(json, "updatedAt")
			? undefined
			: new Date(json["updatedAt"]),
		universityAccordId: !exists(json, "universityAccordId")
			? undefined
			: json["universityAccordId"],
		message: !exists(json, "message") ? undefined : json["message"],
		fileName: !exists(json, "fileName") ? undefined : json["fileName"],
		authorId: !exists(json, "authorId") ? undefined : json["authorId"],
		universityAccord: !exists(json, "universityAccord")
			? undefined
			: CompanyUniversityAccordFromJSON(json["universityAccord"]),
		author: !exists(json, "author")
			? undefined
			: AcademicUserFromJSON(json["author"]),
	};
}

export function UniversityAccordReviewToJSON(
	value?: UniversityAccordReview | null
): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		id: value.id,
		createdAt:
			value.createdAt === undefined ? undefined : value.createdAt.toISOString(),
		updatedAt:
			value.updatedAt === undefined ? undefined : value.updatedAt.toISOString(),
		universityAccordId: value.universityAccordId,
		message: value.message,
		fileName: value.fileName,
		authorId: value.authorId,
		universityAccord: CompanyUniversityAccordToJSON(value.universityAccord),
		author: AcademicUserToJSON(value.author),
	};
}
