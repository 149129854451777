/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
	AcademicUser,
	AcademicUserFromJSON,
	AcademicUserFromJSONTyped,
	AcademicUserToJSON,
} from "./AcademicUser";
import {
	ResearchProposal,
	ResearchProposalFromJSON,
	ResearchProposalFromJSONTyped,
	ResearchProposalToJSON,
} from "./ResearchProposal";

/**
 *
 * @export
 * @interface ProfessorResearchProposal
 */
export interface ProfessorResearchProposal {
	/**
	 *
	 * @type {string}
	 * @memberof ProfessorResearchProposal
	 */
	id?: string;
	/**
	 *
	 * @type {Date}
	 * @memberof ProfessorResearchProposal
	 */
	createdAt?: Date;
	/**
	 *
	 * @type {Date}
	 * @memberof ProfessorResearchProposal
	 */
	updatedAt?: Date;
	/**
	 *
	 * @type {string}
	 * @memberof ProfessorResearchProposal
	 */
	professorId?: string;
	/**
	 *
	 * @type {string}
	 * @memberof ProfessorResearchProposal
	 */
	researchProposalId?: string;
	/**
	 *
	 * @type {AcademicUser}
	 * @memberof ProfessorResearchProposal
	 */
	professor?: AcademicUser;
	/**
	 *
	 * @type {ResearchProposal}
	 * @memberof ProfessorResearchProposal
	 */
	researchProposal?: ResearchProposal;
}

export function ProfessorResearchProposalFromJSON(
	json: any
): ProfessorResearchProposal {
	return ProfessorResearchProposalFromJSONTyped(json, false);
}

export function ProfessorResearchProposalFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): ProfessorResearchProposal {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		id: !exists(json, "id") ? undefined : json["id"],
		createdAt: !exists(json, "createdAt")
			? undefined
			: new Date(json["createdAt"]),
		updatedAt: !exists(json, "updatedAt")
			? undefined
			: new Date(json["updatedAt"]),
		professorId: !exists(json, "professorId") ? undefined : json["professorId"],
		researchProposalId: !exists(json, "researchProposalId")
			? undefined
			: json["researchProposalId"],
		professor: !exists(json, "professor")
			? undefined
			: AcademicUserFromJSON(json["professor"]),
		researchProposal: !exists(json, "researchProposal")
			? undefined
			: ResearchProposalFromJSON(json["researchProposal"]),
	};
}

export function ProfessorResearchProposalToJSON(
	value?: ProfessorResearchProposal | null
): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		id: value.id,
		createdAt:
			value.createdAt === undefined ? undefined : value.createdAt.toISOString(),
		updatedAt:
			value.updatedAt === undefined ? undefined : value.updatedAt.toISOString(),
		professorId: value.professorId,
		researchProposalId: value.researchProposalId,
		professor: AcademicUserToJSON(value.professor),
		researchProposal: ResearchProposalToJSON(value.researchProposal),
	};
}
