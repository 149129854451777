/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
	StudentYearEnum,
	StudentYearEnumFromJSON,
	StudentYearEnumFromJSONTyped,
	StudentYearEnumToJSON,
} from "./StudentYearEnum";

/**
 *
 * @export
 * @interface SupervisedStudyProgramDTO
 */
export interface SupervisedStudyProgramDTO {
	/**
	 *
	 * @type {string}
	 * @memberof SupervisedStudyProgramDTO
	 */
	studyProgramId?: string;
	/**
	 *
	 * @type {StudentYearEnum}
	 * @memberof SupervisedStudyProgramDTO
	 */
	studentYear?: StudentYearEnum;
}

export function SupervisedStudyProgramDTOFromJSON(
	json: any
): SupervisedStudyProgramDTO {
	return SupervisedStudyProgramDTOFromJSONTyped(json, false);
}

export function SupervisedStudyProgramDTOFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): SupervisedStudyProgramDTO {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		studyProgramId: !exists(json, "studyProgramId")
			? undefined
			: json["studyProgramId"],
		studentYear: !exists(json, "studentYear")
			? undefined
			: StudentYearEnumFromJSON(json["studentYear"]),
	};
}

export function SupervisedStudyProgramDTOToJSON(
	value?: SupervisedStudyProgramDTO | null
): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		studyProgramId: value.studyProgramId,
		studentYear: StudentYearEnumToJSON(value.studentYear),
	};
}
