/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
	StudentYearEnum,
	StudentYearEnumFromJSON,
	StudentYearEnumFromJSONTyped,
	StudentYearEnumToJSON,
} from "./StudentYearEnum";

/**
 *
 * @export
 * @interface StudyProgramDTO
 */
export interface StudyProgramDTO {
	/**
	 *
	 * @type {string}
	 * @memberof StudyProgramDTO
	 */
	id?: string;
	/**
	 *
	 * @type {string}
	 * @memberof StudyProgramDTO
	 */
	studyCycle?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof StudyProgramDTO
	 */
	name?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof StudyProgramDTO
	 */
	domain?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof StudyProgramDTO
	 */
	facultyId?: string;
	/**
	 *
	 * @type {string}
	 * @memberof StudyProgramDTO
	 */
	facultyAcronym?: string | null;
	/**
	 *
	 * @type {Array<StudentYearEnum>}
	 * @memberof StudyProgramDTO
	 */
	supervisedYears?: Array<StudentYearEnum> | null;
	/**
	 *
	 * @type {string}
	 * @memberof StudyProgramDTO
	 */
	coordinatorId?: string | null;
}

export function StudyProgramDTOFromJSON(json: any): StudyProgramDTO {
	return StudyProgramDTOFromJSONTyped(json, false);
}

export function StudyProgramDTOFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): StudyProgramDTO {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		id: !exists(json, "id") ? undefined : json["id"],
		studyCycle: !exists(json, "studyCycle") ? undefined : json["studyCycle"],
		name: !exists(json, "name") ? undefined : json["name"],
		domain: !exists(json, "domain") ? undefined : json["domain"],
		facultyId: !exists(json, "facultyId") ? undefined : json["facultyId"],
		facultyAcronym: !exists(json, "facultyAcronym")
			? undefined
			: json["facultyAcronym"],
		supervisedYears: !exists(json, "supervisedYears")
			? undefined
			: json["supervisedYears"] === null
			? null
			: (json["supervisedYears"] as Array<any>).map(StudentYearEnumFromJSON),
		coordinatorId: !exists(json, "coordinatorId")
			? undefined
			: json["coordinatorId"],
	};
}

export function StudyProgramDTOToJSON(value?: StudyProgramDTO | null): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		id: value.id,
		studyCycle: value.studyCycle,
		name: value.name,
		domain: value.domain,
		facultyId: value.facultyId,
		facultyAcronym: value.facultyAcronym,
		supervisedYears:
			value.supervisedYears === undefined
				? undefined
				: value.supervisedYears === null
				? null
				: (value.supervisedYears as Array<any>).map(StudentYearEnumToJSON),
		coordinatorId: value.coordinatorId,
	};
}
