/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
	AcademicUser,
	AcademicUserFromJSON,
	AcademicUserFromJSONTyped,
	AcademicUserToJSON,
} from "./AcademicUser";
import {
	Company,
	CompanyFromJSON,
	CompanyFromJSONTyped,
	CompanyToJSON,
} from "./Company";
import {
	CompanyUser,
	CompanyUserFromJSON,
	CompanyUserFromJSONTyped,
	CompanyUserToJSON,
} from "./CompanyUser";
import {
	ResearchProposal,
	ResearchProposalFromJSON,
	ResearchProposalFromJSONTyped,
	ResearchProposalToJSON,
} from "./ResearchProposal";
import {
	ResearchProposalEnrollmentStatus,
	ResearchProposalEnrollmentStatusFromJSON,
	ResearchProposalEnrollmentStatusFromJSONTyped,
	ResearchProposalEnrollmentStatusToJSON,
} from "./ResearchProposalEnrollmentStatus";
import {
	ResearchProposalEnrollmentType,
	ResearchProposalEnrollmentTypeFromJSON,
	ResearchProposalEnrollmentTypeFromJSONTyped,
	ResearchProposalEnrollmentTypeToJSON,
} from "./ResearchProposalEnrollmentType";

/**
 *
 * @export
 * @interface ResearchProposalEnrollment
 */
export interface ResearchProposalEnrollment {
	/**
	 *
	 * @type {string}
	 * @memberof ResearchProposalEnrollment
	 */
	id?: string;
	/**
	 *
	 * @type {Date}
	 * @memberof ResearchProposalEnrollment
	 */
	createdAt?: Date;
	/**
	 *
	 * @type {Date}
	 * @memberof ResearchProposalEnrollment
	 */
	updatedAt?: Date;
	/**
	 *
	 * @type {string}
	 * @memberof ResearchProposalEnrollment
	 */
	researchProposalId?: string;
	/**
	 *
	 * @type {ResearchProposalEnrollmentType}
	 * @memberof ResearchProposalEnrollment
	 */
	enrollmentType?: ResearchProposalEnrollmentType;
	/**
	 *
	 * @type {string}
	 * @memberof ResearchProposalEnrollment
	 */
	professorId?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof ResearchProposalEnrollment
	 */
	companyId?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof ResearchProposalEnrollment
	 */
	companyUserId?: string | null;
	/**
	 *
	 * @type {ResearchProposal}
	 * @memberof ResearchProposalEnrollment
	 */
	researchProposal?: ResearchProposal;
	/**
	 *
	 * @type {AcademicUser}
	 * @memberof ResearchProposalEnrollment
	 */
	professor?: AcademicUser;
	/**
	 *
	 * @type {Company}
	 * @memberof ResearchProposalEnrollment
	 */
	company?: Company;
	/**
	 *
	 * @type {CompanyUser}
	 * @memberof ResearchProposalEnrollment
	 */
	companyUser?: CompanyUser;
	/**
	 *
	 * @type {ResearchProposalEnrollmentStatus}
	 * @memberof ResearchProposalEnrollment
	 */
	enrollmentStatus?: ResearchProposalEnrollmentStatus;
	/**
	 *
	 * @type {boolean}
	 * @memberof ResearchProposalEnrollment
	 */
	isViewed?: boolean;
}

export function ResearchProposalEnrollmentFromJSON(
	json: any
): ResearchProposalEnrollment {
	return ResearchProposalEnrollmentFromJSONTyped(json, false);
}

export function ResearchProposalEnrollmentFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): ResearchProposalEnrollment {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		id: !exists(json, "id") ? undefined : json["id"],
		createdAt: !exists(json, "createdAt")
			? undefined
			: new Date(json["createdAt"]),
		updatedAt: !exists(json, "updatedAt")
			? undefined
			: new Date(json["updatedAt"]),
		researchProposalId: !exists(json, "researchProposalId")
			? undefined
			: json["researchProposalId"],
		enrollmentType: !exists(json, "enrollmentType")
			? undefined
			: ResearchProposalEnrollmentTypeFromJSON(json["enrollmentType"]),
		professorId: !exists(json, "professorId") ? undefined : json["professorId"],
		companyId: !exists(json, "companyId") ? undefined : json["companyId"],
		companyUserId: !exists(json, "companyUserId")
			? undefined
			: json["companyUserId"],
		researchProposal: !exists(json, "researchProposal")
			? undefined
			: ResearchProposalFromJSON(json["researchProposal"]),
		professor: !exists(json, "professor")
			? undefined
			: AcademicUserFromJSON(json["professor"]),
		company: !exists(json, "company")
			? undefined
			: CompanyFromJSON(json["company"]),
		companyUser: !exists(json, "companyUser")
			? undefined
			: CompanyUserFromJSON(json["companyUser"]),
		enrollmentStatus: !exists(json, "enrollmentStatus")
			? undefined
			: ResearchProposalEnrollmentStatusFromJSON(json["enrollmentStatus"]),
		isViewed: !exists(json, "isViewed") ? undefined : json["isViewed"],
	};
}

export function ResearchProposalEnrollmentToJSON(
	value?: ResearchProposalEnrollment | null
): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		id: value.id,
		createdAt:
			value.createdAt === undefined ? undefined : value.createdAt.toISOString(),
		updatedAt:
			value.updatedAt === undefined ? undefined : value.updatedAt.toISOString(),
		researchProposalId: value.researchProposalId,
		enrollmentType: ResearchProposalEnrollmentTypeToJSON(value.enrollmentType),
		professorId: value.professorId,
		companyId: value.companyId,
		companyUserId: value.companyUserId,
		researchProposal: ResearchProposalToJSON(value.researchProposal),
		professor: AcademicUserToJSON(value.professor),
		company: CompanyToJSON(value.company),
		companyUser: CompanyUserToJSON(value.companyUser),
		enrollmentStatus: ResearchProposalEnrollmentStatusToJSON(
			value.enrollmentStatus
		),
		isViewed: value.isViewed,
	};
}
