import { PushNotificationSubscriptionDTO, PushNotificationsApi } from "../Api";
import { getAuthConfiguration } from "./configuration";

const AuthPushNotificationsApiFactory = () =>
	new PushNotificationsApi(getAuthConfiguration());

export const registerPushNotificationsSubscription = (
	pushNotificationSubscriptionDTO: PushNotificationSubscriptionDTO
) => {
	return AuthPushNotificationsApiFactory().apiPushNotificationsSubscribePost({
		pushNotificationSubscriptionDTO,
	});
};

export const unregisterPushNotificationsSubscription = async () => {
	await AuthPushNotificationsApiFactory().apiPushNotificationsUnsubscribeDelete();
};
