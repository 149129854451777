import { useState } from "react";
import styles from "./Register.module.scss";
import { Button, Form, Input, Select, Spin } from "antd";
import CustomButton from "../../CustomComponents/CustomButton";
import {
	CustomInput,
	CustomPasswordInput,
} from "../../CustomComponents/CustomInput";
import CustomForm from "../../CustomComponents/CustomForm";
import { theme } from "../../theme";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../Notifications/NotificationsUtils";
import { useHistory } from "react-router-dom";
import {
	fetchCompanyData,
	registerCompany,
} from "../../Requests/company-requests";
import { useTranslation } from "react-i18next";
import { faxPattern, phonePattern } from "../../utils/constants";
import CustomSelect from "../../CustomComponents/CustomSelect";
import { ErrorCodes } from "../../Api";
import { getErrorFromResponse } from "../../utils/responseUtils";
import SimpleLayout from "../../layouts/Simple";

const { Option } = Select;

export interface companyState {
	name: string;
	cif: string;
	address: string;
	phone: string;
	fax: string;
	activityDomain: string;
	representativeName: string;
	email: string;
	password: string;
	passwordConfirm: string;
}

const Register = () => {
	const { t } = useTranslation();
	const history = useHistory();
	const [form] = Form.useForm();
	const [state, setState] = useState({
		name: "" as any,
		commercialName: "" as any,
		cif: "" as any,
		address: "" as any,
		postalCode: "" as any,
		phone: "" as any,
		activityDomain: "" as any,
		fax: "" as any,
		representativeName: "" as any,
		email: "" as any,
		password: "" as any,
		passwordConfirm: "",
		preferredLanguage: "ro" as any,
	});

	const [step, setStep] = useState(1);
	const [spinning, setSpinning] = useState(false);
	const [cleanedCif, setCleanedCif] = useState("");

	const fetchData = async () => {
		let newCleanedCif = state.cif;
		if (state.cif.toLowerCase().includes("ro")) {
			newCleanedCif = state.cif.replace(/ro/gi, "");
		}
		setCleanedCif(newCleanedCif);

		const cui = parseInt(newCleanedCif);
		fetchCompanyData(cui)
			.then((response) => {
				setState((prevState) => {
					return {
						...prevState,
						name: response.response?.denumire,
						address: response.response?.adresa,
						phone: response.response?.telefon,
						fax: response.response?.fax,
					};
				});

				form.setFieldsValue({
					name: response.response?.denumire,
					cif: state.cif,
					address: response.response?.adresa,
					phone: response.response?.telefon,
					fax: response.response?.fax,
				});

				if (response.response?.denumire !== "") setStep(2);
				else
					openNotification(
						t("signupText.messages.error"),
						t("signupText.messages.companyNotFound"),
						NOTIFICATION_TYPES.ERROR
					);
			})
			.catch(async (_error) => {
				const errorMessage = await getErrorFromResponse(_error);

				if (errorMessage?.code === ErrorCodes.AnafApiUnavailable) {
					openNotification(
						t("signupText.messages.error"),
						t("signupText.messages.anafApiUnavailable"),
						NOTIFICATION_TYPES.INFO
					);

					form.setFieldsValue({
						cif: state.cif,
					});

					setStep(2);
				} else {
					openNotification(
						t("signupText.messages.error"),
						t("signupText.messages.fetchCompanyDataError"),
						NOTIFICATION_TYPES.ERROR
					);
				}
			});
	};

	const handleChange = (event: any, field: any) => {
		event.persist();

		setState((prevState) => {
			return {
				...prevState,
				[field]: event.target.value,
			};
		});
	};

	const changeLanguage = (lng: any) => {
		setState((prevState) => {
			return {
				...prevState,
				preferredLanguage: lng,
			};
		});
	};

	const verifyCredentials = (event: any) => {
		if (event.key === "Enter") {
			handleRegister();
		}
	};

	const handleRegister = () => {
		form.validateFields().then(() => {
			setSpinning(true);

			const reqBody = {
				name: state.name,
				commercialName: state.commercialName,
				cui: cleanedCif,
				address: state.address,
				postalCode: state.postalCode,
				phone: state.phone,
				domainOfActivity: state.activityDomain,
				fax: state.fax,
				repName: state.representativeName,
				email: state.email,
				password: state.password,
				preferredLanguage: state.preferredLanguage,
			};

			registerCompany(reqBody)
				.then(() => {
					openNotification(
						t("signupText.messages.successRegister"),
						t("signupText.messages.successRegisterMessage"),
						NOTIFICATION_TYPES.SUCCESS,
						10
					);

					history.push("/autentificare");
				})
				.catch(async (error: any) => {
					const errorMessage = await getErrorFromResponse(error);

					let notificationMessage;

					switch (errorMessage?.code) {
						case ErrorCodes.CompanyAlreadyExists:
							notificationMessage = t("signupText.messages.duplicatedCIF");
							break;
						case ErrorCodes.CompanyUserAlreadyExists:
							notificationMessage = t("signupText.messages.userExists");
							break;
						default:
							notificationMessage = t("signupText.messages.failRegister");
							break;
					}

					openNotification(
						t("signupText.register"),
						notificationMessage,
						NOTIFICATION_TYPES.ERROR
					);
				})
				.finally(() => setSpinning(false));
		});
	};

	return (
		<SimpleLayout>
			<div className="container mx-auto flex flex-col items-center gap-10">
				<div className="italic text-sm">{t("signupText.signupWarning")}</div>
				{step === 1 && (
					<Form.Provider onFormFinish={fetchData}>
						<CustomForm form={form} layout={theme.layout} widthelem={"70%"}>
							<Form.Item
								className="w-full max-w-sm"
								name="cif"
								label={t("signupText.cif") + ":"}
								rules={[
									{
										pattern: new RegExp(/^(RO)?\d{2,10}$/),
										message: t("usersText.invalidField", {
											field: t("signupText.cif"),
										}),
									},
									{
										required: true,
										message: t("usersText.requiredField", {
											field: t("signupText.cif"),
										}),
									},
								]}
							>
								<Input onChange={(event: any) => handleChange(event, "cif")} />
							</Form.Item>

							<Button onClick={() => form.submit()}>
								{t("signupText.continueButton")}
							</Button>
						</CustomForm>
					</Form.Provider>
				)}
				{step === 2 && (
					<Form.Provider>
						<CustomForm
							form={form}
							layout={theme.layout}
							widthelem={"70%"}
							className="max-w-sm"
						>
							<Form.Item
								className="w-full"
								name="name"
								label={t("signupText.companyName") + ":"}
								rules={[
									{
										required: true,
										message: t("usersText.requiredField", {
											field: t("signupText.companyName"),
										}),
										whitespace: true,
									},
								]}
							>
								<Input onChange={(event: any) => handleChange(event, "name")} />
							</Form.Item>

							<Form.Item
								className="w-full"
								name="commercialName"
								label={t("signupText.commercialName") + ":"}
							>
								<Input
									onChange={(event: any) =>
										handleChange(event, "commercialName")
									}
								/>
							</Form.Item>

							<Form.Item
								className="w-full"
								name="cif"
								label={t("signupText.cif") + ":"}
							>
								<Input
									onChange={(event: any) => handleChange(event, "cif")}
									disabled={true}
								/>
							</Form.Item>

							<Form.Item
								className="w-full"
								name="address"
								label={t("signupText.address") + ":"}
								rules={[
									{
										required: true,
										message: t("usersText.requiredField", {
											field: t("signupText.address"),
										}),
										whitespace: true,
									},
								]}
							>
								<Input
									onChange={(event: any) => handleChange(event, "address")}
								/>
							</Form.Item>

							<Form.Item
								className="w-full"
								name="postalCode"
								label={t("signupText.postalCode") + ":"}
								rules={[
									{
										required: true,
										message: t("usersText.requiredField", {
											field: t("signupText.postalCode"),
										}),
										whitespace: true,
									},
								]}
							>
								<Input
									onChange={(event: any) => handleChange(event, "postalCode")}
								/>
							</Form.Item>

							<Form.Item
								className="w-full"
								name="phone"
								label={t("signupText.phone") + ":"}
								rules={[
									{
										pattern: phonePattern,
										message: t("usersText.invalidField", {
											field: t("account.phone"),
										}),
									},
									{
										required: true,
										message: t("usersText.requiredField", {
											field: t("signupText.phone"),
										}),
										whitespace: true,
									},
								]}
							>
								<Input
									onChange={(event: any) => handleChange(event, "phone")}
								/>
							</Form.Item>

							<Form.Item
								className="w-full"
								name="fax"
								label={t("signupText.fax") + ":"}
								rules={[
									{
										pattern: faxPattern,
										message: t("usersText.invalidField", {
											field: t("signupText.fax"),
										}),
									},
								]}
							>
								<Input onChange={(event: any) => handleChange(event, "fax")} />
							</Form.Item>

							<Form.Item
								className="w-full"
								name="activityDomain"
								label={t("signupText.activityDomain") + ":"}
								rules={[
									{
										required: true,
										message: t("usersText.requiredField", {
											field: t("signupText.activityDomain"),
										}),
										whitespace: true,
									},
								]}
							>
								<Input
									onChange={(event: any) =>
										handleChange(event, "activityDomain")
									}
								/>
							</Form.Item>

							<Form.Item
								className="w-full"
								name="representativeName"
								label={t("signupText.companyRepresentativeName") + ":"}
								rules={[
									{
										required: true,
										message: t("usersText.requiredField", {
											field: t("signupText.companyRepresentativeName"),
										}),
										whitespace: true,
									},
								]}
							>
								<Input
									onChange={(event: any) =>
										handleChange(event, "representativeName")
									}
								/>
							</Form.Item>

							<Form.Item
								className="w-full"
								name="email"
								label={t("signupText.companyRepresentativeEmail") + ":"}
								rules={[
									{
										type: "email",
										message: t("usersText.invalidField", {
											field: t("signupText.companyRepresentativeEmail"),
										}),
									},
									{
										required: true,
										message: t("usersText.requiredField", {
											field: t("signupText.companyRepresentativeEmail"),
										}),
									},
								]}
							>
								<Input
									onChange={(event: any) => handleChange(event, "email")}
								/>
							</Form.Item>

							<Form.Item
								className="w-full"
								name="preferredLanguage"
								initialValue={"ro"}
								label={t("account.preferredLanguage") + ":"}
								rules={[
									{
										whitespace: true,
									},
								]}
							>
								<Select onChange={(event: any) => changeLanguage(event)}>
									<Option value="ro">{t("account.romanian")}</Option>
									<Option value="en">{t("account.english")}</Option>
								</Select>
							</Form.Item>

							<Form.Item
								className="w-full"
								name="password"
								label={t("signupText.password") + ":"}
								rules={[
									{
										required: true,
										message: t("usersText.requiredField", {
											field: t("signupText.password"),
										}),
									},
									{
										min: 4,
										message: t("signupText.messages.lengthPasswordError"),
									},
								]}
							>
								<Input.Password
									onChange={(event: any) => handleChange(event, "password")}
									onKeyPress={verifyCredentials}
								/>
							</Form.Item>

							<Form.Item
								className="w-full"
								name="passwordConfirm"
								label={t("signupText.confirmPassword") + ":"}
								rules={[
									{
										required: true,
										message: t("usersText.requiredField", {
											field: t("signupText.confirmPassword"),
										}),
									},
									({ getFieldValue }) => ({
										validator(_, value) {
											if (!value || getFieldValue("password") === value) {
												return Promise.resolve();
											}
											return Promise.reject(
												new Error(t("signupText.messages.mismatchedPasswords"))
											);
										},
									}),
								]}
							>
								<Input.Password
									onChange={(event: any) =>
										handleChange(event, "passwordConfirm")
									}
									onKeyPress={verifyCredentials}
								/>
							</Form.Item>

							<Button onClick={handleRegister}>
								{t("signupText.register")} <Spin spinning={spinning} />
							</Button>

							<Button className="my-6" onClick={() => setStep(1)}>
								{t("signupText.back")}
							</Button>
						</CustomForm>
					</Form.Provider>
				)}
			</div>
		</SimpleLayout>
	);
};

export default Register;
