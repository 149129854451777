/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
	EnrollmentStatus,
	EnrollmentStatusFromJSON,
	EnrollmentStatusFromJSONTyped,
	EnrollmentStatusToJSON,
} from "./EnrollmentStatus";
import {
	InternshipEngagementType,
	InternshipEngagementTypeFromJSON,
	InternshipEngagementTypeFromJSONTyped,
	InternshipEngagementTypeToJSON,
} from "./InternshipEngagementType";

/**
 *
 * @export
 * @interface EnrollmentDTO
 */
export interface EnrollmentDTO {
	/**
	 *
	 * @type {string}
	 * @memberof EnrollmentDTO
	 */
	id?: string;
	/**
	 *
	 * @type {string}
	 * @memberof EnrollmentDTO
	 */
	internshipId?: string;
	/**
	 *
	 * @type {string}
	 * @memberof EnrollmentDTO
	 */
	employerName?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof EnrollmentDTO
	 */
	internshipName?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof EnrollmentDTO
	 */
	studentName?: string | null;
	/**
	 *
	 * @type {EnrollmentStatus}
	 * @memberof EnrollmentDTO
	 */
	recruiterStatus?: EnrollmentStatus;
	/**
	 *
	 * @type {EnrollmentStatus}
	 * @memberof EnrollmentDTO
	 */
	studentStatus?: EnrollmentStatus;
	/**
	 *
	 * @type {InternshipEngagementType}
	 * @memberof EnrollmentDTO
	 */
	engagementType?: InternshipEngagementType;
}

export function EnrollmentDTOFromJSON(json: any): EnrollmentDTO {
	return EnrollmentDTOFromJSONTyped(json, false);
}

export function EnrollmentDTOFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): EnrollmentDTO {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		id: !exists(json, "id") ? undefined : json["id"],
		internshipId: !exists(json, "internshipId")
			? undefined
			: json["internshipId"],
		employerName: !exists(json, "employerName")
			? undefined
			: json["employerName"],
		internshipName: !exists(json, "internshipName")
			? undefined
			: json["internshipName"],
		studentName: !exists(json, "studentName") ? undefined : json["studentName"],
		recruiterStatus: !exists(json, "recruiterStatus")
			? undefined
			: EnrollmentStatusFromJSON(json["recruiterStatus"]),
		studentStatus: !exists(json, "studentStatus")
			? undefined
			: EnrollmentStatusFromJSON(json["studentStatus"]),
		engagementType: !exists(json, "engagementType")
			? undefined
			: InternshipEngagementTypeFromJSON(json["engagementType"]),
	};
}

export function EnrollmentDTOToJSON(value?: EnrollmentDTO | null): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		id: value.id,
		internshipId: value.internshipId,
		employerName: value.employerName,
		internshipName: value.internshipName,
		studentName: value.studentName,
		recruiterStatus: EnrollmentStatusToJSON(value.recruiterStatus),
		studentStatus: EnrollmentStatusToJSON(value.studentStatus),
		engagementType: InternshipEngagementTypeToJSON(value.engagementType),
	};
}
