import Layout from "../../Containers/Layout";
import styles from "./Terms.module.scss";
import { Link } from "react-scroll";
import { useTranslation } from "react-i18next";

const Terms = () => {
	const { t } = useTranslation();

	return (
		<Layout>
			<div className={styles.wrapper}>
				<div
					className={styles.tableofContents}
					style={{ display: window.innerWidth > 1215 ? "block" : "none" }}
				>
					<ul id={"sideMenu"} style={{ listStyle: "none", height: "auto" }}>
						<li>
							<Link activeClass="active" to="terms" spy={true} smooth={true}>
								1. {t("terms.definitions")}
							</Link>
						</li>
						<li>
							<Link to="register" spy={true} smooth={true}>
								2. {t("terms.siteContent")}
							</Link>
						</li>
						<li>
							<Link activeClass="active" to="verify" spy={true} smooth={true}>
								3. {t("terms.siteUtilization")}
							</Link>
						</li>
						<li>
							<Link to="opportunities" spy={true} smooth={true}>
								4. {t("terms.upbResponsibility")}
							</Link>
						</li>
						<li>
							<Link to="payment" spy={true} smooth={true}>
								5. {t("terms.privacyPolicy")}
							</Link>
						</li>
					</ul>
				</div>
				<div className={styles.content}>
					<div className={styles.tableElements}>
						<div id="terms" className={styles.container}>
							<h2>{t("terms.definitions")}</h2>
							<p>
								{t("terms.termsAndConditionsRules")}
								<br />
								{t("terms.domain")}
								<br />
								{t("terms.upb")}
								<br />
								{t("terms.site")}
								<br />
								{t("terms.user")}
								<br />
								{t("terms.utilization")}
								<br />
							</p>
						</div>

						<div id="register" className={styles.container}>
							<h2>{t("terms.siteContent")}</h2>
							{t("terms.siteContent1")}
							<br />
							{t("terms.siteContent2")}
							<br />
							{t("terms.siteContent3")}
							<br />
							{t("terms.siteContent4")}
							<br />
						</div>

						<div id="verify" className={styles.container}>
							<h2>{t("terms.siteUtilization")}</h2>
							{t("terms.utilizationRules")}
							<br />
						</div>

						<div id="opportunities" className={styles.container}>
							<h2>{t("terms.upbResponsibility")}</h2>
							{t("terms.upbResponsibility1")}
							<br />
							{t("terms.upbResponsibility2")}
							<br />
							{t("terms.upbResponsibility3")}
							<br />
							{t("terms.upbResponsibility4")}
							<br />
							{t("terms.upbResponsibility5")}
							<br />
						</div>

						<div id="payment" className={styles.container}>
							<h2>{t("terms.privacyPolicy")}</h2>
							{t("terms.privacyPolicy1")}
							<br />
							{t("terms.privacyPolicy2")}
							<br />
							{t("terms.privacyPolicy2bis")}
							<br />
							{t("terms.privacyPolicy3")}
							<br />
							{t("terms.privacyPolicy4")}
							<br />
							{t("terms.privacyPolicy5")}
							<br />
							{t("terms.privacyPolicy6")}
							<br />
							{t("terms.privacyPolicy7")}
							<br />
						</div>
					</div>
					<h3>{t("terms.rulesApplicationDate")}</h3>
				</div>
			</div>
		</Layout>
	);
};

export default Terms;
