/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum NewsletterSortFieldEnum {
	PublishDate = "PublishDate",
}

export function NewsletterSortFieldEnumFromJSON(
	json: any
): NewsletterSortFieldEnum {
	return NewsletterSortFieldEnumFromJSONTyped(json, false);
}

export function NewsletterSortFieldEnumFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): NewsletterSortFieldEnum {
	return json as NewsletterSortFieldEnum;
}

export function NewsletterSortFieldEnumToJSON(
	value?: NewsletterSortFieldEnum | null
): any {
	return value as any;
}
