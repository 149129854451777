import { UniversityAccordApi, UniversityAccordStateEnum } from "../Api";
import { getAuthConfiguration } from "./configuration";
import { arrayParamAjust } from "../utils/dataUtils";

const AuthUniversityAccordApiFactory = () =>
	new UniversityAccordApi(getAuthConfiguration());

export const generateUniversityAccord = () => {
	return AuthUniversityAccordApiFactory().apiUniversityAccordGenerateAccordGet();
};

export const generateUniversityAccordDocx = () => {
	return AuthUniversityAccordApiFactory().apiUniversityAccordGenerateDocxAccordGet();
};

export const getUniversityAccords = (
	namePattern?: string,
	status?: Array<string>,
	page?: number,
	pageSize?: number
) => {
	return AuthUniversityAccordApiFactory().apiUniversityAccordGetAllGet({
		namePattern,
		status: arrayParamAjust(
			status?.map(
				(e) =>
					UniversityAccordStateEnum[e as keyof typeof UniversityAccordStateEnum]
			)
		),
		page,
		pageSize,
	});
};

export const getUniversityAccordsToSign = (page: number, pageSize: number) => {
	return AuthUniversityAccordApiFactory().apiUniversityAccordGetAllToSignGet({
		page,
		pageSize,
	});
};

export const uploadUniversityAccord = (id: string, file: Blob) => {
	return AuthUniversityAccordApiFactory().apiUniversityAccordUploadSignedIdPost(
		{ id, file }
	);
};

export const setAccordEndDate = (accordId: string, endDate: Date) => {
	return AuthUniversityAccordApiFactory().apiUniversityAccordSetAccordEndDatePost(
		{ accordId, endDate }
	);
};

export const downloadSignedUniversityAccord = (signatureId: string) => {
	return AuthUniversityAccordApiFactory().apiUniversityAccordDownloadFileSignatureIdGet(
		{ signatureId }
	);
};

export const overwriteUniversityAccord = (signatureId: string, file: Blob) => {
	return AuthUniversityAccordApiFactory().apiUniversityAccordOverwriteSignedSignatureIdPut(
		{ signatureId, file }
	);
};

export const reviewChanges = (
	id: string,
	accordAccepted: boolean,
	message?: string,
	file?: Blob
) => {
	return AuthUniversityAccordApiFactory().apiUniversityAccordReviewChangesIdPut(
		{ id, accordAccepted, file, message }
	);
};

export const getAccordsFilters = () => {
	return AuthUniversityAccordApiFactory().apiUniversityAccordGetAccordsFiltersGet();
};
