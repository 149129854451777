/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
	InternshipDocumentAddDTO,
	InternshipDocumentAddDTOFromJSON,
	InternshipDocumentAddDTOToJSON,
	InternshipDocumentDTO,
	InternshipDocumentDTOFromJSON,
	InternshipDocumentDTOToJSON,
	InternshipDocumentUpdateDTO,
	InternshipDocumentUpdateDTOFromJSON,
	InternshipDocumentUpdateDTOToJSON,
} from "../models";

export interface ApiInternshipDocumentAddPostRequest {
	internshipDocumentAddDTO?: InternshipDocumentAddDTO;
}

export interface ApiInternshipDocumentAddSignatureDocumentIdPostRequest {
	documentId: string;
}

export interface ApiInternshipDocumentDeleteIdDeleteRequest {
	id: string;
}

export interface ApiInternshipDocumentGenerateDocumentIdGetRequest {
	id: string;
	type?: string;
}

export interface ApiInternshipDocumentGetForEnrollmentInternshipEnrollmentIdGetRequest {
	internshipEnrollmentId: string;
}

export interface ApiInternshipDocumentUpdateIdPutRequest {
	id: string;
	internshipDocumentUpdateDTO?: InternshipDocumentUpdateDTO;
}

/**
 *
 */
export class InternshipDocumentApi extends runtime.BaseAPI {
	/**
	 */
	async apiInternshipDocumentAddPostRaw(
		requestParameters: ApiInternshipDocumentAddPostRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<void>> {
		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		headerParameters["Content-Type"] = "application/json";

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/InternshipDocument/Add`,
				method: "POST",
				headers: headerParameters,
				query: queryParameters,
				body: InternshipDocumentAddDTOToJSON(
					requestParameters.internshipDocumentAddDTO
				),
			},
			initOverrides
		);

		return new runtime.VoidApiResponse(response);
	}

	/**
	 */
	async apiInternshipDocumentAddPost(
		requestParameters: ApiInternshipDocumentAddPostRequest = {},
		initOverrides?: RequestInit
	): Promise<void> {
		await this.apiInternshipDocumentAddPostRaw(
			requestParameters,
			initOverrides
		);
	}

	/**
	 */
	async apiInternshipDocumentAddSignatureDocumentIdPostRaw(
		requestParameters: ApiInternshipDocumentAddSignatureDocumentIdPostRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<void>> {
		if (
			requestParameters.documentId === null ||
			requestParameters.documentId === undefined
		) {
			throw new runtime.RequiredError(
				"documentId",
				"Required parameter requestParameters.documentId was null or undefined when calling apiInternshipDocumentAddSignatureDocumentIdPost."
			);
		}

		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/InternshipDocument/AddSignature/{documentId}`.replace(
					`{${"documentId"}}`,
					encodeURIComponent(String(requestParameters.documentId))
				),
				method: "POST",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.VoidApiResponse(response);
	}

	/**
	 */
	async apiInternshipDocumentAddSignatureDocumentIdPost(
		requestParameters: ApiInternshipDocumentAddSignatureDocumentIdPostRequest,
		initOverrides?: RequestInit
	): Promise<void> {
		await this.apiInternshipDocumentAddSignatureDocumentIdPostRaw(
			requestParameters,
			initOverrides
		);
	}

	/**
	 */
	async apiInternshipDocumentApproveAllDiplomaDocumentsPutRaw(
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<void>> {
		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/InternshipDocument/ApproveAllDiplomaDocuments`,
				method: "PUT",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.VoidApiResponse(response);
	}

	/**
	 */
	async apiInternshipDocumentApproveAllDiplomaDocumentsPut(
		initOverrides?: RequestInit
	): Promise<void> {
		await this.apiInternshipDocumentApproveAllDiplomaDocumentsPutRaw(
			initOverrides
		);
	}

	/**
	 */
	async apiInternshipDocumentDeleteIdDeleteRaw(
		requestParameters: ApiInternshipDocumentDeleteIdDeleteRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<void>> {
		if (requestParameters.id === null || requestParameters.id === undefined) {
			throw new runtime.RequiredError(
				"id",
				"Required parameter requestParameters.id was null or undefined when calling apiInternshipDocumentDeleteIdDelete."
			);
		}

		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/InternshipDocument/Delete/{id}`.replace(
					`{${"id"}}`,
					encodeURIComponent(String(requestParameters.id))
				),
				method: "DELETE",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.VoidApiResponse(response);
	}

	/**
	 */
	async apiInternshipDocumentDeleteIdDelete(
		requestParameters: ApiInternshipDocumentDeleteIdDeleteRequest,
		initOverrides?: RequestInit
	): Promise<void> {
		await this.apiInternshipDocumentDeleteIdDeleteRaw(
			requestParameters,
			initOverrides
		);
	}

	/**
	 */
	async apiInternshipDocumentGenerateDocumentIdGetRaw(
		requestParameters: ApiInternshipDocumentGenerateDocumentIdGetRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<Blob>> {
		if (requestParameters.id === null || requestParameters.id === undefined) {
			throw new runtime.RequiredError(
				"id",
				"Required parameter requestParameters.id was null or undefined when calling apiInternshipDocumentGenerateDocumentIdGet."
			);
		}

		const queryParameters: any = {};

		if (requestParameters.type !== undefined) {
			queryParameters["type"] = requestParameters.type;
		}

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/InternshipDocument/GenerateDocument/{id}`.replace(
					`{${"id"}}`,
					encodeURIComponent(String(requestParameters.id))
				),
				method: "GET",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.BlobApiResponse(response);
	}

	/**
	 */
	async apiInternshipDocumentGenerateDocumentIdGet(
		requestParameters: ApiInternshipDocumentGenerateDocumentIdGetRequest,
		initOverrides?: RequestInit
	): Promise<Blob> {
		const response = await this.apiInternshipDocumentGenerateDocumentIdGetRaw(
			requestParameters,
			initOverrides
		);
		return await response.value();
	}

	/**
	 */
	async apiInternshipDocumentGetForEnrollmentInternshipEnrollmentIdGetRaw(
		requestParameters: ApiInternshipDocumentGetForEnrollmentInternshipEnrollmentIdGetRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<InternshipDocumentDTO>> {
		if (
			requestParameters.internshipEnrollmentId === null ||
			requestParameters.internshipEnrollmentId === undefined
		) {
			throw new runtime.RequiredError(
				"internshipEnrollmentId",
				"Required parameter requestParameters.internshipEnrollmentId was null or undefined when calling apiInternshipDocumentGetForEnrollmentInternshipEnrollmentIdGet."
			);
		}

		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/InternshipDocument/GetForEnrollment/{internshipEnrollmentId}`.replace(
					`{${"internshipEnrollmentId"}}`,
					encodeURIComponent(String(requestParameters.internshipEnrollmentId))
				),
				method: "GET",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			InternshipDocumentDTOFromJSON(jsonValue)
		);
	}

	/**
	 */
	async apiInternshipDocumentGetForEnrollmentInternshipEnrollmentIdGet(
		requestParameters: ApiInternshipDocumentGetForEnrollmentInternshipEnrollmentIdGetRequest,
		initOverrides?: RequestInit
	): Promise<InternshipDocumentDTO> {
		const response =
			await this.apiInternshipDocumentGetForEnrollmentInternshipEnrollmentIdGetRaw(
				requestParameters,
				initOverrides
			);
		return await response.value();
	}

	/**
	 */
	async apiInternshipDocumentUpdateIdPutRaw(
		requestParameters: ApiInternshipDocumentUpdateIdPutRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<void>> {
		if (requestParameters.id === null || requestParameters.id === undefined) {
			throw new runtime.RequiredError(
				"id",
				"Required parameter requestParameters.id was null or undefined when calling apiInternshipDocumentUpdateIdPut."
			);
		}

		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		headerParameters["Content-Type"] = "application/json";

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/InternshipDocument/Update/{id}`.replace(
					`{${"id"}}`,
					encodeURIComponent(String(requestParameters.id))
				),
				method: "PUT",
				headers: headerParameters,
				query: queryParameters,
				body: InternshipDocumentUpdateDTOToJSON(
					requestParameters.internshipDocumentUpdateDTO
				),
			},
			initOverrides
		);

		return new runtime.VoidApiResponse(response);
	}

	/**
	 */
	async apiInternshipDocumentUpdateIdPut(
		requestParameters: ApiInternshipDocumentUpdateIdPutRequest,
		initOverrides?: RequestInit
	): Promise<void> {
		await this.apiInternshipDocumentUpdateIdPutRaw(
			requestParameters,
			initOverrides
		);
	}
}
