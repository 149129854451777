/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
	UniversityAccordSigningEnum,
	UniversityAccordSigningEnumFromJSON,
	UniversityAccordSigningEnumFromJSONTyped,
	UniversityAccordSigningEnumToJSON,
} from "./UniversityAccordSigningEnum";

/**
 *
 * @export
 * @interface UniversityAccordSignatureDTO
 */
export interface UniversityAccordSignatureDTO {
	/**
	 *
	 * @type {string}
	 * @memberof UniversityAccordSignatureDTO
	 */
	id?: string;
	/**
	 *
	 * @type {string}
	 * @memberof UniversityAccordSignatureDTO
	 */
	accordId?: string;
	/**
	 *
	 * @type {UniversityAccordSigningEnum}
	 * @memberof UniversityAccordSignatureDTO
	 */
	signer?: UniversityAccordSigningEnum;
	/**
	 *
	 * @type {Date}
	 * @memberof UniversityAccordSignatureDTO
	 */
	signedAt?: Date;
}

export function UniversityAccordSignatureDTOFromJSON(
	json: any
): UniversityAccordSignatureDTO {
	return UniversityAccordSignatureDTOFromJSONTyped(json, false);
}

export function UniversityAccordSignatureDTOFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): UniversityAccordSignatureDTO {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		id: !exists(json, "id") ? undefined : json["id"],
		accordId: !exists(json, "accordId") ? undefined : json["accordId"],
		signer: !exists(json, "signer")
			? undefined
			: UniversityAccordSigningEnumFromJSON(json["signer"]),
		signedAt: !exists(json, "signedAt")
			? undefined
			: new Date(json["signedAt"]),
	};
}

export function UniversityAccordSignatureDTOToJSON(
	value?: UniversityAccordSignatureDTO | null
): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		id: value.id,
		accordId: value.accordId,
		signer: UniversityAccordSigningEnumToJSON(value.signer),
		signedAt:
			value.signedAt === undefined ? undefined : value.signedAt.toISOString(),
	};
}
