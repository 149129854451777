import { Modal, Input, Form, Row, DatePicker, Col, Spin, Button } from "antd";
import { useState } from "react";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../Notifications/NotificationsUtils";
import { StatusCodes } from "http-status-codes";
import { theme } from "../../theme";
import {
	addStudentPersonalData,
	updateStudentPersonalData,
	getStudentPersonalDataForStudent,
} from "../../Requests/student-personal-data-requests";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useQuery } from "react-query";
import { getUserId, useIsAdmin } from "../../utils/utilFunctions";
import CustomModalFooter from "../../Containers/CustomModalFooter";
import { getStudentPersonalData } from "../../utils/reactQueriesConstants";
import { StudentPersonalDataAddUpdateDTO } from "../../Api";

const dateFormatList = "YYYY-MM-DD";

const PersonalDataModal = (props: { type: string; studentId?: string }) => {
	const { t } = useTranslation();
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [spinning, setSpinning] = useState(false);
	const [personalDataId, setPersonalDataId] = useState("");
	const [form] = Form.useForm();
	const isAdmin = useIsAdmin();

	const openGetErrorNotification = (_error: any) => {
		openNotification(
			t("studentPersonalData.error"),
			t("studentPersonalData.fetchDataError"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	useQuery(
		[getStudentPersonalData, isAdmin],
		async () =>
			await getStudentPersonalDataForStudent(
				isAdmin ? props.studentId : getUserId()
			),
		{
			refetchOnWindowFocus: false,
			onError: (err) => {
				if (props.type === "edit") {
					openGetErrorNotification(err);
				}
			},
			onSuccess: (data) => {
				if (data) {
					setPersonalDataId(data.id!);
					form.setFieldsValue({
						birthPlace: data.birthPlace!,
						city: data.city!,
						county: data.county!,
						streetNumber: data.streetNumber!,
						streetName: data.streetName!,
						building: data.building,
						floor: data.floor,
						apartment: data.apartment,
						identitySeries: data.identitySeries!,
						identityNumber: data.identityNumber!,
						identityIssuer: data.identityIssuer!,
						identityDate: moment(data.identityDate),
						cnp: data.cnp!,
						passport: data.passport,
						residencePermit: data.residencePermit,
						birthdate: moment(data.birthdate),
					});
				}
			},
		}
	);

	const openSuccessNotification = () => {
		openNotification(
			t("studentPersonalData.editPersonalData"),
			t("studentPersonalData.editPersonalDataSuccess"),
			NOTIFICATION_TYPES.SUCCESS,
			6
		);
	};

	const handleShow = () => {
		setIsModalVisible(true);
	};

	const handleClose = () => {
		setIsModalVisible(false);
	};

	const handleSave = () => {
		const state = form.getFieldsValue();

		if (
			!state.birthPlace ||
			!state.city ||
			!state.county ||
			!state.streetNumber ||
			!state.streetName ||
			!state.identitySeries ||
			!state.identityNumber ||
			!state.identityIssuer ||
			state.identityDate === null ||
			state.birthdate === null ||
			!state.cnp
		) {
			openNotification(
				t("studentPersonalData.saveError"),
				t("usersText.requiredFieldsError"),
				NOTIFICATION_TYPES.ERROR
			);
		} else {
			setSpinning(true);
			let obj: StudentPersonalDataAddUpdateDTO = {
				birthPlace: state.birthPlace,
				city: state.city,
				county: state.county,
				streetNumber: state.streetNumber,
				streetName: state.streetName,
				building: state.building,
				floor: state.floor,
				apartment: state.apartment,
				identitySeries: state.identitySeries,
				identityNumber: state.identityNumber,
				identityIssuer: state.identityIssuer,
				identityDate: state.identityDate.toDate(),
				birthdate: state.birthdate.toDate(),
				cnp: state.cnp,
				passport: state.passport,
				residencePermit: state.residencePermit,
			};

			(personalDataId
				? updateStudentPersonalData(personalDataId, obj)
				: addStudentPersonalData(obj)
			)
				.then(openSuccessNotification)
				.catch((ex: any) => {
					if (ex.status && ex.status === StatusCodes.CONFLICT) {
						openNotification(
							t("studentPersonalData.conflict"),
							t("studentPersonalData.conflictUser"),
							NOTIFICATION_TYPES.ERROR
						);
					} else {
						openNotification(
							t("studentPersonalData.error"),
							t("studentPersonalData.unknownError"),
							NOTIFICATION_TYPES.ERROR
						);
						setIsModalVisible(false);
					}
				});
			setSpinning(false);
			setIsModalVisible(false);
		}
	};

	return (
		<>
			<Button title={t("studentPersonalData.addData")} onClick={handleShow}>
				{t("studentPersonalData.addData")}
			</Button>

			<Modal
				open={isModalVisible}
				onOk={() => form.submit()}
				onCancel={handleClose}
				title={t("studentPersonalData.addData")}
				width={window.innerWidth > 1215 ? "55%" : "80%"}
				footer={
					<CustomModalFooter
						handleClose={handleClose}
						spinning={spinning}
						handleSave={() => form.submit()}
						confirmButtonName={t("account.save")}
					/>
				}
			>
				<Spin spinning={spinning}>
					<Form.Provider onFormFinish={handleSave}>
						<Form
							form={form}
							layout={theme.layout}
							action="/student"
							style={{ width: "100%" }}
						>
							<div style={{ width: "100%" }}>
								<Row gutter={8}>
									<Col span={8}>
										<Form.Item
											name="cnp"
											label={t("studentPersonalData.cnp") + ":"}
											rules={[
												{
													required: true,
													message: t("usersText.requiredField", {
														field: t("studentPersonalData.cnp"),
													}),
													whitespace: true,
												},
											]}
										>
											<Input placeholder={t("studentPersonalData.cnp")} />
										</Form.Item>
									</Col>
									<Col span={8}>
										<Form.Item
											name="passport"
											label={t("studentPersonalData.passport") + ":"}
											rules={[
												{
													required: false,
													whitespace: true,
												},
											]}
										>
											<Input
												placeholder={t(
													"studentPersonalData.passportPlaceholder"
												)}
											/>
										</Form.Item>
									</Col>
									<Col span={8}>
										<Form.Item
											name="residencePermit"
											label={t("studentPersonalData.residencePermit") + ":"}
											rules={[
												{
													required: false,
													whitespace: true,
												},
											]}
										>
											<Input
												placeholder={t(
													"studentPersonalData.residencePermitPlaceholder"
												)}
											/>
										</Form.Item>
									</Col>
								</Row>
								<Row gutter={8}>
									<Col xs={24} sm={12} md={12} lg={12} xl={6}>
										<Form.Item
											name="birthPlace"
											label={t("studentPersonalData.birthPlace") + ":"}
											rules={[
												{
													required: true,
													message: t("usersText.requiredField", {
														field: t("studentPersonalData.birthPlace"),
													}),
													whitespace: true,
												},
											]}
										>
											<Input
												placeholder={t("studentPersonalData.birthPlace")}
											/>
										</Form.Item>
									</Col>
									<Col xs={24} sm={12} md={12} lg={12} xl={6}>
										<Form.Item
											name="city"
											label={t("studentPersonalData.city") + ":"}
											rules={[
												{
													required: true,
													message: t("usersText.requiredField", {
														field: t("studentPersonalData.city"),
													}),
													whitespace: true,
												},
											]}
										>
											<Input placeholder={t("studentPersonalData.city")} />
										</Form.Item>
									</Col>
									<Col xs={24} sm={12} md={12} lg={12} xl={6}>
										<Form.Item
											name="county"
											label={t("studentPersonalData.county") + ":"}
											rules={[
												{
													required: true,
													message: t("usersText.requiredField", {
														field: t("studentPersonalData.county"),
													}),
													whitespace: true,
												},
											]}
										>
											<Input placeholder={t("studentPersonalData.county")} />
										</Form.Item>
									</Col>
									<Col xs={24} sm={12} md={12} lg={12} xl={6}>
										<Form.Item
											name="streetName"
											label={t("studentPersonalData.streetName") + ":"}
											rules={[
												{
													required: true,
													message: t("usersText.requiredField", {
														field: t("studentPersonalData.streetName"),
													}),
													whitespace: true,
												},
											]}
										>
											<Input
												placeholder={t("studentPersonalData.streetName")}
											/>
										</Form.Item>
									</Col>
								</Row>

								<Row gutter={8}>
									<Col xs={24} sm={12} md={12} lg={12} xl={6}>
										<Form.Item
											name="streetNumber"
											label={t("studentPersonalData.streetNumber") + ":"}
											rules={[
												{
													required: true,
													message: t("usersText.requiredField", {
														field: t("studentPersonalData.streetNumber"),
													}),
													whitespace: true,
												},
											]}
										>
											<Input
												placeholder={t("studentPersonalData.streetNumber")}
											/>
										</Form.Item>
									</Col>
									<Col xs={24} sm={12} md={12} lg={12} xl={6}>
										<Form.Item
											name="building"
											label={t("studentPersonalData.building") + ":"}
											rules={[
												{
													message: t("studentPersonalData.buildingPlaceholder"),
													whitespace: true,
												},
											]}
										>
											<Input placeholder={t("studentPersonalData.building")} />
										</Form.Item>
									</Col>

									<Col xs={24} sm={12} md={12} lg={12} xl={6}>
										<Form.Item
											name="floor"
											label={t("studentPersonalData.floor") + ":"}
											rules={[
												{
													message: t("studentPersonalData.floorPlaceholder"),
													whitespace: true,
												},
											]}
										>
											<Input placeholder={t("studentPersonalData.floor")} />
										</Form.Item>
									</Col>

									<Col xs={24} sm={12} md={12} lg={12} xl={6}>
										<Form.Item
											name="apartment"
											label={t("studentPersonalData.apartment") + ":"}
											rules={[
												{
													message: t(
														"studentPersonalData.apartmentPlaceholder"
													),
													whitespace: true,
												},
											]}
										>
											<Input placeholder={t("studentPersonalData.apartment")} />
										</Form.Item>
									</Col>
								</Row>

								<Row gutter={8}>
									<Col xs={24} sm={12} md={12} lg={12} xl={5}>
										<Form.Item
											name="identitySeries"
											label={t("studentPersonalData.identitySeries") + ":"}
											rules={[
												{
													required: true,
													message: t("usersText.requiredField", {
														field: t("studentPersonalData.identitySeries"),
													}),
													whitespace: true,
												},
											]}
										>
											<Input
												placeholder={t("studentPersonalData.identitySeries")}
											/>
										</Form.Item>
									</Col>

									<Col xs={24} sm={12} md={12} lg={12} xl={5}>
										<Form.Item
											name="identityNumber"
											label={t("studentPersonalData.identityNumber") + ":"}
											rules={[
												{
													required: true,
													message: t("usersText.requiredField", {
														field: t("studentPersonalData.identityNumber"),
													}),
													whitespace: true,
												},
											]}
										>
											<Input
												placeholder={t("studentPersonalData.identityNumber")}
											/>
										</Form.Item>
									</Col>

									<Col xs={24} sm={12} md={12} lg={12} xl={5}>
										<Form.Item
											name="identityDate"
											label={t("studentPersonalData.identityDate") + ":"}
											rules={[
												{
													required: true,
													message: t("usersText.requiredField", {
														field: t("studentPersonalData.identityDate"),
													}),
												},
											]}
										>
											<DatePicker
												format={dateFormatList}
												style={{ width: "100%", height: "32px" }}
											/>
										</Form.Item>
									</Col>
									<Col xs={24} sm={12} md={12} lg={12} xl={9}>
										<Form.Item
											name="identityIssuer"
											label={t("studentPersonalData.identityIssuer") + ":"}
											rules={[
												{
													required: true,
													message: t("usersText.requiredField", {
														field: t("studentPersonalData.identityIssuer"),
													}),
													whitespace: true,
												},
											]}
										>
											<Input
												placeholder={t("studentPersonalData.identityIssuer")}
											/>
										</Form.Item>
									</Col>
								</Row>
								<Row gutter={8}>
									<Col xs={24} sm={12} md={12} lg={12} xl={5}>
										<Form.Item
											name="birthdate"
											label={t("studentPersonalData.birthdate") + ":"}
											rules={[
												{
													required: true,
													message: t("usersText.requiredField", {
														field: t("studentPersonalData.birthdate"),
													}),
												},
											]}
										>
											<DatePicker
												format={dateFormatList}
												style={{ width: "100%", height: "32px" }}
											/>
										</Form.Item>
									</Col>
								</Row>
							</div>
						</Form>
					</Form.Provider>
				</Spin>
			</Modal>
		</>
	);
};

export default PersonalDataModal;
