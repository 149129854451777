import {
	PoliJobsContactDetailsApi,
	PoliJobsContactDetailsAddUpdateDTO,
} from "../Api";
import { getAuthConfiguration } from "./configuration";

const AuthPoliJobsContactDetailsApiFactory = () =>
	new PoliJobsContactDetailsApi(getAuthConfiguration());

export const getPoliJobsContactDetailsForStudent = (studentId: string) => {
	return AuthPoliJobsContactDetailsApiFactory().apiPoliJobsContactDetailsGetForStudentStudentIdGet(
		{ studentId }
	);
};

export const addPoliJobsContactDetails = async (
	studentId: string,
	poliJobsContactDetailsAddUpdateDTO: PoliJobsContactDetailsAddUpdateDTO
) => {
	await AuthPoliJobsContactDetailsApiFactory().apiPoliJobsContactDetailsAddStudentIdPut(
		{
			studentId,
			poliJobsContactDetailsAddUpdateDTO,
		}
	);
};

export const updatePoliJobsContactDetails = async (
	id: string,
	poliJobsContactDetailsAddUpdateDTO: PoliJobsContactDetailsAddUpdateDTO
) => {
	await AuthPoliJobsContactDetailsApiFactory().apiPoliJobsContactDetailsUpdateIdPut(
		{
			id,
			poliJobsContactDetailsAddUpdateDTO,
		}
	);
};
