/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum InternType {
	Applicant = "Applicant",
	Trainee = "Trainee",
}

export function InternTypeFromJSON(json: any): InternType {
	return InternTypeFromJSONTyped(json, false);
}

export function InternTypeFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): InternType {
	return json as InternType;
}

export function InternTypeToJSON(value?: InternType | null): any {
	return value as any;
}
