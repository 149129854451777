/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
	AcademicUser,
	AcademicUserFromJSON,
	AcademicUserFromJSONTyped,
	AcademicUserToJSON,
} from "./AcademicUser";
import {
	Internship,
	InternshipFromJSON,
	InternshipFromJSONTyped,
	InternshipToJSON,
} from "./Internship";
import {
	ProfessorInternshipCoTutor,
	ProfessorInternshipCoTutorFromJSON,
	ProfessorInternshipCoTutorFromJSONTyped,
	ProfessorInternshipCoTutorToJSON,
} from "./ProfessorInternshipCoTutor";

/**
 *
 * @export
 * @interface ProfessorInternship
 */
export interface ProfessorInternship {
	/**
	 *
	 * @type {string}
	 * @memberof ProfessorInternship
	 */
	id?: string;
	/**
	 *
	 * @type {Date}
	 * @memberof ProfessorInternship
	 */
	createdAt?: Date;
	/**
	 *
	 * @type {Date}
	 * @memberof ProfessorInternship
	 */
	updatedAt?: Date;
	/**
	 *
	 * @type {string}
	 * @memberof ProfessorInternship
	 */
	professorId?: string;
	/**
	 *
	 * @type {string}
	 * @memberof ProfessorInternship
	 */
	internshipId?: string;
	/**
	 *
	 * @type {string}
	 * @memberof ProfessorInternship
	 */
	coTutorId?: string | null;
	/**
	 *
	 * @type {boolean}
	 * @memberof ProfessorInternship
	 */
	isValid?: boolean;
	/**
	 *
	 * @type {boolean}
	 * @memberof ProfessorInternship
	 */
	isViewOnly?: boolean;
	/**
	 *
	 * @type {AcademicUser}
	 * @memberof ProfessorInternship
	 */
	professor?: AcademicUser;
	/**
	 *
	 * @type {Internship}
	 * @memberof ProfessorInternship
	 */
	internship?: Internship;
	/**
	 *
	 * @type {Array<ProfessorInternshipCoTutor>}
	 * @memberof ProfessorInternship
	 */
	professorInternshipCoTutors?: Array<ProfessorInternshipCoTutor> | null;
	/**
	 *
	 * @type {Array<AcademicUser>}
	 * @memberof ProfessorInternship
	 */
	coTutors?: Array<AcademicUser> | null;
}

export function ProfessorInternshipFromJSON(json: any): ProfessorInternship {
	return ProfessorInternshipFromJSONTyped(json, false);
}

export function ProfessorInternshipFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): ProfessorInternship {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		id: !exists(json, "id") ? undefined : json["id"],
		createdAt: !exists(json, "createdAt")
			? undefined
			: new Date(json["createdAt"]),
		updatedAt: !exists(json, "updatedAt")
			? undefined
			: new Date(json["updatedAt"]),
		professorId: !exists(json, "professorId") ? undefined : json["professorId"],
		internshipId: !exists(json, "internshipId")
			? undefined
			: json["internshipId"],
		coTutorId: !exists(json, "coTutorId") ? undefined : json["coTutorId"],
		isValid: !exists(json, "isValid") ? undefined : json["isValid"],
		isViewOnly: !exists(json, "isViewOnly") ? undefined : json["isViewOnly"],
		professor: !exists(json, "professor")
			? undefined
			: AcademicUserFromJSON(json["professor"]),
		internship: !exists(json, "internship")
			? undefined
			: InternshipFromJSON(json["internship"]),
		professorInternshipCoTutors: !exists(json, "professorInternshipCoTutors")
			? undefined
			: json["professorInternshipCoTutors"] === null
			? null
			: (json["professorInternshipCoTutors"] as Array<any>).map(
					ProfessorInternshipCoTutorFromJSON
			  ),
		coTutors: !exists(json, "coTutors")
			? undefined
			: json["coTutors"] === null
			? null
			: (json["coTutors"] as Array<any>).map(AcademicUserFromJSON),
	};
}

export function ProfessorInternshipToJSON(
	value?: ProfessorInternship | null
): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		id: value.id,
		createdAt:
			value.createdAt === undefined ? undefined : value.createdAt.toISOString(),
		updatedAt:
			value.updatedAt === undefined ? undefined : value.updatedAt.toISOString(),
		professorId: value.professorId,
		internshipId: value.internshipId,
		coTutorId: value.coTutorId,
		isValid: value.isValid,
		isViewOnly: value.isViewOnly,
		professor: AcademicUserToJSON(value.professor),
		internship: InternshipToJSON(value.internship),
		professorInternshipCoTutors:
			value.professorInternshipCoTutors === undefined
				? undefined
				: value.professorInternshipCoTutors === null
				? null
				: (value.professorInternshipCoTutors as Array<any>).map(
						ProfessorInternshipCoTutorToJSON
				  ),
		coTutors:
			value.coTutors === undefined
				? undefined
				: value.coTutors === null
				? null
				: (value.coTutors as Array<any>).map(AcademicUserToJSON),
	};
}
