/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
	CompanyDocumentStatusEnum,
	CompanyDocumentStatusEnumFromJSON,
	CompanyDocumentStatusEnumFromJSONTyped,
	CompanyDocumentStatusEnumToJSON,
} from "./CompanyDocumentStatusEnum";
import {
	CompanyStateEnum,
	CompanyStateEnumFromJSON,
	CompanyStateEnumFromJSONTyped,
	CompanyStateEnumToJSON,
} from "./CompanyStateEnum";
import {
	PreferredLanguageEnum,
	PreferredLanguageEnumFromJSON,
	PreferredLanguageEnumFromJSONTyped,
	PreferredLanguageEnumToJSON,
} from "./PreferredLanguageEnum";

/**
 *
 * @export
 * @interface CompanyDTO
 */
export interface CompanyDTO {
	/**
	 *
	 * @type {string}
	 * @memberof CompanyDTO
	 */
	id?: string;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyDTO
	 */
	name?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyDTO
	 */
	commercialName?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyDTO
	 */
	cui?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyDTO
	 */
	email?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyDTO
	 */
	address?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyDTO
	 */
	postalCode?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyDTO
	 */
	city?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyDTO
	 */
	country?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyDTO
	 */
	phone?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyDTO
	 */
	domainOfActivity?: string | null;
	/**
	 *
	 * @type {Array<string>}
	 * @memberof CompanyDTO
	 */
	areasOfInterest?: Array<string> | null;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyDTO
	 */
	fax?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyDTO
	 */
	siteUrl?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyDTO
	 */
	linkedInUrl?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyDTO
	 */
	description?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyDTO
	 */
	lowResLogo?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyDTO
	 */
	fullResLogo?: string | null;
	/**
	 *
	 * @type {boolean}
	 * @memberof CompanyDTO
	 */
	isContact?: boolean;
	/**
	 *
	 * @type {Array<string>}
	 * @memberof CompanyDTO
	 */
	facultiesOfInterestAcronyms?: Array<string> | null;
	/**
	 *
	 * @type {CompanyStateEnum}
	 * @memberof CompanyDTO
	 */
	state?: CompanyStateEnum;
	/**
	 *
	 * @type {PreferredLanguageEnum}
	 * @memberof CompanyDTO
	 */
	preferredLanguage?: PreferredLanguageEnum;
	/**
	 *
	 * @type {CompanyDocumentStatusEnum}
	 * @memberof CompanyDTO
	 */
	companyDocumentStatus?: CompanyDocumentStatusEnum;
}

export function CompanyDTOFromJSON(json: any): CompanyDTO {
	return CompanyDTOFromJSONTyped(json, false);
}

export function CompanyDTOFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): CompanyDTO {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		id: !exists(json, "id") ? undefined : json["id"],
		name: !exists(json, "name") ? undefined : json["name"],
		commercialName: !exists(json, "commercialName")
			? undefined
			: json["commercialName"],
		cui: !exists(json, "cui") ? undefined : json["cui"],
		email: !exists(json, "email") ? undefined : json["email"],
		address: !exists(json, "address") ? undefined : json["address"],
		postalCode: !exists(json, "postalCode") ? undefined : json["postalCode"],
		city: !exists(json, "city") ? undefined : json["city"],
		country: !exists(json, "country") ? undefined : json["country"],
		phone: !exists(json, "phone") ? undefined : json["phone"],
		domainOfActivity: !exists(json, "domainOfActivity")
			? undefined
			: json["domainOfActivity"],
		areasOfInterest: !exists(json, "areasOfInterest")
			? undefined
			: json["areasOfInterest"],
		fax: !exists(json, "fax") ? undefined : json["fax"],
		siteUrl: !exists(json, "siteUrl") ? undefined : json["siteUrl"],
		linkedInUrl: !exists(json, "linkedInUrl") ? undefined : json["linkedInUrl"],
		description: !exists(json, "description") ? undefined : json["description"],
		lowResLogo: !exists(json, "lowResLogo") ? undefined : json["lowResLogo"],
		fullResLogo: !exists(json, "fullResLogo") ? undefined : json["fullResLogo"],
		isContact: !exists(json, "isContact") ? undefined : json["isContact"],
		facultiesOfInterestAcronyms: !exists(json, "facultiesOfInterestAcronyms")
			? undefined
			: json["facultiesOfInterestAcronyms"],
		state: !exists(json, "state")
			? undefined
			: CompanyStateEnumFromJSON(json["state"]),
		preferredLanguage: !exists(json, "preferredLanguage")
			? undefined
			: PreferredLanguageEnumFromJSON(json["preferredLanguage"]),
		companyDocumentStatus: !exists(json, "companyDocumentStatus")
			? undefined
			: CompanyDocumentStatusEnumFromJSON(json["companyDocumentStatus"]),
	};
}

export function CompanyDTOToJSON(value?: CompanyDTO | null): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		id: value.id,
		name: value.name,
		commercialName: value.commercialName,
		cui: value.cui,
		email: value.email,
		address: value.address,
		postalCode: value.postalCode,
		city: value.city,
		country: value.country,
		phone: value.phone,
		domainOfActivity: value.domainOfActivity,
		areasOfInterest: value.areasOfInterest,
		fax: value.fax,
		siteUrl: value.siteUrl,
		linkedInUrl: value.linkedInUrl,
		description: value.description,
		lowResLogo: value.lowResLogo,
		fullResLogo: value.fullResLogo,
		isContact: value.isContact,
		facultiesOfInterestAcronyms: value.facultiesOfInterestAcronyms,
		state: CompanyStateEnumToJSON(value.state),
		preferredLanguage: PreferredLanguageEnumToJSON(value.preferredLanguage),
		companyDocumentStatus: CompanyDocumentStatusEnumToJSON(
			value.companyDocumentStatus
		),
	};
}
