/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
	InternshipLimitsDTO,
	InternshipLimitsDTOFromJSON,
	InternshipLimitsDTOFromJSONTyped,
	InternshipLimitsDTOToJSON,
} from "./InternshipLimitsDTO";
import {
	PracticeInternshipPeriodDTO,
	PracticeInternshipPeriodDTOFromJSON,
	PracticeInternshipPeriodDTOFromJSONTyped,
	PracticeInternshipPeriodDTOToJSON,
} from "./PracticeInternshipPeriodDTO";

/**
 *
 * @export
 * @interface FacultySettingsDTO
 */
export interface FacultySettingsDTO {
	/**
	 *
	 * @type {string}
	 * @memberof FacultySettingsDTO
	 */
	readonly id?: string;
	/**
	 *
	 * @type {string}
	 * @memberof FacultySettingsDTO
	 */
	readonly decisionNumber?: string | null;
	/**
	 *
	 * @type {Array<PracticeInternshipPeriodDTO>}
	 * @memberof FacultySettingsDTO
	 */
	readonly practiceInternshipPeriods?: Array<PracticeInternshipPeriodDTO> | null;
	/**
	 *
	 * @type {Array<InternshipLimitsDTO>}
	 * @memberof FacultySettingsDTO
	 */
	readonly internshipLimits?: Array<InternshipLimitsDTO> | null;
}

export function FacultySettingsDTOFromJSON(json: any): FacultySettingsDTO {
	return FacultySettingsDTOFromJSONTyped(json, false);
}

export function FacultySettingsDTOFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): FacultySettingsDTO {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		id: !exists(json, "id") ? undefined : json["id"],
		decisionNumber: !exists(json, "decisionNumber")
			? undefined
			: json["decisionNumber"],
		practiceInternshipPeriods: !exists(json, "practiceInternshipPeriods")
			? undefined
			: json["practiceInternshipPeriods"] === null
			? null
			: (json["practiceInternshipPeriods"] as Array<any>).map(
					PracticeInternshipPeriodDTOFromJSON
			  ),
		internshipLimits: !exists(json, "internshipLimits")
			? undefined
			: json["internshipLimits"] === null
			? null
			: (json["internshipLimits"] as Array<any>).map(
					InternshipLimitsDTOFromJSON
			  ),
	};
}

export function FacultySettingsDTOToJSON(
	value?: FacultySettingsDTO | null
): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {};
}
