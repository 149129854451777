import { ApplicantActionsProps } from "./ApplicantActions.types";
import styles from "../../../ApplicantsView.module.scss";
import { Space } from "antd";
import {
	EnrollmentStatus,
	InternshipEngagementType,
	InternshipType,
} from "../../../../../../Api";
import CustomButton from "../../../../../../CustomComponents/CustomButton";
import { theme } from "../../../../../../theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import {
	getUserId,
	useIsCompany,
	useIsProfessor,
} from "../../../../../../utils/utilFunctions";
import { useQuery, useQueryClient } from "react-query";
import {
	getCompanyUser,
	getInterns,
	getTraineesFilters,
} from "../../../../../../utils/reactQueriesConstants";
import { getCompanyUserById } from "../../../../../../Requests/company-users-requests";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../../../../../Notifications/NotificationsUtils";
import { useTranslation } from "react-i18next";
import { updateIsEnrollmentViewed } from "../../../../../../Requests/internship-requests";

export const ApplicantActions = (props: ApplicantActionsProps) => {
	const { t } = useTranslation();
	const isProfessor = useIsProfessor();
	const isCompany = useIsCompany();
	const queryClient = useQueryClient();

	const updateViewApplication = (id: any, viewed: boolean) => {
		updateIsEnrollmentViewed(id, viewed).then(async () => {
			await queryClient.invalidateQueries(getInterns);
			await queryClient.invalidateQueries(getTraineesFilters);
		});
	};

	const openNotificationFetchError = (ex: any) => {
		if (ex.status) {
			openNotification(
				t("usersText.errorTexts.failedUserGet"),
				t("usersText.errorTexts.serverFailedDescription"),
				NOTIFICATION_TYPES.ERROR
			);
		} else {
			openNotification(
				t("usersText.errorTexts.failedUserGet"),
				t("usersText.errorTexts.networkFailedDescription"),
				NOTIFICATION_TYPES.ERROR
			);
		}
	};

	const { data: companyUser } = useQuery(
		[getCompanyUser, isCompany],
		async () => (isCompany ? getCompanyUserById(getUserId()) : null),
		{
			onError: openNotificationFetchError,
		}
	);

	return (
		<div className={styles.modalContainer} key={"actions-" + props.record.id}>
			<Space>
				{(props.record.recruiterStatus === EnrollmentStatus.Waiting ||
					(props.record.recruiterStatus ===
						EnrollmentStatus.ProfessorAccepted &&
						!isProfessor)) && (
					<div className={styles.modalBtnContainer}>
						<CustomButton
							fontSize={"0.9rem"}
							style={{
								background: "transparent",
								border: "none",
								outline: "none",
								color: theme.black,
								boxShadow: "none",
								padding: 0,
								marginTop: 0,
							}}
							onClick={() => {
								props.setSelectedApplicant(props.record);
								props.setSelectedTutor(props.record.internshipTutorId!);
								props.setAcceptModalVisibility(true);
							}}
							title={t("students.acceptApplicant")}
							icon={
								<FontAwesomeIcon
									icon={solid("circle-check")}
									style={{ fontSize: "15px" }}
								/>
							}
						/>
					</div>
				)}
				{props.record.recruiterStatus === EnrollmentStatus.Waiting &&
					(props.record.internshipEngagementType ===
						InternshipEngagementType.BachelorsInternship ||
						props.record.internshipEngagementType ===
							InternshipEngagementType.MastersInternship) &&
					(!isProfessor ||
						props.record.internshipType === InternshipType.ByProfessor) && (
						<div className={styles.modalBtnContainer}>
							<CustomButton
								fontSize={"0.9rem"}
								style={{
									background: "transparent",
									border: "none",
									outline: "none",
									color: theme.black,
									boxShadow: "none",
									padding: 0,
									marginTop: 0,
								}}
								onClick={() => {
									props.setSelectedApplicant(props.record);
									props.setFinalAcceptModalVisibility(true);
								}}
								title={t("students.finalAcceptApplicant")}
								icon={
									<FontAwesomeIcon
										icon={solid("check-double")}
										style={{ fontSize: "15px" }}
									/>
								}
							/>
						</div>
					)}
				{(props.record.recruiterStatus === EnrollmentStatus.Waiting ||
					(props.record.recruiterStatus ===
						EnrollmentStatus.ProfessorAccepted &&
						!isProfessor)) && (
					<div className={styles.modalBtnContainer}>
						<CustomButton
							fontSize={"0.9rem"}
							style={{
								background: "transparent",
								border: "none",
								outline: "none",
								color: theme.black,
								boxShadow: "none",
								padding: 0,
								marginTop: 0,
							}}
							onClick={() => {
								props.setSelectedApplicant(props.record);
								props.setRejectModalVisibility(true);
							}}
							title={t("students.rejectApplicant")}
							icon={
								<FontAwesomeIcon
									icon={solid("ban")}
									style={{ fontSize: "15px" }}
								/>
							}
						/>
					</div>
				)}
				{((isProfessor && props.record.internshipEmployerId === getUserId()) ||
					(isCompany &&
						props.record.internshipEmployerId === companyUser?.companyId)) &&
					props.record.recruiterStatus === EnrollmentStatus.Accepted &&
					props.record.studentStatus === EnrollmentStatus.Accepted && (
						<div className={styles.modalBtnContainer}>
							<CustomButton
								fontSize={"0.9rem"}
								style={{
									background: "transparent",
									border: "none",
									outline: "none",
									color: theme.black,
									boxShadow: "none",
									padding: 0,
									marginTop: 0,
								}}
								onClick={() => {
									props.setSelectedApplicant(props.record);
									props.setRemoveModalVisibility(true);
								}}
								title={t("students.removeApplicant")}
								icon={
									<FontAwesomeIcon
										icon={solid("trash")}
										style={{ fontSize: "15px" }}
									/>
								}
							/>
						</div>
					)}
				{props.record.enrollmentExpired &&
				props.record.recruiterStatus === EnrollmentStatus.Accepted &&
				props.record.studentStatus !== EnrollmentStatus.Accepted &&
				((isProfessor && props.record.internshipEmployerId === getUserId()) ||
					(isCompany &&
						props.record.internshipEmployerId === companyUser?.companyId)) ? (
					<div className={styles.modalBtnContainer}>
						<CustomButton
							fontSize={"0.9rem"}
							style={{
								background: "transparent",
								border: "none",
								outline: "none",
								color: theme.black,
								boxShadow: "none",
								padding: 0,
								marginTop: 0,
							}}
							onClick={() => {
								props.setSelectedApplicant(props.record);
								props.setWithdrawModalVisibility(true);
							}}
							title={t("students.withdrawApplication")}
							icon={
								<FontAwesomeIcon
									icon={solid("ban")}
									style={{ fontSize: "15px" }}
								/>
							}
						/>
					</div>
				) : null}
				{props.record.isEnrollmentViewed &&
					((isProfessor && props.record.internshipEmployerId === getUserId()) ||
						(isCompany &&
							props.record.internshipEmployerId ===
								companyUser?.companyId)) && (
						<CustomButton
							fontSize={"0.9rem"}
							style={{
								background: "transparent",
								border: "none",
								outline: "none",
								color: theme.black,
								boxShadow: "none",
								padding: 0,
								marginTop: 0,
							}}
							onClick={() => {
								updateViewApplication(
									props.record.internshipEnrollmentId,
									false
								);
							}}
							title={t("students.unviewApplication")}
							icon={
								<FontAwesomeIcon
									icon={solid("eye-slash")}
									style={{ fontSize: "15px" }}
								/>
							}
						/>
					)}
			</Space>
		</div>
	);
};
