/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
	PushNotificationSubscriptionDTO,
	PushNotificationSubscriptionDTOFromJSON,
	PushNotificationSubscriptionDTOToJSON,
} from "../models";

export interface ApiPushNotificationsSubscribePostRequest {
	pushNotificationSubscriptionDTO?: PushNotificationSubscriptionDTO;
}

/**
 *
 */
export class PushNotificationsApi extends runtime.BaseAPI {
	/**
	 */
	async apiPushNotificationsSubscribePostRaw(
		requestParameters: ApiPushNotificationsSubscribePostRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<void>> {
		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		headerParameters["Content-Type"] = "application/json";

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/PushNotifications/Subscribe`,
				method: "POST",
				headers: headerParameters,
				query: queryParameters,
				body: PushNotificationSubscriptionDTOToJSON(
					requestParameters.pushNotificationSubscriptionDTO
				),
			},
			initOverrides
		);

		return new runtime.VoidApiResponse(response);
	}

	/**
	 */
	async apiPushNotificationsSubscribePost(
		requestParameters: ApiPushNotificationsSubscribePostRequest = {},
		initOverrides?: RequestInit
	): Promise<void> {
		await this.apiPushNotificationsSubscribePostRaw(
			requestParameters,
			initOverrides
		);
	}

	/**
	 */
	async apiPushNotificationsUnsubscribeDeleteRaw(
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<void>> {
		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/PushNotifications/Unsubscribe`,
				method: "DELETE",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.VoidApiResponse(response);
	}

	/**
	 */
	async apiPushNotificationsUnsubscribeDelete(
		initOverrides?: RequestInit
	): Promise<void> {
		await this.apiPushNotificationsUnsubscribeDeleteRaw(initOverrides);
	}
}
