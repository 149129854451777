import { useState } from "react";
import { Form, InputNumber, Checkbox, Select, Button, Input } from "antd";
import { theme } from "../../theme";
import { useHistory } from "react-router-dom";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../Notifications/NotificationsUtils";
import {
	PracticeQualificationDTO,
	PracticeCertificateQualifier,
	PracticeCertificateAddDTO,
	PracticeCertificateState,
	PracticeCertificateUpdateDTO,
	InternshipType,
	ErrorCodes,
} from "../../Api";
import ConfirmationModal from "../../Containers/ConfirmationModal";
import { useTranslation } from "react-i18next";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import {
	addPracticeCertificate,
	getPracticeCertificateForInternshipEnrollment,
	updatePracticeCertificate,
} from "../../Requests/practice-certificate-requests";
import { criterions, qualifiers } from "../../utils/constants";
import { useSelector } from "react-redux";
import { selectStudentInternshipEnrollmentId } from "../Student/StudentSlice";
import { useQuery } from "react-query";
import { getInternshipEnrollmentById } from "../../Requests/internship-requests";
import {
	getInternshipEnrollment,
	getPracticeCertificate,
} from "../../utils/reactQueriesConstants";
import { getErrorFromResponse } from "../../utils/responseUtils";

const { Option } = Select;

const computeDefaultQualifications = () => {
	let defaultQualifications: PracticeQualificationDTO[] = [];

	for (let i = 0; i < criterions.length; i++) {
		defaultQualifications.push({
			criterion: criterions[i],
			qualifier: PracticeCertificateQualifier.Fb,
		});
	}

	return defaultQualifications;
};

const PracticeCertificate = () => {
	const { t } = useTranslation();
	const [complete, setComplete] = useState(false);
	const [qualifiersList, setQualifiersList] = useState<
		PracticeQualificationDTO[]
	>(computeDefaultQualifications());
	const [cancelModalVisibility, setCancelModalVisibility] = useState(false);
	const [addModalVisibility, setAddModalVisibility] = useState(false);
	const internshipEnrollmentId = useSelector(
		selectStudentInternshipEnrollmentId
	);
	const history = useHistory();
	const [spinning, setSpinning] = useState(false);

	const openGetErrorNotification = (_error: any) => {
		openNotification(
			t("practiceCertificate.error"),
			t("practiceCertificate.fetchDataError"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const openSaveErrorNotification = async (error: any) => {
		const errorMessage = await getErrorFromResponse(error);

		let notificationMessage;

		switch (errorMessage?.code) {
			case ErrorCodes.PracticeCertificateAlreadyExists:
				notificationMessage = t("account.notFoundUser");
				break;
			case ErrorCodes.Forbidden:
				notificationMessage = t("errors.forbidden");
				break;
			case ErrorCodes.InternshipEnrollmentNotFound:
				notificationMessage = t("internships.enrollmentNotFound");
				break;
			case ErrorCodes.BadRequest:
				notificationMessage = t("documents.invalidData");
				break;
			default:
				notificationMessage = t("account.unknownError");
				break;
		}

		openNotification(
			t("practiceCertificate.error"),
			notificationMessage,
			NOTIFICATION_TYPES.ERROR
		);
		setAddModalVisibility(false);
	};

	const [state, setState] = useState({
		totalWorkHours: 0,
		workHoursPerDay: 8,
		observations: "",
	});

	const [form] = Form.useForm<{
		totalWorkHours: number;
		workHoursPerDay: number;
		observations: string | null;
	}>();

	const { data: internshipEnrollment } = useQuery(
		[getInternshipEnrollment, internshipEnrollmentId],
		() => getInternshipEnrollmentById(internshipEnrollmentId!),
		{
			onError: (err) => {
				openGetErrorNotification(err);
			},
			onSuccess: (data) => {
				if (data) {
					form.setFieldsValue({
						totalWorkHours: data.totalPracticeHours,
					});

					setState((prevState) => {
						return {
							...prevState,
							totalWorkHours: data.totalPracticeHours as number,
						};
					});
				}
			},
			refetchOnWindowFocus: false,
		}
	);

	const { data: practiceCertificate } = useQuery(
		[getPracticeCertificate, internshipEnrollmentId],
		() =>
			getPracticeCertificateForInternshipEnrollment(internshipEnrollmentId!),
		{
			onError: (err: any) => {
				if (err.status !== 404) {
					openGetErrorNotification(err);
				}
			},
			onSuccess: (data) => {
				if (data.state !== PracticeCertificateState.InProgress) {
					history.push("/404");
				}
				if (data) {
					form.setFieldsValue({
						totalWorkHours: data.totalWorkHours,
						workHoursPerDay: data.workHoursPerDay,
						observations: data.observations,
					});

					setQualifiersList(data.qualifiers!);
					setComplete(data.state !== PracticeCertificateState.InProgress);
				}
			},
			refetchOnWindowFocus: false,
		}
	);

	const handleChangeInputs = (event: any, field: any) => {
		event.persist();
		setState({
			...state,
			[field]: event.target.value,
		});
	};

	const handleChangeNumberInputs = (value: any, field: any) => {
		setState({
			...state,
			[field]: value,
		});
	};

	const handleChangeCheckbox = (event: CheckboxChangeEvent) => {
		setComplete(event.target.checked);
	};

	const handleChangeQualifier = (event: any, index: number) => {
		const newQualifiersList = [...qualifiersList];
		newQualifiersList[index].qualifier = event;

		setQualifiersList(newQualifiersList);
	};

	const handleCancel = () => {
		setCancelModalVisibility(false);
		history.goBack();
	};

	const openSuccessNotificationAndRedirect = () => {
		history.goBack();
		openNotification(
			t("practiceCertificate.editPracticeCertificate"),
			t("practiceCertificate.editPracticeCertificateSuccess"),
			NOTIFICATION_TYPES.SUCCESS,
			6
		);
	};

	const renderQualifiers = () => {
		return qualifiersList.map((q, index) => (
			<div className="grid grid-cols-1 md:grid-cols-2 gap-2">
				<label>{t("practiceCertificate.criterionsText." + q.criterion!)}</label>

				<Select
					allowClear
					onChange={(event) => handleChangeQualifier(event, index)}
					value={q.qualifier}
					placeholder={t(
						"practiceCertificate.addPracticeCertificateForm.grade"
					)}
				>
					{qualifiers.map((item, index) => (
						<Option value={item} key={"criterion" + index}>
							{t("practiceCertificate.qualifiers." + item)}
						</Option>
					))}
				</Select>
			</div>
		));
	};

	const handleSave = () => {
		if (
			state.totalWorkHours > 0 &&
			state.workHoursPerDay > 0 &&
			state.workHoursPerDay <= 8 &&
			qualifiersList.length > 0
		) {
			setSpinning(true);

			if (practiceCertificate) {
				const obj: PracticeCertificateUpdateDTO = {
					qualifiers: qualifiersList,
					workHoursPerDay: state.workHoursPerDay,
					observations: state.observations,
					state: complete
						? practiceCertificate.internshipType === InternshipType.ByProfessor
							? PracticeCertificateState.PendingInternshipAdminValidation
							: PracticeCertificateState.PendingPracticeRepresentativeSignature
						: PracticeCertificateState.InProgress,
				};

				updatePracticeCertificate(practiceCertificate.id!, obj)
					.then(openSuccessNotificationAndRedirect)
					.catch(openSaveErrorNotification)
					.finally(() => {
						setSpinning(false);
						setAddModalVisibility(false);
					});
			} else {
				const obj: PracticeCertificateAddDTO = {
					qualifiers: qualifiersList,
					internshipEnrollmentId: internshipEnrollmentId,
					workHoursPerDay: state.workHoursPerDay,
					observations: state.observations,
					state: complete
						? internshipEnrollment?.internshipType ===
						  InternshipType.ByProfessor
							? PracticeCertificateState.PendingInternshipAdminValidation
							: PracticeCertificateState.PendingPracticeRepresentativeSignature
						: PracticeCertificateState.InProgress,
				};

				addPracticeCertificate(obj)
					.then(openSuccessNotificationAndRedirect)
					.catch(openSaveErrorNotification)
					.finally(() => {
						setSpinning(false);
						setAddModalVisibility(false);
					});
			}
		} else {
			openNotification(
				t("practiceCertificate.editPracticeCertificate"),
				t("usersText.requiredFieldsError"),
				NOTIFICATION_TYPES.ERROR
			);
		}
	};

	return (
		<Form form={form} layout={theme.layout} action="/aplicanti">
			<div className="container max-w-3xl mx-auto px-4 py-10 space-y-6">
				<div className="space-y-6">
					<div className="flex gap-4">
						<Form.Item
							className="basis-full"
							name="totalWorkHours"
							label={
								t(
									"practiceCertificate.addPracticeCertificateForm.totalNumberOfHours"
								) + ":"
							}
						>
							<InputNumber
								className="w-full"
								disabled
								placeholder={t(
									"practiceCertificate.addPracticeCertificateForm.totalNumberOfHoursPlaceholder"
								)}
								onChange={(event) =>
									handleChangeNumberInputs(event, "totalWorkHours")
								}
							/>
						</Form.Item>

						<Form.Item
							className="basis-full"
							name="workHoursPerDay"
							initialValue={8}
							label={
								t(
									"practiceCertificate.addPracticeCertificateForm.workHoursPerDay"
								) + ":"
							}
						>
							<InputNumber
								className="w-full"
								placeholder={t(
									"practiceCertificate.addPracticeCertificateForm.workHoursPerDayPlaceholder"
								)}
								defaultValue={8}
								max={8}
								onChange={(event) =>
									handleChangeNumberInputs(event, "workHoursPerDay")
								}
							/>
						</Form.Item>
					</div>

					{renderQualifiers()}

					<Form.Item
						name="observations"
						label={
							t("practiceCertificate.addPracticeCertificateForm.observations") +
							":"
						}
					>
						<Input.TextArea
							rows={3}
							cols={100}
							className="w-full"
							placeholder={t(
								"practiceCertificate.addPracticeCertificateForm.observations"
							)}
							onChange={(event) => handleChangeInputs(event, "observations")}
						/>
					</Form.Item>

					<Checkbox
						checked={complete}
						onChange={(event) => handleChangeCheckbox(event)}
					>
						{t("practiceCertificate.addPracticeCertificateForm.markComplete")}
					</Checkbox>
				</div>

				<div className="flex gap-2 justify-end">
					<div>
						<Button onClick={() => setCancelModalVisibility(true)}>
							{t("practiceCertificate.addPracticeCertificateForm.cancel")}
						</Button>
						<ConfirmationModal
							modalText={t(
								"practiceCertificate.addPracticeCertificateForm.cancelMessage"
							)}
							handleFunction={handleCancel}
							modalVisibility={cancelModalVisibility}
							title=""
							changeModalVisibility={() => setCancelModalVisibility(false)}
							spinning={spinning}
						/>
					</div>

					<div>
						<Button type="primary" onClick={() => setAddModalVisibility(true)}>
							{practiceCertificate
								? !complete
									? t(
											"practiceCertificate.addPracticeCertificateForm.updateDraftButton"
									  )
									: t(
											"practiceCertificate.addPracticeCertificateForm.updateButton"
									  )
								: !complete
								? t(
										"practiceCertificate.addPracticeCertificateForm.saveDraftButton"
								  )
								: t(
										"practiceCertificate.addPracticeCertificateForm.saveButton"
								  )}
						</Button>
						<ConfirmationModal
							modalText={t(
								"practiceCertificate.addPracticeCertificateForm.saveMessage"
							)}
							handleFunction={handleSave}
							modalVisibility={addModalVisibility}
							title=""
							changeModalVisibility={() => setAddModalVisibility(false)}
							spinning={spinning}
						/>
					</div>
				</div>
			</div>
		</Form>
	);
};

export default PracticeCertificate;
