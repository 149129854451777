/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface CompanyUserReportDTO
 */
export interface CompanyUserReportDTO {
	/**
	 *
	 * @type {string}
	 * @memberof CompanyUserReportDTO
	 */
	id?: string;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyUserReportDTO
	 */
	name?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyUserReportDTO
	 */
	email?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyUserReportDTO
	 */
	phone?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyUserReportDTO
	 */
	department?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyUserReportDTO
	 */
	_function?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyUserReportDTO
	 */
	companyId?: string;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyUserReportDTO
	 */
	companyName?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyUserReportDTO
	 */
	companyCif?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyUserReportDTO
	 */
	companyAddress?: string | null;
}

export function CompanyUserReportDTOFromJSON(json: any): CompanyUserReportDTO {
	return CompanyUserReportDTOFromJSONTyped(json, false);
}

export function CompanyUserReportDTOFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): CompanyUserReportDTO {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		id: !exists(json, "id") ? undefined : json["id"],
		name: !exists(json, "name") ? undefined : json["name"],
		email: !exists(json, "email") ? undefined : json["email"],
		phone: !exists(json, "phone") ? undefined : json["phone"],
		department: !exists(json, "department") ? undefined : json["department"],
		_function: !exists(json, "function") ? undefined : json["function"],
		companyId: !exists(json, "companyId") ? undefined : json["companyId"],
		companyName: !exists(json, "companyName") ? undefined : json["companyName"],
		companyCif: !exists(json, "companyCif") ? undefined : json["companyCif"],
		companyAddress: !exists(json, "companyAddress")
			? undefined
			: json["companyAddress"],
	};
}

export function CompanyUserReportDTOToJSON(
	value?: CompanyUserReportDTO | null
): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		id: value.id,
		name: value.name,
		email: value.email,
		phone: value.phone,
		department: value.department,
		function: value._function,
		companyId: value.companyId,
		companyName: value.companyName,
		companyCif: value.companyCif,
		companyAddress: value.companyAddress,
	};
}
