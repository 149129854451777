import { Input } from "antd";
import styled from "styled-components";
import { theme } from "../theme";

const StyledInput = styled(Input)`
	outline: none;
	box-shadow: none;
	width: 90%;
	margin-top: 15px;
	background-color: ${(props: any) =>
		props.backgroundcolor || theme.primaryColor};
	color: ${theme.black};
	border-radius: 4px;
	border: 1px solid ${theme.forthColor};
	font-family: ${theme.font};

	.hOtQMo:hover,
	.hOtQMo:focus,
	.hOtQMo:active {
		padding: 0 !important;
	}

	.ant-form-item-has-feedback .ant-input {
		padding-right: 0 !important;
	}

	&:hover,
	&:focus,
	&:active {
		background-color: ${(props: any) =>
			props.backgroundcolor || theme.primaryColor};
		color: ${theme.black};
		outline: none;
		box-shadow: none;
		border: 1px solid;
		width: 90%;
		border-radius: 4px;
		border-color: ${theme.forthColor};
		font-family: ${theme.font};
	}
`;

const StyledPasswordInput = styled(Input.Password)`
	outline: none;
	box-shadow: none;
	width: 90%;
	margin-top: 15px;
	background-color: ${(props: any) =>
		props.backgroundcolor || theme.primaryColor};
	color: ${theme.black};
	border-radius: 4px;
	border: 1px solid ${theme.forthColor};
	font-family: ${theme.font};

	.hOtQMo:hover,
	.hOtQMo:focus,
	.hOtQMo:active {
		padding: 0 !important;
	}

	.ant-form-item-has-feedback .ant-input {
		padding-right: 0 !important;
	}

	&:hover,
	&:focus,
	&:active {
		background-color: ${(props: any) =>
			props.backgroundcolor || theme.primaryColor};
		color: ${theme.black};
		outline: none;
		box-shadow: none;
		border: 1px solid;
		width: 90%;
		border-radius: 4px;
		border-color: ${theme.forthColor};
		font-family: ${theme.font};
	}
`;

export const CustomInput = (props: any) => {
	return <StyledInput {...props} />;
};

export const CustomPasswordInput = (props: any) => {
	return <StyledPasswordInput {...props} />;
};
