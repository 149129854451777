import Layout from "../../Containers/Layout";
import MyStudentsTable from "./MyStudentsTable";
import { useLocation } from "react-router-dom";

const MyStudents = () => {
	const location = useLocation();

	return (
		<Layout>
			<MyStudentsTable location={location} />
		</Layout>
	);
};

export default MyStudents;
