import {
	Button,
	DatePicker,
	Form,
	Input,
	InputNumber,
	Radio,
	Select,
} from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Redirect, useParams } from "react-router-dom";
import ConfirmationModal from "../../Containers/ConfirmationModal";
import CustomButton from "../../CustomComponents/CustomButton";
import CustomForm from "../../CustomComponents/CustomForm";
import CustomEditor from "../../CustomComponents/CustomEditor";
import {
	CompanyResearchProposalAddUpdateDTO,
	ProfessorResearchProposalAddUpdateDTO,
} from "../../Api";
import { getCompanyInternshipById } from "../../Requests/company-internship-requests";
import { getProfessorInternshipById } from "../../Requests/professor-internship-requests";
import { theme } from "../../theme";
import { userSkills } from "../../utils/constants";
import {
	getUserId,
	useGetCompanyId,
	useIsCompany,
	useIsDepartmentSecretary,
	useIsProfessor,
} from "../../utils/utilFunctions";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../Notifications/NotificationsUtils";
import styles from "./AddResearchProposalForm.module.scss";
import { StatusCodes } from "http-status-codes";
import {
	addProfessorResearchProposal,
	getProfessorResearchProposalById,
	updateProfessorResearchProposalInfo,
} from "../../Requests/professor-research-proposals-requests";
import {
	addCompanyResearchProposal,
	getCompanyResearchProposalById,
	updateCompanyResearchProposalInfo,
} from "../../Requests/company-research-proposals-requests";
import Layout from "../../Containers/Layout";
import { getSpecificResearchProposal } from "../../utils/reactQueriesConstants";
import { useQuery } from "react-query";

const { RangePicker } = DatePicker;
const { Group } = Radio;
const { Option } = Select;
const dateFormatList = "YYYY-MM-DD";

const AddResearchProposalForm = (props: any) => {
	const getResearchProposal = async (
		isProfessor?: boolean,
		isCompany?: boolean,
		researchProposalId?: string
	) => {
		if (!researchProposalId) {
			return null;
		}

		if (isProfessor) {
			return await getProfessorResearchProposalById(researchProposalId);
		}

		if (isCompany) {
			return await getCompanyResearchProposalById(researchProposalId);
		}

		return null;
	};

	const { t } = useTranslation();
	const [form] = Form.useForm<any>();
	const [redirect, setRedirect] = useState(false);
	const [cancelModalVisibility, setCancelModalVisibility] = useState(false);
	const [saveModalVisibility, setSaveModalVisibility] = useState(false);

	const { researchProposalId } = useParams<{ researchProposalId?: string }>();

	const isProfessor = useIsProfessor();
	const isCompany = useIsCompany();
	const [spinning, setSpinning] = useState(false);

	const openGetErrorNotification = (_error: any) => {
		openNotification(
			t("internships.error"),
			t("internships.fetchDataError"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	useEffect(() => {
		// only when adding the engagementType can change - prevent this from happening on updates
		if (!researchProposalId) {
			form.setFieldsValue({ ...form.getFieldsValue() });
		}
	}, [researchProposalId, form]);

	const { data: researchProposal } = useQuery(
		[getSpecificResearchProposal, isProfessor, isCompany, researchProposalId],
		() => getResearchProposal(isProfessor, isCompany, researchProposalId),
		{
			onError: (err) => {
				openGetErrorNotification(err);
			},
			onSuccess: (data) => {
				if (data) {
					form.setFieldsValue({
						name: data.researchProposalName,
						description: data.description,
						shortDescription: data.shortDescription,
						numberOfPartners: data.numberOfPartners,
						keywords: data.keywords,
						url: data.url,
						availabilityAd: [
							moment(data.availableFrom, dateFormatList),
							moment(data.availableTo, dateFormatList),
						],
					});
				}
			},
			refetchOnWindowFocus: false,
		}
	);

	const openSaveErrorNotification = (error: any) => {
		if (error.status && error.status === StatusCodes.FORBIDDEN) {
			openNotification(
				t("internships.error"),
				t("internships.cannotDecrementPositionsError"),
				NOTIFICATION_TYPES.ERROR
			);
		} else {
			openNotification(
				t("internships.error"),
				t("internships.saveDataError"),
				NOTIFICATION_TYPES.ERROR
			);
		}
	};

	const handleCancel = () => {
		setCancelModalVisibility(false);
		setRedirect(true);
	};

	const openSuccessEditNotificationAndRedirect = () => {
		setRedirect(true);
		openNotification(
			t("internships.editInternship"),
			t("internships.editInternshipSuccess"),
			NOTIFICATION_TYPES.SUCCESS,
			6
		);
	};

	const openSuccessAddNotificationAndRedirect = () => {
		setRedirect(true);
		openNotification(
			t("internships.addInternship"),
			t("internships.addInternshipSuccess"),
			NOTIFICATION_TYPES.SUCCESS,
			6
		);
	};

	const handleSave = () => {
		const state = form.getFieldsValue();

		/* The length of the description is in parameters */
		if ((state.description?.length || 0) - 7 <= 60000) {
			setSpinning(true);

			if (isProfessor) {
				/* Create body and send request to add/update ProfessorInternship */

				let obj: ProfessorResearchProposalAddUpdateDTO = {
					name: state.name,
					description: state.description,
					shortDescription: state.shortDescription,
					keywords: state.keywords,
					numberOfPartners: state.numberOfPartners,
					url: state.url ? state.url : "",
					availableFrom: (state as any).availabilityAd[0],
					availableTo: (state as any).availabilityAd[1],
				};

				(researchProposalId
					? updateProfessorResearchProposalInfo(researchProposalId, obj)
					: addProfessorResearchProposal(obj)
				)
					.then(
						researchProposalId
							? openSuccessEditNotificationAndRedirect
							: openSuccessAddNotificationAndRedirect
					)
					.catch(openSaveErrorNotification)
					.finally(() => {
						setSpinning(false);
						setSaveModalVisibility(false);
					});
			} else {
				/* Create body and send request to add/update CompanyInternship */

				let obj: CompanyResearchProposalAddUpdateDTO = {
					name: state.name,
					description: state.description,
					shortDescription: state.shortDescription,
					keywords: state.keywords,
					numberOfPartners: state.numberOfPartners,
					url: state.url ? state.url : "",
					availableFrom: (state as any).availabilityAd[0],
					availableTo: (state as any).availabilityAd[1],
				};

				(researchProposalId
					? updateCompanyResearchProposalInfo(researchProposalId, obj)
					: addCompanyResearchProposal(obj)
				)
					.then(
						researchProposalId
							? openSuccessEditNotificationAndRedirect
							: openSuccessAddNotificationAndRedirect
					)
					.catch(openSaveErrorNotification)
					.finally(() => {
						setSpinning(false);
						setSaveModalVisibility(false);
					});
			}
		} else {
			/* The description is too long */
			openNotification(
				t("internships.addInternshipForm.addInternship"),
				t("internships.addInternshipForm.descriptionLengthError"),
				NOTIFICATION_TYPES.ERROR
			);
			setSaveModalVisibility(false);
		}
	};

	if (redirect) {
		return (
			<Redirect
				to={{
					pathname: "/propuneri-cercetare",
					state: {
						existingFilters: props.location.state.filters,
						searchTerm: props.location.state.searchTerm,
						sortField: props.location.state.sortField,
						sortDirection: props.location.state.sortDirection,
						currentPage: props.location.state.currentPage,
						pageSize: props.location.state.pageSize,
						activeTab: props.location.state.activeTab,
					},
				}}
			/>
		);
	} else {
		return (
			<div className="container mx-auto px-4 py-10 flex flex-col gap-8">
				<Form
					form={form}
					onFinish={() => {
						setSaveModalVisibility(true);
					}}
					layout={theme.layout}
					action="/propuneri-cercetare"
				>
					<div className={styles.firstLine}>
						<Form.Item
							required
							name="name"
							label={t("research.addForm.offerTitle") + ":"}
							rules={[
								{
									required: true,
									message: t("research.addForm.missingOfferTitle"),
								},
							]}
						>
							<Input
								className={styles.antItem}
								placeholder={t("research.addForm.offerTitle")}
							/>
						</Form.Item>

						<Form.Item
							required
							name="description"
							label={t("research.addForm.description") + ":"}
							rules={[
								{
									required: true,
									message: t("research.addForm.missingDescription"),
								},
							]}
						>
							<CustomEditor
								onEditorChange={(description: string) => {
									form.setFieldsValue({
										...form.getFieldsValue(),
										description,
									});
								}}
							/>
						</Form.Item>

						<Form.Item
							name="shortDescription"
							label={t("research.addForm.shortDescription") + ":"}
							rules={[
								{
									required: true,
									message: t("research.addForm.missingShortDescription"),
								},
							]}
						>
							<Input.TextArea
								rows={4}
								cols={100}
								style={{ minHeight: 100, width: "100%" }}
								placeholder={t("research.addForm.shortDescription")}
							/>
						</Form.Item>

						<Form.Item
							required
							name="keywords"
							label={t("research.addForm.keywords") + ":"}
							rules={[
								{
									required: true,
									message: t("research.addForm.missingKeywords"),
								},
							]}
						>
							<Select
								className={styles.antSelect}
								mode="tags"
								allowClear
								notFoundContent={t("research.addForm.keywordsNoData")}
								style={{ width: "100%", textAlign: "left" }}
								placeholder={t("research.addForm.keywords")}
							>
								{userSkills}
							</Select>
						</Form.Item>

						<Form.Item name="url" label={t("research.addForm.url") + ":"}>
							<Input placeholder={t("research.addForm.url")}></Input>
						</Form.Item>

						<Form.Item
							name="numberOfPartners"
							label={t("research.addForm.numberOfPartners") + ":"}
						>
							<InputNumber
								className={styles.numberInput}
								min={
									researchProposalId !== undefined
										? researchProposal?.numberOfPartners
										: 0
								}
								placeholder={t("research.addForm.numberOfPartners")}
							></InputNumber>
						</Form.Item>

						<Form.Item
							required
							name="availabilityAd"
							initialValue={[
								moment(),
								moment().add(moment.duration(30, "days")),
							]}
							label={t("research.addForm.availabilityAd") + ":"}
							rules={[
								{
									required: true,
									message: t("research.addForm.missingAvailabilityAd"),
								},
							]}
						>
							<RangePicker
								format={dateFormatList}
								style={{ width: "100%", height: "35px" }}
							/>
						</Form.Item>
					</div>

					<div>
						<div className="flex items-center justify-end gap-2">
							<Button
								style={{
									background: theme.green,
									color: theme.white,
									boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
								}}
								onClick={() => setCancelModalVisibility(true)}
							>
								{t("internships.addInternshipForm.cancel")}
							</Button>

							<Button
								htmlType="submit"
								type={"primary"}
								style={{
									background: theme.secondColor,
									color: theme.white,
									boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
								}}
							>
								{researchProposalId
									? t("internships.addInternshipForm.updateButton")
									: t("internships.addInternshipForm.saveButton")}
							</Button>
						</div>

						<ConfirmationModal
							modalText={t("internships.cancelMessage")}
							handleFunction={handleCancel}
							modalVisibility={cancelModalVisibility}
							changeModalVisibility={() => setCancelModalVisibility(false)}
							title=""
							spinning={spinning}
						/>

						<ConfirmationModal
							modalText={t("internships.saveMessage")}
							handleFunction={handleSave}
							modalVisibility={saveModalVisibility}
							title=""
							changeModalVisibility={() => setSaveModalVisibility(false)}
							spinning={spinning}
						/>
					</div>
				</Form>
			</div>
		);
	}
};

export default AddResearchProposalForm;
