/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
	FacultyDTO,
	FacultyDTOFromJSON,
	FacultyDTOFromJSONTyped,
	FacultyDTOToJSON,
} from "./FacultyDTO";

/**
 *
 * @export
 * @interface FacultyFeatureFlagDTO
 */
export interface FacultyFeatureFlagDTO {
	/**
	 *
	 * @type {string}
	 * @memberof FacultyFeatureFlagDTO
	 */
	featureFlagsId?: string;
	/**
	 *
	 * @type {string}
	 * @memberof FacultyFeatureFlagDTO
	 */
	facultyId?: string;
	/**
	 *
	 * @type {FacultyDTO}
	 * @memberof FacultyFeatureFlagDTO
	 */
	faculty?: FacultyDTO;
	/**
	 *
	 * @type {boolean}
	 * @memberof FacultyFeatureFlagDTO
	 */
	value?: boolean;
}

export function FacultyFeatureFlagDTOFromJSON(
	json: any
): FacultyFeatureFlagDTO {
	return FacultyFeatureFlagDTOFromJSONTyped(json, false);
}

export function FacultyFeatureFlagDTOFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): FacultyFeatureFlagDTO {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		featureFlagsId: !exists(json, "featureFlagsId")
			? undefined
			: json["featureFlagsId"],
		facultyId: !exists(json, "facultyId") ? undefined : json["facultyId"],
		faculty: !exists(json, "faculty")
			? undefined
			: FacultyDTOFromJSON(json["faculty"]),
		value: !exists(json, "value") ? undefined : json["value"],
	};
}

export function FacultyFeatureFlagDTOToJSON(
	value?: FacultyFeatureFlagDTO | null
): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		featureFlagsId: value.featureFlagsId,
		facultyId: value.facultyId,
		faculty: FacultyDTOToJSON(value.faculty),
		value: value.value,
	};
}
