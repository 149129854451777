import { useTranslation } from "react-i18next";
import { useState } from "react";
import { Form } from "antd";
import { FacultySettingsFormModel } from "./models/FacultySettingsFormModel";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../../Notifications/NotificationsUtils";
import { useQuery } from "react-query";
import { FacultySettingsDTO } from "../../../Api";
import { FacultySettingsFormController } from "./FacultySettingsForm.types";
import moment from "moment";
import {
	addFacultySettings,
	getFacultySettings,
	updateFacultySettings,
} from "../../../Requests/faculty-settings-requests";
import { useIsDean } from "../../../utils/utilFunctions";

export const useFacultySettingsFormController =
	(): FacultySettingsFormController => {
		const { t } = useTranslation();
		const [form] = Form.useForm<FacultySettingsFormModel>();
		const [settingsId, setSettingsId] = useState("");
		const [editEnabled, setEditEnabled] = useState(false);
		const isDean = useIsDean();

		const { data: settings } = useQuery(
			[getFacultySettings, isDean],
			() => getFacultySettings(),
			{
				onError: (err) => {
					openGetErrorNotification(err);
				},
				onSuccess: (data: FacultySettingsDTO) => {
					if (data) {
						form.setFieldsValue({
							practiceInternshipPeriods: data.practiceInternshipPeriods
								?.sort((a, b) => a.practiceYear!.localeCompare(b.practiceYear!))
								.map((period) => {
									return {
										practiceYear: period.practiceYear,
										practiceEvaluationStart: moment(
											period.practiceEvaluationStart
										),
										practiceEvaluationEnd: moment(period.practiceEvaluationEnd),
										practiceNumberOfHours: period.practiceNumberOfHours,
									};
								}),
							decisionNumber: data.decisionNumber,
							internshipLimits: data.internshipLimits?.sort((a, b) =>
								a.internshipType!.localeCompare(b.internshipType!)
							),
						});
						setSettingsId(data.id!);
					}
					return data;
				},
				refetchOnWindowFocus: false,
			}
		);

		const openGetErrorNotification = (_error: any) => {
			openNotification(
				t("facultySettings.error"),
				t("facultySettings.fetchDataError"),
				NOTIFICATION_TYPES.ERROR
			);
		};

		const openSuccessEditNotification = () => {
			openNotification(
				t("facultySettings.editSetting"),
				t("facultySettings.editSuccess"),
				NOTIFICATION_TYPES.SUCCESS,
				6
			);
		};

		const openSuccessAddNotification = () => {
			openNotification(
				t("facultySettings.addSetting"),
				t("facultySettings.addSuccess"),
				NOTIFICATION_TYPES.SUCCESS,
				6
			);
		};

		const openSaveErrorNotification = (error: any) => {
			openNotification(
				t("internships.error"),
				t("internships.saveDataError"),
				NOTIFICATION_TYPES.ERROR
			);
		};

		const onFinish = () => {
			const state = form.getFieldsValue();
			(settingsId ? updateFacultySettings(state) : addFacultySettings(state))
				.then(
					settingsId ? openSuccessEditNotification : openSuccessAddNotification
				)
				.catch(openSaveErrorNotification);
			setEditEnabled(false);
		};
		const handleCancel = () => {
			setEditEnabled(false);
		};

		const handleEdit = () => {
			setEditEnabled(true);
		};

		return {
			state: {
				form,
				settings,
				settingsId,
				editEnabled,
			},
			actions: {
				onFinish,
				handleEdit,
				handleCancel,
			},
			computed: null,
		};
	};
