/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface AcademicUserGDPRDataDTO
 */
export interface AcademicUserGDPRDataDTO {
	/**
	 *
	 * @type {Date}
	 * @memberof AcademicUserGDPRDataDTO
	 */
	readonly gdprAcceptDate?: Date | null;
}

export function AcademicUserGDPRDataDTOFromJSON(
	json: any
): AcademicUserGDPRDataDTO {
	return AcademicUserGDPRDataDTOFromJSONTyped(json, false);
}

export function AcademicUserGDPRDataDTOFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): AcademicUserGDPRDataDTO {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		gdprAcceptDate: !exists(json, "gdprAcceptDate")
			? undefined
			: json["gdprAcceptDate"] === null
			? null
			: new Date(json["gdprAcceptDate"]),
	};
}

export function AcademicUserGDPRDataDTOToJSON(
	value?: AcademicUserGDPRDataDTO | null
): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {};
}
