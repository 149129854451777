import { createSlice } from "@reduxjs/toolkit";

interface MailValidationState {
	loading: boolean;
	message: string;
}

const name = "mailValidation";

export const mailValidationSlice = createSlice({
	name,
	initialState: {
		loading: true,
		message: "mailValidationText.wait",
	},
	reducers: {
		mailValidationRequestSucceeded: (state: MailValidationState) => {
			return {
				...state,
				loading: false,
				message: "mailValidationText.success",
			};
		},
		mailValidationRequestConflict: (state: MailValidationState) => {
			return {
				...state,
				loading: false,
				message: "mailValidationText.errorUsed",
			};
		},
		mailValidationRequestUnauthorized: (state: MailValidationState) => {
			return {
				...state,
				loading: false,
				message: "mailValidationText.errorExpire",
			};
		},
		mailValidationRequestFailedOther: (state: MailValidationState) => {
			return {
				...state,
				loading: false,
				message: "mailValidationText.errorOther",
			};
		},
	},
});

export const {
	mailValidationRequestConflict,
	mailValidationRequestFailedOther,
	mailValidationRequestSucceeded,
	mailValidationRequestUnauthorized,
} = mailValidationSlice.actions;

export const selectLoading = (state: { mailValidation: MailValidationState }) =>
	state.mailValidation.loading;
export const selectMessage = (state: { mailValidation: MailValidationState }) =>
	state.mailValidation.message;

export default mailValidationSlice.reducer;
