import {
	Breakpoint,
	Button,
	Checkbox,
	Col,
	Modal,
	Pagination,
	Row,
	Select,
	Space,
	Spin,
	Table,
	Typography,
	Upload,
} from "antd";
import { theme } from "../../theme";
import CustomModalFooter from "../../Containers/CustomModalFooter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Link, useLocation } from "react-router-dom";
import {
	getUserId,
	useGetCompanyId,
	useIsAdmin,
	useIsCompany,
	useIsFacultyAdmin,
} from "../../utils/utilFunctions";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import {
	addNewDocument,
	deleteDocument,
	downloadDocument,
	getCompanyDocuments,
	rejectDocument,
	updateSkipOfferValidationStatus,
} from "../../Requests/company-document-requests";
import {
	getDocuments,
	getFaculties,
	getProfessor,
} from "../../utils/reactQueriesConstants";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../Notifications/NotificationsUtils";
import { StatusCodes } from "http-status-codes";
import { getProfessorById } from "../../Requests/academic-user-requests";
import moment from "moment";
import styles from "./DocumentsTable.module.scss";
import { downloadFile } from "../../utils/downloadUtils";
import ConfirmationModal from "../../Containers/ConfirmationModal";
import {
	CompanyDocumentDTO,
	CompanyDocumentStatusEnum,
	ErrorCodes,
	FacultyDTO,
} from "../../Api";
import { getAllFaculties } from "../../Requests/faculty-requests";
import CustomEditor from "../../CustomComponents/CustomEditor";
import { getErrorFromResponse } from "../../utils/responseUtils";
import useQueryFilters from "../../Hooks/useQueryFilters";
import { ArrowDownOutlined } from "@ant-design/icons";

const { Option } = Select;

const DocumentsTable = () => {
	const { t, i18n } = useTranslation();
	const location = useLocation<
		| {
				filters: any;
				oldSearchTerm: string;
				sortField: any;
				sortDirection: any;
				currentPage: any;
				pageSize: any;
				activeTab: string;
				company: string;
				companyId: string;
		  }
		| null
		| undefined
	>();
	const [searchTerm, setSearchTerm] = useState("");
	const [pageSize, setPageSize] = useState(10);
	const [currentPage, setCurrent] = useState(1);
	const [documentTypes, setDocumentTypes] = useState([]);
	const [selectedDocumentId, setSelectedDocumentId] = useState<any>(undefined);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [modalVisibility, setModalVisibility] = useState(false);
	const [facultyOptions, setFacultyOptions] = useState<any>([]);
	const [allFacultyOptions, setAllFacultyOptions] = useState<any>([]);
	const [facultySearch, setFacultySearch] = useState("");
	const [selectedFaculty, setSelectedFaculty] = useState(undefined);
	const [spinning, setSpinning] = useState(false);
	const [adminFaculties, setAdminFaculties] = useState<any>([]);
	const [file, setFile] = useState<Blob>();
	const [documentType, setDocumentType] = useState<any>(null);
	const [filters, setFilters] = useState(null);
	const isFacultyAdmin = useIsFacultyAdmin();
	const isAdmin = useIsAdmin();
	const isCompany = useIsCompany();
	const queryClient = useQueryClient();
	const companyID = useGetCompanyId();
	const [isRejectModalVisible, setIsRejectModalVisible] = useState(false);
	const [rejectionMessage, setRejectionMessage] = useState("");
	const query = useQueryFilters({});

	const returnAction = t("universityAccord.uploadedFile");

	let locale = {
		emptyText: t("tableText.noDocuments"),
	};

	const handleShow = () => {
		setIsModalVisible(true);
	};

	const uploadDocument = (
		companyId: string,
		file: Blob,
		facultyId?: string,
		documentId?: string
	) => {
		addNewDocument(companyId, file, facultyId, documentId)
			.then(async () => {
				await queryClient.invalidateQueries(getDocuments);
				openNotification(
					t("documents.uploadedDocument"),
					t("documents.uploadSuccess"),
					NOTIFICATION_TYPES.SUCCESS
				);
			})
			.catch(() => {
				openNotification(
					t("usersText.errorTexts.error"),
					t("documents.uploadFail"),
					NOTIFICATION_TYPES.ERROR
				);
			});
	};

	const handleOk = () => {
		setSpinning(true);
		if (!isCompany) {
			if (location.state?.companyId && file !== undefined) {
				uploadDocument(
					location.state?.companyId,
					file,
					selectedFaculty,
					selectedDocumentId
				);
			}
		} else {
			if (file !== undefined)
				uploadDocument(companyID, file, selectedFaculty, selectedDocumentId);
		}
		setDocumentType(null);
		setSelectedFaculty(undefined);
		setSpinning(false);
		setFile(undefined);
		setSelectedDocumentId(undefined);
		setIsModalVisible(false);
	};

	const handleCancel = () => {
		setDocumentType(null);
		setSelectedFaculty(undefined);
		setFile(undefined);
		setSelectedDocumentId(undefined);
		setIsModalVisible(false);
		setIsRejectModalVisible(false);
	};

	const downloadFail = (_err: any) => {
		openNotification(
			t("universityAccord.error"),
			t("universityAccord.downloadError"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const deleteCompanyDocument = (documentId: string) => {
		deleteDocument(documentId)
			.then(async () => {
				await queryClient.invalidateQueries(getDocuments);
				openNotification(
					t("documents.deleteDocument"),
					t("documents.deleteSuccessfully"),
					NOTIFICATION_TYPES.SUCCESS
				);
			})
			.catch(() => {
				openNotification(
					t("usersText.errorTexts.error"),
					t("documents.deleteError"),
					NOTIFICATION_TYPES.ERROR
				);
			});
	};

	const fetchDataErrorNotification = (ex: any) => {
		if (ex.status && ex.status === StatusCodes.NOT_FOUND) {
			openNotification(
				t("account.error"),
				t("account.fetchUserDataError"),
				NOTIFICATION_TYPES.ERROR
			);
		} else {
			openNotification(
				t("account.error"),
				t("account.unknownError"),
				NOTIFICATION_TYPES.ERROR
			);
		}
	};

	const { data: adminData } = useQuery(
		[getProfessor, isAdmin, isFacultyAdmin],
		async () =>
			isAdmin || isFacultyAdmin ? getProfessorById(getUserId()) : null,
		{
			onError: fetchDataErrorNotification,
		}
	);

	const onCheckboxChange = (event: any, id: string) => {
		updateSkipOfferValidationStatus(id)
			.then(async () => {
				await queryClient.invalidateQueries(getDocuments);
				openNotification(
					t("documents.changeStatus"),
					t("documents.statusChangedSuccess"),
					NOTIFICATION_TYPES.SUCCESS
				);
			})
			.catch(() => {
				openNotification(
					t("usersText.errorTexts.error"),
					t("documents.statusChangedFailed"),
					NOTIFICATION_TYPES.ERROR
				);
			});
	};

	const rejectCompanyDocument = (documentId: string, message: string) => {
		setSpinning(true);

		rejectDocument(documentId, message)
			.then(() => {
				openNotification(
					t("documents.documentRejection"),
					t("documents.documentRejectionSuccess"),
					NOTIFICATION_TYPES.SUCCESS
				);

				setRejectionMessage("");
				setIsRejectModalVisible(false);
			})
			.catch(async (error) => {
				const errorMessage = await getErrorFromResponse(error);

				let notificationMessage;

				switch (errorMessage?.code) {
					case ErrorCodes.DocumentNotFound:
						notificationMessage = t("documents.documentNotFound");
						break;
					case ErrorCodes.Forbidden:
						notificationMessage = t("documents.notAllowed");
						break;
					default:
						notificationMessage = t("documents.documentRejectionError");
						break;
				}

				openNotification(
					t("documents.documentRejection"),
					notificationMessage,
					NOTIFICATION_TYPES.ERROR
				);
			})
			.finally(() => {
				setSpinning(false);
			});
	};

	const columns = [
		{
			title: t("documents.documentType"),
			dataIndex: "documentType",
			key: "documentType",
			fixed: "left" as "left",
			// sorter: (a: CompanyDocumentDTO, b: CompanyDocumentDTO) => {
			// 	if (!a.documentType || !b.documentType) return -1;
			// 	return a.documentType.localeCompare(b.documentType);
			// },
			render: (text: string | undefined, record: any) => {
				return <span>{t("documents." + record.documentType)}</span>;
			},
		},
		{
			title: !isCompany
				? t("documents.faculty")
				: t("documents.signedWithFaculty"),
			dataIndex: "facultyNameRo",
			key: "facultyNameRo",
			// sorter: (a: CompanyDocumentDTO, b: CompanyDocumentDTO) => {
			// 	if (!a.facultyNameRo || !b.facultyNameRo) return -1;
			// 	return a.facultyNameRo.localeCompare(b.facultyNameRo);
			// },
			responsive: ["md"] as unknown as Breakpoint[],
		},
		{
			title: t("documents.uploadDate"),
			dataIndex: "generationDate",
			key: "generationDate",
			// sorter: (a: CompanyDocumentDTO, b: CompanyDocumentDTO) => {
			// 	if (!a.generationDate || !b.generationDate) return -1;
			// 	return a.generationDate <= b.generationDate ? -1 : 1;
			// },
			render: (text: string | undefined, record: any) => {
				return (
					<span>{moment(record.generationDate).format("DD.MM.YYYY")}</span>
				);
			},
			ellipsis: true,
			responsive: ["lg"] as unknown as Breakpoint[],
		},
		{
			title: t("documents.expirationDate"),
			dataIndex: "expirationDate",
			key: "expirationDate",
			// sorter: (a: CompanyDocumentDTO, b: CompanyDocumentDTO) => {
			// 	if (!a.expirationDate || !b.expirationDate) return -1;
			// 	return a.expirationDate <= b.expirationDate ? -1 : 1;
			// },
			render: (text: string | undefined, record: any) => {
				return (
					<span>{moment(record.expirationDate).format("DD.MM.YYYY")}</span>
				);
			},
			ellipsis: true,
			responsive: ["lg"] as unknown as Breakpoint[],
		},
		{
			title: t("documents.status"),
			dataIndex: "documentStatus",
			key: "documentStatus",
			// sorter: (a: CompanyDocumentDTO, b: CompanyDocumentDTO) => {
			// 	if (!a.documentStatus || !b.documentStatus) return -1;
			// 	return a.documentStatus.localeCompare(b.documentStatus);
			// },
			render: (text: string | undefined, record: any) => {
				return <span>{t("documents." + record.documentStatus)}</span>;
			},
			ellipsis: true,
			responsive: ["lg"] as unknown as Breakpoint[],
		},
		{
			title: t("documents.canPublish"),
			dataIndex: "skipOfferValidation",
			key: "skipOfferValidation",
			render: (text: string | undefined, record: any) => {
				return getUserId().toString() === record.documentOwner ||
					(!isCompany && record.facultyId == adminData?.faculty?.id) ? (
					<Checkbox
						checked={record.skipOfferValidation}
						disabled={
							getUserId().toString() !== record.documentOwner &&
							!(!isCompany && record.facultyId == adminData?.faculty?.id)
						}
						onChange={(event: any) => onCheckboxChange(event, record.id)}
					/>
				) : (
					<span>
						{record.skipOfferValidation
							? t("documents.yes")
							: t("documents.no")}
					</span>
				);
			},
			hidden: isCompany,
			ellipsis: true,
			responsive: ["lg"] as unknown as Breakpoint[],
		},
		{
			title: t("documents.actions"),
			dataIndex: "actions",
			key: "actions",
			fixed: "right" as "right",
			width: 85,
			render: (text: string | undefined, record: any) => (
				<div className={styles.modalContainer} key={"actions-" + record.id}>
					<Space>
						<div className={styles.modalBtnContainer}>
							<Button
								style={{
									background: "transparent",
									border: "none",
									outline: "none",
									color: theme.black,
									boxShadow: "none",
									padding: 0,
								}}
								title={t("documents.download")}
								onClick={() =>
									downloadFile(
										downloadDocument(record.id!),
										t("documents." + record.documentType) +
											"_" +
											record.facultyNameRo +
											(isAdmin || isFacultyAdmin
												? "_" + location.state?.company
												: "")
									).catch(downloadFail)
								}
							>
								<FontAwesomeIcon
									icon={solid("download")}
									style={{ fontSize: "15px" }}
								/>
							</Button>
						</div>
						{getUserId().toString() === record.documentOwner && !isCompany ? (
							<div className={styles.modalBtnContainer}>
								<Button
									style={{
										background: "transparent",
										border: "none",
										outline: "none",
										color: theme.black,
										boxShadow: "none",
										padding: 0,
										marginTop: 0,
									}}
									onClick={() => {
										setSelectedDocumentId(record.id);
										setModalVisibility(true);
									}}
									icon={
										<FontAwesomeIcon
											icon={solid("trash")}
											style={{ paddingRight: "5%" }}
										/>
									}
									title={t("documents.delete")}
								/>
								<ConfirmationModal
									modalText={t("documents.deleteWarning")}
									handleFunction={() => {
										deleteCompanyDocument(selectedDocumentId);
									}}
									modalVisibility={modalVisibility}
									title=""
									changeModalVisibility={() => setModalVisibility(false)}
									spinning={spinning}
								/>
							</div>
						) : null}
						{(getUserId().toString() === record.documentOwner &&
							isCompany &&
							record.documentStatus !== CompanyDocumentStatusEnum.Finalized) ||
						(!isCompany &&
							record.documentStatus === CompanyDocumentStatusEnum.InProgress &&
							record.facultyId == adminData?.faculty?.id) ? (
							<div className={styles.modalBtnContainer}>
								<Button
									style={{
										background: "transparent",
										border: "none",
										outline: "none",
										color: theme.black,
										boxShadow: "none",
										padding: 0,
										marginTop: 0,
									}}
									onClick={() => {
										setSelectedDocumentId(record.id);
										setSelectedFaculty(record.facultyId);
										setDocumentType("practiceProtocol");
										setIsModalVisible(true);
									}}
									icon={
										<FontAwesomeIcon
											icon={solid("file-pen")}
											style={{ paddingRight: "5%" }}
										/>
									}
									title={t("documents.uploadNewVersion")}
								/>
							</div>
						) : null}
						{!isCompany &&
						record.documentStatus === CompanyDocumentStatusEnum.InProgress &&
						record.facultyId === adminData?.faculty?.id ? (
							<div className={styles.modalBtnContainer}>
								<Button
									style={{
										background: "transparent",
										border: "none",
										outline: "none",
										color: theme.black,
										boxShadow: "none",
										padding: 0,
										marginTop: 0,
									}}
									onClick={() => {
										setSelectedDocumentId(record.id);
										setIsRejectModalVisible(true);
									}}
									icon={
										<FontAwesomeIcon
											icon={solid("ban")}
											style={{ paddingRight: "5%" }}
										/>
									}
									title={t("documents.rejectDocument")}
								/>
							</div>
						) : null}
					</Space>
				</div>
			),
		},
	].filter((item) => !item.hidden);

	const openNotificationErrorFetchDocuments = (_ex: any) => {
		openNotification(
			t("usersText.errorTexts.error"),
			t("documents.documentsFetchFailed"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const openGetErrorNotification = (_error: any) => {
		openNotification(
			t("internships.error"),
			t("internships.fetchDataError"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const { data: allFacultiesList } = useQuery(
		[getFaculties],
		() => getAllFaculties("", 1, 20),
		{
			onError: (err) => {
				openGetErrorNotification(err);
			},
			refetchOnWindowFocus: false,
		}
	);

	const { data, isLoading: loading } = useQuery(
		[
			getDocuments,
			location.state?.companyId,
			documentTypes,
			currentPage,
			pageSize,
			searchTerm,
			isFacultyAdmin,
			isAdmin,
			isCompany,
			companyID,
			adminData,
			allFacultiesList,
		],
		() => {
			if (isCompany) {
				return getCompanyDocuments(
					companyID,
					documentTypes,
					currentPage,
					pageSize
				);
			} else if (location.state?.companyId) {
				return getCompanyDocuments(
					location.state.companyId,
					documentTypes,
					currentPage,
					pageSize
				);
			}
		},
		{
			onError: openNotificationErrorFetchDocuments,
			onSuccess: (data) => {
				if (data) {
					if (data?.data !== null && data?.data !== undefined) {
						if (isCompany) {
							let auxFaculties = allFacultiesList;
							for (let i = 0; i < data?.data.length; i++) {
								let facultyId = data.data[i].facultyId;
								auxFaculties = auxFaculties?.filter((e) => e.id !== facultyId);
							}
							setAllFacultyOptions(auxFaculties);
							setFacultyOptions(
								auxFaculties
									?.filter(
										(faculty: FacultyDTO) => faculty.facultyNameRo !== "Altele"
									)
									.map((faculty: FacultyDTO) => (
										<Option key={faculty.id} value={faculty.id}>
											{i18n.language === "en"
												? faculty.facultyNameEn?.replace("Faculty of", "")
												: faculty.facultyNameRo?.replace("Facultatea de", "")}
										</Option>
									))
							);
						} else {
							let auxFaculties = [adminData?.faculty?.id];
							for (let i = 0; i < data?.data.length; i++) {
								let facultyId = data.data[i].facultyId;
								auxFaculties = auxFaculties?.filter((e) => e !== facultyId);
							}
							setAdminFaculties(auxFaculties);
						}
					}
				}
			},
		}
	);

	useEffect(() => {
		setFacultyOptions(
			allFacultyOptions
				?.filter((faculty: FacultyDTO) => {
					return (
						facultySearch === "" ||
						faculty.facultyNameRo
							?.toLowerCase()
							.includes(facultySearch.toLowerCase()) ||
						faculty.facultyNameEn
							?.toLowerCase()
							.includes(facultySearch.toLowerCase())
					);
				})
				.filter((faculty: FacultyDTO) => faculty.facultyNameRo !== "Altele")
				.map((faculty: FacultyDTO) => (
					<Option key={faculty.id} value={faculty.id}>
						{i18n.language === "en"
							? faculty.facultyNameEn?.replace("Faculty of", "")
							: faculty.facultyNameRo?.replace("Facultatea de", "")}
					</Option>
				))
		);
	}, [facultySearch, allFacultiesList, allFacultyOptions, i18n.language]);

	const onChange = (event: string) => {
		setDocumentType(event);
	};

	const handleSelectedFaculties = (selected: any) => {
		setSelectedFaculty(selected);
	};

	const onPageUpdate = useCallback(
		(page: number, pageSize: number) => {
			query.update({
				page: page.toString(),
				pageSize: pageSize.toString(),
			});
		},
		[query]
	);

	return (
		<>
			<div className="flex items-center gap-2 flex-col sm:flex-row pb-5">
				<div className="flex items-center">
					{(isAdmin || isFacultyAdmin) && (
						<Link
							to={{
								pathname: "/utilizatori",
								state: {
									existingFilters: location.state?.filters,
									oldSearchTerm: location.state?.oldSearchTerm,
									currentPage: location.state?.currentPage,
									pageSize: location.state?.pageSize,
									activeTab: location.state?.activeTab,
								},
							}}
						>
							<ArrowDownOutlined className="rotate-90 text-4xl text-primary" />
						</Link>
					)}
					{!isCompany && (
						<h2 className="px-4">
							{t("documents.documents") +
								(location.state?.company
									? " - " + location.state?.company
									: "")}
						</h2>
					)}
				</div>

				<div>
					<Button
						style={{
							background: theme.secondColor,
							color: theme.white,
						}}
						onClick={handleShow}
						disabled={
							!((!isCompany && adminFaculties?.length !== 0) || isCompany)
						}
					>
						{t("documents.uploadDocument")}
					</Button>
					<Modal
						open={isModalVisible}
						maskClosable={!spinning}
						onCancel={handleCancel}
						onOk={handleOk}
						title={""}
						width={window.innerWidth > 1215 ? "50%" : "80%"}
						footer={
							<CustomModalFooter
								handleClose={handleCancel}
								spinning={spinning}
								handleSave={handleOk}
								confirmButtonName={t("account.confirm")}
							/>
						}
					>
						<Spin spinning={spinning}>
							<p>{t("documents.selectDocumentText")}</p>
							<Row>
								{selectedDocumentId === undefined && (
									<Col span={24} style={{ marginBottom: "1em" }}>
										<Select
											value={documentType}
											onChange={onChange}
											placeholder={t("documents.selectDocumentType")}
											options={[
												{
													label: t("documents.practiceProtocol"),
													value: "practiceProtocol",
												},
											]}
											allowClear
											style={{ width: "20rem" }}
										/>
									</Col>
								)}
								{isCompany && selectedDocumentId === undefined && (
									<Col span={24}>
										<Select
											value={selectedFaculty}
											allowClear
											filterOption={false}
											onSearch={setFacultySearch}
											showSearch
											placeholder={t("documents.selectFaculty")}
											style={{ width: "20rem" }}
											onChange={handleSelectedFaculties}
										>
											{facultyOptions}
										</Select>
									</Col>
								)}
								<Col span={24}>
									<Upload
										accept=".pdf"
										action={(uploaded) => {
											setFile(uploaded);
											return returnAction;
										}}
										showUploadList={true}
										multiple={false}
										customRequest={({ onSuccess }) => {
											onSuccess && onSuccess("");
										}}
									>
										<Button
											disabled={
												(!isCompany && documentType === null) ||
												(isCompany &&
													(documentType === null ||
														selectedFaculty === undefined))
											}
											icon={<FontAwesomeIcon icon={solid("upload")} />}
											title={t("documents.uploadPdf")}
											style={{ marginTop: "2rem" }}
										>
											&nbsp;{t("documents.upload")}
										</Button>
									</Upload>
								</Col>
							</Row>
						</Spin>
					</Modal>
					<Modal
						open={isRejectModalVisible}
						onCancel={handleCancel}
						onOk={() =>
							rejectCompanyDocument(selectedDocumentId, rejectionMessage)
						}
						title={t("documents.documentRejection")}
						width={window.innerWidth > 1215 ? "50%" : "80%"}
						footer={
							<CustomModalFooter
								handleClose={handleCancel}
								spinning={spinning}
								handleSave={() =>
									rejectCompanyDocument(selectedDocumentId, rejectionMessage)
								}
								confirmButtonName={t("account.confirm")}
							/>
						}
					>
						<Spin spinning={spinning}>
							<p>{t("documents.rejectDocumentMessage")}</p>

							<CustomEditor
								content={rejectionMessage}
								onEditorChange={(newValue: string) =>
									setRejectionMessage(newValue)
								}
							/>
						</Spin>
					</Modal>
				</div>
			</div>

			<div className="flex flex-col">
				<div className="flex flex-col gap-10">
					<div className="flex flex-col md:flex-row justify-between items-center">
						<Typography.Title level={3} className="my-0">
							{data?.totalCount} {t("filtering.results")}
						</Typography.Title>
						{!loading && (
							<Pagination
								defaultCurrent={data?.page}
								defaultPageSize={data?.pageSize}
								total={data?.totalCount}
								onChange={onPageUpdate}
								locale={{ items_per_page: t("pagination") }}
							/>
						)}
					</div>

					<Table
						locale={locale}
						columns={columns}
						dataSource={data?.data ?? []}
						pagination={false}
						scroll={{ x: "calc(700px + 50%)", y: 420 }}
						rowKey={(record) => record.id!}
					/>
				</div>
			</div>
		</>
	);
};

export default DocumentsTable;
