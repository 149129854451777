import { NewsletterEmailsViewProps } from "./NewsletterEmailsView.types";
import { useTranslation } from "react-i18next";
import { useCallback, useState } from "react";
import { NewsletterSortFieldEnum, SortDirectionEnum } from "../../../Api";
import { useHistory } from "react-router-dom";
import { useBeforeRender } from "../../../utils/utilFunctions";
import { useQuery } from "react-query";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../../Notifications/NotificationsUtils";
import { getPublishedNewsletterEmailsV2 } from "../../../Requests/newsletter-requests";
import { Button, Pagination, Typography } from "antd";
import EmailsList from "./components/lists/EmailsList/EmailsList";
import { usePagination } from "../../../Hooks";
import Filters from "../../Filters";
import useQueryFilters from "../../../Hooks/useQueryFilters";

export const NewsletterEmailsView = (props: NewsletterEmailsViewProps) => {
	const { t } = useTranslation();
	const {
		currentPage,
		setCurrentPage,
		pageSize,
		setPageSize,
		handleListPaginationChange,
	} = usePagination(1, 12);
	const [sortField, setSortField] = useState(
		NewsletterSortFieldEnum.PublishDate
	);
	const [sortDirection, setSortDirection] = useState(
		SortDirectionEnum.Descending
	);
	const [searchTerm, setSearchTerm] = useState("");
	const history = useHistory();
	const query = useQueryFilters({});

	const getOldLocationValues = () => {
		if (props.location.state !== undefined) {
			let { searchTerm, sortField, sortDirection, currentPage, pageSize }: any =
				props.location.state;

			setSearchTerm(searchTerm);
			setSortField(sortField);
			setSortDirection(sortDirection);
			setCurrentPage(currentPage);
			setPageSize(pageSize);
			history.replace({ ...history.location, state: undefined });
		}
	};

	useBeforeRender(() => getOldLocationValues(), [props.location]);

	const openGetErrorNotification = (_error: any) => {
		console.error(_error);
		openNotification(
			t("emails.error"),
			t("emails.fetchDataError"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const { data: newsletterEmails, isLoading: loadingNewsletterEmails } =
		useQuery(
			["getNewsletterEmails", query.filters],
			() => getPublishedNewsletterEmailsV2(query.filters),
			{
				onError: openGetErrorNotification,
			}
		);

	const onUpdate = useCallback(
		(values: { [key: string]: string[] }) => {
			query.update(values, true);
		},
		[query]
	);

	const onPageUpdate = useCallback(
		(page: number, pageSize: number) => {
			query.update({
				page: page.toString(),
				pageSize: pageSize.toString(),
			});
		},
		[query]
	);

	const goToAction = useCallback(() => {
		history.push("/editare-email/", {
			searchTerm: searchTerm,
			sortField: sortField,
			sortDirection: sortDirection,
			currentPage: currentPage,
			pageSize: pageSize,
		});
	}, [currentPage, history, pageSize, searchTerm, sortDirection, sortField]);

	return (
		<div className="px-4 py-10 flex flex-col relative">
			<div className="fixed bottom-10 md:relative md:bottom-auto md:self-end right-10 z-50">
				<Button
					type="primary"
					className="p-6 flex flex-col md:flex-row justify-center items-center rounded-full md:rounded-md relative"
					onClick={goToAction}
				>
					{t("emails.publishEmail")}
				</Button>
			</div>

			<Filters
				searchFields={[{ name: "searchTerm", label: t("internships.search") }]}
				onUpdate={onUpdate}
				hasSort={true}
			/>

			<div className="flex flex-col gap-10 relative">
				<div className="flex flex-col md:flex-row justify-between items-center">
					<Typography.Title level={3} className="my-0">
						{newsletterEmails?.totalCount} {t("filtering.results")}
					</Typography.Title>
					{!loadingNewsletterEmails && (
						<Pagination
							defaultCurrent={newsletterEmails?.page}
							total={newsletterEmails?.totalCount}
							onChange={onPageUpdate}
							locale={{ items_per_page: t("pagination") }}
							pageSizeOptions={[12, 24, 48, 96]}
							defaultPageSize={12}
						/>
					)}
				</div>

				<EmailsList
					data={newsletterEmails}
					loading={loadingNewsletterEmails}
					location={props.location}
					currentPage={currentPage}
					pageSize={pageSize}
					searchTerm={searchTerm}
					sortDirection={sortDirection}
					sortField={sortField}
					handleListChange={handleListPaginationChange}
				/>
			</div>
		</div>
	);
};
