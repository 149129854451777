import { NewsletterEmailDTO, PublishUpdateEmailDTO } from "../../../../Api";
import { AddEmailFormModel } from "../models/AddEmailFormModel";

export const toDomainModel = (
	model: AddEmailFormModel,
	selectedFaculties: string[]
): PublishUpdateEmailDTO => ({
	subject: model.emailSubject,
	body: model.body,
	newsletterChannel: model.newsletterChannel,
	strategy: model.sendStrategy,
	recipientRole: model.recipientRole,
	faculties: selectedFaculties,
	departments: model.departments,
	studyPrograms: model.studyPrograms,
	years: model.years,
	recipientIds: model.recipients,
	priority: model.priority,
});

export const toFormModel = (
	record: NewsletterEmailDTO
): Partial<AddEmailFormModel> => ({
	emailSubject: record.subject,
	body: record.body,
	newsletterChannel: record.newsletterChannel,
	priority: record.priority,
});
