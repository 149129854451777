// filtersSlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface FilterStateValue {
	filters?: string[];
	searchTerm?: string;
}

export interface FilterState {
	[filterKey: string]: FilterStateValue;
}

interface SetFilterPayload {
	filterKey: string;
	value: FilterStateValue;
}

const initialState: FilterState = {};

export const filtersSlice = createSlice({
	name: "filters",
	initialState,
	reducers: {
		setCheckedFilters: (state, action: PayloadAction<SetFilterPayload>) => {
			const { filterKey, value } = action.payload;
			state[filterKey] = value;
		},
	},
});

export const { setCheckedFilters } = filtersSlice.actions;
export default filtersSlice.reducer;
