import { AcceptApplicantModalProps } from "./AcceptApplicantModal.types";
import { Modal, Row, Select, Space, Spin, Typography } from "antd";
import CustomModalFooter from "../../../../../../Containers/CustomModalFooter";
import ConfirmationModal from "../../../../../../Containers/ConfirmationModal";
import { useTranslation } from "react-i18next";
import { useIsCompany } from "../../../../../../utils/utilFunctions";
import { useQuery } from "react-query";
import { listCompanyUsers } from "../../../../../../utils/reactQueriesConstants";
import { listAllCompanyUsers } from "../../../../../../Requests/company-users-requests";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../../../../../Notifications/NotificationsUtils";
import { acceptApplicant } from "../../../../../../Requests/internship-requests";
import { StatusCodes } from "http-status-codes";

const { Option } = Select;

export const AcceptApplicantModal = (props: AcceptApplicantModalProps) => {
	const { t } = useTranslation();
	const isCompany = useIsCompany();

	const openNotificationFetchError = (ex: any) => {
		openNotification(
			t("usersText.errorTexts.failedUserGet"),
			ex.status
				? t("usersText.errorTexts.serverFailedDescription")
				: t("usersText.errorTexts.networkFailedDescription"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const { data: companyUsers } = useQuery(
		[listCompanyUsers, isCompany],
		async () => (isCompany ? listAllCompanyUsers() : null),
		{
			onError: openNotificationFetchError,
		}
	);

	const handleCancel = () => {
		props.setSelectedTutor("");
		props.setModalVisibility(false);
	};

	const handleAcceptApplicant = (item: any, tutorId?: string) => {
		props.setSpinning(true);

		acceptApplicant(item.internshipId, item.id, tutorId)
			.then(async () => {
				await props.invalidateApplicantsQueries();
				openNotification(
					t("students.acceptApplicant"),
					t("students.successAcceptApplicantMessage"),
					NOTIFICATION_TYPES.SUCCESS
				);
			})
			.catch((ex) => {
				openNotification(
					t("students.acceptApplicant"),
					t(
						ex.status === StatusCodes.FORBIDDEN
							? "students.errorAcceptApplicantExceededMessage"
							: "students.errorAcceptApplicantMessage"
					),
					NOTIFICATION_TYPES.ERROR
				);
			})
			.finally(() => {
				props.setSpinning(false);
				props.setModalVisibility(false);
			});
	};

	return (
		<>
			{isCompany ? (
				<Modal
					open={props.modalVisibility}
					maskClosable={!props.spinning}
					onCancel={handleCancel}
					onOk={() =>
						handleAcceptApplicant(props.selectedApplicant, props.selectedTutor)
					}
					title={
						props.selectedApplicant.availablePositions !== 0
							? ""
							: t("students.acceptStudentExceededTitle")
					}
					width={window.innerWidth > 1215 ? "50%" : "80%"}
					footer={
						<CustomModalFooter
							handleClose={handleCancel}
							spinning={props.spinning}
							handleSave={() =>
								handleAcceptApplicant(
									props.selectedApplicant,
									props.selectedTutor
								)
							}
							confirmButtonName={t("account.confirm")}
						/>
					}
				>
					<Spin spinning={props.spinning}>
						<Space size="large" direction="vertical">
							<div>
								<Typography>
									{props.selectedApplicant.availablePositions !== 0
										? t("students.acceptStudentMessage") +
										  props.selectedApplicant.name +
										  t("students.acceptStudentMessage2")
										: t("students.acceptStudentExceeded") +
										  props.selectedApplicant.internshipName +
										  t("students.acceptStudentExceeded2")}
								</Typography>
							</div>
							<p>{t("students.selectTutor")}</p>
						</Space>
						<Row>
							<Select
								defaultValue={props.selectedApplicant.internshipTutorId}
								onChange={(event) => props.setSelectedTutor(event)}
								style={{ width: "18rem" }}
							>
								{companyUsers?.map((item, index) => (
									<Option value={item.id} key={"user" + index}>
										{item.name}
									</Option>
								))}
							</Select>
						</Row>
					</Spin>
				</Modal>
			) : (
				<ConfirmationModal
					modalText={
						props.selectedApplicant.availablePositions !== 0
							? t("students.acceptStudentMessage") +
							  props.selectedApplicant.name +
							  t("students.acceptStudentMessage2")
							: t("students.acceptStudentExceeded") +
							  props.selectedApplicant.internshipName +
							  t("students.acceptStudentExceeded2")
					}
					handleFunction={() => handleAcceptApplicant(props.selectedApplicant)}
					modalVisibility={props.modalVisibility}
					title={
						props.selectedApplicant.availablePositions !== 0
							? ""
							: t("students.acceptStudentExceededTitle")
					}
					changeModalVisibility={() => props.setModalVisibility(false)}
					spinning={props.spinning}
					divStyle={{}}
				/>
			)}
		</>
	);
};
