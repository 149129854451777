import { StudentYearsSelectProps } from "./StudentYearsSelect.types";
import { Form, Select } from "antd";
import {
	InternshipEngagementType,
	StudentYearEnum,
} from "../../../../../../Api";
import styles from "../../../AddInternshipForm.module.scss";
import { useTranslation } from "react-i18next";
import moment from "moment/moment";
import { useCallback } from "react";
import {
	userYears,
	userYearsBachelor,
	userYearsInternship,
	userYearsMaster,
	userYearsSummerSchool,
} from "../../../../../../utils/constants";
const { Option } = Select;

export const StudentYearsSelect = (props: StudentYearsSelectProps) => {
	const { t } = useTranslation();

	const userYearsByEngagementType = useCallback(
		(type: InternshipEngagementType) => {
			if (type === InternshipEngagementType.BachelorsInternship) {
				return userYearsBachelor.map((year) => (
					<Option key={year} value={year}>
						{year}
					</Option>
				));
			} else if (type === InternshipEngagementType.MastersInternship) {
				return userYearsMaster.map((year) => (
					<Option key={year} value={year}>
						{year}
					</Option>
				));
			} else if (type === InternshipEngagementType.SummerSchool) {
				return userYearsSummerSchool.map((year) => (
					<Option key={year} value={year}>
						{year}
					</Option>
				));
			} else if (type === InternshipEngagementType.Internship) {
				return props.isProfessor
					? props.professorUser?.faculty?.practiceInternshipPeriods?.map(
							(p) => (
								<Option key={p.practiceYear} value={p.practiceYear}>
									{p.practiceYear}
								</Option>
							)
					  )
					: props.facultiesWithCompanyAccordList !== undefined &&
					  props.facultiesWithCompanyAccordList.length > 0
					? props
							.facultiesWithCompanyAccordList!.find(
								(e) => e.id === props.selectedFaculties[0]
							)
							?.practiceInternshipPeriods?.map((period) => (
								<Option key={period.practiceYear} value={period.practiceYear}>
									{period.practiceYear}
								</Option>
							))
					: [];
			} else if (type === InternshipEngagementType.Job) {
				return userYearsInternship.map((year) => (
					<Option key={year} value={year}>
						{year}
					</Option>
				));
			} else {
				return userYears.map((year) => (
					<Option key={year} value={year}>
						{year}
					</Option>
				));
			}
		},
		[
			props.facultiesWithCompanyAccordList,
			props.isProfessor,
			props.professorUser?.faculty?.practiceInternshipPeriods,
		]
	);

	const handleSelectedYears = (selected: StudentYearEnum) => {
		props.setSelectedYears(Array.isArray(selected) ? selected : [selected]);

		if (
			props.engagementTypeValue === InternshipEngagementType.Internship &&
			props.selectedFaculties &&
			props.selectedFaculties.length > 0
		) {
			if (props.isProfessor) {
				const selectedFaculty = props.professorUser?.faculty;

				if (
					selectedFaculty &&
					selectedFaculty.practiceInternshipPeriods &&
					selectedFaculty.practiceInternshipPeriods.length > 0
				) {
					const selectedPracticePeriod =
						selectedFaculty.practiceInternshipPeriods.find(
							(p) => p.practiceYear === selected
						);

					props.form.setFieldsValue({
						...props.form.getFieldsValue(),
						totalPracticeHours: selectedPracticePeriod?.practiceNumberOfHours,
					} as any);

					props.setDisabledDateStart(
						moment(selectedPracticePeriod?.practiceEvaluationStart) ?? moment()
					);
					props.setDisabledDateEnd(
						moment(selectedPracticePeriod?.practiceEvaluationEnd).add(
							-2,
							"month"
						) ?? moment().add(1, "y")
					);
				}
			} else {
				const selectedFaculty = props.facultiesWithCompanyAccordList?.find(
					(f) => f.id === props.selectedFaculties[0]
				);

				if (
					selectedFaculty &&
					selectedFaculty.practiceInternshipPeriods &&
					selectedFaculty.practiceInternshipPeriods.length > 0
				) {
					const selectedPracticePeriod =
						selectedFaculty.practiceInternshipPeriods.find(
							(p) => p.practiceYear === selected
						);

					props.form.setFieldsValue({
						...props.form.getFieldsValue(),
						totalPracticeHours: selectedPracticePeriod?.practiceNumberOfHours,
					} as any);

					props.setDisabledDateStart(
						moment(selectedPracticePeriod?.practiceEvaluationStart) ?? moment()
					);
					props.setDisabledDateEnd(
						moment(selectedPracticePeriod?.practiceEvaluationEnd).add(
							-2,
							"month"
						) ?? moment().add(1, "y")
					);
				}
			}
		}
	};

	return (
		<Form.Item
			required={
				props.engagementTypeValue === InternshipEngagementType.Internship
			}
			name="years"
			label={
				(props.engagementTypeValue === InternshipEngagementType.Internship
					? t("internships.addInternshipForm.year")
					: t("internships.addInternshipForm.years")) + ":"
			}
			tooltip={
				props.engagementTypeValue !== InternshipEngagementType.Internship
					? t("internships.addInternshipForm.yearHelper")
					: null
			}
			rules={[
				{
					required:
						props.engagementTypeValue === InternshipEngagementType.Internship,
					message:
						props.engagementTypeValue === InternshipEngagementType.Internship
							? t("internships.addInternshipForm.requiredYear")
							: "",
				},
			]}
		>
			<Select
				className={styles.antSelect}
				mode={
					props.engagementTypeValue === InternshipEngagementType.Internship
						? undefined
						: "multiple"
				}
				disabled={props.disabled}
				allowClear
				style={{ width: "100%", textAlign: "left" }}
				placeholder={
					props.engagementTypeValue === InternshipEngagementType.Internship
						? t("internships.addInternshipForm.year")
						: t("internships.addInternshipForm.years")
				}
				onChange={handleSelectedYears}
				notFoundContent={t("internships.addInternshipForm.notFoundContent")}
			>
				{userYearsByEngagementType(props.engagementTypeValue)}
			</Select>
		</Form.Item>
	);
};
