/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
	StudentYearEnum,
	StudentYearEnumFromJSON,
	StudentYearEnumFromJSONTyped,
	StudentYearEnumToJSON,
} from "./StudentYearEnum";
import {
	StudyProgramDTO,
	StudyProgramDTOFromJSON,
	StudyProgramDTOFromJSONTyped,
	StudyProgramDTOToJSON,
} from "./StudyProgramDTO";

/**
 *
 * @export
 * @interface StudentStudyProgramDTO
 */
export interface StudentStudyProgramDTO {
	/**
	 *
	 * @type {string}
	 * @memberof StudentStudyProgramDTO
	 */
	id?: string;
	/**
	 *
	 * @type {string}
	 * @memberof StudentStudyProgramDTO
	 */
	studentId?: string;
	/**
	 *
	 * @type {number}
	 * @memberof StudentStudyProgramDTO
	 */
	universityYear?: number;
	/**
	 *
	 * @type {StudentYearEnum}
	 * @memberof StudentStudyProgramDTO
	 */
	studentYear?: StudentYearEnum;
	/**
	 *
	 * @type {StudyProgramDTO}
	 * @memberof StudentStudyProgramDTO
	 */
	studyProgram?: StudyProgramDTO;
}

export function StudentStudyProgramDTOFromJSON(
	json: any
): StudentStudyProgramDTO {
	return StudentStudyProgramDTOFromJSONTyped(json, false);
}

export function StudentStudyProgramDTOFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): StudentStudyProgramDTO {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		id: !exists(json, "id") ? undefined : json["id"],
		studentId: !exists(json, "studentId") ? undefined : json["studentId"],
		universityYear: !exists(json, "universityYear")
			? undefined
			: json["universityYear"],
		studentYear: !exists(json, "studentYear")
			? undefined
			: StudentYearEnumFromJSON(json["studentYear"]),
		studyProgram: !exists(json, "studyProgram")
			? undefined
			: StudyProgramDTOFromJSON(json["studyProgram"]),
	};
}

export function StudentStudyProgramDTOToJSON(
	value?: StudentStudyProgramDTO | null
): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		id: value.id,
		studentId: value.studentId,
		universityYear: value.universityYear,
		studentYear: StudentYearEnumToJSON(value.studentYear),
		studyProgram: StudyProgramDTOToJSON(value.studyProgram),
	};
}
