/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
	PoliJobsPotentialCandidateDTOPagedResponse,
	PoliJobsPotentialCandidateDTOPagedResponseFromJSON,
	PoliJobsPotentialCandidateDTOPagedResponseToJSON,
	PoliJobsProposalCandidateFiltersDTO,
	PoliJobsProposalCandidateFiltersDTOFromJSON,
	PoliJobsProposalCandidateFiltersDTOToJSON,
} from "../models";

export interface ApiPoliJobsPotentialCandidateAcceptRecommendationIdPutRequest {
	id: string;
}

export interface ApiPoliJobsPotentialCandidateGenerateRecommendationsForProposalPoliJobsProposalIdPutRequest {
	poliJobsProposalId: string;
	poliJobsProposalCandidateFiltersDTO?: PoliJobsProposalCandidateFiltersDTO;
}

export interface ApiPoliJobsPotentialCandidateGetRecommendationsForProposalPoliJobsProposalIdGetRequest {
	poliJobsProposalId: string;
	page?: number;
	pageSize?: number;
}

export interface ApiPoliJobsPotentialCandidateInvalidateRecommendationIdPutRequest {
	id: string;
}

/**
 *
 */
export class PoliJobsPotentialCandidateApi extends runtime.BaseAPI {
	/**
	 */
	async apiPoliJobsPotentialCandidateAcceptRecommendationIdPutRaw(
		requestParameters: ApiPoliJobsPotentialCandidateAcceptRecommendationIdPutRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<void>> {
		if (requestParameters.id === null || requestParameters.id === undefined) {
			throw new runtime.RequiredError(
				"id",
				"Required parameter requestParameters.id was null or undefined when calling apiPoliJobsPotentialCandidateAcceptRecommendationIdPut."
			);
		}

		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/PoliJobsPotentialCandidate/AcceptRecommendation/{id}`.replace(
					`{${"id"}}`,
					encodeURIComponent(String(requestParameters.id))
				),
				method: "PUT",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.VoidApiResponse(response);
	}

	/**
	 */
	async apiPoliJobsPotentialCandidateAcceptRecommendationIdPut(
		requestParameters: ApiPoliJobsPotentialCandidateAcceptRecommendationIdPutRequest,
		initOverrides?: RequestInit
	): Promise<void> {
		await this.apiPoliJobsPotentialCandidateAcceptRecommendationIdPutRaw(
			requestParameters,
			initOverrides
		);
	}

	/**
	 */
	async apiPoliJobsPotentialCandidateGenerateRecommendationsForProposalPoliJobsProposalIdPutRaw(
		requestParameters: ApiPoliJobsPotentialCandidateGenerateRecommendationsForProposalPoliJobsProposalIdPutRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<void>> {
		if (
			requestParameters.poliJobsProposalId === null ||
			requestParameters.poliJobsProposalId === undefined
		) {
			throw new runtime.RequiredError(
				"poliJobsProposalId",
				"Required parameter requestParameters.poliJobsProposalId was null or undefined when calling apiPoliJobsPotentialCandidateGenerateRecommendationsForProposalPoliJobsProposalIdPut."
			);
		}

		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		headerParameters["Content-Type"] = "application/json";

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/PoliJobsPotentialCandidate/GenerateRecommendationsForProposal/{poliJobsProposalId}`.replace(
					`{${"poliJobsProposalId"}}`,
					encodeURIComponent(String(requestParameters.poliJobsProposalId))
				),
				method: "PUT",
				headers: headerParameters,
				query: queryParameters,
				body: PoliJobsProposalCandidateFiltersDTOToJSON(
					requestParameters.poliJobsProposalCandidateFiltersDTO
				),
			},
			initOverrides
		);

		return new runtime.VoidApiResponse(response);
	}

	/**
	 */
	async apiPoliJobsPotentialCandidateGenerateRecommendationsForProposalPoliJobsProposalIdPut(
		requestParameters: ApiPoliJobsPotentialCandidateGenerateRecommendationsForProposalPoliJobsProposalIdPutRequest,
		initOverrides?: RequestInit
	): Promise<void> {
		await this.apiPoliJobsPotentialCandidateGenerateRecommendationsForProposalPoliJobsProposalIdPutRaw(
			requestParameters,
			initOverrides
		);
	}

	/**
	 */
	async apiPoliJobsPotentialCandidateGetRecommendationsForProposalPoliJobsProposalIdGetRaw(
		requestParameters: ApiPoliJobsPotentialCandidateGetRecommendationsForProposalPoliJobsProposalIdGetRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<PoliJobsPotentialCandidateDTOPagedResponse>> {
		if (
			requestParameters.poliJobsProposalId === null ||
			requestParameters.poliJobsProposalId === undefined
		) {
			throw new runtime.RequiredError(
				"poliJobsProposalId",
				"Required parameter requestParameters.poliJobsProposalId was null or undefined when calling apiPoliJobsPotentialCandidateGetRecommendationsForProposalPoliJobsProposalIdGet."
			);
		}

		const queryParameters: any = {};

		if (requestParameters.page !== undefined) {
			queryParameters["Page"] = requestParameters.page;
		}

		if (requestParameters.pageSize !== undefined) {
			queryParameters["PageSize"] = requestParameters.pageSize;
		}

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/PoliJobsPotentialCandidate/GetRecommendationsForProposal/{poliJobsProposalId}`.replace(
					`{${"poliJobsProposalId"}}`,
					encodeURIComponent(String(requestParameters.poliJobsProposalId))
				),
				method: "GET",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			PoliJobsPotentialCandidateDTOPagedResponseFromJSON(jsonValue)
		);
	}

	/**
	 */
	async apiPoliJobsPotentialCandidateGetRecommendationsForProposalPoliJobsProposalIdGet(
		requestParameters: ApiPoliJobsPotentialCandidateGetRecommendationsForProposalPoliJobsProposalIdGetRequest,
		initOverrides?: RequestInit
	): Promise<PoliJobsPotentialCandidateDTOPagedResponse> {
		const response =
			await this.apiPoliJobsPotentialCandidateGetRecommendationsForProposalPoliJobsProposalIdGetRaw(
				requestParameters,
				initOverrides
			);
		return await response.value();
	}

	/**
	 */
	async apiPoliJobsPotentialCandidateInvalidateRecommendationIdPutRaw(
		requestParameters: ApiPoliJobsPotentialCandidateInvalidateRecommendationIdPutRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<void>> {
		if (requestParameters.id === null || requestParameters.id === undefined) {
			throw new runtime.RequiredError(
				"id",
				"Required parameter requestParameters.id was null or undefined when calling apiPoliJobsPotentialCandidateInvalidateRecommendationIdPut."
			);
		}

		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/PoliJobsPotentialCandidate/InvalidateRecommendation/{id}`.replace(
					`{${"id"}}`,
					encodeURIComponent(String(requestParameters.id))
				),
				method: "PUT",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.VoidApiResponse(response);
	}

	/**
	 */
	async apiPoliJobsPotentialCandidateInvalidateRecommendationIdPut(
		requestParameters: ApiPoliJobsPotentialCandidateInvalidateRecommendationIdPutRequest,
		initOverrides?: RequestInit
	): Promise<void> {
		await this.apiPoliJobsPotentialCandidateInvalidateRecommendationIdPutRaw(
			requestParameters,
			initOverrides
		);
	}
}
