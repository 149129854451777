import {
	CompanyResearchProposalAddUpdateDTO,
	CompanyResearchProposalApi,
	InternshipSortFieldEnum,
	SortDirectionEnum,
} from "../Api";
import { getAuthConfiguration } from "./configuration";

const AuthCompanyResearchProposalApiFactory = () =>
	new CompanyResearchProposalApi(getAuthConfiguration());

export const addCompanyResearchProposal = async (
	companyResearchProposalAddUpdateDTO: CompanyResearchProposalAddUpdateDTO
) => {
	await AuthCompanyResearchProposalApiFactory().apiCompanyResearchProposalAddPost(
		{ companyResearchProposalAddUpdateDTO }
	);
};

interface AllCompanyResearchPropsalsProps {
	searchTerm?: string;
	page?: number;
	pageSize?: number;
	sortField?: InternshipSortFieldEnum;
	sortDirection?: SortDirectionEnum;
}

export const getAllCompanyResearchPropsalsV2 = (
	props: AllCompanyResearchPropsalsProps
) => {
	const { page, pageSize } = props;
	return AuthCompanyResearchProposalApiFactory().apiCompanyResearchProposalGetAllGet(
		{ page, pageSize }
	);
};

export const getAllCompanyResearchPropsals = (
	searchTerm?: string,
	page?: number,
	pageSize?: number,
	sortField?: InternshipSortFieldEnum,
	sortDirection?: SortDirectionEnum
) => {
	return AuthCompanyResearchProposalApiFactory().apiCompanyResearchProposalGetAllGet(
		{ page, pageSize }
	);
};

export const getCompanyResearchProposalById = (id: string) => {
	return AuthCompanyResearchProposalApiFactory().apiCompanyResearchProposalGetByIdIdGet(
		{ id }
	);
};

export const updateCompanyResearchProposalInfo = async (
	id: string,
	companyResearchProposalAddUpdateDTO?: CompanyResearchProposalAddUpdateDTO
) => {
	await AuthCompanyResearchProposalApiFactory().apiCompanyResearchProposalUpdateIdPut(
		{ id, companyResearchProposalAddUpdateDTO }
	);
};

export const deleteCompanyResearchProposal = async (id: string) => {
	await AuthCompanyResearchProposalApiFactory().apiCompanyResearchProposalDeleteIdDelete(
		{ id }
	);
};

// export const getAllCompanyInternshipFilters = () => {
//     return AuthCompanyInternshipApiFactory().apiCompanyInternshipGetInternshipFiltersGet();
// }
