import Layout from "../../Containers/Layout";
import { PreviousEnrollmentsView } from "../Views";

export const PreviousEnrollmentsPage = (props: { location: any }) => {
	const pathname = props.location.pathname;

	return (
		<Layout>
			<PreviousEnrollmentsView pathname={pathname} />
		</Layout>
	);
};
