/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface SkillEndorsementDTO
 */
export interface SkillEndorsementDTO {
	/**
	 *
	 * @type {string}
	 * @memberof SkillEndorsementDTO
	 */
	id?: string;
	/**
	 *
	 * @type {string}
	 * @memberof SkillEndorsementDTO
	 */
	professorId?: string;
	/**
	 *
	 * @type {string}
	 * @memberof SkillEndorsementDTO
	 */
	userSkillId?: string;
}

export function SkillEndorsementDTOFromJSON(json: any): SkillEndorsementDTO {
	return SkillEndorsementDTOFromJSONTyped(json, false);
}

export function SkillEndorsementDTOFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): SkillEndorsementDTO {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		id: !exists(json, "id") ? undefined : json["id"],
		professorId: !exists(json, "professorId") ? undefined : json["professorId"],
		userSkillId: !exists(json, "userSkillId") ? undefined : json["userSkillId"],
	};
}

export function SkillEndorsementDTOToJSON(
	value?: SkillEndorsementDTO | null
): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		id: value.id,
		professorId: value.professorId,
		userSkillId: value.userSkillId,
	};
}
