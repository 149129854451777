/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
	Company,
	CompanyFromJSON,
	CompanyFromJSONTyped,
	CompanyToJSON,
} from "./Company";
import {
	UniversityAccordReview,
	UniversityAccordReviewFromJSON,
	UniversityAccordReviewFromJSONTyped,
	UniversityAccordReviewToJSON,
} from "./UniversityAccordReview";
import {
	UniversityAccordSignature,
	UniversityAccordSignatureFromJSON,
	UniversityAccordSignatureFromJSONTyped,
	UniversityAccordSignatureToJSON,
} from "./UniversityAccordSignature";
import {
	UniversityAccordStateEnum,
	UniversityAccordStateEnumFromJSON,
	UniversityAccordStateEnumFromJSONTyped,
	UniversityAccordStateEnumToJSON,
} from "./UniversityAccordStateEnum";

/**
 *
 * @export
 * @interface CompanyUniversityAccord
 */
export interface CompanyUniversityAccord {
	/**
	 *
	 * @type {string}
	 * @memberof CompanyUniversityAccord
	 */
	id?: string;
	/**
	 *
	 * @type {Date}
	 * @memberof CompanyUniversityAccord
	 */
	createdAt?: Date;
	/**
	 *
	 * @type {Date}
	 * @memberof CompanyUniversityAccord
	 */
	updatedAt?: Date;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyUniversityAccord
	 */
	companyId?: string;
	/**
	 *
	 * @type {number}
	 * @memberof CompanyUniversityAccord
	 */
	number?: number;
	/**
	 *
	 * @type {Date}
	 * @memberof CompanyUniversityAccord
	 */
	endDate?: Date;
	/**
	 *
	 * @type {UniversityAccordStateEnum}
	 * @memberof CompanyUniversityAccord
	 */
	state?: UniversityAccordStateEnum;
	/**
	 *
	 * @type {Company}
	 * @memberof CompanyUniversityAccord
	 */
	company?: Company;
	/**
	 *
	 * @type {Array<UniversityAccordSignature>}
	 * @memberof CompanyUniversityAccord
	 */
	universityAccordSignatures?: Array<UniversityAccordSignature> | null;
	/**
	 *
	 * @type {Array<UniversityAccordReview>}
	 * @memberof CompanyUniversityAccord
	 */
	universityAccordReviews?: Array<UniversityAccordReview> | null;
}

export function CompanyUniversityAccordFromJSON(
	json: any
): CompanyUniversityAccord {
	return CompanyUniversityAccordFromJSONTyped(json, false);
}

export function CompanyUniversityAccordFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): CompanyUniversityAccord {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		id: !exists(json, "id") ? undefined : json["id"],
		createdAt: !exists(json, "createdAt")
			? undefined
			: new Date(json["createdAt"]),
		updatedAt: !exists(json, "updatedAt")
			? undefined
			: new Date(json["updatedAt"]),
		companyId: !exists(json, "companyId") ? undefined : json["companyId"],
		number: !exists(json, "number") ? undefined : json["number"],
		endDate: !exists(json, "endDate") ? undefined : new Date(json["endDate"]),
		state: !exists(json, "state")
			? undefined
			: UniversityAccordStateEnumFromJSON(json["state"]),
		company: !exists(json, "company")
			? undefined
			: CompanyFromJSON(json["company"]),
		universityAccordSignatures: !exists(json, "universityAccordSignatures")
			? undefined
			: json["universityAccordSignatures"] === null
			? null
			: (json["universityAccordSignatures"] as Array<any>).map(
					UniversityAccordSignatureFromJSON
			  ),
		universityAccordReviews: !exists(json, "universityAccordReviews")
			? undefined
			: json["universityAccordReviews"] === null
			? null
			: (json["universityAccordReviews"] as Array<any>).map(
					UniversityAccordReviewFromJSON
			  ),
	};
}

export function CompanyUniversityAccordToJSON(
	value?: CompanyUniversityAccord | null
): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		id: value.id,
		createdAt:
			value.createdAt === undefined ? undefined : value.createdAt.toISOString(),
		updatedAt:
			value.updatedAt === undefined ? undefined : value.updatedAt.toISOString(),
		companyId: value.companyId,
		number: value.number,
		endDate:
			value.endDate === undefined ? undefined : value.endDate.toISOString(),
		state: UniversityAccordStateEnumToJSON(value.state),
		company: CompanyToJSON(value.company),
		universityAccordSignatures:
			value.universityAccordSignatures === undefined
				? undefined
				: value.universityAccordSignatures === null
				? null
				: (value.universityAccordSignatures as Array<any>).map(
						UniversityAccordSignatureToJSON
				  ),
		universityAccordReviews:
			value.universityAccordReviews === undefined
				? undefined
				: value.universityAccordReviews === null
				? null
				: (value.universityAccordReviews as Array<any>).map(
						UniversityAccordReviewToJSON
				  ),
	};
}
