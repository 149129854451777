import { Table, Progress, Typography, Pagination, Button } from "antd";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../Notifications/NotificationsUtils";
import CustomButton from "../../CustomComponents/CustomButton";
import { removeDiacritics } from "../../utils/dataUtils";
import { downloadExcel } from "../../utils/downloadUtils";

const openErrorNotification = (error: string, message: string) => {
	openNotification(error, message, NOTIFICATION_TYPES.ERROR);
};

async function downloadAll<T>(
	getCall: any,
	args: any[],
	setProgress: (progress: number) => void,
	onError: () => void
) {
	let data = new Array<T>();
	let hasData = true;
	let pageIndex = 1;
	setProgress(0);

	do {
		const page = await getCall(pageIndex, 100, ...args).catch(onError);

		++pageIndex;

		if (page?.data === undefined || page?.data === null) {
			onError();

			return;
		}

		data = data.concat(page.data);

		if ((pageIndex - 1) * 100 >= (page.totalCount ?? 0)) {
			hasData = false;
		}

		setProgress(
			hasData
				? Math.round((((pageIndex - 1) * 100) / (page.totalCount ?? 1)) * 100)
				: 100
		);
	} while (hasData);

	return data;
}

function ReportTable(props: {
	queryKey: string;
	getCall: any;
	args: any[];
	columns: any[];
	reportColumns: any[];
	watchChanges?: any[];
	fileName: string;
}) {
	const { t } = useTranslation();
	const [progress, setProgress] = useState(0);
	const [downloading, setDownloading] = useState(false);
	const [downloadError, setDownloadError] = useState(false);
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(10);

	let locale = {
		emptyText: t("tableText.noReports"),
	};

	const downloadReport = useCallback(async () => {
		setDownloading(true);

		return downloadExcel(
			props.fileName,
			props.reportColumns.map((e) => {
				return { header: removeDiacritics(e.title), key: e.key.toString() };
			}),
			await downloadAll(
				props.getCall,
				props.args,
				(value) => {
					setDownloadError(false);
					setProgress(value);
				},
				() => {
					setDownloadError(true);
					openErrorNotification(
						t("reports.errorTexts.downloadFailed"),
						t("reports.errorTexts.downloadFailedMessage")
					);
				}
			)
		);
	}, [
		setDownloading,
		props.reportColumns,
		props.getCall,
		props.args,
		props.fileName,
		t,
	]);

	const { data: report, isLoading } = useQuery(
		[props.queryKey, props.args, page, pageSize, ...(props.watchChanges ?? [])],
		() => props.getCall(page, pageSize, ...props.args),
		{
			refetchOnWindowFocus: false,
			onError: () =>
				openErrorNotification(
					t("reports.errorTexts.loadingFailed"),
					t("reports.errorTexts.loadingFailedMessage")
				),
		}
	);

	const onPageUpdate = useCallback(
		(page: number, pageSize: number) => {
			setPage(page);
			setPageSize(pageSize);
		},
		[setPage, setPageSize]
	);

	return (
		<div className="flex flex-col items-end gap-4">
			<div>
				<Button type="primary" onClick={downloadReport}>
					{t("reports.download")}
				</Button>
				{downloading && (
					<Progress
						percent={progress}
						status={downloadError ? "exception" : undefined}
					/>
				)}
			</div>

			<div className="flex flex-col gap-10 w-full">
				<div className="flex flex-col md:flex-row justify-between items-center">
					<Typography.Title level={3} className="my-0">
						{report?.totalCount} {t("filtering.results")}
					</Typography.Title>
					{!isLoading && (
						<Pagination
							defaultCurrent={report?.page}
							total={report?.totalCount}
							onChange={onPageUpdate}
							locale={{ items_per_page: t("pagination") }}
						/>
					)}
				</div>

				<Table
					locale={locale}
					columns={props.columns.map((e) => {
						return {
							title: e.title,
							fixed: e.fixed,
							key: e.key.toString(),
							dataIndex: e.key.toString(),
							// sorter: e.sorter,
							render: e.render,
						};
					})}
					dataSource={report?.data ?? []}
					pagination={{
						position: ["none"],
					}}
					scroll={{ x: "calc(700px + 50%)", y: 420 }}
					rowKey={(record) => record.id!}
				/>
			</div>
		</div>
	);
}

export default ReportTable;
