import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../Notifications/NotificationsUtils";
import { useTranslation } from "react-i18next";
import { useQuery, useQueryClient } from "react-query";
import CountUp from "react-countup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Button, Typography } from "antd";
import { isUserLogged } from "../../utils/utilFunctions";
import {
	getStatisticsForLandingPage,
	getRoles,
} from "../../utils/reactQueriesConstants";
import { getLandingPageStatistics } from "../../Requests/statistics-requests";
import DashboardLayout from "../../layouts/Dashboard";
import SimpleLayout from "../../layouts/Simple";
import { Routes } from "../../utils/routes";

const Home = () => {
	if (isUserLogged())
		return (
			<DashboardLayout>
				<HomeContent />
			</DashboardLayout>
		);

	return (
		<SimpleLayout>
			<HomeContent />
		</SimpleLayout>
	);
};

const HomeContent = () => {
	const { t } = useTranslation();
	const [boolInfo, setBoolInfo] = useState(false);
	const search = useLocation().search;
	const queryClient = useQueryClient();
	const { Text } = Typography;

	const openExpire = (message: string, description: string) =>
		setTimeout(() => {
			openNotification(t(message), t(description), NOTIFICATION_TYPES.ERROR);
		}, 1000);

	const { data: statistics } = useQuery(
		[getStatisticsForLandingPage],
		() => getLandingPageStatistics(),
		{
			refetchOnWindowFocus: false,
			onError: () =>
				openNotification(
					t("reports.errorTexts.loadingFailed"),
					t("reports.errorTexts.loadingFailedMessage"),
					NOTIFICATION_TYPES.ERROR
				),
		}
	);

	useEffect(() => {
		if (!boolInfo) {
			const token = new URLSearchParams(search).get("token") as any;
			const refreshToken = new URLSearchParams(search).get(
				"refreshToken"
			) as any;
			const expired = new URLSearchParams(search).get("expired") as any;

			if (expired) {
				queryClient.invalidateQueries(getRoles);
				openExpire(
					"home.messages.sessionExpired",
					"home.messages.sessionExpiredMessage"
				);
			}

			//TO DO: complete fetch info for profile

			if (token) {
				localStorage.setItem("token", token);
				localStorage.setItem("refreshToken", refreshToken);
			} else {
				const error = new URLSearchParams(search).get("error");
				if (error) {
					openExpire("home.messages.loginFailure", "home.messages.noAccount");
				}
			}
			setBoolInfo(true);
		}
	}, [search, boolInfo, t]);

	useEffect(() => {
		const nextUrl = localStorage.getItem("nextUrl");
		if (isUserLogged() && nextUrl !== null) {
			localStorage.removeItem("nextUrl");
			window.location.replace(nextUrl!);
		}
	}, [isUserLogged]);

	return (
		<div className="flex flex-col items-center gap-10 grow justify-center px-4">
			<Text className="text-center text-3xl max-w-[60ch]">
				{t("home.welcomeText")}
			</Text>

			<img
				alt="logo"
				src="/images/logo/text.svg"
				className="w-[90%] max-w-[420px]"
			/>

			{!isUserLogged() && (
				<div className="flex flex-col md:flex-row gap-4">
					<Link to={Routes.LOGIN}>
						<Button type="primary">{t("header.login")}</Button>
					</Link>
					<Link to={Routes.REGISTER}>
						<Button>{t("header.signup")}</Button>
					</Link>
				</div>
			)}

			<div className="relative overflow-hidden max-h-[900px] md:max-h-none">
				<img
					src="/images/globe.svg"
					alt="globe"
					className="min-h-[1400px] md:min-h-min"
				/>

				<div className="absolute left-0 right-0 top-0 min-h-full flex flex-col md:flex-row md:justify-center gap-x-14 gap-y-8 pt-20 md:pt-40">
					<div className=" flex flex-col gap-2">
						<FontAwesomeIcon
							icon={solid("graduation-cap")}
							className="h-[40px] text-white"
						/>

						<CountUp
							end={statistics?.studentsCount ?? 0}
							duration={2}
							className="font-Montserrat font-black text-4xl text-white text-center"
						/>
						<div className="text-white text-xl text-center mx-auto max-w-[10ch]">
							{t("home.enrolledStudents")}
						</div>
					</div>

					<div className=" flex flex-col gap-2">
						<FontAwesomeIcon
							icon={solid("building")}
							className="h-[40px] text-white"
						/>

						<CountUp
							end={statistics?.partnerCompaniesCount ?? 0}
							duration={2}
							className="font-Montserrat font-black text-4xl text-white text-center"
						/>
						<div className="text-white text-xl mx-auto max-w-[10ch] text-center">
							{t("home.partnerCompanies")}
						</div>
					</div>

					<div className=" flex flex-col gap-2">
						<FontAwesomeIcon
							icon={solid("file")}
							className="h-[40px] text-white"
						/>

						<CountUp
							end={statistics?.proposalsCount ?? 0}
							duration={2}
							className="font-Montserrat font-black text-4xl text-white text-center"
						/>
						<div className="text-white text-xl mx-auto max-w-[10ch] text-center">
							{t("home.offers")}
						</div>
					</div>

					<div className=" flex flex-col gap-2">
						<FontAwesomeIcon
							icon={solid("chalkboard-user")}
							className="h-[40px] text-white"
						/>

						<CountUp
							end={statistics?.professorsCount ?? 0}
							duration={2}
							className="font-Montserrat font-black text-4xl text-white text-center"
						/>
						<div className="text-white text-xl mx-auto max-w-[10ch] text-center">
							{t("home.professors")}
						</div>
					</div>
				</div>
			</div>

			{/* <div className="container mx-auto flex flex-col md:flex-row gap-10 justify-around items-center w-full pb-20">
					<img src="/images/partners/adobe.png" alt="adobe" />

					<img src="/images/partners/google.png" alt="google" />

					<img src="/images/partners/cisco.png" alt="cisco" />

					<img src="/images/partners/deloitte.png" alt="deloitte" />
				</div> */}
		</div>
	);
};
export default Home;
