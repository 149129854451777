import { Button, Form, Modal, Select, Spin } from "antd";
import { useMemo, useState } from "react";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../Notifications/NotificationsUtils";
import styles from "./Profile.module.scss";
import { theme } from "../../theme";
import { useTranslation } from "react-i18next";
import { useQuery, useQueryClient } from "react-query";
import CustomForm from "../../CustomComponents/CustomForm";
import CustomModalFooter from "../../Containers/CustomModalFooter";
import {
	getAvailablePrograms,
	getSupervisedPrograms,
} from "../../utils/reactQueriesConstants";
import { getErrorFromResponse } from "../../utils/responseUtils";
import { ErrorCodes, StudentYearEnum } from "../../Api";
import { addSupervisedStudyProgram } from "../../Requests/academic-user-requests";
import { getAvailableStudyPrograms } from "../../Requests/student-study-requests";

const { Option } = Select;

const AddSupervisedStudyProgramModal = (props: { supervisorId: string }) => {
	const { t } = useTranslation();
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [spinning, setSpinning] = useState(false);
	const [form] = Form.useForm<{
		studyProgramId: string;
		studyYear: StudentYearEnum;
	}>();
	const studyProgramId = Form.useWatch("studyProgramId", form);

	const openGetErrorNotification = (_error: any) => {
		openNotification(
			t("internships.error"),
			t("internships.fetchDataError"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const { data: availableStudyPrograms } = useQuery(
		[getAvailablePrograms, props.supervisorId],
		() =>
			getAvailableStudyPrograms(props.supervisorId).then((studyPrograms) =>
				studyPrograms
					.map((studyProgram) => ({
						...studyProgram,
						availableYears: (studyProgram.facultyAcronym === "FIIR"
							? [StudentYearEnum.L2, StudentYearEnum.L3]
							: [StudentYearEnum.L3]
						).filter((year) => !studyProgram.supervisedYears?.includes(year)),
					}))
					.filter(
						(studyProgram) =>
							studyProgram.availableYears?.length &&
							studyProgram.availableYears?.length > 0
					)
			),
		{
			onError: (err) => {
				openGetErrorNotification(err);
			},
		}
	);

	const selectedStudyProgram = useMemo(
		() => availableStudyPrograms?.find((sp) => sp.id === studyProgramId),
		[availableStudyPrograms, studyProgramId]
	);

	const queryClient = useQueryClient();
	const invalidateUsersQuery = async () => {
		await queryClient.invalidateQueries(getAvailablePrograms);
		await queryClient.invalidateQueries(getSupervisedPrograms);
	};

	const handleShow = () => {
		setIsModalVisible(true);
	};

	const handleClose = () => {
		setIsModalVisible(false);
	};

	const saveChangesError = async (ex: any) => {
		const error = await getErrorFromResponse(ex);

		openNotification(
			t("account.studyProgramAdd"),
			error?.code === ErrorCodes.Forbidden
				? t("account.noPermissions")
				: error?.code === ErrorCodes.SupervisorNotFound
				? t("account.supervisorNotFound")
				: error?.code === ErrorCodes.StudyProgramNotFound
				? t("account.studyProgramNotFound")
				: error?.code === ErrorCodes.SupervisorAlreadyAllocated
				? t("account.studyProgramAlreadyAllocated")
				: t("account.studyProgramAddError"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const missingFieldsError = () => {
		openNotification(
			t("account.saveError"),
			t("usersText.requiredFieldsError"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const handleAdd = () => {
		const state = form.getFieldsValue();

		if (!state.studyProgramId) {
			missingFieldsError();
			return;
		}

		setSpinning(true);

		addSupervisedStudyProgram(
			props.supervisorId,
			state.studyProgramId,
			state.studyYear ??
				selectedStudyProgram?.availableYears?.at(0) ??
				StudentYearEnum.L3
		)
			.then(async () => {
				form.setFieldsValue({
					studyProgramId: "",
					studyYear: undefined,
				});
				await invalidateUsersQuery();
				openNotification(
					t("account.studyProgramAdd"),
					t("account.studyProgramAddSuccess"),
					NOTIFICATION_TYPES.SUCCESS
				);
			})
			.catch(saveChangesError)
			.finally(() => {
				setSpinning(false);
				setIsModalVisible(false);
			});
	};

	return (
		<div style={{ display: "flex", justifyContent: "flex-end" }}>
			<Button
				type="primary"
				onClick={handleShow}
				title={t("account.addNewStudyProgram")}
			>
				{t("account.addStudyProgram")}
			</Button>

			<Modal
				open={isModalVisible}
				onOk={() => form.submit()}
				onCancel={handleClose}
				title={t("account.addStudyProgram")}
				width={window.innerWidth > 1215 ? "30%" : "80%"}
				footer={
					<CustomModalFooter
						handleClose={handleClose}
						spinning={spinning}
						handleSave={() => form.submit()}
						confirmButtonName={t("account.confirm")}
					/>
				}
			>
				<Spin spinning={spinning}>
					<Form.Provider onFormFinish={handleAdd}>
						<CustomForm layout={theme.layout} form={form}>
							<div
								style={{
									display: "flex",
									justifyContent: "flex-end",
									gap: "16px",
								}}
							>
								<Form.Item
									required
									label={
										t("account.addStudyProgramForm.studyProgramName") + ":"
									}
									name="studyProgramId"
									rules={[
										{
											required: true,
											message: t("usersText.requiredField", {
												field: t(
													"account.addStudyProgramForm.studyProgramName"
												),
											}),
										},
									]}
								>
									<Select
										className={styles.antSelect}
										allowClear
										style={{ width: "100%", textAlign: "left" }}
										placeholder={t(
											"account.addStudyProgramForm.studyProgramName"
										)}
									>
										{availableStudyPrograms?.length &&
											availableStudyPrograms?.length > 0 &&
											availableStudyPrograms?.map((sp) => (
												<Option key={sp.id} value={sp.id}>
													{sp.name}
												</Option>
											))}
									</Select>
								</Form.Item>
								{selectedStudyProgram?.availableYears?.length &&
									selectedStudyProgram?.availableYears?.length > 1 && (
										<Form.Item
											required
											label={
												t("account.addStudyProgramForm.studyProgramYear") + ":"
											}
											name="studyYear"
											rules={[
												{
													required: true,
													message: t("usersText.requiredField", {
														field: t(
															"account.addStudyProgramForm.studyProgramYear"
														),
													}),
												},
											]}
										>
											<Select
												className={styles.antSelect}
												allowClear
												style={{ width: "100%", textAlign: "left" }}
												placeholder={t(
													"account.addStudyProgramForm.studyProgramYear"
												)}
											>
												{selectedStudyProgram?.availableYears?.map((year) => (
													<Option key={year} value={year}>
														{t("account." + year)}
													</Option>
												))}
											</Select>
										</Form.Item>
									)}
							</div>
						</CustomForm>
					</Form.Provider>
				</Spin>
			</Modal>
		</div>
	);
};

export default AddSupervisedStudyProgramModal;
