import { RemoveApplicantModalProps } from "./RemoveApplicantModal.types";
import ConfirmationModal from "../../../../../../Containers/ConfirmationModal";
import { useTranslation } from "react-i18next";
import { deleteEnrollment } from "../../../../../../Requests/internship-requests";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../../../../../Notifications/NotificationsUtils";

export const RemoveApplicantModal = (props: RemoveApplicantModalProps) => {
	const { t } = useTranslation();

	const removeApplicant = (item: any) => {
		props.setSpinning(true);

		deleteEnrollment(item.internshipEnrollmentId)
			.then(async () => {
				await props.invalidateApplicantsQueries();
				openNotification(
					t("students.removeApplicant"),
					t("students.successRemoveApplicantMessage"),
					NOTIFICATION_TYPES.SUCCESS
				);
			})
			.catch((_error) => {
				openNotification(
					t("students.removeApplicant"),
					t("students.errorRemoveApplicantMessage"),
					NOTIFICATION_TYPES.ERROR
				);
			})
			.finally(() => {
				props.setSpinning(false);
				props.setModalVisibility(false);
			});
	};

	return (
		<ConfirmationModal
			modalText={
				t("students.removeApplicantMessage") +
				props.selectedApplicant.name +
				t("students.removeApplicantMessage2")
			}
			handleFunction={() => removeApplicant(props.selectedApplicant)}
			modalVisibility={props.modalVisibility}
			title=""
			changeModalVisibility={() => props.setModalVisibility(false)}
			spinning={props.spinning}
			divStyle={{}}
		/>
	);
};
