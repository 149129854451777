/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum ExperienceLevel {
	NotSpecified = "NotSpecified",
	Junior = "Junior",
	Middle = "Middle",
	Senior = "Senior",
}

export function ExperienceLevelFromJSON(json: any): ExperienceLevel {
	return ExperienceLevelFromJSONTyped(json, false);
}

export function ExperienceLevelFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): ExperienceLevel {
	return json as ExperienceLevel;
}

export function ExperienceLevelToJSON(value?: ExperienceLevel | null): any {
	return value as any;
}
