import {
	FeedbackQuestionsApi,
	FormTypeEnum,
	AnswersApi,
	AddAnswerDTO,
} from "../Api";
import { getAuthConfiguration } from "./configuration";

const AuthQuestionApiFactory = () =>
	new FeedbackQuestionsApi(getAuthConfiguration());
const AuthAnswerApiFactory = () => new AnswersApi(getAuthConfiguration());

export const getQuestionsByForm = (formType: FormTypeEnum) => {
	return AuthQuestionApiFactory().apiFeedbackQuestionsGetQuestionsByFormGet({
		formType,
	});
};

export const addAnswer = (answers: AddAnswerDTO[], formType: FormTypeEnum) => {
	return AuthAnswerApiFactory().apiAnswersSaveAnswersPost({
		addAnswersDTO: { answers, formType },
	});
};
