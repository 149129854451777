import styles from "./InternshipApplicantActions.module.scss";
import { Space } from "antd";
import { theme } from "../../../../../../../theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useTranslation } from "react-i18next";
import { InternshipApplicantActionsProps } from "./InternshipApplicantActions.types";
import { useIsProfessor } from "../../../../../../../utils/utilFunctions";
import {
	EnrollmentStatus,
	InternshipEngagementType,
	InternshipType,
} from "../../../../../../../Api";
import CustomButton from "../../../../../../../CustomComponents/CustomButton";

export const InternshipApplicantActions = (
	props: InternshipApplicantActionsProps
) => {
	const { t } = useTranslation();
	const isProfessor = useIsProfessor();

	return (
		<div className={styles.modalContainer} key={"actions-" + props.record.id}>
			<Space>
				{(props.record.recruiterStatus === EnrollmentStatus.Waiting ||
					(props.record.recruiterStatus ===
						EnrollmentStatus.ProfessorAccepted &&
						!isProfessor)) && (
					<div className={styles.modalBtnContainer}>
						<CustomButton
							fontSize={"0.9rem"}
							style={{
								background: "transparent",
								border: "none",
								outline: "none",
								color: theme.black,
								boxShadow: "none",
								padding: 0,
								marginTop: 0,
							}}
							onClick={() => {
								props.setSelectedApplicant(props.record);
								props.setSelectedTutor(props.record.internshipTutorId!);
								props.setAcceptModalVisibility(true);
							}}
							title={t("students.acceptApplicant")}
							icon={
								<FontAwesomeIcon
									icon={solid("circle-check")}
									style={{ fontSize: "15px" }}
								/>
							}
						/>
					</div>
				)}
				{props.record.recruiterStatus === EnrollmentStatus.Waiting &&
					(props.record.internshipEngagementType ===
						InternshipEngagementType.BachelorsInternship ||
						props.record.internshipEngagementType ===
							InternshipEngagementType.MastersInternship) &&
					(!isProfessor ||
						props.record.internshipType === InternshipType.ByProfessor) && (
						<div className={styles.modalBtnContainer}>
							<CustomButton
								fontSize={"0.9rem"}
								style={{
									background: "transparent",
									border: "none",
									outline: "none",
									color: theme.black,
									boxShadow: "none",
									padding: 0,
									marginTop: 0,
								}}
								onClick={() => {
									props.setSelectedApplicant(props.record);
									props.setFinalAcceptModalVisibility(true);
								}}
								title={t("students.finalAcceptApplicant")}
								icon={
									<FontAwesomeIcon
										icon={solid("check-double")}
										style={{ fontSize: "15px" }}
									/>
								}
							/>
						</div>
					)}
				{(props.record.recruiterStatus === EnrollmentStatus.Waiting ||
					(props.record.recruiterStatus ===
						EnrollmentStatus.ProfessorAccepted &&
						!isProfessor)) && (
					<div className={styles.modalBtnContainer}>
						<CustomButton
							fontSize={"0.9rem"}
							style={{
								background: "transparent",
								border: "none",
								outline: "none",
								color: theme.black,
								boxShadow: "none",
								padding: 0,
								marginTop: 0,
							}}
							onClick={() => {
								props.setSelectedApplicant(props.record);
								props.setRejectModalVisibility(true);
							}}
							title={t("students.rejectApplicant")}
							icon={
								<FontAwesomeIcon
									icon={solid("ban")}
									style={{ fontSize: "15px" }}
								/>
							}
						/>
					</div>
				)}
			</Space>
		</div>
	);
};
