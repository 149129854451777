import { useCallback } from "react";
import { Pagination, Table, Typography } from "antd";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../../../../Notifications/NotificationsUtils";
import { getEmailRecipients } from "../../../../../utils/reactQueriesConstants";
import { EmailRecipientDTO } from "../../../../../Api";
import { getNewsletterEmailRecipientsV2 } from "../../../../../Requests/newsletter-requests";
import useQueryFilters from "../../../../../Hooks/useQueryFilters";

export const EmailRecipientsTable = (props: {
	emailId: string | undefined;
}) => {
	const { t } = useTranslation();
	const query = useQueryFilters({});

	let locale = {
		emptyText: t("tableText.noRecipients"),
	};

	const openGetErrorNotification = (_error: any) => {
		openNotification(
			t("emails.error"),
			t("emails.fetchDataError"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const { data: recipients, isLoading } = useQuery(
		[getEmailRecipients, props.emailId, query.filters],
		() =>
			props.emailId
				? getNewsletterEmailRecipientsV2({
						id: props.emailId,
						...query.filters,
				  })
				: null,
		{
			onError: (err) => {
				openGetErrorNotification(err);
			},
		}
	);

	const columns = [
		{
			title: t("account.name"),
			dataIndex: "recipientName",
			key: "recipientName",
			// sorter: (a: EmailRecipientDTO, b: EmailRecipientDTO) => {
			// 	if (!a.recipientName || !b.recipientName) return -1;
			// 	return a.recipientName.localeCompare(b.recipientName);
			// },
		},
		{
			title: t("account.email"),
			dataIndex: "recipientEmail",
			key: "recipientEmail",
			// sorter: (a: EmailRecipientDTO, b: EmailRecipientDTO) => {
			// 	if (!a.recipientEmail || !b.recipientEmail) return -1;
			// 	return a.recipientEmail.localeCompare(b.recipientEmail);
			// },
		},
		{
			title: t("emails.status"),
			dataIndex: "status",
			key: "status",
			fixed: "right" as "right",
			// sorter: (a: EmailRecipientDTO, b: EmailRecipientDTO) => {
			// 	if (!a.status || !b.status) return -1;
			// 	return a.status.localeCompare(b.status);
			// },
			render: (_text: string | undefined, record: EmailRecipientDTO) =>
				t("emails.statuses." + record.status),
		},
	];

	const onPageUpdate = useCallback(
		(page: number, pageSize: number) => {
			query.update({
				page: page.toString(),
				pageSize: pageSize.toString(),
			});
		},
		[query]
	);

	return (
		<div className="px-4 pb-10 flex flex-col">
			<div className="flex flex-col gap-10">
				<div className="flex flex-col md:flex-row justify-between items-center">
					<Typography.Title level={3} className="my-0">
						{recipients?.totalCount} {t("filtering.results")}
					</Typography.Title>
					{!isLoading && (
						<Pagination
							defaultCurrent={recipients?.page}
							defaultPageSize={recipients?.pageSize}
							total={recipients?.totalCount}
							onChange={onPageUpdate}
							locale={{ items_per_page: t("pagination") }}
						/>
					)}
				</div>

				<Table
					locale={locale}
					columns={columns}
					dataSource={recipients?.data ?? []}
					pagination={false}
					scroll={{ x: "calc(700px + 50%)", y: 420 }}
					rowKey={(record) => record.id!}
				/>
			</div>
		</div>
	);
};
