/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
	InternshipEnrollment,
	InternshipEnrollmentFromJSON,
	InternshipEnrollmentFromJSONTyped,
	InternshipEnrollmentToJSON,
} from "./InternshipEnrollment";
import {
	PracticalActivity,
	PracticalActivityFromJSON,
	PracticalActivityFromJSONTyped,
	PracticalActivityToJSON,
} from "./PracticalActivity";
import {
	PracticeNotebookSignature,
	PracticeNotebookSignatureFromJSON,
	PracticeNotebookSignatureFromJSONTyped,
	PracticeNotebookSignatureToJSON,
} from "./PracticeNotebookSignature";
import {
	PracticeNotebookState,
	PracticeNotebookStateFromJSON,
	PracticeNotebookStateFromJSONTyped,
	PracticeNotebookStateToJSON,
} from "./PracticeNotebookState";

/**
 *
 * @export
 * @interface PracticeNotebook
 */
export interface PracticeNotebook {
	/**
	 *
	 * @type {string}
	 * @memberof PracticeNotebook
	 */
	id?: string;
	/**
	 *
	 * @type {Date}
	 * @memberof PracticeNotebook
	 */
	createdAt?: Date;
	/**
	 *
	 * @type {Date}
	 * @memberof PracticeNotebook
	 */
	updatedAt?: Date;
	/**
	 *
	 * @type {string}
	 * @memberof PracticeNotebook
	 */
	title?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof PracticeNotebook
	 */
	internshipEnrollmentId?: string;
	/**
	 *
	 * @type {InternshipEnrollment}
	 * @memberof PracticeNotebook
	 */
	internshipEnrollment?: InternshipEnrollment;
	/**
	 *
	 * @type {PracticeNotebookState}
	 * @memberof PracticeNotebook
	 */
	state?: PracticeNotebookState;
	/**
	 *
	 * @type {string}
	 * @memberof PracticeNotebook
	 */
	signingTutorName?: string | null;
	/**
	 *
	 * @type {Array<PracticalActivity>}
	 * @memberof PracticeNotebook
	 */
	practicalActivities?: Array<PracticalActivity> | null;
	/**
	 *
	 * @type {Array<PracticeNotebookSignature>}
	 * @memberof PracticeNotebook
	 */
	practiceNotebookSignatures?: Array<PracticeNotebookSignature> | null;
}

export function PracticeNotebookFromJSON(json: any): PracticeNotebook {
	return PracticeNotebookFromJSONTyped(json, false);
}

export function PracticeNotebookFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): PracticeNotebook {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		id: !exists(json, "id") ? undefined : json["id"],
		createdAt: !exists(json, "createdAt")
			? undefined
			: new Date(json["createdAt"]),
		updatedAt: !exists(json, "updatedAt")
			? undefined
			: new Date(json["updatedAt"]),
		title: !exists(json, "title") ? undefined : json["title"],
		internshipEnrollmentId: !exists(json, "internshipEnrollmentId")
			? undefined
			: json["internshipEnrollmentId"],
		internshipEnrollment: !exists(json, "internshipEnrollment")
			? undefined
			: InternshipEnrollmentFromJSON(json["internshipEnrollment"]),
		state: !exists(json, "state")
			? undefined
			: PracticeNotebookStateFromJSON(json["state"]),
		signingTutorName: !exists(json, "signingTutorName")
			? undefined
			: json["signingTutorName"],
		practicalActivities: !exists(json, "practicalActivities")
			? undefined
			: json["practicalActivities"] === null
			? null
			: (json["practicalActivities"] as Array<any>).map(
					PracticalActivityFromJSON
			  ),
		practiceNotebookSignatures: !exists(json, "practiceNotebookSignatures")
			? undefined
			: json["practiceNotebookSignatures"] === null
			? null
			: (json["practiceNotebookSignatures"] as Array<any>).map(
					PracticeNotebookSignatureFromJSON
			  ),
	};
}

export function PracticeNotebookToJSON(value?: PracticeNotebook | null): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		id: value.id,
		createdAt:
			value.createdAt === undefined ? undefined : value.createdAt.toISOString(),
		updatedAt:
			value.updatedAt === undefined ? undefined : value.updatedAt.toISOString(),
		title: value.title,
		internshipEnrollmentId: value.internshipEnrollmentId,
		internshipEnrollment: InternshipEnrollmentToJSON(
			value.internshipEnrollment
		),
		state: PracticeNotebookStateToJSON(value.state),
		signingTutorName: value.signingTutorName,
		practicalActivities:
			value.practicalActivities === undefined
				? undefined
				: value.practicalActivities === null
				? null
				: (value.practicalActivities as Array<any>).map(
						PracticalActivityToJSON
				  ),
		practiceNotebookSignatures:
			value.practiceNotebookSignatures === undefined
				? undefined
				: value.practiceNotebookSignatures === null
				? null
				: (value.practiceNotebookSignatures as Array<any>).map(
						PracticeNotebookSignatureToJSON
				  ),
	};
}
