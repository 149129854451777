import { Button, Pagination, Typography } from "antd";
import { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../Notifications/NotificationsUtils";
import { useTranslation } from "react-i18next";
import { useQuery, useQueryClient } from "react-query";
import OffersList from "./OffersList";
import {
	getPoliJobsOffers,
	getPoliJobsProposalFilters,
} from "../../utils/reactQueriesConstants";
import { PlusCircleOutlined } from "@ant-design/icons";
import useQueryFilters from "../../Hooks/useQueryFilters";
import {
	getAllPoliJobsProposalFilters,
	getAllPoliJobsProposals,
} from "../../Requests/polijobs-proposals-requests";
import { Routes } from "../../utils/routes";
import { useIsPoliJobsAdmin } from "../../utils/utilFunctions";
import Filters from "../Filters";

const PoliJobsOffers = (props: any) => {
	const { t } = useTranslation();
	const isPoliJobsAdmin = useIsPoliJobsAdmin();
	const [pageSize, setPageSize] = useState(10);
	const [currentPage, setCurrentPage] = useState(1);
	const [checkedKeys, setCheckedKeys] = useState([]);
	const [searchTerm, setSearchTerm] = useState("");
	const queryClient = useQueryClient();
	const query = useQueryFilters({});
	const history = useHistory();

	const handleListChange = async (current: number, pageSize: number) => {
		setCurrentPage(() => current);
		setPageSize(() => pageSize);
		await queryClient.invalidateQueries(getPoliJobsOffers);
	};

	const openGetErrorNotification = (_error: any) => {
		openNotification(
			t("polijobs.error"),
			t("polijobs.fetchDataError"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const { data: filters } = useQuery(
		[getPoliJobsProposalFilters],
		() => {
			return getAllPoliJobsProposalFilters();
		},
		{
			onError: openGetErrorNotification,
			refetchOnWindowFocus: false,
		}
	);

	const { data: offers, isLoading: loadingOffers } = useQuery(
		[getPoliJobsOffers, query.filters],
		() => getAllPoliJobsProposals(query.filters),
		{
			onError: openGetErrorNotification,
			refetchOnWindowFocus: false,
		}
	);

	const onUpdate = useCallback(
		(values: { [key: string]: string[] }) => {
			query.update(values, true);
		},
		[query]
	);

	const onPageUpdate = useCallback(
		(page: number, pageSize: number) => {
			query.update({
				page: page.toString(),
				pageSize: pageSize.toString(),
			});
		},
		[query]
	);

	const goToAction = useCallback(() => {
		history.push(Routes.EDIT_POLIJOBS, {
			filters: checkedKeys,
			searchTerm: searchTerm,
			currentPage: currentPage,
			pageSize: pageSize,
		});
	}, [checkedKeys, currentPage, history, pageSize, searchTerm]);

	return (
		<div className="px-4 pb-10 flex flex-col relative">
			{isPoliJobsAdmin && (
				<div className="fixed bottom-10 md:relative md:bottom-auto md:self-end md:mt-10 right-10 z-50">
					<Button
						type="primary"
						className="p-6 flex flex-col md:flex-row justify-center items-center rounded-full md:rounded-md relative"
						onClick={goToAction}
					>
						<PlusCircleOutlined />

						{t("polijobs.addOffer")}
					</Button>
				</div>
			)}

			<Filters
				filters={filters}
				searchFields={[{ name: "searchTerm", label: t("internships.search") }]}
				onUpdate={onUpdate}
				hasSort={false}
			/>

			<div className="flex flex-col gap-10 relative">
				<div className="flex flex-col md:flex-row justify-between items-center">
					<Typography.Title level={3} className="my-0">
						{offers?.totalCount} {t("filtering.results")}
					</Typography.Title>

					{!loadingOffers && (
						<Pagination
							defaultCurrent={offers?.page}
							total={offers?.totalCount}
							onChange={onPageUpdate}
							pageSizeOptions={[12, 24, 48, 96]}
							defaultPageSize={12}
						/>
					)}
				</div>

				<OffersList
					data={offers}
					checkedKeys={[]}
					loading={loadingOffers}
					location={props.location}
					activeTab={"1"}
					currentPage={currentPage}
					pageSize={pageSize}
					searchTerm={""}
					handleListChange={handleListChange}
				/>
			</div>
		</div>
	);
};

export default PoliJobsOffers;
