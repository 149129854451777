import { useCallback, useEffect, useState } from "react";
import { CompanyStateEnum, Role } from "../../Api";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../Notifications/NotificationsUtils";
import { useTranslation } from "react-i18next";
import { Pagination, Table, Tabs, Typography } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import {
	getAllAcademicUsersNonStudentsV2,
	getAllProfessorsV2,
	getProfessorFilters,
} from "../../Requests/academic-user-requests";
import AddUserRolesModal from "./AddUserRolesModal";
import {
	activateCompany,
	validateCompany,
	getCompaniesFilter,
	invalidateCompany,
	getCompaniesV2,
} from "../../Requests/company-requests";
import styles from "../Admin/Users.module.scss";
import { Key } from "antd/lib/table/interface";
import {
	getAllKeys,
	useIsAdmin,
	useIsDean,
	useIsDepartmentDirector,
	useIsFacultyAdmin,
	useIsRector,
} from "../../utils/utilFunctions";
import StudentsTable from "../Students/StudentsTable";
import { theme } from "../../theme";
import ConfirmationModal from "../../Containers/ConfirmationModal";
import { useQuery, useQueryClient } from "react-query";
import MyStudentsTable from "../Students/MyStudentsTable";
import { Link, useLocation } from "react-router-dom";
import CustomButton from "../../CustomComponents/CustomButton";
import {
	getAllCompanies,
	getCompanyFilters,
	getFilterForProfessor,
	getUsers,
} from "../../utils/reactQueriesConstants";
import useQueryFilters from "../../Hooks/useQueryFilters";
import Filters from "../Filters";

const { TabPane } = Tabs;

const Users = () => {
	const { t, i18n } = useTranslation();
	const [searchTerm, setSearchTerm] = useState("");
	const [pageSize, setPageSize] = useState(10);
	const [currentPage, setCurrent] = useState(1);
	const [expandedKeys, setExpandedKeys] = useState<any[]>([]);
	const [checkedKeys, setCheckedKeys] = useState<Key[]>([]);
	const [expandedKeys2, setExpandedKeys2] = useState<any[]>([]);
	const [checkedKeys2, setCheckedKeys2] = useState<Key[]>([]);
	const isFacultyAdmin = useIsFacultyAdmin();
	const isDepartmentDirector = useIsDepartmentDirector();
	const isDean = useIsDean();
	const isAdmin = useIsAdmin();
	const isRector = useIsRector();
	const location = useLocation();
	const { search } = useLocation();
	const [companyId, setCompanyId] = useState("");
	const [activateCompanyModalVisibility, setActivateCompanyModalVisibility] =
		useState(false);
	const [validateCompanyModalVisibility, setValidateCompanyModalVisibility] =
		useState(false);
	const [
		invalidateCompanyModalVisibility,
		setInvalidateCompanyModalVisibility,
	] = useState(false);
	const [spinning, setSpinning] = useState(false);
	const [activeTab, setActiveTab] = useState(
		isFacultyAdmin || isDepartmentDirector ? "1" : "2"
	);
	const query = useQueryFilters({
		initialValues: {
			tab: activeTab,
		},
	});
	const companyQuery = useQueryFilters({
		initialValues: {
			tab: activeTab,
		},
	});

	let locale = {
		emptyText: t("tableText.noCompanies"),
	};

	useEffect(() => {
		const url = new URLSearchParams(search);
		if (!url) return;

		for (const [key, value] of url) {
			if (key === "tab") {
				setActiveTab(value);
				break;
			}
		}
	}, []);

	const columns = [
		{
			title: t("account.name"),
			dataIndex: "name",
			key: "name",
			fixed: "left" as "left",
			// sorter: (a: SimpleViewAcademicUserDTO, b: SimpleViewAcademicUserDTO) => {
			// 	if (!a.name || !b.name) return -1;
			// 	return a.name.localeCompare(b.name);
			// },
			render: (_text: string | undefined, record: any) => (
				<Link
					to={{
						pathname: `/profil`,
						state: {
							id: record.id,
							userType: Role.Professor,
							origin: location.pathname,
							activeTab: activeTab,
							oldSearchTerm: searchTerm,
							pageSize: pageSize,
							currentPage: currentPage,
							filters: checkedKeys,
						},
					}}
				>
					<b>{record.name}</b>
				</Link>
			),
		},
		{
			title: t("students.faculty"),
			dataIndex: "facultyNameRo",
			key: "facultyNameRo",
			// sorter: (a: SimpleViewAcademicUserDTO, b: SimpleViewAcademicUserDTO) => {
			// 	if (!a.facultyNameRo || !b.facultyNameRo) return -1;
			// 	return a.facultyNameRo.localeCompare(b.facultyNameRo);
			// },
			hidden: isFacultyAdmin || isDean,
		},
		{
			title: t("admin.department"),
			dataIndex: "departmentNameRo",
			key: "departmentNameRo",
			// sorter: (a: SimpleViewAcademicUserDTO, b: SimpleViewAcademicUserDTO) => {
			// 	if (!a.departmentNameRo || !b.departmentNameRo) return -1;
			// 	return a.departmentNameRo.localeCompare(b.departmentNameRo);
			// },
		},
		{
			title: t("students.email"),
			dataIndex: "email",
			key: "email",
			// sorter: (a: SimpleViewAcademicUserDTO, b: SimpleViewAcademicUserDTO) => {
			// 	if (!a.email || !b.email) return -1;
			// 	return a.email.localeCompare(b.email);
			// },
		},
		{
			title: t("students.phone"),
			dataIndex: "phone",
			key: "phone",
			// sorter: (a: SimpleViewAcademicUserDTO, b: SimpleViewAcademicUserDTO) => {
			// 	if (!a.phone || !b.phone) return -1;
			// 	return a.phone.localeCompare(b.phone);
			// },
		},
		{
			title: t("admin.roles"),
			dataIndex: "roles",
			key: "roles",
			fixed: "right" as "right",
			width: 85,
			render: (text: string | undefined, record: any) => (
				<AddUserRolesModal
					roles={record.roles}
					icon={
						<FontAwesomeIcon
							icon={solid("users-gear")}
							title={t("admin.editRoles")}
						/>
					}
					userId={record.id}
				/>
			),
			hidden: !isAdmin && !isDean && !isFacultyAdmin,
		},
	].filter((item) => !item.hidden);

	const companyColumns = [
		{
			title: t("account.name"),
			dataIndex: "name",
			fixed: "left" as "left",
			// sorter: (a: CompanyDTO, b: CompanyDTO) => {
			// 	if (!a.commercialName || !b.commercialName) return -1;
			// 	return a.commercialName.localeCompare(b.commercialName);
			// },
			render: (_text: string | undefined, record: any) => (
				<Link
					to={{
						pathname: `/profil`,
						state: {
							id: record.id,
							userType: Role.Company,
							origin: location.pathname,
							activeTab: activeTab,
							oldSearchTerm: searchTerm,
							pageSize: pageSize,
							currentPage: currentPage,
							filters: checkedKeys2,
						},
					}}
				>
					<b>{record.commercialName}</b>
				</Link>
			),
		},
		{
			title: t("admin.cui"),
			dataIndex: "cui",
			key: "cui",
			// sorter: (a: CompanyDTO, b: CompanyDTO) => {
			// 	if (!a.cui || !b.cui) return -1;
			// 	return a.cui.localeCompare(b.cui);
			// },
		},
		{
			title: t("admin.domainOfActivity"),
			dataIndex: "domainOfActivity",
			key: "domainOfActivity",
			// sorter: (a: CompanyDTO, b: CompanyDTO) => {
			// 	if (!a.domainOfActivity || !b.domainOfActivity) return -1;
			// 	return a.domainOfActivity.localeCompare(b.domainOfActivity);
			// },
		},
		{
			title: t("students.phone"),
			dataIndex: "phone",
			key: "phone",
			// sorter: (a: CompanyDTO, b: CompanyDTO) => {
			// 	if (!a.phone || !b.phone) return -1;
			// 	return a.phone.localeCompare(b.phone);
			// },
		},
		{
			title: t("admin.PracticeProtocolStatus"),
			dataIndex: "companyDocumentStatus",
			key: "companyDocumentStatus",
			// sorter: (a: CompanyDTO, b: CompanyDTO) => {
			// 	if (!a.companyDocumentStatus || !b.companyDocumentStatus) return -1;
			// 	return a.companyDocumentStatus.localeCompare(b.companyDocumentStatus);
			// },
			render: (text: string | undefined, record: any) => (
				<span>{t("admin." + record.companyDocumentStatus)}</span>
			),
		},
		// {
		//     title: t('profile.address'),
		//     dataIndex: 'address',
		//     key: 'address',
		//     render: (text: string | undefined, record: any) =>
		//         <span>{record.country ? record.country + ', ' : ''}{record.city ? record.city + ', ' : ''}{record.address ? record.address : '-'}</span>
		// },
		// {
		//     title: t('students.email'),
		//     dataIndex: 'emailContact',
		//     key: 'emailContact',
		// },
		{
			title: t("admin.State"),
			dataIndex: "state",
			render: (_text: string | undefined, record: any) =>
				record.state === CompanyStateEnum.AdminApproved ? (
					<div style={{ whiteSpace: "nowrap" }}>
						<div className={styles.modalBtnContainer}>
							<CustomButton
								fontSize={"0.9rem"}
								style={{
									background: "transparent",
									border: "none",
									outline: "none",
									color: theme.black,
									boxShadow: "none",
									padding: 0,
								}}
								onClick={() => {
									setCompanyId(record.id);
									setActivateCompanyModalVisibility(true);
								}}
								icon={<FontAwesomeIcon icon={solid("user-check")} />}
								title={t("admin.activateCompany")}
							/>
							<ConfirmationModal
								modalText={t("admin.activateCompanyMessage")}
								handleFunction={() =>
									changeCompanyState(CompanyStateEnum.Active, companyId)
								}
								modalVisibility={activateCompanyModalVisibility}
								changeModalVisibility={() =>
									setActivateCompanyModalVisibility(false)
								}
								title=""
								spinning={spinning}
							/>
						</div>
						<div className={styles.modalBtnContainer}>
							<CustomButton
								fontSize={"0.9rem"}
								style={{
									background: "transparent",
									border: "none",
									outline: "none",
									color: theme.black,
									boxShadow: "none",
									padding: 0,
								}}
								onClick={() => {
									setCompanyId(record.id);
									setInvalidateCompanyModalVisibility(true);
								}}
								icon={<FontAwesomeIcon icon={solid("ban")} />}
								title={t("admin.invalidateCompany")}
							/>
							<ConfirmationModal
								modalText={t("admin.invalidateCompanyMessage")}
								handleFunction={() =>
									changeCompanyState(
										CompanyStateEnum.PendingAdminVerification,
										companyId
									)
								}
								modalVisibility={invalidateCompanyModalVisibility}
								changeModalVisibility={() =>
									setInvalidateCompanyModalVisibility(false)
								}
								title=""
								spinning={spinning}
							/>
						</div>
					</div>
				) : record.state === CompanyStateEnum.PendingAdminVerification ? (
					<div style={{ whiteSpace: "nowrap" }}>
						<div className={styles.modalBtnContainer}>
							<CustomButton
								fontSize={"0.9rem"}
								style={{
									background: "transparent",
									border: "none",
									outline: "none",
									color: theme.black,
									boxShadow: "none",
									padding: 0,
								}}
								onClick={() => {
									setCompanyId(record.id);
									setValidateCompanyModalVisibility(true);
								}}
								icon={<FontAwesomeIcon icon={solid("circle-check")} />}
								title={t("admin.validateCompany")}
							/>
							<ConfirmationModal
								modalText={t("admin.validateCompanyMessage")}
								handleFunction={() =>
									changeCompanyState(CompanyStateEnum.AdminApproved, companyId)
								}
								modalVisibility={validateCompanyModalVisibility}
								changeModalVisibility={() =>
									setValidateCompanyModalVisibility(false)
								}
								title=""
								spinning={spinning}
							/>
						</div>
					</div>
				) : (
					<div>{t("admin." + record.state)}</div>
				),
		},
		{
			title: t("students.documents"),
			dataIndex: "documents",
			key: "documents",
			fixed: "right" as "right",
			width: 85,
			render: (_text: string | undefined, record: any) => (
				<Link
					to={{
						pathname: "/documente-companie",
						state: {
							activeTab: activeTab,
							oldSearchTerm: searchTerm,
							pageSize: pageSize,
							currentPage: currentPage,
							filters: checkedKeys2,
							company: record.commercialName,
							companyId: record.id,
						},
					}}
				>
					<FontAwesomeIcon
						icon={solid("arrow-right")}
						title={t("students.viewDocuments")}
					/>
				</Link>
			),
			hidden: !isFacultyAdmin,
		},
	];

	const openNotificationErrorFetchUsers = (ex: any) => {
		if (ex.status) {
			openNotification(
				t("usersText.errorTexts.failedUserGet"),
				t("usersText.errorTexts.serverFailedDescription"),
				NOTIFICATION_TYPES.ERROR
			);
		} else {
			openNotification(
				t("usersText.errorTexts.failedUserGet"),
				t("usersText.errorTexts.networkFailedDescription"),
				NOTIFICATION_TYPES.ERROR
			);
		}
	};

	const queryClient = useQueryClient();
	const { data, isLoading: loading } = useQuery(
		[getUsers, isFacultyAdmin, isAdmin, isRector, query.filters],
		() => {
			if (isAdmin || isRector) {
				return getAllAcademicUsersNonStudentsV2(query.filters);
			}

			if (isFacultyAdmin || isDean || isDepartmentDirector) {
				return getAllProfessorsV2(query.filters);
			}
		},
		{
			onError: openNotificationErrorFetchUsers,
		}
	);

	const { data: companyData, isLoading: loadingCompanies } = useQuery(
		[
			getAllCompanies,
			isAdmin,
			isRector,
			isFacultyAdmin,
			isDean,
			isDepartmentDirector,
			companyQuery.filters,
		],
		() => {
			if (
				isAdmin ||
				isRector ||
				isFacultyAdmin ||
				isDean ||
				isDepartmentDirector
			) {
				return getCompaniesV2(companyQuery.filters);
			}
		},
		{
			onError: openNotificationErrorFetchUsers,
		}
	);

	const openFilterErrorNotification = (_error: any) => {
		openNotification(
			t("usersText.errorTexts.error"),
			t("usersText.errorTexts.filterDataError"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const { data: filters } = useQuery(
		[getFilterForProfessor, isAdmin, isRector, isDean, isFacultyAdmin],
		() => {
			if (isAdmin || isRector || isDean || isFacultyAdmin) {
				return getProfessorFilters(i18n.language);
			}
		},
		{
			onError: openFilterErrorNotification,
			onSuccess: (response: any) => {
				if (isAdmin || isRector || isDean || isFacultyAdmin) {
					if (!expandedKeys || expandedKeys.length === 0)
						setExpandedKeys(getAllKeys(response));
				}
			},
		}
	);

	const { data: companyFilters } = useQuery(
		[getCompanyFilters, isAdmin, isFacultyAdmin, isRector],
		() => {
			if (isAdmin || isFacultyAdmin || isRector) return getCompaniesFilter();
		},
		{
			onError: openFilterErrorNotification,
			onSuccess: (response: any) => {
				if (isAdmin)
					if (!expandedKeys2 || expandedKeys2.length === 0)
						setExpandedKeys2(getAllKeys(response));
			},
		}
	);

	const openNotificationUpdateCompanyStatusError = (error: any) => {
		if (error) {
			openNotification(
				t("usersText.errorTexts.error"),
				t("admin.validateError"),
				NOTIFICATION_TYPES.ERROR
			);
		}
	};

	const changeCompanyState = (state: string, id: string) => {
		setSpinning(true);

		if (state === CompanyStateEnum.AdminApproved) {
			validateCompany(id)
				.then(async () => {
					await queryClient.invalidateQueries(getAllCompanies);
				})
				.catch((error: any) => openNotificationUpdateCompanyStatusError(error))
				.finally(() => {
					setSpinning(false);
					setValidateCompanyModalVisibility(false);
				});
		} else if (state === CompanyStateEnum.Active) {
			activateCompany(id)
				.then(async () => {
					await queryClient.invalidateQueries(getAllCompanies);
				})
				.catch((error: any) => openNotificationUpdateCompanyStatusError(error))
				.finally(() => {
					setSpinning(false);
					setActivateCompanyModalVisibility(false);
				});
		} else {
			invalidateCompany(id)
				.then(async () => {
					await queryClient.invalidateQueries(getAllCompanies);
				})
				.catch((error: any) => openNotificationUpdateCompanyStatusError(error))
				.finally(() => {
					setSpinning(false);
					setInvalidateCompanyModalVisibility(false);
				});
		}
	};

	const changeTab = (activeKey: any) => {
		setActiveTab(activeKey);

		query.update(
			{
				tab: [activeKey],
				Search: "",
				Type: [],
				StudyProgram: [],
				Year: [],
				Department: [],
				Faculty: [],
			},
			true
		);

		companyQuery.update(
			{
				tab: [activeKey],
				Search: "",
				Type: [],
				StudyProgram: [],
				Year: [],
				Department: [],
				Faculty: [],
			},
			true
		);
	};

	const onPageUpdate = useCallback(
		(page: number, pageSize: number) => {
			query.update({
				page: page.toString(),
				pageSize: pageSize.toString(),
			});
		},
		[query]
	);

	const onPageUpdateCompany = useCallback(
		(page: number, pageSize: number) => {
			companyQuery.update({
				page: page.toString(),
				pageSize: pageSize.toString(),
			});
		},
		[companyQuery]
	);

	const onUpdate = useCallback(
		(values: { [key: string]: string[] }) => {
			query.update({ tab: [activeTab], ...values }, true);
		},
		[query]
	);

	const onUpdateCompany = useCallback(
		(values: { [key: string]: string[] }) => {
			companyQuery.update({ tab: [activeTab], ...values }, true);
		},
		[companyQuery]
	);

	return (
		<Tabs
			tabBarStyle={{
				padding: "0px 1rem",
			}}
			activeKey={activeTab}
			onChange={changeTab}
		>
			{(isFacultyAdmin || isDepartmentDirector || isDean) && (
				<TabPane tab={t("admin.myStudents")} key="1">
					<MyStudentsTable
						location={location}
						existingFilters={checkedKeys2}
						oldSearchTerm={searchTerm}
						currentPage={currentPage}
						pageSize={pageSize}
						activeTab={activeTab}
					/>
				</TabPane>
			)}
			<TabPane tab={t("admin.students")} key="2">
				<StudentsTable />
			</TabPane>
			<TabPane
				tab={isAdmin || isRector ? t("admin.upbStaff") : t("admin.professors")}
				key="3"
			>
				<div className="px-4 pb-10 flex flex-col">
					{(isAdmin || isRector || isDean || isFacultyAdmin) && filters && (
						<Filters
							filters={filters}
							searchFields={[{ name: "search", label: t("search.byName") }]}
							onUpdate={onUpdate}
							hasSort={false}
						/>
					)}

					<div className="flex flex-col gap-10">
						<div className="flex flex-col md:flex-row justify-between items-center">
							<Typography.Title level={3} className="my-0">
								{data?.totalCount} {t("filtering.results")}
							</Typography.Title>
							{!loading && (
								<Pagination
									defaultCurrent={data?.page}
									defaultPageSize={data?.pageSize}
									total={data?.totalCount}
									onChange={onPageUpdate}
									locale={{ items_per_page: t("pagination") }}
								/>
							)}
						</div>

						<Table
							locale={locale}
							columns={columns}
							dataSource={data?.data ?? []}
							pagination={false}
							scroll={{ x: "calc(700px + 50%)", y: 420 }}
							rowKey={(record) => record.id!}
						/>
					</div>
				</div>
			</TabPane>
			{(isAdmin ||
				isRector ||
				isFacultyAdmin ||
				isDepartmentDirector ||
				isDean) && (
				<TabPane tab={t("admin.companies")} key="4">
					<div className="px-4 pb-10 flex flex-col">
						<Filters
							filters={companyFilters ?? []}
							searchFields={[{ name: "search", label: t("search.byName") }]}
							onUpdate={onUpdateCompany}
							hasSort={false}
						/>

						<div className="flex flex-col gap-10">
							<div className="flex flex-col md:flex-row justify-between items-center">
								<Typography.Title level={3} className="my-0">
									{companyData?.totalCount} {t("filtering.results")}
								</Typography.Title>
								{!loadingCompanies && (
									<Pagination
										defaultCurrent={companyData?.page}
										defaultPageSize={data?.pageSize}
										total={companyData?.totalCount}
										onChange={onPageUpdateCompany}
										locale={{ items_per_page: t("pagination") }}
									/>
								)}
							</div>

							<Table
								locale={locale}
								columns={companyColumns}
								dataSource={companyData?.data ?? []}
								pagination={false}
								scroll={{ x: "calc(700px + 50%)", y: 420 }}
								rowKey={(record) => record.id!}
							/>
						</div>
					</div>
				</TabPane>
			)}
		</Tabs>
	);
};

export default Users;
