import { useCallback, useState } from "react";
import { TablePaginationConfig } from "antd";

export const usePagination = (
	defaultCurrentPage: number,
	defaultPageSize: number
) => {
	const [currentPage, setCurrentPage] = useState(defaultCurrentPage);
	const [pageSize, setPageSize] = useState(defaultPageSize);

	const handleTablePaginationChange = useCallback(
		(pagination: TablePaginationConfig) => {
			setCurrentPage(pagination.current ?? defaultCurrentPage);
			setPageSize(pagination.pageSize ?? defaultPageSize);
		},
		[defaultCurrentPage, defaultPageSize]
	);

	const handleListPaginationChange = useCallback(
		async (current: number, pageSize: number) => {
			setCurrentPage(current);
			setPageSize(pageSize);
		},
		[]
	);

	return {
		currentPage,
		setCurrentPage,
		pageSize,
		setPageSize,
		handleTablePaginationChange,
		handleListPaginationChange,
	};
};
