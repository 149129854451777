import { useCallback, useState } from "react";
import styles from "./Profile.module.scss";
import { Button, Pagination, Table, Typography } from "antd";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../Notifications/NotificationsUtils";
import { ErrorCodes } from "../../Api";
import { useQuery, useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import ConfirmationModal from "../../Containers/ConfirmationModal";
import { theme } from "../../theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import {
	getAllocatedSupervisors,
	getAvailableSupervisors,
} from "../../utils/reactQueriesConstants";
import { getAllocatedPracticeSupervisorsV2 } from "../../Requests/academic-user-requests";
import { deleteCompanyPracticeSupervisor } from "../../Requests/company-requests";
import { getErrorFromResponse } from "../../utils/responseUtils";
import AddPracticeSupervisorModal from "./AddPracticeSupervisorModal";
import useQueryFilters from "../../Hooks/useQueryFilters";

const PracticeSupervisorTable = (props: { companyId: string }) => {
	const { t, i18n } = useTranslation();
	const [removeUserModalVisibility, setRemoveUserModalVisibility] =
		useState(false);
	const [selectedUserId, setSelectedUserId] = useState<string>("");
	const [spinning, setSpinning] = useState(false);
	const query = useQueryFilters({});

	let locale = {
		emptyText: t("tableText.noPracticeSupervisors"),
	};

	const queryClient = useQueryClient();
	const invalidateUsersQuery = async () => {
		await queryClient.invalidateQueries(getAllocatedSupervisors);
		await queryClient.invalidateQueries(getAvailableSupervisors);
	};

	const handleDelete = (id: string) => {
		setSpinning(true);

		deleteCompanyPracticeSupervisor(props.companyId, id)
			.then(async () => {
				await invalidateUsersQuery();

				openNotification(
					t("account.supervisorDelete"),
					t("account.supervisorDeleteSuccess"),
					NOTIFICATION_TYPES.SUCCESS
				);
			})
			.catch(async (ex: any) => {
				const error = await getErrorFromResponse(ex);

				openNotification(
					t("account.supervisorDelete"),
					error?.code === ErrorCodes.Forbidden
						? t("account.noPermissions")
						: error?.code === ErrorCodes.SupervisionsOngoing
						? t("account.supervisionsOngoing")
						: error?.code === ErrorCodes.CompanyNotFound
						? t("account.companyNotFound")
						: error?.code === ErrorCodes.SupervisorNotFound
						? t("account.supervisorNotFound")
						: t("account.supervisorDeleteError"),
					NOTIFICATION_TYPES.ERROR
				);
			})
			.finally(() => {
				setSpinning(false);
				setRemoveUserModalVisibility(false);
			});
	};

	const openGetErrorNotification = (_error: any) => {
		openNotification(
			t("internships.error"),
			t("internships.fetchDataError"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const { data: supervisors, isLoading } = useQuery(
		[getAllocatedSupervisors, props.companyId, query.filters],
		() =>
			getAllocatedPracticeSupervisorsV2({
				companyId: props.companyId,
				...query.filters,
			}),
		{
			onError: (err) => {
				openGetErrorNotification(err);
			},
		}
	);

	const columns = [
		{
			title: t("account.name"),
			dataIndex: "name",
			key: "name",
			fixed: "left" as "left",
			// sorter: (a: ProfessorDTO, b: ProfessorDTO) => {
			// 	if (!a.name || !b.name) return -1;
			// 	return a.name.localeCompare(b.name);
			// },
		},
		{
			title: t("account.email"),
			dataIndex: "email",
			key: "email",
			// sorter: (a: ProfessorDTO, b: ProfessorDTO) => {
			// 	if (!a.email || !b.email) return -1;
			// 	return a.email.localeCompare(b.email);
			// },
		},
		{
			title: t("account.phone"),
			dataIndex: "phone",
			key: "phone",
			// sorter: (a: ProfessorDTO, b: ProfessorDTO) => {
			// 	if (!a.phone || !b.phone) return -1;
			// 	return a.phone.localeCompare(b.phone);
			// },
		},
		{
			title: t("account.department"),
			dataIndex: "department",
			key: "department",
			// sorter: (a: ProfessorDTO, b: ProfessorDTO) => {
			// 	if (i18n.language === "ro") {
			// 		if (!a.departmentNameRo || !b.departmentNameRo) return -1;
			// 		return a.departmentNameRo.localeCompare(b.departmentNameRo);
			// 	} else {
			// 		if (!a.departmentNameEn || !b.departmentNameEn) return -1;
			// 		return a.departmentNameEn.localeCompare(b.departmentNameEn);
			// 	}
			// },
			render: (_text: string | undefined, record: any) =>
				i18n.language === "ro"
					? record.departmentNameRo
					: record.departmentNameEn,
		},
		{
			title: t("account.actions"),
			fixed: "right" as "right",
			width: 85,
			render: (_text: string | undefined, record: any) => (
				<div style={{ whiteSpace: "nowrap" }}>
					<div className={styles.modalBtnContainer}>
						<>
							<div className={styles.modalContainer}>
								<Button
									style={{
										background: "transparent",
										border: "none",
										outline: "none",
										color: theme.black,
										boxShadow: "none",
										padding: 0,
										marginTop: 0,
									}}
									onClick={() => {
										setSelectedUserId(record.id);
										setRemoveUserModalVisibility(true);
									}}
									icon={<FontAwesomeIcon icon={solid("user-slash")} />}
									title={t("account.deleteSupervisor")}
								/>
								<ConfirmationModal
									modalText={
										t("account.deleteSupervisorMessage") +
										record.name +
										t("account.deleteSupervisorMessage2")
									}
									handleFunction={() => handleDelete(selectedUserId)}
									modalVisibility={removeUserModalVisibility}
									title=""
									changeModalVisibility={() =>
										setRemoveUserModalVisibility(false)
									}
									spinning={spinning}
								/>
							</div>
						</>
					</div>
				</div>
			),
		},
	];

	const onPageUpdate = useCallback(
		(page: number, pageSize: number) => {
			query.update({
				page: page.toString(),
				pageSize: pageSize.toString(),
			});
		},
		[query]
	);

	return (
		<div className="px-4 py-10 flex flex-col gap-4">
			<AddPracticeSupervisorModal companyId={props.companyId} />

			<div className="flex flex-col gap-10">
				<div className="flex flex-col md:flex-row justify-between items-center">
					<Typography.Title level={3} className="my-0">
						{supervisors?.totalCount} {t("filtering.results")}
					</Typography.Title>
					{!isLoading && (
						<Pagination
							defaultCurrent={supervisors?.page}
							defaultPageSize={supervisors?.pageSize}
							total={supervisors?.totalCount}
							onChange={onPageUpdate}
							locale={{ items_per_page: t("pagination") }}
						/>
					)}
				</div>

				<Table
					locale={locale}
					columns={columns}
					dataSource={supervisors?.data ?? []}
					pagination={false}
					scroll={{ x: "calc(700px + 50%)", y: 420 }}
					rowKey={(record) => record.id!}
				/>
			</div>
		</div>
	);
};

export default PracticeSupervisorTable;
