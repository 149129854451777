import { QuestionOutlined } from "@ant-design/icons";
import { Button, Select } from "antd";
import { useTranslation } from "react-i18next";
import { Routes } from "../utils/routes";

const langs = [
	{
		value: "ro",
		label: "RO",
	},
	{
		value: "en",
		label: "EN",
	},
];

const Navbar = () => {
	const { i18n, t } = useTranslation();

	const updateLang = (newLang: string) => {
		i18n.changeLanguage(newLang);
	};

	return (
		<div className="flex items-center gap-4">
			<Button
				type="primary"
				shape="circle"
				icon={<QuestionOutlined />}
				size="middle"
				className="ml-auto"
				title={t("footer.frequentQuestions")}
				href={Routes.FAQ}
			/>

			<Select
				options={langs}
				defaultValue={i18n.resolvedLanguage ?? langs[0].value}
				onChange={updateLang}
				className="w-[64px]"
			/>
		</div>
	);
};

export default Navbar;
