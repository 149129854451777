/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
	Question,
	QuestionFromJSON,
	QuestionFromJSONTyped,
	QuestionToJSON,
} from "./Question";

/**
 *
 * @export
 * @interface QuestionPrerequisite
 */
export interface QuestionPrerequisite {
	/**
	 *
	 * @type {string}
	 * @memberof QuestionPrerequisite
	 */
	id?: string;
	/**
	 *
	 * @type {Date}
	 * @memberof QuestionPrerequisite
	 */
	createdAt?: Date;
	/**
	 *
	 * @type {Date}
	 * @memberof QuestionPrerequisite
	 */
	updatedAt?: Date;
	/**
	 *
	 * @type {string}
	 * @memberof QuestionPrerequisite
	 */
	questionId?: string;
	/**
	 *
	 * @type {Question}
	 * @memberof QuestionPrerequisite
	 */
	question?: Question;
	/**
	 *
	 * @type {string}
	 * @memberof QuestionPrerequisite
	 */
	name?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof QuestionPrerequisite
	 */
	answer?: string | null;
}

export function QuestionPrerequisiteFromJSON(json: any): QuestionPrerequisite {
	return QuestionPrerequisiteFromJSONTyped(json, false);
}

export function QuestionPrerequisiteFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): QuestionPrerequisite {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		id: !exists(json, "id") ? undefined : json["id"],
		createdAt: !exists(json, "createdAt")
			? undefined
			: new Date(json["createdAt"]),
		updatedAt: !exists(json, "updatedAt")
			? undefined
			: new Date(json["updatedAt"]),
		questionId: !exists(json, "questionId") ? undefined : json["questionId"],
		question: !exists(json, "question")
			? undefined
			: QuestionFromJSON(json["question"]),
		name: !exists(json, "name") ? undefined : json["name"],
		answer: !exists(json, "answer") ? undefined : json["answer"],
	};
}

export function QuestionPrerequisiteToJSON(
	value?: QuestionPrerequisite | null
): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		id: value.id,
		createdAt:
			value.createdAt === undefined ? undefined : value.createdAt.toISOString(),
		updatedAt:
			value.updatedAt === undefined ? undefined : value.updatedAt.toISOString(),
		questionId: value.questionId,
		question: QuestionToJSON(value.question),
		name: value.name,
		answer: value.answer,
	};
}
