/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
	PracticalActivityDTO,
	PracticalActivityDTOFromJSON,
	PracticalActivityDTOFromJSONTyped,
	PracticalActivityDTOToJSON,
} from "./PracticalActivityDTO";
import {
	PracticeNotebookState,
	PracticeNotebookStateFromJSON,
	PracticeNotebookStateFromJSONTyped,
	PracticeNotebookStateToJSON,
} from "./PracticeNotebookState";

/**
 *
 * @export
 * @interface PracticeNotebookUpdateDTO
 */
export interface PracticeNotebookUpdateDTO {
	/**
	 *
	 * @type {string}
	 * @memberof PracticeNotebookUpdateDTO
	 */
	title?: string | null;
	/**
	 *
	 * @type {Array<PracticalActivityDTO>}
	 * @memberof PracticeNotebookUpdateDTO
	 */
	activities?: Array<PracticalActivityDTO> | null;
	/**
	 *
	 * @type {PracticeNotebookState}
	 * @memberof PracticeNotebookUpdateDTO
	 */
	state?: PracticeNotebookState;
}

export function PracticeNotebookUpdateDTOFromJSON(
	json: any
): PracticeNotebookUpdateDTO {
	return PracticeNotebookUpdateDTOFromJSONTyped(json, false);
}

export function PracticeNotebookUpdateDTOFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): PracticeNotebookUpdateDTO {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		title: !exists(json, "title") ? undefined : json["title"],
		activities: !exists(json, "activities")
			? undefined
			: json["activities"] === null
			? null
			: (json["activities"] as Array<any>).map(PracticalActivityDTOFromJSON),
		state: !exists(json, "state")
			? undefined
			: PracticeNotebookStateFromJSON(json["state"]),
	};
}

export function PracticeNotebookUpdateDTOToJSON(
	value?: PracticeNotebookUpdateDTO | null
): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		title: value.title,
		activities:
			value.activities === undefined
				? undefined
				: value.activities === null
				? null
				: (value.activities as Array<any>).map(PracticalActivityDTOToJSON),
		state: PracticeNotebookStateToJSON(value.state),
	};
}
