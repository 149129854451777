/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
	NewsletterEmailDTO,
	NewsletterEmailDTOFromJSON,
	NewsletterEmailDTOFromJSONTyped,
	NewsletterEmailDTOToJSON,
} from "./NewsletterEmailDTO";

/**
 *
 * @export
 * @interface NewsletterEmailDTOPagedResponse
 */
export interface NewsletterEmailDTOPagedResponse {
	/**
	 *
	 * @type {number}
	 * @memberof NewsletterEmailDTOPagedResponse
	 */
	page?: number;
	/**
	 *
	 * @type {number}
	 * @memberof NewsletterEmailDTOPagedResponse
	 */
	pageSize?: number;
	/**
	 *
	 * @type {number}
	 * @memberof NewsletterEmailDTOPagedResponse
	 */
	totalCount?: number;
	/**
	 *
	 * @type {Array<NewsletterEmailDTO>}
	 * @memberof NewsletterEmailDTOPagedResponse
	 */
	data?: Array<NewsletterEmailDTO> | null;
}

export function NewsletterEmailDTOPagedResponseFromJSON(
	json: any
): NewsletterEmailDTOPagedResponse {
	return NewsletterEmailDTOPagedResponseFromJSONTyped(json, false);
}

export function NewsletterEmailDTOPagedResponseFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): NewsletterEmailDTOPagedResponse {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		page: !exists(json, "page") ? undefined : json["page"],
		pageSize: !exists(json, "pageSize") ? undefined : json["pageSize"],
		totalCount: !exists(json, "totalCount") ? undefined : json["totalCount"],
		data: !exists(json, "data")
			? undefined
			: json["data"] === null
			? null
			: (json["data"] as Array<any>).map(NewsletterEmailDTOFromJSON),
	};
}

export function NewsletterEmailDTOPagedResponseToJSON(
	value?: NewsletterEmailDTOPagedResponse | null
): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		page: value.page,
		pageSize: value.pageSize,
		totalCount: value.totalCount,
		data:
			value.data === undefined
				? undefined
				: value.data === null
				? null
				: (value.data as Array<any>).map(NewsletterEmailDTOToJSON),
	};
}
