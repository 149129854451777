/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum EmailSendStrategy {
	RoleBased = "RoleBased",
	MultiUser = "MultiUser",
}

export function EmailSendStrategyFromJSON(json: any): EmailSendStrategy {
	return EmailSendStrategyFromJSONTyped(json, false);
}

export function EmailSendStrategyFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): EmailSendStrategy {
	return json as EmailSendStrategy;
}

export function EmailSendStrategyToJSON(value?: EmailSendStrategy | null): any {
	return value as any;
}
