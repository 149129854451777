import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../Components/Notifications/NotificationsUtils";
import { removeDiacritics } from "./dataUtils";
import {
	PageCallBack,
	PageResponse,
	downloadAll,
	downloadExcel,
} from "./downloadUtils";
import { CompanyFacultyAccordsDTO, CompanyUserReportDTO } from "../Api";
import {
	getAllCompaniesContactsReport,
	getCompaniesFacultyAccordsReport,
	getCompanyContactsReport,
} from "../Requests/report-requests";
import { ColumnType } from "./downloadReportUtils.types";

export function normalCallbackToPagedCallback<T>(
	callbackToProcess: () => Promise<Array<T>>
): PageCallBack<T> {
	const processed = async () => {
		const data = await callbackToProcess();

		return {
			data: data,
			page: 0,
			pageSize: data.length,
			totalCount: data.length,
		} as PageResponse<T>;
	};

	return processed;
}

export async function downloadReport<T>(
	fileName: string,
	reportColumns: ColumnType<T>[],
	getCall: PageCallBack<T>,
	translation: (text: string) => string
): Promise<void> {
	return downloadExcel(
		fileName,
		reportColumns.map((e) => {
			return { header: removeDiacritics(e.title), key: e.key.toString() };
		}),
		await downloadAll(
			getCall,
			(_) => {},
			() => {
				openNotification(
					translation("reports.errorTexts.downloadFailed"),
					translation("reports.errorTexts.downloadFailedMessage"),
					NOTIFICATION_TYPES.ERROR
				);
			}
		)
	);
}

export const downloadCompanyFacultiesAccordReport = async (
	fileName: string,
	translation: (text: string) => string
): Promise<void> => {
	const companyFacultiesAccordReportColumns: ColumnType<CompanyFacultyAccordsDTO>[] =
		[
			{
				title: translation("reports.companyName"),
				key: "companyName",
			},
			{
				title: translation("reports.companyCif"),
				key: "companyCif",
			},
			{
				title: translation("reports.facultiesList"),
				key: "facultiesWithValidAccordsCommaSeparated",
			},
		];

	return downloadReport<CompanyFacultyAccordsDTO>(
		fileName,
		companyFacultiesAccordReportColumns,
		(page: number, pageSize: number) =>
			normalCallbackToPagedCallback(getCompaniesFacultyAccordsReport)(
				page,
				pageSize
			),
		translation
	);
};

export const downloadAllCompaniesContactsReport = async (
	fileName: string,
	translation: (text: string) => string,
	searchTerm?: string,
	id?: string
): Promise<void> => {
	const companyContactsReportColumns: ColumnType<CompanyUserReportDTO>[] = [
		{
			title: translation("reports.name"),
			key: "name",
		},
		{
			title: translation("reports._function"),
			key: "_function",
		},
		{
			title: translation("reports.email"),
			key: "email",
		},
		{
			title: translation("reports.phone"),
			key: "phone",
		},
		{
			title: translation("reports.companyName"),
			key: "companyName",
		},
		{
			title: translation("reports.companyCif"),
			key: "companyCif",
		},
		{
			title: translation("reports.companyAddress"),
			key: "companyAddress",
		},
	];
	return downloadReport<CompanyUserReportDTO>(
		fileName,
		companyContactsReportColumns,
		(page: number, pageSize: number) =>
			normalCallbackToPagedCallback(getAllCompaniesContactsReport)(
				page,
				pageSize
			),
		translation
	);
};

export const downloadCompanyContactsReport = async (
	fileName: string,
	translation: (text: string) => string,
	searchTerm?: string,
	id?: string
): Promise<void> => {
	const companyContactsReportColumns: ColumnType<CompanyUserReportDTO>[] = [
		{
			title: translation("reports.name"),
			key: "name",
		},
		{
			title: translation("reports._function"),
			key: "_function",
		},
		{
			title: translation("reports.email"),
			key: "email",
		},
		{
			title: translation("reports.phone"),
			key: "phone",
		},
		{
			title: translation("reports.companyName"),
			key: "companyName",
		},
		{
			title: translation("reports.companyCif"),
			key: "companyCif",
		},
		{
			title: translation("reports.companyAddress"),
			key: "companyAddress",
		},
	];

	return downloadReport<CompanyUserReportDTO>(
		fileName,
		companyContactsReportColumns,
		(page: number, pageSize: number) =>
			getCompanyContactsReport(searchTerm, page, pageSize, id),
		translation
	);
};
