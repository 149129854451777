/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
	PoliJobsContactDetailsAddUpdateDTO,
	PoliJobsContactDetailsAddUpdateDTOFromJSON,
	PoliJobsContactDetailsAddUpdateDTOToJSON,
	PoliJobsContactDetailsDTO,
	PoliJobsContactDetailsDTOFromJSON,
	PoliJobsContactDetailsDTOToJSON,
} from "../models";

export interface ApiPoliJobsContactDetailsAddStudentIdPutRequest {
	studentId: string;
	poliJobsContactDetailsAddUpdateDTO?: PoliJobsContactDetailsAddUpdateDTO;
}

export interface ApiPoliJobsContactDetailsGetForStudentStudentIdGetRequest {
	studentId: string;
}

export interface ApiPoliJobsContactDetailsUpdateIdPutRequest {
	id: string;
	poliJobsContactDetailsAddUpdateDTO?: PoliJobsContactDetailsAddUpdateDTO;
}

/**
 *
 */
export class PoliJobsContactDetailsApi extends runtime.BaseAPI {
	/**
	 */
	async apiPoliJobsContactDetailsAddStudentIdPutRaw(
		requestParameters: ApiPoliJobsContactDetailsAddStudentIdPutRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<void>> {
		if (
			requestParameters.studentId === null ||
			requestParameters.studentId === undefined
		) {
			throw new runtime.RequiredError(
				"studentId",
				"Required parameter requestParameters.studentId was null or undefined when calling apiPoliJobsContactDetailsAddStudentIdPut."
			);
		}

		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		headerParameters["Content-Type"] = "application/json";

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/PoliJobsContactDetails/Add/{studentId}`.replace(
					`{${"studentId"}}`,
					encodeURIComponent(String(requestParameters.studentId))
				),
				method: "PUT",
				headers: headerParameters,
				query: queryParameters,
				body: PoliJobsContactDetailsAddUpdateDTOToJSON(
					requestParameters.poliJobsContactDetailsAddUpdateDTO
				),
			},
			initOverrides
		);

		return new runtime.VoidApiResponse(response);
	}

	/**
	 */
	async apiPoliJobsContactDetailsAddStudentIdPut(
		requestParameters: ApiPoliJobsContactDetailsAddStudentIdPutRequest,
		initOverrides?: RequestInit
	): Promise<void> {
		await this.apiPoliJobsContactDetailsAddStudentIdPutRaw(
			requestParameters,
			initOverrides
		);
	}

	/**
	 */
	async apiPoliJobsContactDetailsGetForStudentStudentIdGetRaw(
		requestParameters: ApiPoliJobsContactDetailsGetForStudentStudentIdGetRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<PoliJobsContactDetailsDTO>> {
		if (
			requestParameters.studentId === null ||
			requestParameters.studentId === undefined
		) {
			throw new runtime.RequiredError(
				"studentId",
				"Required parameter requestParameters.studentId was null or undefined when calling apiPoliJobsContactDetailsGetForStudentStudentIdGet."
			);
		}

		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/PoliJobsContactDetails/GetForStudent/{studentId}`.replace(
					`{${"studentId"}}`,
					encodeURIComponent(String(requestParameters.studentId))
				),
				method: "GET",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			PoliJobsContactDetailsDTOFromJSON(jsonValue)
		);
	}

	/**
	 */
	async apiPoliJobsContactDetailsGetForStudentStudentIdGet(
		requestParameters: ApiPoliJobsContactDetailsGetForStudentStudentIdGetRequest,
		initOverrides?: RequestInit
	): Promise<PoliJobsContactDetailsDTO> {
		const response =
			await this.apiPoliJobsContactDetailsGetForStudentStudentIdGetRaw(
				requestParameters,
				initOverrides
			);
		return await response.value();
	}

	/**
	 */
	async apiPoliJobsContactDetailsUpdateIdPutRaw(
		requestParameters: ApiPoliJobsContactDetailsUpdateIdPutRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<void>> {
		if (requestParameters.id === null || requestParameters.id === undefined) {
			throw new runtime.RequiredError(
				"id",
				"Required parameter requestParameters.id was null or undefined when calling apiPoliJobsContactDetailsUpdateIdPut."
			);
		}

		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		headerParameters["Content-Type"] = "application/json";

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/PoliJobsContactDetails/Update/{id}`.replace(
					`{${"id"}}`,
					encodeURIComponent(String(requestParameters.id))
				),
				method: "PUT",
				headers: headerParameters,
				query: queryParameters,
				body: PoliJobsContactDetailsAddUpdateDTOToJSON(
					requestParameters.poliJobsContactDetailsAddUpdateDTO
				),
			},
			initOverrides
		);

		return new runtime.VoidApiResponse(response);
	}

	/**
	 */
	async apiPoliJobsContactDetailsUpdateIdPut(
		requestParameters: ApiPoliJobsContactDetailsUpdateIdPutRequest,
		initOverrides?: RequestInit
	): Promise<void> {
		await this.apiPoliJobsContactDetailsUpdateIdPutRaw(
			requestParameters,
			initOverrides
		);
	}
}
