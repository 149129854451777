/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface ChangePasswordDTO
 */
export interface ChangePasswordDTO {
	/**
	 *
	 * @type {string}
	 * @memberof ChangePasswordDTO
	 */
	email?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof ChangePasswordDTO
	 */
	actualPassword?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof ChangePasswordDTO
	 */
	newPassword?: string | null;
}

export function ChangePasswordDTOFromJSON(json: any): ChangePasswordDTO {
	return ChangePasswordDTOFromJSONTyped(json, false);
}

export function ChangePasswordDTOFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): ChangePasswordDTO {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		email: !exists(json, "email") ? undefined : json["email"],
		actualPassword: !exists(json, "actualPassword")
			? undefined
			: json["actualPassword"],
		newPassword: !exists(json, "newPassword") ? undefined : json["newPassword"],
	};
}

export function ChangePasswordDTOToJSON(value?: ChangePasswordDTO | null): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		email: value.email,
		actualPassword: value.actualPassword,
		newPassword: value.newPassword,
	};
}
