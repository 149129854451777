/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
	PracticeInternshipPeriodDTO,
	PracticeInternshipPeriodDTOFromJSON,
	PracticeInternshipPeriodDTOFromJSONTyped,
	PracticeInternshipPeriodDTOToJSON,
} from "./PracticeInternshipPeriodDTO";

/**
 *
 * @export
 * @interface FacultyDTO
 */
export interface FacultyDTO {
	/**
	 *
	 * @type {string}
	 * @memberof FacultyDTO
	 */
	id?: string;
	/**
	 *
	 * @type {string}
	 * @memberof FacultyDTO
	 */
	readonly facultyNameRo?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof FacultyDTO
	 */
	readonly facultyNameEn?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof FacultyDTO
	 */
	readonly acronym?: string | null;
	/**
	 *
	 * @type {Array<PracticeInternshipPeriodDTO>}
	 * @memberof FacultyDTO
	 */
	readonly practiceInternshipPeriods?: Array<PracticeInternshipPeriodDTO> | null;
}

export function FacultyDTOFromJSON(json: any): FacultyDTO {
	return FacultyDTOFromJSONTyped(json, false);
}

export function FacultyDTOFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): FacultyDTO {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		id: !exists(json, "id") ? undefined : json["id"],
		facultyNameRo: !exists(json, "facultyNameRo")
			? undefined
			: json["facultyNameRo"],
		facultyNameEn: !exists(json, "facultyNameEn")
			? undefined
			: json["facultyNameEn"],
		acronym: !exists(json, "acronym") ? undefined : json["acronym"],
		practiceInternshipPeriods: !exists(json, "practiceInternshipPeriods")
			? undefined
			: json["practiceInternshipPeriods"] === null
			? null
			: (json["practiceInternshipPeriods"] as Array<any>).map(
					PracticeInternshipPeriodDTOFromJSON
			  ),
	};
}

export function FacultyDTOToJSON(value?: FacultyDTO | null): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		id: value.id,
	};
}
