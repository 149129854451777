/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
	PoliJobsProposalEnrollmentStatus,
	PoliJobsProposalEnrollmentStatusFromJSON,
	PoliJobsProposalEnrollmentStatusFromJSONTyped,
	PoliJobsProposalEnrollmentStatusToJSON,
} from "./PoliJobsProposalEnrollmentStatus";

/**
 *
 * @export
 * @interface PoliJobsProposalEnrollmentDTO
 */
export interface PoliJobsProposalEnrollmentDTO {
	/**
	 *
	 * @type {string}
	 * @memberof PoliJobsProposalEnrollmentDTO
	 */
	id?: string;
	/**
	 *
	 * @type {string}
	 * @memberof PoliJobsProposalEnrollmentDTO
	 */
	companyId?: string;
	/**
	 *
	 * @type {string}
	 * @memberof PoliJobsProposalEnrollmentDTO
	 */
	companyName?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof PoliJobsProposalEnrollmentDTO
	 */
	companyLogo?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof PoliJobsProposalEnrollmentDTO
	 */
	poliJobsProposalId?: string;
	/**
	 *
	 * @type {string}
	 * @memberof PoliJobsProposalEnrollmentDTO
	 */
	poliJobsProposalName?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof PoliJobsProposalEnrollmentDTO
	 */
	location?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof PoliJobsProposalEnrollmentDTO
	 */
	placeId?: string | null;
	/**
	 *
	 * @type {Date}
	 * @memberof PoliJobsProposalEnrollmentDTO
	 */
	availableFrom?: Date;
	/**
	 *
	 * @type {Date}
	 * @memberof PoliJobsProposalEnrollmentDTO
	 */
	availableTo?: Date;
	/**
	 *
	 * @type {number}
	 * @memberof PoliJobsProposalEnrollmentDTO
	 */
	numberOfPositions?: number;
	/**
	 *
	 * @type {number}
	 * @memberof PoliJobsProposalEnrollmentDTO
	 */
	availableNumberOfPositions?: number;
	/**
	 *
	 * @type {PoliJobsProposalEnrollmentStatus}
	 * @memberof PoliJobsProposalEnrollmentDTO
	 */
	enrollmentStatus?: PoliJobsProposalEnrollmentStatus;
}

export function PoliJobsProposalEnrollmentDTOFromJSON(
	json: any
): PoliJobsProposalEnrollmentDTO {
	return PoliJobsProposalEnrollmentDTOFromJSONTyped(json, false);
}

export function PoliJobsProposalEnrollmentDTOFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): PoliJobsProposalEnrollmentDTO {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		id: !exists(json, "id") ? undefined : json["id"],
		companyId: !exists(json, "companyId") ? undefined : json["companyId"],
		companyName: !exists(json, "companyName") ? undefined : json["companyName"],
		companyLogo: !exists(json, "companyLogo") ? undefined : json["companyLogo"],
		poliJobsProposalId: !exists(json, "poliJobsProposalId")
			? undefined
			: json["poliJobsProposalId"],
		poliJobsProposalName: !exists(json, "poliJobsProposalName")
			? undefined
			: json["poliJobsProposalName"],
		location: !exists(json, "location") ? undefined : json["location"],
		placeId: !exists(json, "placeId") ? undefined : json["placeId"],
		availableFrom: !exists(json, "availableFrom")
			? undefined
			: new Date(json["availableFrom"]),
		availableTo: !exists(json, "availableTo")
			? undefined
			: new Date(json["availableTo"]),
		numberOfPositions: !exists(json, "numberOfPositions")
			? undefined
			: json["numberOfPositions"],
		availableNumberOfPositions: !exists(json, "availableNumberOfPositions")
			? undefined
			: json["availableNumberOfPositions"],
		enrollmentStatus: !exists(json, "enrollmentStatus")
			? undefined
			: PoliJobsProposalEnrollmentStatusFromJSON(json["enrollmentStatus"]),
	};
}

export function PoliJobsProposalEnrollmentDTOToJSON(
	value?: PoliJobsProposalEnrollmentDTO | null
): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		id: value.id,
		companyId: value.companyId,
		companyName: value.companyName,
		companyLogo: value.companyLogo,
		poliJobsProposalId: value.poliJobsProposalId,
		poliJobsProposalName: value.poliJobsProposalName,
		location: value.location,
		placeId: value.placeId,
		availableFrom:
			value.availableFrom === undefined
				? undefined
				: value.availableFrom.toISOString(),
		availableTo:
			value.availableTo === undefined
				? undefined
				: value.availableTo.toISOString(),
		numberOfPositions: value.numberOfPositions,
		availableNumberOfPositions: value.availableNumberOfPositions,
		enrollmentStatus: PoliJobsProposalEnrollmentStatusToJSON(
			value.enrollmentStatus
		),
	};
}
