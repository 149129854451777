/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
	SkillEndorsementDTO,
	SkillEndorsementDTOFromJSON,
	SkillEndorsementDTOFromJSONTyped,
	SkillEndorsementDTOToJSON,
} from "./SkillEndorsementDTO";

/**
 *
 * @export
 * @interface UserSkillDTO
 */
export interface UserSkillDTO {
	/**
	 *
	 * @type {string}
	 * @memberof UserSkillDTO
	 */
	id?: string;
	/**
	 *
	 * @type {string}
	 * @memberof UserSkillDTO
	 */
	skill?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof UserSkillDTO
	 */
	studentId?: string;
	/**
	 *
	 * @type {Array<SkillEndorsementDTO>}
	 * @memberof UserSkillDTO
	 */
	skillEndorsements?: Array<SkillEndorsementDTO> | null;
}

export function UserSkillDTOFromJSON(json: any): UserSkillDTO {
	return UserSkillDTOFromJSONTyped(json, false);
}

export function UserSkillDTOFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): UserSkillDTO {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		id: !exists(json, "id") ? undefined : json["id"],
		skill: !exists(json, "skill") ? undefined : json["skill"],
		studentId: !exists(json, "studentId") ? undefined : json["studentId"],
		skillEndorsements: !exists(json, "skillEndorsements")
			? undefined
			: json["skillEndorsements"] === null
			? null
			: (json["skillEndorsements"] as Array<any>).map(
					SkillEndorsementDTOFromJSON
			  ),
	};
}

export function UserSkillDTOToJSON(value?: UserSkillDTO | null): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		id: value.id,
		skill: value.skill,
		studentId: value.studentId,
		skillEndorsements:
			value.skillEndorsements === undefined
				? undefined
				: value.skillEndorsements === null
				? null
				: (value.skillEndorsements as Array<any>).map(
						SkillEndorsementDTOToJSON
				  ),
	};
}
