/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
	FacultyDTO,
	FacultyDTOFromJSON,
	FacultyDTOFromJSONTyped,
	FacultyDTOToJSON,
} from "./FacultyDTO";
import {
	ProfessionalTitleEnum,
	ProfessionalTitleEnumFromJSON,
	ProfessionalTitleEnumFromJSONTyped,
	ProfessionalTitleEnumToJSON,
} from "./ProfessionalTitleEnum";
import { Role, RoleFromJSON, RoleFromJSONTyped, RoleToJSON } from "./Role";

/**
 *
 * @export
 * @interface ProfessorDTO
 */
export interface ProfessorDTO {
	/**
	 *
	 * @type {string}
	 * @memberof ProfessorDTO
	 */
	id?: string;
	/**
	 *
	 * @type {string}
	 * @memberof ProfessorDTO
	 */
	readonly name?: string | null;
	/**
	 *
	 * @type {number}
	 * @memberof ProfessorDTO
	 */
	readonly cursId?: number;
	/**
	 *
	 * @type {string}
	 * @memberof ProfessorDTO
	 */
	readonly email?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof ProfessorDTO
	 */
	readonly personalEmail?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof ProfessorDTO
	 */
	readonly description?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof ProfessorDTO
	 */
	readonly phone?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof ProfessorDTO
	 */
	readonly cvUrl?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof ProfessorDTO
	 */
	readonly linkedInUrl?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof ProfessorDTO
	 */
	readonly gitUrl?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof ProfessorDTO
	 */
	readonly siteUrl?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof ProfessorDTO
	 */
	readonly researchField?: string | null;
	/**
	 *
	 * @type {Array<string>}
	 * @memberof ProfessorDTO
	 */
	readonly areasOfInterest?: Array<string> | null;
	/**
	 *
	 * @type {string}
	 * @memberof ProfessorDTO
	 */
	lowResImage?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof ProfessorDTO
	 */
	fullResImage?: string | null;
	/**
	 *
	 * @type {Array<Role>}
	 * @memberof ProfessorDTO
	 */
	readonly roles?: Array<Role> | null;
	/**
	 *
	 * @type {ProfessionalTitleEnum}
	 * @memberof ProfessorDTO
	 */
	professionalTitle?: ProfessionalTitleEnum;
	/**
	 *
	 * @type {string}
	 * @memberof ProfessorDTO
	 */
	departmentId?: string;
	/**
	 *
	 * @type {string}
	 * @memberof ProfessorDTO
	 */
	readonly departmentNameRo?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof ProfessorDTO
	 */
	readonly departmentNameEn?: string | null;
	/**
	 *
	 * @type {FacultyDTO}
	 * @memberof ProfessorDTO
	 */
	faculty?: FacultyDTO;
	/**
	 *
	 * @type {boolean}
	 * @memberof ProfessorDTO
	 */
	readonly sendNotifications?: boolean;
}

export function ProfessorDTOFromJSON(json: any): ProfessorDTO {
	return ProfessorDTOFromJSONTyped(json, false);
}

export function ProfessorDTOFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): ProfessorDTO {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		id: !exists(json, "id") ? undefined : json["id"],
		name: !exists(json, "name") ? undefined : json["name"],
		cursId: !exists(json, "cursId") ? undefined : json["cursId"],
		email: !exists(json, "email") ? undefined : json["email"],
		personalEmail: !exists(json, "personalEmail")
			? undefined
			: json["personalEmail"],
		description: !exists(json, "description") ? undefined : json["description"],
		phone: !exists(json, "phone") ? undefined : json["phone"],
		cvUrl: !exists(json, "cvUrl") ? undefined : json["cvUrl"],
		linkedInUrl: !exists(json, "linkedInUrl") ? undefined : json["linkedInUrl"],
		gitUrl: !exists(json, "gitUrl") ? undefined : json["gitUrl"],
		siteUrl: !exists(json, "siteUrl") ? undefined : json["siteUrl"],
		researchField: !exists(json, "researchField")
			? undefined
			: json["researchField"],
		areasOfInterest: !exists(json, "areasOfInterest")
			? undefined
			: json["areasOfInterest"],
		lowResImage: !exists(json, "lowResImage") ? undefined : json["lowResImage"],
		fullResImage: !exists(json, "fullResImage")
			? undefined
			: json["fullResImage"],
		roles: !exists(json, "roles")
			? undefined
			: json["roles"] === null
			? null
			: (json["roles"] as Array<any>).map(RoleFromJSON),
		professionalTitle: !exists(json, "professionalTitle")
			? undefined
			: ProfessionalTitleEnumFromJSON(json["professionalTitle"]),
		departmentId: !exists(json, "departmentId")
			? undefined
			: json["departmentId"],
		departmentNameRo: !exists(json, "departmentNameRo")
			? undefined
			: json["departmentNameRo"],
		departmentNameEn: !exists(json, "departmentNameEn")
			? undefined
			: json["departmentNameEn"],
		faculty: !exists(json, "faculty")
			? undefined
			: FacultyDTOFromJSON(json["faculty"]),
		sendNotifications: !exists(json, "sendNotifications")
			? undefined
			: json["sendNotifications"],
	};
}

export function ProfessorDTOToJSON(value?: ProfessorDTO | null): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		id: value.id,
		lowResImage: value.lowResImage,
		fullResImage: value.fullResImage,
		professionalTitle: ProfessionalTitleEnumToJSON(value.professionalTitle),
		departmentId: value.departmentId,
		faculty: FacultyDTOToJSON(value.faculty),
	};
}
