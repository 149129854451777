import { useTranslation } from "react-i18next";
import { useCallback } from "react";
import { studentIdSet } from "../Student/StudentSlice";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, Pagination, Table, Typography } from "antd";
import {
	downloadStudentCv,
	getAllStudentsWhoAcceptGDPRV2,
	getStudentFiltersForNewCompany,
} from "../../Requests/academic-user-requests";
import Filters from "../Filters";
import useQueryFilters from "../../Hooks/useQueryFilters";
import { useQuery } from "react-query";
import {
	getStudents,
	getStudentsFilters,
} from "../../utils/reactQueriesConstants";
import { SimpleViewStudentDTO } from "../../Api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { brands, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { downloadFile } from "../../utils/downloadUtils";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../Notifications/NotificationsUtils";

const CompanyStudentsTable = () => {
	const { t, i18n } = useTranslation();
	const dispatch = useDispatch();
	const history = useHistory();
	const query = useQueryFilters({});

	const goToStudentProfile = (id: any) => {
		dispatch(studentIdSet(id));
		history.push("/student");
	};

	const { data, isLoading } = useQuery([getStudents, query.filters], () => {
		return getAllStudentsWhoAcceptGDPRV2(query.filters);
	});

	const { data: filters } = useQuery([getStudentsFilters], () => {
		return getStudentFiltersForNewCompany(i18n.language);
	});

	const downloadFail = (_err: any) => {
		openNotification(
			t("universityAccord.error"),
			t("universityAccord.downloadError"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const columns = [
		{
			title: t("account.name"),
			dataIndex: "name",
			fixed: "left" as "left",
			// sorter: (a: SimpleViewStudentDTO, b: SimpleViewStudentDTO) => {
			// 	if (!a.name || !b.name) return -1;
			// 	return a.name.localeCompare(b.name);
			// },
			render: (text: string | undefined, record: any) => (
				<a onClick={() => goToStudentProfile(record.id)}>
					<b>{text}</b>
				</a>
			),
		},
		{
			title: t("students.faculty"),
			dataIndex: "facultyNameRo",
			key: "facultyNameRo",
			// sorter: (a: SimpleViewStudentDTO, b: SimpleViewStudentDTO) => {
			// 	if (!a.facultyNameRo || !b.facultyNameRo) return -1;
			// 	return a.facultyNameRo.localeCompare(b.facultyNameRo);
			// },
		},
		{
			title: t("students.year"),
			dataIndex: "year",
			key: "year",
			// sorter: (a: SimpleViewStudentDTO, b: SimpleViewStudentDTO) => {
			// 	if (!a.year || !b.year) return -1;
			// 	return a.year.localeCompare(b.year);
			// },
		},
		// {
		//     title: t('students.cvUrl'),
		//     dataIndex: 'cvUrl',
		//     key: 'cvUrl',
		//     hidden: window.innerWidth < 650,
		//     sorter: (a: SimpleViewStudentDTO, b: SimpleViewStudentDTO) => {
		// 	       if (!a.cvUrl || !b.cvUrl) return -1;
		// 	       return a.cvUrl.localeCompare(b.cvUrl);
		//     },
		//     render: (text: string | undefined, record: any) => {
		// 		if (record.cvUrl) {
		// 			return <a href={text} target="_blank" rel="noreferrer"><FontAwesomeIcon icon={solid('download')} title={t('students.downloadCv')}/></a>
		// 		}

		// 		return <Button
		// 			title={"CV"}
		// 			style={{
		// 				backgroundColor: "transparent",
		// 				float: "left",
		// 				padding: "0",
		// 			}}
		// 			onClick={() =>
		// 				downloadFile(
		// 					downloadStudentCv(record.id!),
		// 					t("documents.Cv") +
		// 						"_" +
		// 						record.name
		// 				).catch(downloadFail)
		// 			}
		// 		>
		// 			<FontAwesomeIcon
		// 				icon={solid("file-lines")}
		// 				className="text-2xl text-white"
		// 			/>
		// 		</Button>
		// 	}
		// },
		// {
		//     title: <FontAwesomeIcon icon={brands('linkedin')} title={t('students.linkedinUrl')}/>,
		//     dataIndex: 'linkedinUrl',
		//     key: 'linkedinUrl',
		//     hidden: window.innerWidth < 650,
		//     sorter: (a: SimpleViewStudentDTO, b: SimpleViewStudentDTO) => {
		// 	       if (!a.linkedinUrl || !b.linkedinUrl) return -1;
		// 	       return a.linkedinUrl.localeCompare(b.linkedinUrl);
		//     },
		//     render: (text: string | undefined) => <a href={text} target="_blank" rel="noreferrer"><FontAwesomeIcon icon={solid('arrow-right')} title={t('students.goToLinkedinUrl')}/></a>
		// },
	];

	const onUpdate = useCallback(
		(values: { [key: string]: string[] }) => {
			query.update(values, true);
		},
		[query]
	);

	const onPageUpdate = useCallback(
		(page: number, pageSize: number) => {
			query.update({
				page: page.toString(),
				pageSize: pageSize.toString(),
			});
		},
		[query]
	);

	return (
		<div className="px-4 pb-10 flex flex-col">
			<Filters
				filters={filters}
				searchFields={[{ name: "search", label: t("search.byName") }]}
				onUpdate={onUpdate}
				hasSort={false}
			/>

			<div className="flex flex-col gap-10">
				<div className="flex flex-col md:flex-row justify-between items-center">
					<Typography.Title level={3} className="my-0">
						{data?.totalCount} {t("filtering.results")}
					</Typography.Title>
					{!isLoading && (
						<Pagination
							defaultCurrent={data?.page}
							defaultPageSize={data?.pageSize}
							total={data?.totalCount}
							onChange={onPageUpdate}
							locale={{ items_per_page: t("pagination") }}
						/>
					)}
				</div>

				<Table
					locale={{ emptyText: t("tableText.emptyTable") }}
					columns={columns}
					dataSource={data?.data ?? []}
					pagination={false}
					scroll={{ x: "calc(700px + 50%)", y: 420 }}
					rowKey={(record) => record.id!}
				/>
			</div>
		</div>
	);
};

export default CompanyStudentsTable;
