/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface FilterItem
 */
export interface FilterItem {
	/**
	 *
	 * @type {string}
	 * @memberof FilterItem
	 */
	title?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof FilterItem
	 */
	key?: string | null;
}

export function FilterItemFromJSON(json: any): FilterItem {
	return FilterItemFromJSONTyped(json, false);
}

export function FilterItemFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): FilterItem {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		title: !exists(json, "title") ? undefined : json["title"],
		key: !exists(json, "key") ? undefined : json["key"],
	};
}

export function FilterItemToJSON(value?: FilterItem | null): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		title: value.title,
		key: value.key,
	};
}
