/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface UniversityAccordReviewDTO
 */
export interface UniversityAccordReviewDTO {
	/**
	 *
	 * @type {string}
	 * @memberof UniversityAccordReviewDTO
	 */
	id?: string;
	/**
	 *
	 * @type {string}
	 * @memberof UniversityAccordReviewDTO
	 */
	universityAccordId?: string;
	/**
	 *
	 * @type {string}
	 * @memberof UniversityAccordReviewDTO
	 */
	message?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof UniversityAccordReviewDTO
	 */
	authorId?: string;
	/**
	 *
	 * @type {Date}
	 * @memberof UniversityAccordReviewDTO
	 */
	updatedAt?: Date;
	/**
	 *
	 * @type {boolean}
	 * @memberof UniversityAccordReviewDTO
	 */
	hasFile?: boolean;
}

export function UniversityAccordReviewDTOFromJSON(
	json: any
): UniversityAccordReviewDTO {
	return UniversityAccordReviewDTOFromJSONTyped(json, false);
}

export function UniversityAccordReviewDTOFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): UniversityAccordReviewDTO {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		id: !exists(json, "id") ? undefined : json["id"],
		universityAccordId: !exists(json, "universityAccordId")
			? undefined
			: json["universityAccordId"],
		message: !exists(json, "message") ? undefined : json["message"],
		authorId: !exists(json, "authorId") ? undefined : json["authorId"],
		updatedAt: !exists(json, "updatedAt")
			? undefined
			: new Date(json["updatedAt"]),
		hasFile: !exists(json, "hasFile") ? undefined : json["hasFile"],
	};
}

export function UniversityAccordReviewDTOToJSON(
	value?: UniversityAccordReviewDTO | null
): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		id: value.id,
		universityAccordId: value.universityAccordId,
		message: value.message,
		authorId: value.authorId,
		updatedAt:
			value.updatedAt === undefined ? undefined : value.updatedAt.toISOString(),
		hasFile: value.hasFile,
	};
}
