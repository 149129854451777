/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
	ProfessionalTitleEnum,
	ProfessionalTitleEnumFromJSON,
	ProfessionalTitleEnumFromJSONTyped,
	ProfessionalTitleEnumToJSON,
} from "./ProfessionalTitleEnum";

/**
 *
 * @export
 * @interface ProfessorProposalReportDTO
 */
export interface ProfessorProposalReportDTO {
	/**
	 *
	 * @type {string}
	 * @memberof ProfessorProposalReportDTO
	 */
	id?: string;
	/**
	 *
	 * @type {ProfessionalTitleEnum}
	 * @memberof ProfessorProposalReportDTO
	 */
	professionalTitle?: ProfessionalTitleEnum;
	/**
	 *
	 * @type {string}
	 * @memberof ProfessorProposalReportDTO
	 */
	name?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof ProfessorProposalReportDTO
	 */
	email?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof ProfessorProposalReportDTO
	 */
	phone?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof ProfessorProposalReportDTO
	 */
	department?: string | null;
	/**
	 *
	 * @type {number}
	 * @memberof ProfessorProposalReportDTO
	 */
	totalAvailablePositions?: number;
	/**
	 *
	 * @type {number}
	 * @memberof ProfessorProposalReportDTO
	 */
	totalAllocatedPositions?: number;
	/**
	 *
	 * @type {number}
	 * @memberof ProfessorProposalReportDTO
	 */
	totalAvailablePositionsAsCoTutor?: number;
	/**
	 *
	 * @type {number}
	 * @memberof ProfessorProposalReportDTO
	 */
	totalAllocatedPositionsAsCoTutor?: number;
}

export function ProfessorProposalReportDTOFromJSON(
	json: any
): ProfessorProposalReportDTO {
	return ProfessorProposalReportDTOFromJSONTyped(json, false);
}

export function ProfessorProposalReportDTOFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): ProfessorProposalReportDTO {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		id: !exists(json, "id") ? undefined : json["id"],
		professionalTitle: !exists(json, "professionalTitle")
			? undefined
			: ProfessionalTitleEnumFromJSON(json["professionalTitle"]),
		name: !exists(json, "name") ? undefined : json["name"],
		email: !exists(json, "email") ? undefined : json["email"],
		phone: !exists(json, "phone") ? undefined : json["phone"],
		department: !exists(json, "department") ? undefined : json["department"],
		totalAvailablePositions: !exists(json, "totalAvailablePositions")
			? undefined
			: json["totalAvailablePositions"],
		totalAllocatedPositions: !exists(json, "totalAllocatedPositions")
			? undefined
			: json["totalAllocatedPositions"],
		totalAvailablePositionsAsCoTutor: !exists(
			json,
			"totalAvailablePositionsAsCoTutor"
		)
			? undefined
			: json["totalAvailablePositionsAsCoTutor"],
		totalAllocatedPositionsAsCoTutor: !exists(
			json,
			"totalAllocatedPositionsAsCoTutor"
		)
			? undefined
			: json["totalAllocatedPositionsAsCoTutor"],
	};
}

export function ProfessorProposalReportDTOToJSON(
	value?: ProfessorProposalReportDTO | null
): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		id: value.id,
		professionalTitle: ProfessionalTitleEnumToJSON(value.professionalTitle),
		name: value.name,
		email: value.email,
		phone: value.phone,
		department: value.department,
		totalAvailablePositions: value.totalAvailablePositions,
		totalAllocatedPositions: value.totalAllocatedPositions,
		totalAvailablePositionsAsCoTutor: value.totalAvailablePositionsAsCoTutor,
		totalAllocatedPositionsAsCoTutor: value.totalAllocatedPositionsAsCoTutor,
	};
}
