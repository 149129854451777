/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
	Faculty,
	FacultyFromJSON,
	FacultyFromJSONTyped,
	FacultyToJSON,
} from "./Faculty";
import {
	Internship,
	InternshipFromJSON,
	InternshipFromJSONTyped,
	InternshipToJSON,
} from "./Internship";

/**
 *
 * @export
 * @interface InternshipFaculty
 */
export interface InternshipFaculty {
	/**
	 *
	 * @type {string}
	 * @memberof InternshipFaculty
	 */
	id?: string;
	/**
	 *
	 * @type {Date}
	 * @memberof InternshipFaculty
	 */
	createdAt?: Date;
	/**
	 *
	 * @type {Date}
	 * @memberof InternshipFaculty
	 */
	updatedAt?: Date;
	/**
	 *
	 * @type {string}
	 * @memberof InternshipFaculty
	 */
	internshipId?: string;
	/**
	 *
	 * @type {string}
	 * @memberof InternshipFaculty
	 */
	facultyId?: string;
	/**
	 *
	 * @type {Internship}
	 * @memberof InternshipFaculty
	 */
	internship?: Internship;
	/**
	 *
	 * @type {Faculty}
	 * @memberof InternshipFaculty
	 */
	faculty?: Faculty;
}

export function InternshipFacultyFromJSON(json: any): InternshipFaculty {
	return InternshipFacultyFromJSONTyped(json, false);
}

export function InternshipFacultyFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): InternshipFaculty {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		id: !exists(json, "id") ? undefined : json["id"],
		createdAt: !exists(json, "createdAt")
			? undefined
			: new Date(json["createdAt"]),
		updatedAt: !exists(json, "updatedAt")
			? undefined
			: new Date(json["updatedAt"]),
		internshipId: !exists(json, "internshipId")
			? undefined
			: json["internshipId"],
		facultyId: !exists(json, "facultyId") ? undefined : json["facultyId"],
		internship: !exists(json, "internship")
			? undefined
			: InternshipFromJSON(json["internship"]),
		faculty: !exists(json, "faculty")
			? undefined
			: FacultyFromJSON(json["faculty"]),
	};
}

export function InternshipFacultyToJSON(value?: InternshipFaculty | null): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		id: value.id,
		createdAt:
			value.createdAt === undefined ? undefined : value.createdAt.toISOString(),
		updatedAt:
			value.updatedAt === undefined ? undefined : value.updatedAt.toISOString(),
		internshipId: value.internshipId,
		facultyId: value.facultyId,
		internship: InternshipToJSON(value.internship),
		faculty: FacultyToJSON(value.faculty),
	};
}
