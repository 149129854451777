import { StudentStudyApi } from "../Api";
import { getAuthConfiguration } from "./configuration";

const StudentStudyApiFactory = () =>
	new StudentStudyApi(getAuthConfiguration());

export const getStudyProgramsForFaculties = (
	facultyIds: Array<string> | null
) => {
	return StudentStudyApiFactory().apiStudentStudyGetStudyProgramsGet({
		facultyIds: facultyIds && facultyIds?.length > 0 ? facultyIds : undefined,
	});
};
