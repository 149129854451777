import { Button, Tag } from "antd";
import { getUserId } from "../../../utils/utilFunctions";
import { InternsForEmployerDTO, InternshipEngagementType } from "../../../Api";
import { Breakpoint } from "antd/lib";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { theme } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { brands, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { updateIsEnrollmentViewed } from "../../../Requests/internship-requests";
import { QueryClient } from "react-query";
import {
	getInterns,
	getTraineesFilters,
} from "../../../utils/reactQueriesConstants";
import { ApplicantActions } from "./components/actions/ApplicantActions/ApplicantActions";
import { downloadFile } from "../../../utils/downloadUtils";
import { downloadStudentCv } from "../../../Requests/academic-user-requests";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../../Notifications/NotificationsUtils";

type ActionsProps = {
	setSelectedApplicant: (record: InternsForEmployerDTO) => void;
	setSelectedTutor: (tutor: string) => void;
	setAcceptModalVisibility: (value: boolean) => void;
	setFinalAcceptModalVisibility: (value: boolean) => void;
	setRejectModalVisibility: (value: boolean) => void;
	setRemoveModalVisibility: (value: boolean) => void;
	setWithdrawModalVisibility: (value: boolean) => void;
};

export function useColumns(
	queryClient: QueryClient,
	goToStudentProfile: any,
	props: ActionsProps
) {
	const { t } = useTranslation();
	const { token } = theme.useToken();
	const updateViewApplication = (id: any, viewed: boolean) => {
		updateIsEnrollmentViewed(id, viewed).then(async () => {
			await queryClient.invalidateQueries(getInterns);
			await queryClient.invalidateQueries(getTraineesFilters);
		});
	};

	const downloadFail = (_err: any) => {
		openNotification(
			t("universityAccord.error"),
			t("universityAccord.downloadError"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const columns = useMemo(() => {
		return [
			{
				title: t("students.applicationDate"),
				key: "applicationDate",
				dataIndex: "applicationDate",
			},
			{
				title: t("students.name"),
				dataIndex: "name",
				key: "name",
				// sorter: (a: InternsForEmployerDTO, b: InternsForEmployerDTO) => {
				// 	if (!a.name || !b.name) return -1;
				// 	return a.name.localeCompare(b.name);
				// },
				fixed: "left" as "left",
				render: (_text: string | undefined, record: InternsForEmployerDTO) => {
					return (
						<a
							onClick={() => {
								updateViewApplication(record.internshipEnrollmentId, true);
								goToStudentProfile(record.id);
							}}
						>
							<b>{record.name}</b>
						</a>
					);
				},
			},
			{
				title: t("internships.searchProposal"),
				dataIndex: "internshipName",
				key: "internshipName",
				// sorter: (a: InternsForEmployerDTO, b: InternsForEmployerDTO) => {
				// 	if (!a.internshipName || !b.internshipName) return -1;
				// 	return a.internshipName.localeCompare(b.internshipName);
				// },
				ellipsis: true,
			},
			{
				title: t("students.email"),
				dataIndex: "email",
				key: "email",
				// sorter: (a: InternsForEmployerDTO, b: InternsForEmployerDTO) => {
				// 	if (!a.email || !b.email) return -1;
				// 	return a.email.localeCompare(b.email);
				// },
				ellipsis: true,
			},
			{
				title: t("students.studyProgram"),
				dataIndex: "studyProgram",
				elipses: true,
				key: "studyProgram",
				// sorter: (a: InternsForEmployerDTO, b: InternsForEmployerDTO) => {
				// 	if (!a.studyProgram || !b.studyProgram) return -1;
				// 	return a.studyProgram.localeCompare(b.studyProgram);
				// },
				ellipsis: true,
				responsive: ["md"] as unknown as Breakpoint[],
			},
			{
				title: t("students.year"),
				dataIndex: "year",
				key: "year",
				render: (_text: string | undefined, record: InternsForEmployerDTO) => {
					return t("students.filters." + record.year);
				},
				// sorter: (a: InternsForEmployerDTO, b: InternsForEmployerDTO) => {
				// 	if (!a.year || !b.year) return -1;
				// 	return a.year.localeCompare(b.year);
				// },
				responsive: ["lg"] as unknown as Breakpoint[],
			},
			{
				title: t("internships.type"),
				dataIndex: "internshipEngagementType",
				key: "internshipEngagementType",
				render: (_text: string | undefined, record: InternsForEmployerDTO) => {
					const color = (type: InternshipEngagementType | undefined) => {
						if (type === InternshipEngagementType.BachelorsInternship)
							return token.colorPrimary;
						if (type === InternshipEngagementType.MastersInternship)
							return token.colorPrimaryActive;
						return token.colorTextBase;
					};

					return (
						<Tag color={color(record.internshipEngagementType)}>
							{t("internships.filters." + record.internshipEngagementType)}
						</Tag>
					);
				},
				// sorter: (a: InternsForEmployerDTO, b: InternsForEmployerDTO) => {
				// 	if (!a.internshipEngagementType || !b.internshipEngagementType)
				// 		return -1;
				// 	return a.internshipEngagementType.localeCompare(
				// 		b.internshipEngagementType
				// 	);
				// },
				responsive: ["lg"] as unknown as Breakpoint[],
			},
			{
				title: t("internships.employer"),
				dataIndex: "internshipEmployer",
				key: "internshipEmployer",
				render: (_text: string | undefined, record: InternsForEmployerDTO) => {
					return (
						<div className="overflow-ellipsis overflow-hidden line-clamp-1">
							{record.internshipEmployerId !== getUserId()
								? record.internshipEmployerName
								: t("internships.you")}
						</div>
					);
				},
				responsive: ["xl"] as unknown as Breakpoint[],
			},
			{
				title: t("students.cvUrl"),
				dataIndex: "cvUrl",
				key: "cvUrl",
				responsive: ["md"] as unknown as Breakpoint[],
				render: (_text: string | undefined, record: InternsForEmployerDTO) => {
					if ((!record.cvUrl || record.cvUrl === "") && !record.cvFileName) {
						return (
							<FontAwesomeIcon
								icon={solid("download")}
								style={{ fontSize: "15px", opacity: 0.2 }}
								title={t("students.cvNotSet")}
							/>
						);
					}

					if (record.cvUrl) {
						<a
							href={record.cvUrl}
							onClick={() =>
								updateViewApplication(record.internshipEnrollmentId, true)
							}
						>
							<FontAwesomeIcon
								icon={solid("download")}
								style={{ fontSize: "15px" }}
								title={t("students.downloadCv")}
							/>
						</a>;
					} else {
						return (
							<Button
								title={"CV"}
								style={{
									backgroundColor: "transparent",
									float: "left",
									padding: "0",
								}}
								onClick={() =>
									downloadFile(
										downloadStudentCv(record.id!),
										t("documents.Cv") + "_" + record.name
									).catch(downloadFail)
								}
							>
								<FontAwesomeIcon
									icon={solid("file-lines")}
									className="text-2xl text-white"
								/>
							</Button>
						);
					}
				},
			},
			{
				title: (
					<FontAwesomeIcon
						icon={brands("linkedin")}
						style={{ fontSize: "15px" }}
					/>
				),
				dataIndex: "linkedinUrl",
				key: "linkedinUrl",
				responsive: ["md"] as unknown as Breakpoint[],
				render: (
					linkedinUrl: string | undefined,
					record: InternsForEmployerDTO
				) => {
					if (!linkedinUrl || linkedinUrl === "") {
						return (
							<FontAwesomeIcon
								icon={solid("arrow-right")}
								style={{ fontSize: "15px", opacity: 0.2 }}
								title={t("students.linkedinUrlNotSet")}
							/>
						);
					}

					return (
						<a
							href={linkedinUrl}
							onClick={() =>
								updateViewApplication(record.internshipEnrollmentId, true)
							}
						>
							<FontAwesomeIcon
								icon={solid("arrow-right")}
								style={{ fontSize: "15px" }}
								title={t("students.goToLinkedinUrl")}
							/>
						</a>
					);
				},
			},
			{
				title: t("students.actions"),
				dataIndex: "actions",
				key: "actions",
				fixed: "right" as "right",
				responsive: ["sm"] as unknown as Breakpoint[],
				render: (text: string | undefined, record: InternsForEmployerDTO) => {
					return (
						<ApplicantActions
							record={record}
							setSelectedApplicant={props.setSelectedApplicant}
							setSelectedTutor={props.setSelectedTutor}
							setAcceptModalVisibility={props.setAcceptModalVisibility}
							setFinalAcceptModalVisibility={
								props.setFinalAcceptModalVisibility
							}
							setRejectModalVisibility={props.setRejectModalVisibility}
							setRemoveModalVisibility={props.setRemoveModalVisibility}
							setWithdrawModalVisibility={props.setWithdrawModalVisibility}
						/>
					);
				},
			},
		];
	}, [t, token.colorPrimary, token.colorPrimaryActive, token.colorTextBase]);

	return columns;
}
