import {
	CompanyInternshipAddUpdateDTO,
	InternshipDTO,
	InternshipEngagementType,
	ProfessorInternshipAddUpdateDTO,
	StudentYearEnum,
} from "../../../../Api";
import { AddInternshipFormModel } from "../models/AddInternshipFormModel";
import moment from "moment/moment";

const dateFormatList = "YYYY-MM-DD";

export const toFormModel = (
	record: InternshipDTO
): Partial<AddInternshipFormModel> => ({
	company: record.recruiterId,
	internshipName: record.internshipName,
	companyDepartment: record.recruiterDepartment,
	description: record.description,
	shortDescription: record.shortDescription,
	internshipLink: record.internshipLink,
	category: record.category,
	engagementType: record.engagementType,
	initialAvailablePositions: record.initialAvailablePositions,
	period: record.period,
	totalPracticeHours: record.totalPracticeHours,
	skills: record.skillsRequired,
	startingDate: moment(record.startingDate),
	isPaid: record.isPaid,
	isViewOnly: record.isViewOnly,
	availabilityAd: [
		moment(record.availableFrom, dateFormatList),
		moment(record.availableTo, dateFormatList),
	],
	years: record.years,
	tutor: record.tutorId,
	coordinator: record.facultyCoordinatorName,
	coTutorIds: record.coTutorIds,
	coordinatingProfessor: record.professorId,
	internshipCompetences: record.internshipCompetences?.sort(
		(a, b) => a.index! - b.index!
	),
});

export const toProfessorInternshipAsDepartmentSecretary = (
	model: AddInternshipFormModel,
	selectedFaculties: string[],
	location: string,
	placeId: string,
	years: StudentYearEnum[] | undefined
): ProfessorInternshipAddUpdateDTO => ({
	name: model.internshipName,
	description: model.description,
	shortDescription: model.shortDescription ? model.shortDescription : "",
	internshipLink: model.internshipLink ? model.internshipLink : "",
	period: model.period,
	availableFrom:
		model.engagementType === InternshipEngagementType.BachelorsInternship ||
		model.engagementType === InternshipEngagementType.MastersInternship
			? new Date(model.availabilityYear, 9, 1)
			: model.availabilityAd[0].toDate(),
	availableTo:
		model.engagementType === InternshipEngagementType.BachelorsInternship ||
		model.engagementType === InternshipEngagementType.MastersInternship
			? new Date(model.availabilityYear + 1, 9, 1)
			: model.availabilityAd[1].toDate(),
	startingDate:
		model.engagementType === InternshipEngagementType.BachelorsInternship ||
		model.engagementType === InternshipEngagementType.MastersInternship
			? new Date(model.availabilityYear, 9, 1)
			: model.startingDate?.toDate(),
	initialAvailablePositions: model.initialAvailablePositions,
	skillsRequired: model.skills ? model.skills : [],
	category: model.category ? model.category : "",
	engagementType: model.engagementType,
	totalPracticeHours: model.totalPracticeHours,
	departments: model.departments,
	studyPrograms: model.studyPrograms,
	faculties: selectedFaculties,
	years,
	location,
	placeId,
	coTutorIds: model.coTutorIds,
	coordinatorId: model.coordinatingProfessor!,
	isViewOnly: model.isViewOnly,
});

export const toProfessorInternshipAsProfessor = (
	model: AddInternshipFormModel,
	selectedFaculties: string[],
	location: string,
	placeId: string,
	years: StudentYearEnum[] | undefined
): ProfessorInternshipAddUpdateDTO => ({
	name: model.internshipName,
	description: model.description,
	shortDescription: model.shortDescription ? model.shortDescription : "",
	internshipLink: model.internshipLink ? model.internshipLink : "",
	period: model.period,
	availableFrom:
		model.engagementType === InternshipEngagementType.BachelorsInternship ||
		model.engagementType === InternshipEngagementType.MastersInternship
			? new Date(model.availabilityYear, 9, 1)
			: model.availabilityAd[0].toDate(),
	availableTo:
		model.engagementType === InternshipEngagementType.BachelorsInternship ||
		model.engagementType === InternshipEngagementType.MastersInternship
			? new Date(model.availabilityYear + 1, 9, 1)
			: model.availabilityAd[1].toDate(),
	startingDate:
		model.engagementType === InternshipEngagementType.BachelorsInternship ||
		model.engagementType === InternshipEngagementType.MastersInternship
			? new Date(model.availabilityYear, 9, 1)
			: model.startingDate?.toDate(),
	initialAvailablePositions: model.initialAvailablePositions,
	skillsRequired: model.skills ? model.skills : [],
	category: model.category ? model.category : "",
	engagementType: model.engagementType,
	totalPracticeHours: model.totalPracticeHours,
	departments: model.departments,
	studyPrograms: model.studyPrograms,
	faculties: selectedFaculties,
	years,
	location,
	placeId,
	coTutorIds: model.coTutorIds,
	isViewOnly: model.isViewOnly,
});

export const toCompanyInternship = (
	model: AddInternshipFormModel,
	selectedFaculties: string[],
	location: string,
	placeId: string,
	facultyCoordinatorId: string,
	years: StudentYearEnum[] | undefined
): CompanyInternshipAddUpdateDTO => ({
	name: model.internshipName,
	companyDepartment: model.companyDepartment,
	description: model.description,
	shortDescription: model.shortDescription ? model.shortDescription : "",
	internshipLink: model.internshipLink ? model.internshipLink : "",
	period: model.period,
	availableFrom: model.availabilityAd[0].toDate(),
	availableTo: model.availabilityAd[1].toDate(),
	startingDate: model.startingDate?.toDate(),
	initialAvailablePositions: model.initialAvailablePositions,
	isPaid: model.isPaid,
	skillsRequired: model.skills ? model.skills : [],
	category: model.category ? model.category : "",
	engagementType: model.engagementType,
	totalPracticeHours: model.totalPracticeHours,
	faculties: selectedFaculties,
	departments: model.departments,
	studyPrograms: model.studyPrograms,
	years,
	location,
	placeId,
	tutorId: model.tutor,
	internshipCompetences: model.internshipCompetences?.map((x, i) => {
		return { ...x, index: i + 1 };
	}),
	facultyCoordinatorId: facultyCoordinatorId,
	companyId: model.company,
});
