import {
	PracticeCertificateAddDTO,
	PracticeCertificateUpdateDTO,
	PracticeCertificateApi,
} from "../Api";
import { getAuthConfiguration } from "./configuration";

const AuthPracticeCertificateApiFactory = () =>
	new PracticeCertificateApi(getAuthConfiguration());

export const getPracticeCertificateForInternshipEnrollment = (
	internshipEnrollmentId: string
) => {
	return AuthPracticeCertificateApiFactory().apiPracticeCertificateGetForEnrollmentInternshipEnrollmentIdGet(
		{ internshipEnrollmentId }
	);
};

export const addPracticeCertificate = async (
	practiceCertificateAddDTO: PracticeCertificateAddDTO
) => {
	await AuthPracticeCertificateApiFactory().apiPracticeCertificateAddPost({
		practiceCertificateAddDTO,
	});
};

export const updatePracticeCertificate = async (
	id: string,
	practiceCertificateUpdateDTO: PracticeCertificateUpdateDTO
) => {
	await AuthPracticeCertificateApiFactory().apiPracticeCertificateUpdateIdPut({
		id,
		practiceCertificateUpdateDTO,
	});
};

export const generatePracticeCertificate = (id: string) => {
	return AuthPracticeCertificateApiFactory().apiPracticeCertificateGenerateCertificateIdGet(
		{ id }
	);
};

export const approvePracticeCertificate = async (id: string) => {
	await AuthPracticeCertificateApiFactory().apiPracticeCertificateApproveCertificateIdPut(
		{ id }
	);
};

export const rejectPracticeCertificate = async (
	id: string,
	comment: string
) => {
	await AuthPracticeCertificateApiFactory().apiPracticeCertificateRejectCertificateIdPut(
		{ id, comment }
	);
};

export const downloadPracticeCertificate = (signatureId: string) => {
	return AuthPracticeCertificateApiFactory().apiPracticeCertificateDownloadFileSignatureIdGet(
		{ signatureId }
	);
};

export const uploadSignedPracticeCertificate = async (
	certificateId: string,
	file: Blob
) => {
	await AuthPracticeCertificateApiFactory().apiPracticeCertificateUploadSignedCertificateIdPost(
		{ certificateId, file }
	);
};

export const uploadFinalizedPracticeCertificate = async (
	internshipEnrollmentId: string,
	file: Blob
) => {
	await AuthPracticeCertificateApiFactory().apiPracticeCertificateUploadFinalizedInternshipEnrollmentIdPost(
		{ internshipEnrollmentId, file }
	);
};
