import { Table, Switch, Button } from "antd";
import { useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { FeatureFlags, FeatureFlagsDTO, FeatureFlagsEnum } from "../../Api";
import {
	getFeatureFlagsAdmin,
	updateFeatureFlagValue,
} from "../../Requests/feedback-feature-flags-requests";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../Notifications/NotificationsUtils";
import styles from "./Feedback.module.scss";
import { t } from "i18next";
import { theme } from "../../theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { UpdateFacultyFlagsModal } from "./components/modals/UpdateFacultyFlagsModal/UpdateFacultyFlagsModal";
import { useIsAdmin } from "../../utils/utilFunctions";

const FeatureFlagsView = () => {
	const isAdmin = useIsAdmin();
	const [isUpdateLoading, setIsUpdateLoading] = useState(false);
	const [
		updateFacultyFlagsModalVisibility,
		setUpdateFacultyFlagsModalVisibility,
	] = useState(false);
	const [selectedFeatureFlag, setSelectedFeatureFlag] =
		useState<FeatureFlagsDTO | null>(null);
	const queryClient = useQueryClient();

	const invalidateQuery = () => {
		queryClient.invalidateQueries("GetAllFeatureFlagsAdmin");
	};

	const openErrorNotification = (_error: any) => {
		openNotification(
			"Eroare!",
			"Datele nu au putut fi aduse cu succes!",
			NOTIFICATION_TYPES.ERROR
		);
	};

	const { data } = useQuery(
		["GetAllFeatureFlagsAdmin", isAdmin],
		() => {
			return isAdmin ? getFeatureFlagsAdmin() : null;
		},
		{
			onError: openErrorNotification,
		}
	);

	const toggleFeatureFlag = async (
		flagName: FeatureFlagsEnum,
		currentValue: boolean
	) => {
		setIsUpdateLoading(true);
		await updateFeatureFlagValue(flagName, !currentValue).then(() => {
			invalidateQuery();
			setIsUpdateLoading(false);
		});
	};

	const columns = [
		{
			title: "Nume flag",
			dataIndex: "featureFlag",
			key: "featureFlag",
			fixed: "left" as "left",
		},
		{
			title: "Descriere",
			dataIndex: "description",
			key: "description",
		},
		{
			title: "Activ",
			dataIndex: "value",
			fixed: "right" as "right",
			render: (_: any, record: FeatureFlags) => {
				return (
					<span>
						<Switch
							loading={isUpdateLoading}
							checked={record.value!}
							onChange={() => {
								toggleFeatureFlag(record.featureFlag!, record.value!);
							}}
						/>
					</span>
				);
			},
		},
		{
			title: "Actiuni",
			dataIndex: "actions",
			fixed: "right" as "right",
			render: (_: any, record: FeatureFlags) => {
				if (record.value) {
					return (
						<div style={{ whiteSpace: "nowrap" }}>
							<div className={styles.modalBtnContainer}>
								<>
									<div className={styles.modalContainer}>
										<Button
											style={{
												background: "transparent",
												border: "none",
												outline: "none",
												color: theme.black,
												boxShadow: "none",
												padding: 0,
												marginTop: 0,
											}}
											onClick={() => {
												setSelectedFeatureFlag(record);
												setUpdateFacultyFlagsModalVisibility(true);
											}}
											icon={<FontAwesomeIcon icon={solid("edit")} />}
											title={"Editeaza flag-urile facultatilor"}
										/>
									</div>
								</>
							</div>
						</div>
					);
				}
			},
		},
	];

	const handleUpdateFacultyFlagsModalClose = () => {
		setUpdateFacultyFlagsModalVisibility(false);
		setSelectedFeatureFlag(null);
	};

	return (
		<div className="flex flex-col w-full h-full px-10 py-10">
			<Table
				locale={{ emptyText: t("tableText.emptyTable") }}
				columns={columns}
				dataSource={data ?? []}
				pagination={false}
				scroll={{ x: "calc(700px + 50%)", y: 680 }}
				rowKey={(record) => record.id!}
			/>
			{selectedFeatureFlag && (
				<UpdateFacultyFlagsModal
					featureFlag={selectedFeatureFlag}
					isModalVisible={updateFacultyFlagsModalVisibility}
					handleClose={handleUpdateFacultyFlagsModalClose}
					invalidateQuery={invalidateQuery}
				/>
			)}
		</div>
	);
};

export default FeatureFlagsView;
