import { PublishEmailForm } from "./components/forms/PublishEmailForm/PublishEmailForm";
import { useAddEmailFormController } from "./AddEmailForm.controller";
import { AddEmailFormProps } from "./AddEmailForm.types";
import { useEffect } from "react";
import { Select } from "antd";
import { useTranslation } from "react-i18next";

const { Option } = Select;

export const AddEmailForm = (props: AddEmailFormProps) => {
	const { i18n } = useTranslation();
	const { state, actions, computed } = useAddEmailFormController(props);

	useEffect(() => {
		actions.setRecipientsOptions(
			state.academicUsers
				?.map((user) => (
					<Option key={user.id} value={user.id}>
						{user.name}
					</Option>
				))
				.concat(
					computed.email?.recipients
						?.filter(
							(recipient) =>
								!state.academicUsers
									?.map((user) => user.id)
									.includes(recipient.recipientId!)
						)
						.map((recipient) => (
							<Option
								key={recipient.recipientId!}
								value={recipient.recipientId!}
							>
								{recipient.recipientName!}
							</Option>
						)) ?? []
				)
		);
	}, [state.academicUsers]);

	useEffect(() => {
		actions.setFacultyOptions(
			state.facultiesList
				?.filter((faculty: any) => {
					return (
						state.facultySearch === "" ||
						faculty.nameRo
							?.toLowerCase()
							.includes(state.facultySearch.toLowerCase()) ||
						faculty.nameEn
							?.toLowerCase()
							.includes(state.facultySearch.toLowerCase())
					);
				})
				.filter((faculty: any) => faculty.nameRo !== "Altele")
				.map((faculty: any) => (
					<Option key={faculty.id} value={faculty.id}>
						{i18n.language === "en" ? faculty.nameEn : faculty.nameRo}
					</Option>
				))
		);
	}, [i18n.language, state.facultySearch, state.facultiesList]);

	useEffect(() => {
		actions.setDepartmentOptions(
			state.departmentsList
				?.filter((department: any) => {
					return (
						state.departmentSearch === "" ||
						department.departmentNameRo
							?.toLowerCase()
							.includes(state.departmentSearch.toLowerCase()) ||
						department.departmentNameEn
							?.toLowerCase()
							.includes(state.departmentSearch.toLowerCase())
					);
				})
				.map((department: any) => (
					<Option key={department.id} value={department.id}>
						{i18n.language === "en"
							? department.departmentNameEn
							: department.departmentNameRo}
					</Option>
				))
		);
	}, [i18n.language, state.departmentSearch, state.departmentsList]);

	useEffect(() => {
		actions.setStudyProgramOptions(
			state.studyProgramsList
				?.filter((program: any) => {
					return (
						state.studyProgramSearch === "" ||
						program.name
							?.toLowerCase()
							.includes(state.studyProgramSearch.toLowerCase())
					);
				})
				.map((program: any) => (
					<Option key={"p" + program.id} value={program.id}>
						{program.name}
					</Option>
				))
		);
	}, [state.studyProgramSearch, state.studyProgramsList]);

	return <PublishEmailForm {...state} {...actions} {...computed} />;
};
