/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface QuestionPrerequisiteDTO
 */
export interface QuestionPrerequisiteDTO {
	/**
	 *
	 * @type {string}
	 * @memberof QuestionPrerequisiteDTO
	 */
	name?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof QuestionPrerequisiteDTO
	 */
	answer?: string | null;
}

export function QuestionPrerequisiteDTOFromJSON(
	json: any
): QuestionPrerequisiteDTO {
	return QuestionPrerequisiteDTOFromJSONTyped(json, false);
}

export function QuestionPrerequisiteDTOFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): QuestionPrerequisiteDTO {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		name: !exists(json, "name") ? undefined : json["name"],
		answer: !exists(json, "answer") ? undefined : json["answer"],
	};
}

export function QuestionPrerequisiteDTOToJSON(
	value?: QuestionPrerequisiteDTO | null
): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		name: value.name,
		answer: value.answer,
	};
}
