import { DepartmentApi } from "../Api";
import { getAuthConfiguration } from "./configuration";

const AuthFacultyApiFactory = () => new DepartmentApi(getAuthConfiguration());

export const getDepartmentsForFaculty = () => {
	return AuthFacultyApiFactory().apiDepartmentGetAllForFacultyGet();
};

export const getDepartmentsForFaculties = (faculties?: Array<string>) => {
	return AuthFacultyApiFactory().apiDepartmentGetAllForFacultiesGet({
		faculties,
	});
};
