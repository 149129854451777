/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
	AcademicUser,
	AcademicUserFromJSON,
	AcademicUserFromJSONTyped,
	AcademicUserToJSON,
} from "./AcademicUser";
import {
	StudentYearEnum,
	StudentYearEnumFromJSON,
	StudentYearEnumFromJSONTyped,
	StudentYearEnumToJSON,
} from "./StudentYearEnum";
import {
	StudyProgram,
	StudyProgramFromJSON,
	StudyProgramFromJSONTyped,
	StudyProgramToJSON,
} from "./StudyProgram";

/**
 *
 * @export
 * @interface StudentStudyProgram
 */
export interface StudentStudyProgram {
	/**
	 *
	 * @type {string}
	 * @memberof StudentStudyProgram
	 */
	id?: string;
	/**
	 *
	 * @type {Date}
	 * @memberof StudentStudyProgram
	 */
	createdAt?: Date;
	/**
	 *
	 * @type {Date}
	 * @memberof StudentStudyProgram
	 */
	updatedAt?: Date;
	/**
	 *
	 * @type {string}
	 * @memberof StudentStudyProgram
	 */
	studentId?: string;
	/**
	 *
	 * @type {string}
	 * @memberof StudentStudyProgram
	 */
	studyProgramId?: string;
	/**
	 *
	 * @type {number}
	 * @memberof StudentStudyProgram
	 */
	universityYear?: number;
	/**
	 *
	 * @type {StudentYearEnum}
	 * @memberof StudentStudyProgram
	 */
	studentYear?: StudentYearEnum;
	/**
	 *
	 * @type {AcademicUser}
	 * @memberof StudentStudyProgram
	 */
	student?: AcademicUser;
	/**
	 *
	 * @type {StudyProgram}
	 * @memberof StudentStudyProgram
	 */
	studyProgram?: StudyProgram;
}

export function StudentStudyProgramFromJSON(json: any): StudentStudyProgram {
	return StudentStudyProgramFromJSONTyped(json, false);
}

export function StudentStudyProgramFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): StudentStudyProgram {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		id: !exists(json, "id") ? undefined : json["id"],
		createdAt: !exists(json, "createdAt")
			? undefined
			: new Date(json["createdAt"]),
		updatedAt: !exists(json, "updatedAt")
			? undefined
			: new Date(json["updatedAt"]),
		studentId: !exists(json, "studentId") ? undefined : json["studentId"],
		studyProgramId: !exists(json, "studyProgramId")
			? undefined
			: json["studyProgramId"],
		universityYear: !exists(json, "universityYear")
			? undefined
			: json["universityYear"],
		studentYear: !exists(json, "studentYear")
			? undefined
			: StudentYearEnumFromJSON(json["studentYear"]),
		student: !exists(json, "student")
			? undefined
			: AcademicUserFromJSON(json["student"]),
		studyProgram: !exists(json, "studyProgram")
			? undefined
			: StudyProgramFromJSON(json["studyProgram"]),
	};
}

export function StudentStudyProgramToJSON(
	value?: StudentStudyProgram | null
): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		id: value.id,
		createdAt:
			value.createdAt === undefined ? undefined : value.createdAt.toISOString(),
		updatedAt:
			value.updatedAt === undefined ? undefined : value.updatedAt.toISOString(),
		studentId: value.studentId,
		studyProgramId: value.studyProgramId,
		universityYear: value.universityYear,
		studentYear: StudentYearEnumToJSON(value.studentYear),
		student: AcademicUserToJSON(value.student),
		studyProgram: StudyProgramToJSON(value.studyProgram),
	};
}
