/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
	CompanyDocumentStatusEnum,
	CompanyDocumentStatusEnumFromJSON,
	CompanyDocumentStatusEnumFromJSONTyped,
	CompanyDocumentStatusEnumToJSON,
} from "./CompanyDocumentStatusEnum";
import {
	DocumentTypeEnum,
	DocumentTypeEnumFromJSON,
	DocumentTypeEnumFromJSONTyped,
	DocumentTypeEnumToJSON,
} from "./DocumentTypeEnum";

/**
 *
 * @export
 * @interface CompanyDocumentDTO
 */
export interface CompanyDocumentDTO {
	/**
	 *
	 * @type {string}
	 * @memberof CompanyDocumentDTO
	 */
	id?: string;
	/**
	 *
	 * @type {DocumentTypeEnum}
	 * @memberof CompanyDocumentDTO
	 */
	documentType?: DocumentTypeEnum;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyDocumentDTO
	 */
	companyId?: string;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyDocumentDTO
	 */
	companyName?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyDocumentDTO
	 */
	facultyId?: string;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyDocumentDTO
	 */
	facultyNameRo?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyDocumentDTO
	 */
	facultyNameEn?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyDocumentDTO
	 */
	documentOwner?: string;
	/**
	 *
	 * @type {boolean}
	 * @memberof CompanyDocumentDTO
	 */
	skipOfferValidation?: boolean;
	/**
	 *
	 * @type {Date}
	 * @memberof CompanyDocumentDTO
	 */
	generationDate?: Date;
	/**
	 *
	 * @type {Date}
	 * @memberof CompanyDocumentDTO
	 */
	updatedAt?: Date;
	/**
	 *
	 * @type {Date}
	 * @memberof CompanyDocumentDTO
	 */
	expirationDate?: Date;
	/**
	 *
	 * @type {CompanyDocumentStatusEnum}
	 * @memberof CompanyDocumentDTO
	 */
	documentStatus?: CompanyDocumentStatusEnum;
}

export function CompanyDocumentDTOFromJSON(json: any): CompanyDocumentDTO {
	return CompanyDocumentDTOFromJSONTyped(json, false);
}

export function CompanyDocumentDTOFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): CompanyDocumentDTO {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		id: !exists(json, "id") ? undefined : json["id"],
		documentType: !exists(json, "documentType")
			? undefined
			: DocumentTypeEnumFromJSON(json["documentType"]),
		companyId: !exists(json, "companyId") ? undefined : json["companyId"],
		companyName: !exists(json, "companyName") ? undefined : json["companyName"],
		facultyId: !exists(json, "facultyId") ? undefined : json["facultyId"],
		facultyNameRo: !exists(json, "facultyNameRo")
			? undefined
			: json["facultyNameRo"],
		facultyNameEn: !exists(json, "facultyNameEn")
			? undefined
			: json["facultyNameEn"],
		documentOwner: !exists(json, "documentOwner")
			? undefined
			: json["documentOwner"],
		skipOfferValidation: !exists(json, "skipOfferValidation")
			? undefined
			: json["skipOfferValidation"],
		generationDate: !exists(json, "generationDate")
			? undefined
			: new Date(json["generationDate"]),
		updatedAt: !exists(json, "updatedAt")
			? undefined
			: new Date(json["updatedAt"]),
		expirationDate: !exists(json, "expirationDate")
			? undefined
			: new Date(json["expirationDate"]),
		documentStatus: !exists(json, "documentStatus")
			? undefined
			: CompanyDocumentStatusEnumFromJSON(json["documentStatus"]),
	};
}

export function CompanyDocumentDTOToJSON(
	value?: CompanyDocumentDTO | null
): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		id: value.id,
		documentType: DocumentTypeEnumToJSON(value.documentType),
		companyId: value.companyId,
		companyName: value.companyName,
		facultyId: value.facultyId,
		facultyNameRo: value.facultyNameRo,
		facultyNameEn: value.facultyNameEn,
		documentOwner: value.documentOwner,
		skipOfferValidation: value.skipOfferValidation,
		generationDate:
			value.generationDate === undefined
				? undefined
				: value.generationDate.toISOString(),
		updatedAt:
			value.updatedAt === undefined ? undefined : value.updatedAt.toISOString(),
		expirationDate:
			value.expirationDate === undefined
				? undefined
				: value.expirationDate.toISOString(),
		documentStatus: CompanyDocumentStatusEnumToJSON(value.documentStatus),
	};
}
