import { PropsWithChildren, useCallback, useEffect } from "react";
import { InternshipSortFieldEnum, SortDirectionEnum } from "../../Api";
import { Button, Form, Input, Select, Space } from "antd";
import useQueryFilters from "../../Hooks/useQueryFilters";
import { useIsCompany } from "../../utils/utilFunctions";
import { SearchOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

interface FilterProps {
	filters?: any[];
	searchFields?: any[];
	onUpdate: (filters: { [key: string]: string[] }) => void;
	handleCancel?: () => void;
	hasSort?: boolean;
}

const FilterContent = (props: PropsWithChildren<FilterProps>) => {
	const { filters, searchFields, onUpdate, handleCancel, hasSort } = props;
	const query = useQueryFilters({});
	const [form] = Form.useForm();
	const isCompany = useIsCompany();
	const { t } = useTranslation();
	const location = useLocation();

	useEffect(() => {
		form.setFieldsValue(query.filters);
	}, [form, query.filters]);

	const onApply = useCallback(
		(values: { [key: string]: string[] }) => {
			return onUpdate(values);
		},
		[onUpdate]
	);

	const onReset = useCallback(() => {
		query.clear();
		if (handleCancel) handleCancel();
	}, [handleCancel, query]);

	return (
		<Form
			layout={window.innerWidth > 768 ? "inline" : "vertical"}
			form={form}
			name={"filter-control" + (handleCancel ? "-mobile" : "")}
			onFinish={onApply}
			onReset={onReset}
			labelCol={{ flex: window.innerWidth > 768 ? "120px" : "0" }}
		>
			<div className="grid grid-cols-[repeat(auto-fit,_minmax(360px,_1fr))] gap-2 w-full">
				{searchFields?.map((field: any) => {
					if (field.options)
						return (
							<Form.Item
								key={field.name}
								name={field.name}
								label={
									<span
										title={field.label}
										className="overflow-hidden overflow-ellipsis"
									>
										{field.label}
									</span>
								}
								className="m-0"
							>
								<Select
									placeholder={t("filtering.selectPlaceholder")}
									allowClear
								>
									{field.options.map((option: any) => (
										<Select.Option value={option.key} key={option.key}>
											{option.value}
										</Select.Option>
									))}
								</Select>
							</Form.Item>
						);

					return (
						<Form.Item
							key={field.name}
							name={field.name}
							label={
								<span
									title={field.label}
									className="overflow-hidden overflow-ellipsis"
								>
									{field.label}
								</span>
							}
							className="m-0"
						>
							<Input addonBefore={<SearchOutlined />} allowClear />
						</Form.Item>
					);
				})}

				{hasSort && (
					<>
						<Form.Item
							key={"sortKey"}
							name={"sortKey"}
							label={
								<span
									title={t("internships.sortCategory")}
									className="overflow-hidden overflow-ellipsis"
								>
									{t("internships.sortCategory")}
								</span>
							}
							className="m-0"
						>
							<Select placeholder="Select option" allowClear>
								<Select.Option
									value={InternshipSortFieldEnum.PublishDate}
									key={InternshipSortFieldEnum.PublishDate}
								>
									{t("internships.sort.publishDate")}
								</Select.Option>

								{location.pathname !== "/newsletter" && (
									<Select.Option
										value={InternshipSortFieldEnum.Name}
										key={InternshipSortFieldEnum.Name}
									>
										{t("internships.sort.name")}
									</Select.Option>
								)}

								{!(
									(location.pathname === "/propuneri" && isCompany) ||
									location.pathname === "/newletter"
								) && (
									<Select.Option
										value={InternshipSortFieldEnum.CoordinatorName}
										key={InternshipSortFieldEnum.CoordinatorName}
									>
										{t("internships.sort.coordinatorName")}
									</Select.Option>
								)}
							</Select>
						</Form.Item>

						<Form.Item
							key={"sort"}
							name={"sort"}
							label={
								<span
									title={t("internships.sortWay")}
									className="overflow-hidden overflow-ellipsis"
								>
									{t("internships.sortWay")}
								</span>
							}
							className="m-0"
						>
							<Select placeholder="Select option" allowClear>
								<Select.Option
									value={SortDirectionEnum.Ascending}
									key={SortDirectionEnum.Ascending}
								>
									{t("internships.ascending")}
								</Select.Option>
								<Select.Option
									value={SortDirectionEnum.Descending}
									key={SortDirectionEnum.Descending}
								>
									{t("internships.descending")}
								</Select.Option>
							</Select>
						</Form.Item>
					</>
				)}

				{filters && (
					<>
						{filters.map((filter) => (
							<Form.Item
								key={filter.key}
								name={filter.key as string}
								label={
									<span
										title={t("filtering." + filter.title)}
										className="overflow-hidden overflow-ellipsis"
									>
										{t("filtering." + filter.title)}
									</span>
								}
								className="m-0"
							>
								<Select
									placeholder={t("filtering.selectPlaceholder")}
									allowClear
									mode="multiple"
								>
									{filter.options.map((option: any) => (
										<Select.Option value={option.key} key={option.key}>
											{option.value}
										</Select.Option>
									))}
								</Select>
							</Form.Item>
						))}
					</>
				)}
			</div>

			<div className="flex gap-2 justify-end w-full pt-4">
				<Button type="primary" htmlType="submit">
					{t("filtering.applyFilters")}
				</Button>
				<Button htmlType="reset">{t("filtering.clearFilters")}</Button>
			</div>
		</Form>
	);
};

export default FilterContent;
