import { Form, Modal, Select, Spin } from "antd";
import { FeatureFlagsDTO, FeatureFlagsEnum } from "../../../../../Api";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../../../../Notifications/NotificationsUtils";
import { updateFacultyFeatureFlagsValue } from "../../../../../Requests/feedback-feature-flags-requests";
import CustomModalFooter from "../../../../../Containers/CustomModalFooter";
import { theme } from "../../../../../theme";
import CustomForm from "../../../../../CustomComponents/CustomForm";

const { Option } = Select;

export const UpdateFacultyFlagsModal = (props: {
	featureFlag: FeatureFlagsDTO;
	isModalVisible: boolean;
	handleClose: () => void;
	invalidateQuery: () => void;
}) => {
	const { t } = useTranslation();
	const [spinning, setSpinning] = useState(false);
	const [form] = Form.useForm<{
		facultyIds: string[];
	}>();

	const handleUpdate = () => {
		const state = form.getFieldsValue();

		setSpinning(true);

		updateFacultyFeatureFlagsValue(
			props.featureFlag.featureFlag ?? FeatureFlagsEnum.StudentsFeedbackEnabled,
			state.facultyIds
		)
			.then(async () => {
				form.setFieldsValue({
					facultyIds: [],
				});
				props.invalidateQuery();
				openNotification(
					t("account.studyProgramAdd"),
					t("account.studyProgramAddSuccess"),
					NOTIFICATION_TYPES.SUCCESS
				);
			})
			.catch(() =>
				openNotification(
					t("account.studyProgramAdd"),
					t("account.studyProgramAddSuccess"),
					NOTIFICATION_TYPES.ERROR
				)
			)
			.finally(() => {
				setSpinning(false);
				props.handleClose();
			});
	};

	const enabledFacultyFlags = props.featureFlag.facultyFeatureFlags
		?.filter((flag) => flag.value)
		.map((flag) => flag.facultyId);

	return (
		<Modal
			open={props.isModalVisible}
			onOk={() => form.submit()}
			onCancel={props.handleClose}
			title={t("featureFlags.updateFacultyFlagsModalTitle")}
			width={window.innerWidth > 1215 ? "60%" : "80%"}
			footer={
				<CustomModalFooter
					handleClose={props.handleClose}
					spinning={spinning}
					handleSave={() => form.submit()}
					confirmButtonName={"Confirm"}
				/>
			}
		>
			<Spin spinning={spinning}>
				<Form.Provider onFormFinish={handleUpdate}>
					<CustomForm layout={theme.layout} form={form}>
						<div
							style={{
								display: "flex",
								justifyContent: "flex-end",
								gap: "16px",
								width: "100%",
							}}
						>
							<Form.Item
								required
								label={t("featureFlags.faculties")}
								name="facultyIds"
								rules={[
									{
										required: true,
										message: t("usersText.requiredField", {
											field: t("featureFlags.faculties"),
										}),
									},
								]}
								className="w-full"
								initialValue={enabledFacultyFlags}
							>
								<Select
									mode="multiple"
									allowClear
									maxTagCount={5}
									showSearch
									style={{ width: "100%", textAlign: "left" }}
									placeholder={t("featureFlags.faculties")}
								>
									{props.featureFlag.facultyFeatureFlags?.map((flag) => (
										<Option key={flag.facultyId} value={flag.facultyId}>
											{flag.faculty?.facultyNameRo}
										</Option>
									))}
								</Select>
							</Form.Item>
						</div>
					</CustomForm>
				</Form.Provider>
			</Spin>
		</Modal>
	);
};
