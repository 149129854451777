import { FinalAcceptApplicantModalProps } from "./FinalAcceptApplicantModal.types";
import ConfirmationModal from "../../../../../../Containers/ConfirmationModal";
import { useTranslation } from "react-i18next";
import { finalAcceptApplicant } from "../../../../../../Requests/internship-requests";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../../../../../Notifications/NotificationsUtils";
import { StatusCodes } from "http-status-codes";

export const FinalAcceptApplicantModal = (
	props: FinalAcceptApplicantModalProps
) => {
	const { t } = useTranslation();

	const handleFinalAcceptApplicant = (item: any) => {
		props.setSpinning(true);

		finalAcceptApplicant(item.internshipId, item.id)
			.then(async () => {
				await props.invalidateApplicantsQueries();
				openNotification(
					t("students.acceptApplicant"),
					t("students.successAcceptApplicantMessage"),
					NOTIFICATION_TYPES.SUCCESS
				);
			})
			.catch((ex) => {
				openNotification(
					t("students.acceptApplicant"),
					t(
						ex.status === StatusCodes.FORBIDDEN
							? "students.errorAcceptApplicantExceededMessage"
							: ex.status === StatusCodes.CONFLICT
							? "students.errorAcceptApplicantHasCompanyEnrollmentMessage"
							: "students.errorAcceptApplicantMessage"
					),
					NOTIFICATION_TYPES.ERROR
				);
			})
			.finally(() => {
				props.setSpinning(false);
				props.setModalVisibility(false);
			});
	};

	return (
		<ConfirmationModal
			modalText={
				props.selectedApplicant.availablePositions !== 0
					? t("students.acceptStudentMessage") +
					  props.selectedApplicant.name +
					  t("students.acceptStudentMessage2")
					: t("students.acceptStudentExceeded") +
					  props.selectedApplicant.internshipName +
					  t("students.acceptStudentExceeded2")
			}
			handleFunction={() => handleFinalAcceptApplicant(props.selectedApplicant)}
			modalVisibility={props.modalVisibility}
			title=""
			changeModalVisibility={() => props.setModalVisibility(false)}
			spinning={props.spinning}
			divStyle={{}}
		/>
	);
};
