import {
	AddUpdateCompanyModalFormController,
	AddUpdateCompanyModalFormProps,
} from "./AddUpdateCompanyModalForm.types";
import { useTranslation } from "react-i18next";
import { Form } from "antd";
import { AddUpdateCompanyFormModel } from "./models/AddUpdateCompanyFormModel";
import { useInitAddUpdateCompanyForm } from "./services/useInitAddUpdateCompanyForm";
import { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import {
	toCompanyAddDomainModel,
	toCompanyUpdateDomainModel,
	toFormModel,
} from "./transformers/model.transformers";
import {
	addCompanyContact,
	updateCompanyInfo,
} from "../../../Requests/company-requests";
import { getAllCompanies } from "../../../utils/reactQueriesConstants";
import { getErrorFromResponse } from "../../../utils/responseUtils";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../../Notifications/NotificationsUtils";
import { ErrorCodes } from "../../../Api";

export const useAddUpdateCompanyModalFormController = (
	props: AddUpdateCompanyModalFormProps
): AddUpdateCompanyModalFormController => {
	const { t } = useTranslation();
	const [form] = Form.useForm<AddUpdateCompanyFormModel>();
	const { defaultValues, yupSync } = useInitAddUpdateCompanyForm(form);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [spinning, setSpinning] = useState(false);
	const queryClient = useQueryClient();

	useEffect(() => {
		//Runs on the first render
		//And any time any dependency value changes
		if (props.record) {
			form.setFieldsValue(toFormModel(props.record));
		}
	}, [props.record]);

	const handleShow = () => {
		setIsModalVisible(true);
	};

	const handleClose = () => {
		form.setFieldsValue({ ...defaultValues });
		setIsModalVisible(false);
		props.onCloseCallBack();
	};

	const handleFinish = () => {
		form.validateFields().then((values) => {
			setSpinning(true);

			if (props.isEdit && props.record?.id !== undefined) {
				updateCompanyInfo(props.record.id, toCompanyUpdateDomainModel(values))
					.then(async () => {
						await queryClient.invalidateQueries(getAllCompanies);
					})
					.finally(() => {
						setSpinning(false);
						form.setFieldsValue({ ...defaultValues });
						setIsModalVisible(false);
						props.onCloseCallBack();
					});
			} else {
				addCompanyContact(toCompanyAddDomainModel(values))
					.then(async () => {
						await queryClient.invalidateQueries(getAllCompanies);
					})
					.catch(async (error: any) => {
						const errorMessage = await getErrorFromResponse(error);

						openNotification(
							t("signupText.register"),
							errorMessage?.code === ErrorCodes.CompanyAlreadyExists
								? t("signupText.messages.duplicatedCIF")
								: t("signupText.messages.failRegister"),
							NOTIFICATION_TYPES.ERROR
						);
					})
					.finally(() => {
						setSpinning(false);
						form.setFieldsValue({ ...defaultValues });
						setIsModalVisible(false);
						props.onCloseCallBack();
					});
			}
		});
	};

	return {
		state: {
			form,
			isModalVisible,
			spinning,
		},
		actions: {
			handleShow,
			handleClose,
			handleFinish,
		},
		computed: {
			defaultValues,
			yupSync,
		},
	};
};
