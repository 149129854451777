import { Modal, Spin, Form, Select, Button } from "antd";
import { useState } from "react";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../Notifications/NotificationsUtils";
import styles from "./Profile.module.scss";
import { useTranslation } from "react-i18next";
import { useQuery, useQueryClient } from "react-query";
import CustomModalFooter from "../../Containers/CustomModalFooter";
import {
	getFaculties,
	getFacultiesOfInterestList,
} from "../../utils/reactQueriesConstants";
import { getErrorFromResponse } from "../../utils/responseUtils";
import { ErrorCodes } from "../../Api";
import { addFacultyOfInterest } from "../../Requests/company-requests";
import { getAllFaculties } from "../../Requests/faculty-requests";

const { Option } = Select;

const AddFacultyOfInterestModal = (props: {
	facultiesOfInterestIds: string[];
}) => {
	const { t } = useTranslation();
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [selectedFaculty, setSelectedFaculty] = useState<string | undefined>();
	const [spinning, setSpinning] = useState(false);
	const [form] = Form.useForm<{
		name: string;
	}>();

	const openGetErrorNotification = (_error: any) => {
		openNotification(
			t("internships.error"),
			t("internships.fetchDataError"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const { data: allFacultiesList } = useQuery(
		[getFaculties],
		() => getAllFaculties("", 1, 20),
		{
			onError: (err) => {
				openGetErrorNotification(err);
			},
			refetchOnWindowFocus: false,
		}
	);

	const queryClient = useQueryClient();
	const invalidateQuery = async () => {
		await queryClient.invalidateQueries(getFacultiesOfInterestList);
	};

	const handleShow = () => {
		setIsModalVisible(true);
	};

	const handleClose = () => {
		setIsModalVisible(false);
	};

	const saveChangesError = async (ex: any) => {
		const error = await getErrorFromResponse(ex);

		openNotification(
			t("account.facultyOfInterestAdd"),
			error?.code === ErrorCodes.Forbidden
				? t("account.noPermissions")
				: error?.code === ErrorCodes.CompanyNotFound
				? t("account.companyNotFound")
				: error?.code === ErrorCodes.FacultyNotFound
				? t("account.facultyNotFound")
				: error?.code === ErrorCodes.FacultyOfInterestAlreadyAllocated
				? t("account.facultyOfInterestAlreadyAdded")
				: t("account.saveError"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const missingFieldsError = () => {
		openNotification(
			t("account.saveError"),
			t("usersText.requiredFieldsError"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const handleAdd = () => {
		if (selectedFaculty == null) {
			missingFieldsError();
			return;
		}

		addFacultyOfInterest(selectedFaculty)
			.then(async () => {
				await invalidateQuery();
				openNotification(
					t("account.facultyOfInterestAdd"),
					t("account.facultyOfInterestAddSuccess"),
					NOTIFICATION_TYPES.SUCCESS
				);
			})
			.catch(saveChangesError)
			.finally(() => {
				setSpinning(false);
				setSelectedFaculty(undefined);
				setIsModalVisible(false);
			});
	};

	return (
		<div style={{ display: "flex", justifyContent: "flex-end", margin: "5px" }}>
			<Button
				type="primary"
				onClick={handleShow}
				title={t("account.addFacultyOfInterest")}
			>
				{t("account.addFacultyOfInterest")}
			</Button>
			<Modal
				open={isModalVisible}
				title={t("account.facultyOfInterestAdd")}
				width={window.innerWidth > 1215 ? "30%" : "80%"}
				onCancel={handleClose}
				footer={
					<CustomModalFooter
						handleClose={handleClose}
						spinning={spinning}
						handleSave={handleAdd}
						confirmButtonName={t("account.confirm")}
					/>
				}
			>
				<Spin spinning={spinning}>
					<Select
						className={styles.antSelect}
						allowClear
						value={selectedFaculty}
						onChange={(e) => setSelectedFaculty(e)}
						style={{ width: "100%", textAlign: "left" }}
						placeholder={t("account.facultyOfInterest")}
					>
						{allFacultiesList?.length &&
							allFacultiesList?.length > 0 &&
							allFacultiesList.map((sp) => (
								<Option key={sp.id} value={sp.id}>
									{sp.facultyNameRo}
								</Option>
							))}
					</Select>
				</Spin>
			</Modal>
		</div>
	);
};

export default AddFacultyOfInterestModal;
