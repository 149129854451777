import { ApplicantsViewProps } from "./ApplicantsView.types";
import { useCallback, useState } from "react";
import styles from "./ApplicantsView.module.scss";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { InternsForEmployerDTO, InternType } from "../../../Api";
import { studentIdSet } from "../../Student/StudentSlice";
import { traineesFilters } from "../../../utils/constants";
import { WithdrawApplicationModal } from "./components/modals/WithdrawApplicationModal/WithdrawApplicationModal";
import { RemoveApplicantModal } from "./components/modals/RemoveApplicantModal/RemoveApplicantModal";
import { RejectApplicantModal } from "./components/modals/RejectApplicantModal/RejectApplicantModal";
import { FinalAcceptApplicantModal } from "./components/modals/FinalAcceptApplicantModal/FinalAcceptApplicantModal";
import { AcceptApplicantModal } from "./components/modals/AcceptApplicantModal/AcceptApplicantModal";
import {
	getAllApplicationTypesCount,
	getInterns,
	getTraineesFilters,
} from "../../../utils/reactQueriesConstants";
import { useQuery, useQueryClient } from "react-query";
import {
	getInternsFiltersV2,
	getInternsForEmployerV2,
} from "../../../Requests/internship-requests";
import Filters from "../../../Components/Filters";
import useQueryFilters from "../../../Hooks/useQueryFilters";
import { Button, Pagination, Progress, Table, Typography } from "antd";
import { useColumns } from "./columns";
import { t } from "i18next";
import i18n from "../../../i18n";
import { useIsCompany } from "../../../utils/utilFunctions";
import { downloadAll, downloadExcel } from "../../../utils/downloadUtils";
import { removeDiacritics } from "../../../utils/dataUtils";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../../Notifications/NotificationsUtils";
import ExpandedDetails from "./ExpandedDetails";
import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";

export const ApplicantsView = (props: ApplicantsViewProps) => {
	const queryClient = useQueryClient();
	const dispatch = useDispatch();
	const history = useHistory();
	const isCompany = useIsCompany();
	const [acceptModalVisibility, setAcceptModalVisibility] = useState(false);
	const [finalAcceptModalVisibility, setFinalAcceptModalVisibility] =
		useState(false);
	const [rejectModalVisibility, setRejectModalVisibility] = useState(false);
	const [removeModalVisibility, setRemoveModalVisibility] = useState(false);
	const [withdrawModalVisibility, setWithdrawModalVisibility] = useState(false);
	const [selectedApplicant, setSelectedApplicant] =
		useState<InternsForEmployerDTO>({});
	const [spinning, setSpinning] = useState(false);
	const [downloading, setDownloading] = useState(false);
	const [downloadError, setDownloadError] = useState(false);
	const [progress, setProgress] = useState(0);
	const [selectedTutor, setSelectedTutor] = useState("");
	const query = useQueryFilters({});
	const goToStudentProfile = (id: any) => {
		dispatch(studentIdSet(id));
		history.push("/student");
	};
	const actionProps = {
		setSelectedApplicant,
		setSelectedTutor,
		setAcceptModalVisibility,
		setFinalAcceptModalVisibility,
		setRejectModalVisibility,
		setRemoveModalVisibility,
		setWithdrawModalVisibility,
	};
	const allColumns = useColumns(
		queryClient,
		goToStudentProfile,
		actionProps
	) as any[];
	const internshipAdminColumns = allColumns.filter(
		(column) => column.key !== "applicationDate"
	);
	const columns = isCompany
		? internshipAdminColumns.filter(
				(column) => column.key !== "internshipEmployer"
		  )
		: internshipAdminColumns;
	const excelColumns = isCompany
		? allColumns.filter((column) => column.key !== "internshipEmployer")
		: allColumns;
	const invalidateApplicantsQueries = async () => {
		await queryClient.invalidateQueries(getInterns);
		await queryClient.invalidateQueries(getTraineesFilters);
		await queryClient.invalidateQueries(getAllApplicationTypesCount);
	};
	const { data: filters } = useQuery(
		[traineesFilters, i18n.language, query.filters],
		() => {
			return getInternsFiltersV2({
				filtersList: traineesFilters,
				language: i18n.language,
				type: InternType.Applicant,
				...query.filters,
			});
		}
	);

	const { data, isLoading: loading } = useQuery(
		[getInterns, query.filters],
		() => {
			return getInternsForEmployerV2({
				type: InternType.Applicant,
				...query.filters,
			});
		}
	);

	const onUpdate = useCallback(
		(values: { [key: string]: string[] }) => {
			query.update(values, true);
		},
		[query]
	);

	const onPageUpdate = useCallback(
		(page: number, pageSize: number) => {
			query.update({
				page: page.toString(),
				pageSize: pageSize.toString(),
			});
		},
		[query]
	);

	const downloadApplicantsList = useCallback(async () => {
		setDownloading(true);

		return downloadExcel(
			t("files.applicants"),
			excelColumns
				.filter((e) => e.key !== "actions")
				.map((e) => {
					return {
						header:
							typeof e.title === "string"
								? removeDiacritics(e.title)
								: removeDiacritics(t("applicants." + e.key)),
						key: e.key.toString(),
					};
				}),
			await downloadAll(
				(currentPage, pageSize) =>
					getInternsForEmployerV2({
						type: InternType.Applicant,
						...query.filters,
					}),
				(value) => {
					setDownloadError(false);
					setProgress(value);
				},
				() => {
					setDownloadError(true);
					openNotification(
						t("reports.errorTexts.downloadFailed"),
						t("reports.errorTexts.downloadFailedMessage"),
						NOTIFICATION_TYPES.ERROR
					);
				}
			)
		);
	}, [t, excelColumns, query.filters]);

	return (
		<div className="px-4 pb-10 flex flex-col">
			<Filters
				filters={filters}
				searchFields={[{ name: "search", label: t("search.byName") }]}
				onUpdate={onUpdate}
				hasSort={false}
			/>
			{isCompany && (
				<div className={styles.container}>
					<Button
						onClick={downloadApplicantsList}
						style={{ marginBottom: "1em", marginTop: "0" }}
					>
						{t("reports.download")}
					</Button>
					{downloading && (
						<Progress
							percent={progress}
							status={downloadError ? "exception" : undefined}
						/>
					)}
				</div>
			)}

			<div className="flex flex-col gap-10">
				<div className="flex flex-col md:flex-row justify-between items-center">
					<Typography.Title level={3} className="my-0">
						{data?.totalCount} {t("filtering.results")}
					</Typography.Title>
					{!loading && (
						<Pagination
							defaultCurrent={data?.page}
							defaultPageSize={data?.pageSize}
							total={data?.totalCount}
							onChange={onPageUpdate}
							locale={{ items_per_page: t("pagination") }}
						/>
					)}
				</div>

				<Table
					locale={{ emptyText: t("tableText.emptyTable") }}
					columns={columns}
					dataSource={data?.data ?? []}
					pagination={false}
					scroll={{ x: "calc(700px + 50%)", y: 420 }}
					rowKey={(record) => record.internshipEnrollmentId!}
					expandable={{
						expandedRowRender: (record: InternsForEmployerDTO) => (
							<ExpandedDetails record={record} />
						),
						expandIcon: ({ expanded, onExpand, record }) =>
							expanded ? (
								<CaretUpOutlined onClick={(e) => onExpand(record, e)} />
							) : (
								<CaretDownOutlined onClick={(e) => onExpand(record, e)} />
							),
					}}
				/>
			</div>
			<AcceptApplicantModal
				selectedApplicant={selectedApplicant}
				selectedTutor={selectedTutor}
				modalVisibility={acceptModalVisibility}
				spinning={spinning}
				invalidateApplicantsQueries={invalidateApplicantsQueries}
				setSpinning={setSpinning}
				setModalVisibility={setAcceptModalVisibility}
				setSelectedTutor={setSelectedTutor}
			/>
			<FinalAcceptApplicantModal
				selectedApplicant={selectedApplicant}
				modalVisibility={finalAcceptModalVisibility}
				spinning={spinning}
				invalidateApplicantsQueries={invalidateApplicantsQueries}
				setSpinning={setSpinning}
				setModalVisibility={setFinalAcceptModalVisibility}
			/>
			<RejectApplicantModal
				selectedApplicant={selectedApplicant}
				modalVisibility={rejectModalVisibility}
				spinning={spinning}
				invalidateApplicantsQueries={invalidateApplicantsQueries}
				setSpinning={setSpinning}
				setModalVisibility={setRejectModalVisibility}
			/>
			<RemoveApplicantModal
				selectedApplicant={selectedApplicant}
				modalVisibility={removeModalVisibility}
				spinning={spinning}
				invalidateApplicantsQueries={invalidateApplicantsQueries}
				setSpinning={setSpinning}
				setModalVisibility={setRemoveModalVisibility}
			/>
			<WithdrawApplicationModal
				selectedApplicant={selectedApplicant}
				modalVisibility={withdrawModalVisibility}
				spinning={spinning}
				invalidateApplicantsQueries={invalidateApplicantsQueries}
				setSpinning={setSpinning}
				setModalVisibility={setWithdrawModalVisibility}
			/>
		</div>
	);
};
