/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
	InternshipType,
	InternshipTypeFromJSON,
	InternshipTypeFromJSONTyped,
	InternshipTypeToJSON,
} from "./InternshipType";
import {
	PracticeCertificateSigningEnum,
	PracticeCertificateSigningEnumFromJSON,
	PracticeCertificateSigningEnumFromJSONTyped,
	PracticeCertificateSigningEnumToJSON,
} from "./PracticeCertificateSigningEnum";
import {
	PracticeCertificateState,
	PracticeCertificateStateFromJSON,
	PracticeCertificateStateFromJSONTyped,
	PracticeCertificateStateToJSON,
} from "./PracticeCertificateState";
import {
	PracticeConventionState,
	PracticeConventionStateFromJSON,
	PracticeConventionStateFromJSONTyped,
	PracticeConventionStateToJSON,
} from "./PracticeConventionState";
import {
	PracticeInternshipPeriodDTO,
	PracticeInternshipPeriodDTOFromJSON,
	PracticeInternshipPeriodDTOFromJSONTyped,
	PracticeInternshipPeriodDTOToJSON,
} from "./PracticeInternshipPeriodDTO";
import {
	PracticeNotebookSigningEnum,
	PracticeNotebookSigningEnumFromJSON,
	PracticeNotebookSigningEnumFromJSONTyped,
	PracticeNotebookSigningEnumToJSON,
} from "./PracticeNotebookSigningEnum";
import {
	PracticeNotebookState,
	PracticeNotebookStateFromJSON,
	PracticeNotebookStateFromJSONTyped,
	PracticeNotebookStateToJSON,
} from "./PracticeNotebookState";
import {
	StudentYearEnum,
	StudentYearEnumFromJSON,
	StudentYearEnumFromJSONTyped,
	StudentYearEnumToJSON,
} from "./StudentYearEnum";

/**
 *
 * @export
 * @interface PracticeStudentsDTO
 */
export interface PracticeStudentsDTO {
	/**
	 *
	 * @type {string}
	 * @memberof PracticeStudentsDTO
	 */
	id?: string;
	/**
	 *
	 * @type {string}
	 * @memberof PracticeStudentsDTO
	 */
	readonly name?: string | null;
	/**
	 *
	 * @type {StudentYearEnum}
	 * @memberof PracticeStudentsDTO
	 */
	year?: StudentYearEnum;
	/**
	 *
	 * @type {string}
	 * @memberof PracticeStudentsDTO
	 */
	readonly studyProgram?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof PracticeStudentsDTO
	 */
	readonly employerId?: string;
	/**
	 *
	 * @type {string}
	 * @memberof PracticeStudentsDTO
	 */
	readonly employerName?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof PracticeStudentsDTO
	 */
	internshipId?: string;
	/**
	 *
	 * @type {string}
	 * @memberof PracticeStudentsDTO
	 */
	readonly internshipName?: string | null;
	/**
	 *
	 * @type {InternshipType}
	 * @memberof PracticeStudentsDTO
	 */
	internshipType?: InternshipType;
	/**
	 *
	 * @type {string}
	 * @memberof PracticeStudentsDTO
	 */
	internshipEnrollmentId?: string;
	/**
	 *
	 * @type {string}
	 * @memberof PracticeStudentsDTO
	 */
	supervisorId?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof PracticeStudentsDTO
	 */
	readonly supervisorName?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof PracticeStudentsDTO
	 */
	readonly practiceConventionId?: string;
	/**
	 *
	 * @type {PracticeConventionState}
	 * @memberof PracticeStudentsDTO
	 */
	practiceConventionStatus?: PracticeConventionState;
	/**
	 *
	 * @type {boolean}
	 * @memberof PracticeStudentsDTO
	 */
	hasGeneratedPracticeConvention?: boolean;
	/**
	 *
	 * @type {string}
	 * @memberof PracticeStudentsDTO
	 */
	readonly practiceNotebookId?: string;
	/**
	 *
	 * @type {PracticeNotebookState}
	 * @memberof PracticeStudentsDTO
	 */
	practiceNotebookStatus?: PracticeNotebookState;
	/**
	 *
	 * @type {string}
	 * @memberof PracticeStudentsDTO
	 */
	readonly practiceNotebookLastSignatureId?: string;
	/**
	 *
	 * @type {PracticeNotebookSigningEnum}
	 * @memberof PracticeStudentsDTO
	 */
	practiceNotebookLastSigner?: PracticeNotebookSigningEnum;
	/**
	 *
	 * @type {string}
	 * @memberof PracticeStudentsDTO
	 */
	readonly practiceCertificateId?: string;
	/**
	 *
	 * @type {PracticeCertificateState}
	 * @memberof PracticeStudentsDTO
	 */
	practiceCertificateStatus?: PracticeCertificateState;
	/**
	 *
	 * @type {string}
	 * @memberof PracticeStudentsDTO
	 */
	readonly practiceCertificateLastSignatureId?: string;
	/**
	 *
	 * @type {PracticeCertificateSigningEnum}
	 * @memberof PracticeStudentsDTO
	 */
	practiceCertificateLastSigner?: PracticeCertificateSigningEnum;
	/**
	 *
	 * @type {number}
	 * @memberof PracticeStudentsDTO
	 */
	totalPracticeHours?: number;
	/**
	 *
	 * @type {Array<PracticeInternshipPeriodDTO>}
	 * @memberof PracticeStudentsDTO
	 */
	practiceHoursPerFaculty?: Array<PracticeInternshipPeriodDTO> | null;
	/**
	 *
	 * @type {string}
	 * @memberof PracticeStudentsDTO
	 */
	laboratoryPracticeRoom?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof PracticeStudentsDTO
	 */
	group?: string | null;
}

export function PracticeStudentsDTOFromJSON(json: any): PracticeStudentsDTO {
	return PracticeStudentsDTOFromJSONTyped(json, false);
}

export function PracticeStudentsDTOFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): PracticeStudentsDTO {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		id: !exists(json, "id") ? undefined : json["id"],
		name: !exists(json, "name") ? undefined : json["name"],
		year: !exists(json, "year")
			? undefined
			: StudentYearEnumFromJSON(json["year"]),
		studyProgram: !exists(json, "studyProgram")
			? undefined
			: json["studyProgram"],
		employerId: !exists(json, "employerId") ? undefined : json["employerId"],
		employerName: !exists(json, "employerName")
			? undefined
			: json["employerName"],
		internshipId: !exists(json, "internshipId")
			? undefined
			: json["internshipId"],
		internshipName: !exists(json, "internshipName")
			? undefined
			: json["internshipName"],
		internshipType: !exists(json, "internshipType")
			? undefined
			: InternshipTypeFromJSON(json["internshipType"]),
		internshipEnrollmentId: !exists(json, "internshipEnrollmentId")
			? undefined
			: json["internshipEnrollmentId"],
		supervisorId: !exists(json, "supervisorId")
			? undefined
			: json["supervisorId"],
		supervisorName: !exists(json, "supervisorName")
			? undefined
			: json["supervisorName"],
		practiceConventionId: !exists(json, "practiceConventionId")
			? undefined
			: json["practiceConventionId"],
		practiceConventionStatus: !exists(json, "practiceConventionStatus")
			? undefined
			: PracticeConventionStateFromJSON(json["practiceConventionStatus"]),
		hasGeneratedPracticeConvention: !exists(
			json,
			"hasGeneratedPracticeConvention"
		)
			? undefined
			: json["hasGeneratedPracticeConvention"],
		practiceNotebookId: !exists(json, "practiceNotebookId")
			? undefined
			: json["practiceNotebookId"],
		practiceNotebookStatus: !exists(json, "practiceNotebookStatus")
			? undefined
			: PracticeNotebookStateFromJSON(json["practiceNotebookStatus"]),
		practiceNotebookLastSignatureId: !exists(
			json,
			"practiceNotebookLastSignatureId"
		)
			? undefined
			: json["practiceNotebookLastSignatureId"],
		practiceNotebookLastSigner: !exists(json, "practiceNotebookLastSigner")
			? undefined
			: PracticeNotebookSigningEnumFromJSON(json["practiceNotebookLastSigner"]),
		practiceCertificateId: !exists(json, "practiceCertificateId")
			? undefined
			: json["practiceCertificateId"],
		practiceCertificateStatus: !exists(json, "practiceCertificateStatus")
			? undefined
			: PracticeCertificateStateFromJSON(json["practiceCertificateStatus"]),
		practiceCertificateLastSignatureId: !exists(
			json,
			"practiceCertificateLastSignatureId"
		)
			? undefined
			: json["practiceCertificateLastSignatureId"],
		practiceCertificateLastSigner: !exists(
			json,
			"practiceCertificateLastSigner"
		)
			? undefined
			: PracticeCertificateSigningEnumFromJSON(
					json["practiceCertificateLastSigner"]
			  ),
		totalPracticeHours: !exists(json, "totalPracticeHours")
			? undefined
			: json["totalPracticeHours"],
		practiceHoursPerFaculty: !exists(json, "practiceHoursPerFaculty")
			? undefined
			: json["practiceHoursPerFaculty"] === null
			? null
			: (json["practiceHoursPerFaculty"] as Array<any>).map(
					PracticeInternshipPeriodDTOFromJSON
			  ),
		laboratoryPracticeRoom: !exists(json, "laboratoryPracticeRoom")
			? undefined
			: json["laboratoryPracticeRoom"],
		group: !exists(json, "group") ? undefined : json["group"],
	};
}

export function PracticeStudentsDTOToJSON(
	value?: PracticeStudentsDTO | null
): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		id: value.id,
		year: StudentYearEnumToJSON(value.year),
		internshipId: value.internshipId,
		internshipType: InternshipTypeToJSON(value.internshipType),
		internshipEnrollmentId: value.internshipEnrollmentId,
		supervisorId: value.supervisorId,
		practiceConventionStatus: PracticeConventionStateToJSON(
			value.practiceConventionStatus
		),
		hasGeneratedPracticeConvention: value.hasGeneratedPracticeConvention,
		practiceNotebookStatus: PracticeNotebookStateToJSON(
			value.practiceNotebookStatus
		),
		practiceNotebookLastSigner: PracticeNotebookSigningEnumToJSON(
			value.practiceNotebookLastSigner
		),
		practiceCertificateStatus: PracticeCertificateStateToJSON(
			value.practiceCertificateStatus
		),
		practiceCertificateLastSigner: PracticeCertificateSigningEnumToJSON(
			value.practiceCertificateLastSigner
		),
		totalPracticeHours: value.totalPracticeHours,
		practiceHoursPerFaculty:
			value.practiceHoursPerFaculty === undefined
				? undefined
				: value.practiceHoursPerFaculty === null
				? null
				: (value.practiceHoursPerFaculty as Array<any>).map(
						PracticeInternshipPeriodDTOToJSON
				  ),
		laboratoryPracticeRoom: value.laboratoryPracticeRoom,
		group: value.group,
	};
}
