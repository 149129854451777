/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface PracticeInternshipsPerDomainReportDTO
 */
export interface PracticeInternshipsPerDomainReportDTO {
	/**
	 *
	 * @type {string}
	 * @memberof PracticeInternshipsPerDomainReportDTO
	 */
	id?: string;
	/**
	 *
	 * @type {string}
	 * @memberof PracticeInternshipsPerDomainReportDTO
	 */
	fieldOfStudy?: string | null;
	/**
	 *
	 * @type {number}
	 * @memberof PracticeInternshipsPerDomainReportDTO
	 */
	totalInternships?: number;
	/**
	 *
	 * @type {number}
	 * @memberof PracticeInternshipsPerDomainReportDTO
	 */
	totalInitialPositions?: number;
	/**
	 *
	 * @type {number}
	 * @memberof PracticeInternshipsPerDomainReportDTO
	 */
	totalAvailablePositions?: number;
	/**
	 *
	 * @type {number}
	 * @memberof PracticeInternshipsPerDomainReportDTO
	 */
	averageNumberPositionsPerEmployer?: number;
}

export function PracticeInternshipsPerDomainReportDTOFromJSON(
	json: any
): PracticeInternshipsPerDomainReportDTO {
	return PracticeInternshipsPerDomainReportDTOFromJSONTyped(json, false);
}

export function PracticeInternshipsPerDomainReportDTOFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): PracticeInternshipsPerDomainReportDTO {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		id: !exists(json, "id") ? undefined : json["id"],
		fieldOfStudy: !exists(json, "fieldOfStudy")
			? undefined
			: json["fieldOfStudy"],
		totalInternships: !exists(json, "totalInternships")
			? undefined
			: json["totalInternships"],
		totalInitialPositions: !exists(json, "totalInitialPositions")
			? undefined
			: json["totalInitialPositions"],
		totalAvailablePositions: !exists(json, "totalAvailablePositions")
			? undefined
			: json["totalAvailablePositions"],
		averageNumberPositionsPerEmployer: !exists(
			json,
			"averageNumberPositionsPerEmployer"
		)
			? undefined
			: json["averageNumberPositionsPerEmployer"],
	};
}

export function PracticeInternshipsPerDomainReportDTOToJSON(
	value?: PracticeInternshipsPerDomainReportDTO | null
): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		id: value.id,
		fieldOfStudy: value.fieldOfStudy,
		totalInternships: value.totalInternships,
		totalInitialPositions: value.totalInitialPositions,
		totalAvailablePositions: value.totalAvailablePositions,
		averageNumberPositionsPerEmployer: value.averageNumberPositionsPerEmployer,
	};
}
