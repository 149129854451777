import { Avatar, Button } from "antd";
import { useQuery } from "react-query";
import { getStudentAccountInfo } from "../../utils/reactQueriesConstants";
import { useHistory } from "react-router-dom";
import { getUserId } from "../../utils/utilFunctions";
import { getStudentById } from "../../Requests/academic-user-requests";
import { UserOutlined } from "@ant-design/icons";
import { Routes } from "../../utils/routes";
import { useTranslation } from "react-i18next";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../Notifications/NotificationsUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

export default function ViewStudent() {
	const { t, i18n } = useTranslation();
	const history = useHistory();

	const { data: studentState } = useQuery([getStudentAccountInfo], () => {
		return getStudentById(getUserId());
	});

	const data = studentState
		? {
				id: studentState.id,
				image: studentState.fullResImage ?? studentState.lowResImage,
				departmentNameRo: studentState.departmentNameRo,
				departmentNameEn: studentState.departmentNameEn,
				phone: studentState.phone,
				email: studentState.email,
				personalEmail: studentState.personalEmail,
				description: studentState.description,
				name: studentState.name,
		  }
		: null;

	const goToEdit = () => history.push(Routes.MY_ACCOUNT);

	return (
		<div className="flex flex-col pt-20 pb-10 items-center">
			<div className="relative w-full pb-10">
				<div className="absolute w-full top-32 md:top-16 bottom-0 bg-primary"></div>

				<div className="relative grid md:grid-rows-[auto_1fr] grid-cols-1 md:grid-cols-[218px_auto] gap-12 max-w-[727px] w-full mx-auto">
					<div className="row-span-2 flex flex-col gap-6 items-center">
						<Avatar size={218} src={data?.image} icon={<UserOutlined />} />

						<Button className="bg-primary-faded" onClick={goToEdit}>
							{t("account.edit")}
						</Button>
					</div>

					<div className="font-bold text-primary text-2xl order-first md:order-none text-center md:text-left">
						{data?.name}
					</div>

					<div className="grid grid-cols-1 md:grid-cols-2 gap-4 w-fit text-white order-last md:order-none mx-auto text-center md:text-left">
						{i18n.language === "ro" ? (
							data?.departmentNameRo ? (
								<>
									<div className="font-bold self-center">Departament</div>
									<div className="self-center">{data?.departmentNameRo}</div>
								</>
							) : null
						) : data?.departmentNameEn ? (
							<>
								<div className="font-bold self-center">Department</div>
								<div className="self-center">{data?.departmentNameEn}</div>
							</>
						) : null}

						{data?.email && (
							<>
								<div className="font-bold self-center">
									{t("account.email")}
								</div>

								<div className="self-center flex items-center">
									{data?.email}
									<Button
										type="text"
										style={{
											backgroundColor: "transparent",
										}}
										onClick={() => {
											navigator.clipboard
												.writeText(data?.email || "")
												.then(() => {
													openNotification(
														t("profile.emailCopied"),
														t("profile.emailCopiedMessage"),
														NOTIFICATION_TYPES.SUCCESS
													);
												});
										}}
									>
										<FontAwesomeIcon
											icon={solid("copy")}
											className="text-2xl text-white"
											title={t("account.copyToClipboard")}
										/>
									</Button>
								</div>
							</>
						)}

						{data?.phone && (
							<>
								<div className="font-bold self-center">
									{t("account.phone")}
								</div>
								<div className="self-center">{data?.phone}</div>
							</>
						)}
					</div>
				</div>
			</div>

			<div
				className="max-w-[727px] w-full mx-auto px-4"
				dangerouslySetInnerHTML={{ __html: data?.description || "" }}
			></div>
		</div>
	);
}
