/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum InternshipSortFieldEnum {
	PublishDate = "PublishDate",
	CoordinatorName = "CoordinatorName",
	Name = "Name",
}

export function InternshipSortFieldEnumFromJSON(
	json: any
): InternshipSortFieldEnum {
	return InternshipSortFieldEnumFromJSONTyped(json, false);
}

export function InternshipSortFieldEnumFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): InternshipSortFieldEnum {
	return json as InternshipSortFieldEnum;
}

export function InternshipSortFieldEnumToJSON(
	value?: InternshipSortFieldEnum | null
): any {
	return value as any;
}
