/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum HttpStatusCode {
	Continue = "Continue",
	SwitchingProtocols = "SwitchingProtocols",
	Processing = "Processing",
	EarlyHints = "EarlyHints",
	Ok = "OK",
	Created = "Created",
	Accepted = "Accepted",
	NonAuthoritativeInformation = "NonAuthoritativeInformation",
	NoContent = "NoContent",
	ResetContent = "ResetContent",
	PartialContent = "PartialContent",
	MultiStatus = "MultiStatus",
	AlreadyReported = "AlreadyReported",
	ImUsed = "IMUsed",
	MultipleChoices = "MultipleChoices",
	MovedPermanently = "MovedPermanently",
	Found = "Found",
	SeeOther = "SeeOther",
	NotModified = "NotModified",
	UseProxy = "UseProxy",
	Unused = "Unused",
	TemporaryRedirect = "TemporaryRedirect",
	PermanentRedirect = "PermanentRedirect",
	BadRequest = "BadRequest",
	Unauthorized = "Unauthorized",
	PaymentRequired = "PaymentRequired",
	Forbidden = "Forbidden",
	NotFound = "NotFound",
	MethodNotAllowed = "MethodNotAllowed",
	NotAcceptable = "NotAcceptable",
	ProxyAuthenticationRequired = "ProxyAuthenticationRequired",
	RequestTimeout = "RequestTimeout",
	Conflict = "Conflict",
	Gone = "Gone",
	LengthRequired = "LengthRequired",
	PreconditionFailed = "PreconditionFailed",
	RequestEntityTooLarge = "RequestEntityTooLarge",
	RequestUriTooLong = "RequestUriTooLong",
	UnsupportedMediaType = "UnsupportedMediaType",
	RequestedRangeNotSatisfiable = "RequestedRangeNotSatisfiable",
	ExpectationFailed = "ExpectationFailed",
	MisdirectedRequest = "MisdirectedRequest",
	UnprocessableEntity = "UnprocessableEntity",
	Locked = "Locked",
	FailedDependency = "FailedDependency",
	UpgradeRequired = "UpgradeRequired",
	PreconditionRequired = "PreconditionRequired",
	TooManyRequests = "TooManyRequests",
	RequestHeaderFieldsTooLarge = "RequestHeaderFieldsTooLarge",
	UnavailableForLegalReasons = "UnavailableForLegalReasons",
	InternalServerError = "InternalServerError",
	NotImplemented = "NotImplemented",
	BadGateway = "BadGateway",
	ServiceUnavailable = "ServiceUnavailable",
	GatewayTimeout = "GatewayTimeout",
	HttpVersionNotSupported = "HttpVersionNotSupported",
	VariantAlsoNegotiates = "VariantAlsoNegotiates",
	InsufficientStorage = "InsufficientStorage",
	LoopDetected = "LoopDetected",
	NotExtended = "NotExtended",
	NetworkAuthenticationRequired = "NetworkAuthenticationRequired",
}

export function HttpStatusCodeFromJSON(json: any): HttpStatusCode {
	return HttpStatusCodeFromJSONTyped(json, false);
}

export function HttpStatusCodeFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): HttpStatusCode {
	return json as HttpStatusCode;
}

export function HttpStatusCodeToJSON(value?: HttpStatusCode | null): any {
	return value as any;
}
