import {
	useIsAdmin,
	useIsCompany,
	useIsDean,
	useIsDepartmentDirector,
	useIsDepartmentSecretary,
	useIsFacultyAdmin,
	useIsInternshipAdmin,
	useIsProfessor,
	useIsStudent,
	useIsStudyProgramCoordinator,
	useIsUPBAdministrativeUser,
} from "../../utils/utilFunctions";
import { InternshipDTO } from "../../Api";
import { useTranslation } from "react-i18next";
import { useInternshipListController } from "./InternshipList.controller";
import { InternshipListProps } from "../../PropsLists/InternshipListProps";
import { PropsWithChildren } from "react";
import { InternshipListItem } from "./components/listItems/InternshipListItem/InternshipListItem";

const InternshipsList = (props: PropsWithChildren<InternshipListProps>) => {
	const { t } = useTranslation();
	const isProfessor = useIsProfessor();
	const isDean = useIsDean();
	const isStudent = useIsStudent();
	const isFacultyAdmin = useIsFacultyAdmin();
	const isInternshipAdmin = useIsInternshipAdmin();
	const isDepartmentDirector = useIsDepartmentDirector();
	const isStudyProgramCoordinator = useIsStudyProgramCoordinator();
	const isDepartmentSecretary = useIsDepartmentSecretary();
	const isCompany = useIsCompany();
	const isAdmin = useIsAdmin();
	const isUPBAdministrativeUser = useIsUPBAdministrativeUser();

	const { state, actions } = useInternshipListController(props);

	const areFacultyInternships =
		props.location.pathname === "/propuneri-facultati" ||
		(isProfessor &&
			props.location.pathname === "/propuneri" &&
			props.activeTab !== "2" &&
			props.activeTab !== "6");

	return (
		<>
			{props.loading && !props.data && (
				<div className="w-full py-10 text-center">Loading</div>
			)}
			{props.data?.data.length === 0 && (
				<div className="w-full py-10 text-center">
					{t("tableText.noOffers")}
				</div>
			)}

			<div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 xxl:grid-cols-4 gap-4">
				{props.data?.data.map((item: InternshipDTO) => (
					<InternshipListItem
						key={item.id}
						item={item}
						state={state}
						actions={actions}
						isProfessor={isProfessor}
						isDean={isDean}
						isStudent={isStudent}
						isFacultyAdmin={isFacultyAdmin}
						isInternshipAdmin={isInternshipAdmin}
						isDepartmentDirector={isDepartmentDirector}
						isStudyProgramCoordinator={isStudyProgramCoordinator}
						isDepartmentSecretary={isDepartmentSecretary}
						isCompany={isCompany}
						isAdmin={isAdmin}
						isUPBAdministrativeUser={isUPBAdministrativeUser}
						areFacultyInternships={areFacultyInternships}
						{...props}
					/>
				))}
			</div>
		</>
	);
};

export default InternshipsList;
