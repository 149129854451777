import Layout from "../../Containers/Layout";
import DocumentsTable from "./DocumentsTable";

const Documents = () => {
	return (
		<Layout>
			<DocumentsTable />
		</Layout>
	);
};
export default Documents;
