import { saveAs } from "file-saver";
import * as excel from "exceljs";
export interface PageResponse<T> {
	data?: T[] | null;
	totalCount?: number;
	page?: number;
	pageSize?: number;
}
export type PageCallBack<T> = (
	currentPage: number,
	pageSize: number
) => Promise<PageResponse<T> | null | undefined>;

export enum MimeType {
	docx = "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
	pdf = "application/pdf",
	excel = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
}

export async function downloadFile(
	request: Promise<Blob>,
	fileName: string,
	type: string = ""
) {
	const file = await request;

	if (type === "") {
		type = file.type.toString();
	}

	const blob = new Blob([file], { type });
	const blobUrl = URL.createObjectURL(blob);

	const a = document.createElement("a");
	a.setAttribute("type", "hidden");
	a.href = blobUrl;
	a.download = fileName.replaceAll(".", "");
	a.click();
	a.remove();
}

export async function downloadAll<T>(
	getCall: PageCallBack<T>,
	setProgress: (progress: number) => void,
	onError: () => void
) {
	let data = new Array<T>();
	let hasData = true;
	let pageIndex = 1;
	setProgress(0);

	do {
		const page = await getCall(pageIndex, 100).catch(onError);
		++pageIndex;

		if (page?.data === undefined || page?.data === null) {
			onError();

			return;
		}

		data = data.concat(page.data);

		if ((pageIndex - 1) * 100 >= (page.totalCount ?? 0)) {
			hasData = false;
		}

		setProgress(
			hasData
				? Math.round((((pageIndex - 1) * 100) / (page.totalCount ?? 1)) * 100)
				: 100
		);
	} while (hasData);

	return data;
}

export async function downloadExcel(
	fileName: string,
	columns: { header: string; key: string }[],
	objs?: any[] | null
) {
	if (objs === undefined || objs === null) {
		return;
	}

	const workbook = new excel.Workbook();
	const sheet = workbook.addWorksheet(fileName);
	sheet.columns = columns;
	sheet.addRows(objs);

	const excelBuffer = await workbook.xlsx.writeBuffer();

	saveAs(
		new Blob([excelBuffer], {
			type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
		}),
		`${fileName}.xlsx`
	);
}
