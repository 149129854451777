/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
	EmailPriority,
	EmailPriorityFromJSON,
	EmailPriorityFromJSONTyped,
	EmailPriorityToJSON,
} from "./EmailPriority";
import {
	EmailSendStrategy,
	EmailSendStrategyFromJSON,
	EmailSendStrategyFromJSONTyped,
	EmailSendStrategyToJSON,
} from "./EmailSendStrategy";
import { Role, RoleFromJSON, RoleFromJSONTyped, RoleToJSON } from "./Role";
import {
	StudentYearEnum,
	StudentYearEnumFromJSON,
	StudentYearEnumFromJSONTyped,
	StudentYearEnumToJSON,
} from "./StudentYearEnum";

/**
 *
 * @export
 * @interface PublishUpdateEmailDTO
 */
export interface PublishUpdateEmailDTO {
	/**
	 *
	 * @type {string}
	 * @memberof PublishUpdateEmailDTO
	 */
	subject?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof PublishUpdateEmailDTO
	 */
	body?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof PublishUpdateEmailDTO
	 */
	newsletterChannel?: string | null;
	/**
	 *
	 * @type {EmailSendStrategy}
	 * @memberof PublishUpdateEmailDTO
	 */
	strategy?: EmailSendStrategy;
	/**
	 *
	 * @type {Role}
	 * @memberof PublishUpdateEmailDTO
	 */
	recipientRole?: Role;
	/**
	 *
	 * @type {Array<string>}
	 * @memberof PublishUpdateEmailDTO
	 */
	faculties?: Array<string> | null;
	/**
	 *
	 * @type {Array<string>}
	 * @memberof PublishUpdateEmailDTO
	 */
	departments?: Array<string> | null;
	/**
	 *
	 * @type {Array<string>}
	 * @memberof PublishUpdateEmailDTO
	 */
	studyPrograms?: Array<string> | null;
	/**
	 *
	 * @type {Array<StudentYearEnum>}
	 * @memberof PublishUpdateEmailDTO
	 */
	years?: Array<StudentYearEnum> | null;
	/**
	 *
	 * @type {Array<string>}
	 * @memberof PublishUpdateEmailDTO
	 */
	recipientIds?: Array<string> | null;
	/**
	 *
	 * @type {EmailPriority}
	 * @memberof PublishUpdateEmailDTO
	 */
	priority?: EmailPriority;
}

export function PublishUpdateEmailDTOFromJSON(
	json: any
): PublishUpdateEmailDTO {
	return PublishUpdateEmailDTOFromJSONTyped(json, false);
}

export function PublishUpdateEmailDTOFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): PublishUpdateEmailDTO {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		subject: !exists(json, "subject") ? undefined : json["subject"],
		body: !exists(json, "body") ? undefined : json["body"],
		newsletterChannel: !exists(json, "newsletterChannel")
			? undefined
			: json["newsletterChannel"],
		strategy: !exists(json, "strategy")
			? undefined
			: EmailSendStrategyFromJSON(json["strategy"]),
		recipientRole: !exists(json, "recipientRole")
			? undefined
			: RoleFromJSON(json["recipientRole"]),
		faculties: !exists(json, "faculties") ? undefined : json["faculties"],
		departments: !exists(json, "departments") ? undefined : json["departments"],
		studyPrograms: !exists(json, "studyPrograms")
			? undefined
			: json["studyPrograms"],
		years: !exists(json, "years")
			? undefined
			: json["years"] === null
			? null
			: (json["years"] as Array<any>).map(StudentYearEnumFromJSON),
		recipientIds: !exists(json, "recipientIds")
			? undefined
			: json["recipientIds"],
		priority: !exists(json, "priority")
			? undefined
			: EmailPriorityFromJSON(json["priority"]),
	};
}

export function PublishUpdateEmailDTOToJSON(
	value?: PublishUpdateEmailDTO | null
): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		subject: value.subject,
		body: value.body,
		newsletterChannel: value.newsletterChannel,
		strategy: EmailSendStrategyToJSON(value.strategy),
		recipientRole: RoleToJSON(value.recipientRole),
		faculties: value.faculties,
		departments: value.departments,
		studyPrograms: value.studyPrograms,
		years:
			value.years === undefined
				? undefined
				: value.years === null
				? null
				: (value.years as Array<any>).map(StudentYearEnumToJSON),
		recipientIds: value.recipientIds,
		priority: EmailPriorityToJSON(value.priority),
	};
}
