/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface CompanyLoginResponseDTO
 */
export interface CompanyLoginResponseDTO {
	/**
	 *
	 * @type {string}
	 * @memberof CompanyLoginResponseDTO
	 */
	token: string;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyLoginResponseDTO
	 */
	refreshToken: string;
	/**
	 *
	 * @type {boolean}
	 * @memberof CompanyLoginResponseDTO
	 */
	requirePasswordChange: boolean;
}

export function CompanyLoginResponseDTOFromJSON(
	json: any
): CompanyLoginResponseDTO {
	return CompanyLoginResponseDTOFromJSONTyped(json, false);
}

export function CompanyLoginResponseDTOFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): CompanyLoginResponseDTO {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		token: json["token"],
		refreshToken: json["refreshToken"],
		requirePasswordChange: json["requirePasswordChange"],
	};
}

export function CompanyLoginResponseDTOToJSON(
	value?: CompanyLoginResponseDTO | null
): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		token: value.token,
		refreshToken: value.refreshToken,
		requirePasswordChange: value.requirePasswordChange,
	};
}
