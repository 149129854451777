/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
	StudentYearEnum,
	StudentYearEnumFromJSON,
	StudentYearEnumFromJSONTyped,
	StudentYearEnumToJSON,
} from "./StudentYearEnum";

/**
 *
 * @export
 * @interface Specialization
 */
export interface Specialization {
	/**
	 *
	 * @type {string}
	 * @memberof Specialization
	 */
	id?: string;
	/**
	 *
	 * @type {Date}
	 * @memberof Specialization
	 */
	createdAt?: Date;
	/**
	 *
	 * @type {Date}
	 * @memberof Specialization
	 */
	updatedAt?: Date;
	/**
	 *
	 * @type {string}
	 * @memberof Specialization
	 */
	name?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof Specialization
	 */
	domain?: string | null;
	/**
	 *
	 * @type {StudentYearEnum}
	 * @memberof Specialization
	 */
	year?: StudentYearEnum;
	/**
	 *
	 * @type {number}
	 * @memberof Specialization
	 */
	initialAvailableSpots?: number;
	/**
	 *
	 * @type {number}
	 * @memberof Specialization
	 */
	availableSpots?: number;
	/**
	 *
	 * @type {number}
	 * @memberof Specialization
	 */
	lastGrade?: number;
}

export function SpecializationFromJSON(json: any): Specialization {
	return SpecializationFromJSONTyped(json, false);
}

export function SpecializationFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): Specialization {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		id: !exists(json, "id") ? undefined : json["id"],
		createdAt: !exists(json, "createdAt")
			? undefined
			: new Date(json["createdAt"]),
		updatedAt: !exists(json, "updatedAt")
			? undefined
			: new Date(json["updatedAt"]),
		name: !exists(json, "name") ? undefined : json["name"],
		domain: !exists(json, "domain") ? undefined : json["domain"],
		year: !exists(json, "year")
			? undefined
			: StudentYearEnumFromJSON(json["year"]),
		initialAvailableSpots: !exists(json, "initialAvailableSpots")
			? undefined
			: json["initialAvailableSpots"],
		availableSpots: !exists(json, "availableSpots")
			? undefined
			: json["availableSpots"],
		lastGrade: !exists(json, "lastGrade") ? undefined : json["lastGrade"],
	};
}

export function SpecializationToJSON(value?: Specialization | null): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		id: value.id,
		createdAt:
			value.createdAt === undefined ? undefined : value.createdAt.toISOString(),
		updatedAt:
			value.updatedAt === undefined ? undefined : value.updatedAt.toISOString(),
		name: value.name,
		domain: value.domain,
		year: StudentYearEnumToJSON(value.year),
		initialAvailableSpots: value.initialAvailableSpots,
		availableSpots: value.availableSpots,
		lastGrade: value.lastGrade,
	};
}
