import { useTranslation } from "react-i18next";
import { Routes } from "../utils/routes";

const Footer = () => {
	const { t } = useTranslation();

	const pageLinks = [
		{
			label: t("footer.home"),
			path: Routes.HOME,
		},
		{
			label: t("questions.frequentQuestions"),
			path: Routes.FAQ,
		},
		{
			label: t("footer.studentsGuide"),
			path: "https://console.storage.crescdi.pub.ro/api/v1/buckets/connect-documents/objects/download?prefix=TWFudWFsVVBCQ29ubmVjdFN0dWRlbnRpLnBkZg==&version_id=null",
			target: "_blank",
			rel: "noopener noreferrer",
		},
		{
			label: t("footer.companiesGuide"),
			path: "https://console.storage.crescdi.pub.ro/api/v1/buckets/connect-documents/objects/download?prefix=TWFudWFsVVBCQ29ubmVjdENvbXBhbmlpLnBkZg==&version_id=null",
			target: "_blank",
			rel: "noopener noreferrer",
		},
		{
			label: t("footer.professorsGuide"),
			path: "https://console.storage.crescdi.pub.ro/api/v1/buckets/connect-documents/objects/download?prefix=TWFudWFsVVBCQ29ubmVjdFByb2Zlc29yaS5wZGY=&version_id=null",
			target: "_blank",
			rel: "noopener noreferrer",
		},
	];

	const legalLinks = [
		{
			label: t("footer.terms"),
			path: Routes.TERMS,
		},
		{
			label: t("footer.gdpr"),
			path: Routes.PRIVACY,
		},
		{
			label: t("footer.support"),
			path: "https://support.upb.ro/",
			target: "_blank",
			rel: "noreferrer",
		},
	];

	return (
		<div className="bg-black flex flex-col p-4 gap-6">
			<div className="flex flex-col lg:flex-row gap-20 container mx-auto pt-20 items-start">
				<img src="/images/logo/text-white.svg" alt="logo" />

				<div className="flex flex-col gap-4 items-start">
					<span className="text-white/50 font-bold">{t("footer.pages")}</span>
					{pageLinks.map((link) => (
						<a
							href={link.path}
							className="text-white hover:text-white no-underline"
							target={link.target}
							rel={link.rel}
							key={link.path}
						>
							{link.label}
						</a>
					))}
				</div>

				<div className="flex flex-col gap-4 items-start">
					<span className="text-white/50 font-bold">{t("footer.legal")}</span>
					{legalLinks.map((link) => (
						<a
							href={link.path}
							className="text-white hover:text-white no-underline"
							target={link.target}
							rel={link.rel}
							key={link.path}
						>
							{link.label}
						</a>
					))}
				</div>

				<div className="w-full lg:w-auto lg:ml-auto flex flex-col items-center gap-8">
					<img
						src="/images/logo-upb.png"
						alt="logo-upb"
						className="max-w-[100px]"
					/>
					<img
						src="/images/crescdi.png"
						alt="logo crescdi"
						className="max-w-[142px]"
					/>
				</div>
			</div>

			<div className="container mx-auto h-0.5 bg-white"></div>

			<div className="text-white text-center">Copyright 2024</div>
		</div>
	);
};

export default Footer;
