import { Select } from "antd";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { InternshipEngagementType } from "../../../Api";
import { AddInternshipFormProps } from "./AddInternshipForm.types";
import { AddEventForm } from "./components/forms/AddEventForm/AddEventForm";
import { AddInternshipOfferForm } from "./components/forms/AddInternshipOfferForm/AddInternshipOfferForm";
import { useAddInternshipFormController } from "./AddInternshipForm.controller";

const { Option } = Select;

export const AddInternshipForm = (props: AddInternshipFormProps) => {
	const { i18n } = useTranslation();
	const { state, actions, computed } = useAddInternshipFormController(props);

	useEffect(() => {
		actions.setFacultyOptions(
			(state.engagementTypeValue !== InternshipEngagementType.Internship
				? computed.allFacultiesList
				: computed.facultiesWithCompanyAccordList
			)
				?.filter((faculty) => {
					return (
						state.facultySearch === "" ||
						faculty.facultyNameRo
							?.toLowerCase()
							.includes(state.facultySearch.toLowerCase()) ||
						faculty.facultyNameEn
							?.toLowerCase()
							.includes(state.facultySearch.toLowerCase())
					);
				})
				.filter((faculty) => faculty.facultyNameRo !== "Altele")
				.map((faculty) => (
					<Option key={faculty.id} value={faculty.id}>
						{i18n.language === "en"
							? faculty.facultyNameEn
							: faculty.facultyNameRo}
					</Option>
				))
		);
	}, [
		state.facultySearch,
		computed.allFacultiesList,
		computed.facultiesWithCompanyAccordList,
		state.engagementTypeValue,
		i18n.language,
		actions.setFacultyOptions,
	]);

	const formState = state.form.getFieldsValue();

	if (formState.engagementType === InternshipEngagementType.Event) {
		return <AddEventForm {...state} {...actions} {...computed} />;
	} else {
		return <AddInternshipOfferForm {...state} {...actions} {...computed} />;
	}
};
