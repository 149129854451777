import { Button, Typography, Pagination } from "antd";
import { useCallback, useState } from "react";
import {
	getAllCompanyInternshipFilters,
	getAllCompanyInternshipV2,
} from "../../Requests/company-internship-requests";
import { useHistory } from "react-router-dom";
import {
	getCurrentUniversityYear,
	useIsFacultyAdmin,
	useIsProfessor,
	useIsStudent,
} from "../../utils/utilFunctions";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../Notifications/NotificationsUtils";
import { InternshipSortFieldEnum, SortDirectionEnum } from "../../Api";
import { useTranslation } from "react-i18next";
import { useQuery, useQueryClient } from "react-query";
import { getAllProfessors } from "../../Requests/academic-user-requests";
import InternshipsList from "./InternshipsList";
import {
	getAllApplicationTypesCount,
	getCompanyInternships,
	getCoordinatedInternships,
	getInternshipsFilters,
	getProfessorInternships,
} from "../../utils/reactQueriesConstants";
import useQueryFilters from "../../Hooks/useQueryFilters";
import Filters from "../Filters";
import { PlusCircleOutlined } from "@ant-design/icons";

const CompanyInternships = (props: any) => {
	const { t } = useTranslation();
	const [pageSize, setPageSize] = useState(12);
	const [currentPage, setCurrentPage] = useState(1);
	const [sortField, setSortField] = useState(
		InternshipSortFieldEnum.PublishDate
	);
	const [sortDirection, setSortDirection] = useState(
		SortDirectionEnum.Descending
	);
	const [locations, setLocations] = useState([]);
	const [types, setTypes] = useState([] as any);
	const [statuses, setStatuses] = useState([]);
	const [states, setStates] = useState([]);
	const [universityYears, setUniversityYears] = useState([]);
	const [searchTerm, setSearchTerm] = useState("");
	const [checkedKeys, setCheckedKeys] = useState([]);
	const [professorOptions, setProfessorOptions] = useState([] as any);
	const isProfessor = useIsProfessor();
	const isStudent = useIsStudent();
	const isFacultyAdmin = useIsFacultyAdmin();
	const queryClient = useQueryClient();
	const history = useHistory();

	const query = useQueryFilters({
		initialValues: {
			UniversityYear: [
				getCurrentUniversityYear() + "-" + (getCurrentUniversityYear() + 1),
			],
		},
	});

	const handleListChange = async (current: number, pageSize: number) => {
		setCurrentPage(() => current);
		setPageSize(() => pageSize);
		await queryClient.invalidateQueries(getInternshipsFilters);
		await queryClient.invalidateQueries(getCompanyInternships);
		await queryClient.invalidateQueries(getProfessorInternships);
		await queryClient.invalidateQueries(getCoordinatedInternships);
		await queryClient.invalidateQueries(getAllApplicationTypesCount);
	};

	const openGetErrorNotification = (_error: any) => {
		console.error(_error);
		openNotification(
			t("internships.error"),
			t("internships.fetchDatasError"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const openFilterErrorNotification = (_error: any) => {
		openNotification(
			t("internships.error"),
			t("internships.filterDataError"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const openNotificationErrorFetchUsers = (ex: any) => {
		if (ex.status) {
			openNotification(
				t("usersText.errorTexts.failedUserGet"),
				t("usersText.errorTexts.serverFailedDescription"),
				NOTIFICATION_TYPES.ERROR
			);
		} else {
			openNotification(
				t("usersText.errorTexts.failedUserGet"),
				t("usersText.errorTexts.networkFailedDescription"),
				NOTIFICATION_TYPES.ERROR
			);
		}
	};

	const { data: filters } = useQuery(
		[getInternshipsFilters],
		() => {
			return getAllCompanyInternshipFilters();
		},
		{
			onError: openFilterErrorNotification,
			refetchOnWindowFocus: false,
		}
	);

	const { data: companyInternships, isLoading: loadingCompanyInternships } =
		useQuery(
			[
				getCompanyInternships,
				currentPage,
				pageSize,
				sortField,
				sortDirection,
				types,
				locations,
				statuses,
				states,
				universityYears,
				searchTerm,
				isFacultyAdmin,
				query.filters,
			],
			() => {
				if (isFacultyAdmin) {
					getAllProfessors(searchTerm, currentPage, pageSize)
						.then((response: any) => {
							setProfessorOptions(() => {
								return response.data;
							});
						})
						.catch((ex) => {
							openNotificationErrorFetchUsers(ex);
						});
					return getAllCompanyInternshipV2(query.filters);
				} else {
					return getAllCompanyInternshipV2(query.filters);
				}
			},
			{
				onError: openGetErrorNotification,
				refetchOnWindowFocus: false,
			}
		);

	const onUpdate = useCallback(
		(values: { [key: string]: string[] }) => {
			query.update(values, true);
		},
		[query]
	);

	const onPageUpdate = useCallback(
		(page: number, pageSize: number) => {
			query.update({
				page: page.toString(),
				pageSize: pageSize.toString(),
			});
		},
		[query]
	);

	const goToAction = useCallback(() => {
		history.push("/editare-propunere/", {
			filters: checkedKeys,
			searchTerm: searchTerm,
			sortField: sortField,
			sortDirection: sortDirection,
			currentPage: currentPage,
			pageSize: pageSize,
		});
	}, [
		checkedKeys,
		currentPage,
		history,
		pageSize,
		searchTerm,
		sortDirection,
		sortField,
	]);

	return (
		<div className="px-4 py-10 flex flex-col relative">
			{!isStudent && !isProfessor && (
				<div className="fixed bottom-10 md:relative md:bottom-auto md:self-end md:mt-10 right-10 z-50">
					<Button
						type="primary"
						className="p-6 flex flex-col md:flex-row justify-center items-center rounded-full md:rounded-md relative"
						onClick={goToAction}
					>
						<PlusCircleOutlined />

						{t("internships.addInternship")}
					</Button>
				</div>
			)}

			<Filters
				filters={filters}
				searchFields={[{ name: "searchTerm", label: t("internships.search") }]}
				onUpdate={onUpdate}
				hasSort={true}
			/>

			<div className="flex flex-col gap-10 relative">
				<div className="flex flex-col md:flex-row justify-between items-center">
					<Typography.Title level={3} className="my-0">
						{companyInternships?.totalCount} {t("filtering.results")}
					</Typography.Title>
					{!loadingCompanyInternships && (
						<Pagination
							defaultCurrent={companyInternships?.page}
							total={companyInternships?.totalCount}
							onChange={onPageUpdate}
							locale={{ items_per_page: t("pagination") }}
							pageSizeOptions={[12, 24, 48, 96]}
							defaultPageSize={12}
						/>
					)}
				</div>

				<InternshipsList
					data={companyInternships}
					professorOptions={professorOptions}
					checkedKeys={checkedKeys}
					loading={loadingCompanyInternships}
					location={props.location}
					activeTab={""}
					currentPage={currentPage}
					pageSize={pageSize}
					searchTerm={searchTerm}
					sortDirection={sortDirection}
					sortField={sortField}
					handleListChange={handleListChange}
				/>
			</div>
		</div>
	);
};

export default CompanyInternships;
