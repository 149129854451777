import { Avatar, Col, List, Row, Spin } from "antd";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import styles from "./PreviousEnrollmentsView.module.scss";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../../Notifications/NotificationsUtils";
import { getPreviousEnrollmentsData } from "../../../utils/reactQueriesConstants";
import { getPreviousEnrollments } from "../../../Requests/internship-requests";
import {
	EnrollmentStatus,
	InternshipEnrollmentDTO,
	InternshipType,
	Role,
} from "../../../Api";
import InternshipActions from "../../Internships/InternshipActions";
import { PreviousEnrollmentsViewProps } from "./PreviousEnrollmentsView.types";

export const PreviousEnrollmentsView = (
	props: PreviousEnrollmentsViewProps
) => {
	const { t } = useTranslation();
	const [pageSize, setPageSize] = useState(10);
	const [currentPage, setCurrent] = useState(1);
	const location = useLocation();

	let locale = {
		emptyText: t("tableText.noPreviousEnrollments"),
	};

	const openGeEnrollmentsErrorNotification = (_error: any) => {
		openNotification(
			t("internships.error"),
			t("internships.fetchEnrollmentsError"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const { data, isLoading: loading } = useQuery(
		[getPreviousEnrollmentsData, currentPage, pageSize, props.pathname],
		() =>
			getPreviousEnrollments(
				undefined,
				undefined,
				undefined,
				undefined,
				undefined,
				[EnrollmentStatus.Accepted],
				[EnrollmentStatus.Accepted],
				currentPage,
				pageSize
			),
		{
			onError: openGeEnrollmentsErrorNotification,
		}
	);

	const handleListChange = (current: number, pageSize: number) => {
		setCurrent(() => current);
		setPageSize(() => pageSize);
	};

	return (
		<Row>
			<Col span={24}>
				<Spin size="large" spinning={loading}>
					{data && (
						<List
							locale={locale}
							itemLayout="vertical"
							size="large"
							pagination={{
								total: data?.totalCount,
								current: currentPage,
								pageSize: data?.pageSize,
								pageSizeOptions: ["10", "20", "50"],
								showSizeChanger: true,
								locale: { items_per_page: t("configuration.pagination") },
								position: "both",
								hideOnSinglePage: data?.totalCount
									? data.totalCount <= 10
									: true,
								onChange: handleListChange,
							}}
							loading={loading}
							dataSource={data?.data || []}
							renderItem={(item: InternshipEnrollmentDTO) => (
								<List.Item
									className={styles.ListItem}
									key={item.id}
									actions={[<InternshipActions item={item} activeTab={""} />]}
								>
									<List.Item.Meta
										className={styles.listItemMeta}
										avatar={
											<Link
												to={{
													pathname: `/profil`,
													state: {
														id: item.companyName
															? item.companyId
															: item.professorId,
														type: item.companyName
															? InternshipType.ByCompany
															: InternshipType.ByProfessor,
														userType: item.companyName
															? Role.Company
															: Role.Professor,
														origin: location.pathname,
													},
												}}
											>
												<Row>
													<Col span={24} className={styles.customCol}>
														<Avatar
															src={item.companyLogo || item.professorHeadshot}
														/>
													</Col>
													<Col span={24} className={styles.customCol}>
														<p>{item.companyName || item.professorName}</p>
													</Col>
												</Row>
											</Link>
										}
										title={
											<Link
												to={{
													pathname: "/propunere/" + item.id,
													search:
														"?type=" +
														(item.companyName
															? InternshipType.ByCompany
															: InternshipType.ByProfessor),
													state: {
														id: item.id,
														type: item.companyName
															? InternshipType.ByCompany
															: InternshipType.ByProfessor,
														origin: location.pathname,
													},
												}}
											>
												{item.internshipName}
											</Link>
										}
										description={item.skillsRequired?.join(", ")}
									/>
									{item.shortDescription}
								</List.Item>
							)}
						/>
					)}
				</Spin>
			</Col>
		</Row>
	);
};
