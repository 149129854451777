/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum EmailPriority {
	Low = "Low",
	Normal = "Normal",
	High = "High",
}

export function EmailPriorityFromJSON(json: any): EmailPriority {
	return EmailPriorityFromJSONTyped(json, false);
}

export function EmailPriorityFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): EmailPriority {
	return json as EmailPriority;
}

export function EmailPriorityToJSON(value?: EmailPriority | null): any {
	return value as any;
}
