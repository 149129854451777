import { ReactNode } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";

export default function SimpleLayout({ children }: { children: ReactNode }) {
	return (
		<div className="flex flex-col overflow-hidden min-h-[100vh]">
			<div className="flex justify-end p-4">
				<Navbar />
			</div>

			<div className="grow flex flex-col pt-20">{children}</div>

			<Footer />
		</div>
	);
}
