import {
	PoliJobsPotentialCandidateApi,
	PoliJobsProposalCandidateFiltersDTO,
} from "../Api";
import { getAuthConfiguration } from "./configuration";

const AuthPoliJobsPotentialCandidateApiFactory = () =>
	new PoliJobsPotentialCandidateApi(getAuthConfiguration());

export interface PaginationProps {
	page?: number; //page
	pageSize?: number; //pageSize
}

export const getRecommendationsForPoliJobsProposal = (
	poliJobsProposalId: string,
	pagination: PaginationProps
) => {
	const { page, pageSize } = pagination;

	return AuthPoliJobsPotentialCandidateApiFactory().apiPoliJobsPotentialCandidateGetRecommendationsForProposalPoliJobsProposalIdGet(
		{
			poliJobsProposalId,
			page,
			pageSize,
		}
	);
};

export const generateRecommendationsForPoliJobsProposal = (
	poliJobsProposalId: string,
	poliJobsProposalCandidateFiltersDTO: PoliJobsProposalCandidateFiltersDTO
) => {
	return AuthPoliJobsPotentialCandidateApiFactory().apiPoliJobsPotentialCandidateGenerateRecommendationsForProposalPoliJobsProposalIdPut(
		{
			poliJobsProposalId,
			poliJobsProposalCandidateFiltersDTO,
		}
	);
};

export const acceptRecommendation = (id: string) => {
	return AuthPoliJobsPotentialCandidateApiFactory().apiPoliJobsPotentialCandidateAcceptRecommendationIdPut(
		{
			id,
		}
	);
};

export const invalidateRecommendation = (id: string) => {
	return AuthPoliJobsPotentialCandidateApiFactory().apiPoliJobsPotentialCandidateInvalidateRecommendationIdPut(
		{
			id,
		}
	);
};
