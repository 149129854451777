import { useHistory } from "react-router-dom";
import image from "../../Media/not-found.jpg";
import { useTranslation } from "react-i18next";
import styles from "./ErrorPage.module.scss";
import { Button, Col, Row } from "antd";

const NotFoundPage = () => {
	const { t } = useTranslation();
	const history = useHistory();

	const handleClick = () => history.push("/");

	return (
		<div className={styles.wrapper}>
			<Row>
				<Col
					span={window.innerWidth > 1215 ? 11 : 24}
					style={{ display: "flex", justifyContent: "center" }}
				>
					<div className={styles.container}>
						<h1>{t("home.messages.notFoundPage")}</h1>
						<Button type="primary" onClick={handleClick}>
							{t("header.home")}
						</Button>
					</div>
				</Col>
				<Col
					span={window.innerWidth > 1215 ? 11 : 24}
					style={{ display: "flex" }}
				>
					<div>
						<img
							alt={"img2"}
							src={image}
							style={{
								width: window.innerWidth > 1215 ? "40rem" : "20rem",
								height: window.innerWidth > 1215 ? "40rem" : "20rem",
							}}
						/>
					</div>
				</Col>
			</Row>
		</div>
	);
};
export default NotFoundPage;
