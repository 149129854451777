import {
	PracticeNotebookAddDTO,
	PracticeNotebookUpdateDTO,
	PracticeNotebookApi,
} from "../Api";
import { getAuthConfiguration } from "./configuration";

const AuthPracticeNotebookApiFactory = () =>
	new PracticeNotebookApi(getAuthConfiguration());

export const getPracticeNotebookForInternshipEnrollment = (
	internshipEnrollmentId: string
) => {
	return AuthPracticeNotebookApiFactory().apiPracticeNotebookGetForEnrollmentInternshipEnrollmentIdGet(
		{ internshipEnrollmentId }
	);
};

export const addPracticeNotebook = async (
	practiceNotebookAddDTO: PracticeNotebookAddDTO
) => {
	await AuthPracticeNotebookApiFactory().apiPracticeNotebookAddPost({
		practiceNotebookAddDTO,
	});
};

export const updatePracticeNotebook = async (
	id: string,
	practiceNotebookUpdateDTO: PracticeNotebookUpdateDTO
) => {
	await AuthPracticeNotebookApiFactory().apiPracticeNotebookUpdateIdPut({
		id,
		practiceNotebookUpdateDTO,
	});
};

export const generatePracticeNotebook = (id: string) => {
	return AuthPracticeNotebookApiFactory().apiPracticeNotebookGenerateNotebookIdGet(
		{ id }
	);
};

export const approvePracticeNotebook = async (id: string) => {
	await AuthPracticeNotebookApiFactory().apiPracticeNotebookApproveNotebookIdPut(
		{ id }
	);
};

export const rejectPracticeNotebook = async (id: string, comment: string) => {
	await AuthPracticeNotebookApiFactory().apiPracticeNotebookRejectNotebookIdPut(
		{ id, comment }
	);
};

export const downloadPracticeNotebook = (signatureId: string) => {
	return AuthPracticeNotebookApiFactory().apiPracticeNotebookDownloadFileSignatureIdGet(
		{ signatureId }
	);
};

export const addPracticeNotebookSignature = async (notebookId: string) => {
	await AuthPracticeNotebookApiFactory().apiPracticeNotebookAddSignatureNotebookIdPost(
		{ notebookId }
	);
};

export const uploadFinalizedPracticeNotebook = async (
	internshipEnrollmentId: string,
	file: Blob
) => {
	await AuthPracticeNotebookApiFactory().apiPracticeNotebookUploadFinalizedInternshipEnrollmentIdPost(
		{ internshipEnrollmentId, file }
	);
};
