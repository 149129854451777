import { AddUpdateCompanyFormModel } from "../models/AddUpdateCompanyFormModel";
import { CompanyAddDTO, CompanyDTO, CompanyUpdateDTO } from "../../../../Api";

export const toCompanyAddDomainModel = (
	model: AddUpdateCompanyFormModel
): CompanyAddDTO => ({
	name: model.name,
	commercialName: model.commercialName,
	cui: model.cui,
	address: model.address,
	phone: model.phone,
	domainOfActivity: model.domainOfActivity,
	fax: model.fax,
	email: model.emailContact,
});

export const toCompanyUpdateDomainModel = (
	model: AddUpdateCompanyFormModel
): CompanyUpdateDTO => ({
	commercialName: model.commercialName,
	address: model.address,
	phone: model.phone,
	domainOfActivity: model.domainOfActivity,
	fax: model.fax,
	emailContact: model.emailContact,
});

export const toFormModel = (
	record: CompanyDTO | null
): AddUpdateCompanyFormModel => ({
	name: record?.name ?? "",
	commercialName: record?.commercialName ?? "",
	cui: record?.cui ?? "",
	address: record?.address ?? "",
	phone: record?.phone ?? "",
	emailContact: record?.email ?? "",
	domainOfActivity: record?.domainOfActivity ?? "",
	fax: record?.fax ?? "",
});
