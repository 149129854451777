import { useTranslation } from "react-i18next";
import { ErrorCodes, ErrorMessage } from "../Api";

export const getErrorFromResponse = async (response: Response) => {
	if (
		response.headers.has("content-type") &&
		response.headers.get("content-type")?.includes("application/json")
	) {
		const result = await response.json();

		if (result.errorMessage) {
			return result.errorMessage as ErrorMessage;
		}
	}

	return null;
};

export const useErrorCodeTranslation = (error: ErrorCodes | null) => {
	const { t } = useTranslation();

	if (error === null) {
		return "";
	}

	switch (error) {
		case ErrorCodes.Unknown:
			return t("errors.unknown");
		case ErrorCodes.InternshipOverlaps:
			return t("errors.internshipOverlaps");
		case ErrorCodes.AlreadyEnrolled:
			return t("errors.alreadyEnrolled");
		case ErrorCodes.ApplicationCountExceeded:
			return t("errors.applicationCountExceeded");
		case ErrorCodes.CannotApplyAgain:
			return t("errors.cannotApplyAgain");
		case ErrorCodes.Forbidden:
			return t("errors.forbidden");
		case ErrorCodes.HadBachelorsProject:
			return t("errors.hadBachelorsProject");
		case ErrorCodes.HadMastersProject:
			return t("errors.hadMastersProject");
		case ErrorCodes.InternalError:
			return t("errors.internalError");
		case ErrorCodes.InternshipNotFound:
			return t("errors.internshipNotFound");
		case ErrorCodes.NoPositionsAvailable:
			return t("errors.noAvailablePositions");
		case ErrorCodes.Rejected:
			return t("errors.rejected");
		case ErrorCodes.IsViewOnly:
			return t("errors.isViewOnly");
		case ErrorCodes.UserNotFound:
			return t("errors.userNotFound");
		case ErrorCodes.OfferNotAvailableForFaculty:
			return t("errors.offerNotAvailableForFaculty");
		case ErrorCodes.OfferNotAvailableForStudyProgram:
			return t("errors.offerNotAvailableForStudyProgram");
		case ErrorCodes.OfferNotAvailableForYear:
			return t("errors.offerNotAvailableForYear");
		case ErrorCodes.OfferArchived:
			return t("errors.offerArchived");
		default:
			return "";
	}
};
