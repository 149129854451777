/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
	DepartmentDTO,
	DepartmentDTOFromJSON,
	DepartmentDTOToJSON,
} from "../models";

export interface ApiDepartmentGetAllForFacultiesGetRequest {
	faculties?: Array<string>;
}

/**
 *
 */
export class DepartmentApi extends runtime.BaseAPI {
	/**
	 */
	async apiDepartmentGetAllForFacultiesGetRaw(
		requestParameters: ApiDepartmentGetAllForFacultiesGetRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<Array<DepartmentDTO>>> {
		const queryParameters: any = {};

		if (requestParameters.faculties) {
			queryParameters["faculties"] = requestParameters.faculties;
		}

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/Department/GetAllForFaculties`,
				method: "GET",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			jsonValue.map(DepartmentDTOFromJSON)
		);
	}

	/**
	 */
	async apiDepartmentGetAllForFacultiesGet(
		requestParameters: ApiDepartmentGetAllForFacultiesGetRequest = {},
		initOverrides?: RequestInit
	): Promise<Array<DepartmentDTO>> {
		const response = await this.apiDepartmentGetAllForFacultiesGetRaw(
			requestParameters,
			initOverrides
		);
		return await response.value();
	}

	/**
	 */
	async apiDepartmentGetAllForFacultyGetRaw(
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<Array<DepartmentDTO>>> {
		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/Department/GetAllForFaculty`,
				method: "GET",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			jsonValue.map(DepartmentDTOFromJSON)
		);
	}

	/**
	 */
	async apiDepartmentGetAllForFacultyGet(
		initOverrides?: RequestInit
	): Promise<Array<DepartmentDTO>> {
		const response = await this.apiDepartmentGetAllForFacultyGetRaw(
			initOverrides
		);
		return await response.value();
	}
}
