/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface PracticalActivityDTO
 */
export interface PracticalActivityDTO {
	/**
	 *
	 * @type {string}
	 * @memberof PracticalActivityDTO
	 */
	week: string;
	/**
	 *
	 * @type {string}
	 * @memberof PracticalActivityDTO
	 */
	activity: string;
	/**
	 *
	 * @type {string}
	 * @memberof PracticalActivityDTO
	 */
	description: string;
}

export function PracticalActivityDTOFromJSON(json: any): PracticalActivityDTO {
	return PracticalActivityDTOFromJSONTyped(json, false);
}

export function PracticalActivityDTOFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): PracticalActivityDTO {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		week: json["week"],
		activity: json["activity"],
		description: json["description"],
	};
}

export function PracticalActivityDTOToJSON(
	value?: PracticalActivityDTO | null
): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		week: value.week,
		activity: value.activity,
		description: value.description,
	};
}
