import { Avatar, Button } from "antd";
import OfferActions from "./PoliJobsProposalActions";
import { isUserLogged, useIsPoliJobsAdmin } from "../../utils/utilFunctions";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { PoliJobsProposalDTO, Role } from "../../Api";
import { useTranslation } from "react-i18next";
import { PropsWithChildren } from "react";
import { OfferListProps } from "../../PropsLists/OfferListProps";
import { Routes } from "../../utils/routes";

const OffersList = (props: PropsWithChildren<OfferListProps>) => {
	const { t } = useTranslation();
	const isPoliJobsAdmin = useIsPoliJobsAdmin();

	return (
		<>
			{props.loading && !props.data && (
				<div className="w-full py-10 text-center">Loading</div>
			)}
			{props.data?.data.length === 0 && (
				<div className="w-full py-10 text-center">
					{t("tableText.noOffers")}
				</div>
			)}

			<div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 xxl:grid-cols-4 gap-4">
				{props.data?.data.map((item: PoliJobsProposalDTO) => (
					<div
						className="bg-white rounded-md shadow-md p-4 flex flex-col gap-4"
						key={item.id}
					>
						<div className="flex justify-between gap-6">
							<div className="flex flex-col gap-2">
								<Link
									to={{
										pathname: `/profil`,
										state: {
											id: item.companyId,
											userType: Role.Company,
											filters: props.checkedKeys,
											searchTerm: props.searchTerm,
											sortField: props.sortField,
											sortDirection: props.sortDirection,
											currentPage: props.currentPage,
											pageSize: props.pageSize,
											activeTab: props.activeTab,
											origin: props.location.pathname,
										},
									}}
								>
									<Avatar src={item.recruiterAvatar} />
									{item.recruiterName}
								</Link>

								<Link
									to={{
										pathname: "/propunere-polijobs/" + item.id,
										state: {
											id: item.id,
											filters: props.checkedKeys,
											searchTerm: props.searchTerm,
											sortField: props.sortField,
											sortDirection: props.sortDirection,
											currentPage: props.currentPage,
											pageSize: props.pageSize,
											activeTab: props.activeTab,
											origin: props.location.pathname,
										},
									}}
									className="text-lg text-secondary font-bold flex items-center gap-2 line-clamp-2 overflow-ellipsis"
								>
									<FontAwesomeIcon icon={solid("arrow-up-right-from-square")} />

									{item.poliJobsProposalName}
								</Link>
							</div>
						</div>

						<OfferActions item={item} />

						<div className="mt-auto">
							{isUserLogged() && (
								<>
									{isPoliJobsAdmin && props.activeTab === "1" ? (
										<Button title={t("polijobs.editOffer")} className="w-full">
											<Link
												to={{
													pathname: `${Routes.EDIT_POLIJOBS}/${item.id}`,
													state: {
														activeTab: props.activeTab,
														currentPage: props.currentPage,
														pageSize: props.pageSize,
														filters: props.checkedKeys,
														searchTerm: props.searchTerm,
														sortDirection: props.sortDirection,
														sortField: props.sortField,
													},
												}}
											>
												<FontAwesomeIcon icon={solid("edit")} /> &nbsp;
												{t("polijobs.editOffer")}
											</Link>
										</Button>
									) : null}
								</>
							)}
						</div>
					</div>
				))}
			</div>
		</>
	);
};

export default OffersList;
