/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum FeatureFlagsEnum {
	BachelorOptionalsChoosingEnabled = "BachelorOptionalsChoosingEnabled",
	BachelorAddChangeRequestsEnabled = "BachelorAddChangeRequestsEnabled",
	BachelorChangeRequestsResultsEnabled = "BachelorChangeRequestsResultsEnabled",
	BachelorOptionalsResultsEnabled = "BachelorOptionalsResultsEnabled",
	MastersOptionalsChoosingEnabled = "MastersOptionalsChoosingEnabled",
	MastersAddChangeRequestsEnabled = "MastersAddChangeRequestsEnabled",
	MastersViewResultsEnabled = "MastersViewResultsEnabled",
	StudentsFeedbackEnabled = "StudentsFeedbackEnabled",
	PhdFeedbackEnabled = "PhdFeedbackEnabled",
	ProfessorsFeedbackEnabled = "ProfessorsFeedbackEnabled",
	CompaniesFeedbackEnabled = "CompaniesFeedbackEnabled",
	AcademicUsersBachelorInternshipEnabled = "AcademicUsersBachelorInternshipEnabled",
	AcademicUsersMasterInternshipEnabled = "AcademicUsersMasterInternshipEnabled",
	AcademicUsersResearchInternshipEnabled = "AcademicUsersResearchInternshipEnabled",
	AcademicUsersJobEnabled = "AcademicUsersJobEnabled",
	AcademicUsersSummerSchoolEnabled = "AcademicUsersSummerSchoolEnabled",
	AcademicUsersScholarshipEnabled = "AcademicUsersScholarshipEnabled",
	AcademicUsersPracticeInternshipEnabled = "AcademicUsersPracticeInternshipEnabled",
	AcademicUsersEventEnabled = "AcademicUsersEventEnabled",
	CompaniesBachelorInternshipEnabled = "CompaniesBachelorInternshipEnabled",
	CompaniesMasterInternshipEnabled = "CompaniesMasterInternshipEnabled",
	CompaniesResearchInternshipEnabled = "CompaniesResearchInternshipEnabled",
	CompaniesJobEnabled = "CompaniesJobEnabled",
	CompaniesSummerSchoolEnabled = "CompaniesSummerSchoolEnabled",
	CompaniesScholarshipEnabled = "CompaniesScholarshipEnabled",
	CompaniesPracticeInternshipEnabled = "CompaniesPracticeInternshipEnabled",
	CompaniesEventEnabled = "CompaniesEventEnabled",
	PoliJobsOffersEnabled = "PoliJobsOffersEnabled",
}

export function FeatureFlagsEnumFromJSON(json: any): FeatureFlagsEnum {
	return FeatureFlagsEnumFromJSONTyped(json, false);
}

export function FeatureFlagsEnumFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): FeatureFlagsEnum {
	return json as FeatureFlagsEnum;
}

export function FeatureFlagsEnumToJSON(value?: FeatureFlagsEnum | null): any {
	return value as any;
}
