import {
	getUserId,
	isUserLogged,
	useIsDean,
	useIsDepartmentDirector,
	useIsDepartmentSecretary,
	useIsFacultyAdmin,
	useIsProfessor,
	useIsStudent,
} from "../../utils/utilFunctions";
import { useQuery, useQueryClient } from "react-query";
import { PropsWithChildren, useState } from "react";
import {
	changeStudyPrograms,
	deleteProfessorInternship,
	duplicateProfessorInternship,
	updateProfessorInternshipViewCountByInternshipId,
	updateValidation,
} from "../../Requests/professor-internship-requests";
import {
	acceptCoordination,
	approveInternship,
	approveInternshipStudyProgram,
	changeStudyProgramsCompany,
	deleteCompanyInternship,
	duplicateCompanyInternship,
	invalidateInternship,
	markInternshipAsComplete,
	rejectCoordination,
	rejectInternship,
	updateCompanyInternshipViewCountByInternshipId,
	updateFacultyCoordinator,
} from "../../Requests/company-internship-requests";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../Notifications/NotificationsUtils";
import { useTranslation } from "react-i18next";
import { enrollStudentToInternship } from "../../Requests/internship-requests";
import { InternshipDTO, InternshipType } from "../../Api";
import {
	getAvailableStudents,
	getProfessorById,
} from "../../Requests/academic-user-requests";
import {
	getAllApplicationTypesCount,
	getBachelorStudents,
	getCompanyInternships,
	getCoordinatedInternships,
	getDepartmentInternships,
	getDepartmentInternshipsFiltersForProfessor,
	getFacultiesInternshipsFiltersForProfessor,
	getFacultyInternships,
	getInternshipsFilters,
	getMasterStudents,
	getProfessorInternships,
	getStudyProgramInternships,
	getStudyProgramInternshipsFiltersForProfessor,
	updateCompanyInternshipViewCount,
	updateProfessorInternshipViewCount,
} from "../../utils/reactQueriesConstants";
import { InternshipListProps } from "../../PropsLists/InternshipListProps";
import { getStudyProgramsForFaculties } from "../../Requests/student-study-request";

export const useInternshipListController = (
	props: PropsWithChildren<InternshipListProps>
) => {
	const { t } = useTranslation();
	const isProfessor = useIsProfessor();
	const isStudent = useIsStudent();
	const isFacultyAdmin = useIsFacultyAdmin();
	const isDepartmentDirector = useIsDepartmentDirector();
	const isDepartmentSecretary = useIsDepartmentSecretary();
	const isDean = useIsDean();
	const queryClient = useQueryClient();
	const [selectedInternshipId, setSelectedInternshipId] = useState<string>("");
	const [isCompanyTrusted, setIsCompanyTrusted] = useState(false);
	const [markCompleteModalVisibility, setMarkCompleteModalVisibility] =
		useState(false);
	const [deleteModalVisibility, setDeleteModalVisibility] = useState(false);
	const [duplicateModalVisibility, setDuplicateModalVisibility] =
		useState(false);
	const [approveModalVisibility, setApproveModalVisibility] = useState(false);
	const [rejectModalVisibility, setRejectModalVisibility] = useState(false);
	const [
		acceptCoordinationModalVisibility,
		setAcceptCoordinationModalVisibility,
	] = useState(false);
	const [
		rejectCoordinationModalVisibility,
		setRejectCoordinationModalVisibility,
	] = useState(false);
	const [spinning, setSpinning] = useState(false);
	const [
		approveInternshipModalVisibility,
		setApproveInternshipModalVisibility,
	] = useState(false);

	const openErrorNotification = (_error: any) => {
		openNotification(
			t("internships.error"),
			t("internships.fetchDatasError"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const { data: availableBachelorStudents } = useQuery(
		[
			getBachelorStudents,
			isDepartmentSecretary,
			props.location.pathname,
			isProfessor,
			props.activeTab,
		],
		() => {
			if (isDepartmentSecretary || (isProfessor && props.activeTab === "1")) {
				return getAvailableStudents("bachelor");
			}
		},
		{
			onError: openErrorNotification,
		}
	);

	const { data: availableMasterStudents } = useQuery(
		[
			getMasterStudents,
			isDepartmentSecretary,
			props.location.pathname,
			isProfessor,
			props.activeTab,
		],
		() => {
			if (isDepartmentSecretary || (isProfessor && props.activeTab === "1")) {
				return getAvailableStudents("master");
			}
		},
		{
			onError: openErrorNotification,
		}
	);

	const updateViewCount = (internshipId: string, internshipType: string) => {
		if (isStudent && internshipType === InternshipType.ByCompany) {
			updateCompanyInternshipViewCountByInternshipId(internshipId)
				.then(async () => {
					await queryClient.invalidateQueries(updateCompanyInternshipViewCount);
				})
				.catch((_error) => {
					openErrorNotification(_error);
				});
		}

		if (isStudent && internshipType === InternshipType.ByProfessor) {
			updateProfessorInternshipViewCountByInternshipId(internshipId)
				.then(async () => {
					await queryClient.invalidateQueries(
						updateProfessorInternshipViewCount
					);
				})
				.catch((_error) => {
					openErrorNotification(_error);
				});
		}
	};

	const invalidateInternshipsQueries = async () => {
		await queryClient.invalidateQueries(getInternshipsFilters);
		await queryClient.invalidateQueries(getCompanyInternships);
		await queryClient.invalidateQueries(getProfessorInternships);
		await queryClient.invalidateQueries(getCoordinatedInternships);
		await queryClient.invalidateQueries(getAllApplicationTypesCount);
	};

	const deleteEntry = () => {
		setSpinning(true);

		(isUserLogged() && isProfessor
			? deleteProfessorInternship(selectedInternshipId)
			: deleteCompanyInternship(selectedInternshipId)
		)
			.then(async () => {
				await invalidateInternshipsQueries();
				openNotification(
					t("internships.deleteInternship"),
					t("internships.deleteInternshipSuccess"),
					NOTIFICATION_TYPES.SUCCESS
				);
			})
			.catch((_error) => {
				openNotification(
					t("internships.deleteInternship"),
					t("internships.deleteInternshipError"),
					NOTIFICATION_TYPES.ERROR
				);
			})
			.finally(() => {
				setSpinning(false);
				setDeleteModalVisibility(false);
			});
	};

	const duplicateEntry = () => {
		setSpinning(true);

		(isUserLogged() && isProfessor
			? duplicateProfessorInternship(selectedInternshipId)
			: duplicateCompanyInternship(selectedInternshipId)
		)
			.then(async () => {
				await invalidateInternshipsQueries();
				openNotification(
					t("internships.duplicateInternship"),
					t("internships.duplicateInternshipSuccess"),
					NOTIFICATION_TYPES.SUCCESS
				);
			})
			.catch((_error) => {
				openNotification(
					t("internships.duplicateInternship"),
					t("internships.duplicateInternshipError"),
					NOTIFICATION_TYPES.ERROR
				);
			})
			.finally(() => {
				setSpinning(false);
				setDuplicateModalVisibility(false);
			});
	};

	const sendForApproval = () => {
		setSpinning(true);

		markInternshipAsComplete(selectedInternshipId)
			.then(async () => {
				await invalidateInternshipsQueries();
				openNotification(
					isCompanyTrusted
						? t("internships.finalize")
						: t("internships.markComplete"),
					isCompanyTrusted
						? t("internships.finalizeSuccess")
						: t("internships.completeInternshipSuccess"),
					NOTIFICATION_TYPES.SUCCESS
				);
			})
			.catch((_error) => {
				openNotification(
					isCompanyTrusted
						? t("internships.finalize")
						: t("internships.markComplete"),
					isCompanyTrusted
						? t("internships.finalizeError")
						: t("internships.completeInternshipError"),
					NOTIFICATION_TYPES.ERROR
				);
			})
			.finally(() => {
				setSpinning(false);
				setMarkCompleteModalVisibility(false);
			});
	};

	const approve = () => {
		setSpinning(true);

		approveInternship(selectedInternshipId)
			.then(async () => {
				await invalidateInternshipsQueries();
				openNotification(
					t("internships.approveInternship"),
					t("internships.approveInternshipSuccess"),
					NOTIFICATION_TYPES.SUCCESS
				);
			})
			.catch((_error) => {
				openNotification(
					t("internships.approveInternship"),
					t("internships.approveInternshipError"),
					NOTIFICATION_TYPES.ERROR
				);
			})
			.finally(() => {
				setSpinning(false);
				setApproveModalVisibility(false);
			});
	};

	const reject = (item: any, description: string) => {
		setSpinning(true);

		rejectInternship(item.id, { message: description })
			.then(async () => {
				await invalidateInternshipsQueries();
				openNotification(
					t("internships.rejectInternship"),
					t("internships.rejectInternshipSuccess"),
					NOTIFICATION_TYPES.SUCCESS
				);
			})
			.catch((_error) => {
				openNotification(
					t("internships.rejectInternship"),
					t("internships.rejectInternshipError"),
					NOTIFICATION_TYPES.ERROR
				);
			})
			.finally(() => {
				setSpinning(false);
				setRejectModalVisibility(false);
			});
	};

	const acceptInternshipCoordination = () => {
		setSpinning(true);

		acceptCoordination(selectedInternshipId)
			.then(async () => {
				await invalidateInternshipsQueries();
				openNotification(
					t("internships.acceptCoordination"),
					t("internships.acceptCoordinationSuccess"),
					NOTIFICATION_TYPES.SUCCESS
				);
			})
			.catch((_error) => {
				openNotification(
					t("internships.acceptCoordination"),
					t("internships.acceptCoordinationError"),
					NOTIFICATION_TYPES.ERROR
				);
			})
			.finally(() => {
				setSpinning(false);
				setAcceptCoordinationModalVisibility(false);
			});
	};

	const rejectInternshipCoordination = () => {
		setSpinning(true);

		rejectCoordination(selectedInternshipId)
			.then(async () => {
				await invalidateInternshipsQueries();
				openNotification(
					t("internships.rejectCoordination"),
					t("internships.rejectCoordinationSuccess"),
					NOTIFICATION_TYPES.SUCCESS
				);
			})
			.catch((_error) => {
				openNotification(
					t("internships.rejectCoordination"),
					t("internships.rejectCoordinationError"),
					NOTIFICATION_TYPES.ERROR
				);
			})
			.finally(() => {
				setSpinning(false);
				setRejectCoordinationModalVisibility(false);
			});
	};

	const updateInternshipCoordinator = (
		coordinatorId: any,
		internshipId: any
	) => {
		const bodyReq = {
			facultyCoordinatorId: coordinatorId,
		};
		updateFacultyCoordinator(internshipId, bodyReq)
			.then(async () => {
				await queryClient.invalidateQueries(getCompanyInternships);
				await queryClient.invalidateQueries(getCoordinatedInternships);
				await queryClient.invalidateQueries(getInternshipsFilters);
				openNotification(
					t("admin.updateInternshipResponsible"),
					isProfessor && !isFacultyAdmin
						? t("admin.successAutoUpdatedInternshipResponsible")
						: t("admin.successUpdatedInternshipResponsible"),
					NOTIFICATION_TYPES.SUCCESS
				);
			})
			.catch((_error) => {
				openNotification(
					t("admin.updateInternshipResponsible"),
					isProfessor && !isFacultyAdmin
						? t("admin.errorAutoUpdatedInternshipResponsible")
						: t("admin.errorUpdatedInternshipResponsible"),
					NOTIFICATION_TYPES.ERROR
				);
			});
	};

	const updateStudyPrograms = (studyPrograms: any, id: any, type: string) => {
		if (type === "company") {
			changeStudyProgramsCompany(id, studyPrograms)
				.then(async () => {
					await queryClient.invalidateQueries(getCompanyInternships);
					await queryClient.invalidateQueries(getInternshipsFilters);
					openNotification(
						t("internships.updateStudyPrograms"),
						t("internships.successUpdatedStudyPrograms"),
						NOTIFICATION_TYPES.SUCCESS
					);
				})
				.catch((_error) => {
					openNotification(
						t("internships.updateStudyPrograms"),
						t("internships.errorUpdatedStudyPrograms"),
						NOTIFICATION_TYPES.ERROR
					);
				});
		} else if (type === "faculty") {
			changeStudyPrograms(id, studyPrograms)
				.then(async () => {
					await queryClient.invalidateQueries(getProfessorInternships);
					await queryClient.invalidateQueries(getCoordinatedInternships);
					await queryClient.invalidateQueries(getInternshipsFilters);
					openNotification(
						t("internships.updateStudyPrograms"),
						t("internships.successUpdatedStudyPrograms"),
						NOTIFICATION_TYPES.SUCCESS
					);
				})
				.catch((_error) => {
					openNotification(
						t("internships.updateStudyPrograms"),
						t("internships.errorUpdatedStudyPrograms"),
						NOTIFICATION_TYPES.ERROR
					);
				});
		}
	};

	const enrollStudent = (studentId: any, internshipId: any) => {
		enrollStudentToInternship(studentId, internshipId)
			.then(async () => {
				await queryClient.invalidateQueries(getProfessorInternships);
				await queryClient.invalidateQueries(getBachelorStudents);
				await queryClient.invalidateQueries(getMasterStudents);
				await queryClient.invalidateQueries(getAllApplicationTypesCount);
				openNotification(
					t("admin.enrollStudentMessage"),
					t("admin.successEnrollStudent"),
					NOTIFICATION_TYPES.SUCCESS
				);
			})
			.catch((_error) => {
				openNotification(
					t("admin.enrollStudentMessage"),
					t("admin.errorEnrollStudent"),
					NOTIFICATION_TYPES.ERROR
				);
			});
	};

	const onChangeProfessorInternshipValidation = (
		checked: boolean,
		item: InternshipDTO
	) => {
		updateValidation(item.id, checked)
			.then(async () => {
				if (isFacultyAdmin) {
					await queryClient.invalidateQueries(getFacultyInternships);
					await queryClient.invalidateQueries(
						getFacultiesInternshipsFiltersForProfessor
					);
				} else if (isDepartmentDirector) {
					await queryClient.invalidateQueries(getDepartmentInternships);
					await queryClient.invalidateQueries(
						getDepartmentInternshipsFiltersForProfessor
					);
				} else {
					await queryClient.invalidateQueries(getStudyProgramInternships);
					await queryClient.invalidateQueries(
						getStudyProgramInternshipsFiltersForProfessor
					);
				}
			})
			.catch(() => {
				if (checked) {
					openNotification(
						t("internships.approveInternship"),
						t("internships.approveInternshipError"),
						NOTIFICATION_TYPES.ERROR
					);
				} else {
					openNotification(
						t("internships.rejectInternship"),
						t("internships.rejectInternshipError"),
						NOTIFICATION_TYPES.ERROR
					);
				}
			});
	};

	const onChangeCompanyInternshipValidation = (
		checked: boolean,
		item: InternshipDTO
	) => {
		if (checked) {
			approveInternship(item.id)
				.then(async () => {
					await queryClient.invalidateQueries(getCompanyInternships);
					await queryClient.invalidateQueries(getInternshipsFilters);
					openNotification(
						t("internships.approveInternship"),
						t("internships.approveInternshipSuccess"),
						NOTIFICATION_TYPES.SUCCESS
					);
				})
				.catch((_error) => {
					openNotification(
						t("internships.approveInternship"),
						t("internships.approveInternshipError"),
						NOTIFICATION_TYPES.ERROR
					);
				});
		} else {
			invalidateInternship(item.id)
				.then(async () => {
					await queryClient.invalidateQueries(getCompanyInternships);
					await queryClient.invalidateQueries(getInternshipsFilters);
					openNotification(
						t("internships.rejectInternship"),
						t("internships.rejectInternshipSuccess"),
						NOTIFICATION_TYPES.SUCCESS
					);
				})
				.catch((_error) => {
					openNotification(
						t("internships.rejectInternship"),
						t("internships.rejectInternshipError"),
						NOTIFICATION_TYPES.ERROR
					);
				})
				.finally(() => {
					setSpinning(false);
					setRejectModalVisibility(false);
				});
		}
	};

	const approveInternshipForStudyPrograms = () => {
		setSpinning(true);
		approveInternshipStudyProgram(selectedInternshipId)
			.then(async () => {
				await queryClient.invalidateQueries(getCompanyInternships);
				openNotification(
					t("internships.approveInternshipForStudyPrograms"),
					t("internships.approveInternshipForStudyProgramsSuccess"),
					NOTIFICATION_TYPES.SUCCESS
				);
			})
			.catch((_error) => {
				openNotification(
					t("internships.approveInternshipForStudyPrograms"),
					t("internships.approveInternshipForStudyProgramsError"),
					NOTIFICATION_TYPES.ERROR
				);
			})
			.finally(() => {
				setSpinning(false);
				setApproveInternshipModalVisibility(false);
			});
	};

	return {
		state: {
			availableBachelorStudents,
			availableMasterStudents,
			selectedInternshipId,
			isCompanyTrusted,
			markCompleteModalVisibility,
			deleteModalVisibility,
			duplicateModalVisibility,
			approveModalVisibility,
			rejectModalVisibility,
			acceptCoordinationModalVisibility,
			rejectCoordinationModalVisibility,
			approveInternshipModalVisibility,
			spinning,
		},
		actions: {
			updateViewCount,
			deleteEntry,
			duplicateEntry,
			sendForApproval,
			approve,
			reject,
			acceptInternshipCoordination,
			rejectInternshipCoordination,
			updateInternshipCoordinator,
			enrollStudent,
			onChangeProfessorInternshipValidation,
			onChangeCompanyInternshipValidation,
			setSelectedInternshipId,
			setIsCompanyTrusted,
			setMarkCompleteModalVisibility,
			setDeleteModalVisibility,
			setDuplicateModalVisibility,
			setApproveModalVisibility,
			setAcceptCoordinationModalVisibility,
			setRejectCoordinationModalVisibility,
			updateStudyPrograms,
			approveInternshipForStudyPrograms,
			setApproveInternshipModalVisibility,
		},
	};
};
