/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
	FeatureFlagsEnum,
	FeatureFlagsEnumFromJSON,
	FeatureFlagsEnumFromJSONTyped,
	FeatureFlagsEnumToJSON,
} from "./FeatureFlagsEnum";

/**
 *
 * @export
 * @interface UpdateFacultyFeatureFlagRequestDTO
 */
export interface UpdateFacultyFeatureFlagRequestDTO {
	/**
	 *
	 * @type {FeatureFlagsEnum}
	 * @memberof UpdateFacultyFeatureFlagRequestDTO
	 */
	flag?: FeatureFlagsEnum;
	/**
	 *
	 * @type {Array<string>}
	 * @memberof UpdateFacultyFeatureFlagRequestDTO
	 */
	facultyIds?: Array<string> | null;
}

export function UpdateFacultyFeatureFlagRequestDTOFromJSON(
	json: any
): UpdateFacultyFeatureFlagRequestDTO {
	return UpdateFacultyFeatureFlagRequestDTOFromJSONTyped(json, false);
}

export function UpdateFacultyFeatureFlagRequestDTOFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): UpdateFacultyFeatureFlagRequestDTO {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		flag: !exists(json, "flag")
			? undefined
			: FeatureFlagsEnumFromJSON(json["flag"]),
		facultyIds: !exists(json, "facultyIds") ? undefined : json["facultyIds"],
	};
}

export function UpdateFacultyFeatureFlagRequestDTOToJSON(
	value?: UpdateFacultyFeatureFlagRequestDTO | null
): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		flag: FeatureFlagsEnumToJSON(value.flag),
		facultyIds: value.facultyIds,
	};
}
