/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
	PracticeNotebook,
	PracticeNotebookFromJSON,
	PracticeNotebookFromJSONTyped,
	PracticeNotebookToJSON,
} from "./PracticeNotebook";
import {
	PracticeNotebookSigningEnum,
	PracticeNotebookSigningEnumFromJSON,
	PracticeNotebookSigningEnumFromJSONTyped,
	PracticeNotebookSigningEnumToJSON,
} from "./PracticeNotebookSigningEnum";

/**
 *
 * @export
 * @interface PracticeNotebookSignature
 */
export interface PracticeNotebookSignature {
	/**
	 *
	 * @type {string}
	 * @memberof PracticeNotebookSignature
	 */
	id?: string;
	/**
	 *
	 * @type {Date}
	 * @memberof PracticeNotebookSignature
	 */
	createdAt?: Date;
	/**
	 *
	 * @type {Date}
	 * @memberof PracticeNotebookSignature
	 */
	updatedAt?: Date;
	/**
	 *
	 * @type {string}
	 * @memberof PracticeNotebookSignature
	 */
	practiceNotebookId?: string;
	/**
	 *
	 * @type {PracticeNotebookSigningEnum}
	 * @memberof PracticeNotebookSignature
	 */
	signer?: PracticeNotebookSigningEnum;
	/**
	 *
	 * @type {string}
	 * @memberof PracticeNotebookSignature
	 */
	fileName?: string | null;
	/**
	 *
	 * @type {PracticeNotebook}
	 * @memberof PracticeNotebookSignature
	 */
	practiceNotebook?: PracticeNotebook;
}

export function PracticeNotebookSignatureFromJSON(
	json: any
): PracticeNotebookSignature {
	return PracticeNotebookSignatureFromJSONTyped(json, false);
}

export function PracticeNotebookSignatureFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): PracticeNotebookSignature {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		id: !exists(json, "id") ? undefined : json["id"],
		createdAt: !exists(json, "createdAt")
			? undefined
			: new Date(json["createdAt"]),
		updatedAt: !exists(json, "updatedAt")
			? undefined
			: new Date(json["updatedAt"]),
		practiceNotebookId: !exists(json, "practiceNotebookId")
			? undefined
			: json["practiceNotebookId"],
		signer: !exists(json, "signer")
			? undefined
			: PracticeNotebookSigningEnumFromJSON(json["signer"]),
		fileName: !exists(json, "fileName") ? undefined : json["fileName"],
		practiceNotebook: !exists(json, "practiceNotebook")
			? undefined
			: PracticeNotebookFromJSON(json["practiceNotebook"]),
	};
}

export function PracticeNotebookSignatureToJSON(
	value?: PracticeNotebookSignature | null
): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		id: value.id,
		createdAt:
			value.createdAt === undefined ? undefined : value.createdAt.toISOString(),
		updatedAt:
			value.updatedAt === undefined ? undefined : value.updatedAt.toISOString(),
		practiceNotebookId: value.practiceNotebookId,
		signer: PracticeNotebookSigningEnumToJSON(value.signer),
		fileName: value.fileName,
		practiceNotebook: PracticeNotebookToJSON(value.practiceNotebook),
	};
}
