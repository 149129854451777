/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
	StudentYearEnum,
	StudentYearEnumFromJSON,
	StudentYearEnumFromJSONTyped,
	StudentYearEnumToJSON,
} from "./StudentYearEnum";

/**
 *
 * @export
 * @interface PracticeInternshipPeriodDTO
 */
export interface PracticeInternshipPeriodDTO {
	/**
	 *
	 * @type {string}
	 * @memberof PracticeInternshipPeriodDTO
	 */
	id?: string;
	/**
	 *
	 * @type {Date}
	 * @memberof PracticeInternshipPeriodDTO
	 */
	practiceEvaluationStart?: Date;
	/**
	 *
	 * @type {Date}
	 * @memberof PracticeInternshipPeriodDTO
	 */
	practiceEvaluationEnd?: Date;
	/**
	 *
	 * @type {number}
	 * @memberof PracticeInternshipPeriodDTO
	 */
	practiceNumberOfHours?: number;
	/**
	 *
	 * @type {StudentYearEnum}
	 * @memberof PracticeInternshipPeriodDTO
	 */
	practiceYear?: StudentYearEnum;
}

export function PracticeInternshipPeriodDTOFromJSON(
	json: any
): PracticeInternshipPeriodDTO {
	return PracticeInternshipPeriodDTOFromJSONTyped(json, false);
}

export function PracticeInternshipPeriodDTOFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): PracticeInternshipPeriodDTO {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		id: !exists(json, "id") ? undefined : json["id"],
		practiceEvaluationStart: !exists(json, "practiceEvaluationStart")
			? undefined
			: new Date(json["practiceEvaluationStart"]),
		practiceEvaluationEnd: !exists(json, "practiceEvaluationEnd")
			? undefined
			: new Date(json["practiceEvaluationEnd"]),
		practiceNumberOfHours: !exists(json, "practiceNumberOfHours")
			? undefined
			: json["practiceNumberOfHours"],
		practiceYear: !exists(json, "practiceYear")
			? undefined
			: StudentYearEnumFromJSON(json["practiceYear"]),
	};
}

export function PracticeInternshipPeriodDTOToJSON(
	value?: PracticeInternshipPeriodDTO | null
): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		id: value.id,
		practiceEvaluationStart:
			value.practiceEvaluationStart === undefined
				? undefined
				: value.practiceEvaluationStart.toISOString(),
		practiceEvaluationEnd:
			value.practiceEvaluationEnd === undefined
				? undefined
				: value.practiceEvaluationEnd.toISOString(),
		practiceNumberOfHours: value.practiceNumberOfHours,
		practiceYear: StudentYearEnumToJSON(value.practiceYear),
	};
}
