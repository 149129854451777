import { CoordinatorSelectProps } from "./CoordinatorSelect.types";
import { useTranslation } from "react-i18next";
import { Form, Select, Spin } from "antd";
import styles from "../../../AddInternshipForm.module.scss";
import {
	CompanyInternshipState,
	InternshipEngagementType,
} from "../../../../../../Api";
import { useQuery } from "react-query";
import { getAllFacultyProfessors } from "../../../../../../utils/reactQueriesConstants";
import { getAllProfessors } from "../../../../../../Requests/academic-user-requests";
import useDebounce from "../../../../../../Hooks/debounce";
import { useState } from "react";

const { Option } = Select;

export const CoordinatorSelect = (props: CoordinatorSelectProps) => {
	const { t } = useTranslation();
	const [coordinatorSearch, setCoordinatorSearch] = useState("");
	const debouncedCoordinatorSearch = useDebounce(coordinatorSearch, 1000);

	const { data: facultyProfessors, isLoading: isLoadingFacultyProfessors } =
		useQuery(
			[
				getAllFacultyProfessors,
				props.isCompany,
				props.isAdmin,
				props.engagementTypeValue,
				props.internship,
				debouncedCoordinatorSearch,
			],
			async () => {
				if (!props.isCompany && !props.isAdmin) {
					return null;
				}

				if (
					!props.isAdmin &&
					props.engagementTypeValue !==
						InternshipEngagementType.BachelorsInternship &&
					props.engagementTypeValue !==
						InternshipEngagementType.MastersInternship
				) {
					return null;
				}

				return getAllProfessors(debouncedCoordinatorSearch, 1, 25).then((e) =>
					e.data?.map((e) => {
						return { id: e.id, name: e.name };
					})
				);
			}
		);

	return (
		<Form.Item
			label={t("internships.addInternshipForm.coordinator") + ":"}
			name="coordinator"
			style={{ marginTop: "-10px", marginBottom: "10px" }}
		>
			<Select
				className={styles.antSelect}
				disabled={
					props.isEditing &&
					props.internship?.state !== CompanyInternshipState.Draft
				}
				allowClear
				showSearch
				style={{ width: "100%", textAlign: "left" }}
				placeholder={t("internships.addInternshipForm.coordinator")}
				filterOption={false}
				onChange={props.handleFacultyCoordinatorChange}
				onSearch={setCoordinatorSearch}
				notFoundContent={
					isLoadingFacultyProfessors ? <Spin size="small" /> : null
				}
			>
				{facultyProfessors?.map((user) => (
					<Option key={user.id} value={user.id}>
						{user.name}
					</Option>
				))}
			</Select>
		</Form.Item>
	);
};
