import { AddEventFormProps } from "./AddEventForm.types";
import { Col, DatePicker, Form, Input, Row, Select } from "antd";
import { useTranslation } from "react-i18next";
import styles from "../../../AddInternshipForm.module.scss";
import CustomForm from "../../../../../../CustomComponents/CustomForm";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../../../../../Notifications/NotificationsUtils";
import { theme } from "../../../../../../theme";
import {
	companyInternshipTypes,
	professorInternshipTypes,
	professorInternshipTypesACS,
	userSkills,
} from "../../../../../../utils/constants";
import {
	CompanyInternshipState,
	InternshipEngagementType,
} from "../../../../../../Api";
import { StudyProgramsSelect } from "../../formItems/StudyProgramsSelect/StudyProgramsSelect";
import { StudentYearsSelect } from "../../formItems/StudentYearsSelect/StudentYearsSelect";
import CustomEditor from "../../../../../../CustomComponents/CustomEditor";
import { CoordinatingProfessorSelect } from "../../formItems/CoordinatingProfessorSelect/CoordinatingProfessorSelect";
import { CompanyUsersSelect } from "../../formItems/CompanyUsersSelect/CompanyUsersSelect";
import CustomButton from "../../../../../../CustomComponents/CustomButton";
import ConfirmationModal from "../../../../../../Containers/ConfirmationModal";
import { useCallback } from "react";

const { Option } = Select;
const dateFormatListEvent = "YYYY-MM-DD hh:mm";

export const AddEventForm = (props: AddEventFormProps) => {
	const { t } = useTranslation();

	const getRow1Span = useCallback(
		(size: string) => {
			switch (size) {
				case "xs":
				case "sm":
				case "md":
					return 24;
				default:
					return props.isCompany ? 8 : 12;
			}
		},
		[props.isCompany]
	);

	const getRow2Span = useCallback((size: string) => {
		switch (size) {
			case "xs":
			case "sm":
			case "md":
				return 24;
			default:
				return 12;
		}
	}, []);

	return (
		<>
			{props.isCompany && props.facultiesWithCompanyAccordList?.length === 0 ? (
				<h3 style={{ marginTop: "2rem" }}>
					{t("internships.addInternshipForm.attentionMessage")}
				</h3>
			) : null}
			<div className={styles.container}>
				<CustomForm
					form={props.form}
					onFinish={() => {
						props.setSaveModalVisibility(true);
					}}
					onFinishFailed={() =>
						openNotification(
							t("internships.addInternshipForm.addInternship"),
							t("usersText.requiredFieldsError"),
							NOTIFICATION_TYPES.ERROR
						)
					}
					layout={theme.layout}
					action="/propuneri"
					boxshadow={"none"}
				>
					<div className={styles.firstLine}>
						<Row gutter={8}>
							<Col
								xs={getRow1Span("xs")}
								sm={getRow1Span("sm")}
								md={getRow1Span("md")}
								lg={getRow1Span("lg")}
								xl={getRow1Span("xl")}
							>
								<Form.Item
									required={props.facultySingleSelect}
									name="engagementType"
									label={t("internships.addInternshipForm.type") + ":"}
									rules={[props.yupSync]}
								>
									<Select
										className={styles.antSelect}
										disabled={props.isEditing}
										allowClear
										style={{ width: "100%", textAlign: "left" }}
										placeholder={t("internships.addInternshipForm.type")}
										onChange={props.handleEngagementTypeChange}
										notFoundContent={t(
											"internships.addInternshipForm.notFoundContent"
										)}
									>
										{props.isCompany
											? companyInternshipTypes.map((type) => (
													<Option
														key={type}
														value={type}
														disabled={
															props.facultiesWithCompanyAccordList?.length ===
																0 &&
															(type === InternshipEngagementType.Internship ||
																type === InternshipEngagementType.Scholarship)
														}
													>
														{t("internships.filters." + type)}
													</Option>
											  ))
											: props.defaultFacultyName ===
											  "Facultatea de Automatică și Calculatoare"
											? professorInternshipTypesACS.map((type) => (
													<Option key={type} value={type}>
														{t("internships.filters." + type)}
													</Option>
											  ))
											: professorInternshipTypes.map((type) => (
													<Option key={type} value={type}>
														{t("internships.filters." + type)}
													</Option>
											  ))}
									</Select>
								</Form.Item>
							</Col>
							{props.isCompany && (
								<Col
									xs={getRow1Span("xs")}
									sm={getRow1Span("sm")}
									md={getRow1Span("md")}
									lg={getRow1Span("lg")}
									xl={getRow1Span("xl")}
								>
									<Form.Item
										required={props.facultySingleSelect}
										name="faculties"
										label={
											(props.facultySingleSelect
												? t("internships.addInternshipForm.faculty")
												: t("internships.addInternshipForm.faculties")) + ":"
										}
										tooltip={
											props.engagementTypeValue ===
											InternshipEngagementType.Internship
												? t(
														"internships.addInternshipForm.selectFacultyForInternship"
												  )
												: !props.facultySingleSelect
												? t("internships.addInternshipForm.facultyHelper")
												: t("internships.addInternshipForm.selectActiveFaculty")
										}
										rules={[
											{
												required: props.facultySingleSelect,
												message: props.facultySingleSelect
													? t("internships.addInternshipForm.requiredFaculty")
													: "",
											},
										]}
									>
										<Select
											className={styles.antSelect}
											mode={props.facultySingleSelect ? undefined : "multiple"}
											showSearch={props.facultySingleSelect}
											allowClear
											filterOption={false}
											onSearch={props.setFacultySearch}
											style={{ width: "100%", textAlign: "left" }}
											placeholder={
												props.facultySingleSelect
													? t("internships.addInternshipForm.faculty")
													: t("internships.addInternshipForm.faculties")
											}
											onChange={props.handleSelectedFaculties}
											notFoundContent={t(
												"internships.addInternshipForm.notFoundContent"
											)}
										>
											{props.facultyOptions}
										</Select>
									</Form.Item>
								</Col>
							)}
							<Col
								xs={getRow1Span("xs")}
								sm={getRow1Span("sm")}
								md={getRow1Span("md")}
								lg={getRow1Span("lg")}
								xl={getRow1Span("xl")}
							>
								<StudyProgramsSelect
									isProfessor={props.isProfessor}
									defaultFaculty={props.defaultFaculty}
									selectedFaculties={props.selectedFaculties}
									engagementTypeValue={props.engagementTypeValue}
								/>
							</Col>
						</Row>
						<Row gutter={8}>
							<Col
								xs={getRow2Span("xs")}
								sm={getRow2Span("sm")}
								md={getRow2Span("md")}
								lg={getRow2Span("lg")}
								xl={getRow2Span("xl")}
							>
								<Form.Item
									name="startingDate"
									label={t("internships.addInternshipForm.dateTime") + ":"}
									rules={[props.yupSync]}
								>
									<DatePicker
										showTime={true}
										format={dateFormatListEvent}
										style={{ width: "100%", height: "35px" }}
									/>
								</Form.Item>
							</Col>
							<Col
								xs={getRow2Span("xs")}
								sm={getRow2Span("sm")}
								md={getRow2Span("md")}
								lg={getRow2Span("lg")}
								xl={getRow2Span("xl")}
							>
								<StudentYearsSelect
									isProfessor={props.isProfessor}
									professorUser={props.professorUser}
									facultiesWithCompanyAccordList={
										props.facultiesWithCompanyAccordList
									}
									engagementTypeValue={props.engagementTypeValue}
									selectedFaculties={props.selectedFaculties}
									form={props.form}
									setSelectedYears={props.setSelectedYears}
									setDisabledDateStart={props.setDisabledDateStart}
									setDisabledDateEnd={props.setDisabledDateEnd}
								/>
							</Col>
						</Row>
						<Form.Item
							required
							name="internshipName"
							label={t("internships.addInternshipForm.internshipTitle") + ":"}
							rules={[props.yupSync]}
						>
							<Input
								className={styles.antItem}
								placeholder={t("internships.addInternshipForm.internshipTitle")}
							/>
						</Form.Item>
						<Form.Item
							name="category"
							label={t("internships.addInternshipForm.category") + ":"}
							rules={[props.yupSync]}
						>
							<Input
								className={styles.antItem}
								placeholder={t("internships.addInternshipForm.category")}
							/>
						</Form.Item>
						<Form.Item
							required
							name="description"
							label={t("internships.addInternshipForm.description") + ":"}
							rules={[props.yupSync]}
						>
							<CustomEditor
								onEditorChange={(description: string) => {
									props.form.setFieldsValue({
										...props.form.getFieldsValue(),
										description,
									} as any);
								}}
								disabled={
									props.isCompany &&
									props.isEditing &&
									props.internship?.state !== CompanyInternshipState.Draft
								}
							/>
						</Form.Item>
						<Form.Item
							name="shortDescription"
							label={t("internships.addInternshipForm.shortDescription") + ":"}
							rules={[props.yupSync]}
						>
							<Input.TextArea
								rows={4}
								cols={100}
								style={{ minHeight: 100 }}
								placeholder={t(
									"internships.addInternshipForm.shortDescription"
								)}
							/>
						</Form.Item>
						{props.isDepartmentSecretary && (
							<CoordinatingProfessorSelect
								isEditing={props.isEditing}
								isProfessor={props.isProfessor}
								debouncedUserSearch={props.debouncedUserSearch}
								currentUserId={props.currentUserId}
								internship={props.internship}
								yupSync={props.yupSync}
							/>
						)}
						{props.isCompany && (
							<CompanyUsersSelect
								isCompany={props.isCompany}
								internship={props.internship}
								yupSync={props.yupSync}
							/>
						)}
						<Form.Item
							name="skills"
							label={t("internships.addInternshipForm.requiredSkills") + ":"}
						>
							<Select
								className={styles.antSelect}
								mode="tags"
								allowClear
								notFoundContent={t(
									"internships.addInternshipForm.requiredSkillsNoData"
								)}
								style={{ width: "100%", textAlign: "left" }}
								placeholder={t("internships.addInternshipForm.requiredSkills")}
							>
								{userSkills}
							</Select>
						</Form.Item>
					</div>
					<div className={styles.secondLine}>
						<div className={styles.firstColumn}>
							<CustomButton
								fontSize={"0.9rem"}
								style={{
									background: theme.green,
									color: theme.white,
									marginRight: "2em",
									marginTop: "0%",
									boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
								}}
								onClick={() => props.setCancelModalVisibility(true)}
							>
								{t("internships.addInternshipForm.cancel")}
							</CustomButton>
							<ConfirmationModal
								modalText={t("internships.cancelMessage")}
								handleFunction={props.handleCancel}
								modalVisibility={props.cancelModalVisibility}
								changeModalVisibility={() =>
									props.setCancelModalVisibility(false)
								}
								title=""
								spinning={props.spinning}
							/>
							<CustomButton
								htmlType="submit"
								fontSize={"0.9rem"}
								type={"primary"}
								style={{
									background: theme.secondColor,
									color: theme.white,
									marginRight: "10%",
									marginTop: "0%",
									boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
								}}
							>
								{props.isEditing
									? t("internships.addInternshipForm.updateButton")
									: t("internships.addInternshipForm.saveButton")}
							</CustomButton>
							<ConfirmationModal
								modalText={t("internships.saveMessage")}
								handleFunction={props.handleSave}
								modalVisibility={props.saveModalVisibility}
								title=""
								changeModalVisibility={() =>
									props.setSaveModalVisibility(false)
								}
								spinning={props.spinning}
							/>
						</div>
					</div>
				</CustomForm>
			</div>
		</>
	);
};
