/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
	Filter,
	FilterFromJSON,
	FilterToJSON,
	PoliJobsProposalAddUpdateDTO,
	PoliJobsProposalAddUpdateDTOFromJSON,
	PoliJobsProposalAddUpdateDTOToJSON,
	PoliJobsProposalDTO,
	PoliJobsProposalDTOFromJSON,
	PoliJobsProposalDTOToJSON,
	PoliJobsProposalDTOPagedResponse,
	PoliJobsProposalDTOPagedResponseFromJSON,
	PoliJobsProposalDTOPagedResponseToJSON,
} from "../models";

export interface ApiPoliJobsProposalAddPostRequest {
	poliJobsProposalAddUpdateDTO?: PoliJobsProposalAddUpdateDTO;
}

export interface ApiPoliJobsProposalDeleteIdDeleteRequest {
	id: string;
}

export interface ApiPoliJobsProposalDownloadJobDescriptionPoliJobsProposalIdGetRequest {
	poliJobsProposalId: string;
}

export interface ApiPoliJobsProposalGetAllGetRequest {
	searchTerm?: string;
	companies?: Array<string>;
	locations?: Array<string>;
	page?: number;
	pageSize?: number;
}

export interface ApiPoliJobsProposalGetByIdIdGetRequest {
	id: string;
}

export interface ApiPoliJobsProposalUpdateIdPutRequest {
	id: string;
	poliJobsProposalAddUpdateDTO?: PoliJobsProposalAddUpdateDTO;
}

export interface ApiPoliJobsProposalUploadJobsDescriptionPoliJobsProposalIdPostRequest {
	poliJobsProposalId: string;
	file?: Blob;
}

/**
 *
 */
export class PoliJobsProposalApi extends runtime.BaseAPI {
	/**
	 */
	async apiPoliJobsProposalAddPostRaw(
		requestParameters: ApiPoliJobsProposalAddPostRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<void>> {
		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		headerParameters["Content-Type"] = "application/json";

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/PoliJobsProposal/Add`,
				method: "POST",
				headers: headerParameters,
				query: queryParameters,
				body: PoliJobsProposalAddUpdateDTOToJSON(
					requestParameters.poliJobsProposalAddUpdateDTO
				),
			},
			initOverrides
		);

		return new runtime.VoidApiResponse(response);
	}

	/**
	 */
	async apiPoliJobsProposalAddPost(
		requestParameters: ApiPoliJobsProposalAddPostRequest = {},
		initOverrides?: RequestInit
	): Promise<void> {
		await this.apiPoliJobsProposalAddPostRaw(requestParameters, initOverrides);
	}

	/**
	 */
	async apiPoliJobsProposalDeleteIdDeleteRaw(
		requestParameters: ApiPoliJobsProposalDeleteIdDeleteRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<void>> {
		if (requestParameters.id === null || requestParameters.id === undefined) {
			throw new runtime.RequiredError(
				"id",
				"Required parameter requestParameters.id was null or undefined when calling apiPoliJobsProposalDeleteIdDelete."
			);
		}

		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/PoliJobsProposal/Delete/{id}`.replace(
					`{${"id"}}`,
					encodeURIComponent(String(requestParameters.id))
				),
				method: "DELETE",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.VoidApiResponse(response);
	}

	/**
	 */
	async apiPoliJobsProposalDeleteIdDelete(
		requestParameters: ApiPoliJobsProposalDeleteIdDeleteRequest,
		initOverrides?: RequestInit
	): Promise<void> {
		await this.apiPoliJobsProposalDeleteIdDeleteRaw(
			requestParameters,
			initOverrides
		);
	}

	/**
	 */
	async apiPoliJobsProposalDownloadJobDescriptionPoliJobsProposalIdGetRaw(
		requestParameters: ApiPoliJobsProposalDownloadJobDescriptionPoliJobsProposalIdGetRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<Blob>> {
		if (
			requestParameters.poliJobsProposalId === null ||
			requestParameters.poliJobsProposalId === undefined
		) {
			throw new runtime.RequiredError(
				"poliJobsProposalId",
				"Required parameter requestParameters.poliJobsProposalId was null or undefined when calling apiPoliJobsProposalDownloadJobDescriptionPoliJobsProposalIdGet."
			);
		}

		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/PoliJobsProposal/DownloadJobDescription/{poliJobsProposalId}`.replace(
					`{${"poliJobsProposalId"}}`,
					encodeURIComponent(String(requestParameters.poliJobsProposalId))
				),
				method: "GET",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.BlobApiResponse(response);
	}

	/**
	 */
	async apiPoliJobsProposalDownloadJobDescriptionPoliJobsProposalIdGet(
		requestParameters: ApiPoliJobsProposalDownloadJobDescriptionPoliJobsProposalIdGetRequest,
		initOverrides?: RequestInit
	): Promise<Blob> {
		const response =
			await this.apiPoliJobsProposalDownloadJobDescriptionPoliJobsProposalIdGetRaw(
				requestParameters,
				initOverrides
			);
		return await response.value();
	}

	/**
	 */
	async apiPoliJobsProposalGetAllGetRaw(
		requestParameters: ApiPoliJobsProposalGetAllGetRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<PoliJobsProposalDTOPagedResponse>> {
		const queryParameters: any = {};

		if (requestParameters.searchTerm !== undefined) {
			queryParameters["SearchTerm"] = requestParameters.searchTerm;
		}

		if (requestParameters.companies) {
			queryParameters["Companies"] = requestParameters.companies;
		}

		if (requestParameters.locations) {
			queryParameters["Locations"] = requestParameters.locations;
		}

		if (requestParameters.page !== undefined) {
			queryParameters["Page"] = requestParameters.page;
		}

		if (requestParameters.pageSize !== undefined) {
			queryParameters["PageSize"] = requestParameters.pageSize;
		}

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/PoliJobsProposal/GetAll`,
				method: "GET",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			PoliJobsProposalDTOPagedResponseFromJSON(jsonValue)
		);
	}

	/**
	 */
	async apiPoliJobsProposalGetAllGet(
		requestParameters: ApiPoliJobsProposalGetAllGetRequest = {},
		initOverrides?: RequestInit
	): Promise<PoliJobsProposalDTOPagedResponse> {
		const response = await this.apiPoliJobsProposalGetAllGetRaw(
			requestParameters,
			initOverrides
		);
		return await response.value();
	}

	/**
	 */
	async apiPoliJobsProposalGetByIdIdGetRaw(
		requestParameters: ApiPoliJobsProposalGetByIdIdGetRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<PoliJobsProposalDTO>> {
		if (requestParameters.id === null || requestParameters.id === undefined) {
			throw new runtime.RequiredError(
				"id",
				"Required parameter requestParameters.id was null or undefined when calling apiPoliJobsProposalGetByIdIdGet."
			);
		}

		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/PoliJobsProposal/GetById/{id}`.replace(
					`{${"id"}}`,
					encodeURIComponent(String(requestParameters.id))
				),
				method: "GET",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			PoliJobsProposalDTOFromJSON(jsonValue)
		);
	}

	/**
	 */
	async apiPoliJobsProposalGetByIdIdGet(
		requestParameters: ApiPoliJobsProposalGetByIdIdGetRequest,
		initOverrides?: RequestInit
	): Promise<PoliJobsProposalDTO> {
		const response = await this.apiPoliJobsProposalGetByIdIdGetRaw(
			requestParameters,
			initOverrides
		);
		return await response.value();
	}

	/**
	 */
	async apiPoliJobsProposalGetPoliJobsProposalFiltersGetRaw(
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<Array<Filter>>> {
		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/PoliJobsProposal/GetPoliJobsProposalFilters`,
				method: "GET",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			jsonValue.map(FilterFromJSON)
		);
	}

	/**
	 */
	async apiPoliJobsProposalGetPoliJobsProposalFiltersGet(
		initOverrides?: RequestInit
	): Promise<Array<Filter>> {
		const response =
			await this.apiPoliJobsProposalGetPoliJobsProposalFiltersGetRaw(
				initOverrides
			);
		return await response.value();
	}

	/**
	 */
	async apiPoliJobsProposalUpdateIdPutRaw(
		requestParameters: ApiPoliJobsProposalUpdateIdPutRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<void>> {
		if (requestParameters.id === null || requestParameters.id === undefined) {
			throw new runtime.RequiredError(
				"id",
				"Required parameter requestParameters.id was null or undefined when calling apiPoliJobsProposalUpdateIdPut."
			);
		}

		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		headerParameters["Content-Type"] = "application/json";

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/PoliJobsProposal/Update/{id}`.replace(
					`{${"id"}}`,
					encodeURIComponent(String(requestParameters.id))
				),
				method: "PUT",
				headers: headerParameters,
				query: queryParameters,
				body: PoliJobsProposalAddUpdateDTOToJSON(
					requestParameters.poliJobsProposalAddUpdateDTO
				),
			},
			initOverrides
		);

		return new runtime.VoidApiResponse(response);
	}

	/**
	 */
	async apiPoliJobsProposalUpdateIdPut(
		requestParameters: ApiPoliJobsProposalUpdateIdPutRequest,
		initOverrides?: RequestInit
	): Promise<void> {
		await this.apiPoliJobsProposalUpdateIdPutRaw(
			requestParameters,
			initOverrides
		);
	}

	/**
	 */
	async apiPoliJobsProposalUploadJobsDescriptionPoliJobsProposalIdPostRaw(
		requestParameters: ApiPoliJobsProposalUploadJobsDescriptionPoliJobsProposalIdPostRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<void>> {
		if (
			requestParameters.poliJobsProposalId === null ||
			requestParameters.poliJobsProposalId === undefined
		) {
			throw new runtime.RequiredError(
				"poliJobsProposalId",
				"Required parameter requestParameters.poliJobsProposalId was null or undefined when calling apiPoliJobsProposalUploadJobsDescriptionPoliJobsProposalIdPost."
			);
		}

		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const consumes: runtime.Consume[] = [
			{ contentType: "multipart/form-data" },
		];
		// @ts-ignore: canConsumeForm may be unused
		const canConsumeForm = runtime.canConsumeForm(consumes);

		let formParams: { append(param: string, value: any): any };
		let useForm = false;
		// use FormData to transmit files using content-type "multipart/form-data"
		useForm = canConsumeForm;
		if (useForm) {
			formParams = new FormData();
		} else {
			formParams = new URLSearchParams();
		}

		if (requestParameters.file !== undefined) {
			formParams.append("File", requestParameters.file as any);
		}

		const response = await this.request(
			{
				path: `/api/PoliJobsProposal/UploadJobsDescription/{poliJobsProposalId}`.replace(
					`{${"poliJobsProposalId"}}`,
					encodeURIComponent(String(requestParameters.poliJobsProposalId))
				),
				method: "POST",
				headers: headerParameters,
				query: queryParameters,
				body: formParams,
			},
			initOverrides
		);

		return new runtime.VoidApiResponse(response);
	}

	/**
	 */
	async apiPoliJobsProposalUploadJobsDescriptionPoliJobsProposalIdPost(
		requestParameters: ApiPoliJobsProposalUploadJobsDescriptionPoliJobsProposalIdPostRequest,
		initOverrides?: RequestInit
	): Promise<void> {
		await this.apiPoliJobsProposalUploadJobsDescriptionPoliJobsProposalIdPostRaw(
			requestParameters,
			initOverrides
		);
	}
}
