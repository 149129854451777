/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
	PracticeCertificateState,
	PracticeCertificateStateFromJSON,
	PracticeCertificateStateFromJSONTyped,
	PracticeCertificateStateToJSON,
} from "./PracticeCertificateState";
import {
	PracticeQualificationDTO,
	PracticeQualificationDTOFromJSON,
	PracticeQualificationDTOFromJSONTyped,
	PracticeQualificationDTOToJSON,
} from "./PracticeQualificationDTO";

/**
 *
 * @export
 * @interface PracticeCertificateAddDTO
 */
export interface PracticeCertificateAddDTO {
	/**
	 *
	 * @type {number}
	 * @memberof PracticeCertificateAddDTO
	 */
	workHoursPerDay?: number;
	/**
	 *
	 * @type {string}
	 * @memberof PracticeCertificateAddDTO
	 */
	observations?: string | null;
	/**
	 *
	 * @type {Array<PracticeQualificationDTO>}
	 * @memberof PracticeCertificateAddDTO
	 */
	qualifiers?: Array<PracticeQualificationDTO> | null;
	/**
	 *
	 * @type {string}
	 * @memberof PracticeCertificateAddDTO
	 */
	internshipEnrollmentId?: string;
	/**
	 *
	 * @type {PracticeCertificateState}
	 * @memberof PracticeCertificateAddDTO
	 */
	state?: PracticeCertificateState;
}

export function PracticeCertificateAddDTOFromJSON(
	json: any
): PracticeCertificateAddDTO {
	return PracticeCertificateAddDTOFromJSONTyped(json, false);
}

export function PracticeCertificateAddDTOFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): PracticeCertificateAddDTO {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		workHoursPerDay: !exists(json, "workHoursPerDay")
			? undefined
			: json["workHoursPerDay"],
		observations: !exists(json, "observations")
			? undefined
			: json["observations"],
		qualifiers: !exists(json, "qualifiers")
			? undefined
			: json["qualifiers"] === null
			? null
			: (json["qualifiers"] as Array<any>).map(
					PracticeQualificationDTOFromJSON
			  ),
		internshipEnrollmentId: !exists(json, "internshipEnrollmentId")
			? undefined
			: json["internshipEnrollmentId"],
		state: !exists(json, "state")
			? undefined
			: PracticeCertificateStateFromJSON(json["state"]),
	};
}

export function PracticeCertificateAddDTOToJSON(
	value?: PracticeCertificateAddDTO | null
): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		workHoursPerDay: value.workHoursPerDay,
		observations: value.observations,
		qualifiers:
			value.qualifiers === undefined
				? undefined
				: value.qualifiers === null
				? null
				: (value.qualifiers as Array<any>).map(PracticeQualificationDTOToJSON),
		internshipEnrollmentId: value.internshipEnrollmentId,
		state: PracticeCertificateStateToJSON(value.state),
	};
}
