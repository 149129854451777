import { CompanyDocumentApi, DocumentTypeEnum } from "../Api";
import { getAuthConfiguration } from "./configuration";
import { arrayParamAjust } from "../utils/dataUtils";

const AuthCompanyDocumentApiFactory = () =>
	new CompanyDocumentApi(getAuthConfiguration());

export const getCompanyDocuments = (
	companyId: string,
	documentType?: Array<DocumentTypeEnum>,
	page?: number,
	pageSize?: number
) => {
	return AuthCompanyDocumentApiFactory().apiCompanyDocumentGetAllCompanyIdGet({
		companyId,
		documentType: arrayParamAjust(documentType),
		page,
		pageSize,
	});
};

export const downloadDocument = (documentId: string) => {
	return AuthCompanyDocumentApiFactory().apiCompanyDocumentDownloadDocumentDocumentIdGet(
		{ documentId }
	);
};

export const addNewDocument = (
	companyId: string,
	file: Blob,
	facultyId?: string,
	documentId?: string
) => {
	return AuthCompanyDocumentApiFactory().apiCompanyDocumentUploadDocumentCompanyIdPut(
		{ companyId, file, facultyId, documentId }
	);
};

export const deleteDocument = (documentId: string) => {
	return AuthCompanyDocumentApiFactory().apiCompanyDocumentDeleteDocumentIdDelete(
		{ documentId }
	);
};

export const updateSkipOfferValidationStatus = (documentId: string) => {
	return AuthCompanyDocumentApiFactory().apiCompanyDocumentUpdateSkipOfferValidationStatusDocumentIdPut(
		{ documentId }
	);
};

export const rejectDocument = async (documentId: string, message: string) => {
	await AuthCompanyDocumentApiFactory().apiCompanyDocumentRejectDocumentDocumentIdPut(
		{ documentId, body: message }
	);
};
