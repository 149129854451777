import { Input, List } from "antd";
import { useEffect, useState } from "react";
import usePlacesAutocompleteService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import styles from "./CustomAutocomplete.module.scss";
import { useTranslation } from "react-i18next";

interface CustomAutocompleteProps {
	onPlaceChange: (place: any) => void;
	locationInput: string;
	disabled?: boolean | undefined;
}

const CustomAutocomplete: React.FC<CustomAutocompleteProps> = ({
	onPlaceChange,
	locationInput,
	disabled,
}) => {
	const { t } = useTranslation();
	const [value, setValue] = useState(locationInput);
	const [isVisible, setIsVisible] = useState(false);
	const handlePlaceOnChange = (e: any) => {
		setIsVisible(false);
		onPlaceChange(e);
	};
	const { placePredictions, getPlacePredictions, isPlacePredictionsLoading } =
		usePlacesAutocompleteService({
			apiKey: "AIzaSyAKmr3w8h3KU5qEFefd64KyyayflLoWiLo",
			options: {
				types: ["(cities)"],
			},
		});

	useEffect(() => {
		setValue(locationInput);
	}, [locationInput]);

	return (
		<div className={styles.autocompleteWrapper}>
			<Input
				disabled={disabled ?? false}
				className={styles.antItem}
				value={value}
				placeholder={t("internships.addInternshipForm.locationPlaceholder")}
				onChange={(evt) => {
					getPlacePredictions({ input: evt.target.value });
					setValue(evt.target.value);
					setIsVisible(true);
					evt.preventDefault();
				}}
			/>

			{isVisible && (
				<div>
					{!isPlacePredictionsLoading && (
						<List
							className={`${styles.autocompleteDropdown} ${
								isVisible ? styles.active : ""
							}`}
							dataSource={placePredictions}
							locale={{
								emptyText: t(
									"internships.addInternshipForm.locationPlaceholder"
								),
							}}
							renderItem={(item) => (
								<List.Item
									className={styles.autocompleteItem}
									onClick={() => {
										handlePlaceOnChange(item);
										setValue(item.description);
									}}
								>
									<List.Item.Meta title={item.description} />
								</List.Item>
							)}
						/>
					)}
				</div>
			)}
		</div>
	);
};

export default CustomAutocomplete;
