/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum CompanyStateEnum {
	Unverified = "Unverified",
	PendingAdminVerification = "PendingAdminVerification",
	AdminApproved = "AdminApproved",
	AdminRejected = "AdminRejected",
	Active = "Active",
	Suspended = "Suspended",
}

export function CompanyStateEnumFromJSON(json: any): CompanyStateEnum {
	return CompanyStateEnumFromJSONTyped(json, false);
}

export function CompanyStateEnumFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): CompanyStateEnum {
	return json as CompanyStateEnum;
}

export function CompanyStateEnumToJSON(value?: CompanyStateEnum | null): any {
	return value as any;
}
