import {
	ReportApi,
	InternshipEngagementType,
	StudentYearEnum,
	InternshipType,
} from "../Api";
import { getAuthConfiguration } from "./configuration";
import { arrayParamAjust } from "../utils/dataUtils";

const ReportApiFactory = () => new ReportApi(getAuthConfiguration());

export const getProfessorProposalReport = (
	page: number,
	pageSize: number,
	engagementType: InternshipEngagementType,
	studyProgram: Array<string>
) =>
	ReportApiFactory().apiReportGetProfessorProposalReportGet({
		page,
		pageSize,
		engagementType,
		studyProgram: arrayParamAjust(studyProgram),
	});

export const getMissingStudentProjectEnrollmentReport = (
	page: number,
	pageSize: number,
	studyProgram: Array<string>
) =>
	ReportApiFactory().apiReportGetMissingStudentProjectEnrollmentReportGet({
		page,
		pageSize,
		studyProgram: arrayParamAjust(studyProgram),
	});

export const getProjectAllocationReport = (
	page: number,
	pageSize: number,
	engagementType: InternshipEngagementType,
	studyProgram: Array<string>
) =>
	ReportApiFactory().apiReportGetProjectAllocationReportGet({
		page,
		pageSize,
		engagementType,
		studyProgram: arrayParamAjust(studyProgram),
	});

export const getMissingStudentPracticeInternshipEnrollmentReport = (
	page: number,
	pageSize: number,
	studyProgram: Array<string>
) =>
	ReportApiFactory().apiReportGetMissingStudentPracticeInternshipEnrollmentReportGet(
		{ page, pageSize, studyProgram: arrayParamAjust(studyProgram) }
	);

export const getPracticeInternshipAllocationReport = (
	page: number,
	pageSize: number,
	studyProgram: Array<string>,
	internshipType?: InternshipType
) =>
	ReportApiFactory().apiReportGetPracticeInternshipAllocationReportGet({
		page,
		pageSize,
		internshipType,
		studyProgram: arrayParamAjust(studyProgram),
	});

export const getStudentAllocationPerDomainReport = (
	page: number,
	pageSize: number
) =>
	ReportApiFactory().apiReportGetStudentAllocationPerDomainReportGet({
		page,
		pageSize,
	});

export const getPracticeInternshipPerDomainReport = (
	page: number,
	pageSize: number,
	internshipType: InternshipType
) =>
	ReportApiFactory().apiReportGetPracticeInternshipsPerDomainReportGet({
		page,
		pageSize,
		internshipType,
	});

export const getSummerSchoolsReport = (
	page: number,
	pageSize: number,
	engagementType: InternshipEngagementType,
	studyProgram: Array<string>
) =>
	ReportApiFactory().apiReportGetSummerSchoolsReportGet({
		page,
		pageSize,
		engagementType,
		studyProgram: arrayParamAjust(studyProgram),
	});

export const getCompaniesFacultyAccordsReport = () =>
	ReportApiFactory().apiReportGetCompaniesFacultyAccordsReportGet({});

export const getCompanyContactsReport = (
	namePattern?: string,
	page?: number,
	pageSize?: number,
	companyId: string | undefined = undefined
) =>
	ReportApiFactory().apiReportGetCompanyContactsReportGet({
		namePattern,
		companyId,
		page,
		pageSize,
	});

export const getAllCompaniesContactsReport = () =>
	ReportApiFactory().apiReportGetAllCompaniesContactsReportGet();
