/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
	ExperienceLevel,
	ExperienceLevelFromJSON,
	ExperienceLevelFromJSONTyped,
	ExperienceLevelToJSON,
} from "./ExperienceLevel";
import { Lang, LangFromJSON, LangFromJSONTyped, LangToJSON } from "./Lang";
import {
	StudentYearEnum,
	StudentYearEnumFromJSON,
	StudentYearEnumFromJSONTyped,
	StudentYearEnumToJSON,
} from "./StudentYearEnum";

/**
 *
 * @export
 * @interface PoliJobsProposalDTO
 */
export interface PoliJobsProposalDTO {
	/**
	 *
	 * @type {string}
	 * @memberof PoliJobsProposalDTO
	 */
	id?: string;
	/**
	 *
	 * @type {string}
	 * @memberof PoliJobsProposalDTO
	 */
	poliJobsProposalName?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof PoliJobsProposalDTO
	 */
	description?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof PoliJobsProposalDTO
	 */
	jobDescriptionFileName?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof PoliJobsProposalDTO
	 */
	url?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof PoliJobsProposalDTO
	 */
	location?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof PoliJobsProposalDTO
	 */
	placeId?: string | null;
	/**
	 *
	 * @type {Date}
	 * @memberof PoliJobsProposalDTO
	 */
	availableFrom?: Date;
	/**
	 *
	 * @type {Date}
	 * @memberof PoliJobsProposalDTO
	 */
	availableTo?: Date;
	/**
	 *
	 * @type {Date}
	 * @memberof PoliJobsProposalDTO
	 */
	startingDate?: Date;
	/**
	 *
	 * @type {boolean}
	 * @memberof PoliJobsProposalDTO
	 */
	isArchived?: boolean | null;
	/**
	 *
	 * @type {number}
	 * @memberof PoliJobsProposalDTO
	 */
	numberOfPositions?: number;
	/**
	 *
	 * @type {number}
	 * @memberof PoliJobsProposalDTO
	 */
	availableNumberOfPositions?: number;
	/**
	 *
	 * @type {ExperienceLevel}
	 * @memberof PoliJobsProposalDTO
	 */
	experienceLevel?: ExperienceLevel;
	/**
	 *
	 * @type {Array<string>}
	 * @memberof PoliJobsProposalDTO
	 */
	facultyIds?: Array<string> | null;
	/**
	 *
	 * @type {Array<string>}
	 * @memberof PoliJobsProposalDTO
	 */
	studyProgramIds?: Array<string> | null;
	/**
	 *
	 * @type {Array<StudentYearEnum>}
	 * @memberof PoliJobsProposalDTO
	 */
	years?: Array<StudentYearEnum> | null;
	/**
	 *
	 * @type {Array<string>}
	 * @memberof PoliJobsProposalDTO
	 */
	blacklist?: Array<string> | null;
	/**
	 *
	 * @type {Array<string>}
	 * @memberof PoliJobsProposalDTO
	 */
	whitelist?: Array<string> | null;
	/**
	 *
	 * @type {Array<Lang>}
	 * @memberof PoliJobsProposalDTO
	 */
	languages?: Array<Lang> | null;
	/**
	 *
	 * @type {string}
	 * @memberof PoliJobsProposalDTO
	 */
	companyId?: string;
	/**
	 *
	 * @type {string}
	 * @memberof PoliJobsProposalDTO
	 */
	publisherId?: string;
	/**
	 *
	 * @type {string}
	 * @memberof PoliJobsProposalDTO
	 */
	recruiterName?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof PoliJobsProposalDTO
	 */
	recruiterAvatar?: string | null;
	/**
	 *
	 * @type {number}
	 * @memberof PoliJobsProposalDTO
	 */
	enrolledStudentsCount?: number;
}

export function PoliJobsProposalDTOFromJSON(json: any): PoliJobsProposalDTO {
	return PoliJobsProposalDTOFromJSONTyped(json, false);
}

export function PoliJobsProposalDTOFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): PoliJobsProposalDTO {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		id: !exists(json, "id") ? undefined : json["id"],
		poliJobsProposalName: !exists(json, "poliJobsProposalName")
			? undefined
			: json["poliJobsProposalName"],
		description: !exists(json, "description") ? undefined : json["description"],
		jobDescriptionFileName: !exists(json, "jobDescriptionFileName")
			? undefined
			: json["jobDescriptionFileName"],
		url: !exists(json, "url") ? undefined : json["url"],
		location: !exists(json, "location") ? undefined : json["location"],
		placeId: !exists(json, "placeId") ? undefined : json["placeId"],
		availableFrom: !exists(json, "availableFrom")
			? undefined
			: new Date(json["availableFrom"]),
		availableTo: !exists(json, "availableTo")
			? undefined
			: new Date(json["availableTo"]),
		startingDate: !exists(json, "startingDate")
			? undefined
			: new Date(json["startingDate"]),
		isArchived: !exists(json, "isArchived") ? undefined : json["isArchived"],
		numberOfPositions: !exists(json, "numberOfPositions")
			? undefined
			: json["numberOfPositions"],
		availableNumberOfPositions: !exists(json, "availableNumberOfPositions")
			? undefined
			: json["availableNumberOfPositions"],
		experienceLevel: !exists(json, "experienceLevel")
			? undefined
			: ExperienceLevelFromJSON(json["experienceLevel"]),
		facultyIds: !exists(json, "facultyIds") ? undefined : json["facultyIds"],
		studyProgramIds: !exists(json, "studyProgramIds")
			? undefined
			: json["studyProgramIds"],
		years: !exists(json, "years")
			? undefined
			: json["years"] === null
			? null
			: (json["years"] as Array<any>).map(StudentYearEnumFromJSON),
		blacklist: !exists(json, "blacklist") ? undefined : json["blacklist"],
		whitelist: !exists(json, "whitelist") ? undefined : json["whitelist"],
		languages: !exists(json, "languages")
			? undefined
			: json["languages"] === null
			? null
			: (json["languages"] as Array<any>).map(LangFromJSON),
		companyId: !exists(json, "companyId") ? undefined : json["companyId"],
		publisherId: !exists(json, "publisherId") ? undefined : json["publisherId"],
		recruiterName: !exists(json, "recruiterName")
			? undefined
			: json["recruiterName"],
		recruiterAvatar: !exists(json, "recruiterAvatar")
			? undefined
			: json["recruiterAvatar"],
		enrolledStudentsCount: !exists(json, "enrolledStudentsCount")
			? undefined
			: json["enrolledStudentsCount"],
	};
}

export function PoliJobsProposalDTOToJSON(
	value?: PoliJobsProposalDTO | null
): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		id: value.id,
		poliJobsProposalName: value.poliJobsProposalName,
		description: value.description,
		jobDescriptionFileName: value.jobDescriptionFileName,
		url: value.url,
		location: value.location,
		placeId: value.placeId,
		availableFrom:
			value.availableFrom === undefined
				? undefined
				: value.availableFrom.toISOString(),
		availableTo:
			value.availableTo === undefined
				? undefined
				: value.availableTo.toISOString(),
		startingDate:
			value.startingDate === undefined
				? undefined
				: value.startingDate.toISOString(),
		isArchived: value.isArchived,
		numberOfPositions: value.numberOfPositions,
		availableNumberOfPositions: value.availableNumberOfPositions,
		experienceLevel: ExperienceLevelToJSON(value.experienceLevel),
		facultyIds: value.facultyIds,
		studyProgramIds: value.studyProgramIds,
		years:
			value.years === undefined
				? undefined
				: value.years === null
				? null
				: (value.years as Array<any>).map(StudentYearEnumToJSON),
		blacklist: value.blacklist,
		whitelist: value.whitelist,
		languages:
			value.languages === undefined
				? undefined
				: value.languages === null
				? null
				: (value.languages as Array<any>).map(LangToJSON),
		companyId: value.companyId,
		publisherId: value.publisherId,
		recruiterName: value.recruiterName,
		recruiterAvatar: value.recruiterAvatar,
		enrolledStudentsCount: value.enrolledStudentsCount,
	};
}
