import { AddUpdateCompanyFormModel } from "../models/AddUpdateCompanyFormModel";
import { FormInstance } from "antd";
import { useTranslation } from "react-i18next";
import { useCallback } from "react";
import * as yup from "yup";
import {
	cuiPattern,
	faxPattern,
	phonePattern,
} from "../../../../utils/constants";

const getDefaultValues = (): Partial<AddUpdateCompanyFormModel> => ({
	name: "",
	commercialName: "",
	cui: "",
	address: "",
	phone: "",
	domainOfActivity: "",
	fax: "",
	emailContact: "",
});

export const useInitAddUpdateCompanyForm = (
	form: FormInstance<AddUpdateCompanyFormModel>
) => {
	const defaultValues = getDefaultValues();
	const { t } = useTranslation();

	let schema = yup.object().shape({
		fax: yup
			.string()
			.trim()
			.transform((s) => (s === "" ? null : s))
			.nullable()
			.matches(
				faxPattern,
				t("usersText.invalidField", { field: t("signupText.fax") })
			),
		domainOfActivity: yup
			.string()
			.trim()
			.required(
				t("account.requiredField", { field: t("account.activityDomain") })
			),
		emailContact: yup
			.string()
			.trim()
			.required(
				t("account.requiredField", {
					field: t("signupText.companyRepresentativeEmail"),
				})
			)
			.email(
				t("usersText.invalidField", {
					field: t("signupText.companyRepresentativeEmail"),
				})
			),
		phone: yup
			.string()
			.trim()
			.required(t("account.requiredField", { field: t("account.phone") }))
			.matches(
				phonePattern,
				t("usersText.invalidField", { field: t("account.phone") })
			),
		address: yup
			.string()
			.trim()
			.required(t("account.requiredField", { field: t("account.address") })),
		commercialName: yup
			.string()
			.trim()
			.required(
				t("account.requiredField", { field: t("account.commercialName") })
			),
		name: yup
			.string()
			.trim()
			.required(t("account.requiredField", { field: t("account.name") })),
		cui: yup
			.string()
			.trim()
			.required(t("account.requiredField", { field: t("signupText.cif") }))
			.matches(
				cuiPattern,
				t("usersText.invalidField", { field: t("signupText.cif") })
			),
	});

	const validator = useCallback(
		async ({ field }: any, _value: any) => {
			await schema.validateSyncAt(field, form.getFieldsValue());
		},
		[schema, form]
	);

	const yupSync = {
		validator,
	};

	return { defaultValues, schema, yupSync };
};
