import { CoordinatingProfessorSelectProps } from "./CoordinatingProfessorSelect.types";
import { Form, Select } from "antd";
import styles from "../../../AddInternshipForm.module.scss";
import { useTranslation } from "react-i18next";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../../../../../Notifications/NotificationsUtils";
import { useQuery } from "react-query";
import { getProfessors } from "../../../../../../utils/reactQueriesConstants";
import { getAllProfessors } from "../../../../../../Requests/academic-user-requests";

const { Option } = Select;

export const CoordinatingProfessorSelect = (
	props: CoordinatingProfessorSelectProps
) => {
	const { t } = useTranslation();

	const openGetErrorNotification = (_error: any) => {
		openNotification(
			t("internships.error"),
			t("internships.fetchDataError"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const { data: professors } = useQuery(
		[
			getProfessors,
			props.isProfessor,
			props.internship,
			props.debouncedUserSearch,
		],
		async () => {
			if (!props.isProfessor) {
				return null;
			}

			return getAllProfessors(props.debouncedUserSearch, 1, 25).then((e) =>
				e.data?.map((e) => {
					return { id: e.id, name: e.name };
				})
			);
		},
		{
			onError: (err) => {
				openGetErrorNotification(err);
			},
			refetchOnWindowFocus: false,
		}
	);

	return (
		<Form.Item
			required
			label={t("internships.addInternshipForm.coordinatingProfessor") + ":"}
			name="coordinatingProfessor"
			style={{ marginTop: "-10px" }}
			rules={[props.yupSync]}
		>
			<Select
				className={styles.antSelect}
				allowClear
				style={{ width: "100%", textAlign: "left" }}
				placeholder={t("internships.addInternshipForm.coordinatingProfessor")}
				disabled={props.isEditing}
				notFoundContent={t("internships.addInternshipForm.notFoundContent")}
			>
				{professors
					?.filter((e) => e.id !== props.currentUserId)
					.map((user) => (
						<Option key={user.id} value={user.id}>
							{user.name}
						</Option>
					))}
			</Select>
		</Form.Item>
	);
};
