import { Form } from "antd";
import styled from "styled-components";

const StyledForm = styled(Form)`
	box-shadow: ${(props: any) => props.boxshadow || "auto"};
	background-color: ${(props: any) => props.backgroundcolor};
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	margin-top: 0;
	width: ${(props: any) => (props.widthelem ? props.widthelem : "100%")};
`;

const CustomForm = (props: any) => {
	return <StyledForm {...props}>{props.children}</StyledForm>;
};

export default CustomForm;
