import { Avatar } from "antd";
import EventActions from "./EventActions";
import {
	useIsDepartmentDirector,
	useIsProfessor,
} from "../../utils/utilFunctions";
import { Link } from "react-router-dom";
import { InternshipDTO, InternshipType } from "../../Api";
import { useTranslation } from "react-i18next";
import { EventListProps } from "../../PropsLists/EventListProps";
import { PropsWithChildren } from "react";
import { useEventListController } from "./EventList.controller";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

const EventsList = (props: PropsWithChildren<EventListProps>) => {
	const { t } = useTranslation();
	const isProfessor = useIsProfessor();
	const isDepartmentDirector = useIsDepartmentDirector();

	const { actions } = useEventListController(props);

	return (
		<>
			{props.loading && !props.data && (
				<div className="w-full py-10 text-center">Loading</div>
			)}
			{props.data?.data.length === 0 && (
				<div className="w-full py-10 text-center">
					{t("tableText.noOffers")}
				</div>
			)}

			<div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 xxl:grid-cols-4 gap-4">
				{props.data?.data.map((item: InternshipDTO) => (
					<div
						className="bg-white rounded-md shadow-md p-4 flex flex-col gap-4"
						key={item.id}
					>
						{isProfessor &&
						!isDepartmentDirector &&
						item.type !== InternshipType.ByCompany &&
						item.isValid !== true ? (
							<span>{t("internships.invalidProposal")}</span>
						) : null}

						<div className="flex justify-between gap-6">
							<div className="flex flex-col gap-2">
								<Link
									to={{
										pathname: `/profil`,
										state: {
											id:
												item.type === InternshipType.ByCompany
													? item.companyId
													: item.professorId,
											type: item.type,
											filters: props.checkedKeys,
											searchTerm: props.searchTerm,
											sortField: props.sortField,
											sortDirection: props.sortDirection,
											currentPage: props.currentPage,
											pageSize: props.pageSize,
											origin: props.location.pathname,
										},
									}}
								>
									{item.type === InternshipType.ByCompany && (
										<Avatar src={item.recruiterAvatar} />
									)}
									{!isProfessor && (
										<span className="text-secondary">
											{item.type === InternshipType.ByProfessor
												? t("abbreviations." + item.professionalTitle) + " "
												: ""}{" "}
											{item.recruiterName}
										</span>
									)}
								</Link>

								<Link
									to={{
										pathname: "/propunere/" + item.id,
										search: "?type=" + item.type,
										state: {
											id: item.id,
											type: item.type,
											filters: props.checkedKeys,
											searchTerm: props.searchTerm,
											sortField: props.sortField,
											sortDirection: props.sortDirection,
											currentPage: props.currentPage,
											pageSize: props.pageSize,
											origin: props.location.pathname,
										},
									}}
									onClick={() => actions.updateViewCount(item.id, item.type)}
									className="text-lg text-secondary font-bold flex items-center gap-2 line-clamp-2 overflow-ellipsis"
								>
									<FontAwesomeIcon icon={solid("arrow-up-right-from-square")} />

									{item.internshipName}
								</Link>
							</div>
						</div>

						{item.skillsRequired?.length > 0 && (
							<div className="flex flex-wrap gap-1 items-center">
								{item.skillsRequired?.map((skill) => (
									<span
										className="bg-primary rounded-md px-2 py-1 text-white"
										key={skill}
									>
										{skill}
									</span>
								))}
							</div>
						)}

						<div className="line-clamp-5 overflow-ellipsis">
							{item.shortDescription}
						</div>

						<EventActions item={item} />
					</div>
				))}
			</div>
		</>
	);
};

export default EventsList;
