import { Tabs, Select } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
	InternshipEngagementType,
	InternshipType,
	ProfessorProposalReportDTO,
	Role,
	StudyProgramDTO,
} from "../../Api";
import {
	getMissingStudentPracticeInternshipEnrollmentReport,
	getMissingStudentProjectEnrollmentReport,
	getPracticeInternshipAllocationReport,
	getPracticeInternshipPerDomainReport,
	getProfessorProposalReport,
	getProjectAllocationReport,
	getStudentAllocationPerDomainReport,
	getSummerSchoolsReport,
} from "../../Requests/report-requests";
import ReportTable from "./ReportTable";
import { useQuery } from "react-query";
import { getReportStudyPrograms } from "../../Requests/student-study";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../Notifications/NotificationsUtils";
import {
	getMissingStudentPracticeInternshipEnrollmentReportInfo,
	getMissingStudentProjectEnrollmentReportInfo,
	getPracticeInternshipAllocationReportInfo,
	getPracticeInternshipPerDomainReportInfo,
	getProfessorProposalReportInfo,
	getPrograms,
	getProjectAllocationReportInfo,
	getStudentAllocationPerDomainReportInfo,
	getSummerSchoolReportInfo,
} from "../../utils/reactQueriesConstants";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { studentIdSet } from "../Student/StudentSlice";
import { useBeforeRender } from "../../utils/utilFunctions";
import { useFeatureFlags } from "../../utils/featureFlags";

const { TabPane } = Tabs;
const { Option } = Select;

type StudyCycle = "Bachelor" | "Master";

const Report = () => {
	const { t } = useTranslation();
	const {
		isAcademicUsersMasterInternshipEnabled,
		isAcademicUsersBachelorInternshipEnabled,
		isAcademicUsersSummerSchoolEnabled,
	} = useFeatureFlags();
	const [tab, setTab] = useState(
		isAcademicUsersMasterInternshipEnabled ||
			isAcademicUsersBachelorInternshipEnabled
			? "1"
			: "4"
	);
	const [studyCycle, setStudyCycle] = useState<StudyCycle>("Bachelor");
	const [studyProgram, setStudyProgram] = useState([]);
	const [internshipType, setInternshipType] = useState<InternshipType | null>(
		tab === "5" ? null : InternshipType.ByProfessor
	);
	const [filteredPrograms, setFilteredPrograms] = useState<StudyProgramDTO[]>(
		[]
	);
	const location = useLocation();
	const history = useHistory();
	const dispatch = useDispatch();

	const goToStudentProfile = (id: any) => {
		dispatch(studentIdSet(id));
		history.push("/student");
	};

	const professorReportColumns = (t: (text: string) => string) => [
		{
			title: t("reports.professionalTitle"),
			key: "professionalTitle",
			hidden: window.innerWidth < 550,
			// sorter: (
			// 	a: ProfessorProposalReportDTO,
			// 	b: ProfessorProposalReportDTO
			// ) => {
			// 	if (!a.professionalTitle || !b.professionalTitle) return -1;
			// 	return a.professionalTitle.localeCompare(b.professionalTitle);
			// },
			render: (
				text: string | undefined,
				record: ProfessorProposalReportDTO
			) => {
				return (
					<span>{t("professionalTitle." + record.professionalTitle)}</span>
				);
			},
		},
		{
			title: t("reports.name"),
			key: "name",
			// sorter: (
			// 	a: ProfessorProposalReportDTO,
			// 	b: ProfessorProposalReportDTO
			// ) => {
			// 	if (!a.name || !b.name) return -1;
			// 	return a.name.localeCompare(b.name);
			// },
			render: (_text: string | undefined, record: any) => (
				<Link
					to={{
						pathname: `/profil`,
						state: {
							id: record.id,
							userType: Role.Professor,
							origin: location.pathname,
							activeTab: tab,
						},
					}}
				>
					<b>{record.name}</b>
				</Link>
			),
		},
		{
			title: t("reports.department"),
			key: "department",
			hidden: window.innerWidth < 430,
			// sorter: (
			// 	a: ProfessorProposalReportDTO,
			// 	b: ProfessorProposalReportDTO
			// ) => {
			// 	if (!a.department || !b.department) return -1;
			// 	return a.department.localeCompare(b.department);
			// },
		},
		{
			title: t("reports.email"),
			key: "email",
			hidden: window.innerWidth < 975,
			// sorter: (
			// 	a: ProfessorProposalReportDTO,
			// 	b: ProfessorProposalReportDTO
			// ) => {
			// 	if (!a.email || !b.email) return -1;
			// 	return a.email.localeCompare(b.email);
			// },
		},
		{
			title: t("reports.totalAvailablePositions"),
			key: "totalAvailablePositions",
			// sorter: (
			// 	a: ProfessorProposalReportDTO,
			// 	b: ProfessorProposalReportDTO
			// ) => {
			// 	if (!a.totalAvailablePositions || !b.totalAvailablePositions) return -1;
			// 	return a.totalAvailablePositions - b.totalAvailablePositions;
			// },
		},
		{
			title: t("reports.totalAllocatedPositions"),
			key: "totalAllocatedPositions",
			// sorter: (
			// 	a: ProfessorProposalReportDTO,
			// 	b: ProfessorProposalReportDTO
			// ) => {
			// 	if (!a.totalAllocatedPositions || !b.totalAllocatedPositions) return -1;
			// 	return a.totalAllocatedPositions - b.totalAllocatedPositions;
			// },
		},
		{
			title: t("reports.totalAvailablePositionsAsCoTutor"),
			key: "totalAvailablePositionsAsCoTutor",
			hidden: window.innerWidth < 740,
			// sorter: (
			// 	a: ProfessorProposalReportDTO,
			// 	b: ProfessorProposalReportDTO
			// ) => {
			// 	if (
			// 		!a.totalAvailablePositionsAsCoTutor ||
			// 		!b.totalAvailablePositionsAsCoTutor
			// 	)
			// 		return -1;
			// 	return (
			// 		a.totalAvailablePositionsAsCoTutor -
			// 		b.totalAvailablePositionsAsCoTutor
			// 	);
			// },
		},
		{
			title: t("reports.totalAllocatedPositionsAsCoTutor"),
			key: "totalAllocatedPositionsAsCoTutor",
			hidden: window.innerWidth < 740,
			// sorter: (
			// 	a: ProfessorProposalReportDTO,
			// 	b: ProfessorProposalReportDTO
			// ) => {
			// 	if (
			// 		!a.totalAllocatedPositionsAsCoTutor ||
			// 		!b.totalAllocatedPositionsAsCoTutor
			// 	)
			// 		return -1;
			// 	return (
			// 		a.totalAllocatedPositionsAsCoTutor -
			// 		b.totalAllocatedPositionsAsCoTutor
			// 	);
			// },
		},
	];

	const missingStudentProjectEnrollmentReportColumns = (
		t: (text: string) => string
	) => [
		{
			title: t("reports.name"),
			key: "name",
			fixed: "left" as "left",
			// sorter: (
			// 	a: MissingStudentEnrollmentReportDTO,
			// 	b: MissingStudentEnrollmentReportDTO
			// ) => {
			// 	if (!a.name || !b.name) return -1;
			// 	return a.name.localeCompare(b.name);
			// },
			render: (text: string | undefined, record: any) => (
				<a onClick={() => goToStudentProfile(record.id)}>
					<b>{text}</b>
				</a>
			),
		},
		{
			title: t("reports.studyProgram"),
			key: "studyProgram",
			// sorter: (
			// 	a: MissingStudentEnrollmentReportDTO,
			// 	b: MissingStudentEnrollmentReportDTO
			// ) => {
			// 	if (!a.studyProgram || !b.studyProgram) return -1;
			// 	return a.studyProgram.localeCompare(b.studyProgram);
			// },
		},
		{
			title: t("reports.email"),
			key: "email",
			hidden: window.innerWidth < 650,
			// sorter: (
			// 	a: MissingStudentEnrollmentReportDTO,
			// 	b: MissingStudentEnrollmentReportDTO
			// ) => {
			// 	if (!a.email || !b.email) return -1;
			// 	return a.email.localeCompare(b.email);
			// },
		},
		{
			title: t("reports.applications"),
			key: "applications",
			// sorter: (
			// 	a: MissingStudentEnrollmentReportDTO,
			// 	b: MissingStudentEnrollmentReportDTO
			// ) => {
			// 	if (!a.applications || !b.applications) return -1;
			// 	return a.applications - b.applications;
			// },
		},
	];

	const missingStudentPracticeEnrollmentReportColumns = (
		t: (text: string) => string
	) => [
		{
			title: t("reports.name"),
			key: "name",
			fixed: "left" as "left",
			// sorter: (
			// 	a: MissingStudentEnrollmentReportDTO,
			// 	b: MissingStudentEnrollmentReportDTO
			// ) => {
			// 	if (!a.name || !b.name) return -1;
			// 	return a.name.localeCompare(b.name);
			// },
			render: (text: string | undefined, record: any) => (
				<a onClick={() => goToStudentProfile(record.id)}>
					<b>{text}</b>
				</a>
			),
		},
		{
			title: t("reports.studyProgram"),
			key: "studyProgram",
			// sorter: (
			// 	a: MissingStudentEnrollmentReportDTO,
			// 	b: MissingStudentEnrollmentReportDTO
			// ) => {
			// 	if (!a.studyProgram || !b.studyProgram) return -1;
			// 	return a.studyProgram.localeCompare(b.studyProgram);
			// },
		},
		{
			title: t("reports.group"),
			key: "group",
			// sorter: (
			// 	a: MissingStudentEnrollmentReportDTO,
			// 	b: MissingStudentEnrollmentReportDTO
			// ) => {
			// 	if (!a.group || !b.group) return -1;
			// 	return a.group.localeCompare(b.group);
			// },
		},
		{
			title: t("reports.phone"),
			key: "phone",
			// sorter: (
			// 	a: MissingStudentEnrollmentReportDTO,
			// 	b: MissingStudentEnrollmentReportDTO
			// ) => {
			// 	if (!a.phone || !b.phone) return -1;
			// 	return a.phone.localeCompare(b.phone);
			// },
		},
		{
			title: t("reports.email"),
			key: "email",
			hidden: window.innerWidth < 650,
			// sorter: (
			// 	a: MissingStudentEnrollmentReportDTO,
			// 	b: MissingStudentEnrollmentReportDTO
			// ) => {
			// 	if (!a.email || !b.email) return -1;
			// 	return a.email.localeCompare(b.email);
			// },
		},
		{
			title: t("reports.applications"),
			key: "applications",
			// sorter: (
			// 	a: MissingStudentEnrollmentReportDTO,
			// 	b: MissingStudentEnrollmentReportDTO
			// ) => {
			// 	if (!a.applications || !b.applications) return -1;
			// 	return a.applications - b.applications;
			// },
		},
	];

	const projectAllocationReportColumns = (t: (text: string) => string) => [
		{
			title: t("reports.studentName"),
			key: "studentName",
			fixed: "left" as "left",
			// sorter: (
			// 	a: ProjectAllocationReportDTO,
			// 	b: ProjectAllocationReportDTO
			// ) => {
			// 	if (!a.studentName || !b.studentName) return -1;
			// 	return a.studentName.localeCompare(b.studentName);
			// },
			render: (text: string | undefined, record: any) => (
				<a onClick={() => goToStudentProfile(record.studentId)}>
					<b>{text}</b>
				</a>
			),
		},
		{
			title: t("reports.professorName"),
			key: "professorName",
			// sorter: (
			// 	a: ProjectAllocationReportDTO,
			// 	b: ProjectAllocationReportDTO
			// ) => {
			// 	if (!a.professorName || !b.professorName) return -1;
			// 	return a.professorName.localeCompare(b.professorName);
			// },
			render: (_text: string | undefined, record: any) => (
				<Link
					to={{
						pathname: `/profil`,
						state: {
							id: record.professorId,
							userType: Role.Professor,
							origin: location.pathname,
							activeTab: tab,
						},
					}}
				>
					<b>{record.professorName}</b>
				</Link>
			),
		},
		{
			title: t("reports.projectName"),
			key: "projectName",
			// sorter: (
			// 	a: ProjectAllocationReportDTO,
			// 	b: ProjectAllocationReportDTO
			// ) => {
			// 	if (!a.projectName || !b.projectName) return -1;
			// 	return a.projectName.localeCompare(b.projectName);
			// },
			render: (_text: string | undefined, record: any) => (
				<Link
					to={{
						pathname: "/propunere/" + record.projectId,
						search: "?type=" + record.projectType,
						state: {
							id: record.projectId,
							type: record.projectType,
							origin: location.pathname,
							activeTab: tab,
						},
					}}
				>
					<b>{record.projectName}</b>
				</Link>
			),
		},
		{
			title: t("reports.studentStudyProgram"),
			key: "studentStudyProgram",
			hidden: window.innerWidth < 440,
			// sorter: (
			// 	a: ProjectAllocationReportDTO,
			// 	b: ProjectAllocationReportDTO
			// ) => {
			// 	if (!a.studentStudyProgram || !b.studentStudyProgram) return -1;
			// 	return a.studentStudyProgram.localeCompare(b.studentStudyProgram);
			// },
		},
		{
			title: t("reports.studentEmail"),
			key: "studentEmail",
			hidden: window.innerWidth < 700,
			// sorter: (
			// 	a: ProjectAllocationReportDTO,
			// 	b: ProjectAllocationReportDTO
			// ) => {
			// 	if (!a.studentEmail || !b.studentEmail) return -1;
			// 	return a.studentEmail.localeCompare(b.studentEmail);
			// },
		},
		{
			title: t("reports.professorDepartment"),
			key: "professorDepartment",
			hidden: window.innerWidth < 880,
			// sorter: (
			// 	a: ProjectAllocationReportDTO,
			// 	b: ProjectAllocationReportDTO
			// ) => {
			// 	if (!a.professorDepartment || !b.professorDepartment) return -1;
			// 	return a.professorDepartment.localeCompare(b.professorDepartment);
			// },
		},
		{
			title: t("reports.professorEmail"),
			key: "professorEmail",
			hidden: window.innerWidth < 700,
			// sorter: (
			// 	a: ProjectAllocationReportDTO,
			// 	b: ProjectAllocationReportDTO
			// ) => {
			// 	if (!a.professorEmail || !b.professorEmail) return -1;
			// 	return a.professorEmail.localeCompare(b.professorEmail);
			// },
		},
		{
			title: t("reports.coTutorName"),
			key: "coTutorName",
			hidden: window.innerWidth < 880,
			// sorter: (
			// 	a: ProjectAllocationReportDTO,
			// 	b: ProjectAllocationReportDTO
			// ) => {
			// 	if (!a.coTutorName || !b.coTutorName) return -1;
			// 	return a.coTutorName.localeCompare(b.coTutorName);
			// },
		},
	];

	const summerSchoolsReportColumns = (t: (text: string) => string) => [
		{
			title: t("reports.summerSchoolName"),
			key: "summerSchoolName",
			fixed: "left" as "left",
			// sorter: (a: SummerSchoolsReportDTO, b: SummerSchoolsReportDTO) => {
			// 	if (!a.summerSchoolName || !b.summerSchoolName) return -1;
			// 	return a.summerSchoolName.localeCompare(b.summerSchoolName);
			// },
			render: (_text: string | undefined, record: any) => (
				<Link
					to={{
						pathname: "/propunere/" + record.summerSchoolId,
						search: "?type=" + record.summerSchoolType,
						state: {
							id: record.summerSchoolId,
							type: record.summerSchoolType,
							origin: location.pathname,
							activeTab: tab,
						},
					}}
				>
					<b>{record.summerSchoolName}</b>
				</Link>
			),
		},
		{
			title: t("reports.coTutorName"),
			key: "coTutorName",
			// sorter: (a: SummerSchoolsReportDTO, b: SummerSchoolsReportDTO) => {
			// 	if (!a.coTutorName || !b.coTutorName) return -1;
			// 	return a.coTutorName.localeCompare(b.coTutorName);
			// },
		},
		{
			title: t("reports.totalAvailablePositions"),
			key: "totalAvailablePositions",
			// sorter: (a: SummerSchoolsReportDTO, b: SummerSchoolsReportDTO) => {
			// 	if (!a.totalAvailablePositions || !b.totalAvailablePositions) return -1;
			// 	return a.totalAvailablePositions - b.totalAvailablePositions;
			// },
		},
		{
			title: t("reports.totalAllocatedPositions"),
			key: "totalAllocatedPositions",
			// sorter: (a: SummerSchoolsReportDTO, b: SummerSchoolsReportDTO) => {
			// 	if (!a.totalAllocatedPositions || !b.totalAllocatedPositions) return -1;
			// 	return a.totalAllocatedPositions - b.totalAllocatedPositions;
			// },
		},
	];

	const practiceInternshipAllocationReportColumns = (
		t: (text: string) => string
	) => [
		{
			title: t("reports.studentName"),
			key: "studentName",
			fixed: "left" as "left",
			// sorter: (
			// 	a: PracticeInternshipAllocationReportDTO,
			// 	b: PracticeInternshipAllocationReportDTO
			// ) => {
			// 	if (!a.studentName || !b.studentName) return -1;
			// 	return a.studentName.localeCompare(b.studentName);
			// },
			render: (text: string | undefined, record: any) => (
				<a onClick={() => goToStudentProfile(record.studentId)}>
					<b>{text}</b>
				</a>
			),
		},
		{
			title: t("reports.employerName"),
			key: "employerName",
			// sorter: (
			// 	a: PracticeInternshipAllocationReportDTO,
			// 	b: PracticeInternshipAllocationReportDTO
			// ) => {
			// 	if (!a.employerName || !b.employerName) return -1;
			// 	return a.employerName.localeCompare(b.employerName);
			// },
			render: (_text: string | undefined, record: any) => (
				<Link
					to={{
						pathname: `/profil`,
						state: {
							id: record.employerId,
							userType:
								record.internshipType === InternshipType.ByProfessor
									? Role.Professor
									: Role.Company,
							origin: location.pathname,
							activeTab: tab,
						},
					}}
				>
					<b>{record.employerName}</b>
				</Link>
			),
		},
		{
			title: t("reports.internshipName"),
			key: "internshipName",
			// sorter: (
			// 	a: PracticeInternshipAllocationReportDTO,
			// 	b: PracticeInternshipAllocationReportDTO
			// ) => {
			// 	if (!a.internshipName || !b.internshipName) return -1;
			// 	return a.internshipName.localeCompare(b.internshipName);
			// },
			render: (_text: string | undefined, record: any) => (
				<Link
					to={{
						pathname: "/propunere/" + record.internshipId,
						search: "?type=" + record.internshipType,
						state: {
							id: record.internshipId,
							type: record.internshipType,
							origin: location.pathname,
							activeTab: tab,
						},
					}}
				>
					<b>{record.internshipName}</b>
				</Link>
			),
		},
		{
			title: t("reports.studentStudyProgram"),
			key: "studentStudyProgram",
			hidden: window.innerWidth < 440,
			// sorter: (
			// 	a: PracticeInternshipAllocationReportDTO,
			// 	b: PracticeInternshipAllocationReportDTO
			// ) => {
			// 	if (!a.studentStudyProgram || !b.studentStudyProgram) return -1;
			// 	return a.studentStudyProgram.localeCompare(b.studentStudyProgram);
			// },
		},
		{
			title: t("reports.studentEmail"),
			key: "studentEmail",
			hidden: window.innerWidth < 700,
			// sorter: (
			// 	a: PracticeInternshipAllocationReportDTO,
			// 	b: PracticeInternshipAllocationReportDTO
			// ) => {
			// 	if (!a.studentEmail || !b.studentEmail) return -1;
			// 	return a.studentEmail.localeCompare(b.studentEmail);
			// },
		},
		{
			title: t("reports.tutorEmail"),
			key: "tutorEmail",
			hidden: window.innerWidth < 700,
			// sorter: (
			// 	a: PracticeInternshipAllocationReportDTO,
			// 	b: PracticeInternshipAllocationReportDTO
			// ) => {
			// 	if (!a.tutorEmail || !b.tutorEmail) return -1;
			// 	return a.tutorEmail.localeCompare(b.tutorEmail);
			// },
		},
	];

	const studentAllocationPerDomainReportColumns = (
		t: (text: string) => string
	) => [
		{
			title: t("reports.studyProgram"),
			key: "fieldOfStudy",
			// sorter: (
			// 	a: StudentsPerDomainReportDTO,
			// 	b: StudentsPerDomainReportDTO
			// ) => {
			// 	if (!a.fieldOfStudy || !b.fieldOfStudy) return -1;
			// 	return a.fieldOfStudy.localeCompare(b.fieldOfStudy);
			// },
		},
		{
			title: t("reports.year"),
			key: "year",
			// sorter: (
			// 	a: StudentsPerDomainReportDTO,
			// 	b: StudentsPerDomainReportDTO
			// ) => {
			// 	if (!a.year || !b.year) return -1;
			// 	return a.year.localeCompare(b.year);
			// },
		},
		{
			title: t("reports.totalStudents"),
			key: "totalStudents",
			// sorter: (
			// 	a: StudentsPerDomainReportDTO,
			// 	b: StudentsPerDomainReportDTO
			// ) => {
			// 	if (!a.totalStudents || !b.totalStudents) return -1;
			// 	return a.totalStudents - b.totalStudents;
			// },
		},
		{
			title: t("reports.applyingStudents"),
			key: "applyingStudents",
			// sorter: (
			// 	a: StudentsPerDomainReportDTO,
			// 	b: StudentsPerDomainReportDTO
			// ) => {
			// 	if (!a.applyingStudents || !b.applyingStudents) return -1;
			// 	return a.applyingStudents - b.applyingStudents;
			// },
		},
		{
			title: t("reports.applicationAcceptedStudents"),
			key: "applicationAcceptedStudents",
			// sorter: (
			// 	a: StudentsPerDomainReportDTO,
			// 	b: StudentsPerDomainReportDTO
			// ) => {
			// 	if (!a.applicationAcceptedStudents || !b.applicationAcceptedStudents)
			// 		return -1;
			// 	return a.applicationAcceptedStudents - b.applicationAcceptedStudents;
			// },
		},
		{
			title: t("reports.offerAcceptedStudents"),
			key: "offerAcceptedStudents",
			// sorter: (
			// 	a: StudentsPerDomainReportDTO,
			// 	b: StudentsPerDomainReportDTO
			// ) => {
			// 	if (!a.offerAcceptedStudents || !b.offerAcceptedStudents) return -1;
			// 	return a.offerAcceptedStudents - b.offerAcceptedStudents;
			// },
		},
	];

	const practiceInternshipsPerDomainReportColumns = (
		t: (text: string) => string
	) => [
		{
			title: t("reports.studyProgram"),
			key: "fieldOfStudy",
			// sorter: (
			// 	a: PracticeInternshipsPerDomainReportDTO,
			// 	b: PracticeInternshipsPerDomainReportDTO
			// ) => {
			// 	if (!a.fieldOfStudy || !b.fieldOfStudy) return -1;
			// 	return a.fieldOfStudy.localeCompare(b.fieldOfStudy);
			// },
		},
		{
			title: t("reports.totalInternships"),
			key: "totalInternships",
			// sorter: (
			// 	a: PracticeInternshipsPerDomainReportDTO,
			// 	b: PracticeInternshipsPerDomainReportDTO
			// ) => {
			// 	if (!a.totalInternships || !b.totalInternships) return -1;
			// 	return a.totalInternships - b.totalInternships;
			// },
		},
		{
			title: t("reports.totalInitialPositions"),
			key: "totalInitialPositions",
			// sorter: (
			// 	a: PracticeInternshipsPerDomainReportDTO,
			// 	b: PracticeInternshipsPerDomainReportDTO
			// ) => {
			// 	if (!a.totalInitialPositions || !b.totalInitialPositions) return -1;
			// 	return a.totalInitialPositions - b.totalInitialPositions;
			// },
		},
		{
			title: t("reports.totalAvailablePositions"),
			key: "totalAvailablePositions",
			// sorter: (
			// 	a: PracticeInternshipsPerDomainReportDTO,
			// 	b: PracticeInternshipsPerDomainReportDTO
			// ) => {
			// 	if (!a.totalAvailablePositions || !b.totalAvailablePositions) return -1;
			// 	return a.totalAvailablePositions - b.totalAvailablePositions;
			// },
		},
		{
			title: t("reports.averageNumberPositionsPerEmployer"),
			key: "averageNumberPositionsPerEmployer",
			// sorter: (
			// 	a: PracticeInternshipsPerDomainReportDTO,
			// 	b: PracticeInternshipsPerDomainReportDTO
			// ) => {
			// 	if (
			// 		!a.averageNumberPositionsPerEmployer ||
			// 		!b.averageNumberPositionsPerEmployer
			// 	)
			// 		return -1;
			// 	return (
			// 		a.averageNumberPositionsPerEmployer -
			// 		b.averageNumberPositionsPerEmployer
			// 	);
			// },
		},
	];

	const openErrorNotification = (error: string, message: string) => {
		openNotification(error, message, NOTIFICATION_TYPES.ERROR);
	};

	const { data: programs } = useQuery(
		[getPrograms],
		() => getReportStudyPrograms(),
		{
			refetchOnWindowFocus: false,
			onError: () =>
				openErrorNotification(
					t("reports.errorTexts.loadingFailed"),
					t("reports.errorTexts.loadingFailedMessage")
				),
		}
	);

	useEffect(() => {
		setFilteredPrograms(
			programs?.filter(
				(program) =>
					program.studyCycle?.toLowerCase() === studyCycle?.toLowerCase()
			) ?? []
		);
		setStudyProgram([]);
	}, [studyCycle, programs]);

	useEffect(() => {
		if (tab === "4" || tab === "5") {
			setStudyCycle("Bachelor");
		}
	}, [tab]);

	const getOldValues = () => {
		if (location.state !== undefined) {
			let { activeTab }: any = location.state;

			setTab(activeTab);

			history.replace({ ...history.location, state: undefined });
		}
	};

	useBeforeRender(() => getOldValues(), [location]);

	return (
		<div className="px-4 flex flex-col gap-4 py-10">
			<div className="flex flex-wrap items-center gap-2">
				{tab !== "4" && tab !== "5" && tab !== "6" && tab !== "7" && (
					<Select
						defaultValue={studyCycle}
						options={[
							{ label: t("reports.bachelors"), value: "Bachelor" },
							{ label: t("reports.masters"), value: "Master" },
						]}
						onChange={setStudyCycle}
					/>
				)}
				{tab === "5" && (
					<Select
						defaultValue={internshipType}
						options={[
							{ label: t("reports.allOffers"), value: null },
							{
								label: t("reports.facultyOffers"),
								value: InternshipType.ByProfessor,
							},
							{
								label: t("reports.companyOffers"),
								value: InternshipType.ByCompany,
							},
						]}
						onChange={setInternshipType}
					/>
				)}
				{tab === "7" && (
					<Select
						defaultValue={internshipType}
						options={[
							{
								label: t("reports.facultyOffers"),
								value: InternshipType.ByProfessor,
							},
							{
								label: t("reports.companyOffers"),
								value: InternshipType.ByCompany,
							},
						]}
						onChange={setInternshipType}
					/>
				)}
				{tab !== "1" &&
					tab !== "6" &&
					tab !== "7" &&
					programs &&
					programs.length > 1 && (
						<Select
							showSearch
							allowClear
							onChange={setStudyProgram}
							style={{ width: "12em" }}
							placeholder={t("reports.studyProgram")}
							value={studyProgram}
						>
							{filteredPrograms?.map((program) => (
								<Option key={program.id} value={program.name}>
									{program.name}
								</Option>
							))}
						</Select>
					)}
			</div>

			<Tabs activeKey={tab} onChange={setTab}>
				{studyCycle === "Bachelor" && isAcademicUsersBachelorInternshipEnabled && (
					<TabPane tab={t("reports.professorReport")} key="1">
						<ReportTable
							getCall={getProfessorProposalReport}
							args={[
								InternshipEngagementType.BachelorsInternship,
								studyProgram,
							]}
							columns={professorReportColumns(t).filter(
								(item) => !item?.hidden
							)}
							reportColumns={professorReportColumns(t)}
							queryKey={getProfessorProposalReportInfo}
							watchChanges={[studyCycle]}
							fileName={t("files.professorReport")}
						/>
					</TabPane>
				)}
				{studyCycle === "Master" && isAcademicUsersMasterInternshipEnabled && (
					<TabPane tab={t("reports.professorReport")} key="1">
						<ReportTable
							getCall={getProfessorProposalReport}
							args={[InternshipEngagementType.MastersInternship, studyProgram]}
							columns={professorReportColumns(t).filter(
								(item) => !item?.hidden
							)}
							reportColumns={professorReportColumns(t)}
							queryKey={getProfessorProposalReportInfo}
							watchChanges={[studyCycle]}
							fileName={t("files.professorReport")}
						/>
					</TabPane>
				)}
				{(isAcademicUsersBachelorInternshipEnabled ||
					isAcademicUsersMasterInternshipEnabled) && (
					<TabPane
						tab={t("reports.missingStudentProjectEnrollmentReport")}
						key="2"
					>
						<ReportTable
							getCall={getMissingStudentProjectEnrollmentReport}
							args={[studyProgram]}
							columns={missingStudentProjectEnrollmentReportColumns(t).filter(
								(item) => !item?.hidden
							)}
							reportColumns={missingStudentProjectEnrollmentReportColumns(t)}
							queryKey={getMissingStudentProjectEnrollmentReportInfo}
							watchChanges={[studyCycle, studyProgram]}
							fileName={t("files.missingStudentProjectEnrollmentReport")}
						/>
					</TabPane>
				)}
				{studyCycle === "Bachelor" && isAcademicUsersBachelorInternshipEnabled && (
					<TabPane tab={t("reports.allocatedProjectsReport")} key="3">
						<ReportTable
							getCall={getProjectAllocationReport}
							args={[
								InternshipEngagementType.BachelorsInternship,
								studyProgram,
							]}
							columns={projectAllocationReportColumns(t).filter(
								(item) => !item?.hidden
							)}
							reportColumns={projectAllocationReportColumns(t)}
							queryKey={getProjectAllocationReportInfo}
							watchChanges={[studyCycle, studyProgram]}
							fileName={t("files.allocatedProjectsReport")}
						/>
					</TabPane>
				)}
				{studyCycle === "Master" && isAcademicUsersMasterInternshipEnabled && (
					<TabPane tab={t("reports.allocatedProjectsReport")} key="3">
						<ReportTable
							getCall={getProjectAllocationReport}
							args={[InternshipEngagementType.MastersInternship, studyProgram]}
							columns={projectAllocationReportColumns(t).filter(
								(item) => !item?.hidden
							)}
							reportColumns={projectAllocationReportColumns(t)}
							queryKey={getProjectAllocationReportInfo}
							watchChanges={[studyCycle, studyProgram]}
							fileName={t("files.allocatedProjectsReport")}
						/>
					</TabPane>
				)}
				<TabPane
					tab={t("reports.missingStudentPracticeInternshipEnrollmentReport")}
					key="4"
				>
					<ReportTable
						getCall={getMissingStudentPracticeInternshipEnrollmentReport}
						args={[studyProgram]}
						columns={missingStudentPracticeEnrollmentReportColumns(t).filter(
							(item) => !item?.hidden
						)}
						reportColumns={missingStudentPracticeEnrollmentReportColumns(t)}
						queryKey={getMissingStudentPracticeInternshipEnrollmentReportInfo}
						watchChanges={[studyProgram]}
						fileName={t(
							"files.missingStudentPracticeInternshipEnrollmentReport"
						)}
					/>
				</TabPane>
				<TabPane tab={t("reports.allocatedPracticeInternshipsReport")} key="5">
					<ReportTable
						getCall={getPracticeInternshipAllocationReport}
						args={[
							studyProgram,
							internshipType !== null ? internshipType : undefined,
						]}
						columns={practiceInternshipAllocationReportColumns(t).filter(
							(item) => !item?.hidden
						)}
						reportColumns={practiceInternshipAllocationReportColumns(t)}
						queryKey={getPracticeInternshipAllocationReportInfo}
						watchChanges={[studyProgram, internshipType]}
						fileName={t("files.allocatedPracticeInternshipsReport")}
					/>
				</TabPane>
				<TabPane tab={t("reports.studentAllocationPerDomainReport")} key="6">
					<ReportTable
						getCall={getStudentAllocationPerDomainReport}
						args={[]}
						columns={studentAllocationPerDomainReportColumns(t)}
						reportColumns={studentAllocationPerDomainReportColumns(t)}
						queryKey={getStudentAllocationPerDomainReportInfo}
						watchChanges={[]}
						fileName={t("files.studentAllocationPerDomainReport")}
					/>
				</TabPane>
				<TabPane tab={t("reports.practiceInternshipsPerDomainReport")} key="7">
					<ReportTable
						getCall={getPracticeInternshipPerDomainReport}
						args={[internshipType!]}
						columns={practiceInternshipsPerDomainReportColumns(t)}
						reportColumns={practiceInternshipsPerDomainReportColumns(t)}
						queryKey={getPracticeInternshipPerDomainReportInfo}
						watchChanges={[internshipType]}
						fileName={t("files.practiceInternshipsPerDomainReport")}
					/>
				</TabPane>
				{isAcademicUsersSummerSchoolEnabled && (
					<TabPane tab={t("reports.summerSchoolsReport")} key="8">
						<ReportTable
							getCall={getSummerSchoolsReport}
							args={[InternshipEngagementType.SummerSchool, studyProgram]}
							columns={summerSchoolsReportColumns(t)}
							reportColumns={summerSchoolsReportColumns(t)}
							queryKey={getSummerSchoolReportInfo}
							watchChanges={[studyCycle, studyProgram]}
							fileName={t("files.summerSchoolReport")}
						/>
					</TabPane>
				)}
			</Tabs>
		</div>
	);
};

export default Report;
