/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
	EnrollmentStatus,
	EnrollmentStatusFromJSON,
	EnrollmentStatusFromJSONTyped,
	EnrollmentStatusToJSON,
} from "./EnrollmentStatus";
import {
	InternshipDocumentState,
	InternshipDocumentStateFromJSON,
	InternshipDocumentStateFromJSONTyped,
	InternshipDocumentStateToJSON,
} from "./InternshipDocumentState";
import {
	InternshipEngagementType,
	InternshipEngagementTypeFromJSON,
	InternshipEngagementTypeFromJSONTyped,
	InternshipEngagementTypeToJSON,
} from "./InternshipEngagementType";
import {
	InternshipType,
	InternshipTypeFromJSON,
	InternshipTypeFromJSONTyped,
	InternshipTypeToJSON,
} from "./InternshipType";
import {
	PracticeCertificateSigningEnum,
	PracticeCertificateSigningEnumFromJSON,
	PracticeCertificateSigningEnumFromJSONTyped,
	PracticeCertificateSigningEnumToJSON,
} from "./PracticeCertificateSigningEnum";
import {
	PracticeCertificateState,
	PracticeCertificateStateFromJSON,
	PracticeCertificateStateFromJSONTyped,
	PracticeCertificateStateToJSON,
} from "./PracticeCertificateState";
import {
	PracticeNotebookSigningEnum,
	PracticeNotebookSigningEnumFromJSON,
	PracticeNotebookSigningEnumFromJSONTyped,
	PracticeNotebookSigningEnumToJSON,
} from "./PracticeNotebookSigningEnum";
import {
	PracticeNotebookState,
	PracticeNotebookStateFromJSON,
	PracticeNotebookStateFromJSONTyped,
	PracticeNotebookStateToJSON,
} from "./PracticeNotebookState";
import {
	StudentYearEnum,
	StudentYearEnumFromJSON,
	StudentYearEnumFromJSONTyped,
	StudentYearEnumToJSON,
} from "./StudentYearEnum";

/**
 *
 * @export
 * @interface InternshipEnrollmentDTO
 */
export interface InternshipEnrollmentDTO {
	/**
	 *
	 * @type {string}
	 * @memberof InternshipEnrollmentDTO
	 */
	id?: string;
	/**
	 *
	 * @type {string}
	 * @memberof InternshipEnrollmentDTO
	 */
	companyId?: string;
	/**
	 *
	 * @type {string}
	 * @memberof InternshipEnrollmentDTO
	 */
	companyName?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof InternshipEnrollmentDTO
	 */
	companyLogo?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof InternshipEnrollmentDTO
	 */
	companyDepartment?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof InternshipEnrollmentDTO
	 */
	professorId?: string;
	/**
	 *
	 * @type {string}
	 * @memberof InternshipEnrollmentDTO
	 */
	professorHeadshot?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof InternshipEnrollmentDTO
	 */
	professorName?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof InternshipEnrollmentDTO
	 */
	city?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof InternshipEnrollmentDTO
	 */
	country?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof InternshipEnrollmentDTO
	 */
	address?: string | null;
	/**
	 *
	 * @type {boolean}
	 * @memberof InternshipEnrollmentDTO
	 */
	isPaid?: boolean;
	/**
	 *
	 * @type {string}
	 * @memberof InternshipEnrollmentDTO
	 */
	internshipId?: string;
	/**
	 *
	 * @type {string}
	 * @memberof InternshipEnrollmentDTO
	 */
	internshipName?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof InternshipEnrollmentDTO
	 */
	description?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof InternshipEnrollmentDTO
	 */
	shortDescription?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof InternshipEnrollmentDTO
	 */
	category?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof InternshipEnrollmentDTO
	 */
	location?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof InternshipEnrollmentDTO
	 */
	placeId?: string | null;
	/**
	 *
	 * @type {number}
	 * @memberof InternshipEnrollmentDTO
	 */
	period?: number;
	/**
	 *
	 * @type {number}
	 * @memberof InternshipEnrollmentDTO
	 */
	totalPracticeHours?: number;
	/**
	 *
	 * @type {string}
	 * @memberof InternshipEnrollmentDTO
	 */
	laboratoryPracticeRoom?: string | null;
	/**
	 *
	 * @type {Date}
	 * @memberof InternshipEnrollmentDTO
	 */
	availableFrom?: Date;
	/**
	 *
	 * @type {Date}
	 * @memberof InternshipEnrollmentDTO
	 */
	availableTo?: Date;
	/**
	 *
	 * @type {Date}
	 * @memberof InternshipEnrollmentDTO
	 */
	startingDate?: Date;
	/**
	 *
	 * @type {number}
	 * @memberof InternshipEnrollmentDTO
	 */
	initialAvailablePositions?: number;
	/**
	 *
	 * @type {number}
	 * @memberof InternshipEnrollmentDTO
	 */
	availablePositions?: number;
	/**
	 *
	 * @type {number}
	 * @memberof InternshipEnrollmentDTO
	 */
	enrolledStudentsCount?: number;
	/**
	 *
	 * @type {number}
	 * @memberof InternshipEnrollmentDTO
	 */
	applicantsCount?: number;
	/**
	 *
	 * @type {Array<string>}
	 * @memberof InternshipEnrollmentDTO
	 */
	skillsRequired?: Array<string> | null;
	/**
	 *
	 * @type {EnrollmentStatus}
	 * @memberof InternshipEnrollmentDTO
	 */
	recruiterStatus?: EnrollmentStatus;
	/**
	 *
	 * @type {EnrollmentStatus}
	 * @memberof InternshipEnrollmentDTO
	 */
	studentStatus?: EnrollmentStatus;
	/**
	 *
	 * @type {StudentYearEnum}
	 * @memberof InternshipEnrollmentDTO
	 */
	studentYear?: StudentYearEnum;
	/**
	 *
	 * @type {InternshipType}
	 * @memberof InternshipEnrollmentDTO
	 */
	internshipType?: InternshipType;
	/**
	 *
	 * @type {InternshipEngagementType}
	 * @memberof InternshipEnrollmentDTO
	 */
	engagementType?: InternshipEngagementType;
	/**
	 *
	 * @type {boolean}
	 * @memberof InternshipEnrollmentDTO
	 */
	hasGeneratedPracticeConvention?: boolean;
	/**
	 *
	 * @type {string}
	 * @memberof InternshipEnrollmentDTO
	 */
	internshipEnrollmentId?: string;
	/**
	 *
	 * @type {string}
	 * @memberof InternshipEnrollmentDTO
	 */
	practiceNotebookId?: string;
	/**
	 *
	 * @type {PracticeNotebookState}
	 * @memberof InternshipEnrollmentDTO
	 */
	practiceNotebookState?: PracticeNotebookState;
	/**
	 *
	 * @type {string}
	 * @memberof InternshipEnrollmentDTO
	 */
	readonly practiceNotebookLastSignatureId?: string;
	/**
	 *
	 * @type {PracticeNotebookSigningEnum}
	 * @memberof InternshipEnrollmentDTO
	 */
	practiceNotebookLastSigner?: PracticeNotebookSigningEnum;
	/**
	 *
	 * @type {string}
	 * @memberof InternshipEnrollmentDTO
	 */
	practiceCertificateId?: string;
	/**
	 *
	 * @type {PracticeCertificateState}
	 * @memberof InternshipEnrollmentDTO
	 */
	practiceCertificateState?: PracticeCertificateState;
	/**
	 *
	 * @type {string}
	 * @memberof InternshipEnrollmentDTO
	 */
	readonly practiceCertificateLastSignatureId?: string;
	/**
	 *
	 * @type {PracticeCertificateSigningEnum}
	 * @memberof InternshipEnrollmentDTO
	 */
	practiceCertificateLastSigner?: PracticeCertificateSigningEnum;
	/**
	 *
	 * @type {string}
	 * @memberof InternshipEnrollmentDTO
	 */
	internshipDocumentId?: string;
	/**
	 *
	 * @type {InternshipDocumentState}
	 * @memberof InternshipEnrollmentDTO
	 */
	internshipDocumentState?: InternshipDocumentState;
	/**
	 *
	 * @type {string}
	 * @memberof InternshipEnrollmentDTO
	 */
	facultyCoordinatorId?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof InternshipEnrollmentDTO
	 */
	supervisorId?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof InternshipEnrollmentDTO
	 */
	tutorId?: string;
	/**
	 *
	 * @type {string}
	 * @memberof InternshipEnrollmentDTO
	 */
	supervisorName?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof InternshipEnrollmentDTO
	 */
	tutorName?: string | null;
}

export function InternshipEnrollmentDTOFromJSON(
	json: any
): InternshipEnrollmentDTO {
	return InternshipEnrollmentDTOFromJSONTyped(json, false);
}

export function InternshipEnrollmentDTOFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): InternshipEnrollmentDTO {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		id: !exists(json, "id") ? undefined : json["id"],
		companyId: !exists(json, "companyId") ? undefined : json["companyId"],
		companyName: !exists(json, "companyName") ? undefined : json["companyName"],
		companyLogo: !exists(json, "companyLogo") ? undefined : json["companyLogo"],
		companyDepartment: !exists(json, "companyDepartment")
			? undefined
			: json["companyDepartment"],
		professorId: !exists(json, "professorId") ? undefined : json["professorId"],
		professorHeadshot: !exists(json, "professorHeadshot")
			? undefined
			: json["professorHeadshot"],
		professorName: !exists(json, "professorName")
			? undefined
			: json["professorName"],
		city: !exists(json, "city") ? undefined : json["city"],
		country: !exists(json, "country") ? undefined : json["country"],
		address: !exists(json, "address") ? undefined : json["address"],
		isPaid: !exists(json, "isPaid") ? undefined : json["isPaid"],
		internshipId: !exists(json, "internshipId")
			? undefined
			: json["internshipId"],
		internshipName: !exists(json, "internshipName")
			? undefined
			: json["internshipName"],
		description: !exists(json, "description") ? undefined : json["description"],
		shortDescription: !exists(json, "shortDescription")
			? undefined
			: json["shortDescription"],
		category: !exists(json, "category") ? undefined : json["category"],
		location: !exists(json, "location") ? undefined : json["location"],
		placeId: !exists(json, "placeId") ? undefined : json["placeId"],
		period: !exists(json, "period") ? undefined : json["period"],
		totalPracticeHours: !exists(json, "totalPracticeHours")
			? undefined
			: json["totalPracticeHours"],
		laboratoryPracticeRoom: !exists(json, "laboratoryPracticeRoom")
			? undefined
			: json["laboratoryPracticeRoom"],
		availableFrom: !exists(json, "availableFrom")
			? undefined
			: new Date(json["availableFrom"]),
		availableTo: !exists(json, "availableTo")
			? undefined
			: new Date(json["availableTo"]),
		startingDate: !exists(json, "startingDate")
			? undefined
			: new Date(json["startingDate"]),
		initialAvailablePositions: !exists(json, "initialAvailablePositions")
			? undefined
			: json["initialAvailablePositions"],
		availablePositions: !exists(json, "availablePositions")
			? undefined
			: json["availablePositions"],
		enrolledStudentsCount: !exists(json, "enrolledStudentsCount")
			? undefined
			: json["enrolledStudentsCount"],
		applicantsCount: !exists(json, "applicantsCount")
			? undefined
			: json["applicantsCount"],
		skillsRequired: !exists(json, "skillsRequired")
			? undefined
			: json["skillsRequired"],
		recruiterStatus: !exists(json, "recruiterStatus")
			? undefined
			: EnrollmentStatusFromJSON(json["recruiterStatus"]),
		studentStatus: !exists(json, "studentStatus")
			? undefined
			: EnrollmentStatusFromJSON(json["studentStatus"]),
		studentYear: !exists(json, "studentYear")
			? undefined
			: StudentYearEnumFromJSON(json["studentYear"]),
		internshipType: !exists(json, "internshipType")
			? undefined
			: InternshipTypeFromJSON(json["internshipType"]),
		engagementType: !exists(json, "engagementType")
			? undefined
			: InternshipEngagementTypeFromJSON(json["engagementType"]),
		hasGeneratedPracticeConvention: !exists(
			json,
			"hasGeneratedPracticeConvention"
		)
			? undefined
			: json["hasGeneratedPracticeConvention"],
		internshipEnrollmentId: !exists(json, "internshipEnrollmentId")
			? undefined
			: json["internshipEnrollmentId"],
		practiceNotebookId: !exists(json, "practiceNotebookId")
			? undefined
			: json["practiceNotebookId"],
		practiceNotebookState: !exists(json, "practiceNotebookState")
			? undefined
			: PracticeNotebookStateFromJSON(json["practiceNotebookState"]),
		practiceNotebookLastSignatureId: !exists(
			json,
			"practiceNotebookLastSignatureId"
		)
			? undefined
			: json["practiceNotebookLastSignatureId"],
		practiceNotebookLastSigner: !exists(json, "practiceNotebookLastSigner")
			? undefined
			: PracticeNotebookSigningEnumFromJSON(json["practiceNotebookLastSigner"]),
		practiceCertificateId: !exists(json, "practiceCertificateId")
			? undefined
			: json["practiceCertificateId"],
		practiceCertificateState: !exists(json, "practiceCertificateState")
			? undefined
			: PracticeCertificateStateFromJSON(json["practiceCertificateState"]),
		practiceCertificateLastSignatureId: !exists(
			json,
			"practiceCertificateLastSignatureId"
		)
			? undefined
			: json["practiceCertificateLastSignatureId"],
		practiceCertificateLastSigner: !exists(
			json,
			"practiceCertificateLastSigner"
		)
			? undefined
			: PracticeCertificateSigningEnumFromJSON(
					json["practiceCertificateLastSigner"]
			  ),
		internshipDocumentId: !exists(json, "internshipDocumentId")
			? undefined
			: json["internshipDocumentId"],
		internshipDocumentState: !exists(json, "internshipDocumentState")
			? undefined
			: InternshipDocumentStateFromJSON(json["internshipDocumentState"]),
		facultyCoordinatorId: !exists(json, "facultyCoordinatorId")
			? undefined
			: json["facultyCoordinatorId"],
		supervisorId: !exists(json, "supervisorId")
			? undefined
			: json["supervisorId"],
		tutorId: !exists(json, "tutorId") ? undefined : json["tutorId"],
		supervisorName: !exists(json, "supervisorName")
			? undefined
			: json["supervisorName"],
		tutorName: !exists(json, "tutorName") ? undefined : json["tutorName"],
	};
}

export function InternshipEnrollmentDTOToJSON(
	value?: InternshipEnrollmentDTO | null
): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		id: value.id,
		companyId: value.companyId,
		companyName: value.companyName,
		companyLogo: value.companyLogo,
		companyDepartment: value.companyDepartment,
		professorId: value.professorId,
		professorHeadshot: value.professorHeadshot,
		professorName: value.professorName,
		city: value.city,
		country: value.country,
		address: value.address,
		isPaid: value.isPaid,
		internshipId: value.internshipId,
		internshipName: value.internshipName,
		description: value.description,
		shortDescription: value.shortDescription,
		category: value.category,
		location: value.location,
		placeId: value.placeId,
		period: value.period,
		totalPracticeHours: value.totalPracticeHours,
		laboratoryPracticeRoom: value.laboratoryPracticeRoom,
		availableFrom:
			value.availableFrom === undefined
				? undefined
				: value.availableFrom.toISOString(),
		availableTo:
			value.availableTo === undefined
				? undefined
				: value.availableTo.toISOString(),
		startingDate:
			value.startingDate === undefined
				? undefined
				: value.startingDate.toISOString(),
		initialAvailablePositions: value.initialAvailablePositions,
		availablePositions: value.availablePositions,
		enrolledStudentsCount: value.enrolledStudentsCount,
		applicantsCount: value.applicantsCount,
		skillsRequired: value.skillsRequired,
		recruiterStatus: EnrollmentStatusToJSON(value.recruiterStatus),
		studentStatus: EnrollmentStatusToJSON(value.studentStatus),
		studentYear: StudentYearEnumToJSON(value.studentYear),
		internshipType: InternshipTypeToJSON(value.internshipType),
		engagementType: InternshipEngagementTypeToJSON(value.engagementType),
		hasGeneratedPracticeConvention: value.hasGeneratedPracticeConvention,
		internshipEnrollmentId: value.internshipEnrollmentId,
		practiceNotebookId: value.practiceNotebookId,
		practiceNotebookState: PracticeNotebookStateToJSON(
			value.practiceNotebookState
		),
		practiceNotebookLastSigner: PracticeNotebookSigningEnumToJSON(
			value.practiceNotebookLastSigner
		),
		practiceCertificateId: value.practiceCertificateId,
		practiceCertificateState: PracticeCertificateStateToJSON(
			value.practiceCertificateState
		),
		practiceCertificateLastSigner: PracticeCertificateSigningEnumToJSON(
			value.practiceCertificateLastSigner
		),
		internshipDocumentId: value.internshipDocumentId,
		internshipDocumentState: InternshipDocumentStateToJSON(
			value.internshipDocumentState
		),
		facultyCoordinatorId: value.facultyCoordinatorId,
		supervisorId: value.supervisorId,
		tutorId: value.tutorId,
		supervisorName: value.supervisorName,
		tutorName: value.tutorName,
	};
}
