/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
	FilterItem,
	FilterItemFromJSON,
	FilterItemFromJSONTyped,
	FilterItemToJSON,
} from "./FilterItem";

/**
 *
 * @export
 * @interface Filter
 */
export interface Filter {
	/**
	 *
	 * @type {string}
	 * @memberof Filter
	 */
	title?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof Filter
	 */
	key?: string | null;
	/**
	 *
	 * @type {Set<FilterItem>}
	 * @memberof Filter
	 */
	children?: Set<FilterItem> | null;
}

export function FilterFromJSON(json: any): Filter {
	return FilterFromJSONTyped(json, false);
}

export function FilterFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): Filter {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		title: !exists(json, "title") ? undefined : json["title"],
		key: !exists(json, "key") ? undefined : json["key"],
		children: !exists(json, "children")
			? undefined
			: json["children"] === null
			? null
			: new Set((json["children"] as Array<any>).map(FilterItemFromJSON)),
	};
}

export function FilterToJSON(value?: Filter | null): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		title: value.title,
		key: value.key,
		children:
			value.children === undefined
				? undefined
				: value.children === null
				? null
				: Array.from(value.children as Set<any>).map(FilterItemToJSON),
	};
}
