/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface FeatureFlag
 */
export interface FeatureFlag {
	/**
	 *
	 * @type {boolean}
	 * @memberof FeatureFlag
	 */
	value?: boolean;
}

export function FeatureFlagFromJSON(json: any): FeatureFlag {
	return FeatureFlagFromJSONTyped(json, false);
}

export function FeatureFlagFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): FeatureFlag {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		value: !exists(json, "value") ? undefined : json["value"],
	};
}

export function FeatureFlagToJSON(value?: FeatureFlag | null): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		value: value.value,
	};
}
